import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import {DEFAULT_CRUD_STATE, DEFAULT_METADATA_SELECT_SEARCH_QUERY, UPDATE_PERM} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDefaultQueryFields,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getLookup,
    getProp,
    longTableColumn,
    mergeDeep,
    openInNewTab,
    saveTableColumns
} from '../../../common/util/util-helpers'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecords from "../../../common/components/no-records-found/no-records";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import {CREATE_PERM, DEFAULT_QUERY_LIMIT, READ_PERM} from '../../../common/util/util-consts'
import {formatNumber} from "chart.js/helpers";
import CellLink from "../../../common/components/resource-table/table-components/cell-link";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import WorkOrderStatusBadge from "../../../common/components/badge/work-order-status-badge";
import Tippy from "@tippyjs/react";
import {toFrontDate, toFrontDateTimeFromUTC} from "../../../common/util/util-dates";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalDefault from "../../../common/components/modal/modal-default";
import {getSecondResource} from "../../../data/actions/secondResource";
import moment from "moment";
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";
import Env from "../../../util/env";

class UpcomingMaintenanceTab extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1) + "_upcoming"

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'ScheduledMaintenanceTypeID',
            sortWo: 'ASC',
            sortByWo: 'AutoCounter',
            offsetWo: 0,
            paginationPageWo: 1,
            contactsList: {},
            selectedContactsToAdd: {},
            selectedContacts: {},
            selectedWoItem: null,
            showWoDialog: false,
            // Modals
            createModalOpen: false,
            isAllContactsModalOpen: false,
            mileageModalOpen: false,
            selectedMileageItem: null,

            // Fields
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), {
                behaviour: {
                    canAdjustWidth: true,
                    hasVirtualRows: true,
                    hasMenu: true
                },
                style: {
                    condensed: true,
                    floatingActions: true,
                    isGPUAccelerated: true,
                    condensedHeight: 100
                },
                classes: {},
                columns: {
                    Assets: {
                        frozen: false
                    }
                }
            }, this.pagePath, this.props.translate),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),
            queryWoFields: this.getQueryWoFields(this.props.translate),
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    fetchWoData = (item) => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.WorkOrderScheduledMaintenanceWorkOrders,
            query: this.getWoQuery(item)
        }))
    }

    createWO = (item) => {
        this.setState({text: this.props.translate('message.are_you_sure_create_wo_upcoming')}, () => {
            this.handleToggleConfirmDialog(() => {
                openInNewTab(Env.getTruckWorkOrdersUrl("/work-orders-create?prefilled=" + JSON.stringify({
                    value: item.ScheduledMaintenanceID,
                    type: item.ScheduledMaintenanceTypeID,
                    notes: item.ScheduleNotes,
                    TruckID: item?.TruckID,
                    TrailerID: item?.TrailerID,
                    Truck: item?.Truck,
                    Trailer: item?.Trailer,
                    Mileage: item?.Mileage,
                    Hours: item?.Hours
                })));

                this.handleToggleConfirmDialog();
            })
        })
    }

    /** UI Events
     ================================================================= */
    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleEditClick = (item) => {

    }

    handleLinkClick = (e) => {
        this.props.history.push(e)
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {
            behaviour: {
                canAdjustWidth: true,
                hasVirtualRows: true,
                hasMenu: true
            },
            columns: {
                Assets: {frozen: false}
            },
            classes: {}
        }, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    setOptions = (options) => {
        let newOptions = options;
        newOptions.style = {
            ...newOptions.style,
            condensed: true,
            condensedHeight: 100
        }

        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleToggleEditMileageModal = (item) => {
        this.setState({
            mileageModalOpen: !this.state.mileageModalOpen,
            selectedMileageItem: item
        })
    }

    handleToggleWoDialog = (item) => {
        this.setState({
            showWoDialog: !this.state.showWoDialog,
            selectedWoItem: item
        })

        if (item?.TruckID || item?.TrailerID) {
            this.fetchWoData(item);
        }
    }

    handleFilterWoInputChange = (name, value) => {
        this.setState({
            queryWoFields: FieldsManager.updateField(this.state.queryWoFields, name, value),
            offsetWo: 0,
            paginationPageWo: 1
        }, () => {
            this.saveFilters()
            this.fetchData(this.state.selectedWoItem)
        })
    }
    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'input', 'archived'])
    }

    getFields = () => {
        return {
            Assets: new Field('Assets', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        <div>
                            {item.TruckID && (
                                <CellLink
                                    to={`/trucks/info/${item.TruckID}`}
                                    openInNewTab={true}
                                    disabled={!checkPerm(Resources.TrucksInfo, READ_PERM)}
                                >
                                    {item.TruckID ? ("Truck " + item.Truck) : ""}
                                </CellLink>)
                            }

                            {item.TrailerID && (
                                <CellLink
                                    to={`/trailers/info/${item.TrailerID}`}
                                    openInNewTab={true}
                                    disabled={!checkPerm(Resources.Trailer, READ_PERM)}
                                >
                                    {item.TrailerID ? ("Trailer " + item.Trailer) : ""}
                                </CellLink>)
                            }
                        </div>
                    )
                }
            }),
            ScheduledMaintenanceTypeID: new Field('ScheduledMaintenanceTypeID', '', [''], false, 'select'),
            ScheduleNotes: new Field('ScheduleNotes', '', [''], false, 'textarea'),
            ScheduledInterval: new Field('ScheduledInterval', '', [''], false, 'custom', {
                omitSort: true,
                render: (item) => {
                    return (
                        <div className={"grid grid-rows-2"}>
                            <> {!!item.IsByDate && (
                                <div className={"row-start-1"}>
                                    <button
                                        className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 text-xs font-medium pointer-events-none"
                                    >
                                        <span>Every {item.DateInterval} days</span>
                                    </button>
                                    <br/>
                                </div>
                            )}
                                {!!item.IsByOdometer && !!item.TruckID && (
                                    <div className={"row-start-2"}>
                                        <button
                                            className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 text-xs font-medium pointer-events-none"
                                        >
                                            <span>Every {formatNumber(item.OdometerInterval, undefined)} mil.</span>
                                        </button>
                                        <br/>
                                    </div>
                                )}
                                {!!item.IsByHours && !!item.TrailerID && (
                                    <div className={"row-start-2"}>
                                        <button
                                            className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 text-xs font-medium pointer-events-none"
                                        >
                                            <span>Every {item.HoursInterval} engine hours</span>
                                        </button>
                                        <br/>
                                    </div>
                                )}
                            </>
                        </div>
                    )
                }
            }),
            LastService: new Field('LastService', '', [''], false, 'custom', {
                omitSort: true,
                render: (item) => {
                    return (
                        <div className={"grid grid-rows-2"}>
                            <> {!!item.IsByDate && (
                                <div className={"row-start-1"}>
                                    <button
                                        className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 text-xs font-medium pointer-events-none"
                                    >
                                        <span>{toFrontDate(item.LastDateRun)}</span>
                                    </button>
                                    <br/>
                                </div>
                            )}
                                {!!item.IsByOdometer && !!item.TruckID && (
                                    <div className={"row-start-2"}>
                                        <button
                                            className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 text-xs font-medium pointer-events-none"
                                        >
                                            <span> {formatNumber(item.LastOdometerRun, undefined)} mil.</span>
                                        </button>
                                        <br/>
                                    </div>
                                )}
                                {!!item.IsByHours && !!item.TrailerID && (
                                    <div className={"row-start-2"}>
                                        <button
                                            className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 text-xs font-medium pointer-events-none"
                                        >
                                            <span>{formatNumber(item.LastHoursRun, undefined)} engine hours</span>
                                        </button>
                                        <br/>
                                    </div>
                                )}
                            </>
                        </div>
                    )
                }
            }),
            CurrentReading: new Field('CurrentReading', '', [''], false, 'custom', {
                omitSort: true,
                render: (item) => {
                    return (
                        <CellButton
                            disabled={!item.TruckID && !item.TrailerID}
                            onClick={() => this.handleToggleEditMileageModal(item)}
                        >
                            <div className={"grid grid-rows-2"}>

                                <div className={"row-start-1"}></div>
                                {!!item.TruckID && item.Mileage && (
                                    <div className={"row-start-2"}>
                                        <button
                                            className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 text-xs font-medium pointer-events-none"
                                        >
                                            <span>{formatNumber(item.Mileage)} mil.</span>
                                        </button>
                                        <br/>
                                    </div>
                                )}

                                {!!item.TrailerID && item.Hours && (
                                    <div className={"row-start-2"}>
                                        <button
                                            className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 text-xs font-medium pointer-events-none"
                                        >
                                            <span>{formatNumber(item.Hours, undefined)} hours</span>
                                        </button>
                                        <br/>
                                    </div>
                                )}
                            </div>
                        </CellButton>
                    )
                }
            }),
            MaintenanceDue: new Field('MaintenanceDue', '', [''], false, 'custom', {
                omitSort: true,
                render: (item) => {
                    let OdometerDue = 0;
                    let HoursDue = 0;
                    let DateDueIn = null

                    if (item.TruckID) {
                        const LastOdometerRun = item.LastOdometerRun;
                        const NextService = LastOdometerRun + item.OdometerInterval;
                        OdometerDue = NextService - item.Mileage;
                    }

                    if (item.TrailerID) {
                        const LastHoursRun = item.LastHoursRun;
                        const NextService = LastHoursRun + item.HoursInterval;
                        HoursDue = NextService - item.Hours;
                    }

                    if (item.LastDateRun) {
                        const DateDue = moment(item.LastDateRun).add(Number(item.DateInterval) ?? 0, "days")
                        DateDueIn = DateDue.diff(moment(), 'days')
                    }

                    return (
                        <div className={"grid grid-rows-2"}>
                            {
                                !!item.IsByDate && (
                                    <div className={"row-start-1"}>
                                        <button
                                            className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 text-xs font-medium pointer-events-none"
                                        >
                                        <span
                                            className={classNames("text-green-600", item.IsDateDue && "text-red-500", item.IsDateWarning && "text-yellow-600")}>{(DateDueIn || DateDueIn === 0) ? formatNumber(DateDueIn, undefined) + " days" : ""}</span>
                                        </button>
                                        <br/>
                                    </div>
                                )
                            }
                            {
                                !!item.IsByOdometer && !!item.TruckID && (
                                    <div className={"row-start-2"}>
                                        <button
                                            className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 text-xs font-medium pointer-events-none"
                                        >
                                            <span
                                                className={classNames("text-green-600", item.IsOdometerDue && "text-red-500", item.IsOdometerWarning && "text-yellow-600")}>{(OdometerDue || OdometerDue === 0) ? formatNumber(OdometerDue, undefined) + " mil." : ""}</span>
                                        </button>
                                        <br/>
                                    </div>
                                )
                            }
                            {
                                !!item.IsByHours && !!item.TrailerID && (
                                    <div className={"row-start-2"}>
                                        <button
                                            className="w-full my-1 inline-flex items-center justify-between px-2.5 py-1 text-xs font-medium pointer-events-none"
                                        >
                                            <span
                                                className={classNames("text-green-600", item.IsHoursDue && "text-red-500", item.IsHoursWarning && "text-yellow-600")}>{(HoursDue || HoursDue === 0) ? HoursDue + " hours" : ""} </span>
                                        </button>
                                        <br/>
                                    </div>
                                )
                            }</div>)
                }
            })
        }
    }

    getWoFields = (item = {}) => {
        const TruckID = !!item?.TruckID;
        const TrailerID = !!item?.TrailerID;

        const workOrderStatuses = getLookup("WorkOrderStatus") ?? {};

        const CheckboxCell = ({value}) => {
            return value ? <CheckCircleIcon className="w-5 h-5 text-green-600 inline"/>
                : <XMarkIcon className="w-5 h-5 text-tm-gray-400 inline"/>
        }

        const fieldTemplates = {
            AutoCounter: new Field('AutoCounter', '', [''], false, 'custom', {
                render: (it) => {
                    return (
                        <CellLink
                            to={checkPerm(Resources.WorkOrders, UPDATE_PERM) && "/work-orders/" + it.WorkOrderID}
                            disabled={!checkPerm(Resources.WorkOrders, UPDATE_PERM)}
                        >
                            {it.AutoCounter}
                        </CellLink>
                    )
                }
            }),
            Labels: new Field('Labels', '', [''], false, 'custom', {
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
                , omitSort: true
            }),
            WorkOrderStatusID: new Field('WorkOrderStatusID', '', [''], false, 'select', {
                render: (it) => <WorkOrderStatusBadge
                    workOrderStatuses={workOrderStatuses}
                    statusID={it.WorkOrderStatusID}
                />
            }),
            TruckID: new Field('TruckID', '', TrailerID ? [''] : ['empty'], false, 'select-search', {
                render: (item) => {
                    if (!item) return null;

                    if (item.TruckID) {
                        return (
                            <CellLink
                                to={`/trucks/info/${item.TruckID}`}
                                openInNewTab={true}
                                disabled={!checkPerm(Resources.TrucksInfo, READ_PERM)}
                            >
                                {item.Truck}
                            </CellLink>
                        )
                    } else {
                        return (<span>{item.Truck}</span>)
                    }
                },
                addContainerClass: "col-span-6 col-start-1"
            }, {isClearable: true}),
            TrailerID: new Field('TrailerID', '', TruckID ? [''] : ['empty'], false, 'select-search', {
                render: (item) => {
                    if (!item) return null;

                    if (item.TrailerID) {
                        return (
                            <CellLink
                                to={`/trailers/info/${item.TrailerID}`}
                                openInNewTab={true}
                                disabled={!checkPerm(Resources.Trailer, READ_PERM)}
                            >
                                {item.Trailer}
                            </CellLink>
                        )
                    } else {
                        return item.Trailer;
                    }
                },
                addContainerClass: "col-span-6"
            }, {isClearable: true}),
            ChargeTo: new Field('ChargeTo', '', [''], false, 'custom', {
                omitSort: true,
                label: "ChargeTo",
                render: (item) => {
                    if (item.ChargeTo == 2) {
                        return ("Non-Chargeable")
                    }
                    if (item.ChargeContactID) {
                        return <CellButton
                            // onClick={() => checkPerm(Resources.Contacts, READ_PERM) && handleViewContactClick(item.ChargeContactID)}
                        >
                            {item.ChargeContact}
                        </CellButton>
                    }
                    if (item.ChargeOrganizationID) {
                        return <CellButton
                            // onClick={() => checkPerm(Resources.Contacts, READ_PERM) && handleViewOrganizationClick(item.ChargeOrganizationID)}
                        >
                            {item.ChargeOrganization}
                        </CellButton>
                    }
                }
            }),
            // UpdateReminderInterval: new Field('UpdateReminderInterval', "", [''], false, 'custom', {
            //     disableColumnFilter: true,
            //     // hideTable: true,
            //     label: "StopUpdateReminder",
            //     render: (item) => {
            //         if (!item.UpdateReminderInterval) {
            //             return <button onClick={
            //                 (e) => {
            //                     e.stopPropagation();
            //                     handleReminderUpdateClick(item)
            //                 }}
            //                            className="text-tm-gray-500 font-semibold hover:text-primary hover:bg-tm-gray-100 p-1.5 rounded-btn">
            //                 No reminder set
            //             </button>
            //         }
            //
            //         const greenZoneDiff = item.UpdateReminderInterval * 0.8;
            //         const currentDateTime = moment()
            //             .tz(getUserTimezone())
            //             .subtract(item?.UpdateReminderInterval ?? 0, 'minutes')
            //             .format(DEFAULT_DATABASE_DATETIME_FORMAT)
            //         const utcDate = moment(toFrontDateTimeFromUTC(item.LastNoteDateTime), getUserDateTimeFormat())
            //             .format(DEFAULT_DATABASE_DATETIME_FORMAT);
            //
            //         const intervalDiff = moment(utcDate).diff(currentDateTime, 'minutes');
            //
            //         let type = "warning"
            //
            //         const conversion = minutesToDayHourMinutes(Math.abs(intervalDiff))
            //
            //         let reminderText = translate("text.reminderToNote", [intervalDiff]);
            //
            //         if (!!conversion.days) {
            //             reminderText = translate(`text.reminderToNoteDHM`, [conversion.days, conversion.hours, conversion.minutes])
            //         } else if (!!conversion.hours) {
            //             reminderText = translate("text.reminderToNoteHM", [conversion.hours, conversion.minutes])
            //         }
            //
            //         if (intervalDiff < 0) {
            //             type = "danger"
            //             reminderText = translate("text.reminderDueNote", [Math.abs(intervalDiff)])
            //
            //             if (!!conversion.days) {
            //                 reminderText = translate(`text.reminderDueNoteDHM`, [conversion.days, conversion.hours, conversion.minutes])
            //             } else if (!!conversion.hours) {
            //                 reminderText = translate("text.reminderDueNoteHM", [conversion.hours, conversion.minutes])
            //             }
            //
            //         } else if (intervalDiff >= greenZoneDiff) {
            //             type = "ok"
            //         }
            //
            //         if (!item?.LastNoteDateTime) {
            //             reminderText = "Reminder is set, first update should be posted"
            //         }
            //
            //         return (
            //             <TableReminder
            //                 onClick={() => handleReminderUpdateClick(item)}
            //                 type={type}
            //                 content={reminderText}
            //                 item={item}
            //             />
            //         )
            //     }
            // }),
            Title: new Field('Title', "", [''], false, 'custom', {
                disableColumnFilter: true,
                label: "LastStopUpdate",
                render: (item) => {
                    if (!item.LastTitleNotes) {
                        return null;
                    }
                    if (item.LastNotes) {
                        return (
                            <Tippy
                                content={<span>{item.LastNotes}</span>}
                            >
                                <span>
                                    {item.LastTitleNotes} ({toFrontDateTimeFromUTC(item.LastNoteDateTime)})
                                </span>
                            </Tippy>
                        )
                    }
                    return (
                        <span>
                            {item.Title}
                        </span>
                    )
                }
            }),
            InitialComplaint: new Field('InitialComplaint', '', [''], false, 'text', {
                render: (item) => (
                    <div>{longTableColumn(item.InitialComplaint)}</div>
                )
            }),
            Notes: new Field('Notes', '', [''], false, 'text', {
                render: (item) => (
                    <div>{longTableColumn(item.Notes)}</div>
                )
            }),
            TotalAmount: new Field('TotalAmount', '', [''], false, "float", {
                render: (it) => (
                    <Tippy
                        content={
                            <div className={"text-right"}>
                                <div>Labor: {genericMoneyFormatter(it.LaborAmount)}</div>
                                <div>Parts: {genericMoneyFormatter(it.PartsAmount)}</div>
                                <div>Shop supplies: {genericMoneyFormatter(it.MiscAmount)}</div>
                                <div>Misc. surcharge: {genericMoneyFormatter(it.SurchargeAmount)}</div>
                            </div>
                        }
                    >
                        <div className={"text-right"}>
                            {genericMoneyFormatter(it.TotalAmount)}
                        </div>
                    </Tippy>
                ),
                colFilter: true
            }, {icon: false}),
            IsInvoiced: new Field('IsInvoiced', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsInvoiced}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsAuthorized: new Field('IsAuthorized', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsAuthorized}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            CreatedDate: new Field('CreatedDate', '', [], false, 'datetimezone'),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone'),
            UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'select-search')
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getMileageFields = (item = {}) => {
        const fieldTemplates = {
            Mileage: new Field('Mileage', item?.TruckID ? item?.Mileage : item?.Hours, ['empty'], false, 'integer', {
                addContainerClass: 'col-span-3',
                label: item?.TruckID ? "Odometer" : "EngineHours"
            }),
            OdometerDate: new Field('OdometerDate', '', ['empty'], false, 'datetime', {addContainerClass: 'col-span-3'}),
            // OdometerMethodID: new Field('OdometerMethodID', '', [''], false, 'select-search', {addContainerClass: 'col-span-3'})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'}),
            ScheduledMaintenanceIDs: new Field('ScheduledMaintenanceIDs', '', [''], false, 'select-search', {}, {
                multi: true,
                all: true
            }),
            ...(!(!!this.props?.TruckID || !!this.props?.TrailerID) && {
                TruckIDs: new Field('TruckIDs', '', [''], false, 'select-search', {}, {
                    multi: true,
                    all: true
                }),
                TrailerIDs: new Field('TrailerIDs', '', [''], false, 'select-search', {}, {
                    multi: true,
                    all: true
                })
            }),
            IsUpcoming: new Field('IsUpcoming', '', [''], false, 'checkbox', {}, {}),
            IsDue: new Field('IsDue', '', [''], false, 'checkbox', {}, {})
        }
    }

    getQueryWoFields = (translate) => {
        return (
            Object.assign(
                getDefaultQueryFields("WorkOrderID", translate),
                {}
            )
        )
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...(!!this.props?.TruckID && {
                TruckIDs: [this.props?.TruckID]
            }),
            ...(!!this.props?.TrailerID && {
                TrailerIDs: [this.props?.TrailerID]
            }),

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    getWoQuery = (item) => {
        return {
            sort: this.state.sortWo,
            sortBy: this.state.sortByWo,
            offset: this.state.offsetWo,
            TruckID: item.TruckID ?? "",
            TrailerID: item.TrailerID ?? "",
            ScheduledMaintenanceID: item.ScheduledMaintenanceID ?? "",

            ...FieldsManager.getFieldKeyValues(this.state.queryWoFields)
        }
    }

    getResource = () => {
        return Resources.WorkOrderUpcomingMaintenance
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, secondResource} = this.props
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const secondData = getProp(secondResource.data, 'list', [])
        const isSecondLoading = getProp(secondResource, 'isLoading', true)
        const secondCount = getProp(secondResource.data, 'count', 0)

        const selects = {
            ScheduledMaintenanceIDs: {
                api: 'api/' + Resources.WorkOrderScheduledMaintenance,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.ScheduledMaintenanceID,
                    label: item.ScheduledMaintenanceType + ", " + item.ScheduleNotes
                })
            },
            TruckIDs: {
                api: 'api/' + Resources.TrucksQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.TruckID,
                    label: item.Truck
                })
            },
            TrailerIDs: {
                api: 'api/' + Resources.TrailersQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.TrailerID,
                    label: item.Trailer
                })
            }
        }

        const {style: {condensed, condensedHeight, ...restStyle}, style, ...restParent} = this.state.tableOptions;

        const tableOptions = {
            ...restParent,
            style: restStyle
        };

        return (
            <React.Fragment>
                <div className="sm:flex mb-2">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />

                    <div className="ml-auto flex sm:justify-start justify-end items-center">
                        <div className={'flex mr-4'}>
                            <TableSettingsPopOver
                                options={tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>
                </div>

                <TableCard addClass={'relative'}>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        isLoading={resource.isLoading}
                        selects={selects}
                    />

                    <ResourceTable
                        data={data}
                        fields={this.getFields()}
                        // verticalTableIsVisible={this.props.breakpoint.index <= 1}

                        translate={translate}
                        isLoading={resource.isLoading}

                        options={this.state.tableOptions}

                        limit={this.state.queryFilterFields.limit.value}

                        saveTableOptions={this.setOptions}
                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onView={(item) => !checkPerm(this.getResource(), UPDATE_PERM) ? this.handleToggleWoDialog(item) : null}
                        actions={[
                            {
                                action: (item) => {
                                    this.createWO(item)
                                },
                                title: "Create work order",
                                order: 20,
                                icon: PlusCircleIcon,
                                visible: () => checkPerm(Resources.WorkOrders, CREATE_PERM)
                            }
                        ]}
                    />

                    {/*Table footer*/}
                    <TableCardFooter
                        show={!(!data.length && !resource.isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={resource.isLoading}
                            hideRowsPerPage={false}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>

                    <NoRecords
                        show={(data.length === 0) && !resource.isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                </TableCard>

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                <ModalSaveResource
                    show={this.state.mileageModalOpen}
                    title={this.state.selectedMileageItem?.TruckID ? translate("text.update_odometer") : translate("text.update_engine_hours")}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={() => this.handleToggleEditMileageModal()}
                    errorMessage={this.state.errorMessage}
                    fields={this.getMileageFields(this.state.selectedMileageItem)}
                    onSubmit={(params) => {
                        if (params) {
                            if (this.state.selectedMileageItem?.TruckID) {
                                params.TruckID = this.state.selectedMileageItem?.TruckID
                            }

                            if (this.state.selectedMileageItem?.TrailerID) {
                                params.TrailerID = this.state.selectedMileageItem?.TrailerID
                            }

                            if (params.TrailerID) {
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: Resources.TrailerMileage,
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: "Engine hours updated"
                                }));
                                this.handleToggleEditMileageModal(null)
                            } else if (params.TruckID) {
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: Resources.TrucksMileage,
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: "Odometer updated"
                                }));
                                this.handleToggleEditMileageModal(null)
                            }
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata}
                />

                <ModalDefault
                    title={translate("title.related_work_orders")}
                    show={this.state.showWoDialog}
                    onClose={this.handleToggleWoDialog}
                    translate={translate}
                    widthClass={"max-w-6xl"}
                >

                    {(isSecondLoading || !!secondCount) && (
                        <ResourceTable
                            data={secondData}
                            fields={this.getWoFields()}
                            queryFields={this.state.queryWoFields}

                            options={{
                                style: {
                                    stripedRows: true,
                                    horizontalLines: true,
                                    verticalLines: true,
                                    floatingActions: true
                                }
                            }}


                            translate={translate}
                            isLoading={isSecondLoading}
                        />
                    )}

                    {/*{!isSecondLoading && !secondCount && (*/}
                    <NoRecordsTable
                        show={(secondData.length === 0) && !isSecondLoading}
                        canCreate={false}
                        title={'No matching records found'}
                        className={"pb-12 pt-16 px-6"}
                    />
                    {/*)}*/}

                    <TableCardFooter
                        show={!(!secondData.length && !isSecondLoading)}
                    >
                        <Pagination
                            count={secondCount}
                            isLoading={isSecondLoading}
                            handleQueryChange={this.handleFilterWoInputChange}
                            queryFields={this.state.queryWoFields}
                            translate={translate}
                        />
                    </TableCardFooter>
                </ModalDefault>
            </React.Fragment>
        )
    }
}

export default connect(state => state)(UpcomingMaintenanceTab)
