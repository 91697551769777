import TableCard from "../../../../../common/components/resource-table/table-components/table-card";
import FieldsToHtml from "../../../../../common/components/fields/fields-to-html";
import Resources from "../../../../../data/services/resources";
import ResourceTable from "../../../../../common/components/resource-table";
import {Field, FieldsManager} from "../../../../../data/services/fields";
import DriverStatusBadge from "../../../../../common/components/badge/driver-status-badge";
import EmployeeStatusBadge from "../../../../../common/components/badge/employee-status-badge";
import NoRecords from "../../../../../common/components/no-records-found/no-records";
import Pagination from "../../../../../common/components/resource-table/table-components/pagination";
import React, {useEffect, useRef, useState} from "react";
import {
    DEFAULT_QUERY_LIMIT,
    LOAD_PAY_TO_OPTION_DRIVER_OR_TUCK,
    LOAD_PAY_TO_OPTION_TRUCK
} from "../../../../../common/util/util-consts";
import {useDispatch, useSelector} from "react-redux";
import {getThirdResource} from "../../../../../data/actions/thirdResource";
import {getUser} from "../../../../../common/util/util-auth";
import TableCardFooter from "../../../../../common/components/resource-table/table-components/table-card-footer";
import {classNames, getDefaultTableOptions} from "../../../../../common/util/util-helpers";
import {TruckIcon, UsersIcon} from "@heroicons/react/24/outline";
import TrucksTableBadge from "../../../../fleet/trucks-view/trucks-table-badge";
import useQueryNew from "../../../../../hooks/use-query-new";

export default function AddEntryTable({translate, handleAddEntry}) {
    const isInitialMount = useRef(true);
    const dispatch = useDispatch();
    const settingsResource = useSelector(state => state.resource);
    const initialTab = settingsResource?.data?.settings.LoadPayToOptionID === LOAD_PAY_TO_OPTION_TRUCK
    || (settingsResource?.data?.settings?.LoadPayToOptionID === LOAD_PAY_TO_OPTION_DRIVER_OR_TUCK && settingsResource?.data?.settings?.LoadDefaultPayID === LOAD_PAY_TO_OPTION_TRUCK)
        ? "Trucks" : "Contacts";

    const PAYROLL_BATCH_TYPES = [
        {label: 'Drivers', value: 'Drivers'},
        {label: 'Agents', value: 'Agents'},
        {label: 'Employees', value: 'Employees'}
    ];

    const resource = useSelector(state => state.thirdResource);
    const contactsData = resource?.data?.list ?? [];
    const contactsCount = resource?.data?.count ?? 0;

    const isLoading = resource.isLoading;
    const [tabs, setTabs] = useState(() => getTabs());
    const LoadPayToOptionID = settingsResource?.data?.settings?.LoadPayToOptionID;
    const hasTabsVisible = LoadPayToOptionID === 3;

    const selectedTab = tabs.find(it => it.isActive)?.id ?? "";
    const areContactFilters = selectedTab === 'Contacts';

    const [tableOptions, setTableOptions] = useState(() => getTableOptions());

    const [queryFields, setQueryFields] = useQueryNew(getQueryFields(), 'test_key', true);

    function fetchData() {
        dispatch(getThirdResource({
            user: getUser(),
            query: getQuery(queryFields),
            resource: selectedTab === "Contacts" ? Resources.Contacts : Resources.Trucks
        }));
    }

    function handleTabChange(selectedTab) {
        const tabsUpdate = tabs.map(tab => {
            tab.isActive = tab.id === selectedTab.id
            return tab;
        });

        setTabs(tabsUpdate);
    }

    function getQueryFields() {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            Groups: new Field('Groups', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            PayrollBatchTypes: new Field('PayrollBatchTypes', '', [''], false, areContactFilters ? 'select' : 'hidden', {}, {
                isClearable: true,
                options: PAYROLL_BATCH_TYPES
            }),
            HighImportance: new Field('HighImportance', '', [''], false, areContactFilters ? 'checkbox' : 'hidden'),
            sort: new Field('sort', '', [''], false, 'text', {}, {}),
            sortBy: new Field('sortBy', 'FirstName', [''], false, 'text', {}, {}),
            offset: new Field('offset', "0", [''], false, 'text', {}, {}),

            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                hideLabel: true,
                labelType: "float"
            }, {menuPlacement: 'top'})
        }
    }

    function getContactFields() {
        return {
            FirstName: new Field('FirstName', '', ['empty']),
            LastName: new Field('LastName', '', ['empty']),
            Email: new Field('Email', '', ['empty', 'email']),
            Status: new Field('Status', '', [''], false, 'custom', {
                omitSort: true,
                render: (item) => {
                    if (!item.DriverStatusID
                        && !item.EmployeeStatusID
                        && !item.AgentStatusID
                    ) {
                        return null
                    }

                    item.StatusID = item.DriverID ?
                        item.DriverStatusID : (item.EmployeeID ? item.EmployeeStatusID : item.AgentStatusID)
                    if (item.DriverID) {
                        return <DriverStatusBadge item={item}
                                                  translate={translate}/>
                    } else {
                        return <EmployeeStatusBadge item={item}
                                                    translate={translate}/>
                    }
                }
            }),
            DriverPositionTypeID: new Field('DriverPositionTypeID', '', [], false, 'select')
        }
    }

    function getTruckFields() {
        return {
            TruckNumber: new Field('TruckNumber', '', [''], false, 'custom', {
                    render: (it) => <button
                        className="text-primary hover:text-tm-gray-900 hover:bg-tm-gray-200 h-12 w-full text-left px-2"
                        onClick={(e) => {
                            e.stopPropagation();
                            window.open(`${window.location.origin}/trucks/info/${it.TruckID}`, '_blank');
                        }}
                    >
                        {it.TruckNumber}
                    </button>
                }
            ),
            PayToOrganization: new Field('PayToOrganization', '', [''], false, "text"),
            StatusID: new Field('StatusID', '', [''], false, 'custom', {
                render: (item) => (
                    <TrucksTableBadge
                        item={item}
                        translate={translate}
                    />
                )
            }),
            VIN: new Field('VIN', '', ['']),
            TruckLicenceNumber: new Field('TruckLicenceNumber', '', [''], false, "text"),
            Model: new Field('Model', '', ['']),
            Year: new Field('Year', '', [''], false, 'text'),

            ContactGroup: new Field('ContactGroup', '', [], false, 'text', {label: "FleetGroupID"})
        }
    }

    function handleQueryInputChange(name, value) {
        let queryFieldsUpdate = Object.assign({}, queryFields);

        if ("sortBy" === name && queryFieldsUpdate.sortBy.value === value) {
            queryFieldsUpdate.sort.value = queryFieldsUpdate.sort.value === 'DESC' ? 'ASC' : 'DESC';
        }

        queryFieldsUpdate = FieldsManager.updateField(queryFieldsUpdate, name, value);

        setQueryFields(queryFieldsUpdate)
    }

    function getTabs() {
        return [
            {
                id: "Contacts",
                name: "Drivers / Agents / Employees",
                icon: UsersIcon,
                ref: React.createRef(),
                isActive: initialTab === 'Contacts'
            },
            {
                id: "Trucks",
                name: 'Trucks',
                icon: TruckIcon,
                ref: React.createRef(),
                isActive: initialTab === 'Trucks'
            }
        ]
    }

    function getTableOptions() {
        return getDefaultTableOptions(selectedTab === 'Contacts' ? getContactFields() : getTruckFields(), {
            behaviour: {
                hasVirtualRows: true
            },
            style: {
                condensed: true,
                horizontalLines: true,
                verticalLines: true,
                headerHeightClass: 'h-8',
                floatingActions: true,
                frozenActionColumn: true,
                isGPUAccelerated: true
            },
        }, "add_entry_table", translate)
    }

    function getQuery(queryFields) {
        const query = FieldsManager.getFieldKeyValues(queryFields);

        query.IsDriver = query.PayrollBatchTypes.value === 'Drivers' ? 1 : 0;
        query.IsAgent = query.PayrollBatchTypes.value === 'Agents' ? 1 : 0;
        query.Employees = query.PayrollBatchTypes.value === 'Employees' ? 1 : 0;
        query.IncludeStatuses = 1;
        delete query.PayrollBatchTypes;

        return query;
    }

    useEffect(() => {
        const activeTab = tabs.find(it => it.isActive)
        if (activeTab?.ref?.current) {
            activeTab.ref.current.focus();
        }

        fetchData();
    }, [queryFields]);

    useEffect(() => {
        if (isInitialMount.current === false) {
            setQueryFields(getQueryFields());
            setTableOptions(getTableOptions());
        }

        isInitialMount.current = false;
    }, [tabs]);

    return (
        <div className="flex relative pb-3">
            {hasTabsVisible && (
                <div className="w-48 flex-shrink-0 h-full sticky top-0 pr-4">
                    <div className="py-6 pl-2 space-y-2">
                        {tabs.map(tab => {
                            return <button
                                key={tab.id}
                                ref={tab.ref}
                                onClick={() => handleTabChange(tab)}
                                className={
                                    classNames(
                                        tab.isActive ? "text-tm-gray-800 bg-primary-transparent" : "text-tm-gray-500",
                                        "hover:text-tm-gray-900 w-full text-left flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold focus:outline-none focus:ring-2 focus:ring-primary"
                                    )
                                }
                            >
                                <tab.icon className='w-5 h-5 flex-shrink-0'/>

                                {tab.name}
                            </button>
                        })}
                    </div>
                </div>
            )}
            <div
                className={
                    classNames(hasTabsVisible ? 'max-w-[calc(100%-12rem)]' : 'max-w-full',
                        'space-y-5 flex-grow'
                    )
                }
            >
                <div className={'space-y-5'}>
                    <div className="">
                        <TableCard addClass={'rounded-none'}>
                            <div className="grid grid-cols-4 gap-4 px-6 pt-2 pb-4 border-b border-tm-gray-300">
                                <FieldsToHtml
                                    fieldsState={queryFields}
                                    excludeFields={['limit', 'sort', 'sortBy', 'offset']}
                                    onInputChange={handleQueryInputChange}
                                    translate={translate}
                                    selects={{
                                        Groups: {
                                            api: 'api/' + Resources.ContactGroupsQuick,
                                            query: {},
                                            searchMap: (item) => ({
                                                label: item.ContactGroupName,
                                                value: item.ContactGroupID
                                            })
                                        }
                                    }}
                                />
                            </div>

                            <ResourceTable
                                maxHeightClass={"max-h-[calc(100vh-23rem)]"}
                                data={contactsData}
                                count={contactsCount}
                                fields={selectedTab === 'Contacts' ? getContactFields() : getTruckFields()}
                                options={tableOptions}
                                translate={translate}
                                isLoading={isLoading}

                                onRowClick={handleAddEntry}
                                queryFields={queryFields}

                                onSortChange={(colName) => handleQueryInputChange("sortBy", colName)}
                            />

                            <NoRecords
                                addClass={'mt-5'}
                                show={(contactsData.length === 0) && !isLoading}
                                title={translate('text.no_records')}
                            />

                            <TableCardFooter
                                addClass=""
                                show={!(!contactsData.length && !isLoading)}
                            >
                                <Pagination
                                    count={contactsCount}
                                    isLoading={isLoading}
                                    handleQueryChange={
                                        (name, value) => handleQueryInputChange(name, value)
                                    }
                                    queryFields={queryFields}
                                    translate={translate}
                                />
                            </TableCardFooter>
                        </TableCard>
                    </div>
                </div>
            </div>
        </div>
    )
}