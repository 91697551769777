import React, {Component} from 'react'
import {connect} from 'react-redux'
import {deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getLookup,
    getProp, getSystemName,
    mergeDeep,
    returnOffsetAndPagination,
    saveTableColumns,
    verifyFilterFields,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import {download} from '../../../data/actions/download'
import {showGlobalModal} from '../../../data/actions/ui'
import {ArchiveBoxIcon, PencilIcon} from '@heroicons/react/24/outline'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DELETE_PERM,
    NUMBER_OF_AXLES,
    READ_PERM,
    SCAC_CODE_FIELD_LENGTH,
    TRUCK_STATUS_TYPES,
    UPDATE_PERM, VIN_CODE_FIELD_LENGTH
} from '../../../util/util-constants'
import {currentDate, toFrontDate, toFrontDateTimeFromUTC} from '../../../common/util/util-dates'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import TrucksTableBadge from "../trucks-view/trucks-table-badge";
import {getSecondResource} from "../../../data/actions/secondResource";
import Layout from "../../../common/components/layout";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import BulkBatchEdit from "../../../common/components/modal/bulk-batch-edit";
import ResourceTable from "../../../common/components/resource-table";
import Tooltip from "../../../common/components/tooltip";
import ModalDefault from "../../../common/components/modal/modal-default";
import LocationRadiusSearch from "../../../common/components/modal/location-radius-search";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import FleetShareTableMap
    from "../../../common/components/command-center-table/fleet-share-table/fleet-share-table-map";
import TrucksTableBadgeRental from "../trucks-view/trucks-table-badge-rental";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import TableTagManager from "../../../common/components/resource-table/table-components/table-tag-manager";
import {ChevronUpIcon as ChevronUpSolidIcon} from "@heroicons/react/20/solid";
import moment from "moment";
import {Loader} from "../../../common/components/loader";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import {clearThirdResource, getThirdResource} from "../../../data/actions/thirdResource";
import Button from "../../../common/components/button";
import {numberWithCommas} from "../../../common/util/util-formaters";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";

class TrailersView extends Component {
    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1)
        this.tablePageDefaults = {
            behaviour: {
                rowSelect: checkPerm(Resources.Trailer, UPDATE_PERM),
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'TrailerNumber',

            // Fields
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),

            // Dialog(s)
            selectedItem: null,
            confirmDialog: false,
            confirmDialogHideAnimation: false,

            // Batch actions
            selectedItems: {},
            selectedRows: {},
            batchActionDialog: false,
            batchActionDialogHideAnimation: false,
            batchFields: this.getBatchFields(),
            breakpoint: {},
            isTableFullScreen: false,
            isMapModalOpen: false,
            lastLocation: null,
            DecodeVINModalOpen: false
        }

        this.state = mergeDeep(this.state, verifyFilterFields(
            LocalStorage.rehydrateState(this.pagePath),
            this.state.queryFilterFields
        ));
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
        this.fetchCompanyData();
    }

    componentDidUpdate(prevProps) {
        if (!!this.props.resource.data && !this.props.resource.isLoading && prevProps.resource.isLoading) {
            const data = getProp(this.props.resource.data, 'list', [])

            if (!this.state.queryFilterFields.archived.value) {
                const rows = Object.values(Object.assign({}, this.state.selectedRows)).reduce((memo, it) => {

                    data.filter((item) => {
                        if (item.TrailerID === it.TrailerID) {
                            if (!item.ArchivedDate) {
                                memo[it.TrailerID] = item;
                            }
                        }
                    })

                    return memo;
                }, {})
                this.setSelectedRows(rows)
            }
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    fetchVINData = (VIN) => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            resource: Resources.DecodeVIN,
            query: {VIN: VIN}
        }))
    }

    fetchCompanyData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.Company
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'trailers_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    downloadExcelTemplate = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'trailers_template' + currentDate() + '.xlsx'
            }, this.getQuery(), {excelTemplate: 1})
        }))
    }

    archiveResource = (item) => {
        this.setState({text: this.props.translate('message.confirm_archive_trailer')}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, {id: item[this.getPrimaryKey()]}),
                        piggyQuery: this.getQuery(),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [item.TrailerNumber]),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))

                    if (!this.state.queryFilterFields.archived.value) {
                        const rows = Object.values(Object.assign({}, this.state.selectedRows)).reduce((memo, it) => {
                            if (it.TrailerID !== item.TrailerID) {
                                memo[it.TrailerID] = it;
                            }
                            return memo;
                        }, {})
                        this.setSelectedRows(rows)
                    }
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {
        this.setState({text: `${this.props.translate('message.are_you_sure_restore_trailer')} ${item.TrailerNumber}`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            id: item[this.getPrimaryKey()],
                            ArchivedDate: 1
                        },
                        query: this.getQuery(),
                        errorMessage: true, successMessage: `Trailer ${item.TrailerNumber} restored`,
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    handleBulkEdit = (fields) => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {
                IDs: Object.keys(this.state.selectedRows),
                Fields: FieldsManager.getFieldKeyValues(fields)
            },
            resource: Resources.TrailersBulkEdit,
            query: this.getQuery(),
            piggyResource: this.getResource()
        }))
        this.handleCloseBatchActionDialog()
        this.handleResetBatchFields()
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'TrailerNumber'
        }
        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        if (name === 'radius' && value === true) {
            this.handleToggleLocationRadiusSearchDialog()
        } else {
            this.setState({
                queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
                offset: 0,
                paginationPage: 1
            }, () => {
                this.saveFilters()
                this.fetchData()
            })
        }
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    toggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    handleQuickView = (item) => {
        this.props.dispatch(showGlobalModal('ViewTrailerCard', item.TrailerID))
    }

    handleActionUpdate = (item = null) => {
        this.handleLinkClick(`/trailers/info/${item[this.getPrimaryKey()]}`)
    }

    handleOnLoadClick = item => {
        this.handleLinkClick(`/loads/info/${item.OnLoad}`)
    }

    handleCreateUpdateResource = () => {
        this.handleLinkClick('/trailer/create')
    }

    handleCloseBatchActionDialog = () => {
        this.setState({batchActionDialog: false})
    }

    handleResetBatchFields = () => {
        this.setState({
            selectedDrivers: {},
            batchFields: this.getBatchFields()
        })
    }

    handleToggleLocationRadiusSearchDialog = () => {
        this.setState({LocationRadiusSearchDialog: !this.state.LocationRadiusSearchDialog})
    }

    handleSelectRowClick = (item, event) => {
        const itemID = item.TrailerID;

        const data = getProp(this.props.resource.data, 'list', [])
        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedItems = this.state.selectedItems
        if (event?.nativeEvent?.shiftKey) {
            const firstSelectedItem = data.findIndex(it => selectedRows[it.TrailerID])
            const lastSelectedItem = data.findIndex(it => it.TrailerID === itemID)

            if (firstSelectedItem > -1 && lastSelectedItem > -1) {
                if (firstSelectedItem < lastSelectedItem) {
                    for (let i = firstSelectedItem; i < lastSelectedItem + 1; i++) {
                        Object.assign(selectedRows, {[data[i].TrailerID]: data[i].TrailerID})
                        selectedItems[data[i].TrailerID] = data[i]
                    }
                } else {
                    for (let i = lastSelectedItem; i < firstSelectedItem + 1; i++) {
                        selectedItems[data[i].TrailerID] = data[i]
                        Object.assign(selectedRows, {[data[i].TrailerID]: data[i].TrailerID})
                    }
                }
            }
        } else {
            if (selectedRows[itemID]) {
                delete selectedItems[itemID]
                delete selectedRows[itemID]
            } else {
                selectedItems[itemID] = data.find(it => it.TrailerID === itemID)
                Object.assign(selectedRows, {[itemID]: item})
            }
        }

        this.setState({selectedRows, selectedItems})
    }

    handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(this.props.resource.data, 'list', []))

        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedItems = this.state.selectedItems

        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                selectedItems[it.TrailerID] = it
                memo[it.TrailerID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.TrailerID.toString())) {
                    delete selectedItems[it.TrailerID]
                    delete selectedRows[it.TrailerID]
                }
            })
        }

        this.setState({selectedRows})
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), this.tablePageDefaults, this.props.translate);
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleLinkClick = (e) => {
        this.props.history.push(e)
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    /** Fields
     ================================================================= */
    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            StatusIDs: new Field('StatusIDs', '', [''], false, 'multi-select', {}, {
                isMulti: true,
                all: true
            }),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            NotSoldRetired: new Field('NotSoldRetired', 1, [''], false, 'checkbox'),
            radius: new Field('radius', '', [''], false, 'checkbox'),
            TrailerOwnershipTypeIDs: new Field('TrailerOwnershipTypeIDs', '', [''], false, 'multi-select', {}, {
                all: true,
                isMulti: true
            }),
            TrailerTypeID: new Field('TrailerTypeID', '', [''], false, 'select', {label: "type"}, {all: true}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            }),
        }
    }

    getFields = () => {
        return {
            TrailerNumber: new Field('TrailerNumber', '', ['empty']),
            Labels: new Field('Labels', '', [''], false, 'custom', {
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag} addClass="py-0.5 mx-2"/>
                , omitSort: true
            }),
            ...(getSystemName() === 'main' && {
                StatusID: new Field('StatusID', '', [''], false, 'custom', {
                    render: (item) => (
                        <TrucksTableBadge
                            itemType="trailer"
                            item={item}
                            translate={this.props.translate}
                        />
                    )
                })
            }),
            ...(getSystemName() === 'rental' && {
                RentStatusID: new Field('RentStatusID', '', [''], false, 'custom', {
                    render: (item) => (
                        <TrucksTableBadgeRental
                            item={item}
                            translate={this.props.translate}
                            itemType={'Trailer'}
                        />
                    )
                })
            }),
            LastGPSLocation: new Field('LastGPSLocation', '', [''], false, 'custom', {
                render: (item) => {
                    if (item.LocationDate) {
                        const LocationDate = moment(item.LocationDate);
                        const currentDate = moment();

                        const dateDifference = moment.duration(LocationDate.diff(currentDate)).asDays();

                        return (<CellButton
                            onClick={() => {
                                this.setState({isMapModalOpen: true, lastLocation: item})
                            }}
                        >
                            <Tippy content={this.props.translate("text.GPS_older_than_7_days")} disabled={dateDifference > -7}>
                                <div className={classNames("text-left", (dateDifference < -7) ? "text-red-500" : 'text-primary')}>
                                    {item?.CityName || item?.State || item?.PostalCode ?
                                        <div>{item?.CityName ? item.CityName + ", " : ""} {item?.GPSState} {item?.PostalCode}</div> : ""}
                                    {(item.LocationDate ?
                                        <div>({toFrontDateTimeFromUTC(item.LocationDate)})</div> : undefined)}
                                </div>
                            </Tippy>
                        </CellButton>)
                    } else {
                        return (<div className="flex items-center">No data</div>)
                    }
                }, omitSort: true
            }),
            Mileage: new Field('Mileage', '', [''], false, 'custom', {
                render: (item) => {
                    if (item.Mileage) {
                        return (
                            <div>

                                {
                                    <div>{numberWithCommas(item.Mileage, 0) ?? ""} hours {item.OdometerDate ? "(" + toFrontDate(item.OdometerDate) + ")" : ''}</div>}
                            </div>
                        )

                    }
                }, label: "EngineHours"
            }),
            // SCAC: new Field('SCAC', '', ['five_char_max'], false, 'text', {hideTable: !getProp(this.props.secondResource.data, 'UseMultiSCAC', '')}),
            InServiceDate: new Field('InServiceDate', '', [''], false, 'date', {}),
            Manufacturer: new Field('Manufacturer', '', ['']),
            Model: new Field('Model', '', ['']),
            Year: new Field('Year', '', [''], false, 'text'),
            TrailerTypeID: new Field('TrailerTypeID', '', [''], false, 'select', {label: "type"}),
            AssetCompanyStatus: new Field('AssetCompanyStatus', '', [''], false, 'select'),
            TrailerLength: new Field('TrailerLength', '', ['float_or_empty'], false, 'float'),
            TrailerHeight: new Field('TrailerHeight', '', ['float_or_empty'], false, 'float'),
            VIN: new Field('VIN', '', ['']),
            TrailerLicenceNumber: new Field('TrailerLicenceNumber', '', ['']),
            Office: new Field('Office', '', [''], false, 'text'),
            ContactGroup: new Field('ContactGroup', '', [], false, 'text', {label: "FleetGroupID"}),
            TrailerOwnershipTypeID: new Field('TrailerOwnershipTypeID', '', [''], false, 'select'),
            CurrentMarkerValue: new Field('CurrentMarkerValue', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3',
                render: (it) => {
                    if (it.CurrentMarkerValue) {
                        return genericMoneyFormatter(it.CurrentMarkerValue)
                    } else {
                        return ''
                    }
                }
            }),
            CreateUpdateDate: new Field('CreateUpdateDate', '', ['empty'], false, 'datetimezone')
        }
    }

    getBatchFields = () => {
        return {
            SCAC: new Field('SCAC', '', [''], false, 'five_char_max', {}, {
                upperCaseOnly: true,
                max: SCAC_CODE_FIELD_LENGTH
            }),
            Notes: new Field('Notes', '', [], false, 'textarea'),
            TrailerTypeID: new Field('TrailerTypeID', '', [''], false, 'select'),
            Year: new Field('Year', '', [''], false, 'select'),
            Manufacturer: new Field('Manufacturer', '', ['']),
            Model: new Field('Model', '', ['']),
            TrailerLicenceNumber: new Field('TrailerLicenceNumber', '', ['']),
            OfficeID: new Field('OfficeID', '', [''], false, 'select-search'),
            TrailerOwnershipTypeID: new Field('TrailerOwnershipTypeID', '', [''], false, 'select'),
            TrailerLength: new Field('TrailerLength', '', ['float_or_empty'], false, 'float'),
            TrailerHeight: new Field('TrailerHeight', '', ['float_or_empty'], false, 'float'),
            CarbID: new Field('CarbID', '', ['']),
            TrailerUnloadedWeight: new Field('TrailerUnloadedWeight', '', [''], false, 'float_or_empty'),
            TrailerGrossWeight: new Field('TrailerGrossWeight', '', [''], false, 'float_or_empty'),
            TrailerNumberOfAxles: new Field('TrailerNumberOfAxles', '', [''], false, 'select', {}, {
                values: NUMBER_OF_AXLES
            }),
            ContactGroupID: new Field('ContactGroupID', '', [''], false, 'select-search')
        }
    }

    getDecodeVINFields = () => {
        return {
            DecodeVIN: new Field('DecodeVIN', '', ['length_===_17'], false, 'text', {addContainerClass: 'col-span-3'}, {
                max: VIN_CODE_FIELD_LENGTH,
                upperCaseOnly: true,
                numbersAndLettersOnly: true
            }),
        }
    }

    getResource = () => {
        return Resources.Trailers
    }

    getPrimaryKey = () => {
        return 'TrailerID'
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    setSelectedRows = (selectedRows) => {
        this.setState({selectedRows})
    }

    handleDecodeVIN = () => {
        this.setState({
            DecodeVINModalOpen: !this.state.DecodeVINModalOpen
        })
        this.props.dispatch(clearThirdResource());
    }
    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...this.state.queryFilterFields.radius.value,
            searchFields: JSON.stringify({
                OutOfServiceID: this.state.queryFilterFields.OutOfService?.value ? 1 : 0,
                OnLoadID: this.state.queryFilterFields.NotOnLoad?.value ? 1 : 0,
                TrailerOwnershipTypeID: this.state.queryFilterFields.TrailerOwnershipTypeID?.value,
                TrailerTypeID: this.state.queryFilterFields.TrailerTypeID?.value,
            }),
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    saveFilters = () => {
        let filters = []
        Object.keys(this.getQuery()).map(it => filters.push(it))
        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource,  thirdResource} = this.props
        const data = getProp(resource.data, 'list', [])
        const dataVIN = getProp(thirdResource, 'data', [])
        const isLoadingThird = getProp(thirdResource, 'isLoading', false)
        const count = getProp(resource.data, 'count', 0)
        const areSomeItemsSelected = !!Object.keys(this.state.selectedRows).length
        const isLoading = !!resource.isLoading;

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.trailers')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleCreateUpdateResource()}
                        hasPerm={checkPerm(Resources.Trailers, CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    >
                        <div className="ml-auto flex">
                            <Button
                                isLoading={isLoading}
                                onClick={() => {
                                    this.handleDecodeVIN();
                                }}
                            >
                                {translate('text.decode_vin')}
                            </Button>
                        </div>
                    </PageHeader>

                    <div className="sm:flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex sm:justify-start justify-end items-center">
                            {/*<Tooltip content={translate('text.download_template')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcelTemplate}
                                >
                                    <RectangleGroupIcon className="w-5 h-5"/>
                                </button>
                            </Tooltip>*/}

                            <Tooltip content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tooltip>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>


                    <TableCard
                        addClass={
                            classNames(
                                this.state.isTableFullScreen
                                    ? "fixed inset-1 -top-1 z-50"
                                    : undefined
                            )}
                    >
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            onFullScreenToggle={this.handleFullScreenToggle}
                            isTableFullScreen={this.state.isTableFullScreen}
                            isLoading={isLoading}
                            selects={{
                                StatusIDs: TRUCK_STATUS_TYPES,
                                TrailerOwnershipTypeIDs: getLookup('TrailerOwnershipType'),
                            }}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}
                            saveTableOptions={this.setOptions}

                            onRowClick={checkPerm(Resources.Trailer, UPDATE_PERM) ? this.handleActionUpdate : null}

                            onView={this.handleQuickView}
                            onEdit={this.handleActionUpdate}
                            onRestore={this.restoreFromArchive}

                            hasViewPerm={checkPerm(Resources.Trailer, READ_PERM)}
                            hasEditPerm={checkPerm(Resources.Trailer, UPDATE_PERM)}
                            hasRestorePerm={checkPerm(this.getResource(), UPDATE_PERM)}

                            actions={[
                                {
                                    action: (it) => {
                                        (it.StatusID >= 5) && this.archiveResource(it)
                                    },
                                    icon: ArchiveBoxIcon,
                                    visible: (it) => checkPerm(this.getResource(), DELETE_PERM) && !it.ArchivedDate,
                                    label: false,
                                    order: 30,
                                    disabled: false,
                                    title: (it) => {
                                        return it.StatusID >= 5 ? translate("btn.archive") : translate("Active asset can't be archived")
                                    },
                                    class: false,
                                    iconClass: (it) => {
                                        return it.StatusID >= 5 ? "w-5 h-5" : "w-5 h-5 opacity-30"
                                    }

                                }
                            ]}
                            onSelectRow={this.handleSelectRowClick}
                            selectedRows={this.state.selectedRows}
                            onSelectAllClick={this.handleSelectAllClick}
                            tableKey={'TrailerID'}
                        />

                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                        <TableBulkActions
                            selectedRows={this.state.selectedRows}
                            tableKey={'TrailerID'}
                            fields={this.getFields()}
                            translate={translate}
                            options={this.state.tableOptions}
                            setSelectedRows={this.setSelectedRows}
                            onSelectAllClick={this.handleSelectAllClick}
                        >
                            <div className="flex items-center h-8">
                                <div className={classNames('flex divide-x-2 divide-primary-shade pr-2')}>
                                    {checkPerm(Resources.Trailer, UPDATE_PERM) && (
                                        <div className="flex items-center gap-x-1 pr-4">
                                            <Tippy content={translate('text.bulk_actions')}>
                                                <button
                                                    onClick={() => this.setState({batchActionDialog: true})}
                                                    disabled={!areSomeItemsSelected}
                                                    className={classNames(areSomeItemsSelected ? 'hover:bg-primary-shade' : undefined, 'icon-btn p-2 flex items-center')}
                                                >
                                                    <PencilIcon
                                                        className={classNames(areSomeItemsSelected ? 'text-primary-contrast' : 'text-tm-gray-400', 'w-5 h-5')}/>
                                                </button>
                                            </Tippy>
                                        </div>
                                    )}

                                    {checkPerm(Resources.TrailersColor, UPDATE_PERM) && (
                                        <div className="flex items-center gap-x-1 pl-4">
                                            <TableTagManager
                                                rowKey={'TrailerID'}
                                                btnIconClass='w-5 h-5'
                                                customIcon={<ChevronUpSolidIcon className="w-5 h-5"/>}
                                                selectedRows={this.state.selectedRows}
                                                resourceName={Resources.TrailersColor}
                                                piggyResourceName={this.getResource()}
                                                query={this.getQuery()}
                                                onAfterTagRows={() => {
                                                    this.setSelectedRows({})
                                                }}
                                                translate={translate}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </TableBulkActions>


                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(Resources.Trailer, CREATE_PERM)}
                            title={'No matching records found'}
                            text={'Create a new trailer'}
                            btnLabel="Create trailer"
                            onBtnClick={() => {
                                this.setState({prefilled: this.state.queryFilterFields.query.value}, () => {
                                    this.handleLinkClick(`/trailer/create?prefilled=` + this.state.prefilled)
                                })
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />

                    {this.state.batchActionDialog && (
                        <BulkBatchEdit
                            show={this.state.batchActionDialog}
                            fields={this.state.batchFields}
                            translate={translate}
                            onClose={this.handleCloseBatchActionDialog}
                            selectedItems={this.state.selectedRows}
                            itemKeyLabel={'TrailerNumber'}
                            onSubmit={this.handleBulkEdit}
                        />
                    )}


                    <ModalDefault
                        show={!!this.state.LocationRadiusSearchDialog}
                        title={translate("text.SearchByRadius")}
                        widthClass="max-w-7xl"
                        onClose={this.handleToggleLocationRadiusSearchDialog}
                        hideDialogFooter={true}
                    >
                        {this.state.LocationRadiusSearchDialog && (
                            <LocationRadiusSearch
                                handleSearchByRadius={this.handleFilterInputChange}
                                {...this.props}
                                close={this.handleToggleLocationRadiusSearchDialog}
                                radius={this.state.queryFilterFields.radius.value}
                            />
                        )}
                    </ModalDefault>

                    <ModalDefault
                        show={this.state.isMapModalOpen}
                        widthClass={"max-w-7xl"}
                        title={translate("text.last_known_truck_location")}

                        closeButtonLabel={translate("btn.close")}
                        onClose={() => this.setState({isMapModalOpen: false})}
                    >
                        <FleetShareTableMap
                            translate={translate}
                            selectedLocation={this.state.lastLocation}
                        />
                    </ModalDefault>

                    <ModalSaveResource
                        title={'Decode VIN'}
                        show={this.state.DecodeVINModalOpen}
                        widthClass="max-w-md"
                        gridColsClass="grid-cols-3"
                        onClose={this.handleDecodeVIN}
                        secondaryButtonLabel={checkPerm(this.getResource(), CREATE_PERM) && dataVIN?.Model && (dataVIN?.Type === "TRUCK" ? translate("btn.create_truck") : translate("btn.create_trailer"))}
                        onSecondaryButtonClick={(params) => {
                            if (dataVIN.Type === "TRUCK") {
                                dataVIN.VIN = params.DecodeVIN
                                this.handleLinkClick(`/truck/create?prefilled=` + JSON.stringify(dataVIN))
                            } else if (dataVIN.Type === "TRAILER") {
                                dataVIN.VIN = params.DecodeVIN
                                this.handleLinkClick(`/trailer/create?prefilled=` + JSON.stringify(dataVIN))
                            }

                        }}
                        secondaryButtonDisabled={!dataVIN?.Model || !checkPerm(this.getResource(), CREATE_PERM)}
                        buttonLabel={translate("btn.decode_vin")}
                        fields={this.getDecodeVINFields()}
                        onSubmit={(params) => {
                            if (params) {
                                this.fetchVINData(params.DecodeVIN)
                            }
                        }}
                        htmlAfter={<div>
                            {!!isLoadingThird && (
                                <div className="relative flex w-full justify-center my-3">
                                    <Loader disableContainer={true}/>
                                </div>
                            )}
                            {(!isLoadingThird && !!dataVIN?.Model) && (
                                <div className={"grid gap-4 ml-6 text-base mb-4"}>
                                    <p>Type: {dataVIN.Type}</p>
                                    <p>Manufacturer: {dataVIN.Manufacturer}</p>
                                    <p>Model: {dataVIN.Model}</p>
                                    <p>Engine: {dataVIN.Engine}</p>
                                    <p>Year: {dataVIN.Year}</p>
                                </div>
                            )}
                        </div>
                        }
                        translate={this.props.translate}
                    />
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(TrailersView)
