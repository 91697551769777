import React, {Component} from 'react';
import {Field, FieldsManager} from "../../../../data/services/fields";
import {
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    GROUP_MEMBER_TYPE_MEMBER,
    TASK_GROUP_MEMBER_ID,
    UPDATE_PERM,
} from "../../../../util/util-constants";
import Resources from "../../../../data/services/resources";
import {checkPerm, classNames, getDefaultTableOptions, getProp} from "../../../../common/util/util-helpers";
import {cloneDeep, scrollErrorIntoViewFields} from "../../../../common/util/util-vanilla";
import {showModal} from "../../../../data/actions/ui";
import {EyeIcon, UserIcon} from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import ModalHeader from "../../../../common/components/modal/modal-header";
import NavResponsive from "../../../../common/components/nav-responsive";
import MobileTabs from "../../../../common/components/tabs-navigation/mobile-tabs";
import PageHeader from "../../../../common/components/layout/layout-components/page/page-header";
import ActiveFilters from "../../../../common/components/resource-table/table-components/active-filters";
import TableCard from "../../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../../common/components/resource-table";
import NoRecordsTable from "../../../../common/components/no-records-found/no-records-table";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import ModalDefault from "../../../../common/components/modal/modal-default";
import ModalFooter from "../../../../common/components/modal/modal-footer";
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import TableBulkActions
    from "../../../../common/components/resource-table/table-components/table-footer-bulk-actions";
import {excludeFields, fieldsToHtml, fillFieldsFromData} from "../../../../common/util/util-fields";
import {getUser} from "../../../../common/util/util-auth";
import {DEFAULT_QUERY_LIMIT} from "../../../../common/util/util-consts";

class BoardMembersModal extends Component {
    constructor(props) {
        super(props)

        this.pagePath = 'task_group_select_modal'
        const tabs = this.getTabs()
        // this.tablePageDefaults = {
        //     behaviour: {
        //         rowSelect: true,
        //     }
        // }

        this.state = {
            // Defaults and filters
            ...DEFAULT_CRUD_STATE,

            contactsTableQuery: {
                ...DEFAULT_CRUD_STATE
            },

            // Tabs
            tabs: tabs,
            selectedTab: tabs[0].resource,

            // Table selected rows
            addContactRows: [],
            removeContactRows: [],
            selectedItems: {},

            // Table options
            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            contactsTableOptions: getDefaultTableOptions(this.getFields(['GroupMemberTypeID']), {}, this.pagePath, this.props.translate),

            // Fields
            queryFilterFields: this.getQueryFilterFields(),
            contactsQueryFilterFields: this.getContactsQueryFilterFields(),
            contactsTableFields: this.getFields(),
            taskGroupInfoFields: this.getInfoFields(this.props.selectedItem),

            // Modals
            addContactsModalOpen: false,
            deleteContactsModalOpen: false,

            canSubmit: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchMembers()
    }

    /** Data Events
     ================================================================= */
    fetchMembers = () => {
        this.props.selectedItem ?
            this.props.onFetchMembers({
                resource: this.getResource(),
                query: this.getQuery()
            })
            : this.props.onTabChange(this.state.selectedTab)
    }

    fetchContacts = () => {
        this.props.onFetchContacts({
            resource: Resources.TaskGroupContacts,
            query: this.getContactsQuery()
        }, this.getMemberType())
    }

    /** UI Events
     ================================================================= */
    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, this.fetchMembers)
    }

    contactsHandleClearFiltersClick = (excludeAdditional = []) => {
        const contactsQueryFilterFields = cloneDeep(this.state.contactsQueryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(contactsQueryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(contactsQueryFilterFields, it.name, '')
        })

        this.setState({
            contactsQueryFilterFields: contactsQueryFilterFields,
            contactsTableQuery: {...this.state.contactsTableQuery, offset: 0, paginationPage: 1}
        }, this.fetchContacts)
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, this.fetchMembers)
    }

    contactsHandleUpdateOffset = (offset, num) => {
        this.setState({
            contactsTableQuery: {...this.state.contactsTableQuery, offset: offset, paginationPage: num}
        }, this.fetchContacts)
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, this.fetchMembers)
    }

    contactsHandleUpdateSort = (sortBy) => {
        this.setState({
            contactTableQuery: {
                ...this.state.contactsTableQuery,
                sortBy: sortBy,
                sort: (this.state.contactsTableQuery.sortBy === sortBy) ? (this.state.contactsTableQuery.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
            }
        }, this.fetchContacts)
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, this.fetchMembers)
    }

    contactsHandleFilterInputChange = (name, value) => {
        this.setState({
            contactsQueryFilterFields: FieldsManager.updateField(this.state.contactsQueryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, this.fetchContacts)
    }

    handleInputChange = (name, value) => {
        this.setState({
            canSubmit: true,
            taskGroupInfoFields: FieldsManager.updateField(this.state.taskGroupInfoFields, name, value),
        })
    };

    handleTabChange = (name) => {
        if (this.state.selectedTab === name) return 0

        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === name;
                return it;
            }),
            selectedTab: name,
            addContactRows: {},
            removeContactRows: {},
            queryFilterFields: this.getQueryFilterFields(),
        }, this.fetchMembers)
    };

    handleSelectRowClick = (item, event, rowState, resource) => {
        const itemID = item.ContactID;

        const data = this.getDataFor(resource)
        let selectedRows = cloneDeep(this.state[rowState])
        let selectedItems = this.state.selectedItems
        if (event?.nativeEvent?.shiftKey) {
            const firstSelectedItem = data.findIndex(it => selectedRows[it.ContactID])
            const lastSelectedItem = data.findIndex(it => it.ContactID === itemID)

            if (firstSelectedItem > -1 && lastSelectedItem > -1) {
                if (firstSelectedItem < lastSelectedItem) {
                    for (let i = firstSelectedItem; i < lastSelectedItem + 1; i++) {
                        Object.assign(selectedRows, {[data[i].ContactID]: data[i].ContactID})
                        selectedItems[data[i].ContactID] = data[i]
                    }
                } else {
                    for (let i = lastSelectedItem; i < firstSelectedItem + 1; i++) {
                        Object.assign(selectedRows, {[data[i].ContactID]: data[i].ContactID})
                        selectedItems[data[i].ContactID] = data[i]
                    }
                }
            }
        } else {
            if (selectedRows[itemID]) {
                delete selectedItems[itemID]
                delete selectedRows[itemID]
            } else {
                selectedItems[itemID] = data.find(it => it.ContactID === itemID)
                Object.assign(selectedRows, {[itemID]: itemID})
            }
        }

        this.setState({[rowState]: selectedRows, selectedItems})
    }

    handleSelectAllClick = (areAllSelected, rowState, resource) => {
        const data = cloneDeep(this.getDataFor(resource))

        let selectedRows = cloneDeep(this.state[rowState])
        let selectedItems = this.state.selectedItems

        if (!areAllSelected) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                if (this.state.selectedTab === 'Members') {
                    it.GroupMemberTypeID = GROUP_MEMBER_TYPE_MEMBER
                }

                if (!it.IsCurrentUser) {
                    selectedItems[it.ContactID] = it
                    memo[it.ContactID] = it.ContactID
                }

                return memo
            }, {}))

        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.ContactID.toString())) {
                    delete selectedItems[it.ContactID]
                    delete selectedRows[it.ContactID]
                }
            })
        }

        this.setState({[rowState]: selectedRows, selectedItems})
    }

    handleDeleteContactsModalOpen = () => {
        this.setState({deleteContactsModalOpen: true})
    }

    handleDeleteContactsModalClose = () => {
        this.setState({deleteContactsModalOpen: false})
        this.setState({removeContactRows: {}})
    }

    handleChangeGroupMembersTypeToWatcher = (it) => {
        this.props?.ChangeGroupMembersTypes(it,'Watcher',this.getQuery());
    }

    handleChangeGroupMembersTypeToFullMember = (it) => {
        this.props?.ChangeGroupMembersTypes(it,'FullMember',this.getQuery());
    }

    handleRemoveContacts = () => {
        this.props.onDeleteMembers({
            resource: Resources.TaskGroupRemoveMembers,
            query: {
                IDs: Object.keys(this.state.removeContactRows),
                GroupMemberTypeID: this.getMemberType(),
                ...(!this.props.selectedItem && {contactRows: this.state.selectedItems})
            },
            piggyResource: this.getResource(),
            piggyQuery: this.getQuery()
        })
    }

    handleAddContacts = () => {
        let memberType = this.state.groupType === 'Member' ? 2 : 1
        let AddedContacts = Object.keys(this.state.addContactRows)
        let Members = AddedContacts.map((it) => {return {ContactID:it,GroupMemberTypeID: memberType}})
        let Contacts = this.state.selectedItems
        let ContactsNew = Contacts.map((it) => {return {...it,GroupMemberTypeID: memberType}})
        this.props.onAddMembers({
            resource: Resources.TaskGroupAddMembers,
            query: {
                IDs: Members,
                ...(!this.props.selectedItem && {contactRows: ContactsNew})
            },
            piggyResource: this.getResource(),
            piggyQuery: this.getQuery()
        })
        this.handleToggleAddContactModal()
    }

    handleEditTaskGroup = () => {
        this.setState({
                taskGroupInfoFields: FieldsManager.validateFields(this.state.taskGroupInfoFields),
            }, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.taskGroupInfoFields)) {
                    let fieldValues = FieldsManager.getFieldKeyValues(this.state.taskGroupInfoFields)
                    if (this.props.selectedItem) {
                        this.props.onEditTaskGroup({
                            resource: Resources.BoardTasks,
                            query: {
                                TaskGroupID: this.props.selectedItem.TaskGroupID,
                                TaskGroupName: fieldValues.TaskGroupName,
                                TaskGroupDesc: fieldValues.TaskGroupDesc
                            },
                            piggyResource: Resources.BoardTasks
                        })
                    } else {
                        this.props.onCreateNewBoard(fieldValues)
                    }
                } else {
                    scrollErrorIntoViewFields([this.state.taskGroupInfoFields])
                }
            }
        )
    }

    handleToggleAddContactModal = (type) => {
        this.setState({
            addContactsModalOpen: !this.state.addContactsModalOpen,
            selectedItems: [],
            addContactRows: {},
            groupType: type
        }, () => {
            if (this.state.addContactsModalOpen) {
                this.fetchContacts()
            }
        })
    }

    /** Fields/Data Definitions
     ================================================================= */
    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true})
        }
    }

    getContactsQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}),
            ContactGroupID: new Field('ContactGroupID', '', [''], false, 'select-search',{},{
                isClearable: true,
                api: 'api/' + Resources.ContactGroupsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            })
        }
    }

    getFields = (hideFields = []) => {
        return {
            FirstName: new Field('FirstName', '', [''], false, 'text', {
                render:(it) => { return (<button onClick={() => {
                    this.props.dispatch(showModal('ViewContactCard', {ContactID: it.ContactID}))
                }
                }>
                    {it.FirstName}
                </button>)

                }
            }),
            LastName: new Field('LastName', '', [''], false, 'text', {
                render:(it) => { return (<button onClick={() => {
                    this.props.dispatch(showModal('ViewContactCard', {ContactID: it.ContactID}))
                }
                }>
                    {it.LastName}
                </button>)

                }
            }),
            Email: new Field('Email', '', ['']),
            GroupMemberTypeID: new Field('GroupMemberTypeID','', [''], false, 'custom', {
                hideTable: this.state?.addContactsModalOpen || hideFields.includes('GroupMemberTypeID'),
                render:(it) => {
                    if (it.GroupMemberTypeID === 1) {
                        return <div>Watcher</div>
                    } else if (it.GroupMemberTypeID === 2) {
                        return <div>Full member</div>
                    }
                }
            })
        }
    }

    getInfoFields = (item = null) => {
        const fieldTemplates = {
            TaskGroupName: new Field('TaskGroupName', '', ['empty'], false, 'text', {
                addContainerClass: 'relative col-span-6'
            }),
            TaskGroupDesc: new Field('TaskGroupDesc', '', [''], false, 'textarea', {
                addContainerClass: 'relative col-span-6'
            }),
        }
        return fillFieldsFromData(fieldTemplates, item)
    }


    /** Query Definitions
     ================================================================= */
    getQuery = () => {
        return {
            GroupMemberTypeID: this.getMemberType(),
            IsSystemUser: 1,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getContactsQuery = () => {
        return {
            sort: this.state.contactsTableQuery.sort,
            sortBy: this.state.contactsTableQuery.sortBy,
            offset: this.state.contactsTableQuery.offset,
            ExcludeIDs: this.props.ExcludeIDs ? this.props.ExcludeIDs.join(",") : "",
            ...FieldsManager.getFieldKeyValues(this.state.contactsQueryFilterFields)
        }
    }

    /** Helpers
     ================================================================= */
    getDataFor = (resource) => {
        return getProp(this.props, resource + '.data.list', [])
    }

    getResource = () => {
        return Resources.TaskGroupMembers
    }


    getMemberType = () => {
        return TASK_GROUP_MEMBER_ID
    }

    getTabs = () => {
        return [
            {
                name: 'Info',
                resource: Resources.BoardTasks,
                current: true,
                visible: true
            },
            {
                name: 'Members',
                resource: 'Members',
                current: false,
                visible: true
            }
        ]
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, membersResource, contactsResource, onClose} = this.props;

        // Members
        const membersList = this.getDataFor("membersResource")
        const membersCount = getProp(membersResource, 'data.count', 0)
        const membersIsLoading = getProp(membersResource, 'isLoading', false)

        // Table modal contacts list
        const contactsList = this.getDataFor("contactsResource")
        const contactsCount = getProp(contactsResource, 'data.count', 0)
        const contactsIsLoading = getProp(contactsResource, 'isLoading', false)

        let currentUser = getUser('Contact')
        const fieldsHtmlBoardName = fieldsToHtml(Object.values(Object.assign({}, this.state.taskGroupInfoFields)).slice(0, 1), translate, this.handleInputChange, {})
        const fieldsHtmlBoardDesc = fieldsToHtml(Object.values(Object.assign({}, this.state.taskGroupInfoFields)).slice(1, 2), translate, this.handleInputChange, {})

        return (
            <div>
                <ModalHeader
                    title={!!this.props.selectedItem ? translate('Edit Board') : translate('Create Board')}
                    onClose={onClose}
                />
                <div className="px-6 mb-6">
                    <div className="mb-5 hidden sm:block">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="mb-5">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    {this.state.selectedTab === Resources.BoardTasks &&
                        <>
                            <PageHeader
                                onButtonClick={this.handleEditTaskGroup}
                                buttonDisabled={!this.state.canSubmit}
                            />
                            <div>
                                <div className='pb-3 pt-2'>{fieldsHtmlBoardName}</div>
                                <div>{fieldsHtmlBoardDesc}</div>
                            </div>
                        </>
                    }

                    {(this.state.selectedTab === 'Members') && (
                        <>
                            <PageHeader
                                secondaryButtonLabel={translate('btn.add_member')}
                                onSecondaryButtonClick={() => {this.handleToggleAddContactModal('Member')}}
                                buttonLabel={translate('btn.add_watcher')}
                                onButtonClick={() => {this.handleToggleAddContactModal('Watcher')}}
                            >
                                {!!Object.keys(this.state.removeContactRows).length && (
                                    <button
                                        className={`btn btn-outline-secondary mr-2`}
                                        onClick={this.handleDeleteContactsModalOpen}
                                    >
                                        Remove Selected
                                    </button>
                                )}
                            </PageHeader>

                            <div className="flex">
                                <ActiveFilters
                                    filterFields={this.state.queryFilterFields}
                                    onLabelClick={this.handleFilterInputChange}
                                    onClearFiltersClick={this.handleClearFiltersClick}
                                    translate={translate}
                                />
                            </div>

                            <TableCard addClass={'relative mt-3'}>
                                <TableFilters
                                    hideLimit
                                    filterFields={this.state.queryFilterFields}
                                    handleInputChange={this.handleFilterInputChange}
                                    translate={translate}
                                />

                                <ResourceTable
                                    data={membersList}
                                    fields={this.getFields()}

                                    translate={translate}
                                    isLoading={membersIsLoading}

                                    limit={this.state.queryFilterFields.limit.value}

                                    sort={this.state.sort}
                                    sortBy={this.state.sortBy}
                                    onSortChange={this.handleUpdateSort}

                                    options={this.state.tableOptions}
                                    onDelete={this.handleToggleConfirmModal}

                                    isSelectRowDisabled={(it) => it.IsCurrentUser}
                                    onSelectRow={(item, event) => this.handleSelectRowClick(item, event, "removeContactRows", "membersResource")}
                                    selectedRows={this.state.removeContactRows}
                                    onSelectAllClick={(areAllSelected) => this.handleSelectAllClick(areAllSelected, "removeContactRows", "membersResource")}
                                    tableKey={'ContactID'}
                                />

                                {/*Table footer*/}
                                {!(!membersList.length && !membersIsLoading) && (
                                    <div
                                        className="bg-inverse px-4 py-3 flex items-center justify-between border-t border-tm-gray-300 sm:px-6 h-16 rounded-b-lg"
                                    >
                                        <TableBulkActions
                                            areActionsVisible={checkPerm(Resources.BoardMembers, UPDATE_PERM)}
                                            translate={translate}
                                            pagination=
                                                {!!this.props.selectedItem && (
                                                    <div className={"flex items-center w-full pl-3 h-9"}>
                                                        <Pagination
                                                            count={membersCount}
                                                            isLoading={membersIsLoading}
                                                            handleQueryChange={
                                                                (name, value, currentPage) => name === "offset"
                                                                    ? this.handleUpdateOffset(value, currentPage)
                                                                    : this.handleFilterInputChange(name, value)
                                                            }
                                                            pageOffset={this.state.offset}
                                                            queryFields={this.state.queryFilterFields}
                                                            translate={translate}
                                                        />
                                                    </div>)}
                                        >
                                            <div className="flex divide-x-2 divide-tm-gray-300">
                                                <div className="flex space-x-2 px-3">
                                                    <Tippy content={translate('text.set_as_watchers')}>
                                                        <button
                                                            onClick={() => this.handleChangeGroupMembersTypeToWatcher(this.state.removeContactRows)}
                                                            disabled={!Object.keys(this.state.removeContactRows).length}
                                                            className={classNames(  Object.keys(this.state.removeContactRows).length ? 'hover:bg-tm-gray-100' : undefined, 'icon-btn p-2 flex items-center')}
                                                        >
                                                            <EyeIcon
                                                                className={classNames( Object.keys(this.state.removeContactRows).length ? 'text-primary' : 'text-tm-gray-400', 'w-5 h-5')}/>
                                                        </button>
                                                    </Tippy>

                                                    <Tippy content={translate('text.set_as_full_members')}>
                                                        <button
                                                            onClick={() => this.handleChangeGroupMembersTypeToFullMember(this.state.removeContactRows)}
                                                            disabled={!Object.keys(this.state.removeContactRows).length}
                                                            className={classNames(  Object.keys(this.state.removeContactRows).length ? 'hover:bg-tm-gray-100' : undefined, 'icon-btn p-2 flex items-center')}
                                                        >
                                                            <UserIcon
                                                                className={classNames( Object.keys(this.state.removeContactRows).length ? 'text-primary' : 'text-tm-gray-400', 'w-5 h-5')}/>
                                                        </button>
                                                    </Tippy>
                                                </div>
                                            </div>

                                        </TableBulkActions>
                                    </div>
                                )}

                                <NoRecordsTable
                                    show={(membersList.length === 0) && !membersIsLoading}
                                    title={'No matching records found'}
                                    onClearFilterClick={this.handleClearFiltersClick}
                                    clearFilterBtnLabel={translate('text.clear_all_filters')}
                                    clearFilterText={translate('text.try_without_filters')}
                                    filters={this.state.queryFilterFields}
                                />

                                <ModalConfirm
                                    title={translate("text.Confirm")}
                                    show={this.state.deleteContactsModalOpen}
                                    text={translate('text.confirm_delete_contact')}
                                    onClose={this.handleDeleteContactsModalClose}
                                    buttonLabel={translate("btn.confirm")}
                                    closeButtonLabel={'Cancel'}
                                    translate={translate}
                                    onConfirm={() => {
                                        this.handleRemoveContacts(this.state.selectedItem, this.getQuery())
                                        this.handleDeleteContactsModalClose()
                                    }}
                                />
                            </TableCard>
                        </>
                    )}

                    {/* CONTACTS TABLE  */}
                    {/* _______________________________________________________________________________ */}

                    <ModalDefault
                        show={this.state.addContactsModalOpen}
                        title={'Add Contact'}
                        widthClass={"max-w-5xl"}
                        onClose={this.handleToggleAddContactModal}
                        closeButtonLabel={translate("btn.close")}
                        translate={translate}
                        onButtonClick={this.handleAddContacts}
                        buttonLabel={translate('btn.add_selected')}
                        buttonDisabled={!Object.keys(this.state.addContactRows).length}
                    >
                        <div className="flex">
                            <ActiveFilters
                                filterFields={this.state.contactsQueryFilterFields}
                                onLabelClick={this.contactsHandleFilterInputChange}
                                onClearFiltersClick={this.contactsHandleClearFiltersClick}
                                translate={translate}
                            />
                        </div>

                        <TableCard addClass={'relative'} isRounded={false}>
                            <TableFilters
                                hideLimit
                                filterFields={this.state.contactsQueryFilterFields}
                                handleInputChange={this.contactsHandleFilterInputChange}
                                translate={translate}
                            />

                            <ResourceTable
                                data={contactsList}
                                fields={this.getFields()}

                                translate={translate}
                                isLoading={contactsIsLoading}

                                limit={this.state.contactsQueryFilterFields.limit.value}

                                sort={this.state.contactsTableQuery.sort}
                                sortBy={this.state.contactsTableQuery.sortBy}
                                onSortChange={this.contactsHandleUpdateSort}

                                options={this.state.contactsTableOptions}

                                onSelectRow={(item, event) => this.handleSelectRowClick(item, event, "addContactRows", "contactsResource")}
                                onRowClick={(item, event) => this.handleSelectRowClick(item, event, "addContactRows", "contactsResource")}
                                selectedRows={this.state.addContactRows}
                                onSelectAllClick={(areAllSelected) => this.handleSelectAllClick(areAllSelected, "addContactRows", "contactsResource")}
                                tableKey={'ContactID'}
                            />

                            {/*Table footer*/}
                            {!(!contactsList.length && !contactsIsLoading) && (
                                <div
                                    className="bg-inverse px-4 py-3 flex items-center justify-between border-t border-tm-gray-300 sm:px-6 h-16 rounded-b-lg"
                                >
                                    <div className={"flex items-center w-full pl-3 h-9"}>
                                        <Pagination
                                            count={contactsCount}
                                            isLoading={contactsIsLoading}
                                            handleQueryChange={
                                                (name, value, currentPage) => name === "offset"
                                                    ? this.contactsHandleUpdateOffset(value, currentPage)
                                                    : this.contactsHandleFilterInputChange(name, value)
                                            }
                                            pageOffset={this.state.contactsTableQuery.offset}
                                            queryFields={this.state.contactsQueryFilterFields}
                                            translate={translate}
                                        />
                                    </div>
                                </div>
                            )}

                            <NoRecordsTable
                                show={(contactsList.length === 0) && !contactsIsLoading}
                                title={'No matching records found'}
                                onClearFilterClick={this.contactsHandleClearFiltersClick}
                                clearFilterBtnLabel={translate('text.clear_all_filters')}
                                clearFilterText={translate('text.try_without_filters')}
                                filters={this.state.contactsQueryFilterFields}
                            />
                        </TableCard>
                    </ModalDefault>
                </div>
                <ModalFooter
                    buttonLabel={translate("btn.save")}
                    onButtonClick={this.handleEditTaskGroup}
                    closeButtonLabel={translate("btn.close")}
                    onClose={onClose}
                />
            </div>
        )
    }
}

export default BoardMembersModal;
