import {hideGlobalModal} from "../../../../data/actions/ui";
import TasksFormDialog from "./task-form";
import Modal from "../../modal";
import React from "react";
import {useDispatch} from "react-redux";

export default function TaskFormDialogWrapper({ui, translate, location}) {
    const dispatch = useDispatch();

    return <Modal
        show={ui?.globalDialogs?.createTaskDialog?.visible && location.pathname !== "/all-tasks"}
        widthClass={"max-w-5xl"}
        translate={translate}
        onClose={() => dispatch(hideGlobalModal("createTaskDialog"))}
    >
        <TasksFormDialog
            close={() => dispatch(hideGlobalModal("createTaskDialog"))}
            TaskID={ui?.globalDialogs?.createTaskDialog?.data?.TaskID}
            taskResource={ui?.globalDialogs?.createTaskDialog?.data?.taskResource}
            taskResourcePath={ui?.globalDialogs?.createTaskDialog?.data?.taskResource}
            globalDialog={ui?.globalDialogs?.createTaskDialog?.data?.globalDialog}
            taskName={ui?.globalDialogs?.createTaskDialog?.data?.TaskName}
            taskDescription={ui?.globalDialogs?.createTaskDialog?.data?.Description}
            pathname={location.pathname}
            translate={translate}
        />
    </Modal>
}