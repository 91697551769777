import React, { Component } from 'react';
import L from 'leaflet'
import { LayersControl, Map, Marker, Popup, TileLayer } from "react-leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {LoaderSmall} from "../../../loader";
import {classNames} from "../../../../util/util-helpers";
import {layers} from "../../../../../util/util-constants";
import "../../../../../styles/react-leaflet-markercluster.css";
import "../../../../../styles/react-leaflet.css";

const myIcon = L.icon({
    iconSize: [22, 34],
    iconAnchor: [13, 44],
    iconUrl: icon,
    shadowUrl: iconShadow
});

class DragAndDrop extends Component {

    addMarker = (e) => {
        this.props.addMarker({
            Longitude: e.latlng.lng,
            Latitude: e.latlng.lat
        })
    }

    render() {
        return (
            <div className={this.props.className ?? "w-full h-[960px] max-h-[calc(100vh-32rem)] relative z-0"}>
                {!!this.props.isLoading && (
                    <div className="inset-0 absolute flex items-center justify-center">
                        <div className="text-center">
                            <LoaderSmall />

                            {!!this.props.loaderMessage && (
                                <p className="mt-2 font-bold text-base text-tm-gray-700">{this.props.loaderMessage}</p>
                            )}
                        </div>
                    </div>
                )}

                <div
                    className={classNames(
                        "leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom",
                        this.props.isLoading ? "invisible" : undefined
                    )}>
                    <Map
                        center={[40, -100]}
                        onClick={this.addMarker}
                        zoom={5}>
                        <LayersControl position="topright">
                            {layers.map((layer, index) =>
                                <LayersControl.BaseLayer checked={index === 0} name={layer.name}>
                                    <TileLayer
                                        url={layer.url}
                                        attribution={layer.attribution}
                                    />
                                </LayersControl.BaseLayer>
                            )}
                            {
                                !isNaN(this.props.Latitude) && !isNaN(this.props.Longitude) && (
                                    <Marker
                                        icon={myIcon}
                                        position={[this.props.Latitude, this.props.Longitude]}>
                                        <Popup autoPan={false}>{this.props.Latitude},{this.props.Longitude}</Popup>
                                    </Marker>
                                )
                            }
                        </LayersControl>
                    </Map>
                </div>
            </div>
        )
    }
}

export default DragAndDrop;
