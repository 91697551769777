import React from "react";
import {classNames} from "../../../../../common/util/util-helpers";
import DropZoneAlt from "../../../../../common/components/dropzone/drop-zone-alt";

export default function FactoringFileUploadZone({translate, setFile}) {
    return (
        <DropZoneAlt
            maxFilesAccepted={1}
            accept={
                'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
            translate={translate}
            onAcceptFiles={(acceptedFile) => {
                setFile(acceptedFile);
            }}
            content={(isDragAccept, isDragReject) => {
                return (
                    <React.Fragment>
                        <div
                            className={
                                classNames(
                                    isDragReject ? 'border-red-600 bg-red-600/10' : undefined,
                                    isDragAccept ? 'border-green-600 bg-green-600/10' : undefined,
                                    !isDragAccept && !isDragReject ? 'border-primary' : undefined,
                                    'text-base mt-2 flex items-center justify-center rounded-lg border border-dashed px-6 py-10 h-48'
                                )}
                        >
                            <div className="space-x-2 flex-wrap text-center max-w-xs">
                                <span className="text-primary font-bold cursor-pointer">Upload a file</span>

                                <span>or drag and drop</span>

                                <p>CSV, XLS, XLSX up to 10MB</p>
                            </div>
                        </div>


                    </React.Fragment>
                )
            }}
        />
    )
}