import React, {useEffect, useState} from "react";
import ColorPickerGradient from "./color-picker-gradient";
import {classNames} from "../../util/util-helpers";

export default function ColorPickerNew({colorRef, color}) {
    const [selectedColor, setSelectedColor] = useState("#000000");
    const [markerPosition, setMarkerPosition] = useState(0);
    const [isInvalidColor, setIsInvalidColor] = useState(false);

    if (colorRef) {
        colorRef.current = [selectedColor, !isInvalidColor];
    }

    function handleInputChange(e) {
        setSelectedColor(e.target.value);
        const isValid = isValidHexColor(e.target.value) && (e.target.value.length === 4 || e.target.value.length === 8);

        if (isValid) {
            setMarkerPosition(0);
        } else if (e.target.value.length === 0) {
            setMarkerPosition(0);
        }
    }

    function isValidHexColor(color) {
        return /^#([A-Fa-f0-9]{3}){1,2}$/i.test(color);
    }

    useEffect(() => {
        setIsInvalidColor(!(isValidHexColor(selectedColor) && (selectedColor.length === 4 || selectedColor.length === 7)));
    }, [selectedColor]);

    useEffect(() => {
        setSelectedColor(color);
        setMarkerPosition(0);
    }, [color]);

    return (
        <div className="flex flex-col items-center w-full max-w-xl">
            <div className="flex items-center mb-4 space-x-4 ml-24">
                <div
                    className="w-16 h-16 rounded-full border-2 border-inverse"
                    style={{backgroundColor: selectedColor}}
                ></div>

                <input
                    type="text"
                    className={classNames("form-control w-24 text-center", isInvalidColor ? "is-invalid" : undefined)}
                    value={selectedColor}
                    onChange={handleInputChange}
                />
            </div>

            <ColorPickerGradient
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                markerPosition={markerPosition}
                setMarkerPosition={setMarkerPosition}
            />
        </div>
    );
}
