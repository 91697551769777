import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {fillFieldsFromData} from '../../../common/util/util-fields';
import {checkPerm, openInNewTab} from '../../../common/util/util-helpers'
import {getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {showGlobalModal, showModal} from '../../../data/actions/ui'
import {
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    LOAD_STATUS_CANCELED,
    LOAD_STATUS_DELIVERED, READ_PERM
} from '../../../util/util-constants'
import {classNames, getLookup, getProp, mileageToErrorMessage, returnMileage} from '../../../common/util/util-helpers'
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import {cloneDeep, genericMoneyFormatter} from "../../../common/util/util-vanilla";
import Resources from "../../../data/services/resources";
import BuildingOfficeIcon from "@heroicons/react/24/outline/BuildingOfficeIcon";
import Tippy from "@tippyjs/react";
import StarSolidIcon from "@heroicons/react/20/solid/StarIcon";
import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon";
import { getInfoResource } from "../../../data/actions/infoResource";
import { toFrontDateNoTime } from '../../../common/util/util-dates'
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import FireIcon from "@heroicons/react/20/solid/FireIcon";
import ResourceTable from "../../../common/components/resource-table";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import StatusProgressTable from "../../../common/components/resource-table/table-components/status-progress-table";
import Card from "../../../common/components/card";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";


export default class SummaryTab extends Component {

    constructor(props) {
        super(props)
        this.state = {
            limit: 10,
            offset: 0,
            sort: 'ASC',
            sortBy: 'LoadID',
            query: '',
            paginationPage: 1,
            fields: this.getFields(LocalStorage.get("loads_column_filters", {})),
            queryFilterFields: this.getQueryFilterFields()
        }
    }

    componentDidMount() {
        this.fetchData()
        this.fetchSummaryData()
    }

    fetchSummaryData = () => {
        this.props.dispatch(getInfoResource({
            user: LocalStorage.get('user'),
            resource: Resources.CustomersSummary,
            query: {id: this.props.CustomerID}
        }))
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.LoadsQuick,
            query: this.getQuery()
        }))
    }
    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true},{menuPlacement: "top"})
        }
    }
    getQuery = () => {
        const LoadStatusExceptDelivered = getProp(LocalStorage.get('lookup'), 'LoadStatus', [])
            .filter(it => it.LoadStatusID !== LOAD_STATUS_DELIVERED && it.LoadStatusID !== LOAD_STATUS_CANCELED)
            .map(it => it.LoadStatusID)
            .join(",")

        return {
            customers: this.props.CustomerID,
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            searchFields: JSON.stringify({
                LoadStatusID: LoadStatusExceptDelivered
            })
        }
    }

    handleUpdateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, this.fetchData)
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, this.fetchData)
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
                queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
                offset: 0,
                paginationPage: 1

            },
            this.fetchData
        )
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            LoadNumber: new Field('LoadNumber', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        <div key={item.LoadNumber} className="flex">
                            {item.LoadNumber}

                            {(!!item.Favourite || !!item.Marked) && (
                                <div className="ml-auto flex pl-5">
                                    {!!item.Favourite && (
                                        <Tippy
                                            content={this.props.translate('btn.favourite_load')}
                                        >
                                            <StarSolidIcon
                                                className={classNames('h-5 w-5 cursor-pointer text-yellow-400')}
                                            />
                                        </Tippy>
                                    )}

                                    {!!item.Marked && (
                                        <Tippy
                                            content={this.props.translate('btn.load_needs_attention')}
                                        >
                                            <span><FireIcon className="relative z-12 h-5 w-5 cursor-pointer text-red-600 animate-pulse"/></span>
                                        </Tippy>
                                    )}
                                </div>
                            )}
                        </div>
                    )
                },
                colFilter: {
                    name: "LoadID"
                }
            }, {icon: false}),

            Labels: new Field('Labels', '', [''], false, 'custom', {
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
            }),
            IsBroker: new Field('IsBroker', '', [''], false, 'custom', {
                render: (it) => !!it.IsBroker ?
                    <span
                        className={'w-5 h-5 font-bold text-indigo-500'}>{this.props.translate("text.brokerage")}</span> :
                    <span className={'w-5 h-5 font-bold text-green-600'}>{this.props.translate("text.carrier")}</span>
            }),
            LoadTypeID: new Field('LoadTypeID', "", [''], false, 'select', {}),
            LoadSubTypeID: new Field('LoadSubTypeID', "", [''], false, 'select', {}),
            Customer: new Field('Customer', "", [''], false, 'select-search', {
                colFilter: {
                    name: "CustomerID",
                    select: {
                        CustomerID: {
                            api: 'api/' + Resources.CustomersQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                label: item.LegalName || item.Organization,
                                value: item.CustomerID
                            })
                        }
                    }
                },
                render: (item) => {
                    if (!item.CustomerID) return null;
                    return (
                        <button
                            disabled={!checkPerm(Resources.OrganizationsQuick, READ_PERM)}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.dispatch(showModal('ViewCustomerCard', {
                                    CustomerID: item.CustomerID,
                                    OrganizationID: item.OrganizationID
                                }));
                            }}
                            className="btn btn-text hover:bg-primary-transparent hover:text-tm-gray-900 focus-visible:bg-primary-transparent focus:outline-none focus:ring-0"
                        >
                            {item.Customer}
                        </button>
                    )
                }
            }, {isClearable: true, addClass: "min-w-[9rem] form-control", hasPortal: true}),
            BookedBy: new Field('BookedBy', '', [''], false, 'select-search', {
                colFilter: {
                    name: "BookedByID",
                    select: {
                        BookedByID: {
                            api: 'api/' + Resources.ContactsQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.ContactID,
                                label: item.FirstName + " " + item.LastName
                            })
                        },
                    }
                }
            }, {isClearable: true}),
            CoveredBy: new Field('CoveredBy', '', [''], false, 'select-search', {
                colFilter: {
                    name: "CoveredByID",
                    select: {
                        CoveredByID: {
                            api: 'api/' + Resources.ContactsQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.ContactID,
                                label: item.FirstName + " " + item.LastName
                            })
                        },
                    }
                }
            }, {isClearable: true}),
            Driver: new Field('Driver', '', [''], false, 'select-search', {
                colFilter: {
                    name: "DriverID",
                    select: {
                        DriverID: {
                            api: 'api/' + Resources.DriversQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.DriverID,
                                label: item.FirstName + " " + item.LastName
                            })
                        },
                    }
                },
                render: (item) => {
                    if (!item) return null;

                    if (!!item.DriverContactID) {
                        return (
                            <button
                                disabled={!checkPerm(Resources.Contacts, READ_PERM)}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.props.dispatch(showModal('ViewContactCard', {ContactID: item.DriverContactID}));
                                }}
                                className="btn btn-text hover:bg-primary-transparent hover:text-tm-gray-900 focus-visible:bg-primary-transparent  focus:outline-none focus:ring-0"
                            >
                                {item.Driver}
                            </button>
                        )
                    } else {
                        return (<span className={"pl-3.5"}>{item.Driver}</span>)
                    }
                }
            }, {isClearable: true, addClass: "min-w-[9rem] form-control", hasPortal: true}),
            CoDriver: new Field('CoDriver', '', [''], false, 'select-search', {
                colFilter: {
                    name: "CoDriver",
                    select: {
                        CoDriver: {
                            api: 'api/' + Resources.DriversQuick,
                            query: {},
                            searchMap: (item) => ({
                                value: item.DriverID,
                                label: item.FirstName + " " + item.LastName
                            })
                        },
                    }
                },
                render: (item) => {
                    if (!item) return null;

                    if (!!item.CoDriverContactID) {
                        return (
                            <button
                                disabled={!checkPerm(Resources.Contacts, READ_PERM)}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.props.dispatch(showModal('ViewContactCard', {ContactID: item.CoDriverContactID}));
                                }}
                                className="btn btn-text hover:bg-primary-transparent  hover:text-tm-gray-900 focus-visible:bg-primary-transparent  focus:outline-none focus:ring-0"
                            >
                                {item.CoDriver}
                            </button>
                        )
                    } else {
                        return (<span className={"pl-3.5"}>{item.CoDriver}</span>)
                    }
                }
            }, {isClearable: true, addClass: "min-w-[9rem] form-control", hasPortal: true}),
            Truck: new Field('Truck', '', [''], false, 'text', {
                render: (item) => {
                    if (!item) return null;

                    if (!!item.TruckID) {
                        return (
                            <button
                                disabled={!checkPerm(Resources.TrucksInfo, READ_PERM)}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.props.dispatch(showGlobalModal('ViewTruckCard', item.TruckID));
                                }}
                                className="btn btn-text hover:bg-primary-transparent  hover:text-tm-gray-900 focus-visible:bg-primary-transparent  focus:outline-none focus:ring-0"
                            >
                                {item.Truck}
                            </button>
                        )
                    } else {
                        return (<span className={"pl-3.5"}>{item.Truck}</span>)
                    }
                }
            }, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true
            }),
            Trailer: new Field('Trailer', '', [''], false, 'text', {
                render: (item) => {
                    if (!item) return null;

                    if (!!item.TrailerID) {
                        return (
                            <button
                                disabled={!checkPerm(Resources.Trailer, READ_PERM)}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.props.dispatch(showGlobalModal('ViewTrailerCard', item.TrailerID));
                                }}
                                className="btn btn-text hover:bg-primary-transparent  hover:text-tm-gray-900 focus-visible:bg-primary-transparent  focus:outline-none focus:ring-0"
                            >
                                {item.Trailer}
                            </button>
                        )
                    } else {
                        return (<span className={"pl-3.5"}>{item.Trailer}</span>)
                    }
                }
            }, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true
            }),
            SecondTrailer: new Field('SecondTrailer', '', ['']),
            ThirdTrailer: new Field('ThirdTrailer', '', ['']),
            TrailerType: new Field('TrailerType', '', [''], false, 'text', {}, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true
            }),
            Carrier: new Field('Carrier', '', [''], false, 'text', {}, {
                isClearable: true,
                addClass: "min-w-[9rem] form-control",
                hasPortal: true,
                colFilter: true
            }),
            LoadStatus: new Field('LoadStatus', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        <StatusProgressTable
                            addClass=""
                            LoadStatusID={item.LoadStatusID}
                            translate={this.props.translate}
                        />
                    )
                },
                colFilter: {
                    name: "LoadStatusID",
                    type: "select",
                    select: getLookup("LoadStatus", "LoadStatusID", "LoadStatus")
                }
            }, {all: true, addClass: "min-w-[9rem] form-control", hasPortal: true}),
            Price: new Field('Price', '', [''], false, "float", {
                render: (it) => genericMoneyFormatter(it.Price),
                colFilter: true
            }, {icon: false}),
            AccessorialAmount: new Field('AccessorialAmount', '', [''], false, "money", {
                colFilter: true
            }, {icon: false}),
            DriverPayAmount: new Field('DriverPayAmount', '', [''], false, "money", {
                colFilter: true
            }, {icon: false}),
            AccessorialDriverPayAmount: new Field('AccessorialDriverPayAmount', '', [''], false, "money", {
                colFilter: true
            }, {icon: false}),
            CarrierAmount: new Field('CarrierAmount', '', [''], false, "money", {
                colFilter: true
            }, {icon: false}),
            CommisionAmount: new Field('CommisionAmount', '', [''], false, "money", {
                colFilter: true
            }, {icon: false}),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [''], false, 'datetimezone'),
            PickupDate: new Field('PickupDate', '', [''], false, 'date'),
            Pickup: new Field('Pickup', '', [''], false, "text", {colFilter: true}, {icon: false}),
            PickupCityName: new Field('PickupCityName', '', [''], false, "text", {colFilter: true}, {icon: false}),
            PickupState: new Field('PickupState', '', [''], false, "text", {colFilter: true}, {icon: false}),
            PickupPostalCode: new Field('PickupPostalCode', '', [''], false, "text", {colFilter: true}, {icon: false}),

            DestinationDate: new Field('DestinationDate', '', [''], false, 'datetime'),
            Destination: new Field('Destination', '', [''], false, "text", {colFilter: true}, {icon: false}),
            DestinationCityName: new Field('DestinationCityName', '', [''], false, "text", {colFilter: true}, {icon: false}),
            DestinationState: new Field('DestinationState', '', [''], false, "text", {colFilter: true}, {icon: false}),
            DestinationPostalCode: new Field('DestinationPostalCode', '', [''], false, "text", {colFilter: true}, {icon: false}),

            NumberOfStops: new Field('NumberOfStops', '', [''], false, 'integer', {hideDialog: true}),
            TotalMiles: new Field('TotalMiles', '', [''], false, "float", {
                colFilter: true,
                render: (item) => <div className={"text-right"}>{mileageToErrorMessage(returnMileage(item.TotalMiles), this.props.translate)}</div>
            }, {icon: false}),
            EmptyMiles: new Field('EmptyMiles', '', [''], false, "float", {
                colFilter: true,
                render: (item) => <div className={"text-right"}>{returnMileage(item.EmptyMiles)}</div>
            }, {icon: false}),
            TotalWeight: new Field('TotalWeight', '', [''], false, "custom", {
                render: (it) => {
                    return <div className="text-right">{it.TotalWeight}</div>
                }
            }),// TODO Add unit suffix, will be return by API in LBS
            TotalVolume: new Field('TotalVolume', '', [''], false, 'float'), // TODO Add unit suffix, will be return by API in inches
            TotalPieces: new Field('TotalPieces', '', [''], false, "float", {colFilter: true}, {icon: false}),
            LastDetectedAddress: new Field('LastDetectedAddress', '', [''], false, "text", {colFilter: true}, {icon: false}),
            LastDetectedAddressEtaTime: new Field('LastDetectedAddressEtaTime', '', ['']),
            IsHazmat: new Field('IsHazmat', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        item.IsHazmat ?
                            <div className="pl-4"><ExclamationTriangleIcon className={'w-5 h-5 text-red-600'}/>
                            </div> : null
                    )
                },
                colFilter: {type: "checkbox"}
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsTonu: new Field('IsTonu', '', [''], false, 'checkbox', {
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsInvoiced: new Field('IsInvoiced', '', [''], false, 'checkbox', {
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsPODReady: new Field('IsPODReady', '', [''], false, 'checkbox', {
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsAccessorialHold: new Field('IsAccessorialHold', '', [''], false, 'checkbox', {
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            CreateDate: new Field('CreateDate', '', [''], false, 'datetimezone', {hideDialog: true}),
        }

        let filledFields = fillFieldsFromData(fieldTemplates, item)

        if (!!item) {
            filledFields.Customer.value = !!item.Customer ? {label: item.Customer, value: item.CustomerID} : "";
            filledFields.Driver.value = !!item.Driver ? {label: item.Driver, value: item.DriverID} : "";
            filledFields.CoDriver.value = !!item.CoDriver ? {label: item.CoDriver, value: item.CoDriverID} : "";
            filledFields.BookedBy.value = !!item.BookedBy ? {label: item.BookedBy, value: item.BookedByID} : "";
        }

        return filledFields;
    }

    handleFilterChange = (name, value) => {
        const fieldsUpdate = FieldsManager.updateField(this.state.fields, name, value);
        let queryFields = this.state.queryFilterFields

        queryFields = FieldsManager.updateField(queryFields, 'ColumnFilters', true)

        this.setState({
            fields: fieldsUpdate,
            queryFilterFields: queryFields
        }, () => {
            this.fetchData();
            this.persistColumnFilters(fieldsUpdate);
            this.saveFilters();
        })
    }

    render() {
        const {translate, resource, info} = this.props
        const data = getProp(resource, 'data.list', [])
        const count = getProp(resource, 'data.count', 0)
        const CustomerStats = getProp(info, 'data.CustomerStats', {});

        const AgingReport = getProp(info, 'data.CustomerStats.AgingReport', {});
        const AgingFactoringReport = getProp(info, 'data.CustomerStats.AgingReportFactoring', {});
        const AvgDaysToPay = getProp(info, 'data.CustomerStats.AvgDaysToPay', 0);
        const AvgDaysToPayFactoring = getProp(info, 'data.CustomerStats.AvgDaysToPayFactoring', 0);

        return (
            <div className={"pb-5"}>
                <Card>
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />

                    <div className={"grid grid-cols-12 gap-5"}>
                        <div className="col-span-5">
                            <div className="relative bg-inverse shadow rounded-lg">
                                <div className="pt-4 pb-6 px-4 sm:px-5">

                                    <div className="mb-5 flex items-center flex-wrap gap-x-2">
                                        <BuildingOfficeIcon className="w-5 h-5 text-tm-gray-600"/>

                                        <h2 className="text-lg text-tm-gray-700 flex gap-2">Avg. days to pay</h2>
                                    </div>

                                    <div className="grid grid-cols-12 gap-4">
                                        <div className="col-span-6">
                                            <p className="text-tm-gray-500 text-xs leading-4">Avg. days to pay</p>
                                            <p className="font-bold leading-5 whitespace-pre text-base text-tm-gray-800">{AvgDaysToPay}</p>
                                        </div>

                                        <div className="col-span-6">
                                            <p className="text-tm-gray-500 text-xs leading-4">Avg. days to pay (Factoring)</p>
                                            <p className="font-bold leading-5 whitespace-pre text-base text-tm-gray-800">{AvgDaysToPayFactoring}</p>
                                        </div>
                                    </div>

                                    <h2 className="text-lg text-tm-gray-700 flex gap-2 mt-5 mb-5">Invoiced loads in the past:</h2>
                                    <div className="grid grid-cols-12">
                                        <div className="col-span-2">
                                            <p className="text-tm-gray-500 text-xs leading-4">3 months</p>
                                            <p className="font-bold leading-5 whitespace-pre text-base text-tm-gray-800">{CustomerStats.Load3}</p>
                                        </div>
                                        <div className="col-span-2">
                                            <p className="text-tm-gray-500 text-xs leading-4">6 months</p>
                                            <p className="font-bold leading-5 whitespace-pre text-base text-tm-gray-800">{CustomerStats.Load6}</p>
                                        </div>
                                        <div className="col-span-2">
                                            <p className="text-tm-gray-500 text-xs leading-4">12 months</p>
                                            <p className="font-bold leading-5 whitespace-pre text-base text-tm-gray-800">{CustomerStats.Load12}</p>
                                        </div>
                                        <div className="col-span-2">
                                            <p className="text-tm-gray-500 text-xs leading-4">24 months</p>
                                            <p className="font-bold leading-5 whitespace-pre text-base text-tm-gray-800">{CustomerStats.Load24}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {!!AgingReport && (
                            <div className={"col-span-7"}>
                                <div className="relative -top-4">
                                    <button
                                        disabled={!checkPerm(Resources.Invoices, READ_PERM)}
                                        onClick={() => openInNewTab("/accounting-income?OrganizationID=" + this.props.OrganizationID + "&Organization=" + CustomerStats.LegalName)}>
                                        <div
                                            className="grid grid-cols-7 border border-tm-gray-200 rounded-card relative mt-4 divide-x">
                                            <div
                                                className="absolute -top-2 left-1/2 -translate-x-1/2 bg-inverse px-2 font-bold text-tm-gray-800 z-10">
                                                {translate("text.AgingReport")}
                                            </div>
                                            <div className="flex flex-col col-start-1 p-4">
                                                <p className="text-tm-gray-800 text-xs leading-4">Age</p>
                                                <p className="mt-auto font-bold leading-5 whitespace-pre  text-tm-gray-800 pt-12">{AgingReport.Age}</p>
                                            </div>

                                            <div className="flex flex-col p-4">
                                                <p className="text-tm-gray-800 text-xs leading-4">Amount</p>
                                                <p className="mt-auto font-bold leading-5 whitespace-pre  text-tm-gray-800 pt-12">{genericMoneyFormatter(AgingReport.Amount)}</p>
                                            </div>

                                            <div className="flex flex-col p-4">
                                                <p className="text-tm-gray-800 text-xs leading-4">Balance</p>
                                                <p className="mt-auto font-bold leading-5 whitespace-pre  text-tm-gray-800 pt-12">{genericMoneyFormatter(AgingReport.OpenInvoices)}</p>
                                            </div>

                                            <div className="flex flex-col p-4">
                                                <p className="text-tm-gray-800 text-xs leading-4">{translate("field.CurrentInvoices")}</p>
                                                <p className="mt-auto font-bold leading-5 whitespace-pre  text-tm-gray-800">{genericMoneyFormatter(AgingReport.CurrentInvoices)}</p>
                                            </div>

                                            <div className="flex flex-col p-4">
                                                <p className="text-tm-gray-800 text-xs leading-4">{translate("field.PastDue60")}</p>
                                                <p className="mt-auto font-bold leading-5 whitespace-pre  text-tm-gray-800 pt-12">{genericMoneyFormatter(AgingReport.PastDue30)}</p>
                                            </div>


                                            <div className="flex flex-col p-4">
                                                <p className="text-tm-gray-800 text-xs leading-4">{translate("field.PastDue90")}</p>
                                                <p className="mt-auto font-bold leading-5 whitespace-pre  text-tm-gray-800 pt-12">{genericMoneyFormatter(AgingReport.PastDue60)}</p>
                                            </div>

                                            <div className="flex flex-col p-4">
                                                <p className="text-tm-gray-800 text-xs leading-4">{translate("field.PastDue90More")}</p>
                                                <p className="mt-auto font-bold leading-5 whitespace-pre  text-tm-gray-800 pt-12">{genericMoneyFormatter(AgingReport.PastDue90)}</p>
                                            </div>
                                        </div>
                                    </button>
                                </div>

                                <div className="relative -top-4 ">
                                    <button
                                        disabled={!checkPerm(Resources.Invoices, READ_PERM)}
                                        onClick={() => openInNewTab("/accounting-income?OrganizationID=" + this.props.OrganizationID + "&Organization=" + CustomerStats.LegalName)}>
                                        <div
                                            className="grid grid-cols-7 border border-tm-gray-200 rounded-card relative mt-4 divide-x">
                                            <div
                                                className="absolute -top-2 left-1/2 -translate-x-1/2 bg-inverse px-2 font-bold text-tm-gray-800 z-10">
                                                Factoring {translate("text.AgingReport")}
                                            </div>
                                            <div className="flex flex-col col-start-1 p-4">
                                                <p className="text-tm-gray-800 text-xs leading-4">Age</p>
                                                <p className="mt-auto font-bold leading-5 whitespace-pre  text-tm-gray-800 pt-12">{AgingFactoringReport.Age}</p>
                                            </div>

                                            <div className="flex flex-col p-4">
                                                <p className="text-tm-gray-800 text-xs leading-4">Amount</p>
                                                <p className="mt-auto font-bold leading-5 whitespace-pre  text-tm-gray-800 pt-12">{genericMoneyFormatter(AgingFactoringReport.Amount)}</p>
                                            </div>

                                            <div className="flex flex-col p-4">
                                                <p className="text-tm-gray-800 text-xs leading-4">Balance</p>
                                                <p className="mt-auto font-bold leading-5 whitespace-pre  text-tm-gray-800 pt-12">{genericMoneyFormatter(AgingFactoringReport.OpenInvoices)}</p>
                                            </div>

                                            <div className="flex flex-col p-4">
                                                <p className="text-tm-gray-800 text-xs leading-4">{translate("field.CurrentInvoices")}</p>
                                                <p className="mt-auto font-bold leading-5 whitespace-pre  text-tm-gray-800">{genericMoneyFormatter(AgingFactoringReport.CurrentInvoices)}</p>
                                            </div>

                                            <div className="flex flex-col p-4">
                                                <p className="text-tm-gray-800 text-xs leading-4">{translate("field.PastDue60")}</p>
                                                <p className="mt-auto font-bold leading-5 whitespace-pre  text-tm-gray-800 pt-12">{genericMoneyFormatter(AgingFactoringReport.PastDue30)}</p>
                                            </div>


                                            <div className="flex flex-col p-4">
                                                <p className="text-tm-gray-800 text-xs leading-4">{translate("field.PastDue90")}</p>
                                                <p className="mt-auto font-bold leading-5 whitespace-pre  text-tm-gray-800 pt-12">{genericMoneyFormatter(AgingFactoringReport.PastDue60)}</p>
                                            </div>

                                            <div className="flex flex-col p-4">
                                                <p className="text-tm-gray-800 text-xs leading-4">{translate("field.PastDue90More")}</p>
                                                <p className="mt-auto font-bold leading-5 whitespace-pre  text-tm-gray-800 pt-12">{genericMoneyFormatter(AgingFactoringReport.PastDue90)}</p>
                                            </div>

                                        </div>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={"font-weight-bold text-lg"}>{translate("text.active_and_upcoming_loads")}</div>

                    <TableCard addClass="relative">
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            isLoading={resource.isLoading}
                        />

                        <ResourceTable
                            verticalTable="md"

                            maxHeightClass={this.state.areStatsVisible ? 'max-h-[calc(100vh-29rem)]' : 'max-h-[calc(100vh-22rem)]'}
                            data={data}
                            fields={this.getFields()}
                            onFilterChange={this.handleFilterChange}
                            clearTableColumnFields={this.state.clearTableColumnFields}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            tableKey={'LoadID'}
                        />

                        {!(!data.length && !resource.isLoading) && (
                            <div
                                className="bg-inverse px-4 py-3 flex items-center justify-between border-t border-tm-gray-300 sm:px-6 h-16 rounded-b-lg"
                            >
                                <Pagination
                                    count={count}
                                    isLoading={resource.isLoading}
                                    handleQueryChange={
                                        (name, value, currentPage) => name === "offset"
                                            ? this.handleUpdateOffset(value, currentPage)
                                            : this.handleFilterInputChange(name, value)
                                    }
                                    pageOffset={this.state.offset}
                                    queryFields={this.state.queryFilterFields}
                                    translate={translate}
                                />
                            </div>
                        )}

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={false}
                            title={'No matching records found'}
                            className={"pb-12 pt-16 px-6"}
                        />
                    </TableCard>
                </Card>
            </div>
        )
    }
}
