import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../../data/services/fields'
import Resources from '../../../../data/services/resources'
import {
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    UPDATE_PERM
} from '../../../../util/util-constants'
import ModalDefault from "../../../../common/components/modal/modal-default";
import LocalStorage from "../../../../common/util/localStorage";
import {
    createDialogResource,
    deleteDialogResource,
    getDialogResource,
    updateDialogResource
} from "../../../../data/actions/dialogResource";
import {
    checkPerm,
    getDefaultTableOptions,
    getProp,
    renderInvoiceStatusBadge
} from "../../../../common/util/util-helpers";
import {fieldsToHtml, fillFieldsFromData, includeFields} from "../../../../common/util/util-fields";
import TableCard from "../../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../../common/components/resource-table";
import {CREATE_PERM, DEFAULT_QUERY_LIMIT} from "../../../../common/util/util-consts";
import TableCardFooter from "../../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import {cloneDeep, genericMoneyFormatter} from "../../../../common/util/util-vanilla";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import {LoaderLarge} from "../../../../common/components/loader";
import ResourceTableDialog from "../../../../common/components/modal/resource-table-dialog";
import InvoiceExpenseDetailsDialog from "../invoice-expense-details-dialog";
import {getSecondResource} from "../../../../data/actions/secondResource";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";

export default class BatchDetailsDialog extends Component {

    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1) + 'batch_details_dialog'

        this.pagePathInvoices = this.props.location.pathname.substring(1) + 'Add_Invoices'

        this.tablePageDefaults = {
            behaviour: {
                canAdjustWidth: false,
                hasMenu: false
            },
            style: {
                condensed: false,
                verticalLines: true,
                isGPUAccelerated: true
            }
        }

        this.tablePageDefaultsInvoices = {
            behaviour: {
                rowSelect: true,
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            ...DEFAULT_CRUD_STATE,
            tableOptions: getDefaultTableOptions(this.getFieldsInvoice(), this.tablePageDefaults, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFields(),
            editBatchInfoModalOpen: false,
            AddInvoiceModalOpen: false,
            invoiceExpenseDetails: false,
            selectedItems: {},
            selectedRows: {}
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show && !!this.props.show) {
            this.fetchData();
        }
    }

    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: {InvoiceBatchID: this.props.batchID}
        }))
    }

    getResource = () => {
        return Resources.InvoicesBatch
    }

    getFields = (item) => {
        const fieldTemplates = {
            InvoiceBatch: new Field('InvoiceBatch', '', [], true, 'textarea', {addContainerClass: 'col-span-3'}),
            AssignedToContactID: new Field('AssignedToContactID', '', [''], true, 'select-search', {addContainerClass: 'col-span-3'}),
            IsPayed: new Field('IsPayed', '', [], true, 'checkbox', {label: 'Completed'}),

        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getEditFields = (item) => {
        const fieldTemplates = {
            InvoiceBatch: new Field('InvoiceBatch', '', [], false, 'textarea', {addContainerClass: 'col-span-3'}),
            AssignedToContactID: new Field('AssignedToContactID', '', [''], false, 'select-search', {addContainerClass: 'col-span-3'}),
            IsPayed: new Field('IsPayed', '', [], false, 'checkbox', {label: 'Completed'}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getFieldsInvoice = () => {
        return {
            AutoCounter: new Field('AutoCounter', '', [''], false, 'text', {
                render: (item) => {
                    return (
                        <div className="flex">
                            {item.AutoCounter}
                        </div>
                    )
                }
            }),
            RefNumber: new Field('RefNumber', '', [''], false, 'text'),
            Date: new Field('Date', '', [''], false, 'date'),
            ChargeTo: new Field('ChargeTo', '', [], false, 'custom', {
                render: (item) =>
                    <div>
                        {item.ContactID ? item.Contact : item.Organization}
                    </div>
            }),
            Amount: new Field('Amount', '', [''], false, 'money', {}),
            AmountTransferred: new Field('AmountTransferred', '', [''], false, 'money', {
                addTableHeaderClass: 'ml-auto',
                render: (item) => {
                    return (
                        <div className="flex justify-end w-full">
                            {genericMoneyFormatter(item.Amount - item.AmountTransferred)}
                        </div>
                    )
                }
            }),
            InvoiceStatus: new Field('InvoiceStatus', '', [''], false, 'custom', {
                render: (it) => <div className={"flex mx-auto"}>{renderInvoiceStatusBadge(it)}</div>
            })
        }
    }

    getQueryFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: "top"})
        }
    }

    handleFilterInputChange = (name, value) => {
        const queryFields = FieldsManager.updateField(this.state.queryFilterFields, name, value)
        this.setState({
            queryFilterFields: queryFields,
            offset: 0,
            paginationPage: 1,
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        })
    }


    getPaginatedData = (array, offset, limit) => {
        if (limit < 0) return array.slice(offset);
        return array.slice(offset, offset + Number(limit));
    }

    getItemKeyLabel = () => {
        return this.props.itemKeyLabel ?? ''
    }

    handleToggleEditBatchInfoModal = () => {
        this.setState({editBatchInfoModalOpen: !this.state.editBatchInfoModalOpen})
    }

    handleToggleAddInvoices = () => {
        this.setState({AddInvoiceModalOpen: !this.state.AddInvoiceModalOpen, selectedRows: {}})
    }

    handleSelectRowClick = (item) => {
        const itemID = item.InvoiceID
        let selectedRows = cloneDeep(this.state.selectedRows)

        if (selectedRows[itemID]) {
            delete selectedRows[itemID]
        } else {
            Object.assign(selectedRows, {[itemID]: item})
        }

        this.setState({selectedRows})
    }

    handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(this.props.dialogResource.data, 'list', []))

        let selectedRows = cloneDeep(this.state.selectedRows)

        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                if (!it.InvoiceBatchID) {
                    memo[it.InvoiceID] = it
                }
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.InvoiceID.toString())) {
                    delete selectedRows[it.InvoiceID]
                }
            })
        }

        this.setState({selectedRows})
    }

    handleToggleInvoiceDetailsDialog = (item = null) => {
        this.setState({
            selectedItems: item,
            invoiceExpenseDetails: !this.state.invoiceExpenseDetails
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedConfirmItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    deleteItems = (item = null) => {
        this.props.dispatch(deleteDialogResource({
            user: LocalStorage.get("user"),
            query: {InvoiceBatchID: this.props.batchID, InvoiceIDs: [item.InvoiceID]},
            resource: Resources.InvoicesBatchItems,
            piggyResource: this.getResource(),
            successMessage: this.props.translate('message.invoice_batch_item_removed')
        }));
        this.handleToggleConfirmModal();
    }

    render() {
        const {translate, dialogResource} = this.props

        const dialogData = getProp(dialogResource, 'data', {})
        const invoices = getProp(dialogResource, 'data.Items', [])
        const count = getProp(dialogResource.data, 'Items', 0).length
        const isLoading = getProp(dialogResource, 'isLoading', true);

        const selects = {
            AssignedToContactID: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.ContactID,
                    label: item.FirstName + " " + item.LastName
                })
            },
        }

        const filteredData = invoices.filter(it => {
            let canPass = true
            if (this.state.queryFilterFields.query.value) {
                canPass &=
                    it.RefNumber?.toLowerCase().includes(this.state.queryFilterFields.query.value?.toLowerCase())
                    ||
                    it.CustomerReferenceNumber?.toLowerCase().includes(this.state.queryFilterFields.query.value?.toLowerCase())
                    ||
                    it.Contact?.toLowerCase().includes(this.state.queryFilterFields.query.value?.toLowerCase())
                    ||
                    it.Organization?.toLowerCase().includes(this.state.queryFilterFields.query.value?.toLowerCase())
            }

            return canPass
        })

        return (
            <ModalDefault
                show={this.props.show}
                title={!isLoading ? translate("text.invoiceBatchNumber", [dialogData.AutoCounter]) : ""}
                widthClass={"max-w-[calc(80%)]"}
                closeButtonLabel={translate('btn.close')}
                onClose={this.props.onClose}
            >
                <div className={"grid grid-cols-12 gap-x-10 m-6"}>
                    <div className={"flex col-span-12 mb-4"}>
                        <div className={"flex mr-8 text-lg"}>Invoices count: {dialogData.InvoicesCount}</div>
                        <div className={"flex mr-8 text-lg"}>Total value: {genericMoneyFormatter(dialogData.InvoicesTotal)}</div>

                        <div className={"ml-auto"}>
                            <button className={"btn btn-primary"} disabled={!checkPerm(this.getResource(), UPDATE_PERM)}
                                    onClick={() => this.handleToggleEditBatchInfoModal()}>{translate('btn.edit_batch_info')}</button>
                        </div>
                    </div>

                    {fieldsToHtml(Object.values(Object.assign({}, includeFields(this.getFields(dialogData), ['InvoiceBatch', 'AssignedToContactID','IsPayed']))), translate, () => {
                    }, selects)}

                    {!!isLoading && (
                        <div className={"flex mx-auto"}>
                            <LoaderLarge disableContainer={true} stripesBg={true}/>
                        </div>
                    )}

                    <div className={"col-span-12 my-10"}>
                        <TableCard>
                            <TableFilters
                                hideLimit
                                filterFields={this.state.queryFilterFields}
                                handleInputChange={this.handleFilterInputChange}
                                translate={translate}
                                selects={selects}
                            >
                                <button disabled={!checkPerm(Resources.InvoicesBatchItems, CREATE_PERM)}
                                        className={"flex ml-auto btn btn-primary"}
                                        onClick={() => this.handleToggleAddInvoices()}>Add invoices
                                </button>
                            </TableFilters>

                            <ResourceTable
                                tableKey={'InvoiceID'}
                                data={this.getPaginatedData(filteredData, this.state.offset, this.state.queryFilterFields.limit.value)}
                                count={count}

                                limit={this.state.queryFilterFields.limit.value}

                                sort={this.state.sort}
                                sortBy={this.state.sortBy}
                                onSortChange={this.handleUpdateSort}
                                options={this.state.tableOptions}

                                fields={this.getFieldsInvoice()}
                                translate={this.props.translate}
                                isLoading={isLoading}

                                onView={(it) => this.handleToggleInvoiceDetailsDialog(it)}
                                onRowClick={(it) => this.handleToggleInvoiceDetailsDialog(it)}
                                onDelete={(it) => this.handleToggleConfirmModal(it)}

                                hasDeletePerm={checkPerm(Resources.InvoicesBatchItems, DELETE_PERM)}
                            />
                            <TableCardFooter>
                                <Pagination
                                    count={filteredData.length}
                                    isLoading={isLoading}
                                    handleQueryChange={
                                        (name, value, currentPage) => name === "offset"
                                            ? this.handleUpdateOffset(value, currentPage)
                                            : this.handleFilterInputChange(name, value)
                                    }
                                    pageOffset={this.state.offset}
                                    queryFields={this.state.queryFilterFields}
                                    translate={translate}
                                />
                            </TableCardFooter>
                        </TableCard>
                    </div>
                </div>

                <ModalSaveResource
                    show={this.state.editBatchInfoModalOpen}
                    title={translate('text.edit_batch_info')}
                    widthClass="max-w-[32rem]"
                    gridColsClass="grid-cols-1"
                    onClose={this.handleToggleEditBatchInfoModal}
                    fields={this.getEditFields(dialogData)}
                    closeButtonLabel={translate("btn.cancel")}
                    onSubmit={(params) => {
                        if (params) {
                            params.InvoiceBatchID = this.props.batchID;
                            this.props.dispatch(updateDialogResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                query: {InvoiceBatchID: this.props.batchID},
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                successMessage: translate("message.batch_updated")
                            }))
                            this.handleToggleEditBatchInfoModal()
                        }
                    }}
                    metadata={
                        {
                            AssignedToContactID: {
                                api: 'api/' + Resources.ContactsQuick,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    value: item.ContactID,
                                    label: item.FirstName + " " + item.LastName
                                })
                            }
                        }
                    }
                    translate={translate}
                />

                <ResourceTableDialog
                    show={this.state.AddInvoiceModalOpen}
                    title={translate('title.addInvoiceToBatchModal')}
                    widthClass={"max-w-[calc(80%)]"}
                    onButtonClick={() => {
                        if (this.state.selectedRows) {
                            const InvoiceIDs = Object.keys(this.state.selectedRows);

                            this.props.dispatch(createDialogResource({
                                user: LocalStorage.get("user"),
                                query: {InvoiceBatchID: this.props.batchID},
                                params: {
                                    InvoiceBatchID: this.props.batchID,
                                    InvoiceIDs: InvoiceIDs,
                                },
                                resource: Resources.InvoicesBatchItems,
                                piggyResource: this.getResource(),
                                successMessage: "Invoices added"
                            }));
                            this.handleToggleAddInvoices()
                        }
                    }}
                    buttonLabel={translate('btn.save')}
                    closeButtonLabel={translate('btn.Cancel')}
                    onClose={() => {
                        this.handleToggleAddInvoices();
                        this.fetchData()
                    }}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.Invoices}
                    dispatch={this.props.dispatch}

                    fields={this.getFieldsInvoice()}

                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {addContainerClass: 'col-span-3'}),
                    }}
                    dataFilter={(data) => data.filter((it) => !it.InvoiceBatchID)}

                    options={getDefaultTableOptions(this.getFieldsInvoice(), this.tablePageDefaultsInvoices, this.pagePathInvoices, this.props.translate)}

                    tableKey={"InvoiceID"}
                    onSelectRow={this.handleSelectRowClick}
                    onSelectAllClick={this.handleSelectAllClick}
                    selectedRows={this.state.selectedRows}
                    selects={{
                        ContactGroupID: {
                            api: 'api/' + Resources.ContactGroupsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                label: item.ContactGroupName,
                                value: item.ContactGroupID
                            })
                        }
                    }}
                    buttonDisabled={!Object.keys(this.state.selectedRows).length}
                    translate={translate}
                    isFooterVisible={false}
                    limit={0}
                    htmlAfter={
                        (<div className={"font-semibold m-4 text-base"}>Total invoices
                            selected: {Object.values(this.state.selectedRows).length}</div>)
                    }
                />

                <ModalDefault
                    show={this.state.invoiceExpenseDetails}
                    widthClass={'max-w-full'}
                    title={translate("text.Invoice")}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleInvoiceDetailsDialog()}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={this.props.secondResource}
                        isLoading={this.props.secondResource.isLoading}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        showLoadReferenceLinks={true}

                        disableComments={true}
                        canDeletePayment={false}
                        disableAddReference={true}
                        disableTransactionImport={true}

                        // Data events
                        onFetchData={() => {
                            if (this.state.selectedItems) {
                                this.props.dispatch(getSecondResource({
                                    user: LocalStorage.get('user'),
                                    query: {
                                        InvoiceID: this.state.selectedItems.InvoiceID
                                    },
                                    resource: Resources.InvoicesInvoice
                                }))
                            }
                        }}
                    />
                </ModalDefault>

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmModalOpen}
                    width={"2xl"}
                    text={translate('text.confirm_batch_item_delete', [this.state.selectedConfirmItem?.AutoCounter])}
                    onClose={this.handleToggleConfirmModal}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        if (this.state.selectedConfirmItem) {
                            this.deleteItems(this.state.selectedConfirmItem);
                        }
                    }}
                />
            </ModalDefault>
        )
    }
}

