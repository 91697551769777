import React, {useEffect, useRef, useState} from "react";
import {LayersControl, Map, Marker, Popup, TileLayer} from "react-leaflet";
import L from 'leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster/src/react-leaflet-markercluster";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import ModalDefault from "../../modal-default";
import {layers} from "../../../../../util/util-constants";

const myIcon = L.icon({
    iconSize: [22, 34],
    iconAnchor: [13, 44],
    iconUrl: icon,
    shadowUrl: iconShadow
});

export default function MapDialog({mapData, onClose, translate}) {
    const mapRef = useRef(null);
    const groupRef = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const [showMap, setShowMap] = useState(false);

    const selectedItem = mapData;

    const returnLeafletMarker = (index) => {
        let length = selectedItem.filter(it => (!isNaN(it.Latitude) && !isNaN(it.Longitude))).length
        let background = (length === index + 1) ? "#c70035" : "#00365a"
        return "<div style='background-color: " + background + "' class='marker-pin'><div class='marker-pin-content'>" + index + "</div></div>"
    }

    const handleFitBounds = () => {
        if (Array.isArray(selectedItem) && selectedItem.length > 0) {
            const map = mapRef.current.leafletElement
            const group = groupRef.current.leafletElement
            map.fitBounds(group.getBounds())
        }
    }

    let location
    let position
    if (Array.isArray(selectedItem)) {
        location = selectedItem
            .map((item, index) => {
                let myIcon = L.divIcon({
                    className: 'custom-div-icon',
                    html: returnLeafletMarker(index),
                    iconSize: [30, 42],
                    iconAnchor: [15, 42]
                });
                position = [item.Latitude, item.Longitude]
                return (
                    <Marker
                        key={index}
                        icon={myIcon}
                        position={[item.Latitude, item.Longitude]}>
                        <Popup autoPan={true}>
                            {!!item.PostalCode && (
                                <div>{item.AddressName} {item.CityName} {item.State} {item.PostalCode}</div>
                            )}
                            <div>{item.Latitude},{item.Longitude}</div>
                        </Popup>
                    </Marker>
                )
            })
    } else {
        location = <Marker
            position={[selectedItem.Latitude, selectedItem.Longitude]}
            icon={myIcon}
        />

        position = [selectedItem.Latitude, selectedItem.Longitude]
    }

    useEffect(() => {
        setShowModal(true);
        setTimeout(() => {
            setShowMap(true);
        }, 400)
    }, [setShowMap])

    return (
        <ModalDefault
            show={showModal}
            title={
                <span className="text-xl font-bold truncate mr-6 text-tm-gray-900">
                    {translate("text.map_location")}
                    {!!selectedItem.LocationName && (<span
                        className={"text-small display-inline ml-3"}>{selectedItem.LocationName} - {selectedItem.AddressName} {selectedItem.CityName}, {selectedItem.State} {selectedItem.PostalCode}</span>)}
                </span>
            }
            widthClass={"max-w-7xl w-screen"}
            hideDialogFooter={true}
            onClose={onClose}
            closeButtonLabel={translate("btn.close")}
            translate={translate}
        >
            <>
                {Array.isArray(selectedItem) && (
                    <div className="px-5 py-1.5 text-right">
                        <button
                            onClick={handleFitBounds}
                            className="btn btn-primary">Fit bounds
                        </button>
                    </div>
                )}

                <div className="h-dialog-body h-screen">
                    {showMap && (
                        <div
                            className="leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom">
                            <Map center={position ?? [0, 0]} zoom={13} ref={mapRef}>
                                <LayersControl position="topright">
                                    {layers.map((layer, index) =>
                                        <LayersControl.BaseLayer
                                            key={index}
                                            checked={index === 0}
                                            name={layer.name}>
                                            <TileLayer
                                                url={layer.url}
                                                attribution={layer.attribution}
                                            />
                                        </LayersControl.BaseLayer>
                                    )}
                                    <MarkerClusterGroup
                                        ref={groupRef}
                                    >
                                        {location}
                                    </MarkerClusterGroup>
                                </LayersControl>
                            </Map>
                        </div>
                    )}
                </div>
            </>
        </ModalDefault>
    )
}
