import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {classNames, returnOffsetAndPagination} from '../../../common/util/util-helpers'
import {createResource, deleteResource, getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {showModal} from '../../../data/actions/ui'
import {
    CREATE_PERM, DEFAULT_CRUD_STATE,
    DELETE_PERM,
    feeTypes,
    LOAD_PAY_METHOD_ACC_PERCENT,
    LOAD_PAY_METHOD_NET_PROFIT_PERCENT,
    LOAD_PAY_METHOD_PRICE_ACC_PERCENT, LOAD_PAY_METHOD_PRICE_LOADED_AND_EMPTY_MILES,
    LOAD_PAY_METHOD_PRICE_LOADED_MILES,
    LOAD_PAY_METHOD_PRICE_PERCENT
} from '../../../util/util-constants'
import {checkPerm, getProp} from '../../../common/util/util-helpers'
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import {genericMoneyFormatter} from "../../../common/util/util-vanilla";
import {ArrowPathIcon, TrashIcon} from '@heroicons/react/24/outline'
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ResourceTable from "../../../common/components/resource-table";
import InfoParagraph from "../../../common/components/info-paragraph";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ContactSelectCreateModal from "../../../common/components/modal/contact-select-create-modal";
import TableFooter from "../../../common/components/resource-table/table/table-footer";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";

export default class CommissionsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...DEFAULT_CRUD_STATE,
            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),

            addContactModalOpen: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: this.getQuery()
        }))
    }

    archiveResource = (item) => {
        this.setState({text: this.props.translate('message.are_you_sure_delete_commission')}, () => {
            this.handleToggleConfirmModal(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, this.getQuery(), {CustomerCommisionID: item.CustomerCommisionID}),
                        errorMessage: true, successMessage: `Commission deleted`,
                        resource: this.getResourceName(),
                        piggyResource: this.getResourceName()
                    }))
                    this.setState({confirmDialog: false})
                })
            }, item)
        })
    }

    /** UI events
     ================================================================= */
    handleToggleContactsModal = () => {
        this.setState({addContactModalOpen: true})
    }

    handleFilterInputChange = (name, value) => {
        const queryFilterFields = FieldsManager.updateField(this.state.queryFilterFields, name, value)

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, this.fetchData)
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, this.fetchData)
    }

    handleToggleConfirmModal = (submit = () => {
    }) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    /** Fields/data definitions
     ================================================================= */
    getFields = () => {
        return {
            FirstName: new Field('FirstName', '', [], false, 'custom', {
                render: (item) => {
                    return (
                        <div
                            className="text-primary cursor-pointer font-semibold"
                            onClick={() => this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))}
                        >{item.FirstName} {item.LastName}</div>
                    )
                }
            }),
            Email: new Field('Email', '', []),
            Amount: new Field('Amount', '', [], false, 'custom', {
                render: (item) => {
                    if (item.Amount) {
                        let value = item.Amount
                        if (item.FeeTypeID === 1) {
                            value = genericMoneyFormatter(item.Amount)
                        } else if (
                            item.FeeTypeID === LOAD_PAY_METHOD_PRICE_PERCENT
                            || item.FeeTypeID === LOAD_PAY_METHOD_ACC_PERCENT
                            || item.FeeTypeID === LOAD_PAY_METHOD_PRICE_ACC_PERCENT
                            || item.FeeTypeID === LOAD_PAY_METHOD_NET_PROFIT_PERCENT
                        ) {
                            value = value + '%'
                        } else if (
                            item.FeeTypeID === LOAD_PAY_METHOD_PRICE_LOADED_MILES
                            || item.FeeTypeID === LOAD_PAY_METHOD_PRICE_LOADED_AND_EMPTY_MILES
                        ) {
                            value = value + ' per mile'
                        }
                        return (
                            <>{value}</>
                        )
                    }
                }
            }),
            FeeTypeID: new Field('FeeTypeID', '', [], false, 'custom', {
                render: (item) => {
                    return (
                        <>{this.props.translate(feeTypes[item.FeeTypeID])}</>
                    )
                }
            }),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {
                containerClass: 'col-md-3'
            }, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true})
        }
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            id: this.props.CustomerID,
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            HighImportance: 0,
            ExcludeIDs: this.props.contacts ? this.props.contacts.join(',') : '',
            IsSystemUser: 1
        }
    }

    getResourceName = () => {
        return this.props.resourcePath
    }

    /** Render
     ================================================================= */

    render() {
        const {translate, resource} = this.props
        const data = getProp(resource, 'data.list', [])
        const count = getProp(resource, 'data.count', 0)
        const isLoading = getProp(resource, 'isLoading', 0)

        return (
            <React.Fragment>
                <PageHeader
                    hasPerm={checkPerm(this.getResourceName(), CREATE_PERM)}
                    buttonLabel={translate('btn.add/create')}
                    onButtonClick={() => this.handleToggleContactsModal()}
                />

                <InfoParagraph
                    className="mt-3 mb-3"
                    message={'Fees that are set here are only defaults, values can be changed for each load separately when adding entry to a commission list.'}
                />

                <TableCard>
                    <div className={"flex justify-end p-5 border-tm-gray-300 border-b"}>
                        <button className="btn btn-header" onClick={this.fetchData}>
                            <ArrowPathIcon className={
                                classNames(
                                    "w-5 h-5",
                                    isLoading ? "animate-spin" : undefined,
                                )
                            }/>
                        </button>
                    </div>

                    <ResourceTable
                        data={data}
                        count={count}

                        fields={this.getFields()}
                        translate={translate}
                        isLoading={isLoading}

                        limit={this.state.limit}

                        offset={this.state.offset}
                        page={this.state.paginationPage}
                        paginationButtonLimit={5}
                        onOffsetChange={this.handleUpdateOffset}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        actions={[
                            {
                                tooltipText: () => translate('btn.delete'),
                                hidden: () => !checkPerm(this.getResourceName(), DELETE_PERM),
                                action: this.archiveResource,
                                icon: () => TrashIcon
                            }
                        ]}
                    />

                    <TableCardFooter show={!(!data.length && !isLoading)}>
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === "offset"
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                            hideRowsPerPage
                        />
                    </TableCardFooter>

                    <NoRecordsTable
                        show={(data.length === 0) && !isLoading}
                        canCreate={false}
                        title={translate('text.no_matching_records')}
                        className={"pb-12 pt-16 px-6"}
                    />
                </TableCard>

                {this.state.addContactModalOpen && (
                    <ContactSelectCreateModal
                        {...this.props}
                        show={this.state.addContactModalOpen}
                        title={translate('text.LoadCommissionTemplate')}
                        widthClass={'max-w-7xl'}
                        // ADDITIONAL FIELDS
                        additionalFields={{
                            FeeTypeID: new Field('FeeTypeID', '', ['empty'], false, 'select', {
                                addContainerClass: 'relative md:col-span-2 mb-3'
                            }, {
                                values: Object.keys(feeTypes).reduce((memo, it, i) => {
                                    memo[it] = translate(feeTypes[it])
                                    return memo
                                }, {})
                            }),
                            Amount: new Field('Amount', '', ['float_up_to_12_not_require']),
                        }}

                        // HELPERS
                        query={this.getQuery()}
                        ExcludeIDs={data.map(it => it.ContactID)}

                        // HANDLERS
                        onClose={() => this.setState({addContactModalOpen: false})}
                        handleAddExistingContact={(item, additionalFields) => {
                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                params: {
                                    ContactID: item.ContactID,
                                    id: this.props.CustomerID,
                                    HighImportance: item.HighImportance,
                                    ...additionalFields
                                },
                                query: this.getQuery(),
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName(),
                                errorMessage: true, successMessage: `You added ${item.FirstName} ${item.LastName}`,
                            }))
                            this.setState({addContactModalOpen: false})
                        }}

                        handleCreateNewContact={(fields, phones, files, additionalFields) => {
                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                params: Object.assign({},
                                    {
                                        ...additionalFields,
                                        ...fields,
                                        Phones: phones,
                                        Roles: fields.Roles ?? [],
                                    }, {
                                        ContactID: -1,
                                        id: this.props.match.params.CustomerID
                                    },
                                ),
                                errorMessage: true,
                                successMessage: `You created and added ${fields.FirstName} ${fields.LastName}`,
                                query: this.getQuery(),
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName(),
                                file: this.state.files,
                                fileResource: this.props.resourceImagePath,
                                onResultCallback: (result) => {
                                    if (result && result.status === 0) {
                                        this.setState({addContactModalOpen: false})
                                    }
                                }
                            }))
                        }}
                    />
                )}

                <ModalConfirm
                    title={translate('text.ConfirmDelete')}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.handleToggleConfirmModal()}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />
            </React.Fragment>
        )
    }
}
