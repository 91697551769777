import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../../data/services/fields'
import Resources from '../../../../data/services/resources'
import {DEFAULT_CRUD_STATE, DEFAULT_METADATA_SELECT_SEARCH_QUERY} from '../../../../util/util-constants'
import {cloneDeep, scrollErrorIntoView} from '../../../../common/util/util-vanilla'
import {
    classNames,
    getDefaultContactGroup,
    getDefaultTableOptions,
    getDefaultUserOfficeValues,
    getProp,
    openInNewTab
} from '../../../../common/util/util-helpers'
import moment from 'moment'
import {EyeIcon, TruckIcon, UsersIcon} from '@heroicons/react/24/outline'
import ResourceTable from "../../../../common/components/resource-table";
import ModalDefault from "../../../../common/components/modal/modal-default";
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import FieldsToHtml from "../../../../common/components/fields/fields-to-html";
import ActiveFilters from "../../../../common/components/resource-table/table-components/active-filters";
import TableCard from "../../../../common/components/resource-table/table-components/table-card";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import {fieldsToHtml} from "../../../../common/util/util-fields";
import DriverStatusBadge from "../../../../common/components/badge/driver-status-badge";
import EmployeeStatusBadge from "../../../../common/components/badge/employee-status-badge";
import TableCardFooter from "../../../../common/components/resource-table/table-components/table-card-footer";
import TrucksTableBadge from "../../../fleet/trucks-view/trucks-table-badge";
import {
    DEFAULT_QUERY_LIMIT,
    LOAD_PAY_TO_OPTION_DRIVER_OR_TUCK,
    LOAD_PAY_TO_OPTION_TRUCK
} from "../../../../common/util/util-consts";

const PAYROLL_BATCH_TYPES = [
    {label: 'Drivers', value: 'Drivers'},
    {label: 'Agents', value: 'Agents'},
    {label: 'Employees', value: 'Employees'}
]

export default class PayrollBatchCreateDialog extends Component {
    constructor(props) {
        super(props)
        const selectedTab = this.props?.settings?.LoadPayToOptionID === LOAD_PAY_TO_OPTION_TRUCK
        || (this.props?.settings?.LoadPayToOptionID === LOAD_PAY_TO_OPTION_DRIVER_OR_TUCK && this.props?.settings?.LoadDefaultPayID === LOAD_PAY_TO_OPTION_TRUCK)
            ? "Trucks" : "Contacts";

        this.state = {
            // Defaults and filters
            ...DEFAULT_CRUD_STATE,
            queryFilterFields: this.getQueryFilterFields(true),

            // Fields
            fields: this.getFields(),
            Contacts: [],
            selectedContacts: {},
            reviewContacts: {},
            tableOptions: getDefaultTableOptions(this.getTableFields(), {
                style: {
                    floatingActions: true,
                    stripedRows: true
                }
            }, "payroll-batch-create-dialog", this.props.translate),
            selectedReviewContacts: {},
            tabs: [
                {
                    id: "Contacts",
                    name: "Drivers / Agents / Employees",
                    icon: UsersIcon,
                    ref: React.createRef(),
                    isActive: selectedTab === 'Contacts'
                },
                {
                    id: "Trucks",
                    name: 'Trucks',
                    icon: TruckIcon,
                    ref: React.createRef(),
                    isActive: selectedTab === 'Trucks'
                }
            ],
            selectedTab: selectedTab,
            canSubmit: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        const activeTab = this.state.tabs.find(it => it.isActive)
        if (activeTab?.ref?.current) {
            activeTab.ref.current.focus();
        }

        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.tabs !== prevState.tabs) {
            this.setState({
                queryFilterFields: this.getQueryFilterFields(this.state.selectedTab === "Contacts", this.state.queryFilterFields),
            }, () => {
                this.fetchData();
            });
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.fetchData({query: this.getQuery()}, this.state.selectedTab)
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)})
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.fetchData()
        })
    }

    handleTabChange = (selectedTab) => {
        const tabsUpdate = this.state.tabs.map(tab => {
            tab.isActive = tab.id === selectedTab.id
            return tab;
        });

        this.setState({
            tabs: tabsUpdate,
            selectedTab: selectedTab.id,
            selectedContacts: {}
        });
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
        })
    }

    handleSave = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.onSave(Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                    Items: Object.values(this.state.selectedContacts).map((it) => {
                        return this.state.selectedTab === "Contacts"
                            ? {
                                Name: it.FirstName + ' ' + it.LastName,
                                ContactID: it.ContactID
                            }
                            : {
                                Name: it.TruckNumber,
                                TruckID: it.TruckID
                            }
                    })
                }, {
                    Description: '', // TODO: removed field
                    PayrollBatchTypes: Object.values(this.state.fields.PayrollBatchTypes.value).map(it => {
                        return it?.value
                    }).join(',')
                }))
                this.props.onClose()
            } else {
                scrollErrorIntoView(this.state.fields)
            }
        })
    }

    handleReviewSelectedContactsClick = () => {
        this.setState({
            isViewContactsDialogOpen: true,
            reviewContacts: cloneDeep(this.state.selectedContacts),
            selectedReviewContacts: cloneDeep(this.state.selectedContacts)
        })
    }

    handleCloseReviewSelectedContactsClick = () => {
        this.setState({
            isViewContactsDialogOpen: false,
            reviewContacts: {},
            selectedReviewContacts: {},
            canSubmit: false
        })
    }

    updateSelectedContacts = () => {
        this.setState({
            selectedContacts: this.state.selectedReviewContacts,
            reviewContacts: {},
            selectedReviewContacts: {},
            isViewContactsDialogOpen: false,
            canSubmit: false
        })
    }

    handleViewContactInfoPage = (item) => {
        if (item.DriverID) {
            openInNewTab(`/drivers/info/${item.DriverID}`)
        } else if (item.AgentID) {
            openInNewTab(`/agents/info/${item.AgentID}`)
        } else if (item.EmployeeID) {
            openInNewTab(`/employees/info/${item.EmployeeID}`)
        }

    }

    /** Fields/Data Definitions
     ================================================================= */
    getFields = () => {
        return {
            StartDate: new Field('StartDate', this.getFirstDateOfTheCurrentWeek().add('-7', 'day'), ['empty'], false, 'date', {
                addContainerClass: 'col-span-3',
                htmlBefore: () => <div className="col-span-full">
                    <InfoBar>
                        {this.props.translate('text.selectedDateRange')}
                    </InfoBar>
                </div>
            }),
            EndDate: new Field('EndDate', this.getFirstDateOfTheCurrentWeek(), ['empty'], false, 'date', {
                addContainerClass: 'col-span-3',
            }),
            Date: new Field('Date', moment(), ['empty'], false, 'date', {
                labelRaw: 'Batch date',
                addContainerClass: 'col-span-3',
            }, {placement: 'top-start'}),
            BookDate: new Field('BookDate', moment(), ['empty'], false, 'date', {
                labelRaw: 'Check/Posting date',
                addContainerClass: 'col-span-3',
            }, {placement: 'top-start'}),
            OfficeID: new Field('OfficeID', getDefaultUserOfficeValues(), ['empty'], false, 'select-search',
                {
                    addContainerClass: 'col-span-6',
                }
            ),
            ContactGroupID: new Field(
                'ContactGroupID',
                getDefaultContactGroup(),
                ['empty'],
                false,
                'select-search',
                {
                    addContainerClass: 'col-span-6',
                },
                {}),

            PayrollBatchTypes: new Field('PayrollBatchTypes', '', [''], false, 'select', {addContainerClass: 'col-span-full'}, {
                menuPlacement: 'top',
                options: this.props.payrollBatchOptions,
                isMulti: true
            }),
            Name: new Field('Name', '', [], false, 'textarea', {
                addContainerClass: 'col-span-full',
                label: 'notes'
            }),
        }
    }

    getTruckFields = () => {
        return {
            TruckNumber: new Field('TruckNumber', '', [''], false, 'custom', {
                    render: (it) => <button
                        className="text-primary hover:text-tm-gray-900 hover:bg-tm-gray-200 h-12 w-full text-left px-2"
                        onClick={(e) => {
                            e.stopPropagation();
                            window.open(`${window.location.origin}/trucks/info/${it.TruckID}`, '_blank');
                        }}
                    >
                        {it.TruckNumber}
                    </button>
                }
            ),
            PayToOrganization: new Field('PayToOrganization', '', [''], false, "text"),
            StatusID: new Field('StatusID', '', [''], false, 'custom', {
                render: (item) => (
                    <TrucksTableBadge
                        item={item}
                        translate={this.props.translate}
                    />
                )
            }),
            VIN: new Field('VIN', '', ['']),
            TruckLicenceNumber: new Field('TruckLicenceNumber', '', [''], false, "text"),
            Model: new Field('Model', '', ['']),
            Year: new Field('Year', '', [''], false, 'text'),

            ContactGroup: new Field('ContactGroup', '', [], false, 'text', {label: "FleetGroupID"})
        }
    }

    getTableFields = () => {
        return {
            FirstName: new Field('FirstName', '', ['empty']),
            LastName: new Field('LastName', '', ['empty']),
            Email: new Field('Email', '', ['empty', 'email']),
            Status: new Field('Status', '', [''], false, 'custom', {
                omitSort: true,
                render: (item) => {
                    if (!item.DriverStatusID
                        && !item.EmployeeStatusID
                        && !item.AgentStatusID
                    ) {
                        return null
                    }

                    item.StatusID = item.DriverID ?
                        item.DriverStatusID : (item.EmployeeID ? item.EmployeeStatusID : item.AgentStatusID)
                    if (item.DriverID) {
                        return <DriverStatusBadge item={item} translate={this.props.translate}/>
                    } else {
                        return <EmployeeStatusBadge item={item}
                                                    translate={this.props.translate}/>
                    }
                }
            }),
            DriverPositionTypeID: new Field('DriverPositionTypeID', '', [], false, 'select')
        }
    }

    /** Query Definitions
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            IncludeStatuses: 1,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            ExcludeIDs: this.state.Contacts.map(it => it.ContactID).join(', '),
            IsDriver: this.state.queryFilterFields?.PayrollBatchTypes?.value?.value === 'Drivers' ? 1 : 0,
            IsAgent: this.state.queryFilterFields?.PayrollBatchTypes?.value?.value === 'Agents' ? 1 : 0,
            IsEmployee: this.state.queryFilterFields?.PayrollBatchTypes?.value?.value === 'Employees' ? 1 : 0
        }
    }

    getQueryFilterFields = (areContactFilters, prevQuery = undefined) => {
        let fieldTemplates = {
            query: new Field('query', '', [''], false, 'search'),
            Groups: new Field('Groups', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            PayrollBatchTypes: new Field('PayrollBatchTypes', '', [''], false, !areContactFilters ? 'hidden' : 'select', {}, {
                isClearable: true,
                options: PAYROLL_BATCH_TYPES
            }),
            NotSoldRetired: new Field('NotSoldRetired', '1', [''], false, areContactFilters ? 'hidden' : 'checkbox'),
            HighImportance: new Field('HighImportance', '', [''], false, !areContactFilters ? 'hidden' : 'checkbox'),
            HasActiveEmployment: new Field('HasActiveEmployment', '1', [''], false, !areContactFilters ? 'hidden' : 'checkbox', {
                label: "isActive",
                activeFilterLabel: "ActiveEmployment"
            }),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true, labelType: 'float'})
        }

        if (prevQuery) {
            Object.values(prevQuery).forEach(item => {
                fieldTemplates[item.name].value = item.value;
            })
        }

        if (areContactFilters) {
            fieldTemplates.NotSoldRetired.value = '';
            fieldTemplates.HasActiveEmployment.value = '1';
        } else {
            fieldTemplates.NotSoldRetired.value = '1';
            fieldTemplates.PayrollBatchTypes.value = '';
            fieldTemplates.HighImportance.value = '';
            fieldTemplates.HasActiveEmployment.value = '';
        }

        return fieldTemplates;
    }

    handleSelectContact = (row, id) => {
        const selectedContacts = cloneDeep(this.state.selectedContacts)

        if (selectedContacts[id]) {
            delete selectedContacts[id]
        } else {
            selectedContacts[id] = row
        }

        this.setState({
            selectedContacts
        })
    }

    handleSelectAllContact = (areAllSelected) => {
        const selectedContacts = cloneDeep(this.state.selectedContacts)
        getProp(this.props.resource, 'list', []).map(item => {
            if (!areAllSelected) {
                selectedContacts[item.ContactID] = item
            } else {
                delete selectedContacts[item.ContactID]
            }
        })

        this.setState({
            selectedContacts,
        })
    }

    handleSelectReviewContact = (row, id) => {
        const selectedReviewContacts = cloneDeep(this.state.selectedReviewContacts)

        if (selectedReviewContacts[id]) {
            delete selectedReviewContacts[id]
        } else {
            selectedReviewContacts[id] = row
        }

        this.setState({
            selectedReviewContacts,
            canSubmit: true
        })
    }

    handleSelectAllReviewContact = (areAllSelected) => {
        const selectedReviewContacts = cloneDeep(this.state.selectedReviewContacts)
        getProp(this.props.resource, 'list', []).map(item => {
            if (!areAllSelected) {
                selectedReviewContacts[item.ContactID] = item
            } else {
                delete selectedReviewContacts[item.ContactID]
            }
        })

        this.setState({
            selectedReviewContacts,
            canSubmit: true
        })
    }

    /** Helpers
     ================================================================= */
    getFirstDateOfTheCurrentWeek = () => {
        return moment().startOf('isoWeek')
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, show, onClose, resource, isLoading} = this.props

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, {
            ContactGroupID: {
                api: 'api/' + Resources.ContactGroupsQuick,
                query: {},
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            },
            OfficeID: {
                api: 'api/' + Resources.OfficesQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.OfficeName,
                    value: item.OfficeID
                })
            }
        })

        const data = getProp(resource, 'list', [])
        const count = getProp(resource, 'count', 0)
        const selectedContactsCount = Object.keys(this.state.selectedContacts)?.length ?? 0

        const LoadPayToOptionID = this.props?.settings?.LoadPayToOptionID;
        const hasTabsVisible = LoadPayToOptionID === 3;

        return (
            // Create batch dialog
            <ModalDefault
                show={show}
                widthClass={'max-w-full'}
                limitHeight={true}
                title={translate('text.BatchCreate')}
                onButtonClick={this.handleSave}
                buttonDisabled={!selectedContactsCount}
                buttonLabel={translate('btn.save')}
                closeButtonLabel={translate('btn.Cancel')}
                secondaryButtonLabel={
                    <span className={selectedContactsCount ? 'pr-8' : undefined}>
                            {
                                this.state.selectedTab === "Contacts"
                                    ? translate('btn.view_selected_contacts')
                                    : translate('btn.view_selected_trucks')
                            }
                        {!!selectedContactsCount && (
                            <div
                                className="absolute right-0 top-0 bottom-0 w-10 bg-inverse border-l border-primary rounded-r-btn flex items-center justify-center">
                                <span className="leading-5 text-xs">
                                    {selectedContactsCount}
                                </span>
                            </div>
                        )}
                    </span>
                }
                secondaryButtonDisabled={!selectedContactsCount}
                onSecondaryButtonClick={this.handleReviewSelectedContactsClick}
                onClose={onClose}
            >
                <div className="flex relative pb-3">
                    {hasTabsVisible && (
                        <div className="w-48 flex-shrink-0 h-full sticky top-0">
                            <div className="py-6 pl-2 space-y-2">
                                {this.state.tabs.map(tab => {
                                    return <button
                                        key={tab.id}
                                        ref={tab.ref}
                                        onClick={() => this.handleTabChange(tab)}
                                        className={
                                            classNames(
                                                tab.isActive ? "text-tm-gray-800 bg-primary-transparent" : "text-tm-gray-500",
                                                "hover:text-tm-gray-900 w-full text-left flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold focus:outline-none focus:ring-2 focus:ring-primary"
                                            )
                                        }
                                    >
                                        <tab.icon className='w-5 h-5 flex-shrink-0'/>

                                        {tab.name}
                                    </button>
                                })}
                            </div>
                        </div>
                    )}

                    <div
                        className={
                            classNames(hasTabsVisible ? 'max-w-[calc(100%-12rem)]' : 'max-w-full',
                                'space-y-5 flex-grow'
                            )
                        }
                    >
                        <div className="pt-3 px-6">
                            <TableCard addClass={'rounded-none'}>
                                <div className={'pl-5 pt-3'}>
                                    <ActiveFilters
                                        filterFields={this.state.queryFilterFields}
                                        onLabelClick={this.handleFilterInputChange}
                                        onClearFiltersClick={this.handleClearFiltersClick}
                                        translate={translate}
                                    />
                                </div>
                                <div className="grid grid-cols-5 gap-4 px-6 pt-2 pb-4 border-b border-tm-gray-300">
                                    <FieldsToHtml
                                        fieldsState={this.state.queryFilterFields}
                                        excludeFields={['limit']}
                                        onInputChange={this.handleFilterInputChange}
                                        translate={translate}
                                        selects={{
                                            Groups: {
                                                api: 'api/' + Resources.ContactGroupsQuick,
                                                query: {},
                                                searchMap: (item) => ({
                                                    label: item.ContactGroupName,
                                                    value: item.ContactGroupID
                                                })
                                            }
                                        }}
                                    />
                                </div>

                                <ResourceTable
                                    key={this.state.selectedTab}
                                    data={data}
                                    count={count}
                                    maxHeightClass={'min-h-[17.3rem] max-h-[calc(100vh-53.5rem)]'}
                                    tableKey={this.state.selectedTab === 'Contacts' ? 'ContactID' : 'TruckID'}
                                    onSelectRow={(row) => this.handleSelectContact(row, this.state.selectedTab === 'Contacts' ? row.ContactID : row.TruckID)}
                                    onSelectAllClick={this.handleSelectAllContact}
                                    selectedRows={this.state.selectedContacts}

                                    onRowClick={(row) => this.handleSelectContact(row, this.state.selectedTab === 'Contacts' ? row.ContactID : row.TruckID)}

                                    fields={this.state.selectedTab === 'Contacts' ? this.getTableFields() : this.getTruckFields()}

                                    translate={this.props.translate}
                                    isLoading={isLoading}

                                    limit={this.state.queryFilterFields.limit.value}
                                    offset={this.state.offset}
                                    page={this.state.paginationPage}
                                    paginationButtonLimit={5}
                                    onOffsetChange={this.handleUpdateOffset}

                                    sort={this.state.sort}
                                    sortBy={this.state.sortBy}
                                    onSortChange={this.handleUpdateSort}
                                    options={this.state.tableOptions}
                                    actions={[
                                        {
                                            action: (item) => this.handleViewContactInfoPage(item),
                                            icon: EyeIcon,
                                            visible: () => true,
                                            title: translate('btn.open_in_new_tab')
                                        }
                                    ]}
                                />

                                <NoRecords
                                    addClass={'mt-5'}
                                    show={(data.length === 0) && !isLoading}
                                    title={translate('text.no_records')}
                                />

                                <TableCardFooter
                                    addClass=""
                                    show={!(!data.length && !isLoading)}
                                >
                                    <Pagination
                                        count={count}
                                        isLoading={isLoading}
                                        handleQueryChange={
                                            (name, value, currentPage) => name === 'offset'
                                                ? this.handleUpdateOffset(value, currentPage)
                                                : this.handleFilterInputChange(name, value)
                                        }
                                        pageOffset={this.state.offset}
                                        queryFields={this.state.queryFilterFields}
                                        translate={translate}
                                    />
                                </TableCardFooter>
                            </TableCard>
                        </div>

                        <div
                            className="grid gap-4 grid-cols-12 border border-tm-gray-300 rounded-card bg-tm-gray-100 p-6 mx-6">
                            {fieldsHtml}
                        </div>
                    </div>
                </div>

                <ModalDefault
                    show={this.state.isViewContactsDialogOpen}
                    widthClass={'max-w-xl'}
                    translate={translate}
                    title={
                        this.state.selectedTab === "Contacts"
                            ? translate('modal_heading.review_selected_contacts')
                            : translate('modal_heading.review_selected_trucks')
                    }
                    buttonLabel={translate('btn.Update')}
                    onButtonClick={this.updateSelectedContacts}
                    buttonDisabled={!this.state.canSubmit}
                    onClose={this.handleCloseReviewSelectedContactsClick}
                    closeButtonLabel={translate('btn.cancel')}
                >
                    <ResourceTable
                        commonTable={true}
                        data={Object.values(this.state.reviewContacts)}
                        count={count}

                        tableKey={this.state.selectedTab === 'Contacts' ? 'ContactID' : 'TruckID'}
                        onSelectRow={this.handleSelectReviewContact}
                        selectedRows={this.state.selectedReviewContacts}
                        onSelectAllClick={this.handleSelectAllReviewContact}

                        fields={
                            this.state.selectedTab === "Contacts"
                                ? {
                                    FirstName: new Field('FirstName', '', ['empty']),
                                    LastName: new Field('LastName', '', ['empty']),
                                    Email: new Field('Email', '', ['empty', 'email']),
                                }
                                : {
                                    TruckNumber: new Field('TruckNumber', '', ['empty']),
                                    VIN: new Field('VIN', '', ['empty']),
                                    TruckLicenceNumber: new Field('TruckLicenceNumber', '', ['empty', 'email']),
                                }
                        }

                        translate={this.props.translate}

                        options={{
                            style: {
                                floatingActions: false,
                                stripedRows: true
                            }
                        }}
                    />
                </ModalDefault>
            </ModalDefault>
        )
    }
}
