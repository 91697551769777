import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getResource, updateResource} from "../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import {Field, FieldsManager} from "../../../data/services/fields";
import {cloneDeep} from "../../../common/util/util-vanilla";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import {LoaderLarge} from "../../../common/components/loader";
import FieldSwitchLabel from "../../../common/components/fields/field-switch/field-switch-label";
import {fieldsToHtml, fillFieldsFromData, includeFields} from "../../../common/util/util-fields";
import Card from "../../../common/components/card";
import InfoParagraph from "../../../common/components/info-paragraph";

export default function FleetSettingsGeneralTab({resourcePath, translate}) {

    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const isLoading = resource.isLoading;

    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: getResourceName()
        }))
    }

    const onSubmit = () => {
        const validatedFields = FieldsManager.validateFields(fields)
        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: FieldsManager.getFieldKeyValues(validatedFields),
                resource: getResourceName(),
                piggyResource: getResourceName(),
                errorMessage: true,
                successMessage: translate("message.settings_updated")
            }))
            setIsStateDirty(false)
        } else {
            setFields(validatedFields)
        }
    }

    const getFields = (item) => {
        const fieldTemplates = {
            PreventDoubleTruckNumber: new Field('PreventDoubleTruckNumber', '', [''], false, 'switch', {
                addContainerClass: "pr-4 col-span-full flex items-center rounded-xl bg-inverse shadow border border-tm-gray-300",
                htmlBefore: () =>
                    <FieldSwitchLabel
                        label={translate("field.PreventDoubleTruckNumber")}
                        note={translate("text.PreventDoubleTruckNumber")}
                    />
            }),
            PreventDoubleTrailerNumber: new Field('PreventDoubleTrailerNumber', '', [''], false, 'switch', {
                addContainerClass: "pr-4 col-span-full flex items-center rounded-xl bg-inverse shadow border border-tm-gray-300",
                htmlBefore: () =>
                    <FieldSwitchLabel
                        label={translate("field.PreventDoubleTrailerNumber")}
                        note={translate("text.PreventDoubleTrailerNumber")}
                    />
            }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    /** UI events
     ================================================================= */
    const handleInputChange = (name, value) => {
        setIsStateDirty(true);
        if (value === undefined) return;

        setFields(FieldsManager.updateField(cloneDeep(fields), name, value));
    }

    /** State
     ================================================================= */
    const [fields, setFields] = useState(getFields())
    const [isStateDirty, setIsStateDirty] = useState(false)

    /** Helpers
     ================================================================= */
    const getResourceName = () => {
        return resourcePath;
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (resource?.data && !resource?.isLoading) {
            setFields(getFields(cloneDeep(resource.data)));
        }
    }, [resource]);


    const dispatchFleetCreationFields = fieldsToHtml(Object.values(includeFields(fields, ['PreventDoubleTruckNumber', 'PreventDoubleTrailerNumber'])), translate, handleInputChange)

    const dispatchFleetCreationNotes = 'The following values will be used to determine if truck or trailers numbers can be repeated.'

    return (
        <React.Fragment>
            <div className="pb-10">

                {!isLoading && (
                    <div className={"max-w-3xl mx-auto space-y-8"}>
                        <Card bodyClass={"py-5 px-6 space-y-4"}>
                            <h1 className={"text-lg leading-6 font-medium text-tm-gray-900"}>{translate('text.fleet_rules')}</h1>
                            <InfoParagraph
                                message={dispatchFleetCreationNotes}
                            />
                            {dispatchFleetCreationFields}
                        </Card>
                    </div>
                )}

                {!!isLoading && (
                    <LoaderLarge/>
                )}
            </div>

            <PageFooter
                translate={translate}
                canSubmit={isStateDirty}
                actionCancel={() => setFields(getFields(cloneDeep(resource.data)))}
                actionSubmit={onSubmit}
            />
        </React.Fragment>
    )
}
