import React, {Component} from 'react'
import {connect} from 'react-redux'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {hideGlobalModal, showGlobalModal} from '../../../data/actions/ui'
import {
    checkPerm,
    classNames,
    getDefaultFieldOrder,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getLookup,
    getProp,
    mergeDeep,
    orderFields,
    renderTaskDueDate,
    resourceIsCreated,
    resourceIsLoaded,
    resourceIsUpdated,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import Tippy from '@tippyjs/react'
import {AdjustmentsVerticalIcon, Bars4Icon, StarIcon, ViewColumnsIcon} from '@heroicons/react/24/outline'
import {CalendarIcon, ChevronUpIcon as ChevronUpSolidIcon, Squares2X2Icon} from '@heroicons/react/20/solid'
import {
    COLOR_PICKER_COLOR,
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    TASK_STATUS_CLOSED,
    TASK_STATUS_IN_PROGRESS,
    TASK_STATUS_OPEN,
    UPDATE_PERM
} from '../../../util/util-constants'
import {currentDate, timePickerValueToServerTime, toBackDate, toBackDateTime,} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import ClockIcon from '@heroicons/react/24/outline/ClockIcon'
import StarSolidIcon from "@heroicons/react/20/solid/StarIcon";
import FireSolidIcon from "@heroicons/react/20/solid/FireIcon";
import FireIcon from "@heroicons/react/24/outline/FireIcon";
import {getDialogResource,} from "../../../data/actions/dialogResource";
import TasksDateTimePicker from "../tasks-datetime-picker-filter";
import PlayIcon from "@heroicons/react/24/outline/PlayIcon";
import CheckCircleIcon from "@heroicons/react/24/outline/CheckCircleIcon";
import Cog6ToothIcon from "@heroicons/react/24/outline/esm/Cog6ToothIcon";
import {updateSecondResource} from "../../../data/actions/secondResource";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import {LoaderSmall} from "../../../common/components/loader";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import PopOver from "../../../common/components/popover";
import Layout from "../../../common/components/layout";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import TaskStatusProgressTableV2
    from "../../../common/components/resource-table/table-components/status-progress-table/task-status-progress-table-v2";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import Modal from "../../../common/components/modal";
import TasksInfoDialog from "../../../common/components/modal/allTasksDialog/tasksInfoDialog";
import TaskReassignDialog from "../../../common/components/modal/allTasksDialog/task-reassign-dialog";
import TableTagManager from "../../../common/components/resource-table/table-components/table-tag-manager";
import SimpleBoard from "../../../common/components/simple-board";
import TasksFormDialog from "../../../common/components/layout/global-dialogs/task-form";
import {excludeFields, fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import ResourceTable from "../../../common/components/resource-table";
import {deleteResourceDiff, getResourceDiff, updateResourceDiff} from "../../../data/actions/resourceDiff";
import {getUser} from "../../../common/util/util-auth";
import {DEFAULT_QUERY_LIMIT, READ_PERM} from "../../../common/util/util-consts";

class AllTasksView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                hasMenu: true,
                canAdjustWidth: true,
            },
            columns: {
                TaskName: {
                    minWidth: 220,
                    subColumns: [{'Labels': {show: true, name: 'Labels'}}]
                },
                Labels: {minWidth: 200, show: false},
            }
        }

        this.colors = COLOR_PICKER_COLOR;

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: 'DueDate',
            sort: 'ASC',

            fields: this.getFields(),
            fieldsOrder: getDefaultFieldOrder(this.getFields(), this.pagePath),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),

            queryFilterFields: this.getQueryFilterFields(),

            prefilled: '',
            selectedRows: {},
            selectedLabels: {},
            updatedRows: {},
            tagColor: this.colors[0],
            tagsData: [],

            dataView: LocalStorage.has('dataView') ? LocalStorage.get('dataView') : 'table',

            confirmDialog: false,
            confirmDialogHideAnimation: false,
            createTaskDialog: false,
            breakpoint: {},
            taskInfoDialog: false,
            selectedItem: undefined,
            taskGroupField: this.getTaskGroupField(),
            infoMessage: '',
            isReassignDialogOpen: false,
            isAutoRefreshEnabled: false,

            selectedTaskID: null
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath));

        this.state.queryFilterFields = this.getStoredQueryFilters(this.state.dataView, true);

        this.taskStatuses = getLookup('TaskStatus', 'TaskStatusID', 'TaskStatus') ?? [];
        this.PopoverButtonRef = React.createRef();
        this.taskStatusFieldRef = React.createRef();

        this.autoRefreshTimeout = undefined;
        this.autoRefresTime = 60000;
        this.updatedResource = [];
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.handleCheckIfRedirected();
        this.fetchData();
        this.fetchLabels();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.taskResource.create !== this.props.taskResource.create && !!this.props.taskResource.create?.id) {
            this.fetchData();
        }

        if ((prevProps.taskResource.update !== this.props.taskResource.update) && !!this.props.taskResource.update) {
            this.fetchData();
        }

        if (resourceIsCreated(this.props.dialogResource, prevProps.dialogResource) && this.props.dialogResource.resource === "colortag") {
            this.fetchData();
        }

        if (resourceIsUpdated(this.props.dialogResource, prevProps.dialogResource) && this.props.dialogResource.resource === "tasks/color") {
            this.fetchData();
        }

        if (resourceIsLoaded(this.props.dialogResource, prevProps.dialogResource) && this.props.dialogResource.resource === "colortag") {
            this.setState({
                tagsData: getProp(this.props.dialogResource, 'data', [])
            });
        }

        if (prevProps.resourceDiff !== this.props.resourceDiff) {
            const assignedTasks = getProp(this.props.resourceDiff.data, 'assignedTasksCount', [])
            const inProgressTasks = getProp(this.props.resourceDiff.data, 'inProgressTasksCount', [])
            let infoMessageText = ''
            if (assignedTasks === 0) {
                infoMessageText = this.props.translate("text.tasks_info_no_tasks")
            }
            if (assignedTasks === 1 && inProgressTasks === 0) {
                infoMessageText = this.props.translate("text.tasks_info_one_assigned")
            }
            if (assignedTasks === 1 && inProgressTasks === 1) {
                infoMessageText = this.props.translate("text.tasks_info_one_assigned_and_progressing")
            }
            if (assignedTasks > 1 && inProgressTasks >= 1) {
                infoMessageText = this.props.translate("text.tasks_info_multiple_assigned_and_progressing", [assignedTasks, inProgressTasks])
            }
            if (assignedTasks > 1 && inProgressTasks === 0) {
                infoMessageText = this.props.translate("text.tasks_info_multiple_assigned_and_no_progressing", [assignedTasks])
            }

            this.updatedResource = this.props.resourceDiff?.data?.list ?? [];

            this.setState({infoMessage: infoMessageText})
        }
    }

    componentWillUnmount() {
        clearInterval(this.autoRefreshTimeout);
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.updatedResource = [];

        this.props.dispatch(getResourceDiff({
            user: LocalStorage.get('user'),
            data: {isInitial: true},
            resource: Resources.Tasks,
            query: this.getQuery()
        }))

        if (this.state.isAutoRefreshEnabled) {
            clearTimeout(this.autoRefreshTimeout);
            this.autoRefreshTimeout = setInterval(this.autoFetchData, this.autoRefresTime);
        }
    }

    autoFetchData = () => {
        this.props.dispatch(getResourceDiff({
            user: LocalStorage.get('user'),
            resource: Resources.Tasks,
            data: {
                deltaKey: "TaskID",
                compareKeys: Object.values(this.state.tableOptions?.columns).filter(it => it.show).map(it => it.name)
            },
            query: this.getQuery()
        }))
    }

    fetchLabels = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: Resources.ColorTag
        }))
    }

    archiveResource = (item) => {
        this.setState({
            text: this.props.translate("message.confirm_archive_generic") + ` ${item.TaskName}?`,
            title: this.props.translate('text.ConfirmDelete')
        }, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resourceDiff.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResourceDiff({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, this.getQuery(), {id: item.TaskID}),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [item.TaskName]),
                        resource: Resources.Tasks,
                        piggyResource: Resources.Tasks
                    }))
                })
            }, item)
        })
    }

    handleDroppedItem = (item, TaskStatusID) => {
        item.TaskStatusID = TaskStatusID;

        setTimeout(() => {
            this.props.dispatch(updateSecondResource({
                user: LocalStorage.get('user'),
                params: {
                    id: item.TaskID,
                    TaskStatusID: TaskStatusID,
                },
                query: this.getQuery(),
                errorMessage: true,
                successMessage: this.props.translate('text.TaskStatusUpdatedTo', [item.TaskName, getLookup('TaskStatus')[TaskStatusID]]),
                resource: Resources.MineTasks,
                piggySecondResource: Resources.Tasks,
            }));
        }, 1000);
    }

    restoreFromArchive = (item) => {
        this.setState({text: this.props.translate("message.confirm_restore_generic") + ` ${item.TaskName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    confirmDialog: false,
                }, () => {
                    this.props.dispatch(updateResourceDiff({
                        user: LocalStorage.get('user'),
                        params: {
                            id: item.TaskID,
                            ArchivedDate: 1
                        },
                        query: this.getQuery(),
                        errorMessage: true, successMessage: `Task ${item.TaskName} restored`,
                        resource: Resources.Tasks,
                        piggyResource: Resources.Tasks
                    }))
                })
            }, item)
        })
    }

    handleSetSingleMarkedClick = (item) => {
        this.props.dispatch(updateResourceDiff({
            user: LocalStorage.get('user'),
            params: {
                IDs: [item.TaskID],
                Marked: item.Marked ? 0 : 1
            },
            query: this.getQuery(),
            resource: Resources.TasksMark,
            piggyResource: this.getResource(),
            errorMessage: true,
            successMessage: `Task ${item.TaskID} ${item.Marked ? 'removed from ' : 'added to '} Needs Attention!`
        }))
    }

    handleSetMarkedClick = (isMarkAction = true) => {
        const selectedRows = Object.keys(this.state.selectedRows)

        this.props.dispatch(updateResourceDiff({
            user: LocalStorage.get('user'),
            params: {
                IDs: selectedRows,
                Marked: isMarkAction ? 1 : 0
            },
            query: this.getQuery(),
            resource: Resources.TasksMark,
            piggyResource: this.getResource(),
            errorMessage: true,
            successMessage: isMarkAction ? this.props.translate('message.selection_add_marked') : this.props.translate('message.selection_remove_marked')
        }))

        this.setState({selectedRows: {}})
    }

    handleSetPinnedClick = (TaskPin = true) => {
        const selectedRows = Object.keys(this.state.selectedRows)

        this.props.dispatch(updateResourceDiff({
            user: LocalStorage.get('user'),
            params: {
                TaskIDs: selectedRows,
                TaskPinned: TaskPin ? 1 : 0
            },
            query: this.getQuery(),
            resource: Resources.TaskPin,
            piggyResource: this.getResource(),
            errorMessage: true,
            successMessage: TaskPin ? this.props.translate('message.selection_add_favourite') : this.props.translate('message.selection_remove_favourite')
        }))

        this.setState({selectedRows: {}})
    }

    toggleAutoRefresh = () => {
        this.setState({
                isAutoRefreshEnabled: !this.state.isAutoRefreshEnabled
            }, () => {
                this.saveFilters();

                if (this.state.isAutoRefreshEnabled) {
                    this.autoRefreshTimeout = setInterval(this.autoFetchData, this.autoRefresTime);
                } else {
                    clearTimeout(this.autoRefreshTimeout);
                }
            }
        )
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleCheckIfRedirected = () => {
        const query = new URLSearchParams(this.props.location.search)
        const LinkedTaskID = query.get('LinkedTaskID')
        if (LinkedTaskID) {
            this.handleCreateUpdateResource({TaskID: +LinkedTaskID})
        }
        const TaskID = query.get('TaskID')
        if (TaskID) {
            this.handleCreateUpdateResource({TaskID: TaskID})
        }
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterClear = () => {
        this.setState({
            queryFilterFields: this.getQueryFilterFields(),
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        let queryFields = FieldsManager.updateField(this.state.queryFilterFields, name, value)

        if (name === 'DueStartTime' && !this.state.queryFilterFields.DueStartDate.value) {
            queryFields.DueStartDate.value = currentDate()
        }

        if (name === 'DueEndTime' && !this.state.queryFilterFields.DueEndDate.value) {
            queryFields.DueEndDate.value = currentDate()
        }

        if (name === 'StartStartTime' && !this.state.queryFilterFields.StartStartDate.value) {
            queryFields.StartStartDate.value = currentDate()
        }

        if (name === 'StartEndTime' && !this.state.queryFilterFields.StartEndDate.value) {
            queryFields.StartEndDate.value = currentDate()
        }

        queryFields.DueStartDate.props.maxDate = queryFields.DueEndDate.value;
        queryFields.DueEndDate.props.minDate = queryFields.DueStartDate.value;

        queryFields.StartStartDate.props.maxDate = queryFields.StartEndDate.value;
        queryFields.StartEndDate.props.minDate = queryFields.StartStartDate.value;

        this.setState({
            queryFilterFields: queryFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: true
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleCreateUpdateResource = (item = null, prefilled = '') => {
        this.setState({prefilled: prefilled, selectedTaskID: item?.TaskID,}, () => {
            this.props.dispatch(showGlobalModal('createTaskDialog', item))
        })
    }

    getUpdateStatusIcon = (item) => {
        switch (item?.TaskStatusID) {
            case TASK_STATUS_OPEN:
                return <PlayIcon className="w-5 h-5 text-sky-500"/>
            case TASK_STATUS_IN_PROGRESS:
                return <CheckCircleIcon className="w-5 h-5 text-green-500"/>
            default:
                return <Cog6ToothIcon className="w-5 h-5"/>
        }
    }

    getUpdateStatusIconGrid = (item) => {
        switch (item?.TaskStatusID) {
            case TASK_STATUS_OPEN:
                return <PlayIcon className="w-5 h-5 text-blue-600"/>
            case TASK_STATUS_IN_PROGRESS:
                return <CheckCircleIcon className="w-5 h-5 text-green-600"/>
            default:
                return <Cog6ToothIcon className="w-5 h-5 text-tm-gray-600"/>
        }
    }
    getTooltipText = (it) => {
        return 'Set to ' + this.taskStatuses[(it.TaskStatusID + 1)]
    }

    toggleDetailsDialog = (item = null) => {
        this.setState({
            taskInfoDialog: !!item,
            selectedItem: item
        })
    }

    handleChangeTaskStatus = (item, status = null) => {

        if (status == TASK_STATUS_CLOSED) {
            return
        }

        const nextTaskLabel = status ? getLookup('TaskStatus')[status] : this.taskStatuses[(item.TaskStatusID + 1)]

        this.setState({
            text: this.props.translate('message.text_task_confirm') + ' \'' + nextTaskLabel + '\'',
            title: this.props.translate('text.update_status')
        }, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    offset: (getProp(this.props.resourceDiff.data, 'list', []).length === 1) ? ((this.state.offset - this.state.limit) >= 0 ? this.state.offset - this.state.limit : this.state.offset) : this.state.offset,
                    paginationPage: (getProp(this.props.resourceDiff.data, 'list', []).length === 1) ? this.state.paginationPage - 1 : this.state.paginationPage,
                    confirmDialog: false,
                }, () => {
                    this.props.dispatch(updateResourceDiff({
                        user: LocalStorage.get('user'),
                        params: {
                            id: item.TaskID,
                            TaskStatusID: status ? status : item.TaskStatusID + 1,
                            SkipWatchers: 1
                        },
                        query: this.getQuery(),
                        resource: Resources.MineTasks,
                        piggyResource: this.getResource(),
                        errorMessage: true,
                        successMessage: this.props.translate('text.TaskStatusUpdatedTo', [getLookup('TaskStatus')[status ? status : item.TaskStatusID + 1], nextTaskLabel]),
                    }))
                })
            })
        })
    }

    handleCloseTaskDialog = () => {
        this.props.dispatch(hideGlobalModal('createTaskDialog'))
    }

    handleBulkReassign = () => {
        this.setState({
            isReassignDialogOpen: !this.state.isReassignDialogOpen
        })
    }

    handleSetDataView = (view) => {
        LocalStorage.set('dataView', view);
        let filters = this.state.queryFilterFields;

        filters = this.getStoredQueryFilters(view);

        this.setState({
            dataView: view,
            queryFilterFields: filters
        }, this.fetchData)
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSelectRowClick = (item, event) => {
        const itemID = item.TaskID;
        const data = getProp(this.props.resourceDiff.data, 'list', [])
        let selectedRows = cloneDeep(this.state.selectedRows)
        if (event?.nativeEvent?.shiftKey) {
            const firstSelectedItem = data.findIndex(it => selectedRows[it.TaskID])
            const lastSelectedItem = data.findIndex(it => it.TaskID === itemID)

            if (firstSelectedItem > -1 && lastSelectedItem > -1) {
                if (firstSelectedItem < lastSelectedItem) {
                    for (let i = firstSelectedItem; i < lastSelectedItem + 1; i++) {
                        Object.assign(selectedRows, {[data[i].TaskID]: data[i].TaskID})
                    }
                } else {
                    for (let i = lastSelectedItem; i < firstSelectedItem + 1; i++) {
                        Object.assign(selectedRows, {[data[i].TaskID]: data[i].TaskID})
                    }
                }
            }
        } else {
            if (selectedRows[itemID]) {
                delete selectedRows[itemID]
            } else {
                Object.assign(selectedRows, {[itemID]: itemID}, {[itemID]: item})
            }
        }

        this.setState({selectedRows})
    }

    setSelectedRows = (selectedRows) => {
        this.setState({selectedRows})
    }

    handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(this.props.resourceDiff.data, 'list', []))

        let selectedRows = cloneDeep(this.state.selectedRows)

        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                memo[it.TaskID] = it;

                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.TaskID.toString())) {
                    delete selectedRows[it.TaskID]
                }
            })
        }

        this.setState({selectedRows})
    }

    /** Helpers
     ================================================================= */
    getStoredQueryFilters = (view, initial = false) => {
        if (!initial) {
            LocalStorage.set('board' === this.state.dataView ? 'all_tasks_board_filters' : 'all_tasks_table_filters', FieldsManager.getFieldKeyValues(this.state.queryFilterFields));
        }
        let storedFilters = LocalStorage.get('board' === view ? 'all_tasks_board_filters' : 'all_tasks_table_filters', {});

        if (!Object.keys(storedFilters).length && 'board' === view) {
            storedFilters = {
                ShowCompleted: 1,
                ShowClosed: 1
            }
        }

        return Object.values(
            this.getQueryFilterFields(storedFilters)
        ).reduce((memo, it) => {
            if (!it.value) {
                it.value = "";
            }

            memo[it.name] = it;
            return memo;
        }, {});
    }

    formatDateQueryFields = (queryFields) => {
        if (queryFields.DueStartDate) {
            toBackDateTime(queryFields.DueStartDate)
            if (queryFields.DueStartTime) {
                queryFields.DueStartDate = toBackDate(queryFields.DueStartDate) + ' ' + timePickerValueToServerTime(queryFields.DueStartTime)
            }
        }

        if (queryFields.DueEndDate) {
            toBackDateTime(queryFields.DueEndDate)
            if (queryFields.DueEndTime) {
                queryFields.DueEndDate = toBackDate(queryFields.DueEndDate) + '' + timePickerValueToServerTime(queryFields.DueEndTime)
            }
        }

        if (queryFields.StartStartDate) {
            toBackDateTime(queryFields.StartStartDate)
            if (queryFields.StartStartTime) {
                queryFields.StartStartDate = toBackDate(queryFields.StartStartDate) + ' ' + timePickerValueToServerTime(queryFields.StartStartTime)
            }
        }

        if (queryFields.StartEndDate) {
            toBackDateTime(queryFields.StartEndDate)
            if (queryFields.StartEndTime) {
                queryFields.StartEndDate = toBackDate(queryFields.StartEndDate) + ' ' + timePickerValueToServerTime(queryFields.StartEndTime)
            }
        }

        return queryFields
    }
    getQuery = () => {
        let queryFields = FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        let TaskGroupField = FieldsManager.getFieldKeyValues(this.state.taskGroupField)
        this.formatDateQueryFields(queryFields)

        let query = {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...queryFields,
            TaskGroups: TaskGroupField.TaskGroups
        }

        if (this.state.dataView === 'board') delete query.limit

        return query
    }

    getQueryFilterFields = (item = {}) => {
        let fieldTemplates = {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            archived: new Field('archived', '', [''], false, 'checkbox', {}, {}),
            Marked: new Field('Marked', '', [''], false, 'checkbox', {}, {}),
            NotAssigned: new Field('NotAssigned', '', [''], false, 'checkbox', {}, {}),
            ShowCompleted: new Field('ShowCompleted', '', [''], false, 'checkbox', {}, {}),
            ShowClosed: new Field('ShowClosed', '', [''], false, 'checkbox', {}, {}),
            ShowPrivateTasksOnly: new Field('ShowPrivateTasksOnly', '', [''], false, 'checkbox', {}, {}),
            pinned: new Field('pinned', '', [''], false, 'checkbox', {}, {}),
            TaskPriorityID: new Field('TaskPriorityID', '', [''], false, 'select', {}, {
                all: true,
                reverseKeySort: true
            }),
            TaskStatusID: new Field('TaskStatusID', '', [''], false, 'select', {}, {
                all: true,
                omitSort: true
            }),
            Assignee: new Field('Assignee', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            RequesterID: new Field('RequesterID', '', [''], false, 'select-search', {}, {
                api: 'api/' + Resources.ContactsQuick,
                query: {IsSystemUser: 1},
                searchMap: (item) => ({
                    label: item.FirstName + " " + item.LastName,
                    value: item.ContactID
                }),
                isClearable: true
            }),

            // Due date filter fields
            DueStartDate: new Field('DueStartDate', '', [], false, 'date', {
                label: "DueStartDate",
                addContainerClass: "col-start-1 col-span-full",
                formLabelClass: "flex items-center text-sm font-semibold text-tm-gray-900 whitespace-nowrap"
            }, {}),
            DueEndDate: new Field('DueEndDate', '', [], false, 'date', {
                label: "DueEndDate",
                addContainerClass: "col-start-1 col-span-full",
                formLabelClass: "flex items-center text-sm font-semibold text-tm-gray-900 whitespace-nowrap"
            }, {}),

            // Start date filter fields
            StartStartDate: new Field('StartStartDate', '', [], false, 'date', {
                label: "StartStartDate",
                addContainerClass: "col-start-1 col-span-full",
                formLabelClass: "flex items-center text-sm font-semibold text-tm-gray-900 whitespace-nowrap"
            }, {}),
            StartEndDate: new Field('StartEndDate', '', [], false, 'date', {
                label: "StartEndDate",
                addContainerClass: "col-start-1 col-span-full",
                formLabelClass: "flex items-center text-sm font-semibold text-tm-gray-900 whitespace-nowrap"
            }, {}),


            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                hideLabel: true,
                labelType: "float"
            }, {menuPlacement: "top"})
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    getTaskGroupField = () => {
        return {
            TaskGroups: new Field('TaskGroups', '', [''], false, 'multi-select-search', {
                label: "selectedBoard",
                labelType: "float"
            }, {
                placeholder: "All boards",
                multi: true
            })
        }
    }

    getFields = () => {
        return {
            TaskID: new Field('TaskID', '', ['empty'], false, 'custom', {
                render: (item) => {

                    return (
                        <div key={item.TaskID} className="flex">
                            {item.TaskID}

                            <div className="ml-auto flex pl-5">
                                {!!item.TaskPin && (
                                    <Tippy
                                        content={this.props.translate('btn.TaskFavourite')}
                                    >
                                        <StarSolidIcon
                                            className={classNames('h-5 w-5 cursor-pointer text-yellow-400')}
                                        />
                                    </Tippy>
                                )}

                                {!!item.Marked && (
                                    <Tippy
                                        content={this.props.translate('btn.task_marked')}
                                    >
                                        <span>
                                            <FireSolidIcon
                                                className="relative z-12 h-5 w-5 cursor-pointer text-red-600 animate-pulse"/>
                                        </span>
                                    </Tippy>
                                )}

                                {!!item.IsTaskPostponed && (
                                    <Tippy
                                        content={this.props.translate('text.task_was_postponed')}
                                    >
                                        <span>
                                            <ClockIcon className="w-5 h-5 text-yellow-400"/>
                                        </span>
                                    </Tippy>
                                )}
                            </div>
                        </div>
                    )
                }
            }),
            TaskName: new Field('TaskName', '', ['empty']),
            Labels: new Field('Labels', '', [''], false, 'custom', {
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
            }),
            Requester: new Field('Requester', '', ['empty']),
            Assignee: new Field('Assignee', '', ['empty']),
            StartDate: new Field('StartDate', '', ['empty'], false, 'datetimezone'),
            DueDate: new Field('DueDate', '', ['empty'], false, 'custom', {
                render: (item) => {
                    return renderTaskDueDate(item);
                }
            }),
            TaskPriority: new Field('TaskPriority', '', ['empty']),
            TaskStatus: new Field('TaskStatus', '', ['empty'], false, 'custom', {
                render: (item) => {
                    return (
                        <TaskStatusProgressTableV2
                            addClass=""
                            TaskStatusID={item.TaskStatusID}
                            translate={this.props.translate}
                        />
                    )
                },
            }),
            TaskGroupID: new Field('TaskGroupID', '', ['empty'], false, 'select-search', {}, {omitSort: true}),
            LastComment: new Field('LastComment', '', ['empty'], false, 'text'),
            LastCommentDate: new Field('LastCommentDate', '', ['empty'], false, 'datetimezone'),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone'),
        }
    }

    handleInputChange = (name, value) => {
        let fields = this.state.taskGroupField
        fields = FieldsManager.updateField(fields, name, value);

        this.setState({
            taskGroupField: fields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    saveFilters = () => {
        let filters = ['offset', 'sort', 'sortBy', 'secondSort', 'secondSortBy', 'paginationPage', 'limit', 'isAutoRefreshEnabled']
        Object.keys(this.getQuery()).map(it => filters.push(it));
        LocalStorage.persistState(this.pagePath, this.state, filters);

        LocalStorage.set('board' === this.state.dataView ? 'all_tasks_board_filters' : 'all_tasks_table_filters', FieldsManager.getFieldKeyValues(this.state.queryFilterFields));
    }

    getResource = () => {
        return Resources.Tasks
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resourceDiff} = this.props;
        const resource = resourceDiff ?? {};
        const isLoading = resource.isLoading || this.props?.resource?.isLoading;
        const isLoadingSilent = resource.isLoadingSilent;

        const TaskGroupField = fieldsToHtml(Object.values(Object.assign({}, this.state.taskGroupField)), translate, this.handleInputChange, {
            TaskGroups: {
                api: 'api/' + Resources.BoardTasks,
                query: Object.assign({}, DEFAULT_METADATA_SELECT_SEARCH_QUERY(), {
                    AmIMember: 1
                }),
                searchMap: (item) => ({
                    value: item.TaskGroupID,
                    label: item.TaskGroupName
                })
            }
        });
        const {tagsData} = this.state;
        const data = getProp(resource.data, 'list', [])

        const count = getProp(resource.data, 'count', 0)
        const areSomeItemsSelected = !!Object.keys(this.state.selectedRows).length;

        let queryFilterFields = excludeFields(cloneDeep(this.state.queryFilterFields), [
            'DueStartDate', 'DueEndDate', 'DueStartTime', 'DueEndTime',
            'StartStartDate', 'StartStartTime', 'StartEndDate', 'StartEndTime'
        ])

        if (this.state.dataView === 'board') queryFilterFields.limit.type = 'hidden'
        if (this.state.dataView === 'table') queryFilterFields.limit.type = 'select'

        let boardColumns = {}

        if (this.state.dataView === 'board') {
            boardColumns = getLookup('TaskStatus');
            if (!this.state.queryFilterFields?.ShowCompleted?.value) {
                delete boardColumns['3']
            }

            if (!this.state.queryFilterFields?.ShowClosed?.value) {
                delete boardColumns['4']
            }
            //
        }

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <div className="mb-4 min-h-[2.25rem]">
                        {isLoading ? (<LoaderSmall disableContainer addClass={"mx-2"}/>) :
                            (<InfoBar
                                type="info"
                            >
                                <div>{this.state.infoMessage}</div>
                            </InfoBar>)
                        }
                    </div>

                    <PageHeader
                        className="flex relative z-40"
                        title={translate('page.heading.AllTasks')}
                        buttonLabel={translate('btn.create_new')}
                        buttonsClassName={"ml-auto space-x-3 flex flex-shrink-0"}
                        onButtonClick={() => this.handleCreateUpdateResource()}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                        afterTitle={(
                            <>
                                <PageHeaderInfo
                                    dispatch={this.props.dispatch}
                                />

                                <div className="hidden sm:block lg:w-96 w-64">
                                    {TaskGroupField}
                                </div>
                            </>
                        )}
                    />

                    <div className="sm:hidden block">
                        {TaskGroupField}
                    </div>

                    <div className="sm:flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            excludedFields={['limit', 'DueStartTime', 'DueEndTime', 'StartStartTime', 'StartEndTime']}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleFilterClear}
                            translate={translate}
                        />

                        <div className="ml-auto flex sm:justify-start justify-end items-center">
                            {checkPerm(Resources.TasksBulkReassign, UPDATE_PERM) && (
                                <Tippy content={translate('text.BulkReassign')}>
                                    <button
                                        className="btn-icon"
                                        onClick={this.handleBulkReassign}
                                    >
                                        <AdjustmentsVerticalIcon className="w-5 h-5"/>
                                    </button>
                                </Tippy>
                            )}

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard>
                        <TableFilters
                            toggleAutoRefresh={this.toggleAutoRefresh}
                            isAutoRefreshEnabled={this.state.isAutoRefreshEnabled}
                            isLoading={isLoading || isLoadingSilent}
                            filterFields={queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            forceDialog={true}
                            hideLimit={true}
                            customHeaderHtml={(
                                <div className="flex space-x-4 col-span-2">
                                    <div>
                                        <Tippy content={translate('text.board_view')}>
                                            <button
                                                onClick={() => this.handleSetDataView('board')}
                                                className={classNames(this.state.dataView === 'board' ? 'text-primary' : 'text-tm-gray-400 bg-tm-gray-200', "h-full rounded-l-input relative inline-flex items-center px-4 py-2 border border-tm-gray-300 bg-field text-sm font-medium focus:z-10 focus:outline-none focus:ring-1")}
                                            >
                                                <ViewColumnsIcon className="h-5 w-5"/>
                                            </button>
                                        </Tippy>
                                        <Tippy content={translate('text.table_view')}>
                                            <button
                                                onClick={() => this.handleSetDataView('table')}
                                                className={classNames(this.state.dataView === 'table' ? 'text-primary' : 'text-tm-gray-400 bg-tm-gray-200', "h-full relative inline-flex items-center px-4 py-2 border border-tm-gray-300 bg-field text-sm font-medium focus:z-10 focus:outline-none focus:ring-1")}
                                            >
                                                <Bars4Icon className="h-5 w-5"/>
                                            </button>
                                        </Tippy>
                                        <Tippy content={translate('text.grid_view')}>
                                            <button
                                                onClick={() => this.handleSetDataView('grid')}
                                                className={classNames(this.state.dataView === 'grid' ? 'text-primary' : 'text-tm-gray-400 bg-tm-gray-200', "h-full rounded-r-input  relative inline-flex items-center px-4 py-2 border border-tm-gray-300 bg-field text-sm font-medium focus:z-10 focus:outline-none focus:ring-1")}
                                            >
                                                <Squares2X2Icon className="h-5 w-5"/>
                                            </button>
                                        </Tippy>
                                    </div>
                                </div>
                            )}

                            customHtml={
                                <div className="col-span-full">
                                    <TasksDateTimePicker
                                        title={translate("text.due_date_in")}
                                        queryFields={this.state.queryFilterFields}
                                        translate={translate}
                                        updateQueryFields={(queryFieldsUpdate) => {
                                            this.setState({queryFilterFields: queryFieldsUpdate, offset: 0},
                                                () => this.fetchData());
                                            this.saveFilters(queryFieldsUpdate);
                                        }}
                                        onQueryChange={this.handleFilterInputChange}
                                    />

                                    <PopOver
                                        className="relative mt-4"
                                        btnClass="text-opacity-90 form-control p-0 w-auto text-tm-gray-700 flex items-center flex py-2 px-3"
                                        BtnIcon={CalendarIcon}
                                        chevronIcon={true}
                                        btnLabel={translate("text.start_date_in")}
                                        btnIconClass="h-4 w-4 mr-1"
                                        widthClass="max-w-2xl"
                                        positionClass="absolute translate-x-0 left-0"
                                    >
                                        <div className="px-6 pb-6 pt-4 bg-popup border border-tm-gray-300 rounded-lg">
                                            <TasksDateTimePicker
                                                typeIsStartDate
                                                title={translate("text.start_date_in")}
                                                queryFields={this.state.queryFilterFields}
                                                translate={translate}
                                                updateQueryFields={(queryFieldsUpdate) => {
                                                    this.setState({queryFilterFields: queryFieldsUpdate, offset: 0},
                                                        () => this.fetchData());
                                                    this.saveFilters(queryFieldsUpdate);
                                                }}
                                                onQueryChange={this.handleFilterInputChange}
                                            />
                                        </div>
                                    </PopOver>
                                </div>
                            }
                        />


                        {this.state.dataView === 'table' && (
                            <React.Fragment>
                                <ResourceTable
                                    tableID={'tasks'}
                                    tableKey={'TaskID'}
                                    lastVisitedRowID={this.state.selectedTaskID}
                                    onClearLastVisitedRowID={() => this.setState({selectedTaskID: -1})}
                                    data={this.updatedResource[0] ? this.updatedResource : data}
                                    fields={this.getFields()}
                                    verticalTableIsVisible={this.state.breakpoint.index <= 1}
                                    translate={translate}
                                    isLoading={isLoading}
                                    updatedRows={resource?.diffData ?? {}}
                                    options={this.state.tableOptions}

                                    limit={this.state.queryFilterFields.limit.value}

                                    sort={this.state.sort}
                                    sortBy={this.state.sortBy}
                                    onSortChange={this.handleUpdateSort}
                                    saveTableOptions={this.setOptions}

                                    onRowClick={checkPerm(Resources.TasksInfo, UPDATE_PERM) ? this.handleCreateUpdateResource : null}

                                    onEdit={this.handleCreateUpdateResource}
                                    onDelete={this.archiveResource}
                                    onRestore={this.restoreFromArchive}

                                    hasEditPerm={checkPerm(Resources.TasksInfo, UPDATE_PERM)}
                                    hasDeletePerm={checkPerm(this.getResource(), DELETE_PERM)}
                                    hasRestorePerm={checkPerm(this.getResource(), UPDATE_PERM)}

                                    actions={[
                                        {
                                            action: (item) => this.handleChangeTaskStatus(item),
                                            visible: (it) => it.TaskStatusID < 3 && it.AssigneeID == getUser('Contact.ContactID'),
                                            hasPerm: checkPerm(this.getResource(), UPDATE_PERM) || checkPerm(Resources.MineTasks, READ_PERM),
                                            title: this.getTooltipText,
                                            customContent: this.getUpdateStatusIcon,
                                        },
                                    ]}

                                    onSelectRow={this.handleSelectRowClick}
                                    selectedRows={this.state.selectedRows}
                                    onSelectAllClick={this.handleSelectAllClick}
                                />

                                {/*Table footer*/}
                                <TableCardFooter
                                    show={!(!data.length && !resource.isLoading)}
                                >
                                    <Pagination
                                        count={count}
                                        isLoading={isLoading}
                                        hideRowsPerPage={this.state.breakpoint.index <= 1}
                                        handleQueryChange={
                                            (name, value, currentPage) => name === "offset"
                                                ? this.handleUpdateOffset(value, currentPage)
                                                : this.handleFilterInputChange(name, value)
                                        }
                                        pageOffset={this.state.offset}
                                        queryFields={this.state.queryFilterFields}
                                        translate={translate}
                                    />
                                </TableCardFooter>
                            </React.Fragment>
                        )}

                        <TableBulkActions
                            selectedRows={this.state.selectedRows}
                            tableKey="TaskID"
                            fields={this.getFields()}
                            translate={translate}
                            options={this.state.tableOptions}
                            setSelectedRows={this.setSelectedRows}
                            onSelectAllClick={this.handleSelectAllClick}
                        >
                            <div className='flex items-center h-7'>
                                <div className='flex gap-1 pr-4'>
                                    <Tippy content={translate('btn.set_as_marked')}>
                                        <button
                                            onClick={() => this.handleSetMarkedClick(true)}
                                            disabled={!areSomeItemsSelected}
                                            className='flex p-1 rounded-btn hover:bg-primary-shade'
                                        >
                                            <FireSolidIcon
                                                className='w-5 h-5 text-primary-contrast'/>
                                        </button>
                                    </Tippy>

                                    <Tippy content={translate('btn.remove_from_marked')}>
                                        <button
                                            onClick={() => this.handleSetMarkedClick(false)}
                                            disabled={!areSomeItemsSelected}
                                            className='flex p-1 rounded-btn hover:bg-primary-shade'
                                        >
                                            <FireIcon
                                                className='w-5 h-5 text-primary-contrast'/>
                                        </button>
                                    </Tippy>
                                </div>

                                <div className='w-0.5 h-5 bg-primary-shade'/>

                                <div className='flex gap-1 px-4'>
                                    <Tippy content={translate('btn.set_as_favorite')}>
                                        <button
                                            onClick={() => this.handleSetPinnedClick(true)}
                                            disabled={!areSomeItemsSelected}
                                            className='flex p-1 rounded-btn hover:bg-primary-shade'
                                        >
                                            <StarSolidIcon
                                                className="w-5 h-5 text-primary-contrast"/>
                                        </button>
                                    </Tippy>

                                    <Tippy content={translate('btn.remove_from_favorite')}>
                                        <button
                                            onClick={() => this.handleSetPinnedClick(false)}
                                            disabled={!areSomeItemsSelected}
                                            className='flex p-1 rounded-btn hover:bg-primary-shade'
                                        >
                                            <StarIcon
                                                className='w-5 h-5 text-primary-contrast'/>
                                        </button>
                                    </Tippy>
                                </div>

                                <div className='w-0.5 h-5 bg-primary-shade'/>

                                <div className='flex gap-1 pl-4'>
                                    <TableTagManager
                                        isDiffResource={true}
                                        rowKey={"TaskID"}
                                        selectedRows={this.state.selectedRows}
                                        resourceName={Resources.TaskColor}
                                        btnIconClass='w-5 h-5'
                                        customIcon={<ChevronUpSolidIcon className="w-5 h-5"/>}
                                        piggyResourceName={this.getResource()}
                                        query={this.getQuery()}
                                        onAfterTagRows={() => {
                                            this.setState({selectedRows: {}});
                                        }}
                                        translate={translate}
                                    />
                                </div>
                            </div>
                        </TableBulkActions>

                        {this.state.dataView === 'board' && (
                            <div className="overflow-auto">
                                <SimpleBoard
                                    data={this.updatedResource}
                                    isLoading={isLoading}
                                    translate={translate}
                                    isDraggable={true}
                                    handleUnlinkIssue={this.archiveResource}
                                    getUpdateStatusIcon={this.getUpdateStatusIcon}
                                    handleChangeTaskStatus={this.handleChangeTaskStatus}
                                    getStatusTooltipText={this.getTooltipText}
                                    handleRestoreIssue={this.restoreFromArchive}
                                    handleMarkIssue={this.handleSetSingleMarkedClick}
                                    handleCreateUpdateResource={this.handleCreateUpdateResource}
                                    handleDroppedItem={this.handleDroppedItem}
                                    columns={boardColumns}
                                />
                            </div>
                        )}

                        {this.state.dataView === 'grid' && (
                            <div className="pt-4 px-4 overflow-auto">
                                <ResourceTable
                                    verticalTableIsVisible={true}
                                    verticalTableContainerClass="grid gap-4 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4"
                                    tableKey="TaskID"
                                    data={data}

                                    count={resource?.data?.count}
                                    updatedRows={this.state.updatedRows}
                                    options={this.state.tableOptions}

                                    fields={orderFields(this.state.fields, this.state.fieldsOrder)}
                                    translate={this.props.translate}
                                    isLoading={isLoading}

                                    limit={this.state.queryFilterFields.limit.value}

                                    offset={this.state.offset}
                                    page={this.state.paginationPage}
                                    paginationButtonLimit={5}
                                    onOffsetChange={this.handleUpdateOffset}

                                    sort={this.state.sort}
                                    sortBy={this.state.sortBy}
                                    onSortChange={this.handleUpdateSort}

                                    defaultAction={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleCreateUpdateResource : null}

                                    onEdit={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleCreateUpdateResource : null}
                                    onDelete={checkPerm(this.getResource(), DELETE_PERM) ? this.archiveResource : null}
                                    onRestore={checkPerm(this.getResource(), UPDATE_PERM) ? this.restoreFromArchive : null}

                                    actions={[
                                        {
                                            title: (it) => !it.Marked ? translate('btn.set_as_marked') : translate('btn.remove_from_marked'),
                                            action: (it) => this.handleSetSingleMarkedClick(it),
                                            customContent: (it) => <FireSolidIcon
                                                className={classNames("w-5 h-5", it.Marked ? "text-red-600" : "text-tm-gray-600")}/>
                                        },
                                        {
                                            title: (it) => this.getTooltipText(it),
                                            action: (item) => this.handleChangeTaskStatus(item),
                                            visible: (it) => it.TaskStatusID < 3 && it.AssigneeID == getUser('Contact.ContactID'),
                                            customContent: (it) => this.getUpdateStatusIconGrid(it)
                                        },
                                    ]}
                                />

                                <TableCardFooter
                                    show={!(!data.length && !resource.isLoading)}
                                >
                                    <Pagination
                                        count={count}
                                        isLoading={isLoading}
                                        hideRowsPerPage={this.state.breakpoint.index <= 1}
                                        handleQueryChange={
                                            (name, value, currentPage) => name === "offset"
                                                ? this.handleUpdateOffset(value, currentPage)
                                                : this.handleFilterInputChange(name, value)
                                        }
                                        pageOffset={this.state.offset}
                                        queryFields={this.state.queryFilterFields}
                                        translate={translate}
                                    />
                                </TableCardFooter>
                            </div>
                        )}


                        <NoRecordsTable
                            show={(data.length === 0) && !isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={'No matching records found'}
                            text={'Create a new task'}
                            btnLabel="Create task"
                            onBtnClick={() => {
                                this.handleCreateUpdateResource(null, this.state.queryFilterFields.query.value)
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    <ModalConfirm
                        title={this.state.title}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />

                    <Modal
                        show={!!this.props.ui?.globalDialogs?.createTaskDialog?.visible}
                        title={translate('text.task_info')}
                        widthClass={this.props.ui?.globalDialogs?.createTaskDialog?.data?.TaskID ? 'max-w-5xl' : 'max-w-xl'}
                        translate={translate}
                        onClose={() => this.handleCloseTaskDialog()}
                    >
                        <TasksFormDialog
                            pathname={this.props.location.pathname}
                            close={() => this.handleCloseTaskDialog()}
                            TaskID={this.props.ui?.globalDialogs?.createTaskDialog?.data?.TaskID}
                            tagsData={tagsData}
                            translate={translate}
                            prefilled={this.state.prefilled}
                            query={this.getQuery()}
                            dialogResource={this.props.dialogResource}
                            piggyResource={this.getResource()}
                        />
                    </Modal>

                    <Modal
                        show={!!this.state.taskInfoDialog}
                        widthClass={'max-w-5xl'}
                        initialFocusRef={this.taskStatusFieldRef}
                        translate={translate}
                        closeButtonLabel={translate('btn.close')}
                        onClose={() => this.toggleDetailsDialog()}
                    >
                        <TasksInfoDialog
                            innerRef={this.taskStatusFieldRef}
                            role={'assignee'}
                            onClose={this.toggleDetailsDialog}
                            id={this.state?.selectedItem?.TaskID}
                            translate={translate}
                        />
                    </Modal>

                    {!!this.state.isReassignDialogOpen && (
                        <TaskReassignDialog
                            show={this.state.isReassignDialogOpen}
                            onClose={() => this.setState({isReassignDialogOpen: false})}
                            ui={this.props.ui}
                            translate={translate}
                        />
                    )}
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(AllTasksView)