import React, {Component} from "react";
import LocalStorage from "../../../util/localStorage";
import {
    checkPerm,
    ConstantTranslate,
    getDefaultCountry,
    getLookup,
    getProp,
    openInNewTab
} from "../../../util/util-helpers";
import {timePickerValueToServerTime} from "../../../util/util-dates";
import TrucksTab from "./trucks-tab";
import {ClockIcon, InformationCircleIcon, PhoneIcon, TruckIcon} from "@heroicons/react/24/outline";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import InfoParagraph from "../../info-paragraph";
import {
    COUNTRY_ID_CANADA,
    COUNTRY_ID_USA,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY, DEFAULT_QUERY_LIMIT,
    FIELD_MASK_PHONE,
    LINE_OF_BUSINESS_PRIMARY_CARRIER,
    LOCATION_STOP_TYPES,
    LOCATION_TYPES,
    UPDATE_PERM
} from "../../../../util/util-constants";
import TrailersTab from './trailers-tab'

import {pushNotification} from "../../../../data/actions/ui";
import {HourglassIcon, TrailerIcon} from "../../../../data/themes/icons";
import CardSubTitle from "../../card/card-sub-title";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {scrollErrorIntoView} from "../../../util/util-vanilla";
import WorkingHours from "../../working-hours";
import ContactsTab from "../../tabs/contacts-tab";
import Resources from "../../../../data/services/resources";
import ModalWarning from "../modal-warning";
import ModalFooter from "../modal-footer";
import DialogTabs from "../../tabs-navigation/dialog-tabs";
import MobileTabs from "../../tabs-navigation/mobile-tabs";
import {getCountryCodes} from "../../../util/countryCodes";
import {createDialogResource, deleteDialogResource} from "../../../../data/actions/dialogResource";
import {createResource} from "../../../../data/actions/resource";
import {LoaderSmall} from "../../loader";
import {fieldsToHtml, fillFieldsFromData} from "../../../util/util-fields";

class CreateLocationDialog extends Component {
    constructor(props) {
        super(props);

        const tabs = [
            {id: 1, name: "info", resource: "info", icon: InformationCircleIcon, visible: true, current: true},
            {id: 2, name: "shipping_hours", resource: "shipping_hours", icon: ClockIcon, visible: true},
            {id: 3, name: "receiving_hours", resource: "receiving_hours", icon: ClockIcon, visible: true},
            {id: 4, name: "contacts", resource: "contacts", icon: PhoneIcon, visible: !this.props.isContactsTabHidden},
            getProp(LocalStorage.get('user'), 'Contact.LineOfBusiness', []).includes(LINE_OF_BUSINESS_PRIMARY_CARRIER) &&
            {
                id: 5,
                name: "trucks",
                resource: "trucks",
                icon: TruckIcon,
                visible: !!this.props.updateItem && this.props.updateItem.LocationID !== -1
            },
            getProp(LocalStorage.get('user'), 'Contact.LineOfBusiness', []).includes(LINE_OF_BUSINESS_PRIMARY_CARRIER) &&
            {
                id: 6,
                name: "trailers",
                resource: "trailers",
                icon: TrailerIcon,
                visible: !!this.props.updateItem && this.props.updateItem.LocationID !== -1
            }
            ,
            this.props.handleActionHistoryView ? {
                id: 7,
                name: "history_locations",
                resource: "history_locations",
                icon: HourglassIcon,
                visible: !!this.props.updateItem && this.props.updateItem.LocationID !== -1
            } : null
        ]

        this.state = {
            fields: this.getFields(this.props.updateItem),
            ShippingHours: getProp(this.props.updateItem, "ShippingHours", []),
            ReceivingHours: getProp(this.props.updateItem, "ReceivingHours", []),
            CustomShippingHours: getProp(this.props.updateItem, "CustomShippingHours", 2),
            CustomReceivingHours: getProp(this.props.updateItem, "CustomReceivingHours", 2),
            canSubmit: false,
            confirmDialog: false,
            tabs: tabs.filter(it => !!it), // Remove history
            selectedTab: tabs[0].name,

            Contacts: []
        };

        this.metadata = {
            StateID: getLookup('State'),
            CountryID: getLookup('Country'),
            AreaCode: getCountryCodes(),
            StopTypeID: ConstantTranslate(LOCATION_STOP_TYPES, this.props.translate),
            LocationTypeID: ConstantTranslate(LOCATION_TYPES, this.props.translate),
            LocationZoneID: {
                api: "api/" + Resources.LocationZones,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.LocationZone,
                    value: item.LocationZoneID
                })
            },
            ContactGroupID: {
                api: 'api/' + Resources.ContactGroupsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            }
        }
    }

    getFields = (item = {}) => {
        const defaultCountry = getDefaultCountry();
        const itemCountryID = item?.CountryID ?? defaultCountry?.CountryID ?? 1;

        let fieldTemplates = {
            CountryID: new Field('CountryID', itemCountryID, ['empty'], false, 'select', {
                addContainerClass: "col-span-2",
                htmlBefore: () =>
                    <div className="col-span-full">
                        <CardSubTitle
                            subtitle={this.props.translate('text.address')}
                        />
                    </div>
            }),
            GooglePlaces: new Field('GooglePlaces', '', [], false, 'google-locations', {
                setLocations: this.setLocations,
                addContainerClass: "col-span-4"
            }),
            LocationName: new Field('LocationName', this.props.prefilled ?? "", ['empty'], false, 'text', {
                addContainerClass: "col-span-4"
            }),
            LocationNumber: new Field('LocationNumber', '', [], false, 'text', {
                addContainerClass: "col-span-2"
            }),
            AddressName: new Field('AddressName', '', [], false, 'text', {
                addContainerClass: "col-span-full"
            }),
            AddressName2: new Field('AddressName2', '', [], false, 'text', {
                addContainerClass: "col-span-full"
            }),
            CityName: new Field('CityName', '', ['empty'], false, 'text', {
                addContainerClass: "col-span-2"
            }),
            StateID: new Field('StateID', '', ['empty'], false, 'select', {
                addContainerClass: "col-span-2"
            }),
            PostalCode: new Field('PostalCode', '', [], false, 'text', {
                addContainerClass: "col-span-2"
            }),

            RequiresAppointment: new Field('RequiresAppointment', '', [''], false, 'checkbox', {
                addContainerClass: "col-span-6",
                labelType: "float",
                htmlBefore: () =>
                    <div className="col-span-full pt-6">
                        <CardSubTitle
                            subtitle={this.props.translate('text.location_info')}
                        />
                    </div>
            }, {id: "reqAppCheckbox"}),
            LocationZoneID: new Field("LocationZoneID", '', [], false, 'select-search', {
                addContainerClass: "col-span-2"
            }, {isClearable: true}),
            StopTypeID: new Field("StopTypeID", 1, [], false, 'select', {
                addContainerClass: "col-span-2"
            }, {isClearable: true}),
            ContactGroupID: new Field('ContactGroupID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-2'}),
            LocationTypeID: new Field("LocationTypeID", 1, [], false, 'select', {
                addContainerClass: "col-span-2"
            }, {isClearable: true}),
            AreaCode: new Field('AreaCode', "+1", [], false, 'select', {
                addContainerClass: "col-span-2",
                htmlBefore: () =>
                    <div className="col-span-full">
                        <CardSubTitle
                            subtitle={this.props.translate('text.contact_info')}
                        />
                    </div>
            }, {isClearable: true}),
            PhoneNumber: new Field('PhoneNumber', '', [''], false, 'mask', {
                addContainerClass: "col-span-2"
            }, {
                showMask: true,
                mask: FIELD_MASK_PHONE
            }),
            PhoneExtension: new Field('PhoneExtension', '', [], false, 'text', {
                addContainerClass: "col-span-2"
            }),
            InternalNotes: new Field('InternalNotes', '', [''], false, 'textarea', {
                addContainerClass: "col-span-full",
                htmlBefore: () =>
                    <div className="col-span-full pt-6">
                        <CardSubTitle
                            subtitle={this.props.translate('text.notes')}
                        />
                    </div>
            }),
            DispatchPickupNotes: new Field('DispatchPickupNotes', '', [''], false, (item?.StopTypeID === 1 || !item || item.StopTypeID === null || item.StopTypeID === 2) ? 'textarea' : 'hidden', {
                addContainerClass: "col-span-full"
            }),
            DispatchDeliveryNotes: new Field('DispatchDeliveryNotes', '', [''], false, (item?.StopTypeID === 1 || !item || item.StopTypeID === null || item.StopTypeID === 3) ? 'textarea' : 'hidden', {
                addContainerClass: "col-span-full"
            }),
        };


        if (itemCountryID !== COUNTRY_ID_USA && itemCountryID !== COUNTRY_ID_CANADA) {
            fieldTemplates["StateID"].type = 'hidden'
        }

        if (this.props.readOnly) {
            fieldTemplates = FieldsManager.setFieldsToReadOnly(fieldTemplates);
        }

        return item ? fillFieldsFromData(fieldTemplates, item) : fieldTemplates;
    }

    setParentState = (data, callback = () => {
    }) => {
        this.setState(data, callback);
    };

    handleInputChange = (name, value) => {
        let fields = FieldsManager.updateField(this.state.fields, name, value);

        if (name === "CountryID") {
            if (Number(value) === COUNTRY_ID_USA || Number(value) === COUNTRY_ID_CANADA) {
                fields.StateID.type = 'select'
                fields.StateID.validate = ['empty'];
            } else {
                fields.StateID.type = 'hidden'
                fields.StateID.value = ''
                fields.StateID.validate = [''];
            }
        }

        if (name === 'StopTypeID') {
            fields.DispatchPickupNotes.type = (Number(value) === 1 || Number(value) === 2) ? 'textarea' : 'hidden';
            fields.DispatchDeliveryNotes.type = (Number(value) === 1 || Number(value) === 3) ? 'textarea' : 'hidden';
        }

        fields[name].errorMessage = "";

        this.setState({fields: fields, canSubmit: true})
    };

    setLocations = (fields) => {
        let updatedFields = this.state.fields

        for (const [key, value] of Object.entries(fields)) {
            if (key in this.state.fields) {
                updatedFields = FieldsManager.updateField(updatedFields, key, value)
            }
        }

        this.setState({
            fields: updatedFields,
            canSubmit: true
        }, () => {
            document.getElementById("LocationName").focus()
        })
    }

    submit = () => {
        const params = Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
            ShippingHours: getProp(this.state, "ShippingHours", []).map((fields) => Object.assign({}, fields, {
                FromTime: timePickerValueToServerTime(fields.FromTime),
                ToTime: timePickerValueToServerTime(fields.ToTime)
            })),
            ReceivingHours: getProp(this.state, 'ReceivingHours', []).map((fields) => Object.assign({}, fields, {
                FromTime: timePickerValueToServerTime(fields.FromTime),
                ToTime: timePickerValueToServerTime(fields.ToTime)
            })),
            CustomShippingHours: this.state.CustomShippingHours,
            CustomReceivingHours: this.state.CustomReceivingHours,
            LocationID: this.props.updateItem?.LocationID,
            Contacts: this.state.Contacts
        });

        if (this.props.onSubmit) {
            this.props.onSubmit(params, this.state.fields)
        } else {
            // Fetch data on the page after create
            this.props.dispatch(createDialogResource({
                user: LocalStorage.get('user'),
                params: params,
                resource: Resources.LocationsQuick,
                errorMessage: true, successMessage: "Location Created Successfully!"
            }));
        }

        this.hideModal();
    }

    handleOnSubmitClick = (event) => {
        event && event.preventDefault();

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                if (this.props.updateItem && this.props.updateItem.LocationID !== -1) {
                    this.handleToggleConfirmDialog()
                } else {
                    this.submit()
                }
            } else {
                this.setState({
                    selectedTab: this.state.tabs[0].name
                }, () => scrollErrorIntoView(this.state.fields));
            }
        });
    }

    hideModal = () => {
        this.props.onClose(true)
    }

    handleOnLoadClick = item => {
        this.props.history.push(`/loads/info/${item.LoadID}`)
    }

    handleCopyTabSettingsClick = (tabName) => {
        let stateToCopy = this.state[tabName];

        this.setState({
            ShippingHours: stateToCopy,
            ReceivingHours: stateToCopy,
            canSubmit: true
        })

        this.props.dispatch(pushNotification({
            title: this.props.translate("text.data_copied_to_" + tabName),
        }))
    }

    handleTabChange = (name) => {
        if (name === "history_locations") {
            this.props.handleActionHistoryView(this.props.updateItem);
        } else {
            this.setState({
                tabs: this.state.tabs.map((it) => {
                    it.current = it.name === name;
                    return it;
                }),
                selectedTab: name
            })
        }
    };

    handleToggleConfirmDialog = () => {
        this.setState({confirmDialog: !this.state.confirmDialog})
    }

    handleResultCallback = (result, fields) => {
        if (result && result?.status === 0) {
            let Contacts = this.state.Contacts
            Contacts.push({
                ...fields,
                ContactID: result.data.id
            })
            this.setState({Contacts})
        }
    }

    render() {
        const {translate, readOnly} = this.props;

        const modalTitle = translate("modal_heading." + (!!this.props.updateItem && this.props.updateItem.LocationID !== -1 ? "edit" : "add") + "_location")

        const InfoFieldsToHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, this.metadata);

        return (
            <React.Fragment>
                {this.props.isLoading && (
                    <div className="p-5 text-center">
                        <LoaderSmall/>
                    </div>
                )}

                <header
                    className="p-4 flex justify-between items-center border-tm-gray-200 border-b text-tm-gray-900">
                    <h2 className="text-lg text-current">{modalTitle}</h2>

                    <button className="btn btn-close" aria-label="Close"
                            onClick={() => {
                                this.hideModal();
                            }}>
                        <XMarkIcon className="w-5 h-5"/>
                    </button>
                </header>

                <div className="flex">
                    <div className="pr-3 border-tm-gray-200 border-r hidden lg:block w-52">
                        <DialogTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="w-full lg:w-[calc(100%-13rem)] h-dialog-body h-full">
                        <div className="lg:hidden mb-7 px-6 pt-2 pb-5 border-tm-gray-200 border-b">
                            <p className="text-base">{translate("text.tabs")}</p>
                            <MobileTabs
                                className=""
                                tabs={this.state.tabs}
                                onTabChange={this.handleTabChange}
                                translate={translate}
                            />
                        </div>

                        {this.state.selectedTab === 'info' && (
                            <div className="max-w-3xl mx-auto">
                                {!!this.props.updateItem && !readOnly && (
                                    <div className="px-6 pt-3">
                                        <InfoParagraph
                                            className="mt-2.5 mb-2"
                                            message={translate("text.ByChangingLocationSomeLoadsWillBeAffected")}
                                        />
                                    </div>
                                )}

                                {readOnly && (
                                    <div className="px-6 pt-3">
                                        <InfoParagraph
                                            className="mt-2.5 mb-2"
                                            message={translate("text.readOnlyMode")}
                                        />
                                    </div>
                                )}

                                <div className="p-6 pt-3 lg:pt-6 grid gap-5 grid-cols-6">
                                    {InfoFieldsToHtml}
                                </div>
                            </div>
                        )}

                        {this.state.selectedTab === 'shipping_hours' && (
                            <div className="px-6 pt-3 lg:py-6 bg-tm-gray-50">
                                <WorkingHours
                                    stateName="ShippingHours"
                                    setParentState={this.setParentState}
                                    translate={this.props.translate}
                                    workingHours={this.state.ShippingHours}
                                    customHours={this.state.CustomShippingHours}
                                    onCopyTabSettings={this.handleCopyTabSettingsClick}
                                    dirty={this.state.canSubmit}
                                />
                            </div>
                        )}

                        {this.state.selectedTab === 'receiving_hours' && (
                            <div className="px-6 pt-3 lg:py-6 bg-tm-gray-50">
                                <WorkingHours
                                    stateName="ReceivingHours"
                                    setParentState={this.setParentState}
                                    translate={this.props.translate}
                                    workingHours={this.state.ReceivingHours}
                                    customHours={this.state.CustomReceivingHours}
                                    onCopyTabSettings={this.handleCopyTabSettingsClick}
                                    dirty={this.state.canSubmit}
                                />
                            </div>
                        )}

                        {this.state.selectedTab === 'contacts' && (
                            <div className="px-6 pt-3 lg:py-6">
                                <ContactsTab
                                    key={this.state.Contacts.length}
                                    {...this.props}
                                    id={this.props.updateItem?.LocationID}
                                    resourceName={Resources.LocationsContacts}
                                    resourceImagePath={Resources.ContactImage}
                                    organizationID={this.props.updateItem?.LocationID}
                                    primaryKey={"LocationID"}
                                    disableHeaderFields={true}
                                    isCreate={!this.props.updateItem}
                                    Contacts={this.state.Contacts}
                                    queryFilterFields={{
                                        limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                                            hideLabel: true,
                                            labelType: "float"
                                        })
                                    }}
                                    fields={{
                                        FirstName: new Field("FirstName", '', []),
                                        Email: new Field("Email", '', []),
                                        Notes: new Field("Notes", '', []),
                                    }}
                                    onDelete={(item, query) => {
                                        if (!this.props.updateItem) {
                                            let Contacts = this.state.Contacts.filter(it => it.ContactID !== item.ContactID)
                                            this.setState({Contacts})
                                        } else {
                                            this.props.dispatch(deleteDialogResource({
                                                user: LocalStorage.get('user'),
                                                query: {
                                                    ContactInLocationID: item.ContactInLocationID,
                                                    ...query
                                                },
                                                errorMessage: true,
                                                successMessage: translate(`text.contact_deleted_from_${Resources.LocationsContacts}`, [`${item.FirstName} ${item.LastName}`]),
                                                resource: Resources.LocationsContacts,
                                                piggyResource: Resources.LocationsContacts
                                            }))
                                        }
                                    }}
                                    onCreateNewContact={!this.props.updateItem ? (fields, phones, files, additionalFields) => {
                                        this.props.dispatch(createResource({
                                            user: LocalStorage.get('user'),
                                            params: Object.assign({},
                                                {
                                                    ...additionalFields,
                                                    ...fields,
                                                    Phones: phones,
                                                    Roles: fields.Roles ?? [],
                                                },
                                            ),
                                            errorMessage: true,
                                            successMessage: `You created and added ${fields.FirstName} ${fields.LastName}`,
                                            resource: Resources.Contacts,
                                            file: [files],
                                            fileResource: Resources.ContactImage,
                                            onResultCallback: (result) => this.handleResultCallback(result, fields)
                                        }));
                                    } : null}
                                    onAddExistingContact={!this.props.updateItem ? (item, additionalFields) => {
                                        let Contacts = this.state.Contacts
                                        Contacts.push({...item, additionalFields})
                                        this.setState({Contacts})
                                    } : null}
                                />
                            </div>
                        )}

                        {this.state.selectedTab === "trucks" && (
                            <TrucksTab
                                id={this.props.updateItem?.LocationID}
                                primaryKey={"LocationID"}
                                resourceName={Resources.LocationsTrucks}
                                resourceImagePath={Resources.ContactImage}
                                disableHeaderFields={true}
                                dialogTitle={"Truck Location"}
                                dialogResource={this.props.dialogResource}
                                fields={{
                                    TruckNumber: new Field("TruckNumber", '', [], false, 'text', {omitSort: true}),
                                    VIN: new Field("VIN", '', [], false, 'text', {omitSort: true}),
                                    LocationDate: new Field("LocationDate", '', [], false, 'date', {omitSort: true}),
                                    Notes: new Field("Notes", '', [], false, 'text', {omitSort: true}),
                                    LoadNumber: new Field('LoadNumber', '', [''], false, '', {
                                        render: (item) =>
                                            <div
                                                className={'btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0'}
                                                onClick={() => checkPerm(Resources.LoadInfo, UPDATE_PERM) && item.LoadID && openInNewTab(`/loads/info/${item.LoadID}`)}>
                                                {item.LoadNumber}
                                            </div>
                                    }),
                                }}
                                translate={translate}
                                dispatch={this.props.dispatch}
                            />
                        )}

                        {this.state.selectedTab === "trailers" && (
                            <TrailersTab
                                id={this.props.updateItem?.LocationID}
                                primaryKey={"LocationID"}
                                resourceName={Resources.LocationsTrailers}
                                resourceImagePath={Resources.ContactImage}
                                disableHeaderFields={true}
                                dialogTitle={"Trailer Location"}
                                dialogResource={this.props.dialogResource}
                                fields={{
                                    TrailerNumber: new Field("TrailerNumber", '', [], false, 'text', {omitSort: true}),
                                    VIN: new Field("VIN", '', [], false, 'text', {omitSort: true}),
                                    LocationDate: new Field("LocationDate", '', [], false, 'date', {omitSort: true}),
                                    Notes: new Field("Notes", '', [], false, 'text', {omitSort: true}),
                                    LoadNumber: new Field('LoadNumber', '', [''], false, '', {
                                        render: (item) =>
                                            <div
                                                className={'btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0'}
                                                onClick={() => checkPerm(Resources.LoadInfo, UPDATE_PERM) && item.LoadID && openInNewTab(`/loads/info/${item.LoadID}`)}>
                                                {item.LoadNumber}
                                            </div>
                                    }),
                                }}
                                translate={translate}
                                dispatch={this.props.dispatch}
                            />
                        )}
                    </div>
                </div>

                {(this.state.selectedTab === "info" || this.state.selectedTab === "shipping_hours" || this.state.selectedTab === "receiving_hours") && (
                    <ModalFooter
                        closeButtonLabel={translate("btn.cancel")}
                        onClose={this.hideModal}
                        buttonDisabled={
                            !this.state.canSubmit
                            || (!!this.state.ShippingHours.find(day => !!day.ToTimeErrorMessage || !!day.FromTimeErrorMessage)
                                || !!this.state.ReceivingHours.find(day => !!day.ToTimeErrorMessage || !!day.FromTimeErrorMessage))
                        }
                        buttonLabel={translate("btn.save")}
                        onButtonClick={this.state.canSubmit && this.handleOnSubmitClick}
                    />
                )}

                <ModalWarning
                    title={translate("text.Confirm")}
                    show={!!this.state.confirmDialog}
                    text={translate("text.ByChangingLocationSomeLoadsWillBeAffectedAreYouSure")}
                    textClassName={"break-word text-base text-black"}
                    onClose={this.handleToggleConfirmDialog}
                    buttonLabel={translate("btn.confirm")}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.submit}
                />
            </React.Fragment>
        );
    }
}

export default CreateLocationDialog;
