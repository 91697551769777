import React from "react";
import {Link} from "react-router-dom";
import ChevronRightIcon from "@heroicons/react/20/solid/ChevronRightIcon";
import Modal from "../index";
import ModalHeader from "../modal-header";

const RedirectDialog = (props) => {

    const customButtonsHTML = (
        <div className="flex flex-col items-between w-full font-medium gap-y-3">
            {props.createdLabel && (
                <button
                    onClick={() => {
                        if (props.createdClickAction) {
                            props.createdClickAction();
                        } else {
                            window.open(`${window.location.origin}${props.createdLink}`, '_self')
                        }
                    }}
                    className="btn btn-outline pr-3"
                >
                    {props.createdLabel}

                    <ChevronRightIcon className="w-5 h-5 text-primary ml-auto" />
                </button>
            )}

            {props.newLabel && (
                <Link
                    to={props.newLink ?? ""}
                    onClick={() => !props.newLink ? window.location.reload() : null}
                    className="btn btn-outline pr-3"
                >
                    {props.newLabel}

                    <ChevronRightIcon className="w-5 h-5 text-primary ml-auto" />
                </Link>
            )}

            {props.listLabel && (
                <button
                    onClick={() => {
                        if (props.listClickAction) {
                            props.listClickAction();
                        } else {
                            window.open(`${window.location.origin}${props.listLink}`, '_self')
                        }
                    }}
                    className="btn btn-outline pr-3"
                >
                    {props.listLabel}
                    <ChevronRightIcon className="w-5 h-5 text-primary ml-auto" />

                </button>
            )}

            {props.dashboardLabel && (
                <button
                    onClick={() => window.open(`${window.location.origin}/home`, '_self')}
                    className="btn btn-outline pr-3"
                >
                    {props.dashboardLabel}

                    <ChevronRightIcon className="w-5 h-5 text-primary ml-auto" />
                </button>
            )}
        </div>
    );
    return (
        <Modal
            show={props.show}
            onClose={props.onClose}
            widthClass={"sm:max-w-xs sm:w-full"}
        >
            <div className="text-tm-gray-900">
                <ModalHeader
                    title={props.title}
                    onClose={props.onClose}
                />

                <div className="p-6">
                    <div className="mb-6">{props.children}</div>

                    {customButtonsHTML}
                </div>
            </div>
        </Modal>
    )
}

export default RedirectDialog;
