import Layout from "../../../common/components/layout";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "../../../common/util/util-auth";
import {Field, FieldsManager} from "../../../data/services/fields";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";
import ResourcePage from "../../../common/components/layout/layout-components/resource-page";
import Resources from "../../../data/services/resources";
import {checkPerm, longTableColumn} from "../../../common/util/util-helpers";
import useQueryNew from "../../../hooks/use-query-new";
import {deleteResource, getResource, updateResource} from "../../../data/actions/resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import {useLocation} from "react-router-dom";
import LocalStorage from "../../../common/util/localStorage";
import CreateDivisionDialog from "./dialogs/create-update-division";
import Modal from "../../../common/components/modal";
import {UPDATE_PERM} from "../../../util/util-constants";
import DivisionInfoDialog from "./dialogs/info-dialog";
import ModalDefault from "../../../common/components/modal/modal-default";
import {currentDate} from "../../../common/util/util-dates";
import {download} from "../../../data/actions/download";

export default function DivisionsView({translate, match, history}) {
    /** Page constants
     ================================================================= */
    const pageTitle = translate('page.heading.Divisions');
    const tableKey = 'DivisionName';
    const noRecordsText = "Create a new division";
    const noRecordsButtonLabel = "Create division"
    const resourcePath = Resources.Divisions;
    const tableDefaults = {
        behaviour: {
            rowSelect: true,
            canAdjustWidth: true,
            hasMenu: true
        },
        style: {
            condensed: true,
            isGPUAccelerated: true
        },
        version: "1" // changing version will force update tableDefaults settings
    }

    const location = useLocation();
    const pagePath = location.pathname.replaceAll("/", "");

    const user = getUser();
    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);
    const resource = useSelector(state => state.resource);

    const [breakpoint, setBreakpoint] = useState({});
    const [isResourceFormModalOpen, setIsResourceFormModalOpen] = useState(false);
    const [queryFields, setQueryFields] = useQueryNew(getQueryFields(), pagePath);
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
    const [isConfirmRestoreModalOpen, setIsConfirmRestoreModalOpen] = useState(false);
    const [isResourceInfoModalOpen, setIsResourceInfoModalOpen] = useState(false);
    const query = FieldsManager.getFieldKeyValues(queryFields);

    const [selectedItem, setSelectedItem] = useState({});
    const [prefilled, setPrefilled] = useState("");

    /** Data Events
     ================================================================= */
    function fetchData() {
        dispatch(getResource({
            user: getUser(),
            resource: resourcePath,
            query: query
        }))
    }

    function downloadExcel() {
        dispatch(download({
            user: getUser(),
            resource: resourcePath,
            query: Object.assign({format: 'EXCEL', name: 'divisions_' + currentDate() + '.xlsx'}, query)
        }));
    }

    /** UI Events
     ================================================================= */
    function handleBreakpointChange(breakpointUpdate) {
        setBreakpoint(Object.assign({}, breakpointUpdate));

        if (breakpointUpdate.index <= 1 && queryFields.limit.value !== 10) {
            handleQueryInputChange('limit', 10);
        }
    }

    function handleOpenResourceFormClick(item = null) {
        setIsResourceFormModalOpen(true);
        setSelectedItem(item);
    }

    function handleCloseResourceFormClick() {
        setIsResourceFormModalOpen(false);
        setSelectedItem(null);
        setPrefilled("");
    }

    function handleQueryInputChange(name, value) {
        const queryFieldsUpdate = FieldsManager.updateField(queryFields, name, value);

        if ("sortBy" === name) {
            if (value === queryFieldsUpdate?.sortBy?.value) {
                queryFieldsUpdate.sort.value = queryFieldsUpdate.sort.value === 'ASC' ? 'DESC' : 'ASC'
            }
        }

        setQueryFields(queryFieldsUpdate);
    }


    function handleClearAllFiltersClick() {
        setQueryFields(getQueryFields());
    }

    function handleViewResourceClick(item) {
        setIsResourceInfoModalOpen(true);
        setSelectedItem(item);
    }

    function handleDeleteResourceClick(item) {
        setSelectedItem(item);
        setIsConfirmDeleteModalOpen(true);
    }

    function handleConfirmDeleteClick() {
        dispatch(deleteResource({
            user: getUser(),
            query: Object.assign({
                DivisionID: selectedItem.DivisionID
            }, query),
            errorMessage: true,
            successMessage: `${selectedItem.DivisionName} archived`,
            resource: resourcePath,
            piggyResource: resourcePath
        }));
        setSelectedItem({});
        setIsConfirmDeleteModalOpen(false);
    }

    function handleRestoreResourceClick(item) {
        setSelectedItem(item);
        setIsConfirmRestoreModalOpen(true);
    }

    function handleConfirmRestoreClick() {
        dispatch(updateResource({
            user: getUser(),
            params: {
                DivisionID: selectedItem.DivisionID,
                ArchivedDate: 1
            },
            query: query,
            errorMessage: true,
            successMessage: `${selectedItem.DivisionName} restored`,
            resource: resourcePath,
            piggyResource: resourcePath
        }));
        setSelectedItem({});
        setIsConfirmRestoreModalOpen(false);
    }

    /** Helpers
     ================================================================= */
    function getQueryFields() {
        return {
            query: new Field('query', '', [''], false, 'search'),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            sort: new Field('sort', 'DESC', ['']),
            sortBy: new Field('sortBy', tableKey, ['']),
            offset: new Field('offset', '', ['']),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    function getFields() {
        return {
            DivisionName: new Field('DivisionName', '', ['empty']),
            Description: new Field('Description', '', ['empty'], false, 'textarea', {
                render: (item) => (
                    <div>{longTableColumn(item.Description)}</div>
                )
            }),
            CreateUpdateDate: new Field('CreateUpdateDate', '', ['empty'], false, 'date'),
        }
    }

    useEffect(() => {
        fetchData();
    }, [queryFields]);

    useEffect(() => {
        // Todo: remove in the next version
        function consumeLegacyState() {
            const oldState = LocalStorage.get(pagePath + "_state");
            if (oldState) {
                const queryFieldsUpdate = Object.assign({}, queryFields);
                queryFieldsUpdate.sort.value = oldState.sort;
                queryFieldsUpdate.sortBy.value = oldState.sortBy;
                queryFieldsUpdate.limit.value = oldState.limit;
                queryFieldsUpdate.offset.value = oldState.offset;
                queryFieldsUpdate.query.value = oldState.queryFilterFields?.query?.value ?? "";
                queryFieldsUpdate.archived.value = oldState.queryFilterFields.archived?.value ?? "";

                LocalStorage.remove(pagePath + "_state");
                setQueryFields(queryFieldsUpdate);
            }
        }

        consumeLegacyState();
    }, [pagePath]);

    /** Render
     ================================================================= */
    return (
        <Layout
            ui={ui}
            user={user}
            match={match}
            history={history}
            location={location}
            translate={translate}
            dispatch={dispatch}
            isAccessible={!(resource.errorStatus === 2)}
            onBreakpointChange={handleBreakpointChange}
        >
            <ResourcePage
                tableKey={tableKey}
                pagePath={pagePath}
                resourcePath={resourcePath}
                pageTitle={pageTitle}
                tableFields={getFields()}
                onCreateResourceClick={handleOpenResourceFormClick}
                onNoRecordsCreateClick={() => {
                    setPrefilled(queryFields.query.value);
                    handleOpenResourceFormClick();
                }}
                queryFields={queryFields}
                onQueryInputChange={handleQueryInputChange}
                onClearAllFiltersClick={handleClearAllFiltersClick}
                fetchData={fetchData}
                translate={translate}
                breakpoint={breakpoint}
                onView={handleViewResourceClick}
                onEdit={handleOpenResourceFormClick}
                onDelete={handleDeleteResourceClick}
                onRestore={handleRestoreResourceClick}
                onRowClick={handleOpenResourceFormClick}
                tableDefaults={tableDefaults}
                noRecordsText={noRecordsText}
                noRecordsButtonLabel={noRecordsButtonLabel}
                onDownloadExcel={downloadExcel}
            />

            <Modal
                show={isResourceFormModalOpen}
                widthClass={'max-w-5xl'}
                onClose={handleCloseResourceFormClick}
            >
                <CreateDivisionDialog
                    show={isResourceFormModalOpen}
                    selectedItem={selectedItem}
                    prefilled={prefilled}
                    translate={translate}
                    close={handleCloseResourceFormClick}
                    query={query}
                />
            </Modal>

            <ModalDefault
                show={isResourceInfoModalOpen}
                widthClass={'max-w-3xl'}
                title={`Division '${selectedItem?.DivisionName}' Info`}
                onButtonClick={() => {
                    setIsResourceInfoModalOpen(false);
                    setIsResourceFormModalOpen(true);
                }}
                buttonLabel={checkPerm(Resources.Divisions, UPDATE_PERM) && translate("btn.edit")}
                closeButtonLabel={translate('btn.Cancel')}
                onClose={() => setIsResourceInfoModalOpen(false)}
            >
                <DivisionInfoDialog
                    selectedItem={selectedItem}
                    translate={translate}
                />
            </ModalDefault>

            <ModalConfirm
                title={translate("modal_heading.confirm_archive")}
                show={isConfirmDeleteModalOpen}
                text={`${translate('message.confirm_archive_generic')} ${selectedItem?.DivisionName}?`}
                onClose={() => {
                    setIsConfirmDeleteModalOpen(false);
                    setSelectedItem({});
                }}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={handleConfirmDeleteClick}
            />

            <ModalConfirm
                title={translate("modal_heading.confirm_restore")}
                show={isConfirmRestoreModalOpen}
                text={`${translate('message.are_you_sure_restore_division')} ${selectedItem?.DivisionName}?`}
                onClose={() => {
                    setIsConfirmRestoreModalOpen(false);
                    setSelectedItem(null);
                }}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={handleConfirmRestoreClick}
            />
        </Layout>
    )
}