import React, {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import {
    DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS,
    MAXIMUM_DOCUMENT_UPLOAD_SIZE,
    REACT_DROPZONE_ONE_MEGABYTE
} from "../../../util/util-constants"
import {pushNotification} from "../../../data/actions/ui";
import {useDispatch} from "react-redux";

export default function DropZoneAlt({
                                        content,
                                        maxFilesAccepted = 1,
                                        onAcceptFiles,
                                        accept = DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS,
                                        className = "",
                                        maxSize = MAXIMUM_DOCUMENT_UPLOAD_SIZE
                                    }) {
    const dispatch = useDispatch();

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length) {
            onAcceptFiles(acceptedFiles);
        }
    }, [])

    const onDropRejected = useCallback(rejectedFiles => {
        if (rejectedFiles.length) {
            let filesOverMaxSize = [];
            rejectedFiles.forEach(rejectedFile => {
                if (rejectedFile.size > maxSize) {
                    filesOverMaxSize.push(rejectedFile.name);
                }
            });

            if (filesOverMaxSize.length) {
               dispatch(pushNotification({
                        title: `One or more files are too large to upload (The limit is ${maxSize / REACT_DROPZONE_ONE_MEGABYTE}MB per file.)`,
                        content: "Rejected files: " + filesOverMaxSize.join(", "),
                        messageType: "error"
                    }
                ));
            }
        }
    }, [])

    const {
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        multiple: maxFilesAccepted > 1,
        maxFiles: maxFilesAccepted,
        onDropRejected: onDropRejected,
        onDrop,
        accept: accept,
        maxSize: maxSize
    });

    return (
        <div
            className={className}
            {...getRootProps()}
        >
            <input {...getInputProps()} />

            {content(isDragAccept, isDragReject)}
        </div>
    )
}