import React, {useEffect, useRef, useState} from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import {GlobalWorkerOptions} from 'pdfjs-dist';
import {LoaderLarge, LoaderSmall} from "../../../loader";
import {
    ArrowUturnRightIcon,
    DocumentDuplicateIcon,
    MagnifyingGlassMinusIcon,
    MagnifyingGlassPlusIcon
} from "@heroicons/react/24/outline";
import MagnifyingGlassIcon from "@heroicons/react/24/outline/MagnifyingGlassIcon";
import ChevronLeftIcon from "@heroicons/react/24/outline/ChevronLeftIcon";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import DocumentIcon from "@heroicons/react/24/outline/DocumentIcon";

const PDFViewer = ({filePath}) => {
    const maxZoom = 2.5;
    const minZoom = 0.5;
    const defaultZoom = 1.5;

    const canvasRefs = useRef([]);
    const [pdfDoc, setPdfDoc] = useState(null);
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isRendering, setIsRendering] = useState(false);
    const [rotation, setRotation] = useState(0); // New state for rotation angle
    const [currentScale, setCurrentScale] = useState(defaultZoom);
    const [currentPage, setCurrentPage] = useState(1);
    const [isSinglePageView, setIsSinglePageView] = useState(true);
    const [value, setValue] = useState(1);

    function handleValueChange(val) {
        setValue(val);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (isNaN(value)) {
                setCurrentPage(Number(currentPage));
                setValue(Number(currentPage));
                return false;
            }

            if (Number(value) > totalPages || Number(value) < 1) {
                setCurrentPage(Number(currentPage));
                setValue(Number(currentPage))
                return false;
            }

            setCurrentPage(Number(value))
            setValue(Number(value))
        }
    }

    const handleRotateClick = () => {
        setRotation((prevRotate) => (prevRotate + 90) % 360);
    };

    const handleZoomInClick = () => {
        let zoom = currentScale + 0.25;

        if (zoom >= maxZoom) {
            zoom = maxZoom;
        }

        setCurrentScale(zoom);
    }
    const handleZoomOutClick = () => {
        let zoom = currentScale - 0.25;

        if (zoom <= minZoom) {
            zoom = minZoom;
        }

        setCurrentScale(zoom);
    }

    const handleResetZoomClick = () => {
        setCurrentScale(defaultZoom);
    }


    useEffect(() => {
        GlobalWorkerOptions.workerSrc = new URL(
            'pdfjs-dist/build/pdf.worker.mjs',
            import.meta.url
        ).toString();
    }, []);

    useEffect(() => {
        const loadPDF = async () => {
            try {
                setIsLoading(true);
                const loadingTask = pdfjsLib.getDocument(filePath);
                const pdf = await loadingTask.promise;
                setPdfDoc(pdf);
                setTotalPages(pdf.numPages);
            } catch (error) {
                console.error('Error loading PDF:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (filePath) loadPDF();
    }, [filePath]);

    useEffect(() => {
        const renderPage = async () => {
            if (!pdfDoc || isRendering) return;

            try {
                setIsRendering(true);
                for (let i = 0; i < isSinglePageView ? 1 : totalPages; i++) {
                    const page = await pdfDoc.getPage(isSinglePageView ? currentPage : i + 1);
                    const viewport = page.getViewport({scale: currentScale, rotation}); // Add rotation to viewport
                    const canvas = canvasRefs.current[i];
                    if (!canvas) return;
                    const context = canvas.getContext("2d");

                    // Adjust canvas size based on rotation
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    // Render PDF page into canvas context
                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport,
                    };

                    await page.render(renderContext).promise;
                }
            } catch (error) {
                console.error('Error rendering page:', error);
            } finally {
                setIsRendering(false);
            }
        };

        renderPage();
    }, [pdfDoc, rotation, currentScale, currentPage, isSinglePageView]);

    return (
        <div>
            <div
                className="sticky left-0 top-0 mb-2 z-20 inline-flex items-center gap-x-4 gap-1 bg-inverse px-0 py-1 w-full border-b border-dashed border-tm-gray-300">
                <button
                    onClick={handleRotateClick}
                    className="w-9 h-9 flex items-center justify-center rounded-btn text-primary hover:bg-primary-transparent disabled:text-tm-gray-600 disabled:bg-tm-gray-200 disabled:opacity-25"
                >
                    <ArrowUturnRightIcon className={'w-7 h-7'}/>
                </button>

                <button
                    onClick={handleZoomInClick}
                    className="w-9 h-9 flex items-center justify-center rounded-btn text-primary hover:bg-primary-transparent disabled:text-tm-gray-600 disabled:bg-tm-gray-200 disabled:opacity-25"
                    disabled={currentScale === maxZoom}
                >
                    <MagnifyingGlassPlusIcon className={'w-7 h-7'}/>
                </button>

                <button
                    onClick={handleResetZoomClick}
                    className="w-9 h-9 flex items-center justify-center rounded-btn text-primary hover:bg-primary-transparent disabled:text-tm-gray-600 disabled:bg-tm-gray-200 disabled:opacity-25"
                    disabled={currentScale === defaultZoom}
                >
                    <MagnifyingGlassIcon className={'w-7 h-7'}/>
                </button>

                <button
                    onClick={handleZoomOutClick}
                    className="w-9 h-9 flex items-center justify-center rounded-btn text-primary hover:bg-primary-transparent disabled:text-tm-gray-600 disabled:bg-tm-gray-200 disabled:opacity-25"
                    disabled={currentScale === minZoom}
                >
                    <MagnifyingGlassMinusIcon className={'w-7 h-7'}/>
                </button>

                {totalPages > 1 && (
                    <button
                        onClick={() => setIsSinglePageView(!isSinglePageView)}
                        className="w-9 h-9 flex items-center justify-center rounded-btn text-primary hover:bg-primary-transparent disabled:text-tm-gray-600 disabled:bg-tm-gray-200 disabled:opacity-25"
                    >
                        {!isSinglePageView && (
                            <DocumentIcon className={'w-7 h-7'}/>
                        )}

                        {isSinglePageView && (
                            <DocumentDuplicateIcon className={'w-7 h-7'}/>
                        )}
                    </button>
                )}

                {isSinglePageView && totalPages > 1 && (
                    <div className="relative z-0 inline-flex -space-x-px ml-auto">
                        <button
                            disabled={currentPage <= 1 || isLoading}
                            onClick={() => {
                                setCurrentPage(currentPage - 1);
                                setValue(currentPage - 1);
                            }}
                            className="btn btn-outline-secondary border border-tm-gray-300 px-2 rounded-r-none z-10"
                        >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon className="w-5 h-5"/>
                        </button>

                        <input
                            className="form-control rounded-none w-14 text-right px-1.5 relative focus:z-20"
                            onChange={(e) => handleValueChange(e.target.value)}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) => e.target.select()}
                            value={value}
                        />

                        <div className="relative">
                            <input
                                disabled={true}
                                className="form-control rounded-none w-14 px-0 text-center"
                                value={isLoading ? "" : `of ${totalPages}`}
                            />

                            {isLoading && (
                                <LoaderSmall/>
                            )}
                        </div>

                        <button
                            disabled={currentPage >= totalPages || isLoading}
                            onClick={() => {
                                setCurrentPage(currentPage + 1);
                                setValue(currentPage + 1);
                            }}
                            className="btn btn-outline-secondary border border-tm-gray-300 px-2 rounded-l-none"
                        >
                            <span className="sr-only">Next</span>

                            <ChevronRightIcon className="w-5 h-5"/>
                        </button>
                    </div>
                )}
            </div>

            {isLoading && <LoaderLarge/>}

            {pdfDoc && (
                <div className="w-full overflow-x-auto">
                    {Array.from({length: isSinglePageView ? 1 : totalPages}).map((_, index) => (
                        <div key={index}
                             className={index > 0 ? "mt-4 border-t-8 border-tm-gray-300 border-dashed pt-4" : undefined}>
                            <canvas
                                className="border-2 border-tm-gray-300 mx-auto"
                                ref={(el) => (canvasRefs.current[index] = el)}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PDFViewer;
