import React, {useEffect, useState} from 'react';
import {DEFAULT_CRUD_STATE, READ_PERM} from "../../../util/util-constants";
import {Field, FieldsManager} from "../../../data/services/fields";
import {checkPerm, getProp, JSONParseFix} from "../../../common/util/util-helpers";
import {getResource} from "../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {EyeIcon} from "@heroicons/react/24/outline";
import Env from "../../../util/env";
import {download} from "../../../data/actions/download";
import {ArrowDownTrayIcon} from "@heroicons/react/24/outline";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import PopoverDatePicker from "../../../common/components/popover/popover-datepicker";
import ResourceTable from "../../../common/components/resource-table";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import ModalDefault from "../../../common/components/modal/modal-default";
import FileViewer from "../../../common/components/file-viewer/components";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import {getJWT} from "../../../common/util/util-auth";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";

const AgentSettlementTab = (props) => {

    /** Constants
     ================================================================= */
    const {translate, dispatch, agentID, match} = props;
    const pagePath = match.path.substring(1)

    /** Fields/Data definitions
     ================================================================= */
    const getFields = (item = null) => {
        const fieldTemplates = {
            PayrollBatch: new Field('PayrollBatch', '', [], false, 'text'),
            Date: new Field('Date', '', [], false, 'date'),
            BookDate: new Field('BookDate', '', [''], false, 'date', {
                label: 'CheckPostingDate'
            }),
            Gross: new Field('Gross', '', [], false, 'money'),
            TotalAmount: new Field('TotalAmount', '', [], false, 'money', {
                label: 'NetAmount'
            }),
            PayToOrganizationID: new Field('PayToOrganizationID', '', [], false, 'custom', {
                render: (it) => {
                    return it.PayToOrganizationID ? it.PayToOrganization : ''
                }
            }),
            IncludeIn1099: new Field('IncludeIn1099', '', [], false, 'checkbox'),
            IsPayed: new Field('IsPayed', '', [], false, 'checkbox')
        }
        return fillFieldsFromData(fieldTemplates, item)
    }

    const getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            StartDate: new Field('StartDate', '', [], false, 'date', {labelType: "stack"}),
            EndDate: new Field('EndDate', '', [], false, 'date', {labelType: "stack"}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    /** State
     ================================================================= */
    const [fields, setFields] = useState(getFields())
    const [query, setQuery] = useState(DEFAULT_CRUD_STATE)
    const [queryFilterFields, setQueryFilterFields] = useState(LocalStorage.has(pagePath + '_state') ? JSONParseFix(LocalStorage.get(pagePath + '_state'))?.queryFilterFields : getQueryFilterFields())
    const [selectedItem, setSelectedItem] = useState(null)

    /** Helpers
     ================================================================= */
    const getID = () => {
        return agentID
    }

    const getResourceName = () => {
        return Resources.AgentsPayroll
    }

    const getQuery = () => {
        return {
            AgentID: getID(),
            offset: query.offset,
            sort: query.sort,
            sortBy: query.sortBy,
            ...FieldsManager.getFieldKeyValues(queryFilterFields),
        }
    }

    const saveFilters = () => {
        LocalStorage.persistState(pagePath, {
            query,
            queryFilterFields
        }, Object.keys(getQuery()))
    }

    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            query: getQuery(),
            resource: getResourceName()
        }))
    }

    /** UI events
     ================================================================= */
    const handleFilterInputChange = (name, value) => {
        setQueryFilterFields(FieldsManager.updateField(queryFilterFields, name, value))
    }

    const handleUpdateQueryFields = (fields) => {
        setQueryFilterFields(fields);
    }

    const handleToggleViewModal = (selectedItem = null) => {
        setSelectedItem(selectedItem)
    }

    const handleClearFiltersClick = () => {
        setQueryFilterFields(getQueryFilterFields())
    }

    const handleUpdateOffset = (offset, num) => {
        setQuery((prevState) => ({
            ...prevState,
            offset: offset,
            paginationPage: num
        }))
    }

    const handleUpdateSort = (sortBy) => {
        setQuery((prevState) => ({
            ...prevState,
            sortBy: sortBy,
            sort: (query.sortBy === sortBy) ? (query.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }))
    }

    const downloadDocument = (item) => {
        props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.PayrollBatchesItemsDocument,
            query: {
                PayrollBatchEntryID: selectedItem?.PayrollBatchEntryID ?? item.PayrollBatchEntryID,
                type: 1,
                name: (selectedItem?.Name ?? item.Name) + ' ' + (selectedItem?.PayrollBatch ?? item.PayrollBatch) + '.pdf'
            },
            errorMessage: true, successMessage: 'Successfully downloaded payroll batch!'
        }))
    }
    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
        saveFilters()
    }, [query, queryFilterFields]);

    /** Render
     ================================================================= */
    const renderBatchItemPDF = (translate) => {
        return (
            <ModalDefault
                show={!!selectedItem}
                widthClass={'max-w-7xl'}
                limitHeight={true}
                title={translate("modal_heading.upload_confirm")}
                buttonLabel={translate('btn.download')}
                onButtonClick={downloadDocument}
                close={handleToggleViewModal}
                closeButtonLabel={translate('Close')}
                onClose={handleToggleViewModal}
            >
                <FileViewer
                    fileType={'pdf'}
                    filePath={Env.getApiUrl('api/' + Resources.PayrollBatchesItemsDocument, Object.assign({}, {
                        PayrollBatchEntryID: selectedItem.PayrollBatchEntryID,
                        token: getJWT().access_token,
                        type: 1
                    }))}
                    onError={(e) => {
                        console.log(e)
                    }}/>
            </ModalDefault>
        )
    }

    const data = getProp(props, 'resource.data.list', [])
    const count = getProp(props, 'resource.data.count', 0)
    const isLoading = getProp(props, 'resource.isLoading', false)

    return (
        <>
            <div className={"mb-5"}>
                <ActiveFilters
                    filterFields={queryFilterFields}
                    onLabelClick={handleFilterInputChange}
                    onClearFiltersClick={handleClearFiltersClick}
                    translate={translate}
                />
            </div>

            <TableCard>
                <TableFilters
                    hideLimit
                    filterFields={queryFilterFields}
                    handleInputChange={handleFilterInputChange}
                    onRefreshTable={fetchData}
                    customHeaderHtml={
                        <PopoverDatePicker
                            queryFields={queryFilterFields}
                            onQueryInputChange={handleFilterInputChange}
                            updateFields={handleUpdateQueryFields}
                            translate={translate}
                        />
                    }
                    translate={translate}
                />

                <ResourceTable
                    data={data}
                    count={count}

                    // tableKey="PayrollBatchEntryID"
                    // onSelectRow={handleSelectRowClick}
                    // selectedRows={selectedRows}
                    // onSelectAllClick={handleSelectAllClick}
                    // options={tablePageDefaults}

                    fields={fields}
                    translate={translate}
                    isLoading={isLoading}

                    limit={queryFilterFields?.limit?.value ?? query.limit}
                    offset={query.offset}
                    page={query.paginationPage}
                    onOffsetChange={handleUpdateOffset}

                    sort={query.sort}
                    sortBy={query.sortBy}
                    onSortChange={handleUpdateSort}

                    onRowClick={checkPerm(Resources.PayrollBatchesItemsDocument, READ_PERM) && handleToggleViewModal}

                    actions={[
                        {
                            tooltipText: () => translate("btn.preview"),
                            action: (it) => handleToggleViewModal(it),
                            icon: () => EyeIcon,
                            hidden: () => false,
                            title: () => translate('btn.preview'),
                            visible: () => checkPerm(Resources.PayrollBatchesItemsDocument, READ_PERM),
                        },
                        {
                            tooltipText: () => translate("btn.download"),
                            action: (it) => downloadDocument(it),
                            icon: () => ArrowDownTrayIcon,
                            hidden: () => false,
                            title: () => translate('btn.download'),
                            visible: () => checkPerm(Resources.PayrollBatchesItemsDocument, READ_PERM),

                        },
                    ]}
                />

                <NoRecordsTable
                    show={(data.length === 0) && !isLoading}
                    canCreate={false}
                    title={translate('text.no_matching_records')}
                    className={"pb-12 pt-16 px-6"}
                />

                {(data.length !== 0) && !isLoading && (
                    <TableCardFooter>
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === "offset"
                                    ? handleUpdateOffset(value, currentPage)
                                    : handleFilterInputChange(name, value)
                            }
                            pageOffset={query.offset}
                            queryFields={queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>
                )}

            </TableCard>

            {!isLoading && !!selectedItem && renderBatchItemPDF(translate)}
        </>
    )
}

export default AgentSettlementTab;