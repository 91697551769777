import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {getProp, mergeDeep, verifyFilterFields} from '../../../common/util/util-helpers'
import Resources from '../../../data/services/resources'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {download} from '../../../data/actions/download'
import {currentDate} from '../../../common/util/util-dates'
import moment from 'moment-timezone'
import {genericMoneyFormatter} from '../../../common/util/util-vanilla'
import PayDateFilter from "../carrier-pay/carrier-pay-date-filter";
import NoRecords from "../../../common/components/no-records-found/no-records";
import ResourceTable from "../../../common/components/resource-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";


export default class TrialBalances extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),
        }

        this.state = mergeDeep(this.state, verifyFilterFields(
            LocalStorage.rehydrateState(this.pagePath),
            this.state.queryFilterFields
        ))
    }

    componentDidMount() {
        this.fetchData();
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'reports_trial_balances_' + currentDate() + '.xlsx'
            }, this.getQuery())
        }))
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
        }, () => {
            this.fetchData()
        })
    }

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input'])
    }

    getResourceName = () => {
        return Resources.AccountingReportsTrialBalances
    }

    getQuery = () => {
        return {
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    getQueryFilterFields = () => {
        return {
            StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), ['empty'], false, 'date', {
                labelType: 'float',
                addContainerClass: 'relative z-40'
            }, {
                isClearable: true,

            }),
            EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), ['empty'], false, 'date', {
                labelType: 'float',
                addContainerClass: 'relative z-40'
            }, {
                isClearable: true,
            }),
        }
    }

    getFields = () => {
        return {
            Name: new Field('Name', '', [''], false, 'text', {}),
            Value: new Field('Value', '', [''], false, 'float', {}),
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {
            translate,
        } = this.props

        const dataBalance = getProp(this.props, 'resource.data.Balance', [])
        const netBalance = getProp(this.props, 'resource.data.NetBalance', [])
        const dataIncome = getProp(this.props, 'resource.data.Income', [])
        const netIncome = getProp(this.props, 'resource.data.NetIncome', [])
        const retainedEarning = getProp(this.props, 'resource.data.RetainedEarning', [])
        const isLoading = getProp(this.props, 'resource.isLoading', false)

        return (
            <React.Fragment>
                <div className="flex justify-between items-center">
                    <div className="flex items-end ml-auto">
                        <Tippy content={translate('text.download_excel')}>
                            <button
                                className="btn-icon"
                                onClick={this.downloadExcel}
                            >
                                <DocumentArrowDownIcon className="w-5 h-5"/>
                            </button>
                        </Tippy>
                    </div>
                </div>

                <TableCard>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        largeGrid
                        forceAll
                        forceDialog
                        customHtml={
                            <PayDateFilter
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                                updateQueryFields={(queryFieldsUpdate) => {
                                    this.setState({
                                        queryFilterFields: queryFieldsUpdate,
                                        offset: 0
                                    }, () => this.fetchData())
                                    this.saveFilters(queryFieldsUpdate)
                                }}
                                onQueryChange={this.handleFilterInputChange}
                                hasDateType={false}
                                gridCols={"grid-cols-1"}
                            />
                        }
                    />

                    <div className={`relative z-0 grid grid-cols-2 divide-x divide-tm-gray-200 h-full overflow-hidden`}>
                        <div className="h-full overflow-hidden relative">
                            <div className="flex flex-col max-h-full">
                                <ResourceTable
                                    tableKey={"AccountID"}
                                    data={dataBalance}
                                    limit={dataBalance.length}
                                    fields={this.getFields()}
                                    options={{
                                        behaviour: {hasVirtualRows: true},
                                        style: {floatingActions: true, horizontalLines: true}
                                    }}
                                    translate={translate}
                                    isLoading={isLoading}
                                />

                                {!!(this.state.queryFilterFields.StartDate.value && this.state.queryFilterFields.EndDate.value) && (
                                    <NoRecords
                                        show={(dataBalance.length === 0) && !isLoading}
                                        title={translate('text.no_records')}
                                        addClass={'pt-16 pb-10'}
                                    />
                                )}

                                {(!this.state.queryFilterFields.StartDate.value || !this.state.queryFilterFields.EndDate.value) && (
                                    <NoRecords
                                        show={!isLoading}
                                        title={translate('text.startEndDateRequired')}
                                        addClass={'pt-16 pb-10'}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="h-full overflow-hidden relative">
                            <div className="flex flex-col max-h-full">
                                <ResourceTable
                                    tableKey={"AccountID"}
                                    data={dataIncome}
                                    limit={dataIncome.length}
                                    fields={this.getFields()}
                                    options={{
                                        behaviour: {hasVirtualRows: true},
                                        style: {floatingActions: true, horizontalLines: true}
                                    }}
                                    translate={translate}
                                    isLoading={isLoading}
                                />

                                {!!(this.state.queryFilterFields.StartDate.value && this.state.queryFilterFields.EndDate.value) && (
                                    <NoRecords
                                        show={(dataIncome.length === 0) && !isLoading}
                                        title={translate('text.no_records')}
                                        addClass={'pt-16 pb-10'}
                                    />
                                )}

                                {(!this.state.queryFilterFields.StartDate.value || !this.state.queryFilterFields.EndDate.value) && (
                                    <NoRecords
                                        show={!isLoading}
                                        title={translate('text.startEndDateRequired')}
                                        addClass={'pt-16 pb-10'}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-5 p-2 border-t border-tm-gray-200">
                        <div className="flex items-center justify-between pl-5 font-bold text-lg">
                            <div>Net Balance</div>
                            <div>{genericMoneyFormatter(netBalance)}</div>
                            <div>Retained Earnings</div>
                            <div>{genericMoneyFormatter(retainedEarning)}</div>
                        </div>
                        <div className="flex items-center justify-between pl-5 font-bold text-lg">
                            <div>Net Income</div>
                            <div>{genericMoneyFormatter(netIncome)}</div>
                        </div>
                    </div>

                </TableCard>
            </React.Fragment>
        )
    }
}
