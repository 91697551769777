import React, {Fragment, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getResource, updateResource} from "../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import PermissionsMenu from "./permissions-menu";
import PermissionsList from "./permissions-list";
import {cloneDeep} from "../../../common/util/util-vanilla";
import ExclamationCircleIcon from "@heroicons/react/20/solid/ExclamationCircleIcon";
import {checkPerm, classNames, getProp, resourceIsUpdated} from "../../../common/util/util-helpers";
import {Field, FieldsManager} from "../../../data/services/fields";
import {getSecondResource} from "../../../data/actions/secondResource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import {getDialogResource} from "../../../data/actions/dialogResource";
import {CheckIcon, ClipboardDocumentIcon, DocumentDuplicateIcon, UsersIcon} from "@heroicons/react/24/outline";
import {Menu, Transition} from "@headlessui/react";
import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";
import {toFrontDateTimeFromUTC} from "../../../common/util/util-dates";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import ButtonsGroup from "../../../common/components/buttons/buttons-group";
import NoRecords from "../../../common/components/no-records-found/no-records";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import Layout from "../../../common/components/layout";
import ResourceTable from "../../../common/components/resource-table";
import ModalDefault from "../../../common/components/modal/modal-default";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import SlideOver from "../../../common/components/slideover";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import {LoaderLarge} from "../../../common/components/loader";
import FieldsToHtml from "../../../common/components/fields/fields-to-html";
import FieldSearch from "../../../common/components/fields/field-text/search";
import Button from "../../../common/components/button";
import {READ_PERM, UPDATE_PERM} from "../../../util/util-constants";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";
export default function ContactPermissionsView({translate, match, history, location}) {
    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const ui = useSelector((state) => state.ui);
    const user = useSelector((state) => state.user);
    const resource = useSelector((state) => state.resource);
    const contact = resource?.data?.Contact;
    const isLoading = resource?.isLoading;

    const data = resource?.data;

    const allowAllPermissions = resource?.data?.AllowAll;

    const secondResource = useSelector((state) => state.secondResource);
    const isCompareDataLoading = secondResource.isLoading;

    const dialogResource = useSelector((state) => state.dialogResource);
    const contactsListData = dialogResource?.data?.list ?? [];
    const contactsListDataCount = dialogResource?.data?.count;
    const contactsLisIsLoading = dialogResource.isLoading;
    const AllowPermGrantUpToLevelSelf = resource?.data?.AllowPermGrantUpToLevelSelf;
    const AllowPermGrantUpToLevelInitial = resource?.data?.AllowPermGrantUpToLevel;

    /** State
     ================================================================= */
    const [permissionsState, setPermissionsState] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState({
        categoryName: "",
        subCategoryName: "",
        subCategoryItems: []
    });
    const [menuQuery, setMenuQuery] = useState("");
    const [isStateDirty, setIsStateDirty] = useState(false);
    const [AllowAll, setAllowAll] = useState(allowAllPermissions);
    const [allowPermGrantUpToLevel, setAllowPermGrantUpToLevel] = useState("");
    const [isContactSelectorDialogOpen, setIsContactSelectorDialogOpen] = useState(false);
    const [isConfirmSelectDialogOpen, setIsConfirmSelectDialogOpen] = useState(false);
    const [selectedContact, setSelectedContact] = useState({});
    const [hasContactToCompare, setHasContactToCompare] = useState(false);
    const [isHelpOpen, setIsHelpOpen] = useState(false);
    const [helpData, setHelpData] = useState({});

    const [queryFields, setQueryFields] = useState(getQueryFields())

    const [compareContact, setCompareContact] = useState({});
    const [compareData, setCompareData] = useState({});

    const currentUserPerms = useMemo(() => {
        const currentPerms = resource?.data?.CurrentUserPerms ?? [];
        return currentPerms.reduce((memo, it) => {
            memo[it.Name] = it.ChildrenGroups.reduce((childMemo, childIt) => {
                childMemo[childIt.Name] = childIt.ChildrenChildrenGroups.reduce((childChildMemo, childChildIt) => {
                    if (childChildIt.HavePerm) {
                        childChildMemo[childChildIt.Desc] = childChildIt
                    }
                    return childChildMemo;
                }, {});
                return childMemo;
            }, {});
            return memo;
        }, {})
    }, [resource]);

    const compareOptions = [
        {
            name: translate("btn.clear_compare"),
            icon: CheckIcon,
            onClick: () => {
                setCompareContact({});
                setCompareData({});
                setHasContactToCompare(false);
            }
        },
        {
            name: translate("btn.copy_contacts_permissions", [compareContact?.FirstName]),
            icon: ClipboardDocumentIcon,
            onClick: () => handleCopyContactPermissions(compareContact)
        }
    ]

    /** Data events
     ================================================================= */
    const onSubmit = () => {
        const resources = permissionsState.reduce((memo, group) => {
            group.ChildrenGroups.forEach((cg) => {
                cg.ChildrenChildrenGroups.forEach((ccg) => {
                    if (!memo[ccg.Resource]) {
                        memo[ccg.Resource] = {
                            resource_id: 0,
                            perm: {}
                        };
                    }
                    if (ccg.HavePerm) {
                        memo[ccg.Resource].resource_id = ccg.ResourceID;
                        memo[ccg.Resource].perm[ccg.PermLevel] = true;

                        !!ccg.IncludedResources && ccg.IncludedResources.forEach((ir) => {
                            if (!memo[ir.Resource]) {
                                memo[ir.Resource] = {
                                    resource_id: 0,
                                    perm: {}
                                };
                            }
                            memo[ir.Resource].resource_id = ir.ResourceID;
                            memo[ir.Resource].perm[ir.PermLevel] = true;
                        })
                    } else {
                        memo[ccg.Resource].resource_id = ccg.ResourceID;
                        memo[ccg.Resource].perm[0] = true;

                        !!ccg.IncludedResources && ccg.IncludedResources.forEach((ir) => {
                            if (!memo[ir.Resource]) {
                                memo[ir.Resource] = {
                                    resource_id: 0,
                                    perm: {}
                                };
                            }
                            memo[ir.Resource].resource_id = ir.ResourceID;
                            memo[ir.Resource].perm[0] = true;
                        })
                    }
                })
            })
            return memo;
        }, []);

        const params = {
            Resources: Object.values(resources).map((it) => {
                return {
                    resource_id: it.resource_id,
                    perm: Object.keys(it.perm).reduce((memo, p) => memo + parseInt(p), 0)
                }
            }),
            AllowPermGrantUpToLevel: Number(allowPermGrantUpToLevel),
            AllowAll: Number(AllowAll),
            id: match.params.id
        }

        dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: params,
            errorMessage: true,
            successMessage: `Permissions for user ${contact.FirstName} ${contact.LastName} updated successfully.`,
            resource: Resources.UserPermissions,
            id: match.params.id,
            piggyResource: Resources.UserPermissions,
            query: {id: match.params.id}
        }))
    }

    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            query: {id: match.params.id},
            resource: Resources.UserPermissions
        }))
    }

    const fetchCompareData = (userID) => {
        dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            query: {id: userID},
            resource: Resources.UserPermissions
        }))
    }

    const fetchUserData = () => {
        dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            query: FieldsManager.getFieldKeyValues(queryFields),
            resource: Resources.Contacts
        }))
    }

    const submitCopyPermissions = () => {
        dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {
                UserID: selectedContact.ContactID,
                id: match.params.id
            },
            successMessage: `Permissions for user ${contact.FirstName} ${contact.LastName} copied successfully.`,
            query: FieldsManager.getFieldKeyValues(queryFields),
            resource: Resources.UserPermissionsCopy
        }));
        setIsContactSelectorDialogOpen(false);
        setIsConfirmSelectDialogOpen(false);
    }

    /** UI events
     ================================================================= */
    const handleQueryInputChange = (name, value) => {
        const queryFieldsUpdate = cloneDeep(queryFields)

        if ("sortBy" === name) {
            queryFieldsUpdate.sort.value = queryFieldsUpdate.sort.value === 'ASC' ? 'DESC' : 'ASC'
        }
        setQueryFields(FieldsManager.updateField(queryFieldsUpdate, name, value));
    }

    function handleCopyContactPermissions(contact) {
        setIsConfirmSelectDialogOpen(true);
        setSelectedContact(contact);
    }

    const handleCompareContactsPermissions = (item) => {
        setHasContactToCompare(true);
        fetchCompareData(item.ContactID);
        setIsContactSelectorDialogOpen(false);
    }

    const handleSelectCategoryClick = (categoryName, selectedItem) => {
        setSelectedSubCategory({
            categoryName: categoryName,
            subCategoryName: selectedItem.Name,
            subCategoryItems: selectedItem.ChildrenChildrenGroups
        });
    }

    const handlePermClick = (it, categoryName, subCategoryName) => {
        setPermissionsState(permissionsState.map(cat => {
            if (cat.Name === categoryName) {
                cat.ChildrenGroups = cat.ChildrenGroups.map(subCat => {
                    if (subCat.Name === subCategoryName) {
                        subCat.ChildrenChildrenGroups.map(permIt => {
                            if (it.Desc === permIt.Desc) {
                                permIt.HavePerm = it.HavePerm ? 0 : 1
                            }

                            return permIt;
                        })
                    }
                    return subCat;
                })
            }

            return cat;
        }));

        setIsStateDirty(true);
    }

    const handleSelectAllClick = (areAllSelected, categoryName, subCategoryName) => {
        setPermissionsState(permissionsState.map(cat => {
            if (cat.Name === categoryName) {
                cat.ChildrenGroups = cat.ChildrenGroups.map(subCat => {
                    if (subCat.Name === subCategoryName) {
                        subCat.ChildrenChildrenGroups.map(permIt => {
                            if (!permIt.isLocked) {
                                permIt.HavePerm = !areAllSelected
                            }

                            return permIt;
                        })
                    }
                    return subCat;
                })
            }

            return cat;
        }));

        setIsStateDirty(true);
    }

    const handleMenuQueryInputChange = (val) => {
        setMenuQuery(val);
    }

    const handleShowHelpClick = (data) => {
        setIsHelpOpen(true);
        setHelpData(data[0]);
    }

    let canManagePermGrant = false;

    const handleCompareContactClick = () => {
        setIsContactSelectorDialogOpen(true);
        fetchUserData();
    }

    /** Helpers
     ================================================================= */
    const getPermissionList = () => {
        const cat = permissionsState.find(cat => cat.Name === selectedSubCategory.categoryName)?.ChildrenGroups ?? [];
        let subCat = [];

        if (cat.length) {
            subCat = cat.find(subCat => subCat.Name === selectedSubCategory.subCategoryName)?.ChildrenChildrenGroups ?? [];
        }

        if (subCat.length) {
            subCat = subCat.map(it => {
                if (it.Desc === 'Manage permissions (full-access)') {
                    canManagePermGrant = it.HavePerm;
                }

                if (AllowPermGrantUpToLevelSelf) {
                    it.isLocked = !Object.prototype.hasOwnProperty.call(currentUserPerms?.[selectedSubCategory.categoryName]?.[selectedSubCategory.subCategoryName], it.Desc)
                }
                return it;
            });
        }

        return subCat;
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setPermissionsState(cloneDeep(resource?.data?.Groups) ?? []);
        setAllowAll(resource?.data?.AllowAll === "1" || resource?.data?.AllowAll === 1 ? "1" : "0");
        setIsStateDirty(false);
    }, [resource])

    const isInitialEffectRef = useRef(true);

    useEffect(() => {
        if (!isInitialEffectRef.current) {
            fetchUserData();
        }

        isInitialEffectRef.current = false;
    }, [queryFields]);

    useEffect(() => {
        if (resourceIsUpdated(resource) && resource.resource === Resources.UserPermissionsCopy) {
            setIsContactSelectorDialogOpen(false);
            fetchData();
        }
    }, [resource])

    useEffect(() => {
        if (hasContactToCompare) {
            if (secondResource?.data?.Contact) {
                setCompareContact(secondResource.data.Contact);
            }

            if (secondResource?.data?.Groups) {
                setCompareData(secondResource.data.Groups);
            }
        }
    }, [secondResource, hasContactToCompare])

    useEffect(() => {
        setAllowPermGrantUpToLevel(AllowPermGrantUpToLevelInitial ? "1" : "0");
    }, [AllowPermGrantUpToLevelInitial]);

    return (
        <Layout
            ui={ui}
            user={user}
            match={match}
            history={history}
            location={location}
            translate={translate}
            dispatch={dispatch}
            hasFooter
        >
            <Page className="min-h-[calc(100vh-16rem)]">
                <PageHeader
                    className="flex w-full max-w-5xl mx-auto"
                    title={
                        <div className={'flex justify-start items-center'}>
                            <div className={"relative right-2"}>
                                <GoBackButton
                                    translate={translate}
                                    history={history}
                                    path={'/system-users'}
                                    canSubmit={isStateDirty}
                                />
                            </div>
                            {contact?.FirstName ? translate('page.heading.user_permissions_for', [contact.FirstName, contact.LastName]) : translate('page.heading.user_permissions')}
                        </div>
                    }
                    afterTitle={(
                        <PageHeaderInfo
                            dispatch={dispatch}
                        />
                    )}
                    customHtml={
                        <div className="flex">
                            <Button
                                isLoading={isLoading}
                                hasPerm={!AllowPermGrantUpToLevelSelf && checkPerm(Resources.UserPermissionsCopy, UPDATE_PERM)}
                                className={
                                    classNames(
                                        `btn btn-outline-secondary focus:z-10`,
                                        hasContactToCompare ? 'rounded-r-none' : ''
                                    )
                                }
                                onClick={handleCompareContactClick}
                            >
                                {translate('btn.compare_with_another_user')}
                            </Button>

                            {hasContactToCompare && (
                                <Menu as="div" className="relative -ml-px block">
                                    <Menu.Button
                                        className="btn px-2 relative btn-outline-secondary focus:z-10 rounded-l-none">
                                        <span className="sr-only">Open options</span>
                                        <ChevronDownIcon className="h-5 w-5 text-tm-gray-600" aria-hidden="true"/>
                                    </Menu.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-popup border border-tm-gray-200 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                                {compareOptions.map((item) => (
                                                    <Menu.Item key={item.name}>
                                                        {({active}) => (
                                                            <button
                                                                onClick={item.onClick}
                                                                className={classNames(
                                                                    active ? 'bg-tm-gray-100 text-tm-gray-900' : 'text-tm-gray-700',
                                                                    'block text-left px-4 py-2 text-sm w-full gap-x-1 flex'
                                                                )}
                                                            >
                                                                {item.icon && (
                                                                    <item.icon className="text-tm-gray-600 w-5 h-5"/>
                                                                )}
                                                                {item.name}
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            )}


                        </div>
                    }
                />

                <div className="max-w-5xl mx-auto w-full">
                    <div className=" mb-5">

                        {!!data?.PermCreateUpdateDate && (
                            <div>(Permissions last updated
                                by {data?.PermUpdatedByContactID?.FirstName} {data?.PermUpdatedByContactID?.LastName} at {toFrontDateTimeFromUTC(data?.PermCreateUpdateDate)})</div>

                        )}

                        <div className="relative flex items-center pt-2">
                            <div className="min-w-0 flex-1 text-sm">
                                <label className="font-medium text-tm-gray-900 select-none">
                                    Allow user permissions:
                                    {/*<div className="text-tm-gray-700 pr-4">Add some more info if needed</div>*/}
                                </label>
                            </div>

                            <ButtonsGroup
                                addClass={"mx-auto"}
                                disabled={false}
                                isLoading={isLoading}
                                data={{
                                    "0": translate('btn.only_assigned_groups'),
                                    "1": <div className="flex">{translate('btn.permission_all_resources')}
                                        <ExclamationCircleIcon
                                            className={
                                                classNames("w-5 h-5 text-yellow-600 ml-2",
                                                    AllowAll === "0" || isLoading ? "invisible" : undefined)
                                            }
                                        />
                                    </div>
                                }}
                                value={!isLoading && AllowAll}
                                onChange={() => {
                                    setAllowAll(AllowAll === "1" ? "0" : "1");
                                    setIsStateDirty(true);
                                }}
                            />
                        </div>
                    </div>

                    {AllowPermGrantUpToLevelSelf === "1" && (
                        <InfoBar>You are allowed to grant only permissions that you have yourself</InfoBar>
                    )}
                </div>

                <div
                    className="relative w-full max-w-5xl mx-auto p-6 bg-popup rounded-card border-tm-gray-300 border shadow-card"
                >
                    <div className="flex gap-6">
                        <div className="rounded-card bg-tm-gray-50 w-60 space-y-4">
                            <FieldSearch
                                type="text"
                                addClass="w-full form-control min-h-[2.25rem]"
                                value={menuQuery}
                                onChange={(_, value) => handleMenuQueryInputChange(value)}
                                placeholder={translate("text.filter_menu")}
                            />

                            <PermissionsMenu
                                isLoading={isLoading}
                                filter={menuQuery}
                                permissions={permissionsState}
                                onSelectCategory={handleSelectCategoryClick}
                                translate={translate}
                            />
                        </div>

                        <div className="w-full">
                            <div
                                className="w-full rounded-card overflow-hidden divide-y divide-tm-gray-50 border border-tm-gray-300">
                                <PermissionsList
                                    categoryName={selectedSubCategory.categoryName}
                                    subCategoryName={selectedSubCategory.subCategoryName}
                                    permissionsList={getPermissionList()}
                                    onShowHelpClick={handleShowHelpClick}
                                    onPermClick={handlePermClick}
                                    onSelectAll={handleSelectAllClick}
                                    compareContact={compareContact}
                                    currentContact={contact}
                                    compareData={compareData}
                                    clearCompare={() => {
                                        setCompareContact({});
                                        setCompareData({});
                                        setHasContactToCompare(false);
                                    }}
                                    isLoading={isLoading || isCompareDataLoading}
                                    translate={translate}
                                />
                            </div>

                            {selectedSubCategory.subCategoryName === "System users" && (
                                <div className="relative flex items-center pt-2">
                                    <div className="min-w-0 flex-1 text-sm">
                                        <label className="font-medium text-tm-gray-900 select-none">
                                            Can manage permission:
                                            <div className="text-tm-gray-700 pr-4">Select if user can manage all
                                                permissions or only up to what they are allowed themselves</div>
                                        </label>
                                    </div>

                                    <ButtonsGroup
                                        addClass={"mx-auto"}
                                        disabled={!canManagePermGrant}
                                        isLoading={isLoading}
                                        data={{
                                            "0": <div className="flex">{translate('btn.all_permissions')}
                                                <ExclamationCircleIcon
                                                    className={
                                                        classNames("w-5 h-5 text-yellow-600 ml-2",
                                                            allowPermGrantUpToLevel === "1" || isLoading ? "invisible" : undefined)
                                                    }
                                                />
                                            </div>,
                                            "1": translate('btn.only_allowed')
                                        }}
                                        value={!isLoading && allowPermGrantUpToLevel}
                                        onChange={() => {
                                            if (AllowPermGrantUpToLevelSelf === 0) {
                                                setAllowPermGrantUpToLevel(allowPermGrantUpToLevel === "1" ? "0" : "1");
                                                setIsStateDirty(true);
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </div>

                    </div>

                    {isLoading || isCompareDataLoading && (
                        <LoaderLarge stripesBg/>
                    )}
                </div>

                <PageFooter
                    translate={translate}
                    canSubmit={isStateDirty}
                    actionCancel={() => {
                        setPermissionsState(cloneDeep(resource?.data?.Groups) ?? []);
                        setIsStateDirty(false);
                    }}
                    actionSubmit={onSubmit}
                />
            </Page>

            <ModalDefault
                show={isContactSelectorDialogOpen}
                widthClass={'max-w-5xl'}
                title={translate('text.select_contact_for_perms')}
                closeButtonLabel={translate('btn.Cancel')}
                onClose={() => setIsContactSelectorDialogOpen(false)}
                hideDialogFooter
            >
                <React.Fragment>
                    <div className="grid grid-cols-4 gap-4 px-6 pt-2 pb-4 border-b border-tm-gray-300">
                        <FieldsToHtml
                            fieldsState={queryFields}
                            includeFields={["query"]}

                            onInputChange={handleQueryInputChange}
                            translate={translate}
                        />
                    </div>

                    <ResourceTable
                        commonTable={true}
                        data={contactsListData}
                        count={contactsListDataCount}

                        tableKey={"copyPermissionContacts"}

                        onRowClick={(row) => handleCompareContactsPermissions(row)}

                        actions={[
                            {
                                action: handleCompareContactsPermissions,
                                icon: UsersIcon,
                                title: translate("text.compare_permissions"),
                                hasPerm: checkPerm(Resources.UserPermissions, READ_PERM)
                            },
                            {

                                action: handleCopyContactPermissions,
                                icon: DocumentDuplicateIcon,
                                title: translate("text.copy_permissions"),
                                hasPerm: checkPerm(Resources.UserPermissionsCopy, UPDATE_PERM)
                            }
                        ]}

                        fields={{
                            FirstName: new Field('FirstName', ''),
                            LastName: new Field('LastName', ''),
                            Email: new Field('Email', ''),
                            ContactID: new Field('ContactID', '', [''], false, 'hidden', {hideTable: true}),
                        }}

                        translate={translate}
                        isLoading={contactsLisIsLoading}

                        sort={queryFields.sort.value}
                        sortBy={queryFields.sortBy.value}
                        onSortChange={(value) => handleQueryInputChange('sortBy', value)}
                        options={{
                            style: {
                                floatingActions: false,
                                stripedRows: true
                            }
                        }}
                    />

                    <NoRecords
                        addClass={"mt-5"}
                        show={(contactsListData.length === 0) && !contactsLisIsLoading}
                        title={translate('text.no_records')}
                    />

                    <TableCardFooter
                        addClass=""
                        show={!(!contactsListData.length && !contactsLisIsLoading)}
                    >
                        <Pagination
                            count={contactsListDataCount}
                            isLoading={contactsLisIsLoading}
                            handleQueryChange={handleQueryInputChange}
                            queryFields={queryFields}
                            translate={translate}
                        />
                    </TableCardFooter>

                    <ModalConfirm
                        title={translate('text.confirm')}
                        show={isConfirmSelectDialogOpen}
                        onClose={() => setIsConfirmSelectDialogOpen(false)}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={submitCopyPermissions}
                    >
                        <p>{translate("text.copy_permissions_confirm", [selectedContact.FirstName + " " + selectedContact.LastName])}</p>
                    </ModalConfirm>
                </React.Fragment>
            </ModalDefault>

            <ModalConfirm
                title={translate('text.confirm')}
                show={isConfirmSelectDialogOpen}
                onClose={() => setIsConfirmSelectDialogOpen(false)}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={submitCopyPermissions}
            >
                <p>{translate("text.copy_permissions_confirm", [selectedContact.FirstName + " " + selectedContact.LastName])}</p>
            </ModalConfirm>

            <SlideOver
                title={translate("dialog_heading.permission_details")}
                open={isHelpOpen}
                setOpen={() => setIsHelpOpen(false)}
                widthClass="max-w-sm"
            >
                <div className="p-5 prose text-tm-gray-900">
                    <p className="text-xl">{helpData.Title}</p>

                    <div className="text-sm">
                        {getProp(helpData, "Content", []).map(it => {
                            switch (it.Type) {
                                case '<p>':
                                    return <p>{it.Data}</p>
                                case "<ul>":
                                    return (
                                        <div>
                                            {!!it.Data && <p className="mb-0 font-bold">{it.Data}</p>}
                                            <ul>
                                                {(it.Children ?? []).map((child, i) => {
                                                    return <li key={i}>{child.Data}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    );
                                case "<ol>":
                                    return (
                                        <div>
                                            {!!it.Data && <p className="mb-0 font-bold">{it.Data}</p>}
                                            <ol>
                                                {(it.Children ?? []).map((child, i) => {
                                                    return <li key={i}>{child.Data}</li>;
                                                })}
                                            </ol>
                                        </div>
                                    )
                            }

                        })}
                    </div>
                </div>
            </SlideOver>
        </Layout>
    )
}

const getQueryFields = () => {
    return {
        query: new Field('query', '', [''], false, 'search', {hideLabel: true, labelType: "float"}),
        offset: new Field('offset', '0', [''], false, 'text'),
        sort: new Field('sort', 'ASC', [''], false, 'text'),
        sortBy: new Field('sortBy', 'FirstName', [''], false, 'text'),
        IsSystemUser: new Field('IsSystemUser', '1', [''], false, 'text'),
        limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
            hideLabel: true,
            labelType: "float"
        }, {menuPlacement: "top"})
    }
}