import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import JournalTab from './journal-tab'
import Page from "../../../common/components/layout/layout-components/page";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Layout from "../../../common/components/layout";

const JournalView = ({translate, match, history, location}) => {
    /** Store
     ================================================================= */
    const dispatch = useDispatch()
    const ui = useSelector((state) => state.ui)
    const resource = useSelector((state) => state.resource)
    const user = useSelector((state) => state.user)


    /** State
     ================================================================= */
    const [breakpoint, setBreakpoint] = useState({})

    /** UI events
     ================================================================= */


    const handleBreakpointChange = (breakpoint) => {
        setBreakpoint(breakpoint)
    }

    /** Render
     ================================================================= */
    return (
        <Layout
            onBreakpoinChange={handleBreakpointChange}
            ui={ui}
            user={user}
            match={match}
            history={history}
            location={location}
            translate={translate}
            dispatch={dispatch}
            isAccessible={!(resource.errorStatus === 2)}
        >
            <Page>
                <PageHeader
                    title={translate('page.heading.AccountingJournal')}
                    afterTitle={(
                        <PageHeaderInfo
                            dispatch={dispatch}
                        />
                    )}
                />

                <JournalTab
                    translate={translate}
                    breakpoint={breakpoint}
                />
            </Page>
        </Layout>
    )
}

export default JournalView
