import {classNames} from "../../../util/util-helpers";
import CheckIcon from "@heroicons/react/20/solid/CheckIcon";
import React, {useEffect, useRef, useState} from "react";
import ModalDefault from "../../modal/modal-default";
import ColorPickerNew from "../index";
import {TrashIcon} from "@heroicons/react/24/outline";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import ColorPickerDeleteColors from "../color-picker-delete-colors";
import NavResponsive from "../../nav-responsive";
import {COLOR_PICKER_COLOR} from "../../../../util/util-constants";
import LocalStorage from "../../../util/localStorage";
import Tooltip from "../../tooltip";

const predefinedColors = [
    {
        name: "neutral",
        colors: ["#e5e5e5", "#d4d4d4", "#a8a29e", "#78716c", "#57534e", "#404040", "#262626", "#171717"]
    },
    {
        name: "red",
        colors: ["#fecaca", "#fca5a5", "#f87171", "#ef4444", "#dc2626", "#b91c1c", "#991b1b", "#7f1d1d"]
    },
    {
        name: "orange",
        colors: ["#fed7aa", "#fdba74", "#fb923c", "#f97316", "#ea580c", "#c2410c", "#9a3412", "#7c2d12"]
    },
    {
        name: "amber",
        colors: ["#fde68a", "#fcd34d", "#fbbf24", "#f59e0b", "#d97706", "#b45309", "#92400e", "#78350f"]
    },
    {
        name: "yellow",
        colors: ["#fef08a", "#fde047", "#facc15", "#eab308", "#ca8a04", "#a16207", "#854d0e", "#713f12"]
    },
    {
        name: "lime",
        colors: ["#d9f99d", "#bef264", "#a3e635", "#84cc16", "#65a30d", "#4d7c0f", "#3f6212", "#365314"]
    },
    {
        name: "green",
        colors: ["#bbf7d0", "#86efac", "#4ade80", "#22c55e", "#16a34a", "#15803d", "#166534", "#14532d"]
    },
    {
        name: "emerald",
        colors: ["#a7f3d0", "#6ee7b7", "#34d399", "#10b981", "#059669", "#047857", "#065f46", "#064e3b"]
    },
    {
        name: "teal",
        colors: ["#99f6e4", "#5eead4", "#2dd4bf", "#14b8a6", "#0d9488", "#0f766e", "#115e59", "#134e4a"]
    },
    {
        name: "cyan",
        colors: ["#a5f3fc", "#67e8f9", "#22d3ee", "#06b6d4", "#0891b2", "#0e7490", "#155e75", "#164e63"]
    },
    {
        name: "sky",
        colors: ["#bae6fd", "#7dd3fc", "#38bdf8", "#0ea5e9", "#0284c7", "#0369a1", "#075985", "#0c4a6e"]
    },
    {
        name: "blue",
        colors: ["#bfdbfe", "#93c5fd", "#60a5fa", "#3b82f6", "#2563eb", "#1d4ed8", "#1e40af", "#1e3a8a"]
    },
    {
        name: "indigo",
        colors: ["#c7d2fe", "#a5b4fc", "#818cf8", "#6366f1", "#4f46e5", "#4338ca", "#3730a3", "#312e81"]
    },
    {
        name: "violet",
        colors: ["#ddd6fe", "#c4b5fd", "#a78bfa", "#8b5cf6", "#7c3aed", "#6d28d9", "#5b21b6", "#4c1d95"]
    },
    {
        name: "purple",
        colors: ["#e9d5ff", "#d8b4fe", "#c084fc", "#a855f7", "#9333ea", "#7e22ce", "#6b21a8", "#581c87"]
    },
    {
        name: "fuchsia",
        colors: ["#f5d0fe", "#f0abfc", "#e879f9", "#d946ef", "#c026d3", "#a21caf", "#86198f", "#701a75"]
    },
    {
        name: "pink",
        colors: ["#fbcfe8", "#f9a8d4", "#f472b6", "#ec4899", "#db2777", "#be185d", "#9d174d", "#831843"]
    },
    {
        name: "rose",
        colors: ["#fecdd3", "#fda4af", "#fb7185", "#f43f5e", "#e11d48", "#be123c", "#9f1239", "#881337"]
    },
]

export default function ColorPickerUserColors({translate, selectedColor = "", setSelectedColor, clear}) {
    const colorRef = useRef("");
    const userStoredColors = LocalStorage.get("user_stored_colors");
    const [userColors, setUserColors] = useState((userStoredColors ?? COLOR_PICKER_COLOR ?? []).map(it => it.toLowerCase()));

    const [isColorPickerDialogOpen, setIsColorPickerDialogOpen] = useState(false);
    const [isDeleteColorsDialogOpen, setIsDeleteColorsDialogOpen] = useState(false);
    const [colorOutsidePalette, setColorOutsidePalette] = useState(false);
    const [tabs, setTabs] = useState(getTabs());

    const currentTab = tabs.find(it => it.current);

    const isColorOutsidePalette = !!selectedColor && !userColors.includes(selectedColor?.toLowerCase());

    function handleAddColor() {
        if (currentTab.name === 'ColorGradient' && colorRef.current && colorRef.current[1]) {
            setIsColorPickerDialogOpen(false);

            if (!colorOutsidePalette && !userColors.includes(colorRef.current[0])) {
                setUserColors([...userColors, colorRef.current[0]]);
            }
            setSelectedColor(colorRef.current[0]);
        }

        if (currentTab.name === 'PredefinedColors' && selectedColor) {
            if (!colorOutsidePalette) {
                setUserColors([...userColors, selectedColor]);
            }
            setIsColorPickerDialogOpen(false);
        }

        setColorOutsidePalette(false);
    }

    function handleDeleteColors(colors) {
        setUserColors(userColors.filter(color => !colors.includes(color)));
    }

    function getTabs() {
        return [
            {
                id: 1,
                name: 'ColorGradient',
                resource: 'ColorGradient',
                visible: true,
                current: true
            },
            {
                id: 2,
                name: 'PredefinedColors',
                resource: 'PredefinedColors',
                visible: true,
            }
        ];
    }

    const handleTabChange = (tabName) => {
        let tabsUpdate = [...tabs];
        tabsUpdate.map(tab => {
            tab.current = tabName === tab.resource;

            return tab;
        });

        setTabs(tabsUpdate);
        if (tabName !== "ColorGradient") {
            setSelectedColor(colorRef.current[0]);
        }
    }

    useEffect(() => {
        LocalStorage.set("user_stored_colors", userColors)
    }, [userColors]);

    return <>
        <div className="">
            <div className="flex flex-wrap gap-4 pt-4 pb-2 mx-auto">
                {clear && (
                    <Tooltip
                        trigger={'mouseenter'}
                        content={"No color"}>
                        <button
                            onClick={() => {
                                setSelectedColor("")
                            }}
                            className={classNames(
                                'relative w-10 h-10 bg-stripes border border-tm-gray-300 rounded-full ring-offset-inverse ring-primary focus:ring-2 focus:ring-offset-2 flex items-center justify-center focus:ring-offset-inverse outline-0 focus:outline-none',
                            )}
                        >
                            {!selectedColor && (
                                <CheckIcon className="w-7 h-7 text-tm-gray-700"/>
                            )}
                        </button>
                    </Tooltip>
                )}

                <Tooltip
                    trigger={'mouseenter'}
                    content={
                        !isColorOutsidePalette
                            ? "Selected color that is not in the color palette."
                            : "Select a color that is not in the color palette."
                    }>
                    <button
                        onClick={() => {
                            setColorOutsidePalette(true);
                            setIsColorPickerDialogOpen(true);
                        }}
                        className={classNames(
                            'relative w-10 h-10 bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 rounded-full ring-offset-inverse ring-primary focus:ring-2 focus:ring-offset-2 flex items-center justify-center focus:ring-offset-inverse outline-0 focus:outline-none',
                        )}
                    >
                        {isColorOutsidePalette && (
                            <div
                                className="w-8 h-8 rounded-full flex items-center justify-center border-2 border-white"
                                style={{background: selectedColor}}
                            >
                                <CheckIcon className="w-7 h-7 text-white"/>
                            </div>
                        )}
                    </button>
                </Tooltip>

                {userColors.map((it, i) => (
                    <button
                        key={i}
                        onClick={() => {
                            setSelectedColor(it);
                        }}
                        className={classNames(
                            'w-10 h-10 rounded-full ring-offset-inverse ring-primary focus:ring-2 focus:ring-offset-2 flex items-center justify-center focus:ring-offset-inverse outline-0 focus:outline-none',
                        )}
                        style={{background: it}}
                    >
                        {it?.toLowerCase() === selectedColor?.toLowerCase() && (
                            <CheckIcon className="w-7 h-7 text-white"/>
                        )}
                    </button>
                ))}

                {!!userColors.length && (
                    <button
                        onClick={() => setIsDeleteColorsDialogOpen(true)}
                        className={classNames(
                            'w-10 h-10 border border-tm-gray-400 hover:text-red-400 hover:border-red-400  text-tm-gray-400 rounded-full ring-offset-inverse ring-primary focus:ring-2 focus:ring-offset-2 flex items-center justify-center focus:ring-offset-inverse outline-0 focus:outline-none',
                        )}
                    >

                        <TrashIcon className="w-6 h-6"/>
                    </button>
                )}

                <Tooltip content="Add a color to the color palette">
                    <button
                        onClick={() => setIsColorPickerDialogOpen(true)}
                        disabled={userColors.length >= 35}
                        className={classNames(
                            'w-10 h-10 text-primary border border-primary rounded-full ring-offset-inverse ring-primary focus:ring-2 focus:ring-offset-2 flex items-center justify-center focus:ring-offset-inverse outline-0 focus:outline-none disabled:border-tm-gray-200 disabled:bg-tm-gray-200 disabled:text-inverse',
                        )}
                    >

                        <PlusIcon className="w-6 h-6"/>
                    </button>
                </Tooltip>
            </div>
        </div>

        <ModalDefault
            show={isColorPickerDialogOpen}
            widthClass={'max-w-lg'}
            title={"Color picker"}

            closeButtonLabel={translate('btn.Cancel')}
            onClose={() => {
                setIsColorPickerDialogOpen(false);
                setColorOutsidePalette(false);
            }}
            buttonLabel={colorOutsidePalette ? "Select color" : "Add color"}
            onButtonClick={handleAddColor}
        >
            <div className="px-6 pb-6 bg-tm-gray-100">
                <NavResponsive
                    tabs={tabs}
                    onTabChange={handleTabChange}
                    translate={translate}
                />

                {currentTab.name === "ColorGradient" && (
                    <div className="pt-12 pb-8">
                        <ColorPickerNew
                            colorRef={colorRef}
                            color={selectedColor}
                        />
                    </div>
                )}

                {currentTab.name === "PredefinedColors" && (
                    <div className='text-center mt-10'>
                        <div className="space-y-4">
                            {predefinedColors.map(it => {
                                return <div key={it.name} className="flex max-w-md mx-auto">
                                    <p className="capitalize mr-auto font-semibold">{it.name}</p>

                                    {it.colors.map((color, index) => {
                                        return <button
                                            key={color}
                                            className="w-10 h-6 text-center"
                                            style={{background: color}}
                                            onClick={() => setSelectedColor(color)}
                                        >
                                            {selectedColor === color && (
                                                <CheckIcon
                                                    className={
                                                        classNames(index > 2 ? "text-white" : "text-gray-900", "w-5 h-5 inline-block")
                                                    }
                                                />
                                            )}
                                        </button>
                                    })}
                                </div>
                            })}
                        </div>
                    </div>
                )}
            </div>
        </ModalDefault>

        <ColorPickerDeleteColors
            isDeleteColorsDialogOpen={isDeleteColorsDialogOpen}
            setIsDeleteColorsDialogOpen={setIsDeleteColorsDialogOpen}
            onDeleteColors={handleDeleteColors}
            userColors={userColors}
            translate={translate}
        />
    </>
}