import React, {useEffect, useState} from "react";
import Resources from "../../../data/services/resources";
import {Field, FieldsManager} from "../../../data/services/fields";
import {checkPerm, getDefaultQueryFields, getUser} from "../../../util/util";
import {useDispatch, useSelector} from "react-redux";
import {createResource, deleteResource, getResource, updateResource} from "../../../data/actions/resource";
import useQuery from "../../../hooks/use-query";
import {getProp} from "../../../common/util/util-helpers";
import LocalStorage from "../../../common/util/localStorage";
import {showGlobalModal, showModal} from "../../../data/actions/ui";
import {CREATE_PERM, DELETE_PERM, READ_PERM, UPDATE_PERM} from "../../../common/util/util-consts";
import InformationCircleIcon from "@heroicons/react/20/solid/esm/InformationCircleIcon";
import Layout from "../../../common/components/layout";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import ResourceList from "../../../common/components/layout/layout-components/resource-list";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import FieldOptions from "../../../common/components/fields/field-options";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Page from "../../../common/components/layout/layout-components/page";
import {DEFAULT_LIMIT, DEFAULT_OFFSET, DEFAULT_SORT} from "../../../util/util-constants";

export default function RentalContractsView({history, match, translate}) {

    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const isLoading = getProp(resource, "isLoading", true);
    const count = getProp(resource.data, "count", 0)

    /** State
     ================================================================= */
    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), getListPath())
    const query = Object.assign({}, FieldsManager.getFieldKeyValues(queryFields), {});

    // Modals/Modals data
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [confirmDialogData, setConfirmDialogData] = useState({});


    /** Lifecycles
     ================================================================= */
    useEffect(() => {
        fetchData(dispatch, query)
    }, [queryFields]);

    /** UI events
     ================================================================= */
    const handleToggleCreateModal = () => {
        setCreateModalOpen(!createModalOpen)
    }

    const handleToggleEditModal = (item = null) => {
        history.push("/" + Resources.RentalContractsInfo + "/" + item.RentalContractID)
        //setSelectedItem(item)
        //setEditModalOpen(!editModalOpen)
    }

    const handleModalInputChange = (fieldsClone, name, value) => {
        fieldsClone = FieldsManager.updateField(fieldsClone, name, value)

        if (name === 'RentTo') {
            if (value === 1) {
                fieldsClone['RentOrganizationID'].value = ''
                fieldsClone['RentOrganizationID'].disabled = true
                fieldsClone['RentOrganizationID'].validate = ['']
                fieldsClone['RentContactID'].validate = ['empty']

                fieldsClone['RentContactID'].disabled = false
            }
            if (value === 2) {
                fieldsClone['RentContactID'].value = ''
                fieldsClone['RentContactID'].disabled = true
                fieldsClone['RentContactID'].validate = ['']
                fieldsClone['RentOrganizationID'].validate = ['empty']


                fieldsClone['RentOrganizationID'].disabled = false
            }
        }

        return fieldsClone
    }

    const handleDeleteContractClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => onConfirmDeleteClick(item),
            type: "danger",
            content: (
                <span>
                    Are you sure you want to delete <span className="font-bold text-red-600">{item.AutoCounter}</span>?
                </span>
            ),
            title: translate("text.confirm_archive"),
            buttonLabel: translate("btn.archive")
        })
    }

    const handleRestoreContractClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => onConfirmRestoreClick(item),
            type: "danger",
            content: (
                <span>
                    Are you sure you want to restore <span
                    className="font-bold text-green-600">{item.AutoCounter}</span>?
                </span>
            ),
            title: translate("text.confirm_restore"),
            buttonLabel: translate("btn.restore")
        })
    }

    const onConfirmDeleteClick = (item) => {
        dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: Object.assign({[getPrimaryField()]: item[getPrimaryField()]}),
            notificationMessage: translate(`text.rental_contract_deleted`),
            resource: getResourcePath(),
            piggyResource: getResourcePath(),
            successMessage: translate("text.rental_contract_deleted"),
            errorMessage: true
        }));

        closeConfirmDialog();
    }

    const onConfirmRestoreClick = (item) => {
        dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: Object.assign({[getPrimaryField()]: item[getPrimaryField()], ArchivedDate: 1}),
            notificationMessage: translate(`text.rental_contract_restored`),
            resource: getResourcePath(),
            piggyResource: getResourcePath(),
            query: query,
            successMessage: translate("text.rental_contract_restored"),
            errorMessage: true
        }));

        closeConfirmDialog();
    }

    const closeConfirmDialog = () => {
        setConfirmDialogData(Object.assign({}, confirmDialogData, {isOpen: false}))
    }

    const handleViewContactClick = (contactID) => {
        dispatch(showModal("ViewContactCard", {ContactID: contactID}))
    }

    const handleViewOrganizationClick = (organizationID) => {
        dispatch(showGlobalModal('organizationInfo', organizationID));
    }

    const handleContactInfoClick = (contactID) => {
        dispatch(showModal("ViewContactCard", {ContactID: contactID}))
    }

    const handleOrganizationInfoClick = (organizationID) => {
        dispatch(showModal("ViewCustomerCard", {OrganizationID: organizationID}))
    }

    /** Component Body
     ================================================================= */
    return (
        <React.Fragment>
            <Layout
                history={history}
                match={match}
                translate={translate}
            >
               <Page>
                <PageHeader
                    title={translate("page_title.rental-contracts")}
                    onButtonClick={handleToggleCreateModal}
                    buttonHidden={!checkPerm(getResourcePath(), CREATE_PERM)}
                    buttonLabel={translate("btn.create")}
                />

                <ResourceList
                    fields={getFields(translate, handleViewContactClick, handleViewOrganizationClick, handleContactInfoClick, handleOrganizationInfoClick)}

                    resource={resource}
                    isLoading={isLoading}
                    queryFields={queryFields}
                    setQueryFields={setQueryFields}
                    listPath={getListPath()}
                    dispatch={dispatch}
                    query={query}
                    onRefreshTable={() => fetchData(dispatch, query)}
                    translate={translate}
                    selects={getSelects()}

                    tableKey={"RentalContractID"}
                    onRowClick={checkPerm(getResourcePath(), UPDATE_PERM) && handleToggleEditModal}
                    onEdit={checkPerm(getResourcePath(), UPDATE_PERM) && handleToggleEditModal}
                    onDelete={checkPerm(getResourcePath(), DELETE_PERM) && handleDeleteContractClick}
                    onRestore={checkPerm(getResourcePath(), UPDATE_PERM) && handleRestoreContractClick}
                >
                </ResourceList>
               </Page>
            </Layout>

            <ModalConfirm
                show={confirmDialogData?.isOpen ?? false}
                type={confirmDialogData?.type}
                content={confirmDialogData?.content}
                title={confirmDialogData?.title}
                onConfirm={confirmDialogData?.onClick}
                closeButtonLabel={confirmDialogData?.closeButtonLabel ?? translate("btn.cancel")}
                buttonLabel={confirmDialogData?.buttonLabel}
                translate={translate}
                onClose={closeConfirmDialog}
            />

            <ModalSaveResource
                dialogWidth="max-w-5xl"
                fields={getFields(translate, handleViewContactClick, handleViewOrganizationClick, handleContactInfoClick, handleOrganizationInfoClick)}
                show={createModalOpen}
                title={translate("modal.heading.CreateNewRentalContract")}
                onClose={handleToggleCreateModal}
                addFieldContainerClass={"col-span-full"}
                addBodyClass={"pb-32 text-tm-gray-900"}
                isLoading={isLoading}
                resource={resource}
                metadata={getSelects()}
                translate={translate}
                onSubmit={(params) => {
                    if (params) {
                        dispatch(createResource({
                            user: LocalStorage.get('user'),
                            params: params,
                            query: query,
                            resource: getResourcePath(),
                            piggyResource: getResourcePath(),
                            successMessage: translate("text.rental_contract_created"),
                        }));
                        handleToggleCreateModal()
                    }
                }}
                handleInputChange={handleModalInputChange}
            />

        </React.Fragment>
    )
}

/** Page methods
 ================================================================= */
const getPrimaryField = () => "RentalContractID";
const getResourcePath = () => Resources.WorkOrdersRentalContracts;

const getListPath = () => getResourcePath() + "_list"

const fetchData = (dispatch, query) => {
    dispatch(getResource({
        user: getUser(),
        resource: getResourcePath(),
        query: query
    }))
}

const getQueryFields = (translate) => {
    return {
        query: new Field('query', '', [''], false, 'search', {
            hideLabel: true,
            hasActiveBadge: true,
            labelType: "float"
        }, {placeholder: translate("text.search")}),
        archived: new Field('archived', '', [''], false, 'checkbox', {
            labelType: "float",
            label: "archived",
            hasActiveBadge: true,
        }),
        sort: new Field('sort', DEFAULT_SORT, [''], false, 'hidden'),
        sortBy: new Field('sortBy', getPrimaryField(), [''], false, 'hidden'),
        offset: new Field('offset', DEFAULT_OFFSET, [''], false, 'hidden'),
        limit: new Field('limit', DEFAULT_LIMIT, [''], false, 'select', {
            hideLabel: true,
            labelType: "float"
        }, {
            hasPortal: true,
            placeholder: "100",
            menuPlacement: "top"
        })
    }
}

function getFields(translate, handleViewContactClick, handleViewOrganizationClick, handleContactInfoClick, handleOrganizationInfoClick, item = {}) {
    const fieldTemplates = {
        AutoCounter: new Field('AutoCounter', '', [], false, 'integer', {hideDialog: true}),
        RefNumber: new Field('RefNumber', '', ['empty'], false, 'text', {addContainerClass: "col-span-6"}),
        RentTo: new Field('RentTo', '1', [], false, 'button-group', {
            data: {1: translate('btn.contact'), 2: translate('btn.organization')},
            addContainerClass: 'col-span-full',
            render: (item) => {
                if (item.RentContactID) {
                    return (
                        <CellButton
                            onClick={() => checkPerm(Resources.Contacts, UPDATE_PERM) && handleViewContactClick(item.RentContactID)}
                        >
                            {item.RentContact}
                        </CellButton>
                    )
                } else if (item.RentOrganizationID) {
                    return (
                        <CellButton
                            onClick={() => checkPerm(Resources.Organizations, READ_PERM) && handleViewOrganizationClick(item.RentOrganizationID)}
                        >
                            {item.RentOrganization}
                        </CellButton>
                    )
                } else {
                    return ""
                }
            }
            , omitSort: true
        }),
        RentContactID: new Field(
            'RentContactID',
            '',
            ['empty'],
            false,
            'select-search',
            {
                hideTable: true,
                addContainerClass: "col-span-6",
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[{
                                icon: InformationCircleIcon,
                                onClick: () => handleContactInfoClick(it?.value?.value),
                                isVisible: !!it?.value
                            }]}
                        />
                    )
                }
            }, {
                hasPortal: true,
                menuPosition: 'fixed'
            }
        ),
        RentOrganizationID: new Field(
            'RentOrganizationID',
            '',
            [''],
            true,
            'select-search',
            {
                hideTable: true,
                addContainerClass: "col-span-6",
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[{
                                icon: InformationCircleIcon,
                                onClick: () => handleOrganizationInfoClick(it?.value?.value),
                                isVisible: !!it?.value
                            }]}
                        />
                    )
                }
            }, {
                hasPortal: true,
                menuPosition: 'fixed'
            }
        ),
        StartDate: new Field('StartDate', '', [''], false, 'date',
            {hasActiveBadge: true, addContainerClass: 'col-span-6'},
            {
                isClearable: true,
                popperProps: {
                    strategy: 'fixed',
                    positionFixed: true
                }
            }),
        EndDate: new Field('EndDate', '', [''], false, 'date',
            {hasActiveBadge: true, addContainerClass: 'col-span-6'},
            {
                isClearable: true,
                popperProps: {
                    strategy: 'fixed',
                    positionFixed: true
                }
            }),
        Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}),
    }

    if (item) {
        if (item.RentContactID) {
            fieldTemplates['RentTo'].value = 1;
        }
        if (item.RentOrganizationID) {
            fieldTemplates['RentTo'].value = 2;
        }
    }

    return fillFieldsFromData(fieldTemplates, item)
}


function getSelects() {
    return {
        RentContactID: {
            api: 'api/' + Resources.ContactsQuick,
            query: {},
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.ContactID,
                metadata: it
            })
        },
        RentOrganizationID: {
            api: 'api/' + Resources.OrganizationsQuick,
            query: {},
            searchMap: (it) => ({
                label: it.LegalName,
                value: it.OrganizationID,
                metadata: it
            })
        },
    }
}
