import React, {useEffect, useState} from 'react'
import {Field, FieldsManager} from '../../../../data/services/fields'
import {checkPerm, getDefaultTableOptions, getProp} from "../../../../common/util/util-helpers";
import {
    ACCOUNT_TYPE_OTHER_CURRENT_ASSETS,
    CREATE_PERM,
    MAJOR_ACCOUNT_TYPE_ASSET,
    MAJOR_ACCOUNT_TYPE_EXPENSE,
    READ_PERM,
} from "../../../../util/util-constants";
import Resources from "../../../../data/services/resources";
import ModalHeader from "../../../../common/components/modal/modal-header";
import ActiveFilters from "../../../../common/components/resource-table/table-components/active-filters";
import TableCard from "../../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../../common/components/resource-table";
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import NoRecordsTable from "../../../../common/components/no-records-found/no-records-table";
import ModalFooter from "../../../../common/components/modal/modal-footer";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import {fillFieldsFromData} from "../../../../common/util/util-fields";

const FuelIntegrationSettingsDialog = (props) => {
    const {translate, resource, isLoading, onClose, onSubmit} = props

    /** Helpers
     ================================================================= */
    const getFields = (item = null) => {
        const fieldTemplates = {
            CategoryCode: new Field('CategoryCode', '', ['empty'], true, 'text'),
            Category: new Field('Category', '', ['empty'], true, 'text'),
            ExpensesAccountID: new Field('ExpensesAccountID', '', !!item?.IsAdvanceOnly ? [''] : ['empty'], !!item?.IsAdvanceOnly ? true : false, 'select-search'),
            AdvancesAccountID: new Field('AdvancesAccountID', '', ['empty'], false, 'select-search'),
            IsAdvanceOnly: new Field('IsAdvanceOnly', '', [], false, 'checkbox'),
            SourceID: new Field('SourceID', '', [], false, 'select', {}, {
                values: {
                    1003: 'EFS',
                    1008: 'Relay',
                    1009: 'QuickQ'
                }
            })
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    const getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            ExpensesAccountID: new Field('ExpensesAccountID', '', [], false, 'select-search'),
            AdvancesAccountID: new Field('AdvancesAccountID', '', [], false, 'select-search'),
            SourceID: new Field('SourceID', '', [], false, 'select', {}, {
                values: {
                    1003: 'EFS',
                    1008: 'Relay',
                    1009: 'QuickQ'
                }
            }),
        }
    }

    const getQuery = () => {
        return {
            limit: 10,
            offset: 0,
            paginationPage: 1
        }
    }

    const tableOptions = getDefaultTableOptions(getFields(), {}, 'fuel-integration-settings-dialog', translate)

    /** State
     ================================================================= */
    const [items, setItems] = useState(getProp(resource, "data.list", []))
    const [selectedItem, setSelectedItem] = useState(null)
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)
    const [queryFilterFields, setQueryFilterFields] = useState(getQueryFilterFields())
    const [queryFilter, setQuery] = useState(getQuery())

    /** Lifecycles
     ================================================================= */
    useEffect(() => {
        setEditModalOpen(!!selectedItem)
    }, [selectedItem])

    useEffect(() => {
        setItems(getProp(resource, "data.list", []))
    }, [resource])

    /** UI Events
     ================================================================= */
    const handleFilterInputChange = (name, value) => {
        setQueryFilterFields(FieldsManager.updateField(queryFilterFields, name, value))
        setQuery(getQuery())
    }

    const handleClearFilter = () => {
        setQueryFilterFields(getQueryFilterFields())
    }

    const handleUpdateOffset = (offset, num) => {
        setQuery(Object.assign({}, queryFilter, {
            offset: offset,
            paginationPage: num
        }))
    }

    const handleSubmit = () => {
        onSubmit(items)
    }

    const handleToggleEditModal = (item = null) => {
        setSelectedItem(item)
    }

    const handleInputChange = (fields, name, value) => {
        const fieldsUpdate = FieldsManager.updateField(fields, name, value);


        if (name === 'IsAdvanceOnly') {
            if (Number(value) === 1) {
                fieldsUpdate.ExpensesAccountID.value = null
            }

            fieldsUpdate.ExpensesAccountID.disabled = Number(value) === 1 ? true : false;
            fieldsUpdate.ExpensesAccountID.validate = Number(value) === 1 ? [''] : ['empty'];
        }
        return fieldsUpdate
    }

    /** Render
     ================================================================= */
    const accountsAPIFields = (type, subtype) => ({
        api: 'api/accounting/accounts',
        query: {
            MainAccountTypeID: type,
            AccountTypeID: subtype
        },
        searchMap: (item) => ({
            value: item.AccountID,
            label: item.AccountNumber + ' ' + item.AccountName
        })
    })

    let count = 0

    const data = items
        .filter((it) => {
            const {AdvancesAccountID, ExpensesAccountID, query, SourceID} = FieldsManager.getFieldKeyValues(queryFilterFields)

            if (!!ExpensesAccountID && (ExpensesAccountID != it.ExpensesAccountID)) {
                return false;
            }

            if (!!AdvancesAccountID && (AdvancesAccountID != it.AdvancesAccountID)) {
                return false;
            }

            if (!!SourceID && (SourceID != it.SourceID)) {
                return false;
            }

            if (!!query) {
                if (it.Category && !it.Category.toLowerCase().includes(query.toLowerCase()) && it.CategoryCode && !it.CategoryCode.toLowerCase().includes(query.toLowerCase())) {
                    return false;
                }
            }

            count++

            return true;
        })

    const metadata = {
        ExpensesAccountID: accountsAPIFields(MAJOR_ACCOUNT_TYPE_EXPENSE),
        AdvancesAccountID: accountsAPIFields(MAJOR_ACCOUNT_TYPE_ASSET, ACCOUNT_TYPE_OTHER_CURRENT_ASSETS)
    }

    return (
        <div className={'relative'}>
            <ModalHeader
                title={translate('text.FuelIntegrationSettings')}
                onClose={onClose}
            />
            <div className="p-5">
                <ActiveFilters
                    filterFields={queryFilterFields}
                    onLabelClick={handleFilterInputChange}
                    onClearFiltersClick={handleClearFilter}
                    translate={translate}
                />
            </div>

            <TableCard>
                <TableFilters
                    filterFields={queryFilterFields}
                    handleInputChange={handleFilterInputChange}
                    translate={translate}
                    selects={metadata}
                    hideLimit
                />

                <ResourceTable
                    data={new Array(...data).slice(queryFilter.offset, (queryFilter.limit + queryFilter.offset))}
                    fields={getFields()}

                    translate={translate}
                    isLoading={isLoading}

                    options={tableOptions}

                    onEdit={handleToggleEditModal}

                    hasEditPerm={checkPerm(Resources.Accounts, READ_PERM)}

                />

                {/*Table footer*/}
                {!(!data.length && !isLoading) && (
                    <div
                        className="bg-inverse px-4 py-3 flex items-center justify-between border-t border-tm-gray-300 sm:px-6 h-16 rounded-b-lg"
                    >
                        <Pagination
                            count={count}
                            hideRowsPerPage={true}
                            pageLimit={queryFilter.limit}
                            pageOffset={queryFilter.offset}
                            queryFields={queryFilterFields}
                            handleQueryChange={
                                (name, value, currentPage) => name === "offset"
                                    ? handleUpdateOffset(value, currentPage)
                                    : handleFilterInputChange(name, value)
                            }

                            translate={translate}
                        />
                    </div>
                )}

                <NoRecordsTable
                    show={(data.length === 0) && !isLoading}
                    title={'No matching records found'}
                    clearFilterBtnLabel={'No matching records found'}
                    onClearFilterClick={handleClearFilter}
                    filters={queryFilterFields}
                />
            </TableCard>

            <ModalFooter
                closeButtonLabel={translate('btn.close')}
                onClose={onClose}

                buttonLabel={checkPerm(Resources.FuelIntegrationSettings, CREATE_PERM) && translate('btn.save')}
                buttonDisabled={!canSubmit}
                onButtonClick={handleSubmit}
            />

            <ModalSaveResource
                show={editModalOpen}
                title={translate('text.EditFuelIntegrationSettings')}
                widthClass="max-w-3xl"
                gridColsClass="grid-cols-2"
                onClose={() => handleToggleEditModal()}
                handleInputChange={handleInputChange}
                fields={getFields(selectedItem)}
                getRawFields
                onSubmit={(params) => {
                    if (params) {
                        const index = items.findIndex(elem => elem.FuelImportSettingsID === selectedItem.FuelImportSettingsID)
                        const itemsTmp = [...items]
                        itemsTmp[index] = Object.assign({}, itemsTmp[index], {
                            ExpensesAccountID: params.ExpensesAccountID.value ? params.ExpensesAccountID.value.value : null,
                            ExpensesAccount: params.ExpensesAccountID.value ? params.ExpensesAccountID.value.label : null,
                            AdvancesAccountID: params.AdvancesAccountID.value.value,
                            AdvancesAccount: params.AdvancesAccountID.value.label,
                            IsAdvanceOnly: params.IsAdvanceOnly.value,
                        })
                        setItems(itemsTmp)
                        setCanSubmit(true)
                        handleToggleEditModal()
                    }
                }}
                translate={translate}
                metadata={metadata}
            />
        </div>
    )
}

export default FuelIntegrationSettingsDialog
