import React, {useEffect, useState} from "react";
import {getDefaultQueryFields, getUser, groupListBySCAC} from "../../../../util/util";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {createResource, deleteResource, getResource, updateResource} from "../../../../data/actions/resource";
import Resources from "../../../../data/services/resources";
import useQuery from "../../../../hooks/use-query";
import LocalStorage from "../../../../util/localStorage";

import {
    createSecondResource,
    deleteSecondResource,
    getSecondResource,
    updateSecondResource
} from "../../../../data/actions/secondResource";
import {toBackDateTime} from "../../../../common/util/util-dates";
import {classNames} from "../../../../common/util/util-helpers";
import {fillFieldsFromData} from "../../../../common/util/util-fields";
import ResourceList from "../../../../common/components/layout/layout-components/resource-list";
import Buttons from "../../../../common/components/buttons";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";


export default function WorkOrdersServices({
                                               ID,
                                               resource,
                                               dispatch,
                                               translate,
                                               isLoading,
                                               TruckID,
                                               TrailerID,
                                               secondResource,
                                               secondIsLoading,
                                               Trailer,
                                               Truck,
                                               Vendor,
                                               VendorID
                                           }) {

    /** Constants
     ================================================================= */
    const [isResourceDialogOpenTruck, setIsResourceDialogOpenTruck] = useState(false);
    const [isResourceDialogOpenTrailer, setIsResourceDialogOpenTrailer] = useState(false);
    const [confirmDialogData, setConfirmDialogData] = useState({});
    const [selectedItem, setSelectedItem] = useState(null);

    const getListPath = () => Resources.WorkOrdersServicesTruck + "_list"

    const getListPathTrailer = () => Resources.WorkOrdersServicesTrailer + "_list"
    const getPrimaryField = () => "TruckServiceID";
    const getQueryFields = (translate) => {
        const queryFields = getDefaultQueryFields(getPrimaryField(), translate);
        queryFields.query.metadata.addContainerClass = "col-span-3";

        return queryFields;
    }

    const getPrimaryFieldTrailer = () => "TrailerServiceID";
    const getQueryFieldsTrailer = (translate) => {
        const queryFields = getDefaultQueryFields(getPrimaryFieldTrailer(), translate);
        queryFields.query.metadata.addContainerClass = "col-span-3";

        return queryFields;
    }

    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), getListPath())
    const query = FieldsManager.getFieldKeyValues(queryFields);

    const [queryFieldsTrailer, setQueryFieldsTrailer] = useQuery(getQueryFieldsTrailer(translate), getListPathTrailer())
    const queryTrailer = FieldsManager.getFieldKeyValues(queryFieldsTrailer);

    const fetchData = () => {
        dispatch(getResource({
            user: getUser(),
            resource: Resources.WorkOrdersServicesTruck,
            query: Object.assign(query, {searchFields: JSON.stringify({WorkOrderID: ID})})
        }))
    }

    const fetchDataTrailer = () => {
        dispatch(getSecondResource({
            user: getUser(),
            resource: Resources.WorkOrdersServicesTrailer,
            query: Object.assign(queryTrailer, {searchFields: JSON.stringify({WorkOrderID: ID})})
        }))
    }

    const getFieldsTruck = (item = null) => {
        const fieldTemplates = {
            OutOfServiceDate: new Field("OutOfServiceDate", '', ['empty'], false, 'date', {addContainerClass: "col-span-full"}),
            ReturnsToServiceDate: new Field("ReturnsToServiceDate", '', [], false, 'date', {addContainerClass: "col-span-full"}),
            VendorID: new Field("VendorID", '', [], false, 'select-search', {
                addContainerClass: "col-span-full",
                hideDialog: true,
                hideTable: !VendorID
            }),
            ServiceNotes: new Field("ServiceNotes", '', ['empty'], false, 'textarea', {addContainerClass: "col-span-full"}),
            TruckServiceID: new Field("TruckServiceID", '', [''], false, 'hidden', {hideTable: true}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    const getFieldsTrailer = (item = null) => {
        const fieldTemplates = {
            OutOfServiceDate: new Field("OutOfServiceDate", '', ['empty'], false, 'date', {addContainerClass: "col-span-full"}),
            ReturnsToServiceDate: new Field("ReturnsToServiceDate", '', [], false, 'date', {addContainerClass: "col-span-full"}),
            VendorID: new Field("VendorID", '', [], false, 'select-search', {
                addContainerClass: "col-span-full",
                hideDialog: true,
                hideTable: !VendorID
            }),
            ServiceNotes: new Field("ServiceNotes", '', ['empty'], false, 'textarea', {addContainerClass: "col-span-full"}),
            TrailerServiceID: new Field("TrailerServiceID", '', [''], false, 'hidden', {hideTable: true}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    const handleCreateResourceClick = () => {
        setIsResourceDialogOpenTruck(true);
    }

    const handleCreateResourceClickTrailer = () => {
        setIsResourceDialogOpenTrailer(true);
    }

    const closeConfirmDialog = () => {
        setConfirmDialogData(Object.assign({}, confirmDialogData, {isOpen: false}))
    }

    const handleEditTruckService = (item) => {
        setIsResourceDialogOpenTruck(true);
        setSelectedItem(item);
    }

    const handleEditTrailerService = (item) => {
        setIsResourceDialogOpenTrailer(true);
        setSelectedItem(item);
    }


    const handleDelete = (item) => {
        if (item.TruckID) {
            setConfirmDialogData({
                isOpen: true,
                onClick: () => handleConfirmDeleteClick(item),
                type: "danger",
                content: <span>
                Are you sure you want to delete this item?
            </span>,
                title: translate("text.confirm_delete_service"),
                buttonLabel: translate("btn.delete")
            })
        } else {
            setConfirmDialogData({
                isOpen: true,
                onClick: () => handleConfirmDeleteClickTrailer(item),
                type: "danger",
                content: <span>
                Are you sure you want to delete this item?
            </span>,
                title: translate("text.confirm_delete_service"),
                buttonLabel: translate("btn.delete")
            })
        }

    }

    const handleConfirmDeleteClick = (item) => {
        dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: Object.assign(query, {
                TruckServiceID: item.TruckServiceID,
                searchFields: JSON.stringify({WorkOrderID: ID})
            }),
            resource: Resources.WorkOrdersServicesTruck,
            piggyResource: Resources.WorkOrdersServicesTruck,
            successMessage: translate('message.service_delete'),
            errorMessage: true,

        }));

        closeConfirmDialog();
    }

    const handleConfirmDeleteClickTrailer = (item) => {
        dispatch(deleteSecondResource({
            user: LocalStorage.get('user'),
            query: Object.assign(queryTrailer, {
                TrailerServiceID: item.TrailerServiceID,
                searchFields: JSON.stringify({WorkOrderID: ID})
            }),
            resource: Resources.WorkOrdersServicesTrailer,
            secondPiggyResource: Resources.WorkOrdersServicesTrailer,
            successMessage: translate('message.service_delete'),
            errorMessage: true,

        }));

        closeConfirmDialog();
    }


    useEffect(() => {
        fetchData();
    }, [queryFields])

    useEffect(() => {
        fetchDataTrailer();
    }, [queryFieldsTrailer])


    function getSelects() {
        return {
            VendorID: {
                api: "api/" + Resources.VendorsQuick,
                query: {},
                searchMap: (item) => ({
                    label: item.LegalName + ", " + item.AddressName,
                    value: item.VendorID
                })
            },
            TruckID: {
                api: 'api/' + Resources.Trucks,
                query: {
                    NotSoldRetired: 1
                },
                customizeList: (list) => {
                    return groupListBySCAC(list, 'Truck')
                }
            },
        }
    }

    function getSelectsTrailer() {
        return {
            VendorID: {
                api: "api/" + Resources.VendorsQuick,
                query: {},
                searchMap: (item) => ({
                    label: item.LegalName + ", " + item.AddressName,
                    value: item.VendorID
                })
            },
            TrailerID: {
                api: 'api/' + Resources.Trailers,
                query: {
                    NotSoldRetired: 1
                },
                customizeList: (list) => {
                    return groupListBySCAC(list, 'Trailer')
                }
            },
        }
    }

    /** Component Body
     ================================================================= */
    let gridClass = "xl:grid xl:grid-cols-1 xl:gap-4 xl:space-y-0";

    if (!!TruckID && !!TrailerID) {
        gridClass = "xl:grid xl:grid-cols-2 xl:gap-4 xl:space-y-0";
    }

    return (
        <>
            <div className={classNames("space-y-4 mt-2 relative z-0", gridClass)}>
                <div className="flex flex-col gap-2">
                    {!!TruckID && (
                        <ResourceList
                            fields={getFieldsTruck()}
                            tableTitle={translate("text.truck_out_of_service") + " - " + Truck}
                            afterFiltersHtml={
                                <div className="ml-4 flex-shrink-0">
                                    <Buttons
                                        buttons={
                                            [{
                                                label: translate("btn.create"),
                                                type: "primary",
                                                onClick: handleCreateResourceClick
                                            }]
                                        }
                                    />
                                </div>
                            }
                            resource={resource}
                            // fetchData={fetchData}
                            onRefreshTable={fetchData}
                            isLoading={isLoading}
                            queryFields={queryFields}
                            setQueryFields={setQueryFields}
                            listPath={getListPath()}
                            dispatch={dispatch}
                            query={query}

                            translate={translate}

                            selects={getSelects()}
                            onDelete={handleDelete}
                            onEdit={handleEditTruckService}
                        />
                    )}
                </div>

                <div className="flex flex-col gap-2">
                    {!!TrailerID && (
                        <ResourceList
                            fields={getFieldsTrailer()}
                            tableTitle={translate("text.trailer_out_of_service") + " - " + Trailer}
                            afterFiltersHtml={
                                <div className="ml-4 flex-shrink-0">
                                    <Buttons
                                        buttons={
                                            [{
                                                label: translate("btn.create"),
                                                type: "primary",
                                                onClick: handleCreateResourceClickTrailer
                                            }]
                                        }
                                    />
                                </div>
                            }
                            resource={secondResource}
                            // fetchData={fetchDataTrailer}
                            onRefreshTable={fetchDataTrailer}
                            isLoading={secondIsLoading}
                            queryFields={queryFieldsTrailer}
                            setQueryFields={setQueryFieldsTrailer}
                            listPath={getListPathTrailer()}
                            dispatch={dispatch}
                            query={queryTrailer}

                            translate={translate}

                            selects={getSelectsTrailer()}
                            onDelete={handleDelete}
                            onEdit={handleEditTrailerService}
                        />
                    )}
                </div>
            </div>

            <ModalConfirm
                show={confirmDialogData?.isOpen ?? false}
                type={confirmDialogData?.type}
                content={confirmDialogData?.content}
                title={confirmDialogData?.title}
                onConfirm={confirmDialogData?.onClick}
                closeButtonLabel={confirmDialogData?.closeButtonLabel ?? translate("btn.cancel")}
                buttonLabel={confirmDialogData?.buttonLabel}
                translate={translate}
                onClose={closeConfirmDialog}
            />

            <ModalSaveResource
                dialogWidth="max-w-xl"
                fields={getFieldsTruck(selectedItem)}
                htmlAfter={<div id={'error'}></div>}
                initialFocusID="OutOfServiceDate"
                show={isResourceDialogOpenTruck}
                title={selectedItem ? translate("modal.heading.edit_service") : translate("modal.heading.create_service")}
                onClose={() => {
                    setIsResourceDialogOpenTruck(false)
                    setSelectedItem(null)
                }}

                addFieldContainerClass={"col-span-12"}
                addBodyClass={"pb-32 text-tm-gray-900"}
                isLoading={isLoading}
                resource={resource}
                metadata={getSelects()}
                translate={translate}
                onSubmit={(params) => {
                    if (!!params) {
                        params.TruckID = TruckID;
                        params.OutOfServiceDate = toBackDateTime(params.OutOfServiceDate);
                        params.ReturnsToServiceDate = toBackDateTime(params.ReturnsToServiceDate);
                        params.VendorID = VendorID;
                        params.Vendor = Vendor;

                        if (selectedItem) {
                            dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                params: Object.assign({}, params, {
                                    WorkOrderID: ID,
                                }),
                                query: Object.assign(query, {searchFields: JSON.stringify({WorkOrderID: ID})}),
                                successMessage: translate("message.service_truck_updated"),
                                resource: Resources.WorkOrdersServicesTruck,
                                piggyResource: Resources.WorkOrdersServicesTruck
                            }));
                        } else {
                            dispatch(createResource({
                                user: LocalStorage.get('user'),
                                params: Object.assign({}, params, {
                                    WorkOrderID: ID,
                                }),
                                resource: Resources.WorkOrdersServicesTruck,
                                query: Object.assign(query, {searchFields: JSON.stringify({WorkOrderID: ID})}),
                                successMessage: translate("message.service_truck_created"),
                                piggyResource: Resources.WorkOrdersServicesTruck
                            }))
                        }
                        setIsResourceDialogOpenTruck(false)
                        setSelectedItem(null)
                    }
                }
                }
            />


            <ModalSaveResource
                dialogWidth="max-w-xl"
                fields={getFieldsTrailer(selectedItem)}
                htmlAfter={<div id={'error'}></div>}
                initialFocusID="OutOfServiceDate"
                show={isResourceDialogOpenTrailer}
                title={selectedItem ? translate("modal.heading.edit_service") : translate("modal.heading.create_service")}
                onClose={() => {
                    setIsResourceDialogOpenTrailer(false)
                    setSelectedItem(null)
                }}

                addFieldContainerClass={"col-span-12"}
                addBodyClass={"pb-32 text-tm-gray-900"}
                isLoading={secondIsLoading}
                resource={secondResource}
                metadata={getSelectsTrailer()}
                translate={translate}
                onSubmit={(params) => {
                    if (!!params) {
                        params.TrailerID = TrailerID;
                        params.OutOfServiceDate = toBackDateTime(params.OutOfServiceDate);
                        params.ReturnsToServiceDate = toBackDateTime(params.ReturnsToServiceDate);
                        params.VendorID = VendorID;
                        params.Vendor = Vendor;
                        if (selectedItem) {
                            dispatch(updateSecondResource({
                                user: LocalStorage.get('user'),
                                params: Object.assign({}, params, {
                                    WorkOrderID: ID,
                                }),
                                query: Object.assign(queryTrailer, {searchFields: JSON.stringify({WorkOrderID: ID})}),
                                successMessage: translate("message.service_trailer_updated"),
                                resource: Resources.WorkOrdersServicesTrailer,
                                piggyResource: Resources.WorkOrdersServicesTrailer
                            }));
                        } else {
                            dispatch(createSecondResource({
                                user: LocalStorage.get('user'),
                                params: Object.assign({}, params, {
                                    WorkOrderID: ID,
                                }),
                                resource: Resources.WorkOrdersServicesTrailer,
                                query: Object.assign(queryTrailer, {searchFields: JSON.stringify({WorkOrderID: ID})}),
                                successMessage: translate("message.service_trailer_created"),
                                piggyResource: Resources.WorkOrdersServicesTrailer
                            }))
                        }
                        setIsResourceDialogOpenTrailer(false)
                        setSelectedItem(null)
                    }
                }
                }
            />

        </>
    )
}
