import Layout from "../../../common/components/layout";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "../../../common/util/util-auth";
import {Field, FieldsManager} from "../../../data/services/fields";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";
import ResourcePage from "../../../common/components/layout/layout-components/resource-page";
import Resources from "../../../data/services/resources";
import CreateUpdateContactGroupsDialog from "../../../common/components/modal/createContactsDialog";
import {longTableColumn} from "../../../common/util/util-helpers";
import useQueryNew from "../../../hooks/use-query-new";
import {deleteResource, getResource} from "../../../data/actions/resource";
import {showGlobalModal} from "../../../data/actions/ui";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import {useLocation} from "react-router-dom";
import LocalStorage from "../../../common/util/localStorage";

export default function GroupsView({translate, match, history}) {
    /** Page constants
     ================================================================= */
    const pageTitle = translate('page.heading.group/team/department');
    const tableKey = 'ContactGroupID';
    const noRecordsText = "Create a new group";
    const noRecordsButtonLabel = "Create group"
    const resourcePath = Resources.ContactGroups;
    const tableDefaults = {
        behaviour: {
            rowSelect: true,
            canAdjustWidth: true,
            hasMenu: true
        },
        style: {
            condensed: true,
            isGPUAccelerated: true
        },
        version: "1" // changing version will force update tableDefaults settings
    }


    const location = useLocation();
    const pagePath = location.pathname.replaceAll("/", "");


    const user = getUser();
    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);
    const resource = useSelector(state => state.resource);

    const [breakpoint, setBreakpoint] = useState({});
    const [isGroupFormModalOpen, setIsGroupFormModalOpen] = useState(false);
    const [queryFields, setQueryFields] = useQueryNew(getQueryFields(), pagePath);
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
    const query = FieldsManager.getFieldKeyValues(queryFields);

    const [selectedItem, setSelectedItem] = useState({});
    const [prefilled, setPrefilled] = useState("");

    /** Data Events
     ================================================================= */
    function fetchData() {
        dispatch(getResource({
            user: getUser(),
            resource: resourcePath,
            query: query
        }))
    }

    /** UI Events
     ================================================================= */
    function handleBreakpointChange(breakpointUpdate) {
        setBreakpoint(Object.assign({}, breakpointUpdate));

        if (breakpointUpdate.index <= 1 && queryFields.limit.value !== 10) {
            handleQueryInputChange('limit', 10);
        }
    }

    function handleCreateResourceClick() {
        setSelectedItem({});
        setIsGroupFormModalOpen(true);
    }

    function handleQueryInputChange(name, value) {
        const queryFieldsUpdate = FieldsManager.updateField(queryFields, name, value);

        if ("sortBy" === name) {
            if (value === queryFieldsUpdate?.sortBy?.value) {
                queryFieldsUpdate.sort.value = queryFieldsUpdate.sort.value === 'ASC' ? 'DESC' : 'ASC'
            }
        }

        setQueryFields(queryFieldsUpdate);
    }


    function handleClearAllFiltersClick() {
        setQueryFields(getQueryFields());
    }

    function handleOpenGroupInfoDialog(item) {
        dispatch(showGlobalModal('ViewContactGroupCard', item));
    }

    function handleEditGroupModalClick(item) {
        setSelectedItem(item);
        setIsGroupFormModalOpen(true)
    }

    function handleDeleteResourceClick(item) {
        setSelectedItem(item);
        setIsConfirmDeleteModalOpen(true);
    }

    function handleConfirmDeleteClick() {
        dispatch(deleteResource({
            user: getUser(),
            query: Object.assign({
                ContactGroupID: selectedItem.ContactGroupID
            }, query),
            errorMessage: true,
            successMessage: `${selectedItem.ContactGroupName} deleted`,
            resource: resourcePath,
            piggyResource: resourcePath
        }));
        setSelectedItem({});
        setIsConfirmDeleteModalOpen(false);
    }

    /** Helpers
     ================================================================= */
    function getQueryFields() {
        return {
            query: new Field('query', '', [''], false, 'search'),
            sort: new Field('sort', 'DESC', ['']),
            sortBy: new Field('sortBy', 'ContactGroupName', ['']),
            offset: new Field('offset', '', ['']),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    function getFields() {
        return {
            ContactGroupName: new Field('ContactGroupName', '', ['empty'], false, 'custom', {
                render: (item) => {
                    return (
                        <span>{item?.ContactGroupName} ({item?.MemberCount})</span>
                    )
                }
            }),
            ContactGroupDesc: new Field('ContactGroupDesc', '', ['empty'], false, 'custom', {
                render: (item) => (
                    <>{longTableColumn(item.ContactGroupDesc)}</>
                )
            }),
            // ContactGroupManager: new Field('ContactGroupManager', '', [], false, 'text', {}),
            ContactID: new Field('ContactID', '', [], false, 'select-search', {label: 'ContactGroupManager'}),
            ContactGroupEmail: new Field('ContactGroupEmail', '', [], false, 'text', {}),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'date', {hideDialog: true}),
            ColorTag: new Field('ColorTag', '', [], false, 'hidden', {hideTable: true})
        }
    }

    useEffect(() => {
        fetchData();
    }, [queryFields]);

    useEffect(() => {
        // Todo: remove in the next version
        function consumeLegacyState() {
            const oldState = LocalStorage.get(pagePath + "_state");
            if (oldState) {
                const queryFieldsUpdate = Object.assign({}, queryFields);
                queryFieldsUpdate.sort.value = oldState.sort;
                queryFieldsUpdate.sortBy.value = oldState.sortBy;
                queryFieldsUpdate.limit.value = oldState.limit;
                queryFieldsUpdate.offset.value = oldState.offset;
                queryFieldsUpdate.query.value = oldState.queryFilterFields.value ?? "";

                LocalStorage.remove(pagePath + "_state");
                setQueryFields(queryFieldsUpdate);
            }
        }

        consumeLegacyState();
    }, [pagePath]);

    /** Render
     ================================================================= */
    return (
        <Layout
            ui={ui}
            user={user}
            match={match}
            history={history}
            location={location}
            translate={translate}
            dispatch={dispatch}
            isAccessible={!(resource.errorStatus === 2)}
            onBreakpointChange={handleBreakpointChange}
        >
            <ResourcePage
                tableKey={tableKey}
                pagePath={pagePath}
                resourcePath={resourcePath}
                pageTitle={pageTitle}
                tableFields={getFields()}
                onCreateResourceClick={handleCreateResourceClick}
                onNoRecordsCreateClick={() => {
                    setPrefilled(queryFields.query.value);
                    setIsGroupFormModalOpen(true);
                }}
                queryFields={queryFields}
                onQueryInputChange={handleQueryInputChange}
                onClearAllFiltersClick={handleClearAllFiltersClick}
                fetchData={fetchData}
                translate={translate}
                breakpoint={breakpoint}
                onView={handleOpenGroupInfoDialog}
                onEdit={handleEditGroupModalClick}
                onDelete={handleDeleteResourceClick}
                onRowClick={handleEditGroupModalClick}
                tableDefaults={tableDefaults}
                noRecordsText={noRecordsText}
                noRecordsButtonLabel={noRecordsButtonLabel}
            />

            <CreateUpdateContactGroupsDialog
                isOpen={isGroupFormModalOpen}
                prefilled={prefilled}
                selectedItem={selectedItem}
                translate={translate}
                query={query}
                close={() => setIsGroupFormModalOpen(false)}
            />

            <ModalConfirm
                title={'Confirm'}
                show={isConfirmDeleteModalOpen}
                text={`${translate('message.are_you_sure_delete_group')} ${selectedItem?.ContactGroupName}?`}
                onClose={() => {
                    setIsConfirmDeleteModalOpen(false);
                    setSelectedItem({});
                }}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={handleConfirmDeleteClick}
            />
        </Layout>
    )
}