import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Resources from "../../../data/services/resources";
import {
    FLEET_SETTINGS_INCLUDED_LOOKUPS,
    READ_PERM,
    REQUIRED_DOCUMENTS_TYPES,
    UPDATE_PERM
} from "../../../util/util-constants";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import NavResponsive from "../../../common/components/nav-responsive";
import DocumentEntitiesTab from "../../../common/components/tabs/document-entities-tab";
import DocumentTypesTab from "../../../common/components/tabs/document-types-tab";
import Lookups from "../../../common/components/tabs/lookupTab";
import Layout from "../../../common/components/layout";
import FuelTaxSettingsTab from "./fuel-tax-settings-tab";
import {checkPerm} from "../../../common/util/util-helpers";
import FleetSettingsGeneralTab from "./fleet-settings-tab";


export default function FleetSettingsView({translate, match, history, location}) {
    const dispatch = useDispatch();
    const ui = useSelector((state) => state.ui);
    const resource = useSelector((state) => state.resource);

    const [tabs, setTabs] = useState(getTabs());
    const [breakpoint, setBreakpoint] = useState({})
    const handleTabChange = (resource) => {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource;
                return it
            })
        )
    }

    const handleBreakpointChange = (breakpoint) => {
        setBreakpoint(breakpoint)
    }

    const currentTab = tabs.find(tab => tab.current);

    return (
        <Layout
            ui={ui}
            match={match}
            history={history}
            location={location}
            translate={translate}
            isAccessible={checkPerm(Resources.MileageTaxSettings, UPDATE_PERM) || checkPerm(Resources.TruckDocuments, UPDATE_PERM) || checkPerm(Resources.TrailerDocuments, UPDATE_PERM)}
            dispatch={dispatch}
            hasFooter={currentTab.name === 'RequiredDocuments' || currentTab.name === 'general'}
            onBreakpoinChange={handleBreakpointChange}
        >
            <Page>
                <PageHeader
                    title={translate('page.heading.fleet_settings')}
                    afterTitle={(
                        <PageHeaderInfo
                            dispatch={dispatch}
                        />
                    )}
                />

                <NavResponsive
                    tabs={tabs}
                    onTabChange={handleTabChange}
                    translate={translate}
                />

                {currentTab.name === 'general' && (
                    <FleetSettingsGeneralTab
                        translate={translate}
                        resourcePath={currentTab.resource}
                    />
                )}

                {currentTab.name === 'fuel_and_taxes' && (
                    <div className="grid gap-4 py-8 max-w-3xl mx-auto">
                        <FuelTaxSettingsTab
                            translate={translate}
                        />
                    </div>
                )}

                {currentTab.name === 'customFields' && (
                    <Lookups
                        customLookupFilter={(item, name) => {
                            if (name === "DocumentType") {
                                return !!item.IsFleetDocument
                            }
                            return true
                        }}
                        includeLookups={FLEET_SETTINGS_INCLUDED_LOOKUPS}
                        translate={translate}
                        resource={resource}
                        dispatch={dispatch}
                    />
                )}

                {currentTab.name === "DocumentTypes" && (
                    <DocumentTypesTab
                        translate={translate}
                        primaryType={'IsFleetDocument'}
                        breakpoint={breakpoint}
                    />
                )}

                {currentTab.name === "RequiredDocuments" && (
                    <DocumentEntitiesTab
                        requiredTypes={REQUIRED_DOCUMENTS_TYPES.Fleet}
                        translate={translate}
                        resource={resource}
                        dispatch={dispatch}
                        sortOrder={['Truck', 'Trailer']}
                    />
                )}
            </Page>
        </Layout>
    )
}

const getTabs = () => {
    let perm = "";
    if (checkPerm(Resources.DispatchSettings, READ_PERM)) {
        perm = "general";
    } else if (checkPerm(Resources.DocumentTypes, READ_PERM)) {
        perm = "DocumentTypes";
    } else if (checkPerm(Resources.DocumentEntities, READ_PERM)) {
        perm = "RequiredDocuments";
    }

    return [
        {
            name: 'general',
            resource: Resources.DispatchSettings,
            current: perm === 'general',
            visible: checkPerm(Resources.DispatchSettings, READ_PERM)
        },
        {
            name: 'fuel_and_taxes',
            resource: Resources.MileageTaxSettings,
            current: false,
            visible: checkPerm(Resources.MileageTaxSettings, READ_PERM)
        },
        {
            name: 'customFields',
            resource: Resources.Lookup,
            current: false,
            visible: checkPerm(Resources.Lookup, READ_PERM)
        },
        {
            name: 'DocumentTypes',
            resource: Resources.DocumentTypes,
            current: perm === 'DocumentTypes',
            visible: checkPerm(Resources.DocumentTypes, READ_PERM)
        },
        {
            name: 'RequiredDocuments',
            resource: Resources.DocumentEntities,
            current: perm === 'RequiredDocuments',
            visible: checkPerm(Resources.DocumentEntities, READ_PERM)
        }
    ]
}
