import React, {useEffect, useRef, useState} from "react";
import {Loader} from "../../common/components/loader";
import {classNames} from "../../common/util/util-helpers";


export default function AuthLayout({children, isMobilePage = false}) {
    const hasVideo = process.env.REACT_APP_HAS_LOGIN_VIDEO !== "false" && !isMobilePage;
    const videoURL = process.env.REACT_APP_VIDEO_URL ?? "https://cdn.accur8.services/video.mp4"

    const timeoutRef = useRef();

    const [isBuffering, setIsBuffering] = useState(true);
    const [hideLoader, setHideLoader] = useState(true);
    const [streamingError, setStreamingError] = useState(false);

    useEffect(() => {
        // Don't display loader if buffering is shorter than one second
        timeoutRef.current = setTimeout(() => setHideLoader(false), 1000)

        return () => {
            clearTimeout(timeoutRef.current);
        }
    }, []);

    return (<div
            className={
                classNames("flex min-h-screen flex-1 bg-tm-gray-100 text-tm-gray-900",
                    hasVideo ? undefined : "justify-center"
                )
            }
        >
            {hasVideo && (
                <div className="relative hidden w-0 max-w-1/2 flex-1 lg:block">

                    <div
                        className="absolute inset-0 h-full w-full object-cover bg-[#1e2c47]"
                    >
                        {isBuffering && !streamingError && !hideLoader && (
                            <Loader/>
                        )}
                    </div>

                    {streamingError && (
                        <img
                            className="absolute inset-0 h-full w-full object-cover"
                            src="/src/assets/images/login/truck.jpg"
                            alt=""
                        />
                    )}

                    {hasVideo && !streamingError && (
                        <video loop autoPlay muted preload="auto"
                               onPlay={() => setIsBuffering(false)}
                               onError={() => setStreamingError(true)}
                               className="absolute inset-0 h-full w-full object-cover z-10"
                        >
                            {/* Video should be placed in a .env variable */}
                            <source src={videoURL} type="video/mp4"/>
                        </video>
                    )}

                </div>
            )}

            <div
                className={
                    classNames(
                        "flex flex-col flex-1 justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24",
                        hasVideo ? "w-1/2" : "w-full"
                    )
                }
            >
                {children}
            </div>
        </div>
    )
}