import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../common/util/localStorage'
import Resources from '../../../data/services/resources'
import {hideConfirmDialog, showConfirmDialog,} from '../../../data/actions/ui'
import LoginsTable from './logins-table'
import {Field, FieldsManager} from '../../../data/services/fields'
import {DEFAULT_QUERY_LIMIT, READ_PERM, RESOURCE_TABLE_SIMPLE_DEFAULT_OPTIONS} from '../../../common/util/util-consts'
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import Layout from "../../../common/components/layout";
import {checkPerm, getProp} from "../../../common/util/util-helpers";



class UsersReportView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            offset: 0,
            limit: 10,
            sort: 'DESC',
            sortBy: 'last_login',
            query: '',
            input: '',
            showArchived: '',
            paginationPage: 1,
            tableColumns: {},
            tableEmpty: false,
            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),
            isLogModalOpen: false
        }

        this.pagePath = this.props.location.pathname.substring(1)
        this.defaultColumns = ['username', 'last_login']
    }

    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevState.sort !== this.state.sort) || (prevState.limit !== this.state.limit) || (prevState.sortBy !== this.state.sortBy && this.state.sortBy !== '')) {
            this.fetchData()
        }
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.UsersReport,
            query: this.getQuery()
        }))
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterChange = (name, val) => {
        this.setState({
            [name]: val,
            offset: 0,
            paginationPage: 1,
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    showConfirmDialog = (submit, item) => {
        this.setState({
            officeConfirmText: item.ContactGroupName,
            confirmModal: submit
        })
        this.props.dispatch(showConfirmDialog())
    }

    hideConfirmDialog = () => {
        this.props.dispatch(hideConfirmDialog())
    }

    clearFilterHandler = () => {
        this.setState({
            showArchived: false,
            input: ''
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    openInfoDialog = (item) => {
        this.setState({selectedItem: item, isLogModalOpen: true})
    }

    handleCloseLogModal = () => {
        this.setState({
            isLogModalOpen: false
        })
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'input', 'offset', 'paginationPage', 'showArchived'])
    }

    handleLinkClick = (link) => {
        this.props.history.push(link)
    }

    updateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    getFields = () => {
        return {
            username: new Field('username', '', ['']),
            last_login: new Field('last_login', '', [''], false, 'datetime')
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select')
        }
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        return (
            <Layout {...this.props}>
                <Page>
                    <PageHeader
                        title={translate('page.heading.users-report')}
                    />

                    <TableCard>
                        <TableFilters
                            addClass="z-30 relative"
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                        />

                        <ResourceTable
                            data={data}
                            count={count}
                            options={RESOURCE_TABLE_SIMPLE_DEFAULT_OPTIONS}
                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={resource.isLoading}

                            limit={this.state.queryFilterFields.limit.value}
                            offset={this.state.offset}
                            page={this.state.paginationPage}
                            onOffsetChange={this.updateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={checkPerm(Resources.UsersReport, READ_PERM) ? this.openInfoDialog : null}

                            onView={checkPerm(Resources.UsersReport, READ_PERM) ? this.openInfoDialog : null}
                        />
                    </TableCard>

                    <LoginsTable
                        show={this.state.isLogModalOpen}
                        translate={translate}
                        item={this.state.selectedItem}
                        onClose={this.handleCloseLogModal}
                    />

                    {this.props.ui.confirmDialog && (
                        <ModalConfirm
                            text={this.state.text}
                            name={this.state.officeConfirmText}
                            submit={this.state.confirmModal}
                            cancel={this.hideConfirmDialog}
                            {...this.props}
                        />
                    )}
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(UsersReportView)
