import ModalDefault from "../../modal/modal-default";
import {checkPerm, openInNewTab} from "../../../util/util-helpers";
import {hideGlobalModal, hideModal} from "../../../../data/actions/ui";
import {useDispatch, useSelector} from "react-redux";
import OrganizationInfoDialog from "../../modal/modal-global/organization-info-dialog";
import LocationCardDialog from "../../modal/modal-global/global-location-info"
import React from "react";
import Modal from "../../modal";
import {useHistory, useLocation} from "react-router-dom";
import ModalHeader from "../../modal/modal-header";
import ContactCardDialog from "../../modal/contact-card-dialog";
import GlobalVendorInfoDialog from "../../modal/modal-global/global-vendor-info-dialog";
import Resources from "../../../../data/services/resources";
import ContactGroupCardDialog from "../../modal/contact-group";
import ChangePasswordDialog from "../../modal/change-password";
import About from "../../modal/about-dialog";
import ModalSupport from "../../modal/modal-support";
import {getGrabTaskResource} from "../../../../data/actions/taskResource";
import LocalStorage from "../../../util/localStorage";
import TaskGrabDialog from "./global-grab-task-dialog";
import KnowledgeBase from "../../slideover/knowledge-base";
import BoardViewDialog from "../../modal/modal-global/board-view-dialog";
import GlobalPartsInfoModal from "../../modal/modal-global/global-parts-info-modal";
import {SECTIONS_VISIBILITY, UPDATE_PERM} from "../../../../util/util-constants";
import Env from "../../../../util/env";
import TruckCard from "../../modal/modal-global/truck-card";
import TrailerCard from "../../modal/modal-global/trailer-card";
import DriverQuickCard from "../../modal/modal-global/driver-quick-view/driver-quick-card";
import GlobalSearchPopup from "../layout-components/global-search-popup";
import ModalConfirm from "../../modal/modal-confirm";
import PersonalNoteDialog from "../../modal/personal-note";
import CreateLocationDialog from "../../modal/createLocationDialog";
import {createDialogResource, updateDialogResource} from "../../../../data/actions/dialogResource";
import WorkingHoursDisplay from "../../display-data/working-hours-display";
import StripeTerminalModal from "../../modal/stripe-terminal-modal";
import StripePaymentFailedModal from "../../modal/stripe-terminal-modal/stripe-payment-failed-modal";
import StripePaymentLinkModal from "../../modal/stripe-payment-link-modal";
import TaskFormDialogWrapper from "./task-form-dialog-wrapper";

export default function GlobalDialogs({translate}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const ui = useSelector(state => state.ui);
    const helpResource = useSelector(state => state.helpResource);
    const dialogInfoResource = useSelector(state => state.dialogInfoResource);
    const dialogResource = useSelector(state => state.dialogResource);
    const secondResource = useSelector(state => state.secondResource);
    const contactResource = useSelector(state => state.contactResource);
    const history = useHistory();
    return (
        <>
            <OrganizationInfoDialog
                show={ui.modalName === 'ViewCustomerCard'}
                translate={translate}
                organizationID={ui.data?.OrganizationID}
                handleClose={() => dispatch(hideModal("ViewCustomerCard", 0))}
            />

            <Modal
                show={ui.modalName === 'ViewContactCard'}
                widthClass={"max-w-3xl"}
                onClose={() => dispatch(hideModal("ViewContactCard", 0))}
            >
                <ContactCardDialog
                    show={ui.modalName === 'ViewContactCard'}
                    onClose={() => dispatch(hideModal("ViewContactCard", 0))}
                    resourcePath={ui?.data?.ResourcePath}
                    contactImageResourcePath={ui?.data?.contactImageResourcePath}
                    hideEdit={ui?.data?.hideEdit}
                    translate={translate}
                    ContactID={ui?.data?.ContactID}
                    DriverID={ui?.data?.DriverID}
                    minHeight={"425px"}
                    dispatch={dispatch}
                />
            </Modal>

            {/*<ModalDefault*/}
            {/*    show={ui.modalName === 'ViewCarrierCard'}*/}
            {/*    title={translate("text.carrier_info")}*/}
            {/*    widthClass={"max-w-3xl"}*/}
            {/*    translate={translate}*/}
            {/*    buttonLabel={translate("btn.edit")}*/}
            {/*    onButtonClick={() => openInNewTab("/carriers/info/" + ui?.data?.OrganizationID + "/" + ui?.data?.CarrierID)}*/}
            {/*    onClose={() => dispatch(hideModal("ViewCarrierCard", 0))}*/}
            {/*    closeButtonLabel={translate("btn.close")}*/}
            {/*>*/}
            {/*    <GlobalVendorInfoDialog*/}
            {/*        translate={translate}*/}
            {/*        resourcePath={Resources.Carrier}*/}
            {/*        item={ui?.data}*/}
            {/*        id={"CarrierID"}*/}

            {/*    />*/}
            {/*</ModalDefault>*/}

            {/*<ModalDefault*/}
            {/*    show={ui.modalName === 'ViewVendorCard'}*/}
            {/*    title={translate("text.vendor_info")}*/}
            {/*    widthClass={"max-w-3xl"}*/}
            {/*    translate={translate}*/}
            {/*    buttonLabel={translate("btn.edit")}*/}
            {/*    onButtonClick={() => openInNewTab("/vendors/info/" + ui?.data?.OrganizationID + "/" + ui?.data?.VendorID)}*/}
            {/*    onClose={() => dispatch(hideModal("ViewVendorCard", 0))}*/}
            {/*    closeButtonLabel={translate("btn.close")}*/}
            {/*>*/}
            {/*    <GlobalVendorInfoDialog*/}
            {/*        translate={translate}*/}
            {/*        resourcePath={Resources.VendorsInfo}*/}
            {/*        item={ui?.data}*/}
            {/*        id={"VendorID"}*/}
            {/*    />*/}
            {/*</ModalDefault>*/}

            <Modal
                show={!!ui?.globalDialogs?.locationFormDialog?.visible}
                title={translate(`modal_heading.edit_location`)}
                widthClass={"max-w-7xl w-screen"}
                closeButtonLabel={translate("btn.close")}
                onClose={() => dispatch(hideGlobalModal("locationFormDialog"))}

                translate={translate}
                hideFooter
            >
                <CreateLocationDialog
                    onClose={() => dispatch(hideGlobalModal("locationFormDialog"))}
                    updateItem={ui?.globalDialogs?.locationFormDialog?.data}
                    onSubmit={(params) => {
                        if (params.LocationID) {
                            dispatch(updateDialogResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                resource: Resources.Locations,
                                errorMessage: true, successMessage: `Location ${params.LocationName} updated`,
                            }));
                        } else {
                            dispatch(createDialogResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                resource: Resources.Locations,
                                piggyResource: Resources.Locations,
                                errorMessage: true, successMessage: "Location Created Successfully!"
                            }));
                        }
                    }}
                    dialogResource={dialogResource}
                    contactResource={contactResource}
                    secondResource={secondResource}
                    dispatch={dispatch}
                    translate={translate}
                />
            </Modal>

            {SECTIONS_VISIBILITY.hasTasksSection !== false && (
                <TaskFormDialogWrapper
                    ui={ui}
                    translate={translate}
                    location={location}
                />
            )}

            <ContactGroupCardDialog
                show={!!ui?.globalDialogs?.ViewContactGroupCard?.visible}
                onClose={() => dispatch(hideGlobalModal("ViewContactGroupCard"))}
                translate={translate}
                data={ui?.globalDialogs?.ViewContactGroupCard?.data}
            />

            <Modal
                show={ui.modalName === 'ViewLocationCard'}
                widthClass={"max-w-3xl"}
                onClose={() => dispatch(hideModal("ViewLocationCard", 0))}
            >
                <ModalHeader
                    title={translate("text.location_info")}
                    onClose={() => dispatch(hideModal("ViewLocationCard", 0))}
                />

                <LocationCardDialog
                    translate={translate}
                    item={ui.data}
                    dispatch={dispatch}
                    onClose={() => dispatch(hideModal("ViewLocationCard", 0))}
                />
            </Modal>

            {ui?.globalDialogs?.ChangePassword?.visible &&
                <ChangePasswordDialog
                    dispatch={dispatch}
                    translate={translate}
                />
            }

            <ModalDefault
                show={ui?.globalDialogs?.About?.visible}
                widthClass={"max-w-xl"}
                translate={translate}
                title={translate("modal_heading.About")}
                onClose={() => dispatch(hideGlobalModal('About', 0))}
                closeButtonLabel={translate("btn.close")}
            >
                <About translate={translate}/>
            </ModalDefault>

            <ModalSupport
                title={ui?.globalDialogs?.ticketingModal?.data?.title}
                show={!!ui?.globalDialogs?.ticketingModal?.visible}
                history={history}
                dispatch={dispatch}
                translate={translate}
            />

            <Modal
                show={!!ui?.globalDialogs?.GrabbedTask?.visible}
                widthClass={"max-w-3xl"}
            >
                <ModalHeader
                    onClose={() => {
                        dispatch(hideGlobalModal('GrabbedTask', 0));
                        dispatch(getGrabTaskResource({
                            user: LocalStorage.get("user"),
                            resource: Resources.DashboardTasks,
                            request: 'GET'
                        }))
                    }}
                    title={ui?.globalDialogs?.GrabbedTask?.data?.task?.TaskID ? translate("modal_heading.grabbedTask") : translate("modal_heading.no_task")}
                />
                <TaskGrabDialog
                    onClose={() => {
                        dispatch(hideGlobalModal('GrabbedTask', 0));
                        dispatch(getGrabTaskResource({
                            user: LocalStorage.get("user"),
                            resource: Resources.DashboardTasks,
                            request: 'GET'
                        }))
                    }}
                    translate={translate}
                    dispatch={dispatch}
                    item={ui?.globalDialogs?.GrabbedTask?.data}
                />
            </Modal>

            {ui?.globalDialogs?.BoardInfoInfoDialog?.visible && ( // TODO: fix this. Should not be mounted. Add modal default
                <BoardViewDialog
                    isDialogOpen={ui?.globalDialogs?.BoardInfoInfoDialog?.visible}
                    TaskGroupID={ui?.globalDialogs?.BoardInfoInfoDialog?.data}
                    onClose={() => dispatch(hideGlobalModal("BoardInfoInfoDialog"))}
                    translate={translate}
                />
            )}

            <ModalDefault
                show={!!ui?.globalDialogs?.partsInfo?.visible}
                title={translate("text.parts_info")}
                widthClass={"max-w-3xl"}
                translate={translate}
                onClose={() => dispatch(hideGlobalModal("partsInfo"))}
                closeButtonLabel={translate("btn.close")}
            >
                <GlobalPartsInfoModal
                    translate={translate}
                    partID={ui?.globalDialogs?.partsInfo?.data ?? ""}
                />
            </ModalDefault>

            <KnowledgeBase
                onClose={() => dispatch(hideGlobalModal("knowledgeBaseSlideOver"))}
                open={!!ui?.globalDialogs?.knowledgeBaseSlideOver?.visible}
                translate={translate}
                dispatch={dispatch}
                helpResource={helpResource}
                history={history}
            />

            {SECTIONS_VISIBILITY.hasGlobalSearch === true && (
                <GlobalSearchPopup
                    onClose={() => dispatch(hideGlobalModal("globalSearchPopup"))}
                    show={!!ui?.globalDialogs?.globalSearchPopup?.visible}
                    translate={translate}
                />
            )}

            <ModalDefault
                show={ui.modalName === 'ViewCarrierCard'}
                title={translate("text.carrier_info")}
                widthClass={"max-w-3xl"}
                translate={translate}
                buttonLabel={translate("btn.edit")}
                onButtonClick={() => checkPerm(Resources.Carriers, UPDATE_PERM) && (SECTIONS_VISIBILITY.hasCarriersSubSection !== false ? openInNewTab("/carriers/info/" + ui?.data?.OrganizationID + "/" + ui?.data?.CarrierID) : openInNewTab(Env.getTruckMainUrl("/carriers/info/" + ui?.data?.OrganizationID + "/" + ui?.data?.CarrierID)))}
                onClose={() => dispatch(hideModal("ViewCarrierCard", 0))}
                closeButtonLabel={translate("btn.close")}
            >
                <GlobalVendorInfoDialog
                    translate={translate}
                    resourcePath={Resources.Carrier}
                    item={ui?.data}
                    id={"CarrierID"}
                />
            </ModalDefault>

            <ModalDefault
                show={ui.modalName === 'ViewVendorCard'}
                title={translate("text.vendor_info")}
                widthClass={"max-w-3xl"}
                translate={translate}
                buttonLabel={translate("btn.edit")}
                onButtonClick={() => checkPerm(Resources.Vendors, UPDATE_PERM) && (SECTIONS_VISIBILITY.hasVendorsSubSection !== false ? openInNewTab("/vendors/info/" + ui?.data?.OrganizationID + "/" + ui?.data?.VendorID) : openInNewTab(Env.getTruckMainUrl("/vendors/info/" + ui?.data?.OrganizationID + "/" + ui?.data?.VendorID)))}
                onClose={() => dispatch(hideModal("ViewVendorCard", 0))}
                closeButtonLabel={translate("btn.close")}
            >
                <GlobalVendorInfoDialog
                    translate={translate}
                    resourcePath={Resources.VendorsInfo}
                    item={ui?.data}
                    id={"VendorID"}
                />
            </ModalDefault>

            <Modal
                show={!!ui?.globalDialogs?.ViewTruckCard?.visible}
                widthClass={"max-w-3xl"}
                onClose={() => dispatch(hideGlobalModal("ViewTruckCard", 0))}
            >
                <ModalHeader
                    onClose={() => dispatch(hideGlobalModal("ViewTruckCard", 0))}
                    title={translate("text.truck_info")}
                />
                <TruckCard
                    onClose={() => dispatch(hideGlobalModal("ViewTruckCard", 0))}
                    translate={translate}
                    dialogInfoResource={dialogInfoResource}
                    dispatch={dispatch}
                    TruckID={ui?.globalDialogs?.ViewTruckCard?.data ?? ""}
                />
            </Modal>

            <Modal
                show={!!ui?.globalDialogs?.ViewTrailerCard?.visible}
                widthClass={"max-w-3xl"}
                onClose={() => dispatch(hideGlobalModal("ViewTrailerCard", 0))}
            >
                <ModalHeader
                    onClose={() => dispatch(hideGlobalModal("ViewTrailerCard", 0))}
                    title={translate("text.trailer_info")}
                />

                <TrailerCard
                    translate={translate}
                    onClose={() => dispatch(hideGlobalModal("ViewTrailerCard", 0))}
                    dialogInfoResource={dialogInfoResource}
                    dispatch={dispatch}
                    TrailerID={ui?.globalDialogs?.ViewTrailerCard?.data ?? ""}
                />
            </Modal>

            <Modal
                show={!!ui?.globalDialogs?.ViewDriverCard?.visible}
                widthClass={"max-w-3xl"}
                onClose={() => dispatch(hideGlobalModal("ViewDriverCard", 0))}
            >
                <ModalHeader
                    onClose={() => dispatch(hideGlobalModal("ViewDriverCard", 0))}
                    title={translate("modal_heading.driver_info")}
                />
                <DriverQuickCard
                    onClose={() => dispatch(hideGlobalModal("ViewDriverCard", 0))}
                    translate={translate}
                    dialogInfoResource={dialogInfoResource}
                    dispatch={dispatch}
                    DriverID={ui?.globalDialogs?.ViewDriverCard?.data ?? ""}
                />
            </Modal>


            <ModalConfirm
                title={"You don't have permission for this action"}
                show={!!ui?.globalDialogs?.NoPermissionModal?.visible}
                onClose={() => dispatch(hideGlobalModal('NoPermissionModal'))}
                closeButtonLabel={'Close'}
                translate={translate}
            >
                <p>You do not have sufficient permissions for this action.</p>
                <p>Please contact your system administrator.</p>
            </ModalConfirm>


            {!!ui?.globalDialogs?.PersonalNoteDialog?.visible && (
                <PersonalNoteDialog
                    dispatch={dispatch}
                    show={!!ui?.globalDialogs?.PersonalNoteDialog?.visible}
                    translate={translate}
                    data={ui?.globalDialogs?.PersonalNoteDialog?.data}
                />
            )}

            <ModalDefault
                show={!!ui?.globalDialogs?.workingHoursModal?.visible}
                title={ui?.globalDialogs?.workingHoursModal?.data?.locationName + " " + translate(`modal_heading.working_hours`)}
                widthClass={'max-w-2xl w-screen'}
                closeButtonLabel={translate('btn.close')}
                onClose={() => dispatch(hideGlobalModal("workingHoursModal"))}
                translate={translate}
            >
                {!!ui?.globalDialogs?.workingHoursModal?.visible && (
                    <WorkingHoursDisplay
                        locationData={ui?.globalDialogs?.workingHoursModal?.data?.locationData}
                        translate={translate}
                    />
                )}
            </ModalDefault>

            <StripeTerminalModal
                translate={translate}
            />

            <StripePaymentFailedModal
                show={!!ui?.globalDialogs?.StripePaymentErrorModal?.visible}
                data={ui?.globalDialogs?.StripePaymentErrorModal?.data}
                onClose={() => dispatch(hideGlobalModal('StripePaymentErrorModal'))}
                translate={translate}
            />

            <StripePaymentLinkModal
                translate={translate}
            />
        </>
    )
}