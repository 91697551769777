import React, {Fragment} from 'react'
import {Popover, Transition} from '@headlessui/react'
import {
    CalendarDaysIcon,
    ClipboardDocumentListIcon,
    MapIcon,
    RectangleStackIcon,
    TruckIcon,
    UserGroupIcon,
    LifebuoyIcon
} from '@heroicons/react/24/outline'
import {classNames} from "../../../util/util-helpers";
import menuApp from '../../../../assets/menus/menu-app.json'
import {getUser} from "../../../util/util-auth";
import Env from "../../../../util/env";

export default function AppPicker() {
    const icons = {
        TruckIcon: TruckIcon,
        UserGroupIcon: UserGroupIcon,
        ClipboardDocumentListIcon: ClipboardDocumentListIcon,
        MapIcon: MapIcon,
        CalendarDaysIcon: CalendarDaysIcon,
        LifebuoyIcon: LifebuoyIcon
    };

    let apps = generateAppMenu(menuApp);

    function generateAppMenu(menuApp) {
        return menuApp.map(menuItem => {
            menuItem.isVisible = true;
            switch (menuItem.name) {
                case 'TMS':
                    menuItem.isVisible = !!getUser('Company.IsTmsON', menuItem.isCurrent) || menuItem.isCurrent;
                    menuItem.href = Env.getTruckMainUrl("/");
                    break;
                case 'Tracking':
                    menuItem.isVisible = !!getUser('Company.IsTrackingON', menuItem.isCurrent) || menuItem.isCurrent;
                    menuItem.href = Env.getTruckTrackingUrl("/");
                    break;
                case 'Work orders':
                    menuItem.isVisible = !!getUser('Company.IsWorkOrdersON', menuItem.isCurrent) || menuItem.isCurrent;
                    menuItem.href = Env.getTruckWorkOrdersUrl("/");
                    break;
                case 'My Portal':
                    menuItem.isVisible = !!getUser('Company.IsHrON', menuItem.isCurrent) || menuItem.isCurrent;
                    menuItem.href = Env.getTruckHrUrl("/");
                    break;
                case 'Rental':
                    menuItem.isVisible = !!getUser('Company.IsRentalON', menuItem.isCurrent) || menuItem.isCurrent;
                    menuItem.href = Env.getTruckRentalUrl("/")
                    break;
                case 'Support':
                    menuItem.isVisible = true
                    menuItem.href = Env.getSupportUrl("/")
                    break;
                default:
            }

            if (menuItem.isCurrent) {
                menuItem.href = "/"
            }

            return menuItem;
        })
    }

    return (
        <Popover className="relative">
            {({open}) => (
                <>

                    <Popover.Button
                        className={classNames(open ? 'text-tm-gray-900' : '', 'icon-btn')}
                    >
                        <RectangleStackIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                        />
                    </Popover.Button>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel
                            className="absolute z-10 left-1/2 transform -translate-x-2/3 mt-3 px-2 w-screen max-w-md sm:px-0"
                        >
                            <div
                                className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden border border-tm-gray-300">
                                <div
                                    className="relative grid gap-6 bg-popup border-b border-tm-gray-300 px-5 py-6 sm:gap-8 sm: rounded-t-md">
                                    {apps
                                        .filter(item => item.isVisible)
                                        .map((item) => {
                                                const Icon = icons[item.icon];

                                                return (
                                                    <a
                                                        key={item.name}
                                                        href={item.href}
                                                        target="_blank"
                                                        className={classNames(
                                                            item.isCurrent ? "text-white bg-primary" : "hover:bg-tm-gray-100",
                                                            "-m-3 p-3 flex items-start rounded-lg transition ease-in-out duration-150"
                                                        )}
                                                        rel="noreferrer"
                                                    >
                                                        <Icon
                                                            className={
                                                                classNames(
                                                                    "flex-shrink-0 h-6 w-6",
                                                                    item.isCurrent ? "text-white" : "text-tm-gray-400"
                                                                )
                                                            }
                                                            aria-hidden="true"
                                                        />
                                                        <div className="ml-4">
                                                            <p className={classNames(
                                                                item.isCurrent ? "text-white" : "text-tm-gray-900",
                                                                "text-base font-medium"
                                                            )}>{item.name}</p>

                                                            <p
                                                                className={classNames(
                                                                    "mt-1 text-sm",
                                                                    item.isCurrent ? "text-white" : "text-tm-gray-600"
                                                                )}
                                                            >
                                                                {item.description}
                                                            </p>
                                                        </div>
                                                    </a>
                                                )
                                            }
                                        )}
                                </div>
                                {/*<div*/}
                                {/*    className="px-5 py-5 bg-tm-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">*/}
                                {/*    {callsToAction.map((item) => (*/}
                                {/*        <div key={item.name} className="flow-root">*/}
                                {/*            <a*/}
                                {/*                href={item.href}*/}
                                {/*                className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-tm-gray-900 hover:bg-tm-gray-50 transition ease-in-out duration-150"*/}
                                {/*            >*/}
                                {/*                <item.icon className="flex-shrink-0 h-6 w-6 text-tm-gray-400"*/}
                                {/*                           aria-hidden="true"/>*/}
                                {/*                <span className="ml-3">{item.name}</span>*/}
                                {/*            </a>*/}
                                {/*        </div>*/}
                                {/*    ))}*/}
                                {/*</div>*/}
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}
