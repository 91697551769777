import React, {useRef} from "react";
import {rgbToHex} from "../../../util/util-helpers";

export default function ColorPickerGradient({selectedColor, setSelectedColor, markerPosition, setMarkerPosition}) {

    const gradientRef = useRef(null);
    const isDragging = useRef(false);

    const handleColorSelection = (e) => {
        if (!gradientRef.current) return;
        const rect = gradientRef.current.getBoundingClientRect();
        const x = Math.min(Math.max(e.clientX - rect.left, 0), rect.width);
        const y = Math.min(Math.max(e.clientY - rect.top, 0), rect.height);
        setMarkerPosition(x);

        const canvas = document.createElement("canvas");
        canvas.width = rect.width;
        canvas.height = rect.height;
        const ctx = canvas.getContext("2d");
        if (ctx) {
            const gradient = ctx.createLinearGradient(0, 0, rect.width, 0);
            gradient.addColorStop(0, "red");
            gradient.addColorStop(0.16, "yellow");
            gradient.addColorStop(0.33, "green");
            gradient.addColorStop(0.5, "cyan");
            gradient.addColorStop(0.66, "blue");
            gradient.addColorStop(0.83, "magenta");
            gradient.addColorStop(1, "red");
            ctx.fillStyle = gradient;
            ctx.fillRect(0, 0, rect.width, rect.height);
            const pixel = ctx.getImageData(x, y, 1, 1).data;
            setSelectedColor(rgbToHex(pixel[0], pixel[1], pixel[2]));
        }
    };

    return (
        <div
            ref={gradientRef}
            className="w-full h-6 cursor-pointer rounded-full relative"
            style={{
                background: "linear-gradient(to right, red, yellow, green, cyan, blue, magenta, red)"
            }}
            onMouseDown={(e) => {
                isDragging.current = true;
                handleColorSelection(e);
            }}
            onMouseMove={(e) => {
                if (isDragging.current) handleColorSelection(e);
            }}
            onMouseUp={() => {
                isDragging.current = false;
            }}
        >
            <div
                className="absolute w-6 h-6 border-2 border-white rounded-full"
                style={{
                    left: `${markerPosition - 8}px`,
                    backgroundColor: selectedColor
                }}
            ></div>
        </div>
    )
}