import {classNames} from "../../../util/util-helpers";
import React, {useEffect, useState} from "react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import ModalDefault from "../../modal/modal-default";

export default function ColorPickerDeleteColors({
                                                    userColors,
                                                    isDeleteColorsDialogOpen,
                                                    setIsDeleteColorsDialogOpen,
                                                    onDeleteColors,
                                                    translate
                                                }) {
    const [selectedColors, setSelectedColors] = useState([]);

    function handleSelectColor(color) {
        const selectedColorsUpdate = [...selectedColors];
        if (selectedColorsUpdate.includes(color)) {
            const index = selectedColorsUpdate.indexOf(color);

            if (index !== -1) {
                selectedColorsUpdate.splice(index, 1); // Remove the item at the found index
            }

            setSelectedColors(selectedColorsUpdate);
        } else {
            selectedColorsUpdate.push(color);
            setSelectedColors(selectedColorsUpdate);
        }
    }

    useEffect(() => {
        setSelectedColors([]);
    }, [isDeleteColorsDialogOpen]);

    return (
        <ModalDefault
            show={isDeleteColorsDialogOpen}
            widthClass={'max-w-lg'}
            title={"Remove colors from the color palette"}

            closeButtonLabel={translate('btn.Cancel')}
            onClose={() => setIsDeleteColorsDialogOpen(false)}
            buttonLabel={"Remove selected colors"}
            buttonDisabled={!selectedColors.length}
            onButtonClick={() => {
                onDeleteColors(selectedColors);
                setIsDeleteColorsDialogOpen(false);
            }}
        >
            <div className="p-6 bg-tm-gray-100">

                <div className="flex flex-wrap gap-4 pt-4 pb-2 max-w-md mx-auto px-2">
                    {
                        userColors.map(it => {
                            return <button
                                key={it}
                                onClick={() => {
                                    handleSelectColor(it);
                                }}
                                className={classNames(
                                    'relative w-10 h-10 rounded-full ring-offset-inverse ring-primary focus:ring-2 focus:ring-offset-2 flex items-center justify-center focus:ring-offset-inverse outline-0 focus:outline-none',
                                )}
                                style={{background: it}}
                            >
                                {selectedColors.includes(it) && (
                                    <XMarkIcon className="absolute -top-1 -left-1 w-12 h-12 text-white"/>
                                )}
                            </button>
                        })
                    }
                </div>
            </div>
        </ModalDefault>
    )
}