import React, {useEffect} from 'react'
import Tooltip from "../../../common/components/tooltip";
import {DocumentArrowDownIcon} from "@heroicons/react/24/outline";
import {download} from "../../../data/actions/download";
import LocalStorage from "../../../util/localStorage";
import {currentDate} from "../../../common/util/util-dates";
import {useDispatch, useSelector} from "react-redux";
import Resources from "../../../data/services/resources";
import {Field, FieldsManager} from "../../../data/services/fields";
import {DEFAULT_QUERY_LIMIT} from "../../../util/util-constants";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import NoRecords from "../../../common/components/no-records-found/no-records";
import {getResource} from "../../../data/actions/resource";
import {genericMoneyFormatter} from "../../../common/util/util-vanilla";
import ResourceTable from "../../../common/components/resource-table";
import {getDefaultTableOptions} from "../../../common/util/util-helpers";
import useQuery from "../../../hooks/use-query";
import moment from "moment-timezone";

const SumPaymentDays = {
    "1": 1,
    "5": 5,
    "10": 10,
    "15": 15,
    "20": 20,
    "30": 30,
    "45": 45,
    "60": 60,
    "90": 90,
    "180": 180
}

export default function PaymentAging({translate}) {
    const dispatch = useDispatch();
    const resourcePath = Resources.AccountingReportsARPayments;
    const storageKey = "payment-aging";

    const [queryFields, setQueryFields] = useQuery(getQueryFields(), storageKey);
    const query = FieldsManager.getFieldKeyValues(queryFields)
    const resource = useSelector(state => state.resource);
    const isLoading = resource.isLoading;
    const data = resource?.data?.list ?? [];

    const tableOptions = getDefaultTableOptions(getFields(), {
        behaviour: {
            canAdjustWidth: true
        },
        columns: {}
    }, storageKey, translate);

    let amount = 0;
    let payments = 0;
    let balance = 0;
    let curr = 0;
    let thirty = 0;
    let sixty = 0;
    let ninety = 0;

    data.forEach(it => {
        amount = amount + it.Amount;
        payments = payments + it.Payments;
        balance = balance + it.AmountTransferred;
        curr = curr + it.Curr;
        thirty = thirty + it.Thirty;
        sixty = sixty + it.Sixty;
        ninety = ninety + it.Ninety;
    });

    /** Data Events
     ================================================================= */
    function fetchData() {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            query: query,
            resource: resourcePath
        }))
    }

    function downloadExcel() {
        dispatch(download({
            user: LocalStorage.get('user'),
            resource: resourcePath,
            query: Object.assign({
                format: 'EXCEL',
                name: 'reports_aging_' + currentDate() + '.xlsx'
            }, query)
        }))
    }

    /** UI Events
     ================================================================= */
    function handleFilterInputChange(name, value) {
        setQueryFields(Object.assign({}, FieldsManager.updateField(queryFields, name, value)));
    }

    function handleFilterClear() {
        setQueryFields(getQueryFields());
    }

    /** Helpers
     ================================================================= */
    function getFields() {
        return {
            OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {
                label: 'ChargeTo',
                render: (it) => it.Organization ?? it.Contact
            }),
            Age: new Field('Age', '', [''], false, 'text', {}),
            Amount: new Field('Amount', '', [''], false, 'money', {}),
            Payments: new Field('Payments', '', [''], false, 'money', {}),
            AmountTransferred: new Field('AmountTransferred', '', [''], false, 'money', {
                omitSort: true,
                render: (item) => {
                    return <div
                        className={'text-right'}>{genericMoneyFormatter(item.Amount - item.AmountTransferred)}</div>
                }
            }),
            Curr: new Field('Curr', '', [''], false, 'money', {
                omitSort: true,
            }),
            Thirty: new Field('Thirty', '', [''], false, 'money', {
                omitSort: true,
            }),
            Sixty: new Field('Sixty', '', [''], false, 'money', {
                omitSort: true,
            }),
            Ninety: new Field('Ninety', '', [''], false, 'money', {
                omitSort: true,
            }),
        }
    }

    function getQueryFields() {
        return {
            query: new Field('query', '', [''], false, 'search', {hideLabel: true}, {}),
            SumPaymentDays: new Field('SumPaymentDays', '30', [''], false, 'select', {}),
            EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), ['empty'], false, 'date', {
                labelType: 'float',
                addContainerClass: 'relative z-40'
            }, {
                isClearable: true
            }),
            OrganizationID: new Field('OrganizationID', '', [], false, 'select-search', {
                addContainerClass: "min-w-[231px] max-w-full"
            }, {hasPortal: true}),
            sort: new Field('sort', 'ASC', [''], false, 'text', {}, {}),
            sortBy: new Field('sortBy', '', [''], false, 'text', {}, {}),
            offset: new Field('offset', "0", [''], false, 'text', {}, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                labelType: 'float', hideLabel: true
            }, {menuPlacement: 'top'}),
        }
    }


    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData();
    }, [queryFields]);

    /** Render
     ================================================================= */
    return (
        <React.Fragment>
            <div className="flex mb-4">
                <ActiveFilters
                    excludedFields={['limit', 'offset', 'sort', 'sortBy','SumPaymentDays','EndDate']}
                    filterFields={queryFields}
                    onLabelClick={handleFilterInputChange}
                    onClearFiltersClick={handleFilterClear}
                    translate={translate}
                />

                <div className="flex items-end ml-auto">
                    <Tooltip content={translate('text.download_excel')}>
                        <button
                            className="btn-icon"
                            onClick={downloadExcel}
                        >
                            <DocumentArrowDownIcon className="w-5 h-5"/>
                        </button>
                    </Tooltip>
                </div>
            </div>

            <TableCard>
                <TableFilters
                    hideLimit
                    filterFields={queryFields}
                    selects={
                        {
                            SumPaymentDays: SumPaymentDays
                        }
                    }
                    isLoading={isLoading}
                    handleInputChange={handleFilterInputChange}
                    translate={translate}
                    largeGrid
                    forceDialog
                    onRefreshTable={fetchData}
                />


                <ResourceTable
                    data={data}
                    fields={getFields()}
                    queryFields={queryFields}
                    translate={translate}
                    isLoading={isLoading}
                    options={tableOptions}
                    tfoot={{
                        Amount: amount,
                        Payments: payments,
                        AmountTransferred: balance,
                        Curr: curr,
                        Thirty: thirty,
                        Sixty: sixty,
                        Ninety: ninety
                    }}
                />


                {/*Table footer*/}
                <NoRecords
                    show={(data.length === 0) && !isLoading}
                    title={translate('text.no_records')}
                    addClass={'pt-16 pb-10'}
                />

                <div
                    className='bg-tm-gray-200 px-5 flex items-center justify-between h-2 rounded-card md:rounded-t-none'
                />
            </TableCard>
        </React.Fragment>
    )
}