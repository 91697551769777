import React, {useEffect, useRef, useState} from "react";
import Resources from "../../../data/services/resources";
import {Field, FieldsManager} from "../../../data/services/fields";
import {checkPerm, getDefaultQueryFields, getDefaultTableOptions} from "../../../common/util/util-helpers";
import {getUser} from "../../../common/util/util-auth";
import {useDispatch, useSelector} from "react-redux";
import {deleteResource, getResource} from "../../../data/actions/resource";
import useQuery from "../../../hooks/use-query";
import {classNames, getProp} from "../../../common/util/util-helpers";
import {cloneDeep} from "../../../common/util/util-vanilla";
import {ChevronUpIcon as ChevronUpSolidIcon} from '@heroicons/react/20/solid'
import LocalStorage from "../../../common/util/localStorage";
import {showGlobalModal, showModal} from "../../../data/actions/ui";
import {Square2StackIcon} from "@heroicons/react/24/outline"
import Layout from "../../../common/components/layout";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import ResourceList from "../../../common/components/layout/layout-components/resource-list";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import StatusProgressTable
    from "../../../common/components/resource-table/table-components/status-progress-table/rental-status-progress-table";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import TableTagManager from "../../../common/components/resource-table/table-components/table-tag-manager";
import {
    CREATE_PERM,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    READ_PERM,
    TRUCK_STATUS_TYPES,
    UPDATE_PERM
} from "../../../common/util/util-consts";
import CellLink from "../../../common/components/resource-table/table-components/cell-link";
import Page from "../../../common/components/layout/layout-components/page";

export default function RentalsView({history, match, translate}) {

    const autoRefreshTime = 60000;
    const autoRefreshTimeout = useRef(setInterval(null));
    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const isLoading = getProp(resource, "isLoading", true);
    const [selectedRows, setSelectedRows] = useState({})
    const [confirmDialogData, setConfirmDialogData] = useState({});

    const [isAutoRefreshEnabled, setIsAutoRefreshEnabled] = useState(LocalStorage.get(getListPath() + "_query")?.isAutoRefreshEnabled ?? false);
    /** State
     ================================================================= */
    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), getListPath())
    const query = Object.assign({}, FieldsManager.getFieldKeyValues(queryFields), {
        searchFields: JSON.stringify({
            RentContactID: queryFields.RentContactID?.value?.value,
            RentOrganizationID: queryFields.RentOrganizationID?.value?.value,
            PayContactID: queryFields.PayContactID?.value?.value,
            PayOrganizationID: queryFields.PayOrganizationID?.value?.value,
            RentTypeID: queryFields.RentTypeID?.value,
            RentalStatusID: queryFields.RentalStatusID?.value,
        })
    });
    const [tableOptions, setTableOptions] = useState(
        getDefaultTableOptions(getFields(), {
            behaviour: {
                rowSelect: true,
                hasMenu: true,
                canAdjustWidth: true
            }
        }, getListPath(), translate)
    );

    useEffect(() => {
        fetchData(dispatch, query)
    }, [queryFields]);

    useEffect(() => {
        setQueryFields(queryFields)
    }, []);

    /** UI events
     ================================================================= */
    const handleCreateResourceClick = () => {
        history.push('/rentals-create/');
    }

    const handleEditResourceClick = (item) => {
        history.push('/rentals/' + item.RentalID);
    }

    const handleCopyRental = (item) => {
        history.push(('/rentals/copy/' + item.RentalID))
    }

    const handleSelectRowClick = (item) => {
        const itemID = item.RentalID

        let selectedRowsUpdate = cloneDeep(selectedRows)

        if (selectedRowsUpdate[itemID]) {
            delete selectedRowsUpdate[itemID]
        } else {
            Object.assign(selectedRowsUpdate, {[itemID]: item})
        }

        setSelectedRows(selectedRowsUpdate)
    }

    const handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(resource.data, 'list', []))

        let selectedRowsClone = cloneDeep(selectedRows)

        if (!selectAll) {
            Object.assign(selectedRowsClone, data.reduce((memo, it) => {
                memo[it.RentalID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsCloneKeys = Object.keys(selectedRowsClone)
            data.forEach(it => {
                if (selectedRowsCloneKeys.includes(it.RentalID.toString())) {
                    delete selectedRowsClone[it.RentalID]
                }
            })
        }

        setSelectedRows(selectedRowsClone)
    }

    const handleDeletePartClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmDeleteRentalClick(item),
            type: "danger",
            content: <span>
                Are you sure you want to delete <span className="font-bold text-red-600">{item.AutoCounter}</span>?
            </span>,
            title: translate("text.confirm_delete"),
            buttonLabel: translate("btn.delete")
        })
    }

    const handleConfirmDeleteRentalClick = (item) => {
        dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: Object.assign({}, query, {RentalID: item.RentalID}),
            notificationMessage: translate(`text.rental_deleted`),
            resource: Resources.Rental,
            piggyResource: getResourcePath(),
            successMessage: translate("text.rental_deleted"),
            errorMessage: true
        }));

        closeConfirmDialog();
    }
    const closeConfirmDialog = () => {
        setConfirmDialogData(Object.assign({}, confirmDialogData, {isOpen: false}))
    }

    const handleViewTruckClick = (truckID) => {
        dispatch(showGlobalModal('ViewTruckCard', truckID));
    }

    const handleViewTrailerClick = (trailerID) => {
        dispatch(showGlobalModal('ViewTrailerCard', trailerID));
    }

    const handleViewContactClick = (contactID) => {
        dispatch(showModal("ViewContactCard", {ContactID: contactID}))
    }

    const handleViewOrganizationClick = (organizationID) => {
        dispatch(showModal('ViewCustomerCard', {OrganizationID: organizationID}));
    }

    const autoFetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: getResourcePath(),
            data: {
                deltaKey: "RentalID",
                compareKeys: Object.values(tableOptions?.columns).filter(it => it.show).map(it => it.name)
            },
            query: query
        }))
    }

    function handleAutoRefreshClick() {
        const isEnabled = !isAutoRefreshEnabled;
        const storage = LocalStorage.get(getListPath() + "_query");
        storage.isAutoRefreshEnabled = isEnabled;
        LocalStorage.set(getListPath() + "_query", storage);
        setIsAutoRefreshEnabled(isEnabled);

        if (isEnabled) {
            autoRefreshTimeout.current = setInterval(autoFetchData, autoRefreshTime);
        } else {
            clearTimeout(autoRefreshTimeout.current);
        }
    }
    /** Component Body
     ================================================================= */
    return (
        <React.Fragment>
            <Layout
                history={history}
                match={match}
                customPageTitle={"Rentals"}
                translate={translate}
            >
             <Page>
                <PageHeader
                    title={translate("page_title.rentals")}
                    buttonLabel={translate("btn.create")}
                    onButtonClick={() => handleCreateResourceClick()}
                    // buttonHidden={!checkPerm(getResourcePath(), CREATE_PERM)}
                />

                <ResourceList
                    fields={getFields(translate, handleViewTruckClick, handleViewTrailerClick, handleViewContactClick, handleViewOrganizationClick)}
                    toggleAutoRefresh={handleAutoRefreshClick}
                    isAutoRefreshEnabled={isAutoRefreshEnabled}
                    // dataImages={dataImages}
                    resource={resource}
                    // fetchData={fetchData}
                    isLoading={isLoading}
                    queryFields={queryFields}
                    setQueryFields={setQueryFields}
                    listPath={getListPath()}
                    dispatch={dispatch}
                    onSelectRow={handleSelectRowClick}
                    onSelectAllClick={handleSelectAllClick}
                    selectedRows={selectedRows}
                    query={query}
                    onRefreshTable={() => fetchData(dispatch, query)}
                    translate={translate}
                    // onMount={fetchData}
                    selects={getSelects()}

                    tableKey={"RentalID"}
                    onRowClick={checkPerm(Resources.RentalInfo, UPDATE_PERM) && handleEditResourceClick}
                    // onView
                    onEdit={handleEditResourceClick}
                    onDelete={handleDeletePartClick}
                    actions={[
                        {
                            action: handleCopyRental,
                            icon: Square2StackIcon, // make this a function
                            hasPerm: checkPerm(Resources.Rental, CREATE_PERM),
                            title: translate("btn.duplicate_rental"),
                            order: 21,
                        }
                    ]}
                    hasEditPerm={checkPerm(Resources.RentalInfo, UPDATE_PERM)}
                    hasDeletePerm={checkPerm(Resources.Rental, DELETE_PERM)}
                >
                    <TableBulkActions
                        selectedRows={selectedRows}
                        tableKey="RentalID"
                        fields={getFields(translate, handleViewTruckClick, handleViewTrailerClick, handleViewContactClick, handleViewOrganizationClick)}
                        translate={translate}
                        options={tableOptions}
                        setSelectedRows={setSelectedRows}
                        onSelectAllClick={handleSelectAllClick}
                    >
                        <div className='flex items-center h-8'>
                            <div className={classNames('flex divide-x-2 divide-primary-shade pr-2')}>
                                <div className='flex gap-x-1 pr-2'>


                                    <div className='flex gap-x-1 px-2'>
                                        <TableTagManager
                                            rowKey={'RentalID'}
                                            btnClass={'p-1 flex items-center rounded-md text-primary-contrast hover:bg-primary-shade'}
                                            btnIconClass='w-5 h-5'
                                            customIcon={<ChevronUpSolidIcon className="w-5 h-5"/>}
                                            selectedRows={selectedRows}
                                            resourceName={Resources.ColorTagRental}
                                            piggyResourceName={getResourcePath()}
                                            query={query}
                                            onAfterTagRows={() => {
                                                setSelectedRows({})
                                            }}
                                            translate={translate}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </TableBulkActions>
                </ResourceList>
             </Page>
            </Layout>

            <ModalConfirm
                show={confirmDialogData?.isOpen ?? false}
                type={confirmDialogData?.type}
                content={confirmDialogData?.content}
                title={confirmDialogData?.title}
                onConfirm={confirmDialogData?.onClick}
                closeButtonLabel={confirmDialogData?.closeButtonLabel ?? translate("btn.cancel")}
                buttonLabel={confirmDialogData?.buttonLabel}
                translate={translate}
                onClose={closeConfirmDialog}
            />
        </React.Fragment>
    )
}

/** Page methods
 ================================================================= */
const getPrimaryField = () => "RentalID";
const getResourcePath = () => Resources.Rentals;

const getListPath = () => getResourcePath() + "_list"

const fetchData = (dispatch, query) => {
    dispatch(getResource({
        user: getUser(),
        resource: getResourcePath(),
        query: query
    }))
}

const getQueryFields = (translate) => {
    return (
        Object.assign(
            getDefaultQueryFields(getPrimaryField(), translate),
            {
                archived: new Field('archived', '', [''], false, 'checkbox', {
                    labelType: "float",
                    label: "archived",
                    hasActiveBadge: true,
                }),
                RentContactID: new Field('RentContactID', '', [], false, 'select-search', {
                    hasActiveBadge: true,
                    label: "RentContactFilter"
                }, {isClearable: true}),
                RentOrganizationID: new Field('RentOrganizationID', '', [], false, 'select-search', {
                    hasActiveBadge: true,
                    label: "RentOrganizationFilter"
                }, {isClearable: true}),
                PayContactID: new Field('PayContactID', '', [], false, 'select-search', {
                    hasActiveBadge: true,
                    label: "PayContactFilter"
                }, {isClearable: true}),
                PayOrganizationID: new Field('PayOrganizationID', '', [], false, 'select-search', {
                    hasActiveBadge: true,
                    label: "PayOrganizationFilter"
                }, {isClearable: true}),
                RentTypeID: new Field('RentTypeID', '', [], false, 'select', {hasActiveBadge: true}, {all: true}),
                RentalStatusID: new Field('RentalStatusID', '', [], false, 'select', {hasActiveBadge: true}, {all: true}),
            }
        )
    )
}

function getFields(translate, handleViewTruckClick, handleViewTrailerClick, handleViewContactClick, handleViewOrganizationClick, item = {}) {
    const fieldTemplates = {
        AutoCounter: new Field('AutoCounter', '', [''], false, 'custom', {
            render: (it) => {
                return (
                    <div title={it.InternalNotes}>
                        <CellLink
                            to={checkPerm(Resources.RentalInfo, UPDATE_PERM) && "/rentals/" + it.RentalID}
                            disabled={!checkPerm(Resources.RentalInfo, UPDATE_PERM)}
                        >
                            {it.AutoCounter}
                        </CellLink>
                    </div>
                )
            }
        }),
        RefNumber: new Field('RefNumber', '', [], false, 'text'),
        RentTypeID: new Field('RentTypeID', '', [], false, 'select', {
            render: (it) => {
                if (it.RentTypeID === 1) {
                    return "Rent"
                } else if (it.RentTypeID === 2) {
                    return "Lease"
                }
            }
        }),
        RentalStatusID: new Field('RentalStatusID', '2', [''], false, 'select', {
            render: (item) => {
                return (
                    <StatusProgressTable
                        addClass=""
                        RentalStatusID={item.RentalStatusID}
                        translate={translate}
                    />
                )
            }
        }),
        Labels: new Field('Labels', '', [''], false, 'custom', {
            render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
            , omitSort: true
        }),
        TruckID: new Field('TruckID', '', [''], false, 'select-search', {
            render: (item) => {
                if (!item) return null;

                if (item.TruckID) {
                    return (
                        <CellButton
                            onClick={() => checkPerm(Resources.TrucksInfo, READ_PERM) && handleViewTruckClick(item.TruckID)}
                        >
                            {item.Truck}
                        </CellButton>
                    )
                } else {
                    return (<span>{item.Truck}</span>)
                }
            }
        }),
        TrailerID: new Field('TrailerID', '', [''], false, 'select-search', {
            render: (item) => {
                if (!item) return null;

                if (item.TrailerID) {
                    return (
                        <CellButton
                            onClick={() => checkPerm(Resources.Trailer, READ_PERM) && handleViewTrailerClick(item.TrailerID)}
                        >
                            {item.Trailer}
                        </CellButton>
                    )
                } else {
                    return item.Trailer;
                }
            }
        }),

        RentTo: new Field('RentTo', '', [], false, 'custom', {
            render: (item) => {
                if (item.RentContactID) {
                    return <CellButton
                        onClick={() => checkPerm(Resources.Contacts, UPDATE_PERM) && handleViewContactClick(item.RentContactID)}
                    >
                        {item.RentContact}
                    </CellButton>
                } else if (item.RentOrganizationID) {
                    item.OrganizationID = item.RentOrganizationID
                    return <CellButton
                        onClick={() => checkPerm(Resources.Organizations, READ_PERM) && handleViewOrganizationClick(item.RentOrganizationID)}
                    >
                        {item.RentOrganization}
                    </CellButton>
                } else {
                    return ""
                }
            }
            , omitSort: true
        }),
        PayTo: new Field('PayTo', '', [], false, 'custom', {
            label: "ChargeTo",
            render: (item) => {
                if (item.PayContactID) {
                    return <CellButton
                        onClick={() => checkPerm(Resources.Contacts, UPDATE_PERM) && handleViewContactClick(item.PayContactID)}
                    >
                        {item.PayContact}
                    </CellButton>
                } else if (item.PayOrganizationID) {
                    item.OrganizationID = item.PayOrganizationID
                    return <CellButton
                        onClick={() => checkPerm(Resources.Organizations, READ_PERM) && handleViewOrganizationClick(item.PayOrganizationID)}
                    >
                        {item.PayOrganization}
                    </CellButton>
                } else {
                    return ""
                }
            }
            , omitSort: true
        }),
        RentalContractID: new Field('RentalContractID', '', [''], false, 'select-search'),
        OfficeID: new Field('OfficeID', '', [], false, 'select-search'),
        ContactGroupID: new Field('ContactGroupID', '', [], false, 'select-search'),
        CreatedByID: new Field('CreatedByID', '', [], false, 'select-search'),
        CreateDate: new Field('CreateDate', '', [''], false, 'datetimezone'),
        UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'select-search'),
        CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone')

    }

    return fillFieldsFromData(fieldTemplates, item)
}


function getSelects() {
    return {
        ContactID: {
            api: 'api/' + Resources.Contacts,
            query: {},
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.ContactID,
            })
        },
        RentContactID: {
            api: 'api/' + Resources.ContactsQuick,
            query: {},
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.ContactID,
            })
        },
        PayContactID: {
            api: 'api/' + Resources.ContactsQuick,
            query: {},
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.ContactID,
            })
        },
        MoneyCodeTypeID: {
            api: 'api/' + Resources.MoneyCodeType,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.MoneyCodeTypeID,
                label: item.Reason
            })
        },
        RentOrganizationID: {
            api: 'api/' + Resources.OrganizationsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.LegalName,
                value: item.OrganizationID
            })
        },
        PayOrganizationID: {
            api: 'api/' + Resources.OrganizationsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.LegalName,
                value: item.OrganizationID
            })
        },
        StatusID: TRUCK_STATUS_TYPES,
        RentTypeID: {
            1: "Rent",
            2: "Lease",
        },
        RentalStatusID: {
            1: "Booking Requested",
            2: "Approval Pending",
            3: " Approved",
            4: "Equipment Ready",
            5: "Rental/Lease Active",
            6: "Completed"
        }
    }
}
