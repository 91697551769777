import React, {useEffect, useState} from "react";
import Tippy from "@tippyjs/react";
import Cog6ToothIcon from "@heroicons/react/24/outline/esm/Cog6ToothIcon";
import {classNames} from "../../../../../util/util-helpers";
import {LoaderSmall} from "../../../../loader";
import FieldSearch from "../../../../fields/field-text/search";

export default function TableTagManagerPopover({
                                                   translate,
                                                   onManageTagsClick,
                                                   onApplySelected,
                                                   labels,
                                                   selectedRows,
                                                   taggedRows,
                                                   isLoading
                                               }) {

    const [labelList, setLabelList] = useState([]);
    const [isDirty, setIsDirty] = useState(false);
    const [filterValue, setFilterValue] = useState("");

    useEffect(() => {
        if (!isLoading) {
            setLabelList(!!labels && labels.map(it => {
                const tag = it.ColorTag.split(",");
                let checked = getCheckboxValue(it);
                let someChecked = areSomeChecked(it);

                if (checked && taggedRows.hasRowsWithNoTags) {
                    checked = false;
                    someChecked = true;
                }

                return {
                    id: it.ColorTagID,
                    label: tag[0], color: tag[1],
                    tag: it.ColorTag,
                    checked: checked,
                    areSomeChecked: someChecked,
                };
            }));
        }
    }, [isLoading])

    const getCheckboxValue = (it) => {
        if (taggedRows?.list?.[it.ColorTag]) {
            return Object.keys(selectedRows).every(r => taggedRows.list[it.ColorTag].includes(Number(r)));
        }

        return false;
    }

    const areSomeChecked = (it) => {
        if (taggedRows?.list?.[it.ColorTag]) {
            return taggedRows.list[it.ColorTag].some(r => Object.keys(selectedRows).includes(r.toString()));
        }

        return false;
    }

    const handleCheckboxChange = (id) => {
        setLabelList(labelList.map(it => {
            if (it.id === id) {
                it.checked = !it.checked;
                it.areSomeChecked = false;
            }

            return it;
        }));

        setIsDirty(true);
    }

    const handleCheckboxClearAll = () => {
        setLabelList(labelList.map(it => {
            it.checked = false;
            it.areSomeChecked = false;

            return it;
        }));

        setIsDirty(true);
    }

    let selectionCount = 0;
    labelList.forEach(it => {
        if (it.checked) {
            selectionCount++
        }
    });

    const areTooMuchSelected = selectionCount > 3;
    const hasLabels = !!labelList.length;
    let filteredLabelList = [];

    if (hasLabels) {
        filteredLabelList = labelList.filter(it => it.label.toLowerCase().includes(filterValue.toLowerCase()));
    }

    return (
        <div className="space-y-3">
            <div className="flex items-center justify-between p-3 border-b border-tm-gray-300">
                <h2 className="text-tm-gray-900 text-sm font-bold">
                    {translate("text.label_as")}
                    <span
                        className={classNames(areTooMuchSelected ? "text-red-600" : "text-tm-gray-500", "font-normal")}>{" "}(max 3)</span>
                </h2>

                <Tippy
                    content={translate("text.manage_tags")}
                    delay={[400, 0]}
                    trigger={"mouseenter"}
                >
                    <button
                        onClick={onManageTagsClick}
                        className="btn btn-outline py-1 px-2 group focus:bg-primary-transparent"
                    >
                        <Cog6ToothIcon className="w-5 h-5 text-primary group-focus:text-tm-gray-700"/>
                    </button>
                </Tippy>
            </div>

            <div className="px-3 max-h-[18rem] overflow-auto">
                {isLoading && (
                    <LoaderSmall/>
                )}

                {!isLoading && !labelList?.length && (
                    <p className="mb-4 text-center text-tm-gray-700">{translate("text.no_labels")}</p>
                )}

                {labelList.length > 8 && (
                    <FieldSearch
                        autoFocus={true}
                        addClass={"form-control rounded-md w-full"}
                        onChange={(_, value) => setFilterValue(value)}
                        value={filterValue}
                    />
                )}

                {!isLoading && hasLabels && !filteredLabelList.length && (
                    <div className="leading-6 py-2 px-4">
                        No results
                    </div>
                )}

                {!isLoading && filteredLabelList.map(it => {
                    return (
                        <label
                            key={it.key}
                            className="flex items-center gap-2 text-sm hover:bg-tm-gray-100 py-2 px-4 rounded-md"
                        >
                            <div className={classNames("w-3 h-3 rounded-full")} style={{background: it.color}}/>
                            {it.label}

                            <span className="relative ml-auto">
                                {it.areSomeChecked && !it.checked &&(
                                    <div className="absolute z-10 inset-0 flex items-center justify-center">
                                        <div className="h-px w-2 border-b-2 border-tm-gray-600 pt-px"/>
                                    </div>
                                )}

                                <input
                                    type="checkbox"
                                    checked={it.checked}
                                    onChange={() => handleCheckboxChange(it.id)}
                                    className={
                                        classNames(
                                            "z-10 rounded bg-tm-gray-50 checked:bg-primary text-primary ring-offset-2 !ring-offset-inverse checked:ring-offset-inverse ml-auto",
                                            areTooMuchSelected ? "text-red-600" : "",
                                            it.checked ? "z-20" : ""
                                        )
                                    }
                                />
                            </span>
                        </label>
                    )
                })}
            </div>

            <div className="flex border-t border-tm-gray-300 p-3 text-right space-x-4">
                <button
                    disabled={!isDirty || areTooMuchSelected}
                    onClick={() => onApplySelected(taggedRows, labelList, selectedRows)}
                    className="btn btn-primary py-2 px-6 grow justify-center w-1/2"
                >
                    {translate("btn.apply")}
                </button>

                <button
                    disabled={!Object.values(labelList).some(item => item.checked || item.areSomeChecked)}
                    onClick={() => handleCheckboxClearAll()}
                    className="btn btn-outline py-2 px-6 grow justify-center w-1/2"
                >
                    {translate("btn.clear_all")}
                </button>
            </div>
        </div>
    );
}
