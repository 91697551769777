import React, {useEffect, useState} from "react";
import {Field} from "../../../../../data/services/fields";
import ResourceTable from "../../../../../common/components/resource-table";
import TableCard from "../../../../../common/components/resource-table/table-components/table-card";
import FieldCellMoney from "../../../../../common/components/fields/field-cell-money";
import FieldCellSelectSearch from "../../../../../common/components/fields/field-cell-select-search";
import Resources from "../../../../../data/services/resources";
import {getDefaultTableOptions} from "../../../../../common/util/util-helpers";

export default function FactoringImportTable({data, selectedRows, setSelectedRows, translate}) {

    const [mappedData, setMappedData] = useState(() => mapData(data));
    const tableOptions = getDefaultTableOptions(getFields(), {
        columns: {
            InvoiceID: {
                minWidth: 300
            }
        }
    }, "", translate)

    function handleInputChange(name, value, rowIndex) {
        const selectedRowsUpdate = Object.assign({}, selectedRows);
        const mappedDataUpdate = [...mappedData];
        mappedDataUpdate[rowIndex][name] = value;

        if (!mappedDataUpdate[rowIndex]["InvoiceID"]) { // Unselect rows with no data
            delete selectedRowsUpdate[rowIndex];
            setSelectedRows(selectedRowsUpdate);
        }

        setMappedData(mappedDataUpdate);
    }

    function handleSelectRowClick(row) {
        const selectedRowsUpdate = Object.assign({}, selectedRows);

        if (selectedRows[row.index]) {
            delete selectedRowsUpdate[row.index];
        } else {
            selectedRowsUpdate[row.index] = row;
        }

        setSelectedRows(selectedRowsUpdate);
    }

    function handleSelectAllClick(areAllSelected) {
        let selectedRows = {}

        if (!areAllSelected) {
            selectedRows = Object.values(mappedData).reduce((memo, it) => {
                if (it.InvoiceID) {
                    memo[it.index] = it;
                }

                return memo;
            }, {});
        }

        setSelectedRows(selectedRows);
    }

    function getFields() {
        return {
            InvoiceID: new Field('InvoiceID', '', [''], false, 'custom', {
                render: (item) => {
                    return <div className="w-full pr-6 h-8">
                        <FieldCellSelectSearch
                            name="InvoiceID"
                            isClearable={true}
                            select={{
                                api: 'api/' + Resources.Invoices,
                                query: {Factoring: 1},
                                searchMap: (item) => ({
                                    value: item.InvoiceID,
                                    label: `${item.CustomerReferenceNumber} - ${item.RefNumber}`
                                })
                            }}
                            value={item?.InvoiceID}
                            disabled={false}
                            onChange={(name, value) => handleInputChange(name, value, item.index)}
                            errorMessage={!item?.['InvoiceID']?.value ? ['required'] : []}
                        />
                    </div>
                },
                labelType: 'float',
                hideLabel: true
            }),

            Amount: new Field('Amount', `0`, [''], false, 'custom', {
                render: (item) => {
                    return <div className="pr-6 h-8">
                        <FieldCellMoney
                            name="Amount"
                            value={item?.['Amount']}
                            disabled={false}
                            onChange={(name, value) => handleInputChange(name, value, item.index)}
                        />
                    </div>
                },
                labelType: 'float',
                hideLabel: true
            }, {
                addClass: 'form-control text-right'
            }),
        }
    }

    function mapData(data) {
        return data.reduce((memo, it, index) => {
            memo.push({
                index: index,
                InvoiceID: {value: it?.[0]?.value?.InvoiceID, label: it?.[0]?.name},
                Amount: it?.[1]?.value
            });

            return memo;
        }, [])
    }

    useEffect(() => {
        setMappedData(mapData(data));
    }, [data]);

    return <div className="p-6">
        <TableCard>
            <ResourceTable
                tableKey={"index"}
                maxHeightClass={"max-h-[calc(100vh-23rem)]"}
                options={tableOptions}
                data={mappedData}
                fields={getFields()}
                translate={translate}
                addRowClass={(row) => {
                    return row.InvoiceID ? undefined : "bg-stripes"
                }}
                isSelectRowDisabled={(row) => {
                    return !row.InvoiceID?.value;
                }}
                onSelectRow={handleSelectRowClick}
                selectedRows={selectedRows}
                onSelectAllClick={handleSelectAllClick}
            />
        </TableCard>
    </div>
}