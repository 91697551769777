import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import React from "react";
import {getLookup} from "../../../../common/util/util-helpers";

export default function ContactAddressPreviewInfo({contactData = {}}) {
    const stateList = getLookup('State', 'StateID', "State");
    const {AddressName, AddressName2, CityName, StateID, PostalCode} = contactData;

    const hasContactData = !(!AddressName && !AddressName2 && !CityName && !StateID && !PostalCode);

    if (!hasContactData) {
        return null;
    }

    return (
        <div className="col-span-full">
            <InfoBar>

                <div>
                    <h3>{AddressName}</h3>
                    <div>{AddressName2}</div>
                    <p>{[CityName, stateList[StateID], PostalCode].filter(it => !!it).join(", ")}</p>
                </div>
            </InfoBar>
        </div>
    )
}