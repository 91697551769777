import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DEFAULT_QUERY_LIMIT
} from '../../../util/util-constants'
import {getDefaultTableOptions, getLookup, getProp, saveTableColumns} from '../../../common/util/util-helpers'
import Resources from '../../../data/services/resources'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {download} from '../../../data/actions/download'
import {currentDate} from '../../../common/util/util-dates'
import InvoiceExpenseDetailsDialog from '../dialogs/invoice-expense-details-dialog'
import {getThirdResource} from '../../../data/actions/thirdResource'
import {EyeIcon} from '@heroicons/react/24/outline'
import moment from "moment-timezone";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import NoRecords from "../../../common/components/no-records-found/no-records";
import ModalDefault from "../../../common/components/modal/modal-default";
import ResourceTable from "../../../common/components/resource-table";
import {fieldsToHtml} from "../../../common/util/util-fields";

export default class ArAging extends Component {
    constructor(props) {
        super(props)

        this.tablePageDefaults = {
            behaviour: {
                canAdjustWidth: true
            },
            columns: {}
        }

        this.pagePath = "ar-aging";

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: this.props.sortBy ?? '',

            fields: this.getFields(),
            fieldsGrouped: this.getFieldsGrouped(),
            queryFilterFields: this.getQueryFilterFields(),

            tableOptionsGrouped: getDefaultTableOptions(this.getFieldsGrouped(), this.tablePageDefaults, this.pagePath, this.props.translate),
            tableOptionsNotGrouped: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            Grouped: this.getIdField(),
            selectedItem: null,
            createModalOpen: false,
            confirmModalOpen: false
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'reports_aging_' + currentDate() + '.xlsx'
            }, this.getQuery())
        }))
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen,
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleInputChange = (name, value) => {
        let fields = this.state.Grouped
        fields = FieldsManager.updateField(fields, name, value)

        this.setState({
            Grouped: fields,
            sortBy: '',
            sort: "ASC",
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterClear = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleReceiptDialog = (item = null) => {
        this.setState({
            selectedItem: item,
            showReceiptModal: !this.state.showReceiptModal,
            receiptModalFetchKey: 'InvoiceID',
            receiptModalFetchResource: Resources.InvoicesInvoice
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }
    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input'])
    }

    getResourceName = () => {
        return Resources.AccountingReportsARAging
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            Grouped: this.isGrouped() ? 1 : 0,
        }
    }

    getIdField = () => {
        return {
            Grouped: new Field('Grouped', 1, [''], false, 'select', {
                addContainerClass: 'w-96 ml-2 z-50',
                label: 'Type'
            }, {
                values: {
                    0: "Invoice list",
                    1: "Group by customer"
                }
            })
        }
    }

    getFields = () => {
        return {
            RefNumber: new Field('RefNumber', '', [''], false, 'text', {}),
            CustomerReferenceNumber: new Field('CustomerReferenceNumber', '', [''], false, 'text', {}),
            OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {
                label: 'ChargeTo',
                render: (it) => it.Organization ?? it.Contact
            }),
            Date: new Field('Date', '', [''], false, 'date', {}),
            Age: new Field('Age', '', [''], false, 'text', {}),
            Amount: new Field('Amount', '', [''], false, 'money', {}),
            AmountTransferred: new Field('AmountTransferred', '', [''], false, 'money', {
                omitSort: true,
                render: (item) => {
                    return <div
                        className={'text-right'}>{genericMoneyFormatter(item.Amount - item.AmountTransferred)}</div>
                }
            }),
            Curr: new Field('Curr', '', [''], false, 'money', {
                omitSort: true,
            }),
            Thirty: new Field('Thirty', '', [''], false, 'money', {
                omitSort: true,
            }),
            Sixty: new Field('Sixty', '', [''], false, 'money', {
                omitSort: true,
            }),
            Ninety: new Field('Ninety', '', [''], false, 'money', {
                omitSort: true,
            }),
        }
    }

    getFieldsGrouped = () => {
        return {
            OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {
                label: 'ChargeTo',
                omitSort: true,
                render: (it) => it.Organization ?? it.Contact
            }),
            Age: new Field('Age', '', [''], false, 'text', {}),
            Amount: new Field('Amount', '', [''], false, 'money', {}),
            AmountTransferred: new Field('AmountTransferred', '', [''], false, 'money', {
                omitSort: true,
                render: (item) => {
                    return <div
                        className={'text-right'}>{genericMoneyFormatter(item.Amount - item.AmountTransferred)}</div>
                }
            }),
            Curr: new Field('Curr', '', [''], false, 'money', {}),
            Thirty: new Field('Thirty', '', [''], false, 'money', {}),
            Sixty: new Field('Sixty', '', [''], false, 'money', {}),
            Ninety: new Field('Ninety', '', [''], false, 'money', {})
        }
    }

    getQueryFilterFields = () => {
        return {
            OrganizationID: new Field('OrganizationID', '', [], false, 'select-search', {
                addContainerClass: "min-w-[231px] max-w-full"
            }, {hasPortal: true}),
            EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), ['empty'], false, 'date', {
                labelType: 'float',
                addContainerClass: 'relative z-40'
            }, {
                isClearable: true
            }),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                containerClass: 'col-md-6 limit-field-container float-right', hideLabel: true
            }, {menuPlacement: 'top'}),
            DateType: new Field('DateType', 1, [''], false, 'button-group', {
                    data: {
                        1: this.props.translate('text.Date'),
                        2: this.props.translate('text.PostingDate')
                    },
                    labelType: 'stack',
                    addClass: 'w-full',
                    addButtonClass: 'uppercase font-semibold',
                    addContainerClass: 'col-span-full',
                    label: 'DateType_Date/PostingDate/DueDate'
                }
            ),
        }
    }

    isGrouped = () => {
        return this.state?.Grouped?.Grouped?.value == 1;
    }

    /** Render
     ================================================================= */
    render() {
        const {
            translate,
        } = this.props

        const data = getProp(this.props, 'resource.data.list', [])
        const isLoading = getProp(this.props, 'resource.isLoading', false)

        const idField = fieldsToHtml(Object.values(Object.assign({}, this.state.Grouped)), translate, this.handleInputChange, {})

        let amount = 0;
        let balance = 0;
        let curr = 0;
        let thirty = 0;
        let sixty = 0;
        let ninety = 0;


        data.forEach(it => {
            amount = amount + it.Amount;
            balance = balance + it.AmountTransferred;
            curr = curr + it.Curr;
            thirty = thirty + it.Thirty;
            sixty = sixty + it.Sixty;
            ninety = ninety + it.Ninety;
        });

        return (
            <React.Fragment>
                <div className="flex mb-4">

                    <div className={'flex justify-between items-center'}>
                        {idField}
                    </div>

                    <div className="flex items-end ml-auto">
                        <Tippy content={translate('text.download_excel')}>
                            <button
                                className="btn-icon"
                                onClick={this.downloadExcel}
                            >
                                <DocumentArrowDownIcon className="w-5 h-5"/>
                            </button>
                        </Tippy>
                    </div>
                </div>

                <div className={'m-2'}>
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleFilterClear}
                        translate={translate}
                    />
                </div>

                <TableCard>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        selects={{
                            OrganizationID: {
                                api: 'api/' + Resources.OrganizationsQuick,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    label: item.LegalName + " - " + (item.AddressName ?? "")
                                        + ", " + (item.CityName ?? "")
                                        + " " + (getLookup('State')[item.StateID] ?? "") + " "
                                        + (item.PostalCode ?? ""),
                                    value: item.OrganizationID
                                })
                            }
                        }}
                        translate={translate}
                        largeGrid
                        forceDialog
                        onRefreshTable={this.fetchData}
                    />

                    {!!this.isGrouped() && (
                        <ResourceTable
                            data={data}
                            fields={this.getFieldsGrouped()}

                            translate={translate}
                            isLoading={isLoading}

                            options={this.state.tableOptionsGrouped}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}
                            tfoot={{
                                Amount: amount,
                                AmountTransferred: balance,
                                Curr: curr,
                                Thirty: thirty,
                                Sixty: sixty,
                                Ninety: ninety
                            }}
                        />
                    )}

                    {!this.isGrouped() && (
                        <ResourceTable
                            data={data}
                            fields={this.getFields()}

                            translate={translate}
                            isLoading={isLoading}

                            options={this.state.tableOptionsNotGrouped}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={this.handleToggleReceiptDialog}
                            actions={[
                                {
                                    title: () => translate('btn.view'),
                                    visible: (it) => !!it.InvoiceID,
                                    action: this.handleToggleReceiptDialog,
                                    icon: () => EyeIcon
                                }
                            ]}
                            tfoot={{
                                Amount: amount,
                                AmountTransferred: balance,
                                Curr: curr,
                                Thirty: thirty,
                                Sixty: sixty,
                                Ninety: ninety
                            }}
                        />
                    )}

                    {/*Table footer*/}
                    <NoRecords
                        show={(data.length === 0) && !isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                    {/*
                    <TableFooter
                        show={!(!data.length && !isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableFooter>
                    */}
                    <div
                        className='bg-tm-gray-200 px-5 flex items-center justify-between h-2 rounded-card md:rounded-t-none'
                    />
                </TableCard>

                <ModalDefault
                    show={this.state.showReceiptModal}
                    widthClass={'max-w-5xl w-screen'}
                    title={this.state.receiptModalFetchKey ? translate('text.' + this.state.receiptModalFetchKey) : ''}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleReceiptDialog()}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={this.props.thirdResource}
                        isLoading={this.props.thirdResource.isLoading}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        showLoadReferenceLinks={true}

                        disableComments={true}
                        canDeletePayment={false}
                        disableAddReference={true}
                        disableTransactionImport={true}

                        // Data events
                        onFetchData={() => {
                            if (this.state.selectedItem) {
                                this.props.dispatch(getThirdResource({
                                    user: LocalStorage.get('user'),
                                    query: {
                                        [this.state.receiptModalFetchKey]: this.state.selectedItem.ReferenceID ?? this.state.selectedItem[this.state.receiptModalFetchKey]
                                    },
                                    resource: this.state.receiptModalFetchResource
                                }))
                            }
                        }}
                    />
                </ModalDefault>
            </React.Fragment>
        )
    }
}
