import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import {classNames} from "../../../../../common/util/util-helpers";
import Subtitle from "../../../../../common/components/layout/layout-components/page/subtitle";
import TableCard from "../../../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../../../common/components/resource-table";
import NoRecordsTable from "../../../../../common/components/no-records-found/no-records-table";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Field} from "../../../../../data/services/fields";

export default function TruckFuelCards({translate}) {
    const [isSectionExpanded, setIsSectionExpanded] = useState(true);
    const resource = useSelector(state => state.secondResource);
    const data = resource?.data ?? [];
    const fuelCards = data.FuelCards ?? [] ;
    const isLoading = resource.isLoading;
    const count = resource?.data?.count ?? 0; // TODO

    function getFuelCardsFields() {
        return {
            FuelCard: new Field('FuelCard', '', [''], false, 'text'),
            Description: new Field('Description', '', [''], false, 'text')
        }
    }

    return <>
        <div className="space-y-4">
            <div className="flex justify-between mb-5">
                <div className="flex items-center">
                    <button
                        className="btn btn-icon text-tm-gray-500 hover:text-tm-gray-700 w-8 h-8 -ml-2 md mr-2"
                        onClick={() => setIsSectionExpanded(!isSectionExpanded)}
                    >
                        <ChevronRightIcon
                            className={
                                classNames(
                                    'w-5 h-5',
                                    isSectionExpanded ? 'rotate-90' : undefined
                                )
                            }
                        />
                    </button>

                    <Subtitle subtitle={translate('text.fuel_cards')}/>
                </div>
            </div>
        </div>

        {isSectionExpanded && (
            <div>
                <TableCard addClass={"mb-5"}>
                    <ResourceTable
                        data={fuelCards ?? []}

                        fields={getFuelCardsFields()}
                        translate={translate}
                        isLoading={isLoading}
                    />

                    <NoRecordsTable
                        show={(fuelCards.length === 0) && !isLoading}
                        canCreate={false}
                        title={translate('text.no_matching_records')}
                        className={'pt-16 px-6 mb-5'}
                    />
                </TableCard>
            </div>
        )}
    </>
}