import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../../data/services/resources'
import InfoTab from './infoTab'
import {showGlobalModal} from '../../../data/actions/ui'
import LocalStorage from '../../../util/localStorage'
import {getCountTaskResource} from '../../../data/actions/taskResource'
import {Field} from '../../../data/services/fields'
import {CREATE_PERM, DELETE_PERM, READ_PERM, REFERENCE_TYPE_TRAILER, UPDATE_PERM} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDocumentTypesFor,
    getProp,
    getTabSettings,
    resourceIsUpdated
} from '../../../common/util/util-helpers'
import TrailerTypeTab from "../trailer/trailer-type-tab";
import {ArchiveBoxIcon} from "@heroicons/react/20/solid";
import Page from "../../../common/components/layout/layout-components/page";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import Layout from "../../../common/components/layout";
import * as PropTypes from "prop-types";
import NavResponsive from "../../../common/components/nav-responsive";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import DocumentsUpdateTab from "../../../common/components/tabs/documents-tab/update-documents";
import LocationsTab from "../../../common/components/modal/locations-tab";
import LoadsTab from "../../../common/components/modal/load-tab";
import ExpiryItemsTab from "../../../common/components/tabs/expiry-items-tab";
import ResourceTasksDialog from "../../../common/components/modal/tasksDialogs/resourceTasksDialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalDefault from "../../../common/components/modal/modal-default";
import ServicesTab from "../truck/services-tab";
import {getInfoResource} from "../../../data/actions/infoResource";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import {
    TRUCK_STATUS_AVAILABLE,
    TRUCK_STATUS_BOOKED,
    TRUCK_STATUS_ON_LOAD,
    TRUCK_STATUS_OUT_OF_SERVICE,
    TRUCK_STATUS_RETIRED,
    TRUCK_STATUS_SOLD,
    TRUCK_STATUS_TYPE_TIPPY_TEXT,
    TRUCK_STATUS_TYPES
} from "../../../common/util/util-consts";
import Badge from "../../../common/components/badge";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import {LoaderSmall} from "../../../common/components/loader";
import TrucksTableBadge from "../trucks-view/trucks-table-badge";
import UpcomingMaintenanceTab from "../maintenance-view/upcomingMaintenanceTab";

function PageResourceTag(props) {
    return null;
}

PageResourceTag.propTypes = {children: PropTypes.node};

class TrailerView extends Component {

    constructor(props) {
        super(props)

        let tabsSettings = getTabSettings('Fleet', 'Trailers');

        const tabs = [
            {
                name: 'Trailer',
                resource: Resources.Trailer,
                current: true,
                visible: checkPerm(Resources.Trailer, READ_PERM)
            },
            {
                name: 'TrailerType',
                resource: Resources.TrailersTypes,
                current: false,
                visible: checkPerm(Resources.TrailersTypes, READ_PERM)
            },
            {
                name: 'TrailersLocations',
                resource: Resources.TrailersLocations,
                current: false,
                visible: checkPerm(Resources.TrailersLocations, READ_PERM)
            },
            {
                name: 'Upcoming Maintenance',
                resource: Resources.WorkOrderUpcomingMaintenance,
                current: false,
                visible: checkPerm(Resources.WorkOrderUpcomingMaintenance, READ_PERM)
            },
            {
                name: 'TrailersLoads',
                resource: Resources.TrailersLoads,
                current: false,
                visible: checkPerm(Resources.TrailersLoads, READ_PERM)
            },
            {
                name: 'TrailersService',
                resource: Resources.TrailersService,
                current: false,
                visible: checkPerm(Resources.TrailersService, READ_PERM)
            },
            {
                name: 'ExpiryItems',
                resource: Resources.TrailersExpiry,
                current: false,
                visible: checkPerm(Resources.TrailersExpiry, READ_PERM)
            },
            {
                name: 'TrailerDocuments',
                resource: Resources.TrailerDocuments,
                current: false,
                visible: checkPerm(Resources.TrailerDocuments, READ_PERM)
            },
        ].map(it => {
            if (!!tabsSettings && it.visible !== false) {
                it.visible = tabsSettings?.[it.name]?.isVisible !== false;
            }

            return it;
        })

        this.state = {
            // Tabs
            tabs: tabs,
            selectedTab: Resources.Trailer,

            canSwapTabs: true,

            // Modals
            resourceTaskModalOpen: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        if (this.getID()) {
            this.handleCheckIfRedirected()
            this.fetchTaskResourceCount()
            this.fetchData()
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resource !== this.props.resource && this.state.selectedTab === Resources.Trailer) {
            this.setState({
                TrailerNumber: getProp(this.props.resource, 'data.TrailerNumber', ''),
                ArchivedDate: getProp(this.props.resource, 'data.ArchivedDate', null),
            })
        }

        if (resourceIsUpdated(this.props.dialogResource, prevProps.dialogResource) && this.props.dialogResource.resource === Resources.TrailersColor) {
            this.fetchData();
        }

        if (resourceIsUpdated(this.props.resource, prevProps.resource) && this.props.resource.resource === Resources.TrailerRetire) {
            this.fetchData();
        }
    }

    /** Data Events
     ================================================================= */
    fetchTaskResourceCount = () => {
        this.props.dispatch(getCountTaskResource({
            user: LocalStorage.get('user'),
            resource: Resources.TaskResourceCount,
            query: {param: window.location.pathname}
        }))
    }

    fetchData = () => {
        this.props.dispatch(getInfoResource({
            user: LocalStorage.get('user'),
            resource: Resources.Trailer,
            query: {
                id: this.getID()
            }
        }))
    }

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        if (!this.state.canSwapTabs) {
            this.setState({
                confirmModal: true,
                reserveTab: resource
            })
        } else {
            this.tabChange(resource)
        }
    }

    tabChange = (resource) => {
        this.props.history.push('?tab=' + resource)
        this.setState({
            confirmModal: false,
            isDirty: false,
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    handleCreateNewResourceTask = () => {
        this.props.dispatch(showGlobalModal('createTaskDialog', {taskResource: this.props.match.url}))
    }

    handleToggleResourceTasks = () => {
        this.setState({
            resourceTaskModalOpen: !this.state.resourceTaskModalOpen
        })
    }

    handleCheckIfRedirected = () => {
        const query = new URLSearchParams(this.props.location.search)
        const tab = query.get('tab')
        if (tab) {
            this.handleTabChange(tab)
        }
    }

    /** Helpers
     ================================================================= */
    canSwapTabsHandler = (canSwapTabs = false) => {
        this.setState({canSwapTabs: canSwapTabs})
    }

    getID = () => {
        return this.props.match.params.id
    }

    getTrailerStatusBadgeClass = (type) => {
        switch (type) {
            case TRUCK_STATUS_AVAILABLE:
                return "success"
            case TRUCK_STATUS_BOOKED:
            case TRUCK_STATUS_ON_LOAD:
                return "primary"
            case TRUCK_STATUS_OUT_OF_SERVICE:
                return "warning"
            case TRUCK_STATUS_SOLD:
            case TRUCK_STATUS_RETIRED:
                return "danger"
            default:
                return "danger"
        }
    }
    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props
        const TaskCount = getProp(this.props.taskResource, 'count.count', 0)
        const openTaskCountTwoDigits = TaskCount > 9;
        const ColorTags = getProp(this.props.info, 'data.ColorTag', "")
        const infoData = getProp(this.props.info, 'data', {})
        const isInfoLoading = getProp(this.props.info, 'isLoading', true);
        const trailerNumber = getProp(infoData, 'TrailerNumber', 'Trailer')

        return (
            <Layout
                {...this.props}
                hasFooter={this.state.selectedTab === Resources.Trailer}
                isDirty={!this.state.canSwapTabs}
                customPageTitle={trailerNumber || 'Trailer'}
            >
                <Page>
                    <div className="mb-2 flex justify-between items-center">
                        <div className="flex items-center justify-center">
                            <GoBackButton
                                translate={this.props.translate}
                                history={this.props.history}
                                path={'/trailers'}
                            />

                            <div className={'mr-5 text-3xl ml-2'}>
                                {translate('text.trailer')}

                                <span
                                    className="ml-2 inline-flex items-center gap-2 text-tm-gray-500"> - {this.state.TrailerNumber}

                                    {this.state.ArchivedDate && (
                                        <PageResourceTag>
                                            <ArchiveBoxIcon className="w-5 h-5"/>
                                            {translate("text.item_archived")}
                                        </PageResourceTag>
                                    )}
                                </span>
                            </div>

                            {ColorTags && (
                                <div>
                                    <ResourceTableTags data={ColorTags} addClass="py-1.5 mx-2"/>
                                </div>
                            )}

                            {!isInfoLoading ? (
                                <TrucksTableBadge
                                    addClass={"h-8 items-center"}
                                    itemType="trailer"
                                    item={infoData}
                                    translate={this.props.translate}
                                />
                            ) : (
                                <LoaderSmall disableContainer={true}/>
                            )}

                        </div>

                        <div className="gap-2 flex flex-wrap items-center justify-end">
                            {checkPerm(Resources.Tasks, CREATE_PERM) && (
                                <div className="btn btn-outline mr-3"
                                     onClick={() => this.handleCreateNewResourceTask()}>{translate('btn.new_trailer_task')}
                                </div>
                            )}

                            {checkPerm(Resources.Tasks, READ_PERM) && (
                                <div className="relative">
                                    <button
                                        className="btn btn-outline"
                                        disabled={!checkPerm(Resources.TaskResource, READ_PERM)}
                                        onClick={() => this.handleToggleResourceTasks()}>

                                        {TaskCount > 0 && (
                                            <span
                                                className={
                                                    classNames(
                                                        "absolute z-10 flex -top-2.5 -left-2",
                                                        openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                    )
                                                }
                                            >
                                                          <div
                                                              className="absolute z-10 font-bold inset-0 flex items-center justify-center text-primary-contrast">{[TaskCount]}</div>
                                                          <span
                                                              className="animate-ping absolute z-10 inline-flex h-full w-full rounded-full bg-primary opacity-25"></span>
                                                          <span
                                                              className={
                                                                  classNames(
                                                                      "relative inline-flex rounded-full bg-primary",
                                                                      openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                                  )
                                                              }
                                                          />
                                                    </span>
                                        )}
                                        {translate('btn.show_tasks')}
                                        {this.props.taskResource.isCountLoading && `(${translate('text.Loading...')})`}
                                        {!this.props.taskResource.isCountLoading && TaskCount === 0 && `(${TaskCount})`}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mb-5 hidden sm:block">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="block sm:hidden">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    {this.state.selectedTab === Resources.Trailer && (
                        <InfoTab
                            setActiveTab={this.handleTabChange}
                            canSwapTabsHandler={this.canSwapTabsHandler}
                            {...this.props}
                        />
                    )}

                    {this.state.selectedTab === Resources.TrailerDocuments && (
                        <DocumentsUpdateTab
                            id={this.getID()}
                            dispatch={this.props.dispatch}
                            translate={this.props.translate}
                            resource={this.props.resource}
                            thirdResource={this.props.thirdResource}

                            dialogTitle={'TrailerDocuments'}

                            sortBy={'Description'}
                            primaryKey={'TrailerDocumentID'}
                            download={this.props.download}
                            resourceName={Resources.TrailerDocuments}

                            documentUpdateFields={checkPerm(Resources.TrailerDocuments, UPDATE_PERM) && {
                                DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}, {
                                    values: getDocumentTypesFor("IsFleetDocument")
                                }),
                                ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {addContainerClass: 'col-span-full'}, {isClearable: true}),
                                Description: new Field("Description", '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
                            }}
                            documentTypes={getDocumentTypesFor("IsFleetDocument")}
                            searchFields={{
                                query: new Field('query', '', [], false, 'text', {
                                    containerClass: 'col-md-4'
                                }),
                                DocumentTypeID: new Field('DocumentTypeID', '', [], false, 'select', {
                                    containerClass: 'col-md-4'
                                }, {
                                    all: true,
                                    values: getDocumentTypesFor("IsFleetDocument")
                                }),
                                DocumentExtension: new Field('DocumentExtension', '', [], false, 'select', {
                                    containerClass: 'col-md-4'
                                }, {
                                    all: true
                                }),
                            }}
                            readOnly={!checkPerm(Resources.TrailerDocuments, CREATE_PERM)}
                            disableDelete={!checkPerm(Resources.TrailerDocuments, DELETE_PERM)}
                        />
                    )}

                    {this.state.selectedTab === Resources.TrailersTypes && (
                        <TrailerTypeTab
                            id={this.getID()}
                            dispatch={this.props.dispatch}
                            translate={this.props.translate}
                            resource={this.props.resource}

                            dialogTitle={translate('modal_heading.TrailersType')}

                            sortBy={'TrailerTypeID'}
                            primaryKey={'TrailerTypeItemID'}
                            resourceName={Resources.TrailersTypes}

                            fields={{
                                TrailerTypeID: new Field('TrailerTypeID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
                                Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}),
                            }}
                            searchFields={{
                                query: new Field('query', '', [''], false, 'search', {
                                    containerClass: 'col-md-4'
                                })
                            }}
                        />
                    )}

                    {this.state.selectedTab === Resources.WorkOrderUpcomingMaintenance && (
                        <UpcomingMaintenanceTab
                            TrailerID={this.getID()}
                            {...this.props}/>
                    )}

                    {this.state.selectedTab === Resources.TrailersLocations && (
                        <LocationsTab
                            {...this.props}
                            resourcePath={Resources.TrailersLocations}
                            primaryKey={'TrailerLocationID'}
                        />
                    )}

                    {this.state.selectedTab === Resources.TrailersLoads && (
                        <LoadsTab
                            tabResource={Resources.TrailersLoads}
                            id={this.getID()}
                            tabKey={'id'}
                            resource={this.props.resource}
                            dispatch={this.props.dispatch}
                            history={this.props.history}
                            translate={this.props.translate}
                        />
                    )}

                    {this.state.selectedTab === Resources.TrailersService && (
                        <ServicesTab
                            {...this.props}
                            tabKey={'TrailersService'}
                            disableEdit={!checkPerm(Resources.TrailersService, UPDATE_PERM)}
                            disableDelete={!checkPerm(Resources.TrailersService, DELETE_PERM)}


                            dialogTitle={translate('modal_heading.TrailersService')}

                            sortBy={'TrailerServiceID'}
                            primaryKey={'TrailerServiceID'}
                            resourceName={Resources.TrailersService}
                            TrailerID={this.getID()}
                        />
                    )}

                    {this.state.selectedTab === Resources.TrailersExpiry && (
                        <ExpiryItemsTab
                            translate={translate}
                            resourceName={Resources.TrailersExpiry}
                            Reference={getProp(this.state, 'TrailerNumber', '')}
                            ReferenceID={this.getID()}
                            ReferenceType={REFERENCE_TYPE_TRAILER}
                        />
                    )}

                    <ModalDefault
                        show={!!this.state.resourceTaskModalOpen}
                        widthClass={'max-w-3xl'}
                        title={translate('text.trailer_tasks')}

                        closeButtonLabel={translate('btn.close')}
                        onClose={this.handleToggleResourceTasks}

                        translate={translate}
                    >
                        <ResourceTasksDialog
                            {...this.props}
                            large={true}
                        />
                    </ModalDefault>

                    <ModalConfirm
                        type="default"
                        title={translate("dialog_heading.unsaved_changes_on_tab")}
                        show={!!this.state.confirmModal && this.state.selectedTab === Resources.Trailer}
                        onClose={() => this.setState({confirmModal: false})}
                        buttonLabel={translate("btn.proceed")}
                        closeButtonLabel={translate('btn.stay_on_current_tab')}
                        translate={translate}
                        onConfirm={() => this.setState({
                            selectedTab: this.state.reserveTab,
                            confirmModal: false,
                            canSwapTabs: true,
                            tabs: this.state.tabs.map((it) => {
                                it.current = it.resource === this.state.reserveTab
                                return it
                            })
                        })}
                    >
                        <p>{translate("text.unsaved_changes_on_tab")}</p>
                        <p>{translate("text.unsaved_changes_on_tab_line_2")}</p>
                    </ModalConfirm>
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(TrailerView)
