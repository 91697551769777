import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {DEFAULT_CRUD_STATE, DEFAULT_QUERY_LIMIT, READ_PERM} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getProp,
    onDocumentPrint,
    saveTableColumns
} from '../../../common/util/util-helpers'
import Resources from '../../../data/services/resources'
import {showModal} from "../../../data/actions/ui";
import moment from "moment";
import {cloneDeep, genericMoneyFormatter} from "../../../common/util/util-vanilla";
import Tippy from "@tippyjs/react";
import {
    ArrowPathIcon,
    ArrowSmallDownIcon,
    ArrowSmallLeftIcon,
    ArrowSmallRightIcon,
    ArrowSmallUpIcon,
    PrinterIcon
} from "@heroicons/react/24/outline";
import Env from "../../../util/env";
import {download} from "../../../data/actions/download";
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {currentDate} from '../../../common/util/util-dates'
import FileViewer from "../../../common/components/file-viewer/components"
import Tooltip from "../../../common/components/tooltip";
import ModalDefault from "../../../common/components/modal/modal-default";
import ResourceTable from "../../../common/components/resource-table";
import OrganizationInfoDialog from "../../../common/components/modal/modal-global/organization-info-dialog";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecords from "../../../common/components/no-records-found/no-records";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import {fieldsToHtml} from "../../../common/util/util-fields";
import {getJWT} from "../../../common/util/util-auth";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";

export default class Accounting1099ReportsView extends Component {
    constructor(props) {
        super(props)

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
            },
            columns: {}
        }

        this.pagePath = "reports-1099"

        this.state = {
            ...DEFAULT_CRUD_STATE,
            fields: this.getFields(),
            queryFilterFields: this.getTableQueryFilterFields(),
            mainQueryFilterFields: this.getMainQueryFilterFields(),
            offsetFields: this.getOffsetFields(),
            previewKey: 1,
            previewParams: {},

            selectedRows: {},

            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate)
        }

        this.intervalRef = React.createRef();
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    }

    onDownloadDocument = () => {
        this.props.dispatch(download({
            resource: Resources.Accounting1099ReportsPDF,
            query: {
                ...this.state.previewParams,
                SelectedEntries: Object.values(this.state.selectedRows).map(it => it[this.getPrimaryKey()]),
                name: "1099 Report.pdf",
                token: getJWT().access_token,
                Year: FieldsManager.getFieldKeyValues(this.state.mainQueryFilterFields).Year
            }
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.Accounting1099Reports,
            query: Object.assign({
                format: 'EXCEL',
                name: 'report_1099' + currentDate() + '.xlsx'
            }, this.getQuery())
        }))
    }

    /** UI Events
     ================================================================= */
    handleSelectRowClick = (item, event) => {
        const itemID = item[this.getPrimaryKey()];

        const data = getProp(this.props.resource.data, 'list', [])
        let selectedRows = cloneDeep(this.state.selectedRows)
        if (event?.nativeEvent?.shiftKey) {
            const firstSelectedItem = data.findIndex(it => selectedRows[it[this.getPrimaryKey()]])
            const lastSelectedItem = data.findIndex(it => it[this.getPrimaryKey()] === itemID)

            if (firstSelectedItem > -1 && lastSelectedItem > -1) {
                if (firstSelectedItem < lastSelectedItem) {
                    for (let i = firstSelectedItem; i < lastSelectedItem + 1; i++) {
                        Object.assign(selectedRows, {[data[i][this.getPrimaryKey()]]: data[i]})
                    }
                } else {
                    for (let i = lastSelectedItem; i < firstSelectedItem + 1; i++) {
                        Object.assign(selectedRows, {[data[i][this.getPrimaryKey()]]: data[i]})
                    }
                }
            }
        } else {
            if (selectedRows[itemID]) {
                delete selectedRows[itemID]
            } else {
                Object.assign(selectedRows, {[itemID]: item})
            }
        }
        this.setState({selectedRows})
    }

    handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(this.props.resource.data, 'list', []))

        let selectedRows = cloneDeep(this.state.selectedRows)

        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                memo[it[this.getPrimaryKey()]] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it[this.getPrimaryKey()].toString())) {
                    delete selectedRows[it[this.getPrimaryKey()]]
                }
            })
        }

        this.setState({selectedRows})
    }

    handleToggleBulkPrintPreview = () => {
        this.setState({isBulkPrintPreviewDialogOpen: !this.state.isBulkPrintPreviewDialogOpen})
    }

    handleToggleBulkPdfPreview = () => {
        this.setState({isBulkPdfPreviewDialogOpen: !this.state.isBulkPdfPreviewDialogOpen})
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleMainQueryInputChange = (name, value) => {
        this.setState({
            mainQueryFilterFields: FieldsManager.updateField(this.state.mainQueryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterClear = () => {
        this.setState({
            queryFilterFields: this.getTableQueryFilterFields(),
        }, () => {
            this.fetchData()
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleQuickContactView = (item) => {
        item.ContactID = item.PayID
        this.props.dispatch(showModal('ViewContactCard', item))
    }

    handleToggleOrganizationInfoDialog = (item = {}) => {
        item.OrganizationID = item.PayID
        this.setState({
            selectedOrganization: item,
            isOrganizationInfoDialogOpen: true
        })
    }

    handleOffsetInputChange = (name) => {
        let value = (+this.state.offsetFields[name].value) + 1
        let fieldsUpdate = FieldsManager.updateField(this.state.offsetFields, name, value)
        if (name === 'OffsetDown') {
            fieldsUpdate = FieldsManager.updateField(this.state.offsetFields, 'OffsetUp', -value)
        }
        if (name === 'OffsetUp') {
            fieldsUpdate = FieldsManager.updateField(this.state.offsetFields, 'OffsetDown', -value)
        }
        if (name === 'OffsetRight') {
            fieldsUpdate = FieldsManager.updateField(this.state.offsetFields, 'OffsetLeft', -value)
        }
        if (name === 'OffsetLeft') {
            fieldsUpdate = FieldsManager.updateField(this.state.offsetFields, 'OffsetRight', -value)
        }

        fieldsUpdate[name].errorMessage = ''

        this.setState({
            offsetFields: fieldsUpdate,
            isOffsetStateDirty: true
        })
    }

    handleOffsetButtonHoldStart = (name) => {
        if (this.intervalRef.current) return
        this.intervalRef.current = setInterval(() => {
            this.handleOffsetInputChange(name)
        }, 50)
    }

    handleOffsetButtonHoldStop = () => {
        if (this.intervalRef.current) {
            clearInterval(this.intervalRef.current)
            this.intervalRef.current = null
        }
    }

    handleOffsetSubmitClick = () => {
        let areOffsetsValid = true
        let fields = Object.values(this.state.offsetFields).map(it => {
            if (isNaN(it.value)) {
                it.errorMessage = ['error.value_isNaN']
                areOffsetsValid = false
            }
            return it
        })

        fields = fields.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        if (!areOffsetsValid) {
            this.setState({
                offsetFields: fields,
                isOffsetStateDirty: false
            })
        } else {
            this.submitOffset()
        }
    }

    submitOffset = () => {
        const {OffsetDown, OffsetRight} = FieldsManager.getFieldKeyValues(this.state.offsetFields)
        const params = Object.assign(
            this.state.previewParams,
            {
                OffsetX: OffsetRight,
                OffsetY: OffsetDown,
            }
        )

        this.setState({
            previewParams: params,
            previewKey: this.state.previewKey + 1,
            isOffsetStateDirty: false
        })
    }

    handlePreviewPrintClick = () => {
        const params = this.state.printChequeParams

        if (!!params && !!Object.keys(params).length) {
            if (!this.state.isPreviewVisible) {
                this.setState({
                    isPreviewVisible: true,
                    previewParams: params,
                    isOffsetStateDirty: false
                })
            } else {
                let params = this.state.previewParams

                onDocumentPrint(Env.getApiUrl('api/' + Resources.Accounting1099ReportsPDF, Object.assign({}, {
                    SelectedEntries: Object.values(this.state.selectedRows).map(it => it[this.getPrimaryKey()]),
                    ...params,
                    name: "1099 Report.pdf",
                    Year: FieldsManager.getFieldKeyValues(this.state.mainQueryFilterFields).Year
                })), 'pdf')

                this.setState({
                    isConfirmCheckPrintVisible: true
                })
            }
        }
    }

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input'])
    }

    getResourceName = () => {
        return Resources.Accounting1099Reports
    }

    getPrimaryKey = () => {
        return 'ReportID'
    }

    getQuery = () => {
        return {
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            ...FieldsManager.getFieldKeyValues(this.state.mainQueryFilterFields),
        }
    }

    getFields = () => {
        return {
            ContactID: new Field('ContactID', '', [''], false, 'custom', {
                render: (item) =>
                    <CellButton
                        onClick={() => checkPerm(Resources.Contacts, READ_PERM) ? this.handleQuickContactView(item) : null}>
                        <div>
                            {item.Type === 'Contact' ? item.Pay : ''}
                        </div>
                    </CellButton>
            }),
            OrganizationID: new Field('OrganizationID', '', [''], false, 'custom', {
                render: (item) =>
                    <CellButton
                        onClick={() => checkPerm(Resources.Organizations, READ_PERM) ? this.handleToggleOrganizationInfoDialog(item) : null}>
                        <div>
                            {item.Type === 'Organization' ? item.Pay : ''}
                        </div>
                    </CellButton>
            }),
            Amount: new Field('Amount', '', [''], false, 'float', {}),
        }
    }

    getTableQueryFilterFields = () => {
        return {
            OrganizationID: new Field('OrganizationID', '', [], false, 'select-search', {
                addContainerClass: "min-w-[231px] max-w-full"
            }, {isClearable: true}),
            ContactID: new Field('ContactID', '', [], false, 'select-search', {
                addContainerClass: "min-w-[231px] max-w-full"
            }, {isClearable: true})
        }
    }

    getMainQueryFilterFields = () => {
        const numOfYears = 10

        const years = [...Array(numOfYears).keys()].reduce((memo, it) => {
            const year = moment().startOf('year').add(-it, 'year').format("YYYY")
            let yearLabel = year
            if (it === 0) {
                yearLabel = 'Current Year'
            } else if (it === 1) {
                yearLabel = 'Previous Year'
            }
            memo[year] = yearLabel
            return memo
        }, {})

        return {
            Year: new Field('Year', moment().startOf('year').format("YYYY"), [''], false, 'select', {
                addContainerClass: 'w-96 ml-2',
            }, {
                values: years,
                reverseKeySort: true,
                hasPortal: true
            })
        }
    }

    getOffsetFields = () => {
        return {
            OffsetDown: new Field('OffsetDown', 0, [''], false, 'integer'),
            OffsetUp: new Field('OffsetUp', 0, [''], false, 'integer'),
            OffsetLeft: new Field('OffsetLeft', 0, [''], false, 'integer'),
            OffsetRight: new Field('OffsetRight', 0, [''], false, 'integer'),
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props

        const data = getProp(this.props, 'resource.data.list', [])
        const count = getProp(this.props, 'resource.data.count', 0)

        const isLoading = getProp(this.props, 'resource.isLoading', false)

        const mainQueryField = fieldsToHtml(Object.values(Object.assign({}, this.state.mainQueryFilterFields)), translate, this.handleMainQueryInputChange, {})
        const hasSelectedRows = !!Object.keys(this.state.selectedRows).length
        const totalAmount = genericMoneyFormatter(Object.values(this.state.selectedRows).reduce((memo, it) => memo + it.Amount, 0))

        const {
            OffsetDown,
            OffsetUp,
            OffsetLeft,
            OffsetRight
        } = FieldsManager.getFieldKeyValues(this.state.offsetFields)

        return (
            <React.Fragment>
                <div className={'flex justify-between items-center'}>
                    {mainQueryField}

                    <div className="flex items-end ml-auto">
                        <Tippy content={translate('text.download_excel')}>
                            <button
                                className="btn-icon"
                                onClick={this.downloadExcel}
                            >
                                <DocumentArrowDownIcon className="w-5 h-5"/>
                            </button>
                        </Tippy>
                    </div>
                </div>

                <div className={'m-2'}>
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleFilterClear}
                        translate={translate}
                    />
                </div>

                <TableCard>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                    />

                    <ResourceTable
                        data={data}
                        commonTable={true}
                        fields={this.getFields()}

                        translate={translate}
                        isLoading={isLoading}

                        options={this.state.tableOptions}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}

                        onSelectRow={this.handleSelectRowClick}
                        selectedRows={this.state.selectedRows}
                        onSelectAllClick={this.handleSelectAllClick}
                        tableKey={this.getPrimaryKey()}
                    />

                    {/*Table footer*/}
                    <NoRecords
                        show={(data.length === 0) && !isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />

                    <TableBulkActions
                        selectedRows={this.state.selectedRows}
                        tableKey={this.getPrimaryKey()}
                        fields={this.getFields()}
                        translate={translate}
                        options={this.state.tableOptions}
                        setSelectedRows={(rows) => this.setState({selectedRows: rows})}
                        onSelectAllClick={this.handleSelectAllClick}
                        footerContent={
                            <div className="text-sm flex justify-between w-full px-4 py-1 h-8">
                                <div className="whitespace-nowrap">
                                    Total amount for selected entries:
                                    <span className="ml-2 font-bold text-primary-contrast">
                                        {totalAmount}
                                    </span>
                                </div>
                            </div>
                        }
                    >
                        <Tippy content={translate('btn.bulk_print')}>
                            <button
                                onClick={this.handleToggleBulkPrintPreview}
                                disabled={!hasSelectedRows}
                                className={classNames(hasSelectedRows ? 'hover:bg-primary-shade' : undefined, 'icon-btn p-2 flex items-center')}
                            >
                                <PrinterIcon
                                    className={classNames(hasSelectedRows ? 'text-primary-contrast' : 'text-tm-gray-400', 'w-5 h-5')}/>
                            </button>
                        </Tippy>
                    </TableBulkActions>
                </TableCard>

                {(this.state.isOrganizationInfoDialogOpen &&
                    <OrganizationInfoDialog
                        show={this.state.isOrganizationInfoDialogOpen}
                        translate={translate}
                        organizationID={this.state.selectedOrganization.OrganizationID}
                        handleClose={() => this.setState({
                            isOrganizationInfoDialogOpen: false
                        })}
                    />
                )}

                <ModalDefault
                    show={this.state.isBulkPrintPreviewDialogOpen}
                    widthClass={'max-w-3xl'}
                    title={translate('text.preview_report_print')}

                    buttonLabel={translate('btn.confirm')}
                    onButtonClick={this.handleToggleBulkPdfPreview}
                    closeButtonLabel={translate('btn.Close')}

                    onClose={this.handleToggleBulkPrintPreview}
                >
                    <ResourceTable
                        data={Object.values(this.state.selectedRows)}
                        commonTable={true}
                        fields={this.getFields()}
                        options={{
                            style: {
                                verticalLines: true,
                                horizontalLines: true
                            },
                        }}
                        translate={translate}
                    />
                </ModalDefault>

                <ModalDefault
                    show={this.state.isBulkPdfPreviewDialogOpen}
                    widthClass={'max-w-7xl'}
                    title={"Document preview"}
                    limitHeight={true}

                    buttonLabel={translate('btn.download')}
                    onButtonClick={this.onDownloadDocument}
                    closeButtonLabel={translate('btn.Close')}

                    onClose={this.handleToggleBulkPdfPreview}
                >
                    {this.state.isPDFOffsetFormVisible && (
                        <div
                            className="bg-tm-gray-50 border-b border-tm-gray-300 px-6 pt-1.5 pb-3 flex gap-x-4">
                            <div>
                                <div className="grid grid-cols-5 gap-1">
                                    {/* ROW 1 */}
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5'}/>
                                    <div
                                        className={'w-5 h-5 flex items-center justify-center font-bold'}>
                                        {+OffsetUp}
                                    </div>
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5'}/>

                                    {/* ROW 2 */}
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5 flex items-center justify-center'}>
                                        <button
                                            className="btn btn-icon p-0 text-tm-gray-600 hover:text-tm-gray-900 focus:outline-none"
                                            onMouseDown={() => this.handleOffsetButtonHoldStart('OffsetUp')}
                                            onMouseUp={this.handleOffsetButtonHoldStop}
                                            onMouseLeave={this.handleOffsetButtonHoldStop}
                                        >
                                            <ArrowSmallUpIcon className={'w-5 h-5'}/>
                                        </button>
                                    </div>
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5'}/>

                                    {/* ROW 3 */}
                                    <div
                                        className={'w-5 h-5 flex items-center justify-center font-bold'}>{+OffsetLeft}</div>
                                    <div className={'w-5 h-5 flex items-center justify-center'}>
                                        <button
                                            className="btn btn-icon p-0 text-tm-gray-600 hover:text-tm-gray-900"
                                            onMouseDown={() => this.handleOffsetButtonHoldStart('OffsetLeft')}
                                            onMouseUp={this.handleOffsetButtonHoldStop}
                                            onMouseLeave={this.handleOffsetButtonHoldStop}
                                        >
                                            <ArrowSmallLeftIcon className={'w-5 h-5'}/>
                                        </button>
                                    </div>
                                    <div className={'w-5 h-5 flex items-center justify-center'}>
                                        <button
                                            className="btn btn-icon p-0 text-tm-gray-600 hover:text-tm-gray-900"
                                            onClick={() => this.setState({offsetFields: this.getOffsetFields()})}
                                        >
                                            <ArrowPathIcon className={'w-5 h-5'}/>
                                        </button>
                                    </div>
                                    <div className={'w-5 h-5 flex items-center justify-center'}>
                                        <button
                                            className="btn btn-icon p-0 text-tm-gray-600 hover:text-tm-gray-900"
                                            onMouseDown={() => this.handleOffsetButtonHoldStart('OffsetRight')}
                                            onMouseUp={this.handleOffsetButtonHoldStop}
                                            onMouseLeave={this.handleOffsetButtonHoldStop}
                                        >
                                            <ArrowSmallRightIcon className={'w-5 h-5'}/>
                                        </button>
                                    </div>
                                    <div
                                        className={'w-5 h-5 flex items-center justify-center font-bold'}>{+OffsetRight}</div>

                                    {/* ROW 4 */}
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5 flex items-center justify-center'}>
                                        <button
                                            className="btn btn-icon p-0 text-tm-gray-600 hover:text-tm-gray-900"
                                            onMouseDown={() => this.handleOffsetButtonHoldStart('OffsetDown')}
                                            onMouseUp={this.handleOffsetButtonHoldStop}
                                            onMouseLeave={this.handleOffsetButtonHoldStop}
                                        >
                                            <ArrowSmallDownIcon className={'w-5 h-5'}/>
                                        </button>
                                    </div>
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5'}/>

                                    {/* ROW 5 */}
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5'}/>
                                    <div
                                        className={'w-5 h-5 flex items-center justify-center font-bold'}>{+OffsetDown}</div>
                                    <div className={'w-5 h-5'}/>
                                    <div className={'w-5 h-5'}/>
                                </div>
                            </div>

                            <div className="space-x-4 mt-5 ml-auto">
                                {!(this.state.isConfirmCheckPrintVisible && !this.state.isOffsetStateDirty) && (
                                    <Tooltip
                                        content="Offset is unchanged"
                                    >
                                        <div>
                                            <button
                                                className="btn btn-outline h-10"
                                                onClick={() => {
                                                    this.handleOffsetSubmitClick()
                                                }}
                                            >
                                                {translate('btn.preview_offset_changes')}
                                            </button>
                                        </div>
                                    </Tooltip>
                                )}

                                {this.state.isConfirmCheckPrintVisible && !this.state.isOffsetStateDirty && (
                                    <button
                                        className="btn btn-outline h-10"
                                        onClick={() => {
                                            this.handlePreviewPrintClick()
                                        }}
                                    >
                                        {translate('btn.print_again')}
                                    </button>
                                )}
                            </div>
                        </div>
                    )}

                    <FileViewer
                        key={this.state.previewKey}
                        onPDFLoad={() => {
                            this.setState({
                                isPDFOffsetFormVisible: true
                            })
                        }}
                        fileType={'pdf'}
                        filePath={Env.getApiUrl('api/' + Resources.Accounting1099ReportsPDF, Object.assign({}, this.state.previewParams, {
                            SelectedEntries: Object.values(this.state.selectedRows).map(it => it[this.getPrimaryKey()]),
                            Year: FieldsManager.getFieldKeyValues(this.state.mainQueryFilterFields).Year,
                            token: getJWT().access_token
                        }))}
                        onError={(e) => {
                            console.log(e)
                        }}
                    />
                </ModalDefault>

            </React.Fragment>
        )
    }
}
