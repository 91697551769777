import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, getResource, updateResource} from '../../../../data/actions/resource'
import LocalStorage from '../../../../util/localStorage'
import {Field, FieldsManager} from '../../../../data/services/fields'
import Resources from '../../../../data/services/resources'
import {
    CREATE_PERM,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    UPDATE_PERM
} from '../../../../util/util-constants'
import {checkPerm, getLookup, getProp} from '../../../../common/util/util-helpers'
import Tippy from "@tippyjs/react";
import {ArrowUpTrayIcon, InformationCircleIcon} from "@heroicons/react/24/outline";
import {MinusCircleIcon, PlusCircleIcon} from "@heroicons/react/20/solid";
import CheckIcon from "@heroicons/react/24/outline/CheckIcon";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import moment from "moment-timezone";
import {getThirdResource} from "../../../../data/actions/thirdResource";
import PageFooter from "../../../../common/components/layout/layout-components/page/page-footer";
import OrganizationInfoDialog from "../../../../common/components/modal/modal-global/organization-info-dialog";
import {excludeFields, fieldsToHtml, fillFieldsFromData, includeFields} from "../../../../common/util/util-fields";
import FieldOptions from "../../../../common/components/fields/field-options";
import {formatMoney, numberWithCommas} from "../../../../common/util/util-formaters";
import FieldDate from "../../../../common/components/fields/field-date";
import PageHeader from "../../../../common/components/layout/layout-components/page/page-header";
import Card from "../../../../common/components/card";
import {LoaderLarge} from "../../../../common/components/loader";
import ModalDefault from "../../../../common/components/modal/modal-default";
import ResourceTable from "../../../../common/components/resource-table";
import DataCard from "../../../../common/components/display-data/data-card";
import CardTitle from "../../../../common/components/card/card-title";
import InfoParagraph from "../../../../common/components/info-paragraph";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import ExclamationCircleIcon from "@heroicons/react/20/solid/ExclamationCircleIcon";
import {emailValid} from "../../../../common/util/util-vanilla";

class TruckPayrollSetup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            importTemplateFields: this.getImportTemplateFields(),
            canSubmit: false,
            id: null,
            deductionData: {},
            visible: true,
            templateName: false,
            // Modals
            importFromTemplateModalOpen: false,
            isOrganizationDialogOpen: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.getID() && this.fetchData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.resource !== this.props.resource && !this.props.resource.isLoading && this.getID()) {
            this.setState({
                fields: this.getFields(getProp(this.props.resource, 'data', {})),
                templateName: getProp(this.props.resource, 'data.DriverSettlementTemplate', null)
            }, () => {
                this.assignTemplateIfExists();
            })
        }
        if (prevState.canSubmit !== this.state.canSubmit) {
            this.props.setIsStateDirty(this.state.canSubmit)
        }

        if (prevState.importTemplateFields !== this.state.importTemplateFields) {
            this.fetchDataDeduction();
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    }

    fetchDataDeduction = () => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: this.getResource()
        }))
    }

    submit = () => {
        this.setState({canSubmit: false, fields: FieldsManager.validateFields(this.state.fields)}, () => {
            const fieldsToValidate = Object.assign({}, this.state.fields);

            if (FieldsManager.checkFieldsForErrors(fieldsToValidate)) {
                const params = Object.assign({}, FieldsManager.getFieldKeyValues(fieldsToValidate), {
                    TruckSettlementID: getProp(this.props.resource, 'data.TruckSettlementID', 0)
                })

                if (params.SettlementsEmail) {
                    params.SettlementsEmail = params.SettlementsEmail.filter(it => !it.invalid).map(it => it.value).join(",");
                }

                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: params,
                    resource: this.getResourceName(),
                    piggyResource: this.getResourceName(),
                    piggyQuery: this.getQuery(),
                    errorMessage: true, successMessage: 'Truck settlement successfully updated!',
                }))
            }
        })
    }

    /** UI Events
     ================================================================= */

    handleImportInputChange = (name, value) => {
        const importTemplateFieldsUpdate = this.state.importTemplateFields;
        let deductionData = this.state.deductionData;
        if (name === 'DriverSettlementTemplateID') {
            deductionData = getProp(this.props.thirdResource, 'data.list', []).filter((it) => it.DriverSettlementTemplateID == value.value)
        }

        deductionData.forEach((item) => {
            item.StartDate = moment().format(DEFAULT_DATABASE_DATETIME_FORMAT)
            item.Active = 0
        })
        this.setState({
            importTemplateFields: FieldsManager.updateField(importTemplateFieldsUpdate, name, value),
            deductionData: deductionData
        })
    }

    handleInputChange = (name, value) => {
        const fieldsSource = this.getID() ? this.state.fields : this.state.fields
        let fields = FieldsManager.updateField(fieldsSource, name, value);

        if (name === 'IsFactoringActive') {
            fields.OrganizationID.value = !value ? "" : fields.OrganizationID.value;
            fields.OrganizationID.disabled = !value;
            fields.OrganizationID.validate = value ? ['empty'] : [''];
        }
        if (name === 'LoadPayMethodID') {
            fields.PayMethodValueExtra.type = Number(value) === 7 ? 'text' : 'hidden'
            fields.PayMethodValueExtra.value = Number(value) === 7 ? fields.PayMethodValueExtra.value : ''
            fields.PayMethodValueExtra.metadata.addContainerClass = Number(value) === 7 ? "col-span-3" : "col-span-6"
            fields.PayMethodValue.metadata.addContainerClass = Number(value) === 7 ? "col-span-3" : "col-span-6"

            if (Number(value) >= 2 && Number(value) <= 5) {
                fields.PayMethodValue.type = 'percentage'
                fields.PayMethodValue.value = null
                fields.PayMethodValue.validate = ['float']
            } else {
                fields.PayMethodValue.type = 'money'
                fields.PayMethodValue.validate = ['empty']
            }
        }

        if (name === "SettlementsEmail") {
            value = value && value.map(it => {
                it.invalid = !emailValid(it.value);
                return it;
            });
        }

        if (this.getID()) {
            this.setState({canSubmit: true, fields: fields})
        } else {
            this.props.handleInputChange(fields)
        }
    }

    handleToggleImportFromTemplateModal = () => {
        this.setState({
            importFromTemplateModalOpen: !this.state.importFromTemplateModalOpen,
            importTemplateFields: this.getImportTemplateFields(),
        })
    }

    handleFormCancel = () => {
        if (this.getID()) {
            this.setState({
                fields: this.getFields(getProp(this.props.resource, 'data', {})),
                canSubmit: false
            })
        }
    }

    handleImportFromTemplate = () => {
        if (this.getID()) {
            const templateFields = this.state.importTemplateFields?.DriverSettlementTemplateID?.value?.metadata;
            const item = Object.assign({}, {
                LoadPayMethodID: templateFields?.LoadPayMethodID,
                PayMethodValue: templateFields?.PayMethodValue,
                PayMethodValueExtra: templateFields?.PayMethodValueExtra,
                PayOnlyWhenPODReady: templateFields?.PayOnlyWhenPODReady
            }, {
                IsPaysIFTA: this.state.fields.IsPaysIFTA?.value ? 1 : 0,
                IsPaysFuel: this.state.fields.IsPaysFuel?.value ? 1 : 0,
                IsFactoringActive: this.state.fields.IsFactoringActive?.value ? 1 : 0,
                OrganizationID: this.state.fields.OrganizationID?.value?.value,
                Organization: this.state.fields.OrganizationID?.value?.label,
            })

            this.props.dispatch(createResource({
                user: LocalStorage.get('user'),
                params: Object.assign({}, FieldsManager.getFieldKeyValues(this.getFields(item)), {
                    DriverSettlementID: getProp(this.props.resource, 'data.DriverSettlementID', 0),
                    DriverSettlementTemplateID: this.state.importTemplateFields?.DriverSettlementTemplateID?.value?.value,
                    Items: (this.state.deductionData ?? []).map(it => {
                        it.IsForever = it.DeductionRepeatTypeID == '1' ? 1 : 0
                        return it;
                    })
                }),
                resource: this.getResourceName(),
                piggyResource: this.getResourceName(),
                piggyQuery: this.getQuery(),
                errorMessage: true, successMessage: 'Driver settlement template set!',
            }))

            this.props.getData(this.state.deductionData)
            this.handleToggleImportFromTemplateModal()
        }
    }

    assignTemplateIfExists = () => {
        const readOnlyFields = Object.values(this.state.fields).map(field => {
            if (
                field.name !== 'IsFactoringActive'
                &&
                field.name !== 'OrganizationID'
                &&
                field.name !== 'DepositProvider'
                &&
                field.name !== 'IsPaysIFTA'
                &&
                field.name !== 'IsPaysFuel'
                &&
                field.name !== 'Send1099'
                &&
                field.name !== 'PayOnlyWhenPODReady'
                &&
                field.name !== 'DoNotIssueExpressCode'
            ) {
                field.disabled = !!this.state.templateName
            }
            return field
        }).reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            fields: readOnlyFields
        });
    }

    removeTemplate = () => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                DriverSettlementID: getProp(this.props.resource, 'data.DriverSettlementID', 0),
                DriverSettlementTemplateID: '0'
            }),
            resource: this.getResourceName(),
            piggyResource: this.getResourceName(),
            piggyQuery: this.getQuery(),
            errorMessage: true, successMessage: 'Driver settlement template removed!',
        }))

        this.setState({
            templateName: null
        }, this.assignTemplateIfExists)
    }

    handleOrganizationQuickView = (item) => {
        this.setState({selectedOrganization: item, isOrganizationDialogOpen: !this.state.isOrganizationDialogOpen})
    }
    /** Helpers
     ================================================================= */
    getID = () => {
        return this.props.TruckID
    }

    getResourceName = () => {
        return Resources.TrucksSettlements
    }

    getQuery = () => {
        return {
            id: this.getID(),
            TruckID: this.getID(),
        }
    }

    getResource = () => {
        return Resources.DriverSettlementsTemplateDeductions
    }

    getImportTemplateFields = () => ({
        DriverSettlementTemplateID: new Field('DriverSettlementTemplateID', '', [], false, 'select-search', {addContainerClass: 'col-span-full'}),
    })

    getImportReadonlyFields = (item = null) => {
        let fieldTemplates = {
            LoadPayMethodID: new Field('LoadPayMethod', '', [], false, 'text'),
            PayMethodValue: new Field('PayMethodValue', '', [], false, 'text'),
            PayMethodValueExtra: new Field('PayMethodValueExtra', '', [], false, (item?.LoadPayMethodID == 7) ? 'money' : 'hidden'),

            // PAY TO
            PayOnlyWhenPODReady: new Field('PayOnlyWhenPODReady', '', [], false, 'checkbox', {
                addContainerClass: 'col-span-2',
            }),
            Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass: "pt-6"}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getFields = (item = null) => {
        let fieldTemplates = {
            LoadPayMethodID: new Field('LoadPayMethodID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-6'}),
            PayMethodValue: new Field('PayMethodValue', 0, (item?.LoadPayMethodID >= 2 && item?.LoadPayMethodID <= 5) ? ['float'] : ['empty'], false, (item?.LoadPayMethodID >= 2 && item?.LoadPayMethodID <= 5) ? 'percentage' : 'money', {addContainerClass: item?.LoadPayMethodID == 7 ? 'col-span-3' : 'col-span-6'}),
            PayMethodValueExtra: new Field('PayMethodValueExtra', 0, [], false, (item?.LoadPayMethodID == 7) ? 'text' : 'hidden', {addContainerClass: 'col-span-3'}),

            DepositProvider: new Field('DepositProvider', '', [], false, 'text', {addContainerClass: 'col-span-full'}),

            // PAY TO
            IsPaysIFTA: new Field('IsPaysIFTA', '', [], false, 'checkbox', {
                addContainerClass: 'col-span-4',
                label: "IsChargeIFTA"
            }),
            IsPaysFuel: new Field('IsPaysFuel', '', [], false, 'checkbox', {
                addContainerClass: 'col-span-4',
            }),

            PayOnlyWhenPODReady: new Field('PayOnlyWhenPODReady', '', [], false, 'checkbox', {
                addContainerClass: 'col-span-6 lg:col-span-4',
            }),

            SettlementsEmail: new Field('SettlementsEmail', "", [], false, 'creatable', {
                addContainerClass: 'col-span-full',
                onEnterDown: true
            }, {
                isMulti: true,
                components: {DropdownIndicator: null},
                menuIsOpen: false,
                placeholder: 'Type emails...',
                addClass: "rounded-r-none form-control"
            }),

            OrganizationID: new Field('OrganizationID', '', ['empty'], false, 'select-search', {
                addContainerClass: 'col-span-full',
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.handleOrganizationQuickView(it),
                                    isVisible: !!it.value
                                }
                            ]}
                        />
                    )
                }
            }),

            DriverSettlementTemplateID: new Field('DriverSettlementTemplateID', '', [], false, 'hidden', {}),
        }

        if (item) {
            fieldTemplates.OrganizationID.value = item.OrganizationID;
        }

        fieldTemplates = fillFieldsFromData(fieldTemplates, item)

        if (item?.Driver?.DoNotIssueExpressCode === 1) {
            fieldTemplates.DoNotIssueExpressCode.value = "1";
        }

        if (fieldTemplates.SettlementsEmail.value) {
            fieldTemplates.SettlementsEmail.value = fieldTemplates.SettlementsEmail.value.split(",").map(it => {
                return {
                    "label": it,
                    "value": it,
                };
            })
        }

        return fieldTemplates
    }

    getFieldsDeduction = (item = null) => {
        let fieldTemplates = {
            Deduction: new Field('Deduction', Number(item?.Deduction) === 1 ? 1 : 0, [], false, 'button-group', {
                data: {1: 'Deduction', 0: 'Addition'},
                addContainerClass: 'col-span-full',
                render: (it) => !it.Deduction
                    ? <div className="flex gap-x-2">
                        <PlusCircleIcon className="text-green-600 w-5 h-5"/>
                        {this.props.translate("text.addition")}
                    </div>
                    : <div className="flex gap-x-2">
                        <MinusCircleIcon className="text-red-600 w-5 h-5"/>
                        {this.props.translate("text.deduction")}
                    </div>
            }),
            IncomeAccountID: new Field('IncomeAccountID', '', Number(item?.Deduction) === 1 ? ['empty'] : [''], false, Number(item?.Deduction) !== 1 ? 'hidden' : 'select-search', {
                addContainerClass: 'col-span-full',
                label: "account",
                hideTable: true
            }),
            ExpenseAccountID: new Field('ExpenseAccountID', '', Number(item?.Deduction) === 0 ? ['empty'] : [''], false, Number(item?.Deduction) === 1 ? 'hidden' : 'select-search', {
                addContainerClass: 'col-span-full',
                label: "account",
                render: (it) => !!it.Deduction ? it.IncomeAccount : it.ExpenseAccount
            }),
            ScheduledDeductionsType: new Field('ScheduledDeductionsType', Number(item?.ScheduledDeductionsType) === 2 ? 2 : 1, [], false, 'button-group', {
                data: {1: 'Fixed amount', 2: 'Per mile'},
                addContainerClass: 'col-span-full',
                render: (item) => (Number(item?.ScheduledDeductionsType) === 1)
                    ? <div className="flex gap-x-2">
                        {this.props.translate("text.fixed_amount")}
                    </div>
                    : <div className="flex gap-x-2">
                        {this.props.translate("text.per_mile_amount")}
                    </div>
            }),
            Amount: new Field('Amount', '', [], false, Number(item?.ScheduledDeductionsType) !== 2 ? 'float' : 'hidden', {
                addContainerClass: 'col-span-full',
                render: (item) => {
                    return formatMoney(!!item?.Amount && !!item?.Amount > 0 ? item?.Amount : item?.AmountPer)
                }
            }),
            AmountPer: new Field('AmountPer', '', [], false, Number(item?.ScheduledDeductionsType) === 2 ? 'float' : 'hidden', {
                addContainerClass: 'col-span-full',
                hideTable: true
            }),
            DeductionRepeatTypeID: new Field('DeductionRepeatTypeID', '1', [], false, 'radio', {
                options: [
                    {name: 'Unlimited', value: '1'},
                    {name: 'Up to the account balance', value: '2'},
                    {name: 'Until account balance reaches zero', value: '3'},
                    {name: 'One time', value: '4'}
                ],
                addContainerClass: 'col-span-full',
                render: (it) => {
                    let text = '';
                    switch (+it.DeductionRepeatTypeID) {
                        case 1:
                            text = 'Unlimited'
                            break;
                        case 2:
                            text = 'Up to the account balance'
                            break;
                        case 3:
                            text = 'Until account balance reaches zero'
                            break;
                        case 4:
                            text = 'One time'
                            break;
                    }
                    return text
                }
            }),
            MaxAmount: new Field('MaxAmount', '', [], true, 'float', {
                addContainerClass: 'col-span-full',
                label: "up_to_account_balance",
                render: (it) => {
                    switch (+it.DeductionRepeatTypeID) {
                        case 2:
                            return (numberWithCommas(it.MaxAmount))
                        default:
                            return "/";
                    }
                }
            }),
            Active: new Field('Active', '', [], true, 'custom', {
                addContainerClass: 'col-span-full',
                render: (it) => <button onClick={() => {
                    let data = this.state.deductionData;
                    data[it.dataIndex].Active = it.Active ? 0 : 1

                    this.setState({deductionData: data})
                }
                } className={'btn-table-action btn-outline'}>{it.Active ?
                    <CheckIcon className="w-5 h-5 text-green-600"/> :
                    <XMarkIcon className="w-5 h-5 text-red-600"/>}</button>
            }),
            StartDate: new Field('StartDate', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), [], true, 'custom', {
                addContainerClass: 'col-span-full',
                render: (it) =>
                    <React.Fragment>
                        <FieldDate
                            className={'form-control  select-css-search'}
                            addClass={'form-control'}
                            onChange={(name, value) => {
                                let data = this.state.deductionData;
                                let index = this.state.deductionData.indexOf(it);
                                data[index].StartDate = value
                                this.setState({deductionData: data})
                            }}
                            showTimeSelect={false}
                            value={it.StartDate?.value}
                        />
                    </React.Fragment>
            })
        }

        if (item) {
            fieldTemplates = fillFieldsFromData(fieldTemplates, item)
            if (!item.IsForever) {
                if (item.MaxAmount) {
                    fieldTemplates.MaxAmount.disabled = false
                    fieldTemplates.IsForever.value = '2'
                }
            } else {
                fieldTemplates.IsForever.value = '1'
            }
        }

        return fieldTemplates
    }

    render() {
        const {translate, resource} = this.props

        const isInitialPayrollSetupCompleted = !!resource?.data?.InitialPayrollSetupCompleted;

        const importInfo = this.state.importTemplateFields?.DriverSettlementTemplateID?.value?.metadata;

        const importHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.importTemplateFields)), translate, this.handleImportInputChange, {
            DriverSettlementTemplateID: {
                api: 'api/' + Resources.DriverSettlementsTemplate,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.DriverSettlementTemplate,
                    value: item.DriverSettlementTemplateID,
                    metadata: item
                })
            }

        })

        const fields = this.state.fields;

        const fieldsHtmlColumnOne = fieldsToHtml(Object.values(Object.assign({}, includeFields(fields, ['LoadPayMethodID', 'PayMethodValue']))), translate, this.handleInputChange)

        const fieldsHtmlColumnTwo = fieldsToHtml(Object.values(Object.assign({}, excludeFields(fields, ['LoadPayMethodID', 'PayMethodValue']))), translate, this.handleInputChange, {
            FactoringStateID: getLookup('State'),
            OrganizationID: {
                api: 'api/' + Resources.OrganizationsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => {
                    return ({
                        value: item.OrganizationID,
                        label: item.LegalName + " City: " + item.CityName + " State: " + getLookup('State')[item.StateID] + " Zip: " + item.PostalCode,
                        metadata: item
                    })
                }
            }
        })

        return (
            <div className="space-y-4">
                {!isInitialPayrollSetupCompleted && !resource.isLoading && checkPerm(Resources.DriverSettlements, UPDATE_PERM) && (
                    <InfoBar type="warning" Icon={ExclamationCircleIcon}>
                        <div>
                            {translate("text.isInitialTruckPayrollSetupCompleted")}
                        </div>
                    </InfoBar>
                )}

                <PageHeader
                    title={translate('title.LoadPaymentPlan')}
                    titleClass="mr-5 text-2xl ml-4"
                    className={'flex justify-between'}
                >
                    <div className={'mt-2 ml-4 font-bold'}>{this.state.templateName}</div>
                    {checkPerm(Resources.DriverSettlementsTemplateDeductions, CREATE_PERM) && !this.state.templateName && (
                        <Tippy
                            content={translate('text.ImportFromTemplate')}
                        >
                            <div>
                                <button
                                    className="btn btn-header"
                                    onClick={this.handleToggleImportFromTemplateModal}
                                >
                                    <ArrowUpTrayIcon className="w-5 h-5"/>
                                </button>
                            </div>
                        </Tippy>)}

                    {checkPerm(Resources.DriverSettlementsTemplateDeductions, CREATE_PERM) && !!this.state.templateName && (
                        <Tippy
                            content={translate('text.removeTemplate')}
                        >
                            <div>
                                <button
                                    className="btn btn-header"
                                    onClick={this.removeTemplate}
                                >
                                    <XMarkIcon className="w-5 h-5"/>
                                </button>
                            </div>
                        </Tippy>)}

                </PageHeader>

                <Card addClass="relative" bodyClass="py-3 px-6">
                    <div className="grid grid-cols-12 gap-4">
                        {fieldsHtmlColumnOne}
                    </div>

                    {resource.isLoading && (
                        <LoaderLarge className="m-0" stripesBg/>
                    )}
                    <InfoParagraph type="info" className={"my-4"}>
                        <p>{"The payment plan determines the default load pricing entry for a truck. Each time a truck is assigned to a load, the default entry with the specified values will be added to the load pricing record."}</p>
                    </InfoParagraph>

                </Card>

                <CardTitle title={translate('title.Payroll_settings')}/>
                <Card addClass="relative" bodyClass="py-3 px-6">
                    <div className="grid grid-cols-12 gap-4">
                        {fieldsHtmlColumnTwo}
                    </div>

                    {resource.isLoading && (
                        <LoaderLarge className="m-0" stripesBg/>
                    )}

                    <InfoParagraph type="info" className={"my-4"}>
                        <p>{"Payroll settings determine whether certain items will appear and can be charged to a truck on a payroll record. They also specify whether the resulting payroll bill is assigned to the truck or the company, and if an additional label should be applied to the bill."}</p>
                    </InfoParagraph>
                </Card>

                <ModalDefault
                    show={this.state.importFromTemplateModalOpen}
                    widthClass={"max-w-[90rem]"}
                    title={translate("text.FilePreview")}

                    onButtonClick={this.handleImportFromTemplate}
                    buttonLabel={translate('btn.apply')}
                    buttonDisabled={!this.state.importTemplateFields?.DriverSettlementTemplateID?.value?.value}

                    closeButtonLabel={translate('btn.Cancel')}
                    onClose={this.handleToggleImportFromTemplateModal}
                >
                    <div className="bg-tm-gray-50 text-tm-gray-900">
                        <div className="p-12 max-w-[90rem] mx-auto">
                            {importHtml}

                            {importInfo && (
                                <div className="space-y-2 py-6">
                                    <p className="font-bold leading-5">Template info</p>

                                    {Object.values(this.getImportReadonlyFields()).filter(it => importInfo[it.name] !== undefined).map(field => {
                                        return (
                                            <DataCard
                                                key={field.name}
                                                displayField={field}
                                                fieldsData={importInfo}
                                                translate={translate}
                                            />
                                        )
                                    })}

                                    <div
                                        className={"text-sm mt-2 mb-2 font-bold"}>{translate('page.heading.driver_pay_adjustments')}</div>

                                    <ResourceTable
                                        data={this.state.deductionData}
                                        fields={this.getFieldsDeduction()}

                                        options={{
                                            style: {
                                                verticalLines: true,
                                                stripedRows: true
                                            },
                                            classes: {
                                                headerTextClass: 'text-xs font-semibold text-tm-gray-900 capitalize',
                                                primaryTextClass: ''
                                            }
                                        }}

                                        translate={translate}
                                        isLoading={this.props.thirdResource.isLoading}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </ModalDefault>


                {(!!this.state.isOrganizationDialogOpen &&
                    <OrganizationInfoDialog
                        show={this.state.isOrganizationDialogOpen}
                        translate={translate}
                        organizationID={this.state.selectedOrganization?.value?.value}
                        handleClose={() => this.handleOrganizationQuickView()}
                    />
                )}

                {this.getID() && (
                    <PageFooter
                        translate={translate}
                        canSubmit={this.state.canSubmit}
                        actionCancel={this.handleFormCancel}
                        actionSubmit={checkPerm(Resources.TrucksPayroll, UPDATE_PERM) && this.submit}
                    />
                )}
            </div>
        )
    }
}

export default connect(state => state)(TruckPayrollSetup)


