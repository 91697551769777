import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../common/util/localStorage'
import Resources from '../../../data/services/resources'
import InvoiceDialog from '../dialogs/income-expense-dialog'
import IncomeExpenseDialog from '../dialogs/income-expense-dialog'
import {Field, FieldsManager} from '../../../data/services/fields'
import moment from 'moment-timezone'
import PaymentDialog from '../dialogs/payment-dialog/payment-dialog'
import BulkPaymentDialog from '../dialogs/bulk-payment-dialog'
import Env from '../../../util/env'
import axios from 'axios'
import Tippy from '@tippyjs/react'
import {
    DEFAULT_CRUD_STATE,
    DEFAULT_QUERY_LIMIT,
    READ_PERM,
    TAG_LABEL_COLORS,
    UPDATE_PERM
} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    ConstantTranslate,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    longTableColumn,
    mergeDeep,
    onDocumentPrint,
    renderFactoringStatusBadge,
    resourceIsCreated,
    saveTableColumns, verifyFilterFields
} from '../../../common/util/util-helpers'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import InvoiceExpenseDetailsDialog from '../dialogs/invoice-expense-details-dialog'
import {createSecondResource, deleteSecondResource, getSecondResource} from '../../../data/actions/secondResource'
import {getThirdResource} from '../../../data/actions/thirdResource'
import CheckIcon from '@heroicons/react/20/solid/CheckIcon'
import {currentDate, toFrontDate} from '../../../common/util/util-dates'
import {ChevronUpIcon as ChevronUpSolidIcon, DocumentArrowUpIcon, TruckIcon, XMarkIcon} from '@heroicons/react/20/solid'
import {
    ChatBubbleBottomCenterTextIcon,
    CreditCardIcon,
    EnvelopeIcon,
    EyeIcon,
    MagnifyingGlassIcon,
    PencilIcon
} from '@heroicons/react/24/outline'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {download} from '../../../data/actions/download'
import PayDateFilter from "../carrier-pay/carrier-pay-date-filter";
import ContactInfoDialog from "../../../common/components/modal/contact-info-dialog";
import OrganizationInfoDialog from "../../../common/components/modal/modal-global/organization-info-dialog";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import FileViewer from "../../../common/components/file-viewer/components"
import ModalDefault from "../../../common/components/modal/modal-default";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import EmailDocumentDialog from "../../../common/components/modal/modal-email-documents/modal-email-document";
import TableTagManager from "../../../common/components/resource-table/table-components/table-tag-manager";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import FireIcon from "@heroicons/react/20/solid/FireIcon";
import Badge from "../../../common/components/badge";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import TableReminderMessage from "../../../common/components/resource-table/table-components/table-reminder-message";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import ResourceTable from "../../../common/components/resource-table";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import NoRecords from "../../../common/components/no-records-found/no-records";
import ModalReminder from "../../../common/components/modal/modal-reminder";
import {getJWT} from "../../../common/util/util-auth";
import {
    CREATE_PERM,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    INVOICE_FACTOR_STATUS_OPTIONS
} from "../../../common/util/util-consts";
import SetFactoringModal from "./set-factoring-modal";
import {formatMoney} from "../../../common/util/util-formaters";
import ScaleIcon from "@heroicons/react/24/outline/ScaleIcon";
import {resetPreviewDocumentResource} from "../../../data/actions/previewDocumentResource";
import Button from "../../../common/components/button";
import Tooltip from "../../../common/components/tooltip";
import CollectionsFactoringImportDialog from "./collections-factoring-import";
import {excludeFields} from "../../../common/util/util-fields";

class FactoringCollectionsTab extends Component {

    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1) + '_factoring_collections'

        this.colors = TAG_LABEL_COLORS
        this.pageSettingsName = 'page-settings-' + this.pagePath

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            columns: {
                AutoCounter: {
                    minWidth: 85,
                },
                Labels: {minWidth: 100},
                RefNumber: {minWidth: 105},
                Amount: {minWidth: 120},
                AmountTransferred: {minWidth: 120},
                IsLoadInvoice: {minWidth: 50},
                InvoiceType: {minWidth: 80},
                Date: {minWidth: 130},
                DueDate: {minWidth: 130},
                BookDate: {minWidth: 130},
                InvoiceStatus: {minWidth: 100},
                IsDigitalSent: {minWidth: 80},
                IsDigitalConfirmed: {minWidth: 80},
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'InvoiceID',
            sort: 'DESC',

            // Fields
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            emailFields: this.props.getEmailFields(),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),

            // Dialog(s)
            isInvoiceDialogVisible: false,
            isReceivePaymentDialogVisible: false,
            isDetailsDialogVisible: false,
            invoicePreviewDialog: false,
            isTableFullScreen: false,

            selectedItems: {},
            batchActionDialog: false,
            batchActionDialogHideAnimation: false,
            selectedRows: {},
            selectedOrganizationContact: {},
            isOrganizationInfoDialogOpen: false,
            isContactInfoDialogOpen: false,
            isReminderDialogOpen: false,
            isInvoiceCopyDialogVisible: false,
            isSetFactoringBalanceModalOpen: false,
            importExcelDataModalOpen: false
        }

        if (!this.state.tableOptions.behaviour.hasMenu) { // TEMP FUNCTION
            this.state.tableOptions.behaviour = Object.assign(this.state.tableOptions.behaviour, this.tablePageDefaults.behaviour)
        }

        this.state = mergeDeep(this.state, verifyFilterFields(
            LocalStorage.rehydrateState(this.pagePath),
            this.state.queryFilterFields
        ));
        this.PopoverButtonRef = React.createRef()
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
        this.fetchLabels()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.breakpoint !== this.props.breakpoint && this.props.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10)
        }

        if (resourceIsCreated(this.props.secondResource, prevProps.secondResource) && this.props.secondResource.resource === Resources.AccountingIncomeInvoiceComment) {
            this.fetchData()
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    fetchLabels = () => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            resource: Resources.ColorTag
        }))
    }

    handleToggleImportExcelDataModal = () => {
        this.setState({
            importExcelDataModalOpen: !this.state.importExcelDataModalOpen
        }, () => {
            this.props.dispatch(resetPreviewDocumentResource());
        });
    }

    /** UI Events
     ================================================================= */
    handleSelectRowClick = (item, event) => {
        const itemID = item.InvoiceID
        const data = getProp(this.props.resource.data, 'list', [])
        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedItems = this.state.selectedItems
        if (event?.nativeEvent?.shiftKey) {
            const firstSelectedItem = data.findIndex(it => selectedRows[it.InvoiceID])
            const lastSelectedItem = data.findIndex(it => it.InvoiceID === itemID)

            if (firstSelectedItem > -1 && lastSelectedItem > -1) {
                if (firstSelectedItem < lastSelectedItem) {
                    for (let i = firstSelectedItem; i < lastSelectedItem + 1; i++) {
                        Object.assign(selectedRows, {[data[i].InvoiceID]: data[i]})
                        selectedItems[data[i].InvoiceID] = data[i]
                    }
                } else {
                    for (let i = lastSelectedItem; i < firstSelectedItem + 1; i++) {
                        selectedItems[data[i].InvoiceID] = data[i]
                        Object.assign(selectedRows, {[data[i].InvoiceID]: data[i]})
                    }
                }
            }
        } else {
            if (selectedRows[itemID]) {
                delete selectedItems[itemID]
                delete selectedRows[itemID]
            } else {
                selectedItems[itemID] = data.find(it => it.InvoiceID === itemID)
                Object.assign(selectedRows, {[itemID]: item})
            }
        }

        this.setState({selectedRows, selectedItems})
    }

    handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(this.props.resource.data, 'list', []))

        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedItems = this.state.selectedItems

        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                selectedItems[it.InvoiceID] = it
                memo[it.InvoiceID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.InvoiceID.toString())) {
                    delete selectedItems[it.InvoiceID]
                    delete selectedRows[it.InvoiceID]
                }
            })
        }

        this.setState({selectedRows})
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {

            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'InvoiceID'
            sort = 'DESC'
        }

        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.fetchData()
            this.saveFilters()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), this.tablePageDefaults, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    handleToggleOrganizationInfoDialog = (item = {}) => {
        this.setState({
            selectedOrganization: item,
            isOrganizationInfoDialogOpen: true
        })
    }

    handleToggleContactInfoDialog = (item = {}) => {
        this.setState({
            selectedContact: item,
            isContactInfoDialogOpen: true
        })
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'income_collections_' + currentDate() + '.xlsx'
            }, this.getQuery())
        }))
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    handleReminderClick = (it) => {
        this.setState({
            selectedItem: it,
            isReminderDialogOpen: true
        });
    }

    handleCloseReminderModalClick = () => {
        this.setState({
            selectedItem: {},
            isReminderDialogOpen: false
        });
    }

    toggleSetFactoringBalanceModal = (item) => {
        this.setState({
            selectedItem: item,
            isSetFactoringBalanceModalOpen: !this.state.isSetFactoringBalanceModalOpen,
        })
    }

    handleOpenViewDocumentClick = (item = null) => {
        this.setState({
            selectedItem: item,
            isDocumentPreviewModalOpen: true
        });
    }

    /** Helpers
     ================================================================= */

    renderStatusBadge = (it) => {
        if (
            (it.InvoiceStatus !== 'Paid')
            &&
            it.IsRevised
            && !!it?.RevisedDate
            && !moment(it.RevisedDate).isBefore(moment(it.InvoiceSentDate))
        ) {
            return (
                <div className="inline-block space-y-0.5">
                    <Badge type="warning"
                           className="block flex-grow-0 text-center px-3 py-0.5 leading-4 rounded-btn text-sm font-medium">
                        Revised
                    </Badge>

                    <div className="text-tm-gray-600 font-bold leading-3">
                        {(it?.RevisedDate ? ' ' + toFrontDate(it.RevisedDate) : '')}
                    </div>
                </div>
            )
        } else {
            switch (it.InvoiceStatus) {
                case 'Sent':
                    return <div className="inline-block space-y-0.5">
                        <Badge type="warning"
                               className="block flex-grow-0 text-center px-3 py-0.5 leading-4 rounded-btn text-sm font-medium">
                            {it.InvoiceStatus}
                        </Badge>

                        <div className="text-yellow-600 font-bold leading-3">
                            {(it?.InvoiceSentDate ? ' ' + toFrontDate(it.InvoiceSentDate) : '')}
                        </div>
                    </div>
                case 'Paid':
                    return <div className="inline-block space-y-0.5">
                        <Badge type="success"
                               className="block flex-grow-0 text-center px-3 py-0.5 leading-4 rounded-btn text-sm font-medium">
                            {it.InvoiceStatus}
                        </Badge>

                        <div className="text-green-600 font-bold leading-3">
                            {(it?.InvoicePaidDate ? ' ' + toFrontDate(it.InvoicePaidDate) : '')}
                        </div>
                    </div>
                default:
                    return <Badge type="info">{it.InvoiceStatus}</Badge>
            }
        }
    }

    getQuery = () => {
        let defs = {...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)}
        if (!defs.unpaidDaysFactoring) {
            defs.unpaidDaysFactoring = -1
        }
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...defs,
            IsFactoringActive: 1
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            unpaidDaysFactoring: new Field('unpaidDaysFactoring', 1, [''], false, 'select', {}, {
                values: {1: "1+", 5: "5+", 10: "10+", 15: "15+", 30: "30+", 60: "60+", 90: "90+"},
                isClearable: true,
            }),
            StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {}, {
                isClearable: true
            }),
            EndDate: new Field('EndDate', moment().format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {}, {
                isClearable: true
            }),
            DateType: new Field('DateType', 1, [''], false, 'button-group', {
                    data: {
                        1: this.props.translate('text.Date'),
                        2: this.props.translate('text.PostingDate'),
                        3: this.props.translate('text.DueDate')
                    },
                    labelType: 'stack',
                    addClass: 'w-full',
                    addButtonClass: 'uppercase font-semibold',
                    addContainerClass: 'col-span-full',
                    label: 'DateType_Date/PostingDate/DueDate'
                }
            ),
            OrganizationIDs: new Field('OrganizationIDs', '', [''], false, 'select-search', {}, {
                multi: true,
                isClearable: true
            }),
            ExcludeOrganizationIDs: new Field('ExcludeOrganizationIDs', '', [''], false, 'select-search', {}, {
                multi: true,
                isClearable: true
            }),
            ContactIDs: new Field('ContactIDs', '', [''], false, 'select-search', {}, {
                multi: true,
                isClearable: true
            }),
            ExcludeContactIDs: new Field('ExcludeContactIDs', '', [''], false, 'select-search', {}, {
                multi: true,
                isClearable: true
            }),
            OfficeID: new Field('OfficeID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            UpdatedByContactID: new Field('UpdatedByContactID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            AmountMoreThan: new Field('AmountMoreThan', '', [], false, 'float'),
            AmountLessThan: new Field('AmountLessThan', '', [], false, 'float'),
            AmountExact: new Field('AmountExact', '', [], false, 'float'),
            excludeQuery: new Field('excludeQuery', '', [], false, 'search', {}, {icon: false}),
            IsDriverTerminationInvoice: new Field('IsDriverTerminationInvoice', '', [''], false, 'checkbox'),
            IsLoadInvoice: new Field('IsLoadInvoice', '', [''], false, 'checkbox'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    getFields = () => {
        const InvoiceFactorStatusOptions = ConstantTranslate(INVOICE_FACTOR_STATUS_OPTIONS, this.props.translate);
        return {
            AutoCounter: new Field('AutoCounter', '', [''], false, 'text', {
                render: (item) => {
                    return (
                        <div className="flex">
                            {item.AutoCounter}
                            {!!item.Marked && (
                                <div className="ml-auto flex pl-5">
                                    <Tippy
                                        content={this.props.translate('field.Marked')}
                                    >
                                        <span className="relative">
                                            <FireIcon
                                                className="relative z-12 h-5 w-5 cursor-pointer text-red-600 animate-pulse"/>
                                        </span>
                                    </Tippy>
                                </div>
                            )}
                        </div>
                    )
                }
            }),
            RefNumber: new Field('RefNumber', '', [''], false, 'text'),
            CustomerReferenceNumber: new Field('CustomerReferenceNumber', '', [''], false, 'text', {
                omitSort: true
            }),
            Reminder: new Field('Reminder', '', [''], false, 'text', {
                render: (item) => {
                    return <TableReminderMessage
                        item={item}
                        IsUpdateReminderOn={item.IsUpdateReminderOn}
                        LastUpdateDateTime={item.LastUpdateDateTime}
                        UpdateReminderInterval={item.UpdateReminderInterval}
                        toggleReminderDialog={this.handleReminderClick}
                        translate={this.props.translate}
                    />
                }
            }),
            CollectionNote: new Field('CollectionNote', '', [''], false, 'text', {
                render: (item) => (
                    <div>{longTableColumn(item.CollectionNote)}</div>
                )
            }),
            CollectionNoteDate: new Field('CollectionNoteDate', '', [], false, 'datetimezone'),
            Labels: new Field('Labels', '', ['empty'], false, 'custom', {
                render: (it) => !!it.ColorTag && <ResourceTableTags data={it.ColorTag}/>
            }),
            IsLoadInvoice: new Field('IsLoadInvoice', '', ['empty'], false, 'custom', {
                render: (it) => !!it.IsLoadInvoice &&
                    <Tippy content={<span>Load Invoice</span>}><TruckIcon className={'w-5 h-5'}/></Tippy>
            }),
            Date: new Field('Date', '', [''], false, 'date'),
            DueDate: new Field('DueDate', '', [''], false, 'date'),
            Age: new Field('Age', '', [''], false, 'custom', {
                render: (item) => {
                    return (<div>{(moment().diff(moment(item.Date), 'days'))}</div>)
                }
            }),
            OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {
                render: (item) =>
                    <CellButton
                        onClick={() => item.OrganizationID ? this.handleToggleOrganizationInfoDialog(item) : this.handleToggleContactInfoDialog(item)}>
                        <div>
                            {item.Organization ? item.Organization : item.Contact}
                        </div>
                    </CellButton>,
                label: 'ChargeTo'
            }),
            Amount: new Field('Amount', '', [''], false, 'money'),
            AmountTransferredFactoring: new Field('AmountTransferredFactoring', '', [''], false, 'custom', {
                label: "factoringBalance",
                render: (it) => {
                    return it.IsFactoringActive && it.Amount ? formatMoney(it.Amount - (it?.AmountTransferredFactoring ?? 0)) : "/"
                }
            }),
            InvoiceFactorStatusID: new Field('InvoiceFactorStatusID', '', [''], false, 'custom', {
                label: "factoringStatus",
                render: (it) => it.IsFactoringActive ? (!!it?.InvoiceFactorStatusID && renderFactoringStatusBadge(it, InvoiceFactorStatusOptions)) : "/"
            }),
            IsDigitalSent: new Field('IsDigitalSent', '', [''], false, 'checkbox'),
            IsDigitalConfirmed: new Field('IsDigitalConfirmed', '', [''], false, 'checkbox'),
            Office: new Field('Office', '', [''], false, 'text'),
            IsRevised: new Field('IsRevised', '', [''], false, 'custom', {
                addTableHeaderClass: 'mx-auto',
                render: (it) => it.IsRevised
                    ? <div className="flex">
                        <div className="text-center">
                            <CheckIcon className="w-5 h-5 text-green-600 mr-2 inline"/>
                        </div>
                        {toFrontDate(it.RevisedDate)}
                    </div>
                    :
                    <div className="text-center"><XMarkIcon className="w-5 h-5 text-tm-gray-600 inline"/></div>
            }),
            RevisedDate: new Field('RevisedDate', '', [''], false, 'date'),
            ExternalNotes: new Field('ExternalNotes', '', [''], false, 'text', {
                render: (item) => (
                    <div>{longTableColumn(item.ExternalNotes)}</div>
                )
            }),
            InternalNotes: new Field('InternalNotes', '', [''], false, 'text', {
                render: (item) => (
                    <div>{longTableColumn(item.InternalNotes)}</div>
                )
            }),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone'),
            UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'select-search')
        }
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'query', 'showArchived'])
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    toggleReceivePaymentDialog = (item) => {
        this.setState({
            receivePaymentItem: item,
            isReceivePaymentDialogVisible: !this.state.isReceivePaymentDialogVisible
        })
    }

    toggleProcessPreview = (item = null) => {
        this.setState({
            previewDocument: item,
            invoicePreviewDialog: !this.state.invoicePreviewDialog
        })
    }

    closeInvoiceDialog = () => {
        this.setState({
            isInvoiceDialogVisible: false,
            dialogHideAnimation: false
        })
    }

    toggleShowDetails = (item) => {
        this.setState({
            selectedItem: item,
            isDetailsDialogVisible: !this.state.isDetailsDialogVisible,
        })
    }

    toggleSendEmailDialog = (item = null) => {
        // ignore event
        item = item?.current ? null : item

        if (item) {
            item.DocumentName = item.InvoiceID + '.pdf'
        }

        let emailFields = this.state.emailFields
        emailFields.emails.value = item?.InvoiceSendEmails?.split(',').map(it => ({label: it, value: it})) ?? ''

        this.setState({
            emailModalOpen: !this.state.emailModalOpen,
            previewDocument: item
        })
    }

    canReceivePayment = (item) => {
        return !((item.InvoiceStatusID == 1 || item.InvoiceStatusID == 2))
    }

    editHidden = (item) => {
        const LockedData = getProp(this.props.resource.data, 'defaults.LockedDate', null)
        return !((!LockedData || (moment(LockedData).diff(item?.Date) < 0))
            && !item?.IsLoadInvoice && !item?.IsDriverTerminationInvoice)
    }

    openInvoiceDialog = (item = null) => {
        this.setState({
            isInvoiceDialogVisible: true,
            updateItem: item
        })
    }

    handleToggleBatchDialog = () => {
        this.setState({batchActionDialog: !this.state.batchActionDialog})
    }

    submitBulkPayment = (fields, payments) => {
        this.props.dispatch(createResource({
            user: LocalStorage.get('user'),
            params: Object.assign({}, FieldsManager.getFieldKeyValues(fields), {
                morePayments: payments.map(item => {
                    return {
                        InvoiceID: item.InvoiceID,
                        Amount: item.EnteredAmount,
                    }
                })
            }),
            resource: Resources.InvoicesPayment,
            piggyResource: this.getResource(),
            query: this.getQuery(),
            errorMessage: true,
            successMessage: this.props.translate('text.multiple_invoices_paid')
        }))
        this.setState({selectedItems: {}, selectedRows: {}})
    }

    handleDownloadDocument = async () => {
        try {
            await axios
                .get(Env.getApiUrl('api/' + Resources.BillingDocuments, Object.assign({}, {
                        InvoiceID: this.state.previewDocument.InvoiceID,
                        token: getJWT().access_token,
                        type: 3
                    })),
                    {responseType: 'blob'})
                .then((response) => {
                    //Create a Blob from the PDF Stream
                    const file = new Blob([response.data], {type: 'application/pdf'})
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file)
                    //Open the URL on new Window
                    const pdfWindow = window.open(fileURL)
                    pdfWindow.location.href = fileURL
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            return {error}
        }
    }

    getResource = () => {
        return Resources.Invoices
    }

    setSelectedRows = (selectedRows) => {
        this.setState({selectedRows})
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, secondResource, thirdResource} = this.props
        const data = getProp(resource.data, 'list', []);
        const count = getProp(resource.data, 'count', 0);
        const isLoading = !!resource.isLoading;

        const defaults = getProp(resource.data, 'defaults', []);

        const hasSelectedRows = !!Object.keys(this.state.selectedRows).length
        const totalAmount = genericMoneyFormatter(Object.values(this.state.selectedRows).reduce((memo, it) => memo + it.Amount, 0));
        const totalBalance =
            genericMoneyFormatter(Object.values(this.state.selectedRows).reduce((memo, it) => memo + (it.Amount - it.AmountTransferred), 0));


        return (
            <React.Fragment>
                <div className="flex mb-4">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />

                    <div className="flex ml-auto">
                        <Tooltip
                            disabled={!checkPerm(Resources.InvoicesFactor, CREATE_PERM)}
                            content={translate('text.ImportDataFromExcel')}
                        >
                            <span>
                                <Button
                                    className="btn-icon flex ml-auto"
                                    hasPerm={checkPerm(Resources.InvoicesFactor, UPDATE_PERM)}
                                    onClick={this.handleToggleImportExcelDataModal}
                                >
                                    <DocumentArrowUpIcon className="w-5 h-5"/>
                                </Button>
                            </span>
                        </Tooltip>

                        <Tippy content={translate('text.download_excel')}>
                            <button
                                className="btn-icon"
                                onClick={this.downloadExcel}
                            >
                                <DocumentArrowDownIcon className="w-5 h-5"/>
                            </button>
                        </Tippy>

                        <div className="flex ml-auto">
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>
                </div>

                <TableCard
                    addClass={
                        classNames(
                            this.state.isTableFullScreen
                                ? 'fixed inset-1 -top-1 z-50'
                                : undefined
                        )}
                >
                    <div className="">
                        <TableFilters
                            hideLimit
                            filterFields={excludeFields(this.state.queryFilterFields, ['StartDate', 'EndDate', 'DateType'])}
                            toggleAutoRefresh={this.toggleAutoRefresh}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            customHtml={
                                <PayDateFilter
                                    queryFields={this.state.queryFilterFields}
                                    translate={translate}
                                    updateQueryFields={(queryFieldsUpdate) => {
                                        this.setState({
                                            queryFilterFields: queryFieldsUpdate,
                                            offset: 0
                                        }, () => this.fetchData())
                                        this.saveFilters(queryFieldsUpdate)
                                    }}
                                    onQueryChange={this.handleFilterInputChange}
                                    hasDateType={true}
                                    isDateTypeClearable={true}
                                    gridCols={"grid-cols-1"}
                                />
                            }
                            forceDialog={true}
                            onRefreshTable={this.fetchData}
                            onFullScreenToggle={this.handleFullScreenToggle}
                            isTableFullScreen={this.state.isTableFullScreen}
                            selects={{
                                ExcludeContactIDs: {
                                    api: 'api/' + Resources.ContactsQuick,
                                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                    searchMap: (item) => ({
                                        label: item.FirstName + " " + item.LastName,
                                        value: item.ContactID
                                    })
                                },
                                OrganizationIDs: {
                                    api: 'api/' + Resources.OrganizationsQuick,
                                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                    searchMap: (item) => ({
                                        label: item.LegalName,
                                        value: item.OrganizationID
                                    })
                                },
                                ExcludeOrganizationIDs: {
                                    api: 'api/' + Resources.OrganizationsQuick,
                                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                    searchMap: (item) => ({
                                        label: item.LegalName,
                                        value: item.OrganizationID
                                    })
                                },
                            }}
                            isLoading={isLoading}
                        />

                        <ResourceTable
                            tableKey={'InvoiceID'}
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.props.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}
                            limit={this.state.queryFilterFields.limit.value}

                            onRowClick={checkPerm(Resources.InvoicesInvoice, READ_PERM) && this.toggleShowDetails}

                            saveTableOptions={this.setOptions}

                            maxHeightClass={
                                classNames(
                                    !this.state.isTableFullScreen ? 'max-h-[calc(100vh-23.5rem)]' : 'max-h-[calc(100vh-9.5rem)]',
                                )
                            }

                            actions={[
                                {
                                    action: this.toggleShowDetails,
                                    icon: EyeIcon,
                                    hasPerm: checkPerm(Resources.InvoicesInvoice, READ_PERM),
                                    title: translate('text.show_details'),
                                },
                                {
                                    action: this.toggleProcessPreview,
                                    icon: MagnifyingGlassIcon,
                                    hasPerm: checkPerm(Resources.BillingDocuments, READ_PERM),
                                    title: translate('text.preview_document'),
                                },
                                {
                                    action: this.openInvoiceDialog,
                                    icon: PencilIcon,
                                    visible: (item) => !this.editHidden(item),
                                    hasPerm: checkPerm(this.getResource(), UPDATE_PERM),
                                    title: translate('text.edit_invoice'),
                                },
                                {
                                    action: this.toggleSetFactoringBalanceModal,
                                    icon: ScaleIcon,
                                    hasPerm: checkPerm(Resources.InvoicesFactor, UPDATE_PERM),
                                    visible: (it) => it.IsFactoringActive,
                                    title: translate('btn.setFactoringBalance'),
                                    order: 51
                                },
                                {
                                    action: this.toggleSendEmailDialog,
                                    icon: EnvelopeIcon,
                                    hasPerm: checkPerm(Resources.EmailTemplates, READ_PERM),
                                    title: translate('text.send_document_via_email'),
                                }
                            ]}

                            onSelectRow={this.handleSelectRowClick}
                            selectedRows={this.state.selectedRows}
                            onSelectAllClick={this.handleSelectAllClick}
                        />
                    </div>

                    {/*Table footer*/}
                    <TableCardFooter
                        show={!(!data.length && !resource.isLoading)}
                    >

                        <Pagination
                            count={count}
                            isLoading={resource.isLoading || thirdResource.isLoading}
                            hideRowsPerPage={this.props.breakpoint.index <= 1}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />

                    </TableCardFooter>

                    <TableBulkActions
                        selectedRows={this.state.selectedRows}
                        tableKey="InvoiceID"
                        fields={this.getFields()}
                        translate={translate}
                        options={this.state.tableOptions}
                        setSelectedRows={this.setSelectedRows}
                        onSelectAllClick={this.handleSelectAllClick}
                        footerContent={
                            <div className="text-sm flex justify-between w-full px-4 py-1 h-8">
                                <div className="whitespace-nowrap">
                                    Total amount for selected entries:
                                    <span className="ml-2 font-bold text-primary-contrast">
                                        {totalAmount} (Balance {totalBalance})
                                    </span>
                                </div>
                            </div>
                        }
                    >
                        <div className={classNames('flex divide-x-2 divide-primary-shade pr-4')}>
                            <div className="flex gap-x-1 pr-2">
                                {checkPerm(Resources.InvoicesPayment, UPDATE_PERM) && (
                                    <Tippy content={translate('text.receive_payment')}>
                                        <button
                                            onClick={() => this.setState({batchActionDialog: true})}
                                            disabled={!hasSelectedRows}
                                            className={classNames(hasSelectedRows ? 'hover:bg-primary-shade' : undefined, 'icon-btn p-2 flex items-center')}
                                        >
                                            <CreditCardIcon
                                                className={classNames(hasSelectedRows ? 'text-primary-contrast' : 'text-tm-gray-400', 'w-5 h-5')}/>
                                        </button>
                                    </Tippy>
                                )}

                                {checkPerm(Resources.AccountingIncomeInvoiceComment, UPDATE_PERM) && (
                                    <Tippy
                                        content={translate('text.bulk_post_comment')}
                                    >
                                        <button
                                            onClick={() => this.setState({
                                                isReminderDialogOpen: true,
                                                selectedItem: null
                                            })}
                                            disabled={!hasSelectedRows}
                                            className={classNames(hasSelectedRows ? 'hover:bg-primary-shade' : undefined, 'icon-btn p-2 flex items-center')}
                                        >
                                            <ChatBubbleBottomCenterTextIcon
                                                className={classNames(hasSelectedRows ? 'text-primary-contrast' : 'text-tm-gray-400', 'w-5 h-5')}
                                            />
                                        </button>
                                    </Tippy>
                                )}
                            </div>

                            {checkPerm(Resources.AccountingColor, UPDATE_PERM) && (
                                <div className="flex items-center gap-x-1 pl-4">
                                    <TableTagManager
                                        rowKey={'InvoiceID'}
                                        id={'InvoiceIDs'}
                                        requestMethod={'POST'}
                                        btnIconClass="w-5 h-5"
                                        customIcon={<ChevronUpSolidIcon className="w-5 h-5"/>}
                                        ExpenseIDs={[]}
                                        selectedRows={this.state.selectedRows}
                                        resourceName={Resources.AccountingColor}
                                        piggyResourceName={this.getResource()}
                                        query={this.getQuery()}
                                        onAfterTagRows={() => {
                                            this.setState({selectedRows: {}})
                                        }}
                                        translate={translate}
                                    />
                                </div>
                            )}
                        </div>
                    </TableBulkActions>

                    <NoRecords
                        show={data.length === 0 && !resource.isLoading}
                        title={'No matching records found'}
                        addClass={'pt-16 pb-10'}
                    />
                </TableCard>

                {this.state.isInvoiceCopyDialogVisible && (
                    <IncomeExpenseDialog
                        isInvoiceDialogType={true}
                        onClose={this.closeCopyInvoiceDialog}
                        defaults={defaults}
                        query={this.getQuery()}
                        updateItem={this.state.updateItem}
                        isCopy={true}
                        onSubmit={(params, resource, files, fileResource) => {
                            if (params) {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: resource,
                                    file: files,
                                    fileResource: fileResource,
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: translate('message.invoice_created')
                                }))
                            }
                            this.closeCopyInvoiceDialog()
                        }
                        }
                        {...this.props}
                    />
                )}

                <PaymentDialog
                    show={this.state.isReceivePaymentDialogVisible}
                    payment={'receive'}
                    item={this.state.receivePaymentItem}
                    resourcePath={Resources.InvoicesPayment}
                    piggyResourcePath={this.getResource()}
                    query={this.getQuery()}
                    onClose={this.toggleReceivePaymentDialog}
                    defaults={defaults}
                    {...this.props}
                />

                {this.state.isInvoiceDialogVisible && (
                    <InvoiceDialog
                        isInvoiceDialogType
                        onClose={this.closeInvoiceDialog}
                        query={this.getQuery()}
                        defaults={defaults}
                        updateItem={this.state.updateItem}
                        onSubmit={(params, resource, files, fileResource) => {
                            if (params) {
                                if (params.id) {
                                    this.props.dispatch(updateResource({
                                        user: LocalStorage.get('user'),
                                        query: this.getQuery(),
                                        params: params,
                                        resource: resource,
                                        file: files,
                                        fileResource: fileResource,
                                        piggyResource: this.getResource(),
                                        errorMessage: true, successMessage: translate('message.invoice_updated')
                                    }))
                                } else {
                                    this.props.dispatch(createResource({
                                        user: LocalStorage.get('user'),
                                        query: this.getQuery(),
                                        params: params,
                                        resource: resource,
                                        file: files,
                                        fileResource: fileResource,
                                        piggyResource: this.getResource(),
                                        errorMessage: true, successMessage: translate('message.invoice_created')
                                    }))
                                }
                                this.closeInvoiceDialog()
                            }
                        }}
                        {...this.props}
                    />
                )}

                <EmailDocumentDialog
                    show={this.state.emailModalOpen}
                    title={translate('modal_heading.send_document_via_email')}
                    widthClass={'max-w-5xl'}
                    onClose={this.toggleSendEmailDialog}
                    fields={this.state.emailFields}
                    metadata={{
                        AddContactID: {
                            api: 'api/' + Resources.ContactsQuick,
                            query: {offset: 0, sort: 'ASC', limit: 10},
                            searchMap: (item) => ({
                                value: item.Email,
                                label: item.FirstName + ' ' + item.LastName
                            })
                        },
                        EmailTemplateID: {
                            api: 'api/' + Resources.EmailTemplates,
                            query: {offset: 0, sort: 'ASC', limit: 10},
                            searchMap: (item) => ({
                                value: item.EmailTemplateID,
                                label: item.EmailTemplateName
                            })
                        }
                    }}

                    fetchEmailTemplateQuery={{
                        InvoiceID: this.state?.previewDocument?.InvoiceID,
                        OrganizationID: this.state?.previewDocument?.OrganizationID
                    }}

                    onSubmit={(params) => {
                        if (params) {
                            let emails = params.emails.filter(it => !it.invalid).reduce((memo, it) => {
                                memo += it.value + ','
                                return memo
                            }, '')

                            this.props.dispatch(getResource({
                                user: LocalStorage.get('user'),
                                query: Object.assign({}, {
                                    status: 1,
                                    InvoiceID: this.state.previewDocument.InvoiceID,
                                    type: 2,
                                    name: this.state.previewDocument.DocumentName,
                                    InvoiceSendEmails: emails.substring(0, emails.length - 1),
                                    subject: params.subject,
                                    message: params.emailMessage
                                }, this.getQuery()),
                                resource: Resources.BillingDocuments,
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: 'Successfully sent invoice!'
                            }))

                            this.toggleSendEmailDialog()
                        }
                    }}
                    onPrint={() => this.handleDownloadDocument()}
                    document={this.state.previewDocument}
                    fileType={'pdf'}
                    filePath={Env.getApiUrl('api/' + Resources.BillingDocuments, Object.assign({}, {
                        InvoiceID: this.state?.previewDocument?.InvoiceID,
                        token: getJWT().access_token,
                        type: 1,
                        name: 'invoice_' + this.state.previewDocument?.DocumentName
                    }))}
                    {...this.props}
                />

                <ModalDefault
                    show={this.state.isDetailsDialogVisible}
                    widthClass={'max-w-5xl w-screen'}
                    title={translate('text.InvoiceID')}

                    buttonDisabled={this.editHidden(this.state.selectedItem) || !checkPerm(Resources.InvoicesInvoice, UPDATE_PERM)}
                    onButtonClick={() => {
                        this.toggleShowDetails();
                        this.openInvoiceDialog(this.state.selectedItem)
                    }}
                    buttonLabel={translate('btn.edit')}
                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.toggleShowDetails()}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={secondResource}
                        dialogResource={this.props.dialogResource}
                        isLoading={secondResource.isLoading || this.props.resource.isLoading}
                        translate={translate}
                        selectedItem={this.state.selectedItem}
                        dispatch={this.props.dispatch}
                        canDeletePayment
                        showLoadReferenceLinks
                        createCommentDispatchAction={createSecondResource}
                        deleteCommentDispatchAction={deleteSecondResource}
                        type="invoice"

                        onDeleteComment={(id) => {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get('user'),
                                resource: Resources.AccountingIncomeInvoiceComment,
                                query: {
                                    InvoiceCommentID: id,
                                    InvoiceID: this.state.selectedItem.InvoiceID
                                },
                                piggySecondResource: Resources.InvoicesInvoice,
                                secondQuery: {
                                    InvoiceID: this.state.selectedItem.InvoiceID
                                },
                                errorMessage: true, successMessage: translate('text.PaymentDeletedSuccessfully')
                            }))
                        }}

                        onFetchData={() => {
                            if (this.state.selectedItem) {
                                this.props.dispatch(getSecondResource({
                                    user: LocalStorage.get('user'),
                                    query: {
                                        InvoiceID: this.state.selectedItem.InvoiceID
                                    },
                                    resource: Resources.InvoicesInvoice
                                }))
                            }
                        }}

                        onDeletePayment={(item) => {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get('user'),
                                resource: Resources.InvoicesPayment,
                                query: {
                                    RecvPaymentID: item.RecvPaymentID
                                },
                                piggySecondResource: Resources.InvoicesInvoice,
                                secondQuery: {
                                    InvoiceID: this.state.selectedItem.InvoiceID
                                },
                                errorMessage: true, successMessage: translate('text.PaymentDeletedSuccessfully')
                            }))
                        }}
                    />
                </ModalDefault>

                {this.props.ui.confirmDialog && (
                    <ModalConfirm
                        text={this.state.text}
                        name={this.state.confirmText}
                        submit={this.state.confirmModal}
                        cancel={this.hideConfirmDialog}
                        {...this.props}
                    />
                )}

                <ModalDefault
                    show={this.state.invoicePreviewDialog}
                    limitHeight={true}
                    widthClass={'max-w-7xl'}
                    title={translate('preview') + (this.state.previewDocument?.Description ? ' - ' + this.state.previewDocument?.Description : '')}

                    onButtonClick={this.handleDownloadDocument}
                    buttonLabel={translate('Download')}

                    customButtonsHTML={(
                        <div>
                            <button
                                className={`btn btn-primary mr-2`}
                                onClick={() => onDocumentPrint(Env.getApiUrl('api/' + Resources.BillingDocuments, Object.assign({}, {
                                    InvoiceID: this.state.previewDocument?.InvoiceID,
                                    token: getJWT().access_token,
                                    type: 1,
                                    name: 'invoice_' + this.state.previewDocument?.InvoiceID + '.pdf'
                                })), 'pdf')}
                            >
                                {translate('btn.Print')}
                            </button>
                        </div>
                    )}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={this.toggleProcessPreview}
                    isLoading={false}
                >
                    {!!this.state.invoicePreviewDialog && (
                        <FileViewer
                            fileType={'pdf'}
                            filePath={Env.getApiUrl('api/' + Resources.BillingDocuments, Object.assign({}, {
                                InvoiceID: this.state.previewDocument?.InvoiceID,
                                token: getJWT().access_token,
                                type: 1,
                                name: 'invoice_' + this.state.previewDocument?.InvoiceID + '.pdf'
                            }))}
                            onError={(e) => {
                                console.log(e)
                            }}
                        />
                    )}
                </ModalDefault>

                <BulkPaymentDialog
                    show={this.state.batchActionDialog}
                    translate={translate}
                    onClose={this.handleToggleBatchDialog}
                    selectedItems={this.state.selectedItems}
                    onSubmit={this.submitBulkPayment}
                    defaults={defaults}
                    itemKeyLabel={'InvoiceID'}
                    {...this.props}
                />

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                {(this.state.isOrganizationInfoDialogOpen &&
                    <OrganizationInfoDialog
                        show={this.state.isOrganizationInfoDialogOpen}
                        translate={translate}
                        organizationID={this.state.selectedOrganization?.OrganizationID}
                        handleClose={() => this.setState({
                            isOrganizationInfoDialogOpen: false
                        })}
                    />
                )}

                {(this.state.isContactInfoDialogOpen &&
                    <ContactInfoDialog
                        show={this.state.isContactInfoDialogOpen}
                        translate={translate}
                        contactID={this.state.selectedContact?.ContactID}
                        handleClose={() => this.setState({
                            isContactInfoDialogOpen: false
                        })}
                    />
                )}

                <ModalReminder
                    show={this.state.isReminderDialogOpen}
                    selectedItem={this.state.selectedItem ?? {}}
                    noteFieldName={"CollectionNote"}
                    onCloseReminderModalClick={this.handleCloseReminderModalClick}
                    onSubmit={(params) => {
                        if (this.state.selectedRows && !this.state.selectedItem) {
                            params.InvoiceIDs = Object.values(this.state.selectedRows).reduce((memo, it) => {
                                memo.push(it.InvoiceID)

                                return memo
                            }, [])
                        } else {
                            params.InvoiceIDs = [this.state.selectedItem.InvoiceID];
                        }

                        params.InvoiceCommentNote = params.CollectionNote;
                        delete params.CollectionNote;
                        delete params.Days;
                        delete params.Hours;
                        delete params.Minutes;
                        delete params.StartReminderDate;
                        delete params.StartReminderDateTime;

                        this.props.dispatch(createResource({
                            user: LocalStorage.get('user'),
                            params: params,
                            piggyResource: this.getResource(),
                            query: this.getQuery(),
                            errorMessage: true,
                            successMessage: `Reminder updated successfully`,
                            resource: Resources.AccountingIncomeInvoiceComment,
                        }));

                        this.setState({isReminderDialogOpen: false});
                    }}
                    translate={translate}
                />

                <SetFactoringModal
                    show={this.state.isSetFactoringBalanceModalOpen}
                    onClose={this.toggleSetFactoringBalanceModal}
                    selectedItem={this.state?.selectedItem}
                    query={this.getQuery()}
                    translate={translate}
                />

                {this.state.importExcelDataModalOpen && (
                    <CollectionsFactoringImportDialog
                        show={this.state.importExcelDataModalOpen}
                        query={this.getQuery()}
                        piggyResource={this.getResource()}
                        onClose={() => {
                            this.setState({
                                importExcelDataModalOpen: false
                            })
                        }}
                        translate={translate}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default connect(state => state)(FactoringCollectionsTab)
