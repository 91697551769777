import React, {Component} from 'react'
import {getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import moment from 'moment-timezone'
import {checkPerm, classNames, getProp} from '../../../common/util/util-helpers'
import {genericMoneyFormatter} from '../../../common/util/util-vanilla'
import {
    ArrowPathIcon,
    Bars4Icon,
    ChevronDownIcon,
    ChevronRightIcon,
    CurrencyDollarIcon,
    DocumentDuplicateIcon,
    MinusCircleIcon,
    XCircleIcon
} from '@heroicons/react/24/outline'
import Tippy from '@tippyjs/react'
import {DocumentIcon, Squares2X2Icon} from '@heroicons/react/20/solid'
import PlusIcon from '@heroicons/react/20/solid/PlusIcon'
import {download} from '../../../data/actions/download'
import {currentDate} from '../../../common/util/util-dates'
import {getSecondResource} from '../../../data/actions/secondResource'
import AccountReportDialog from '../dialogs/account-report-dialog'
import {
    ACCOUNT_CREDIT_CARD,
    ACCOUNT_PAYABLE,
    ACCOUNT_RECEIVABLE,
    ACCOUNT_TYPE_CASH,
    ACCOUNT_TYPE_FIXED_ASSETS,
    ACCOUNT_TYPE_NON_CURRENT_LIABILITY,
    ACCOUNT_TYPE_OTHER_CURRENT_ASSETS,
    ACCOUNT_TYPE_OTHER_CURRENT_LIABILITY,
    ACCOUNT_TYPE_OWNER_EQUITY,
    READ_PERM
} from '../../../util/util-constants'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import InfoParagraph from "../../../common/components/info-paragraph";
import FieldDate from "../../../common/components/fields/field-date";
import {fieldsToHtml, getFieldLabelClass} from "../../../common/util/util-fields";
import ModalDefault from "../../../common/components/modal/modal-default";
import {LoaderSmall} from "../../../common/components/loader";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Card from "../../../common/components/card";
import {DEFAULT_DATABASE_DATETIME_FORMAT} from "../../../common/util/util-consts";

export default class BalanceSheetTab extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fields: {
                StartDate: new Field('StartDate', moment().format('YYYY-MM-DD 00:00:00'), ['date'], false, 'date', {
                    label: 'AsOfDate',
                    addContainerClass: 'col-span-4'
                })
            },
            columnsInData: 2,
            comparisonDataView: false,
            comparisonDates: [],
            condenseMap: {},
            zeroValuesShown: true
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingBalanceSheet,
            query: this.getQuery()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingBalanceSheet,
            query: Object.assign({
                format: 'EXCEL',
                name: 'accounting_balance_sheet_' + currentDate() + '.xlsx',
                HideZeroValues: !this.state.zeroValuesShown ? 1 : 0,
                condenseMap: Object.keys(this.state.condenseMap)
            }, this.getQuery())
        }))
    }

    downloadPdf = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingBalanceSheet,
            query: Object.assign({
                format: 'PDF',
                name: 'accounting_balance_sheet_' + currentDate() + '.pdf',
                HideZeroValues: !this.state.zeroValuesShown ? 1 : 0,
                condenseMap: Object.keys(this.state.condenseMap)
            }, this.getQuery())
        }))
    }

    downloadExcelReports = (query) => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingAccountReport,
            query: Object.assign({
                format: 'EXCEL',
                name: 'transactions_' + currentDate() + '.xlsx'
            }, query)
        }))
    }
    /** UI Events
     ================================================================= */
    handleDatesInputChange = (name, value, index) => {
        let fields = this.state.comparisonDates
        fields[index].Date.value = value
        this.setState({comparisonDates: fields})
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)}, this.fetchData)
    }

    handleSetDataView = (columnsInData) => {
        this.setState({columnsInData})
    }

    handleCompareBalanceSheet = () => {
        if (this.state.comparisonDates.length > 0) {
            this.setState({
                comparisonDates: [],
                comparisonDataView: false
            }, this.fetchData)
        } else {
            this.setState({
                comparisonDates: [
                    {
                        Date: new Field('Date', moment().format('YYYY-MM-DD 00:00:00'), ['date'], false, 'date', {
                            label: 'AsOfDate'
                        })
                    },
                ],
                comparisonDataModal: true
            })
        }
    }

    handleToggleAccountDetails = (selectedItem) => {
        this.setState({
            selectedItem: selectedItem,
            accountDetailsDialog: !this.state.accountDetailsDialog
        })
    }

    /** Render
     ================================================================= */
    getDiffPercentage = (prevValue, currValue) => {
        if ((prevValue == 0 || !prevValue) && (currValue == 0 || !currValue)) {
            return 0
        }
        if (prevValue == 0 || !prevValue) {
            return currValue.toFixed(1)
        }
        if (currValue == 0 || !currValue) {
            return prevValue.toFixed(1)
        }
        let mult = prevValue < currValue ? 1 : -1;
        return mult * (((Math.abs(prevValue - currValue)) / ((prevValue + currValue) / 2)) * 100).toFixed(1)
    }

    getAccountTableValues = (item, level = 0) => {
        let children = []
        if (item.Children && item.Children.length > 0) {
            children = item.Children.map(it => this.getAccountTableValues(it, level + 1))
        }
        const dropdownKey = `dropdownChild${level}${item.AccountID}${item.AccountTypeID}`
        const paddingLeft = (level + 3) * 20

        const values = this.state.comparisonDates.map((elem, index) => {
            const Date = elem.Date.value.split(' ')[0]
            const prevDate = index != 0 ? this.state.comparisonDates[index - 1].Date.value.split(' ')[0] ?? 0 : null
            let presentedValue = item.Value[Date]
            let previousValue = prevDate ? item.Value[prevDate] : 0
            const percentage = this.getDiffPercentage(previousValue, presentedValue)

            if (!!this.state[dropdownKey] && item.Children && item.Children.length > 0) {
                presentedValue = item.Children.reduce((memo, it) => {
                    return memo + it.Value[Date]
                }, (item.Value[Date] ?? 0))
            }

            return (
                <td className={'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-tm-gray-500 '}>
                    {this.getFormatedMoneyValue(presentedValue)}<span> </span>
                    {index != 0 && (
                        <>
                            {percentage > 0 ? (
                                <span className={'text-green-600 font-bold'}>
                                    ({percentage}%)
                                </span>
                            ) : percentage < 0 ? (
                                <span className={'text-red-600 font-bold'}>
                                    ({percentage}%)
                                </span>
                            ) : (
                                <span className={'text-gray-600 font-bold'}>
                                (0.0%)
                                </span>
                            )}
                        </>
                    )}
                </td>
            )
        })

        return (
            <>
                <tr className={'border-gray-300 border-t hover:bg-tm-gray-300'}>
                    <td
                        className={'whitespace-nowrap py-4 pr-3 text-sm font-medium text-tm-gray-900 flex justify-start items-center cursor-pointer'}
                        style={{paddingLeft: paddingLeft}}
                        onClick={() => {
                            if (item.Children && item.Children.length > 0) {
                                this.setState({[dropdownKey]: !this.state[dropdownKey]})
                            } else {
                                this.handleToggleAccountDetails(item)
                            }
                        }}
                    >
                        {!this.state[dropdownKey] ?
                            (<ChevronDownIcon
                                className={`h-4 w-4 opacity-${item.Children && item.Children.length > 0 ? '100' : 0}`}/>)
                            :
                            (<ChevronRightIcon
                                className={`h-4 w-4 opacity-${item.Children && item.Children.length > 0 ? '100' : 0}`}/>)
                        }
                        <span className="ml-3">{item.Name}</span>
                    </td>
                    {values}
                </tr>
                {!this.state[dropdownKey] && children}
            </>
        )
    }

    getTableAccounts = (accounts, accountTypes) => {
        const result = Object.keys(accounts)
            .map((key, idx) => {
                const dropdownKey = `dropdownAccounts${key}`
                const accountItems = accounts[key].map((it) => {
                    return this.getAccountTableValues(it)
                })

                const totals = this.state.comparisonDates.map((elem, index) => {
                    const Date = elem.Date.value.split(' ')[0]
                    const prevDate = index != 0 ? this.state.comparisonDates[index - 1].Date.value.split(' ')[0] : null
                    const total = accounts[key].reduce((memo, it) => {
                        return memo + parseFloat(it.Value[Date] ?? 0)
                    }, 0)

                    const prevTotal = !!prevDate && accounts[key].reduce((memo, it) => {
                        return memo + parseFloat(it.Value[prevDate] ?? 0)
                    }, 0)
                    const percentage = this.getDiffPercentage(prevTotal, total)

                    return (
                        <td
                            className={'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-tm-gray-900'}>
                            {this.getFormatedMoneyValue(total)}
                            {index != 0 && (
                                <>
                                    {percentage > 0 ? (
                                        <span className={'text-green-600 font-bold'}>
                                    ({percentage}%)
                                </span>
                                    ) : percentage < 0 ? (
                                        <span className={'text-red-600 font-bold'}>
                                    ({percentage}%)
                                </span>
                                    ) : (
                                        <span className={'text-gray-600 font-bold'}>
                                (0.0%)
                                </span>
                                    )}
                                </>
                            )}
                        </td>
                    )
                })

                return (
                    <>
                        <tr
                            onClick={() => this.setState({[dropdownKey]: !this.state[dropdownKey]})}
                            className={'border-gray-300 border-t hover:bg-tm-gray-300 cursor-pointer'}>
                            <td
                                className={' whitespace-nowrap py-4 pl-10 pr-3 text-sm font-medium text-tm-gray-900 flex justify-start items-center'}>
                                {!this.state[dropdownKey] ?
                                    (<ChevronDownIcon className={'h-4 w-4 mr-3'}/>)
                                    :
                                    (<ChevronRightIcon className={'h-4 w-4 mr-3'}/>)
                                }
                                {accountTypes[key].AccountType}
                            </td>
                            {this.state.comparisonDates.map((it) => (
                                <td className={'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-tm-gray-900'}/>
                            ))}
                        </tr>
                        {!this.state[dropdownKey] && accountItems}

                        <tr
                            className={'border-gray-300 border-t hover:bg-tm-gray-300 cursor-pointer'}>
                            <td
                                className={'font-bold whitespace-nowrap py-4 pl-10 pr-3 text-sm font-medium text-tm-gray-900 flex justify-start items-center'}>
                                {this.props.translate('text.Total')} {accountTypes[key].AccountType}:
                            </td>
                            {totals}
                        </tr>
                    </>
                )
            })

        return (
            <>
                {result}
            </>
        )
    }

    getAccounts = (accounts, accountTypes) => {
        let grandTotal = 0
        const result = Object.keys(accounts)
            .map((key, idx) => {
                const dropdownKey = `dropdownAccounts${key}`
                const accountItems = accounts[key] && accounts[key]?.map((it) => {
                    return this.getAccountValues(it)
                })

                const total = accounts[key] && accounts[key].reduce((memo, it) => {
                    return memo + this.getAccountTotals(it)
                }, 0)

                grandTotal += total

                return accountTypes[key] && (
                    <div className="bg-inverse">
                        <h3
                            onClick={() => this.setState({[dropdownKey]: !this.state[dropdownKey]})}
                            className="hover:bg-tm-gray-300 py-2 pl-5 text-lg leading-6 font-medium text-tm-gray-500 flex justify-start items-center cursor-pointer">
                            {!this.state[dropdownKey] ?
                                (<ChevronDownIcon className={'h-4 w-4 mr-3'}/>)
                                :
                                (<ChevronRightIcon className={'h-4 w-4 mr-3'}/>)
                            }
                            {accountTypes[key].AccountType}
                        </h3>
                        {!this.state[dropdownKey] && (
                            accountItems
                        )}
                        <div
                            className="flex py-2 pl-10 items-center justify-between p-2 font-bold border-y border-tm-gray-300">
                            <div>{this.props.translate('text.Total')} {accountTypes[key].AccountType}:</div>
                            <div>{this.getFormatedMoneyValue(total)}</div>
                        </div>
                    </div>
                )
            })

        return (
            <>
                {result}
            </>
        )
    }

    getAccountValues = (item, level = 0) => {
        let children = []
        if (item?.Children && item.Children.length > 0) {
            children = item.Children.map(it => this.getAccountValues(it, level + 1))
        }
        const dropdownKey = `dropdownChild${level}${item.AccountID}${item.AccountTypeID}`
        const paddingLeft = (level + 2) * 20
        let presentedValue = item.Value
        if (!!this.state[dropdownKey] && item.Children && item.Children.length > 0) {
            presentedValue = item.Children.reduce((memo, it) => {
                return memo + it.Value
            }, (item.Value ?? 0))
        }
        const childrenHaveValues = children.filter(it => !!it).length > 0

        return !this.state.zeroValuesShown && !item.Value && !childrenHaveValues ? '' : (
            <div>
                <div
                    style={{paddingLeft: paddingLeft}}
                    onClick={() => {
                        if (item.Children && item.Children.length > 0) {
                            const oldMap = Object.assign({
                            }, this.state.condenseMap);
                            if (!this.state[dropdownKey]) {
                                oldMap[item.AccountID] = 1;
                            } else {
                                delete oldMap[item.AccountID];
                            }
                            this.setState({
                                [dropdownKey]: !this.state[dropdownKey],
                                condenseMap: oldMap
                            });
                        } else {
                            this.handleToggleAccountDetails(item)
                        }
                    }}
                    className="hover:bg-tm-gray-300 py-2 pr-0 text-lg leading-6 font-medium text-tm-gray-500 flex justify-start items-center cursor-pointer">
                    {!this.state[dropdownKey] ?
                        (<ChevronDownIcon
                            className={`h-4 w-4 opacity-${item.Children && item.Children.length > 0 ? '100' : 0}`}/>)
                        :
                        (<ChevronRightIcon
                            className={`h-4 w-4 opacity-${item.Children && item.Children.length > 0 ? '100' : 0}`}/>)
                    }

                    <div className={'ml-3 w-full flex items-center justify-between'}>
                        <span>{item.Name}</span>
                        <span>{!item.HideValue && this.getFormatedMoneyValue(presentedValue)}</span>
                    </div>
                </div>
                {!this.state[dropdownKey] && children}

                {!!item.HideValue && (
                    <div
                        className="flex py-2 items-center justify-between font-bold border-y border-tm-gray-300"
                        style={{paddingLeft: paddingLeft + 20}}
                    >
                        <div>{this.props.translate('text.Total')} {item.Name}:</div>
                        <div>{this.getFormatedMoneyValue(item.Value)}</div>
                    </div>
                )}
            </div>
        )
    }

    getAccountTotals = (item) => {
        return parseFloat(item.Value)
    }

    getQuery = () => {
        const StartDate = this.state.comparisonDates.map(it => {
            return it.Date.value.split(' ')[0]
        })

        return this.state.comparisonDates.length > 0 ?
            {StartDate: StartDate}
            :
            FieldsManager.getFieldKeyValues(this.state.fields)
    }

    getFormatedMoneyValue = (value) => {
        return genericMoneyFormatter(value)
    }

    getFormattedAssetAsParent = (arr, AccountType, name) => {
        const Value = arr.reduce((memo, it) => {
            return memo + (it.Value ?? 0)
        }, 0)
        return {
            AccountID: 'Parent' + AccountType,
            AccountTypeID: AccountType,
            Children: arr,
            Name: name,
            Value: Value,
            HideValue: true,
        }
    }

    getFormattedAssetCategories = () => {
        const Assets = getProp(this.props, 'resource.data.Assets', {})

        const CurrentAssets = []

        if (Assets[ACCOUNT_TYPE_CASH]) CurrentAssets.push(this.getFormattedAssetAsParent(Assets[ACCOUNT_TYPE_CASH], ACCOUNT_TYPE_CASH, 'Cash and cash equivalents'))
        if (Assets[ACCOUNT_RECEIVABLE]) CurrentAssets.push(this.getFormattedAssetAsParent(Assets[ACCOUNT_RECEIVABLE], ACCOUNT_RECEIVABLE, 'Accounts Receivable (A/R)'))
        if (Assets[ACCOUNT_TYPE_OTHER_CURRENT_ASSETS]) CurrentAssets.push(this.getFormattedAssetAsParent(Assets[ACCOUNT_TYPE_OTHER_CURRENT_ASSETS], ACCOUNT_TYPE_OTHER_CURRENT_ASSETS, 'Other Current Assets'))

        const data = Object.assign({}, {
            0: CurrentAssets,
            [ACCOUNT_TYPE_FIXED_ASSETS]: Assets[ACCOUNT_TYPE_FIXED_ASSETS],
        }, Assets)

        delete data[ACCOUNT_TYPE_CASH]
        delete data[ACCOUNT_RECEIVABLE]
        delete data[ACCOUNT_TYPE_OTHER_CURRENT_ASSETS]

        return this.props.resource.isLoading ? Assets : data
    }

    getFormattedLiabilityCategories = () => {
        const Liability = getProp(this.props, 'resource.data.Liability', {})
        const CurrentLiability = []

        if (Liability[ACCOUNT_CREDIT_CARD]) CurrentLiability.push(this.getFormattedAssetAsParent(Liability[ACCOUNT_CREDIT_CARD], ACCOUNT_CREDIT_CARD, 'Credit Cards'))
        if (Liability[ACCOUNT_PAYABLE]) CurrentLiability.push(this.getFormattedAssetAsParent(Liability[ACCOUNT_PAYABLE], ACCOUNT_PAYABLE, 'Accounts Payable (A/P)'))
        if (Liability[ACCOUNT_TYPE_OTHER_CURRENT_LIABILITY]) CurrentLiability.push(this.getFormattedAssetAsParent(Liability[ACCOUNT_TYPE_OTHER_CURRENT_LIABILITY], ACCOUNT_TYPE_OTHER_CURRENT_LIABILITY, 'Other Current Liabilities'))

        const data = Object.assign({}, {
            1: CurrentLiability,
            [ACCOUNT_TYPE_NON_CURRENT_LIABILITY]: Liability[ACCOUNT_TYPE_NON_CURRENT_LIABILITY]
        }, Liability)

        // These are already formatted in code above
        delete data[ACCOUNT_CREDIT_CARD]
        delete data[ACCOUNT_PAYABLE]
        delete data[ACCOUNT_TYPE_OTHER_CURRENT_LIABILITY]

        // Moved to separate section
        delete data[ACCOUNT_TYPE_OWNER_EQUITY]

        return this.props.resource.isLoading ? Liability : data
    }

    getFormattedEquityAccounts = () => {
        const Liability = getProp(this.props, 'resource.data.Liability', {})

        return this.props.resource.isLoading ? {} : Object.assign({}, {
            [ACCOUNT_TYPE_OWNER_EQUITY]: Liability[ACCOUNT_TYPE_OWNER_EQUITY]
        })
    }

    getAccountTypes = () => {
        const accountTypes = getProp(this.props, 'resource.data.AccountTypes', {})
        accountTypes[0] = {
            AccountType: 'Current Assets',
            AccountTypeID: 0,
        }
        accountTypes[1] = {
            AccountType: 'Current Liabilities',
            AccountTypeID: 1,
        }
        return accountTypes
    }

    render() {
        const {translate} = this.props

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange)

        const accountTypes = this.getAccountTypes()

        const tableAssetsAccounts = this.getTableAccounts(getProp(this.props, 'resource.data.Assets', []), accountTypes)
        const assetsAccounts = this.getAccounts(this.getFormattedAssetCategories(), accountTypes)
        const assetsTotal = getProp(this.props, 'resource.data.TotalAssets', 0)

        const liabilitiesAccounts = this.getAccounts(this.getFormattedLiabilityCategories(), accountTypes)
        const tableLiabilitiesAccounts = this.getTableAccounts(getProp(this.props, 'resource.data.Liability', []), accountTypes)

        const equityAccounts = this.getAccounts(this.getFormattedEquityAccounts(), accountTypes)

        const liabilitiesTotal = getProp(this.props, 'resource.data.TotalLiability', 0)

        const equityTotals = getProp(this.props, 'resource.data.Equity', {
            'NetIncome': 0,
            'RetainedEarnings': 0
        })

        const ownerEquities = this.getFormattedEquityAccounts()
        const ownerEquityTotal = Object.values(ownerEquities).reduce((memo, it) => {
            const categoryTotal = it ? it.reduce((a, b) => {
                return a + (b.Value ?? 0)
            }, 0) : 0

            return memo + categoryTotal
        }, 0)

        return (
            <Card>
                <div className="">
                    <div className="mb-5">
                        <PageHeader
                            title={translate('page.heading.BalanceSheet')}
                        >
                            <div className="">
                                <Tippy
                                    content={translate(`text.${this.state.zeroValuesShown ? 'HideZeroValues' : 'ShowZeroValues'}`)}>
                                    <button
                                        className={classNames('btn-icon relative cursor-pointer relative z-10 p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2', !!this.state.zeroValuesShown ? 'text-secondary-500 bg-tm-gray-100 ' : 'text-red-500 bg-red-50')}
                                        onClick={() => this.setState({zeroValuesShown: !this.state.zeroValuesShown})}
                                    >
                                        <CurrencyDollarIcon className="w-5 h-5"/>
                                        {!this.state.zeroValuesShown && (
                                            <span
                                                className="top-1.5 left-1.5 animate-ping absolute inline-flex h-6 w-6 rounded-full bg-red-400 opacity-75"></span>
                                        )}
                                    </button>
                                </Tippy>
                                <Tippy content={translate('text.download_excel')}>
                                    <button
                                        className="btn-icon"
                                        onClick={this.downloadExcel}
                                    >
                                        <DocumentArrowDownIcon className="w-5 h-5"/>
                                    </button>
                                </Tippy>
                                <Tippy content={translate('text.download_pdf')}>
                                    <button
                                        className="btn-icon"
                                        onClick={this.downloadPdf}
                                    >
                                        <DocumentIcon className="w-5 h-5"/>
                                    </button>
                                </Tippy>
                                <Tippy
                                    content={translate(`text.${this.state.comparisonDates.length > 0 ? 'ClearFilter' : 'CompareDates'}`)}>
                                    <button
                                        className="btn-icon"
                                        onClick={this.handleCompareBalanceSheet}
                                    >
                                        <DocumentDuplicateIcon
                                            className={`w-5 h-5 ${this.state.comparisonDates.length > 0 ? 'text-red-600' : ''}`}/>
                                    </button>
                                </Tippy>

                                <button
                                    className={
                                        classNames(
                                            "btn btn-header z-10 ml-8"
                                        )
                                    }
                                    onClick={this.fetchData}
                                >
                                    <ArrowPathIcon className={
                                        classNames(
                                            "w-5 h-5",
                                            this.props.resource?.isLoading || this.props.secondResource?.isLoading ? "animate-spin" : undefined
                                        )
                                    }/>
                                </button>
                            </div>
                        </PageHeader>
                    </div>

                    {!!this.state.comparisonDataView && (
                        <>
                            <div className="mt-8 flex flex-col">
                                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                        <div
                                            className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                            <table className="min-w-full">
                                                <thead className="bg-inverse">
                                                <tr>
                                                    <th scope="col"
                                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-tm-gray-500"/>
                                                    {this.state.comparisonDates.map((it, index) => (
                                                        <th scope="col"
                                                            className="px-3 py-3.5 text-left text-sm font-semibold text-tm-gray-900">
                                                            <div className="flex">
                                                                <div>{moment(it.Date.value).format('MMMM Do YYYY')}</div>
                                                                <Tippy content={
                                                                    <span>{translate('text.RemoveDateFromComparison')}</span>}>
                                                                    <MinusCircleIcon
                                                                        onClick={() => {
                                                                            let comparisonDates = this.state.comparisonDates
                                                                            comparisonDates.splice(index, 1)
                                                                            this.setState({
                                                                                comparisonDates: comparisonDates,
                                                                                comparisonDataView: comparisonDates.length !== 0 // Changing view if last removed
                                                                            }, () => {
                                                                                this.fetchData()
                                                                            })
                                                                        }}
                                                                        className={'-rotate-45 h-4 w-4 text-red-600 ml-3 cursor-pointer'}/>
                                                                </Tippy>
                                                            </div>
                                                        </th>
                                                    ))}
                                                </tr>
                                                </thead>
                                                <tbody className="bg-inverse">
                                                {/* ASSETS */}
                                                <tr
                                                    onClick={() => this.setState({dropdownAssets: !this.state.dropdownAssets})}
                                                    className={'hover:bg-tm-gray-300 border-gray-300 border-t'}
                                                >
                                                    <td
                                                        className="flex justify-start items-center cursor-pointer whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-tm-gray-900">
                                                        {!this.state.dropdownAssets ?
                                                            (<ChevronDownIcon className={'h-4 w-4 mr-3'}/>)
                                                            :
                                                            (<ChevronRightIcon className={'h-4 w-4 mr-3'}/>)
                                                        }
                                                        {translate('text.ASSETS')}
                                                    </td>

                                                    {this.state.comparisonDates.map((it) => (
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-tm-gray-900"/>
                                                    ))}
                                                </tr>
                                                {!this.state.dropdownAssets && tableAssetsAccounts}
                                                <tr
                                                    className={'hover:bg-tm-gray-300 border-gray-300 border-t'}
                                                >
                                                    <td
                                                        className="flex justify-start items-center cursor-pointer whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-tm-gray-900">
                                                        {translate('text.TotalAssets')}
                                                    </td>
                                                    {this.state.comparisonDates.map((it) => {
                                                        const assets = getProp(this.props, 'resource.data.TotalAssets', {})
                                                        const Date = it.Date.value.split(' ')[0]

                                                        return (
                                                            <td
                                                                className="whitespace-nowrap px-3 py-4 text-sm text-tm-gray-900 text-right">{this.getFormatedMoneyValue(assets[Date])}</td>
                                                        )
                                                    })}
                                                </tr>

                                                {/* LIABILITIES */}
                                                <tr
                                                    onClick={() => this.setState({dropdownLiabilities: !this.state.dropdownLiabilities})}
                                                    className={'hover:bg-tm-gray-300 border-gray-300 border-t'}
                                                >
                                                    <td
                                                        className="flex justify-start items-center cursor-pointer whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-tm-gray-900">
                                                        {!this.state.dropdownLiabilities ?
                                                            (<ChevronDownIcon className={'h-4 w-4 mr-3'}/>)
                                                            :
                                                            (<ChevronRightIcon className={'h-4 w-4 mr-3'}/>)
                                                        }
                                                        {translate('text.LIABILITIES')}
                                                    </td>
                                                    {this.state.comparisonDates.map((it) => (
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"/>
                                                    ))}
                                                </tr>
                                                {!this.state.dropdownLiabilities && tableLiabilitiesAccounts}
                                                <tr
                                                    className={'hover:bg-tm-gray-300 border-gray-300 border-t'}
                                                >
                                                    <td
                                                        className="font-bold flex justify-start items-center cursor-pointer whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-tm-gray-900">
                                                        {translate('text.NetIncome')}
                                                    </td>
                                                    {this.state.comparisonDates.map((it) => {
                                                        const Equity = getProp(this.props, 'resource.data.Equity', {})
                                                        const Date = it.Date.value.split(' ')[0]

                                                        return (
                                                            <td
                                                                className="whitespace-nowrap px-3 py-4 text-sm text-tm-gray-900 text-right">{this.getFormatedMoneyValue(Equity[Date]?.NetIncome ?? 0)}</td>
                                                        )
                                                    })}
                                                </tr>
                                                <tr
                                                    className={'hover:bg-tm-gray-300 border-gray-300 border-t'}
                                                >
                                                    <td
                                                        className="font-bold flex justify-start items-center cursor-pointer whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-tm-gray-900">
                                                        {translate('text.RetainedEarnings')}
                                                    </td>
                                                    {this.state.comparisonDates.map((it) => {
                                                        const Equity = getProp(this.props, 'resource.data.Equity', {})
                                                        const Date = it.Date.value.split(' ')[0]

                                                        return (
                                                            <td
                                                                className="whitespace-nowrap px-3 py-4 text-sm text-tm-gray-900 text-right">{this.getFormatedMoneyValue(Equity[Date]?.RetainedEarnings ?? 0)}</td>
                                                        )
                                                    })}
                                                </tr>
                                                <tr
                                                    className={'hover:bg-tm-gray-300 border-gray-300 border-t'}
                                                >
                                                    <td
                                                        className="flex justify-start items-center cursor-pointer whitespace-nowrap py-4 pl-4 pr-3 text-lg font-medium text-tm-gray-900">
                                                        {translate('text.TotalEquityAndLiabilities')}
                                                    </td>
                                                    {this.state.comparisonDates.map((it) => {
                                                        const Equity = getProp(this.props, 'resource.data.Equity', {})
                                                        const TotalLiability = getProp(this.props, 'resource.data.TotalLiability', {})
                                                        const Date = it.Date.value.split(' ')[0]

                                                        return (
                                                            <td
                                                                className="whitespace-nowrap px-3 py-4 text-sm text-tm-gray-900 text-right">{this.getFormatedMoneyValue((Equity[Date]?.NetIncome ?? 0) + (Equity[Date]?.RetainedEarnings ?? 0) + (TotalLiability[Date] ?? 0))}</td>
                                                        )
                                                    })}
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {!this.state.comparisonDataView && (
                        <>
                            <div className="row mb-4">
                                <div className="col p-5 grid grid-cols-12 gap-4">
                                    {fieldsHtml}
                                </div>
                                <div className="col flex justify-end items-end">
                                    <div className="flex justify-end items-end">
                                        <Tippy content={translate('text.StackedView')}>
                                            <button
                                                onClick={() => this.handleSetDataView(1)}
                                                className={`${this.state.columnsInData === 1 && 'bg-tm-gray-200-500'} h-full relative inline-flex items-center px-4 py-2 rounded-l-md border border-tm-gray-300 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 text-tm-gray-700`}
                                            >
                                                <Bars4Icon
                                                    className={`${this.state.columnsInData === 1 && 'text-primary'} w-5 h-5`}
                                                    aria-hidden="true"/>
                                            </button>
                                        </Tippy>
                                        <Tippy content={translate('text.SplitView')}>
                                            <button
                                                onClick={() => this.handleSetDataView(2)}
                                                className={`${this.state.columnsInData === 2 && 'bg-tm-gray-200-500'} h-full -ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-tm-gray-300 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 text-tm-gray-700`}
                                            >
                                                <Squares2X2Icon
                                                    className={`${this.state.columnsInData === 2 && 'text-primary'} w-5 h-5`}
                                                    aria-hidden="true"/>
                                            </button>
                                        </Tippy>
                                    </div>
                                </div>
                            </div>
                            {/* HEADER */}
                            <div className="text-center">
                                <h1 className={'font-bold'}>{translate('text.BALANCE_SHEET')}</h1>
                                <h2>As
                                    of {moment(this.state.fields.StartDate.value, 'YYYY-MM-DD 00:00:00').format('MMMM Do YYYY')}</h2>
                            </div>

                            <div className="flex items-center justify-between border-b border-gray-300">
                                <div className={'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-tm-gray-500'}/>
                                <div className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-right font-bold">
                                    {translate('text.Total')}
                                </div>
                            </div>

                            <div className="grid grid-cols-12 gap-5">
                                <div className={`col-span-${12 / this.state.columnsInData}`}>
                                    {/* ASSETS BODY */}
                                    <h3
                                        onClick={() => this.setState({dropdownAssets: !this.state.dropdownAssets})}
                                        className="hover:bg-tm-gray-300 p-2 text-lg leading-6 font-medium text-tm-gray-900 flex justify-start items-center cursor-pointer">
                                        {!this.state.dropdownAssets ?
                                            (<ChevronDownIcon className={'h-4 w-4 mr-3'}/>)
                                            :
                                            (<ChevronRightIcon className={'h-4 w-4 mr-3'}/>)
                                        }
                                        {translate('text.ASSETS')}
                                    </h3>
                                    {!this.state.dropdownAssets && assetsAccounts}
                                    <div className="flex items-center justify-between p-2 pl-5 font-bold text-lg">
                                        <div>{translate('text.TotalAssets')}:</div>
                                        <div>{this.getFormatedMoneyValue(assetsTotal)}</div>
                                    </div>
                                </div>

                                <div
                                    className={`col-span-${12 / this.state.columnsInData} ${this.state.columnsInData === 2 ? 'border-l border-gray-300' : ''}`}>
                                    {/* LIABILITIES BODY */}
                                    <h3
                                        onClick={() => this.setState({dropdownLiabilities: !this.state.dropdownLiabilities})}
                                        className="hover:bg-tm-gray-300 p-2 text-lg leading-6 font-medium text-tm-gray-900 flex justify-start items-center cursor-pointer">
                                        {!this.state.dropdownLiabilities ?
                                            (<ChevronDownIcon className={'h-4 w-4 mr-3'}/>)
                                            :
                                            (<ChevronRightIcon className={'h-4 w-4 mr-3'}/>)
                                        }
                                        {translate('text.LIABILITIES')}
                                    </h3>
                                    {!this.state.dropdownLiabilities && liabilitiesAccounts}
                                    <div className="flex items-center justify-between p-2 pl-5 font-bold text-lg">
                                        <div>{translate('text.TotalLiabilities')}:</div>
                                        <div>{this.getFormatedMoneyValue(liabilitiesTotal)}</div>
                                    </div>

                                    {/* OWNER EQUITIES*/}
                                    <h3
                                        onClick={() => this.setState({dropdownEquities: !this.state.dropdownEquities})}
                                        className="hover:bg-tm-gray-300 p-2 text-lg leading-6 font-medium text-tm-gray-500 flex justify-start items-center cursor-pointer">
                                        {!this.state.dropdownEquities ?
                                            (<ChevronDownIcon className={'h-4 w-4 mr-3'}/>)
                                            :
                                            (<ChevronRightIcon className={'h-4 w-4 mr-3'}/>)
                                        }
                                        {translate('text.EQUITY')}
                                    </h3>
                                    {!this.state.dropdownEquities && equityAccounts}
                                    <div className="flex items-center justify-between p-2 pl-5 font-bold">
                                        <div>{translate('text.NetIncome')}:</div>
                                        <div>{this.getFormatedMoneyValue(equityTotals.NetIncome)}</div>
                                    </div>
                                    <div className="flex items-center justify-between p-2 pl-5 font-bold">
                                        <div>{translate('text.RetainedEarnings')}:</div>
                                        <div>{this.getFormatedMoneyValue(equityTotals.RetainedEarnings)}</div>
                                    </div>
                                    <div
                                        className="flex py-2  items-center justify-between pl-5 p-2 font-bold border-y border-gray-300">
                                        <div>{translate('text.TotalEquity')}:</div>
                                        <div>{this.getFormatedMoneyValue(ownerEquityTotal + equityTotals.NetIncome + equityTotals.RetainedEarnings)}</div>
                                    </div>
                                    <div className="flex items-center justify-between p-2 pl-5 font-bold text-lg">
                                        <div>{translate('text.TotalEquityAndLiabilities')}:</div>
                                        <div>{this.getFormatedMoneyValue(equityTotals.NetIncome + equityTotals.RetainedEarnings + liabilitiesTotal)}</div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {this.props.resource.isLoading && (
                        <LoaderSmall/>
                    )}
                </div>

                <ModalDefault
                    show={!!this.state.comparisonDataModal}
                    widthClass={'max-w-xl'}
                    title={translate('text.SelectDatesForComparison')}

                    onButtonClick={() => {
                        // Removing duplicates
                        const dates = this.state.comparisonDates.filter((value, index, self) =>
                                index === self.findIndex((t) => (
                                    t.Date.value === value.Date.value
                                ))
                        )
                        this.setState({
                            comparisonDates: dates,
                            comparisonDataView: true,
                            comparisonDataModal: false
                        }, this.fetchData)
                    }}
                    buttonLabel={translate('btn.save')}

                    closeButtonLabel={translate('btn.Cancel')}
                    onClose={() => this.setState({comparisonDataModal: false, comparisonDates: []})}
                >
                    <div className="p-5">
                        {this.state.comparisonDates.map((item, i) => (
                            <div className={'mb-5'} key={i}>
                                <span className={classNames(getFieldLabelClass(), 'flex justify-between items-center')}>
                                    <span>{translate('field.Date')} {i + 1}</span>
                                    {i !== 0 && (
                                        <XCircleIcon
                                            onClick={() => {
                                                let comparisonDates = this.state.comparisonDates
                                                comparisonDates.splice(i, 1)
                                                this.setState({comparisonDates})
                                            }}
                                            className={'h-4 w-4 cursor-pointer'}/>
                                    )}
                                </span>
                                <FieldDate
                                    addClass={'form-control'}
                                    onChange={(name, value) => this.handleDatesInputChange(name, value, i)}
                                    showTimeSelect={false}
                                    value={this.state.comparisonDates[i].Date.value}
                                    {...item}
                                />
                            </div>
                        ))}

                        {this.state.comparisonDates.length < 5 ?
                            <div className="flex justify-center items-center mt-5">
                                <Tippy
                                    content={translate('btn.AddAnotherDate')}
                                >
                                    <button
                                        className="mx-1 bg-tm-gray-100 p-2 rounded-full text-tm-gray-700 hover:bg-tm-gray-200 focus:outline-none focus:ring-2 ring-offset-inverse ring-link focus:ring-offset-2"
                                        onClick={() => {
                                            this.setState({
                                                comparisonDates: this.state.comparisonDates.concat({Date: new Field('Date', moment().format('YYYY-MM-DD 00:00:00'), ['empty'], false, 'date')})
                                            })
                                        }}
                                    >
                                        <PlusIcon className="h-4 w-4"/>
                                    </button>
                                </Tippy>
                            </div>
                            :
                            <InfoParagraph message={'Maximum comparison dates reached.'}/>
                        }
                    </div>
                </ModalDefault>

                <AccountReportDialog
                    AccountID={this.state.selectedItem?.AccountID}
                    AccountName={this.state.selectedItem?.Name}
                    StartDate={moment(this.state.fields.StartDate?.value, DEFAULT_DATABASE_DATETIME_FORMAT).startOf('year').format(DEFAULT_DATABASE_DATETIME_FORMAT)}
                    EndDate={moment().format(DEFAULT_DATABASE_DATETIME_FORMAT)}
                    onClose={this.handleToggleAccountDetails}
                    show={checkPerm(Resources.AccountingAccountReport, READ_PERM) && this.state.accountDetailsDialog}
                    translate={this.props.translate}
                    getTransactions={(query) => {
                        this.props.dispatch(getSecondResource({
                            user: LocalStorage.get('user'),
                            resource: Resources.AccountingAccountReport,
                            query: query
                        }))
                    }}
                    downloadExcelReports={(query) => this.downloadExcelReports(query)}
                    isLoading={getProp(this.props, 'secondResource.isLoading', false)}
                    transactions={getProp(this.props, 'secondResource.data', [])}
                    thirdResource={this.props.thirdResource}
                    dialogResource={this.props.dialogResource}
                    dispatch={this.props.dispatch}
                />
            </Card>
        )
    }
}
