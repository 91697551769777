import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import Tippy from '@tippyjs/react'
import {CreditCardIcon, PencilIcon, TrashIcon} from '@heroicons/react/24/outline'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    UPDATE_PERM
} from '../../../util/util-constants'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    saveTableColumns
} from '../../../common/util/util-helpers'
import {cloneDeep} from '../../../common/util/util-vanilla'
import PayrollBatchCreateDialog from './dialogs/payroll-batch-create-dialog'
import PayrollBatchProcessDialog from './dialogs/payroll-batch-process-dialog'
import {createSecondResource, deleteSecondResource, getSecondResource,} from '../../../data/actions/secondResource'
import {getThirdResource} from '../../../data/actions/thirdResource'
import {download} from '../../../data/actions/download'
import {getDialogResource} from '../../../data/actions/dialogResource'
import DriverPayrollSettings from './dialogs/driver-payroll-settings'
import Cog6ToothIcon from "@heroicons/react/24/outline/esm/Cog6ToothIcon";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import NoRecords from "../../../common/components/no-records-found/no-records";
import ModalDefault from "../../../common/components/modal/modal-default";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Page from "../../../common/components/layout/layout-components/page";
import Layout from "../../../common/components/layout";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import Button from '../../../common/components/button'
import {DEFAULT_QUERY_LIMIT} from '../../../common/util/util-consts'

const PAYROLL_BATCH_TYPES = [
    {label: 'Drivers', value: 'Drivers'},
    {label: 'Agents', value: 'Agents'},
    {label: 'Employees', value: 'Employees'},

    {label: 'Drivers - Company', value: 'Drivers - Company'},
    {label: 'Drivers - Owner operators', value: 'Drivers - Owner operators'},
    {label: 'Drivers - Subcontractors', value: 'Drivers - Subcontractors'},

    {label: 'Trucks - Owner operators', value: 'Trucks - Owner operators'}
]

class PayrollView extends Component {

    constructor(props) {

        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'AutoCounter',
            sort: 'DESC',

            // Fields
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), {
                behaviour: {
                    canAdjustWidth: true
                },
                style: {
                    condensed: true,
                },
            }, this.pagePath, this.props.translate),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),

            // Dialogs
            selectedBatch: null,
            createBatchDialogVisible: false,
            processBatchItemsDialogVisible: false,
            processBatchDialogIsDirty: false,
            isConfirmModalOpen: false,
            selectedItem: null,
            breakpoint: {},
            createModalOpen: false
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.getBatches({query: this.getQuery()})
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10)
            }
        })
    }
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
        })
    }

    handleSetUpdatedBatch = (data, id) => {
        const piggySelectedBatch = data.data.list.find(item => item.PayrollBatchID === this.state.selectedBatch.PayrollBatchID)
        this.setState({selectedBatch: piggySelectedBatch}, () => {
            this.props.getBatchEntryItems(id)
        })
    }

    /** UI Events Dialogs
     ================================================================= */
    handleToggleCreateDialog = () => {
        this.setState({
            createBatchDialogVisible: !this.state.createBatchDialogVisible
        })
    }

    handleOpenProcessDialog = (item) => {
        this.setState({
            selectedBatch: item,
            processBatchItemsDialogVisible: true
        })
    }

    checkIsStateDirty = (processBatchDialogStateIsDirty) => {
        this.setState({
            processBatchDialogIsDirty: processBatchDialogStateIsDirty
        })
    }

    handleCloseProcessDialog = () => {
        if (this.state.processBatchDialogIsDirty) {
            this.setState({
                closeDirtyBatchConfirmationModal: true
            })
        } else {
            this.setState({
                selectedBatch: null,
                processBatchItemsDialogVisible: false
            })
        }
    }

    handleTogglePayrollSettingsModal = () => {
        this.setState({
            payrollSettingsModalOpen: !this.state.payrollSettingsModalOpen
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    handleToggleEditModal = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            isConfirmModalOpen: !this.state.isConfirmModalOpen,
            selectedItem: item
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    /** Fields/Data Definitions
     ================================================================= */
    getQuery = () => {
        let isPayedQuery = 0
        if (this.state.queryFilterFields.IsPayed.value === 'IsPayed') {
            isPayedQuery = ['IsPayed', '=', '1']
        }

        if (this.state.queryFilterFields.IsPayed.value === 'unPaid') {
            isPayedQuery = ['IsPayed', '=', '0']
        }

        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            searchFields: JSON.stringify({
                ...(this.state.queryFilterFields.EndDate.value && {EndDate: ['Date', '<=', this.state.queryFilterFields.EndDate.value]}),
                ...(this.state.queryFilterFields.StartDate.value && {StartDate: ['Date', '>=', this.state.queryFilterFields.StartDate.value]}),
                IsPayed: isPayedQuery
            })
        }
    }

    getFields = () => {
        return {
            AutoCounter: new Field('AutoCounter', '', [], false, 'text'),
            PayrollBatchTypes: new Field('PayrollBatchTypes', '', [''], false, 'text', {
                hideDialog: true,
            }),
            Description: new Field('Description', '', [''], false, 'text', {hideTable: true}),
            TotalAmount: new Field('TotalAmount', '', [''], false, 'money', {}),
            ProcessedAmount: new Field('ProcessedAmount', '', [''], false, 'money', {}),
            NumberOfEntries: new Field('NumberOfEntries', '', [''], false, 'text', {}),
            IsPayed: new Field('IsPayed', '', [''], false, 'checkbox', {}),
            Date: new Field('Date', '', [''], false, 'date', {}),
            StartDate: new Field('StartDate', '', [''], false, 'date', {}),
            EndDate: new Field('EndDate', '', [''], false, 'date', {}),
            BookDate: new Field('BookDate', '', [''], false, 'date', {
                label: 'CheckPostingDate'
            }),
            OfficeID: new Field('OfficeID', '', [''], false, 'select-search'),
            ContactGroupID: new Field('ContactGroupID', '', [''], false, 'select-search'),
            Name: new Field('Name', '', [], false, 'textarea', {
                addContainerClass: 'col-span-full',
                label: 'notes'
            }),
        }
    }

    getEditFields = () => {
        const fieldTemplates = {
            PayrollBatchTypes: new Field('PayrollBatchTypes', '', [''], false, 'select', {addContainerClass: 'col-span-full'}, {
                options: PAYROLL_BATCH_TYPES,
                isMulti: true
            }),
            Date: new Field('Date', '', [''], false, 'date', {addContainerClass: 'col-span-full'}),
            StartDate: new Field('StartDate', '', [''], false, 'date', {addContainerClass: 'col-span-full'}),
            EndDate: new Field('EndDate', '', [''], false, 'date', {addContainerClass: 'col-span-full'}),
            BookDate: new Field('BookDate', '', [''], false, 'date', {
                addContainerClass: 'col-span-full',
                label: 'CheckPostingDate'
            }),
            OfficeID: new Field('OfficeID', '', [''], false, 'select-search', {addContainerClass: 'col-span-full'}),
            ContactGroupID: new Field('ContactGroupID', '', [''], false, 'select-search', {addContainerClass: 'col-span-full'}),
            Name: new Field('Name', '', [], false, 'textarea', {
                addContainerClass: 'col-span-full',
                label: 'notes'
            })
        }

        let fields = fillFieldsFromData(fieldTemplates, this.state.selectedItem)
        fields.PayrollBatchTypes.value = this.fillMultiSelectField(this.state.selectedItem?.PayrollBatchTypes)

        return fields
    }

    fillMultiSelectField = (receivedObject = "") => {
        if (!receivedObject) {
            return ""
        }

        switch (typeof (receivedObject)) {
            case "string": {
                let values = receivedObject.split(",");

                if (values.length) {
                    return values.map(it => ({
                            label: it,
                            value: it,
                            "manual": true
                        })
                    )
                }
                break;
            }

            case "object":
                return receivedObject;

            default:
                return ""
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            IsPayed: new Field('IsPayed', '', [''], false, 'select', {}, {
                values: {'IsPayed': this.props.translate('text.paid'), 'unPaid': this.props.translate('text.unPaid')},
                isClearable: true
            }),
            StartDate: new Field('StartDate', '', [''], false, 'date', {}),
            EndDate: new Field('EndDate', '', [''], false, 'date', {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    getResource = () => {
        return Resources.PayrollBatches
    }

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'input', 'offset', 'paginationPage', 'showArchived'])
    }

    /** Render
     ================================================================= */
    render() {
        const {
            translate,
            batches,
            batchesLoading,
            batchItems,
            batchItemsLoading,
            batchSingle,
            batchSingleIsLoading,
            batchSingleCount,
            resource
        } = this.props

        const data = getProp(batches, 'list', []);
        const settings = getProp(batches, 'settings', {});
        const count = getProp(batches, 'count', 0)

        const metadata = {
            OfficeID: {
                api: 'api/' + Resources.OfficesQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.OfficeName,
                    value: item.OfficeID
                })
            },
            ContactGroupID: {
                api: 'api/' + Resources.ContactGroupsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            }
        }

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page className="">
                    <PageHeader
                        title={translate('page.heading.Payroll')}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="sm:flex mb-2">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex sm:justify-start justify-end items-center">
                            <div className={'flex mr-4'}>
                                <Tippy content={translate('text.settings')}>
                                    <button
                                        className="btn-icon"
                                        onClick={this.handleTogglePayrollSettingsModal}
                                    >
                                        <Cog6ToothIcon className="w-5 h-5"/>
                                    </button>
                                </Tippy>

                                <TableSettingsPopOver
                                    options={this.state.tableOptions}
                                    setOptions={this.setOptions}
                                    toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                    translate={translate}
                                />
                            </div>
                            <Button
                                hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                                className={`btn btn-primary`}
                                onClick={this.handleToggleCreateDialog}
                            >
                                {translate('btn.create_new')}
                            </Button>
                        </div>
                    </div>

                    <TableCard addClass={'relative'}>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            isLoading={resource.isLoading}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={batchesLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleOpenProcessDialog : null}

                            actions={[
                                {
                                    action: this.handleToggleEditModal,
                                    icon: PencilIcon,
                                    visible: () => checkPerm(this.getResource(), UPDATE_PERM),
                                    title: translate('text.edit'),
                                },
                                {
                                    action: this.handleOpenProcessDialog,
                                    icon: CreditCardIcon, // make this a function
                                    visible: () => true,
                                    label: false, // make this a function
                                    title: translate('text.process'),
                                    disabled: false,
                                    class: false,
                                    iconClass: false
                                },
                                {
                                    action: this.handleToggleConfirmModal,
                                    icon: TrashIcon,
                                    visible: (it) => (it.NumberOfEntries == 0),
                                    title: translate('text.delete_item'),
                                }
                            ]}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !batchesLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={batchesLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === 'offset'
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <NoRecords
                            show={(data.length === 0) && !batchesLoading}
                            title={translate('text.no_records')}
                            addClass={'pt-16 pb-10'}
                        />
                    </TableCard>
                </Page>

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                <ModalDefault
                    show={!!this.state.payrollSettingsModalOpen}
                    title={translate('modal_heading.settings')}
                    widthClass={'max-w-7xl'}
                    closeButtonLabel={translate('btn.close')}
                    onClose={() => this.handleTogglePayrollSettingsModal()}
                    translate={translate}
                >
                    <DriverPayrollSettings
                        location={this.props.location}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        thirdResource={this.props.thirdResource}
                    />
                </ModalDefault>

                <ModalSaveResource
                    title={'Edit batch'}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    show={this.state.editModalOpen}
                    onClose={this.handleToggleEditModal}
                    fields={this.getEditFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params.PayrollBatchID = this.state.selectedItem.PayrollBatchID
                            params.PayrollBatchTypes = (params?.PayrollBatchTypes ? params.PayrollBatchTypes : []).map(it => {
                                return it
                            }).join(',')
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: 'Batch updated.'
                            }))
                            this.handleToggleEditModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />


                {this.state.createBatchDialogVisible && (
                    <PayrollBatchCreateDialog
                        show={!!this.state.createBatchDialogVisible}
                        title={translate('modal_heading.payroll')}
                        widthClass={'max-w-3xl'}
                        closeButtonLabel={translate('btn.close')}
                        payrollBatchOptions={PAYROLL_BATCH_TYPES}
                        settings={settings}
                        onClose={this.handleToggleCreateDialog}
                        onSave={(params) => {
                            this.props.createBatch({
                                params: params,
                                query: this.getQuery(),
                                errorMessage: true,
                                successMessage: translate('text.batch_created')
                            })
                        }}
                        fetchData={(query, selectedTab) => {
                            if (selectedTab === 'Contacts') {
                                this.props.getContacts(query)
                            } else {
                                this.props.getTrucks(query)
                            }
                        }}
                        resource={this.props.contactItems}
                        isLoading={this.props.contactItemsLoading}
                        translate={translate}
                    >
                    </PayrollBatchCreateDialog>
                )}

                <ModalConfirm
                    title={'Unsaved changes'}
                    show={this.state.closeDirtyBatchConfirmationModal}
                    text={translate('text.confirm_close_batch')}
                    onClose={() => this.setState({closeDirtyBatchConfirmationModal: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={translate('btn.cancel')}
                    translate={translate}
                    onConfirm={() => {
                        this.setState({
                            processBatchDialogIsDirty: false,
                            closeDirtyBatchConfirmationModal: false
                        }, () => this.handleCloseProcessDialog())
                    }}
                />

                {this.state.processBatchItemsDialogVisible && (
                    <PayrollBatchProcessDialog
                        show={!!this.state.processBatchItemsDialogVisible}
                        title={translate('modal_heading.payroll')}
                        widthClass={'max-w-3xl'}
                        closeButtonLabel={translate('btn.close')}
                        onClose={this.handleCloseProcessDialog}

                        PayrollBatchID={this.state?.selectedBatch?.PayrollBatchID}
                        AutoCounter={this.state?.selectedBatch?.AutoCounter}
                        dispatch={this.props.dispatch}

                        thirdResource={this.props.thirdResource}
                        pagePath={this.pagePath}
                        checkIsStateDirty={this.checkIsStateDirty}
                        batchItems={batchItems}
                        batchSingle={batchSingle}
                        batchSingleCount={batchSingleCount}
                        isLoading={batchItemsLoading || batchesLoading || batchSingleIsLoading}
                        contactsIsLoading={this.props.contactItemsLoading}
                        fetchSingleBatch={this.props.fetchSingleBatch}
                        fetchContacts={this.props.getContacts}
                        contactResource={this.props.contactItems}
                        getBatchEntryItems={this.props.getBatchEntryItems}
                        postEntryItems={(id) => this.props.postEntryItems(id, this.getQuery(), (data) => this.handleSetUpdatedBatch(data, id))}
                        unpostEntryItems={(id) => this.props.unpostEntryItems(id, this.getQuery(), (data) => this.handleSetUpdatedBatch(data, id))}
                        sendPayrollBatchEmail={this.props.sendPayrollBatchEmail}
                        downloadPayrollBatchPDF={this.props.downloadPayrollBatchPDF}
                        saveEntryItems={this.props.saveEntryItems}
                        translate={translate}
                        dialogInfoResource={this.props.dialogInfoResource}
                    >
                    </PayrollBatchProcessDialog>
                )}

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.isConfirmModalOpen}
                    text={this.props.translate('message.are_you_sure_delete_item')}
                    onClose={this.handleToggleConfirmModal}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get('user'),
                            query: {
                                PayrollBatchID: this.state.selectedItem.PayrollBatchID
                            },
                            piggyQuery: this.getQuery(),
                            errorMessage: true,
                            successMessage: this.props.translate('message.batch_deleted'),
                            resource: Resources.PayrollBatches,
                            piggyResource: Resources.PayrollBatches,
                        }))
                        this.handleToggleConfirmModal()
                    }}
                />
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        batches: state.resource?.data,
        batchesLoading: state.resource?.isLoading,
        batchItems: state.secondResource?.data,
        batchItemsCount: state.secondResource?.count,
        batchItemsLoading: state.secondResource?.isLoading,
        contactItems: state.thirdResource?.data,
        contactItemsLoading: state.thirdResource?.isLoading,
        batchSingle: state.dialogResource?.data?.list ?? {},
        batchSingleCount: state.dialogResource?.data?.count,
        batchSingleIsLoading: state.dialogResource.isLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    const user = LocalStorage.get('user')

    return {
        dispatch: dispatch,

        getBatches: ({query}) => {
            dispatch(getResource({
                user: user,
                query: query,
                resource: Resources.PayrollBatches
            }))
        },
        createBatch: ({params, query, successMessage, errorMessage}) => {
            dispatch(createResource({
                user: user,
                params: params,
                query: query,
                resource: Resources.PayrollBatches,
                piggyResource: Resources.PayrollBatches,
                successMessage: successMessage,
                errorMessage: errorMessage
            }))
        },
        getBatchEntryItems: (ID) => {
            dispatch(getSecondResource({
                user: user,
                query: {
                    PayrollBatchEntryID: ID
                },
                resource: Resources.PayrollBatchesItems
            }))
        },
        fetchSingleBatch: (query) => {
            dispatch(getDialogResource({
                user: user,
                query: query,
                resource: Resources.PayrollBatch
            }))
        },
        saveEntryItems: (ID, Items, notes) => {
            dispatch(createSecondResource({
                user: user,
                params: {
                    PayrollBatchEntryID: ID,
                    Items: Items,
                    InternalNotes: notes.InternalNotes.value,
                    ExternalNotes: notes.ExternalNotes.value
                },
                query: {
                    PayrollBatchEntryID: ID
                },
                resource: Resources.PayrollBatchesItems,
                piggyResource: Resources.PayrollBatchesItems,
                errorMessage: true, successMessage: 'Batch saved'
            }))
        },
        postEntryItems: (ID, query, onPiggyCallback) => {
            dispatch(createResource({
                user: user,
                params: {
                    PayrollBatchEntryID: ID
                },
                query: {
                    PayrollBatchEntryID: ID
                },
                resource: Resources.PayrollBatchesItemsPost,
                piggyQuery: query,
                piggyResource: Resources.PayrollBatches,
                onPiggyCallback: onPiggyCallback,
                secondPiggyResource: Resources.PayrollBatchesItems,
                errorMessage: true, successMessage: 'Batch posted'
            }))
        },
        unpostEntryItems: (ID, query, onPiggyResultCallback) => {
            dispatch(deleteSecondResource({
                user: user,
                query: {
                    PayrollBatchEntryID: ID
                },
                resource: Resources.PayrollBatchesItemsPost,
                piggyQuery: query,
                piggyResource: Resources.PayrollBatches,
                onPiggyResultCallback: onPiggyResultCallback,
                secondPiggyResource: Resources.PayrollBatchesItems,
                errorMessage: true,
                successMessage: 'Batch reverted'
            }))
        },
        getContacts: ({query}) => {
            dispatch(getThirdResource({
                user: user,
                query: query,
                resource: Resources.Contacts
            }))
        },
        getTrucks: ({query}) => {
            dispatch(getThirdResource({
                user: user,
                query: query,
                resource: Resources.Trucks
            }))
        },
        sendPayrollBatchEmail: ({params}) => {
            dispatch(createResource({
                user: user,
                params: params,
                query: {
                    PayrollBatchEntryID: params.PayrollBatchEntryID
                },
                resource: Resources.PayrollBatchesItemsDocument,
                secondPiggyResource: Resources.PayrollBatchesItems,
                errorMessage: true,
                successMessage: 'Settlement PDF sent to ' + params.Emails.join(',') + ' successfully.',
            }))
        },
        downloadPayrollBatchPDF: ({query}) => {
            dispatch(download({
                user: user,
                query: query,
                resource: Resources.PayrollBatchesItemsDocument
            }))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayrollView)
