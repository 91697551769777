import {checkPerm, getProp} from "../../../../util/util-helpers";
import {CREATE_PERM, DELETE_PERM, READ_PERM, UPDATE_PERM} from "../../../../../util/util-constants";
import PageHeaderInfo from "../page/page-header-info";
import PageHeader from "../page/page-header";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ActiveFilters from "../../../resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../resource-table/table-components/table-settings-popover";
import useTableOptions from "../../../../hooks/use-table-options";
import Page from "../page";
import TableFilters from "../../../resource-table/table-components/table-filters";
import TableCard from "../../../resource-table/table-components/table-card";
import ResourceTable from "../../../resource-table";
import TableCardFooter from "../../../resource-table/table-components/table-card-footer";
import Pagination from "../../../resource-table/table-components/pagination";
import NoRecordsTable from "../../../no-records-found/no-records-table";
import TableOptionsDialog from "../../../resource-table/table-components/table-options-dialog";
import DocumentArrowDownIcon from "@heroicons/react/20/solid/DocumentArrowDownIcon";
import Tooltip from "../../../tooltip";

export default function ResourcePage(
    {
        translate,
        pageTitle,
        onCreateResourceClick,
        onNoRecordsCreateClick = onCreateResourceClick,
        resourcePath,
        tableFields,
        fetchData,
        onQueryInputChange,
        onClearAllFiltersClick,
        queryFields,
        breakpoint = {},
        onRowClick,
        onView,
        onEdit,
        onDelete,
        onRestore,
        pagePath,
        tableDefaults = {},
        afterPageTitle,
        noRecordsText,
        noRecordsButtonLabel,
        onDownloadExcel,
        actions
    }
) {
    const dispatch = useDispatch();
    const resource = useSelector(state => state.resource);
    const isLoading = resource.isLoading;
    const count = getProp(resource.data, 'count', 0);

    const [tableOptions, setTableOptions, getTableOptions] = useTableOptions(tableFields, pagePath, tableDefaults, translate);
    const [isColumnsDialogVisible, setIsColumnsDialogVisible] = useState(false);

    const data = getProp(resource.data, 'list', []);

    function setTableColumnOptions(columns) {
        let tableOptionsUpdate = Object.assign({}, tableOptions);

        tableOptionsUpdate.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {});

        setTableOptions(tableOptionsUpdate);
        setIsColumnsDialogVisible(false);
    }

    return <Page>
        <PageHeader
            title={pageTitle}
            buttonLabel={translate('btn.create_new')}
            onButtonClick={onCreateResourceClick}
            hasPerm={checkPerm(resourcePath, CREATE_PERM)}
            afterTitle={
                afterPageTitle
                    ? afterPageTitle
                    : (
                        <PageHeaderInfo
                            dispatch={dispatch}
                        />
                    )

            }
        />

        <div className="flex">
            <ActiveFilters
                filterFields={queryFields}
                onLabelClick={onQueryInputChange}
                onClearFiltersClick={onClearAllFiltersClick}
                translate={translate}
            />

            <div className="flex ml-auto gap-2">
                {!!onDownloadExcel && (
                    <Tooltip content={translate('text.download_excel')}>
                        <button
                            className="btn-icon"
                            onClick={onDownloadExcel}
                        >
                            <DocumentArrowDownIcon className="w-5 h-5"/>
                        </button>
                    </Tooltip>
                )}

                <TableSettingsPopOver
                    options={tableOptions}
                    setOptions={setTableOptions}
                    toggleColumnSettingsDialog={() => setIsColumnsDialogVisible(true)}
                    translate={translate}
                />
            </div>
        </div>

        <TableCard addClass={'relative'}>
            <TableFilters
                hideLimit
                filterFields={queryFields}
                handleInputChange={onQueryInputChange}
                translate={translate}
                onRefreshTable={fetchData}
                isLoading={isLoading}
            />

            <ResourceTable
                data={data}
                fields={tableFields}
                verticalTableIsVisible={breakpoint.index <= 1}

                translate={translate}
                isLoading={isLoading}

                options={tableOptions}
                queryFields={queryFields}
                onSortChange={(fieldName) => onQueryInputChange('sortBy', fieldName)}

                onRowClick={checkPerm(resourcePath, CREATE_PERM) ? onRowClick : null}

                onView={onView}
                onEdit={onEdit}
                onDelete={onDelete}
                onRestore={onRestore}

                actions={actions}

                hasViewPerm={checkPerm(resourcePath, READ_PERM)}
                hasEditPerm={checkPerm(resourcePath, UPDATE_PERM)}
                hasDeletePerm={checkPerm(resourcePath, DELETE_PERM)}
                hasRestorePerm={checkPerm(resourcePath, UPDATE_PERM)}
                saveTableOptions={setTableOptions}
            />

            <TableCardFooter
                show={!(!data.length && !isLoading)}
            >
                <Pagination
                    count={count}
                    isLoading={resource.isLoading}
                    hideRowsPerPage={breakpoint.index <= 1}
                    handleQueryChange={(name, value) => onQueryInputChange(name, value)}
                    queryFields={queryFields}
                    translate={translate}
                />
            </TableCardFooter>

            <NoRecordsTable
                show={(data.length === 0) && !isLoading}
                canCreate={checkPerm(resourcePath, CREATE_PERM)}
                title={'No matching records found'}
                text={noRecordsText}
                btnLabel={noRecordsButtonLabel}
                onBtnClick={onNoRecordsCreateClick}
                onClearFilterClick={onClearAllFiltersClick}
                clearFilterBtnLabel={translate('text.clear_all_filters')}
                clearFilterText={translate('text.try_without_filters')}
                filters={queryFields}
            />
        </TableCard>

        <TableOptionsDialog
            show={isColumnsDialogVisible}
            pagePath={pagePath}
            columns={tableOptions.columns}
            setTableColumnOptions={setTableColumnOptions}
            getDefaultTableColumnOptions={getTableOptions}
            onClose={() => setIsColumnsDialogVisible(false)}
            translate={translate}
        />
    </Page>
}