import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../common/util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import IncomeExpenseDialog from '../dialogs/income-expense-dialog'
import {Field, FieldsManager} from '../../../data/services/fields'
import moment from 'moment-timezone'
import BulkPaymentDialog from '../dialogs/bulk-payment-dialog'
import Env from '../../../util/env'
import Tippy from '@tippyjs/react'
import {
    ArchiveBoxArrowDownIcon,
    CreditCardIcon,
    CurrencyDollarIcon,
    DocumentDuplicateIcon,
    EnvelopeIcon,
    EyeIcon,
    MagnifyingGlassIcon,
    NumberedListIcon,
    PencilIcon,
    TicketIcon
} from '@heroicons/react/24/outline'
import {
    AdjustmentsHorizontalIcon,
    ChevronUpIcon as ChevronUpSolidIcon,
    DocumentArrowDownIcon,
    DocumentIcon,
    LinkIcon
} from '@heroicons/react/20/solid'
import PaymentDialog from '../dialogs/payment-dialog/payment-dialog'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_HISTORY_TABLE_FILTER_FIELDS,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DEFAULT_QUERY_LIMIT,
    DELETE_PERM, INVOICE_FACTOR_STATUS_OPTIONS,
    MAJOR_ACCOUNT_TYPE_EXPENSE,
    MAJOR_ACCOUNT_TYPE_LIABILITY,
    READ_PERM,
    TAG_LABEL_COLORS,
    UPDATE_PERM
} from '../../../common/util/util-consts'
import {
    checkPerm,
    classNames, ConstantTranslate,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getLookup,
    getProp,
    mergeDeep,
    onDocumentPrint,
    resourceIsCreated,
    resourceIsDeleted,
    resourceIsUpdated,
    returnOffsetAndPagination,
    saveTableColumns,
    verifyFilterFields
} from '../../../common/util/util-helpers'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import TrashIcon from '@heroicons/react/24/outline/TrashIcon'
import InvoiceExpenseDetailsDialog from '../dialogs/invoice-expense-details-dialog'
import {
    createSecondResource,
    deleteSecondResource,
    getSecondResource,
    updateSecondResource
} from '../../../data/actions/secondResource'
import {getThirdResource} from '../../../data/actions/thirdResource'
import FireSolidIcon from '@heroicons/react/20/solid/FireIcon'
import FireIcon from '@heroicons/react/24/outline/FireIcon'
import {HourglassIcon, StripeIcon} from '../../../data/themes/icons'
import {getHistoryResource} from '../../../data/actions/historyResource'
import {pushNotification, showGlobalModal, showModal} from '../../../data/actions/ui'
import {numberWithCommasToBack} from '../../../util/util-formaters'
import PayDateFilter from "../carrier-pay/carrier-pay-date-filter";
import ContactInfoDialog from "../../../common/components/modal/contact-info-dialog";
import OrganizationInfoDialog from "../../../common/components/modal/modal-global/organization-info-dialog";
import FileViewer from "../../../common/components/file-viewer/components"
import Modal from "../../../common/components/modal";
import ModalDefault from "../../../common/components/modal/modal-default";
import EmailDocumentDialog from "../../../common/components/modal/modal-email-documents/modal-email-document";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import NoRecords from "../../../common/components/no-records-found/no-records";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableTagManager from "../../../common/components/resource-table/table-components/table-tag-manager";
import {Flags} from "../../../common/components/feature-flags";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import HistoryModal, {DEFAULT_HISTORY_TABLE_FIELDS} from "../../../common/components/modal/modal-history";
import {excludeFields, fillFieldsFromData} from "../../../common/util/util-fields";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ResourceTable from "../../../common/components/resource-table";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import {getJWT} from "../../../common/util/util-auth";
import Button from '../../../common/components/button'
import CreateBatchDialog from "../dialogs/create-batch-dialog";
import BatchDetailsDialog from "../dialogs/batch-details-dialog";
import BatchListDialog from "../dialogs/batch-list-dialog";
import axios from "axios";
import {LoaderLarge} from "../../../common/components/loader";
import StripePaymentIntentsModal from "../../../common/components/modal/stripe-payment-intents-modal";
import StripePayConfirmationModal from "./stripe-pay-confirmation-modal";
import StripeBulkCreatePaymentLinks from "./stripe-bulk-create-payment-links";
import StripePaymentLinksListModal from "../../../common/components/modal/stripe-payment-links-list-modal";
import {getDialogResource} from "../../../data/actions/dialogResource";
import FieldContainer from "../../../common/components/fields/field-container";
import FieldDate from "../../../common/components/fields/field-date";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import getInvoicesFields from "./invoice-fields";
import StripeBulkCreateTerminalPayments from "./stripe-bulk-create-terminal-payments";
import ScaleIcon from "@heroicons/react/24/outline/ScaleIcon";
import SetFactoringModal from "./set-factoring-modal";

class InvoicesTab extends Component {

    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1) + '_invoices'

        this.colors = TAG_LABEL_COLORS
        this.pageSettingsName = 'page-settings-' + this.pagePath

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            columns: {
                AutoCounter: {
                    minWidth: 120,
                },
                Labels: {minWidth: 100},
                RefNumber: {minWidth: 105},
                Amount: {minWidth: 120},
                AmountTransferred: {minWidth: 120},
                IsLoadInvoice: {minWidth: 110},
                InvoiceType: {minWidth: 80},
                Date: {minWidth: 130},
                DueDate: {minWidth: 130},
                BookDate: {minWidth: 130},
                InvoiceStatus: {minWidth: 100},
                Batch: {minWidth: 80},
                IsDigitalSent: {minWidth: 80},
                IsDigitalConfirmed: {minWidth: 80},
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Default state
            ...DEFAULT_CRUD_STATE,
            sortBy: 'InvoiceID',
            sort: 'DESC',
            limit: 100,

            // Fields
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            emailFields: this.props.getEmailFields(),

            // Filter, and header fields
            queryFilterFields: this.getQueryFilterFields(),

            // Dialog(s)
            isInvoiceDialogVisible: false,
            isReceivePaymentDialogVisible: false,
            isDetailsDialogVisible: false,
            isAdjustDialogVisible: false,
            isInvoiceCopyDialogVisible: false,
            isApplyCreditDialogVisible: false,

            isTableFullScreen: false,

            selectedItems: {},
            batchActionDialog: false,
            batchActionDialogHideAnimation: false,
            previewDocument: undefined,
            deleteDialog: false,
            invoicePreviewDialog: false,
            emailModalOpen: false,
            selectedRows: {},
            printInvoiceModalOpen: false,
            isStripeConfirmDialogOpen: false,
            isCreatePaymentLinkDialogOpen: false,
            isBulkCreatePaymentsLinksDialogOpen: false,
            isBulkCreateTerminalPaymentsDialogOpen: false,
            isStripeIntentProcessing: false,
            shouldReopenDetailsDialog: false,
            stripeReaderStatus: null,
            documentSettingsFields: this.getDocumentSettingsFields(),
            createBatchDialog: false,
            batchDetailsDialog: false,
            batchListDialog: false,
            fieldsApplyCreditDate: this.getApplyCreditDateFields(),
            isSetFactoringBalanceModalOpen: false,
            detailsDialogItem: {}
        }

        if (!this.state.tableOptions.behaviour.hasMenu) {
            this.state.tableOptions.behaviour = Object.assign(this.state.tableOptions.behaviour, this.tablePageDefaults.behaviour)
        }

        this.state = mergeDeep(this.state, verifyFilterFields(
            LocalStorage.rehydrateState(this.pagePath),
            this.state.queryFilterFields
        ));

        this.PopoverButtonRef = React.createRef();
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search)
        if (query.get('OrganizationID')) {
            this.getCustomerIDFromQuery(query)
        } else {
            this.fetchData()
        }
        this.fetchLabels()
    }

    componentDidUpdate(prevProps) {
        if (resourceIsDeleted(this.props.secondResource, prevProps.secondResource)) {
            this.fetchInvoiceDialogData()
            this.fetchData()
        }

        if (resourceIsUpdated(this.props.resource, prevProps.resource) && (this.props.resource.resource === "accounting/income/invoice" || this.props.resource.resource === "accounting/income/receipt") && !!this.props.resource.updated) {
            this.closeInvoiceDialog();
        }

        if (resourceIsUpdated(this.props.secondResource, prevProps.secondResource)) {
            this.fetchInvoiceDialogData()
            this.fetchData()
        }

        if (prevProps.breakpoint !== this.props.breakpoint && this.props.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
            this.handleFilterInputChange('limit', 10)
        }

        if (resourceIsDeleted(this.props.dialogInfoResource, prevProps.dialogInfoResource) && this.props.dialogInfoResource.resource === 'accounting/income/invoice/batch') {
            this.fetchData();
        }

        if (resourceIsDeleted(this.props.dialogResource, prevProps.dialogResource) && this.props.dialogResource.resource === 'accounting/income/invoice/batch/items') {
            this.fetchData();
        }

        if (resourceIsCreated(this.props.dialogResource, prevProps.dialogResource) && this.props.dialogResource.resource === 'accounting/income/invoice/batch/items') {
            this.fetchData();
        }

        if (resourceIsCreated(this.props.resource, prevProps.resource) && this.props.resource.resource === 'accounting/income/payment') {
            if (this.state.isDetailsDialogVisible) {
                this.fetchInvoiceDialogData();
            }
        }

        if (!!prevProps?.ui?.globalDialogs?.awaitTerminalPaymentIntentModal?.visible && !this.props?.ui?.globalDialogs?.awaitTerminalPaymentIntentModal?.visible) {
            this.fetchData();
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    fetchLabels = () => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            resource: Resources.ColorTag
        }))
    }

    onBulkDownloadInvoices = () => {
        this.props.dispatch(createResource({
            user: LocalStorage.get('user'),
            resource: Resources.IncomeDocumentsPDF,
            piggyResource: this.getResource(),
            piggyQuery: this.getQuery(),
            params: {
                InvoiceIDs: Object.keys(this.state.selectedRows)
            },
            successMessage: 'JOB_STARTED'
        }))

        this.setState({selectedRows: {}})
    }

    handleSetMarkedClick = (isMarkAction = true) => {
        const selectedRows = this.state.selectedRows

        const InvoiceIDs = Object.keys(selectedRows)

        this.props.dispatch(createResource({
            user: LocalStorage.get('user'),
            params: {
                ExpenseIDs: [],
                InvoiceIDs: InvoiceIDs,
                Marked: isMarkAction ? 1 : 0
            },
            query: this.getQuery(),
            resource: Resources.AccountingMark,
            piggyResource: this.getResource(),
            errorMessage: true,
            successMessage: isMarkAction ? this.props.translate('message.selection_add_marked') : this.props.translate('message.selection_remove_marked')
        }))

        this.setState({selectedRows: {}})
    }

    getCustomerIDFromQuery = (query) => {
        const OrganizationID = query.get('OrganizationID')
        const Organization = query.get('Organization')
        let queryFilterFieldsUpdate = this.state.queryFilterFields
        Object.values(queryFilterFieldsUpdate).forEach(it => {
            if (it.name === 'OrganizationID' && !!OrganizationID && !!Organization) {
                FieldsManager.updateField(queryFilterFieldsUpdate, it.name, {
                    value: OrganizationID,
                    label: Organization
                })
            } else if (it.name !== 'limit' && !!OrganizationID) {
                FieldsManager.updateField(queryFilterFieldsUpdate, it.name, '')
            }
        })

        this.setState(
            {
                queryFilterFields: queryFilterFieldsUpdate
            }, () => {
                this.props.history.push(this.props.match.url)
                this.fetchData()
            }
        )
    }

    fetchInvoiceDialogData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            query: {
                InvoiceID: this.state.selectedItem.InvoiceID
            },
            resource: Resources.InvoicesInvoice
        }));
    }

    deleteResource = (item) => {
        this.handleToggleDeleteModal(() => {
            this.setState({
                ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
            }, () => {
                this.props.dispatch(deleteResource({
                    user: LocalStorage.get('user'),
                    query: Object.assign({}, this.getQuery(), {
                        InvoiceID: item.InvoiceID
                    }),
                    errorMessage: true, successMessage: `${item.AutoCounter} deleted.`,
                    resource: this.getResource(),
                    piggyResource: this.getResource()
                }))
                this.handleToggleDeleteModal();
                if (this.state.isDetailsDialogVisible) {
                    this.toggleShowDetails();
                }
                const rows = Object.values(Object.assign({}, this.state.selectedRows)).reduce((memo, it) => {
                    if (it.InvoiceID !== item.InvoiceID) {
                        memo[it.InvoiceID] = it
                    }
                    return memo
                }, {})
                this.setSelectedRows(rows)

            })
        }, item)
    }

    deleteLoadInvoice = (item) => {
        this.handleToggleDeleteModal(() => {
            this.setState({
                ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
            }, () => {
                this.props.dispatch(deleteResource({
                    user: LocalStorage.get('user'),
                    query: Object.assign({}, this.getQuery(), {
                        InvoiceID: item.InvoiceID
                    }),
                    errorMessage: true, successMessage: `${item.InvoiceID} deleted.`,
                    resource: Resources.Billing,
                    piggyResource: this.getResource()
                }))
                this.handleToggleDeleteModal()
                if (this.state.isDetailsDialogVisible) {
                    this.toggleShowDetails();
                }
            })
        }, item)
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'income_invoices_' + currentDate() + '.xlsx'
            }, this.getQuery())
        }))
    }

    printSampleInvoice = () => {
        this.setState({printInvoiceModalOpen: !this.state.printInvoiceModalOpen})
    }

    downloadDocument = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingIncomeSample,
            query: {
                type: 1,
                name: 'invoice_sample' + '.pdf'
            },
            errorMessage: true, successMessage: 'Successfully downloaded invoice sample!'
        }))
    }

    submitBulkPayment = (fields, payments) => {
        this.props.dispatch(createResource({
            user: LocalStorage.get('user'),
            params: Object.assign({}, FieldsManager.getFieldKeyValues(fields), {
                morePayments: payments.map(item => {
                    return {
                        InvoiceID: item.InvoiceID,
                        Amount: Number(item.EnteredAmount).toFixed(2),
                    }
                })
            }),
            resource: Resources.InvoicesPayment,
            piggyResource: this.getResource(),
            query: this.getQuery(),
            errorMessage: true,
            successMessage: this.props.translate('text.multiple_invoices_paid')
        }))
        this.setState({selectedItems: {}, selectedRows: {}})
    }

    submitCreateBatch = (fields, invoices, zip) => {
        let InvoiceIDs = invoices.map((it) => it.InvoiceID)
        const fieldValues = FieldsManager.getFieldKeyValues(fields);
        this.props.dispatch(createResource({
            user: LocalStorage.get('user'),
            params: Object.assign({}, {
                InvoiceBatch: fieldValues.Description,
                AssignedToContactID: fieldValues.AssignedToContactID
            }, {InvoiceIDs: InvoiceIDs}, {CreateZip: zip}),
            resource: Resources.InvoicesBatch,
            piggyResource: this.getResource(),
            query: this.getQuery(),
            errorMessage: true,
            successMessage: this.props.translate('text.invoices_batch_created')
        }))
        this.setState({selectedItems: {}, selectedRows: {}})
    }

    handleDownloadDocument = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.BillingDocuments,
            query: Object.assign({}, {
                status: 1,
                InvoiceID: this.state.previewDocument.InvoiceID,
                type: 1,
                name: 'invoice_' + this.state.previewDocument.InvoiceID + currentDate() + '.pdf',
                AttachDocuments: this.state?.documentSettingsFields?.AttachDocuments?.value,
                HideBalance: this.state?.documentSettingsFields?.HideBalance?.value
            }, () => this.getQuery),
            errorMessage: true, successMessage: 'Successfully downloaded invoice!'
        }))
    }

    /** Fields definitions
     ================================================================= */
    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            InvoiceStatusID: new Field('InvoiceStatusID', '', [''], false, 'select', {}, {
                all: true,
                values: getLookup('InvoiceStatus'),
                omitSort: true,
            }),
            unpaidDays: new Field('unpaidDays', '', [''], false, 'select', {}, {
                all: true,
                values: {5: 5, 10: 10, 15: 15, 30: 30, 60: 60, 90: 90},
            }),
            StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {addContainerClass: 'col-span-full'}, {}),
            EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {addContainerClass: 'col-span-full'}, {}),
            DateType: new Field('DateType', 1, [''], false, 'button-group', {
                    data: {
                        1: this.props.translate('text.Date'),
                        2: this.props.translate('text.PostingDate'),
                        3: this.props.translate('text.DueDate')
                    },
                    labelType: 'stack',
                    addClass: 'w-full',
                    addButtonClass: 'uppercase font-semibold',
                    addContainerClass: 'col-span-full',
                    label: 'DateType_Date/PostingDate/DueDate'
                }
            ),
            OrganizationIDs: new Field('OrganizationIDs', '', [''], false, 'select-search', {}, {
                multi: true,
                isClearable: true
            }),
            ExcludeOrganizationIDs: new Field('ExcludeOrganizationIDs', '', [''], false, 'select-search', {}, {
                multi: true,
                isClearable: true
            }),
            ContactIDs: new Field('ContactIDs', '', [''], false, 'select-search', {}, {
                multi: true,
                isClearable: true
            }),
            ExcludeContactIDs: new Field('ExcludeContactIDs', '', [''], false, 'select-search', {}, {
                multi: true,
                isClearable: true
            }),
            OfficeID: new Field('OfficeID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            UpdatedByContactID: new Field('UpdatedByContactID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            InvoiceBatchID: new Field('InvoiceBatchID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            RecurringInvoiceID: new Field('RecurringInvoiceID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            InvoiceFactorStatusID: new Field('InvoiceFactorStatusID', '', [''], false, 'select', {}, {
                isClearable: true
            }),
            AmountMoreThan: new Field('AmountMoreThan', '', [], false, 'float'),
            AmountLessThan: new Field('AmountLessThan', '', [], false, 'float'),
            AmountExact: new Field('AmountExact', '', [], false, 'float'),
            excludeQuery: new Field('excludeQuery', '', [], false, 'search', {}, {icon: false}),
            IsDriverTerminationInvoice: new Field('IsDriverTerminationInvoice', '', [''], false, 'checkbox'),
            IsWorkOrderInvoice: new Field('IsWorkOrderInvoice', '', [''], false, 'checkbox'),
            IsFactoringActive: new Field('IsFactoringActive', '', [''], false, 'checkbox'),
            IsLoadInvoice: new Field('IsLoadInvoice', '', [''], false, 'checkbox'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                hideLabel: true,
                labelType: 'float'
            }, {menuPlacement: 'top'})
        }
    }

    getFields = () => {
        const invoiceFields = getInvoicesFields(this.props.translate);

        invoiceFields.ChargeTo.metadata.render = (item) => <CellButton
            onClick={() => item.OrganizationID ? this.handleToggleOrganizationInfoDialog(item) : this.handleToggleContactInfoDialog(item)}>
            <div>
                {item.ContactID ? item.Contact : item.Organization}
            </div>
        </CellButton>

        return invoiceFields;
    }

    getDocumentSettingsFields = (item = null) => {
        /*
        return {
            HideBalance: new Field('HideBalance', 0, [''], false, 'button-group', {
                data: {0: this.props.translate('btn.no'), 1: this.props.translate('btn.yes')},
                addContainerClass: 'col-span-full',
                labelType: 'left'
            }),
            ShowAdjustments: new Field('ShowAdjustments', 0, [''], false, 'button-group', {
                data: {0: this.props.translate('btn.no'), 1: this.props.translate('btn.yes')},
                addContainerClass: 'col-span-full',
                labelType: 'left'
            })
        }*/
        return {
            AttachDocuments: new Field('AttachDocuments', 0, [''], false, (['pdf', 'jpeg', 'png'].some(it => item?.AttachPath?.split('.')?.pop().includes(it)) ? 'button-group' : 'hidden'), {
                data: {0: this.props.translate('btn.no'), 1: this.props.translate('btn.yes')},
                addContainerClass: 'col-span-full',
                labelType: 'left',
                note: this.props.translate('text.invoice_attached_document_note')
            }),
            HideBalance: new Field('HideBalance', 1, [''], false, 'button-group', {
                data: {
                    0: this.props.translate('text.original_invoice'),
                    1: this.props.translate('text.invoice_without_balance')
                },
                addContainerClass: 'col-span-full',
                labelType: 'left',
                note: this.props.translate('text.hide_balance_note')
            })
        }
    }

    getFieldTypes = () => {
        let fieldsCombined = Object.assign({}, this.getFields())

        fieldsCombined = Object.values(fieldsCombined).reduce((memo, it) => {
            memo[it.name] = it.type
            return memo
        }, {})

        fieldsCombined['AmountTransferred'] = 'money'
        fieldsCombined['Marked'] = 'checkbox'
        fieldsCombined['IsRevised'] = 'checkbox'
        fieldsCombined['HasReference'] = 'checkbox'
        fieldsCombined['IsLoadInvoice'] = 'checkbox'
        fieldsCombined['IsDriverTerminationInvoice'] = 'checkbox'
        fieldsCombined['IsWorkOrderInvoice'] = 'checkbox'
        return Object.assign({}, fieldsCombined)
    }

    getAdjustFields = () => {
        return {
            Date: new Field('Date', '', ['empty'], false, 'date', {}, {minDate: getProp(this.props.resource.data, 'defaults.LockedDate', null)}),
            Amount: new Field('Amount', '', ['empty'], false, 'float', {}),
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search', {}),
            Memo: new Field('Memo', '', [''], false, 'text'),
        }
    }

    getDetailsFields(item = {}) {
        const fieldTemplates = {
            Description: new Field("Description", '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
            OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {
                addContainerClass: 'col-span-full',
                hideTable: !this.state.selectedItem?.OrganizationID
            }),
            ContactID: new Field('ContactID', "", [''], false, 'select-search', {
                addContainerClass: 'col-span-full',
                hideTable: !this.state.selectedItem?.ContactID
            }),
            Date: new Field('Date', '', [''], false, 'date', {addContainerClass: 'col-span-3'}),
            OfficeID: new Field('OfficeID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
            ContactGroupID: new Field('ContactGroupID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
            Amount: new Field('Amount', '', [''], false, 'money', {addContainerClass: 'col-span-3'}),
            CreditMemoStatusID: new Field('CreditMemoStatusID', '', [''], false, 'select', {
                addContainerClass: 'col-span-3',
                hideDialog: true
            }, {
                values: {0: "Pending", 1: "Attached to invoice", 2: "Cash payout"}
            }),

        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    getApplyCreditDateFields() {
        return {
            Date: new Field('Date', currentDate(), ['empty'], false, 'date', {addContainerClass: 'col-span-3'})
        }
    }

    /** UI Events (pagination, filters, sort)
     ================================================================= */
    handleSelectRowClick = (item, event) => {
        const itemID = item.InvoiceID
        const data = getProp(this.props.resource.data, 'list', [])
        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedItems = this.state.selectedItems
        if (event?.nativeEvent?.shiftKey) {
            const firstSelectedItem = data.findIndex(it => selectedRows[it.InvoiceID])
            const lastSelectedItem = data.findIndex(it => it.InvoiceID === itemID)

            if (firstSelectedItem > -1 && lastSelectedItem > -1) {
                if (firstSelectedItem < lastSelectedItem) {
                    for (let i = firstSelectedItem; i < lastSelectedItem + 1; i++) {
                        Object.assign(selectedRows, {[data[i].InvoiceID]: data[i]})
                        selectedItems[data[i].InvoiceID] = data[i]
                    }
                } else {
                    for (let i = lastSelectedItem; i < firstSelectedItem + 1; i++) {
                        selectedItems[data[i].InvoiceID] = data[i]
                        Object.assign(selectedRows, {[data[i].InvoiceID]: data[i]})
                    }
                }
            }
        } else {
            if (selectedRows[itemID]) {
                delete selectedItems[itemID]
                delete selectedRows[itemID]
            } else {
                selectedItems[itemID] = data.find(it => it.InvoiceID === itemID)
                Object.assign(selectedRows, {[itemID]: item})
            }
        }

        this.setState({selectedRows, selectedItems})
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(this.props.resource.data, 'list', []))

        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedItems = this.state.selectedItems

        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                selectedItems[it.InvoiceID] = it
                memo[it.InvoiceID] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it.InvoiceID.toString())) {
                    delete selectedItems[it.InvoiceID]
                    delete selectedRows[it.InvoiceID]
                }
            })
        }

        this.setState({selectedRows})
    }

    handleInputChange = (fields, name, value) => {
        return FieldsManager.updateField(fields, name, value)
    }

    handleApplyDateInputChange = (name, value) => {
        this.setState({fieldsApplyCreditDate: FieldsManager.updateField(this.state.fieldsApplyCreditDate, name, value)})
    }

    handleDocumentSettingsInputChange = (name, value) => {
        let documentSettingsFieldsUpdate = this.state.documentSettingsFields
        documentSettingsFieldsUpdate = FieldsManager.updateField(documentSettingsFieldsUpdate, name, value)
        this.setState({documentSettingsFields: Object.assign({}, this.state.documentSettingsFields, documentSettingsFieldsUpdate)})
    }
    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {

            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'InvoiceID'
            sort = 'DESC'
        }

        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.fetchData()
            this.saveFilters()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    /** UI Events (Dialogs)
     ================================================================= */
    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    handleToggleDeleteModal = (submit, item, restore = false) => {
        this.setState({
            confirmText: !restore ? this.props.translate('message.confirm_delete_generic', [item?.AutoCounter]) : this.props.translate('message.confirm_restore_generic') + ` ${item?.InvoiceID}`,
            deleteDialog: submit
        })
    }

    handleToggleHistoryModal = (item = null) => {
        this.setState({
            historyModalOpen: !this.state.historyModalOpen,
            selectedItem: item
        })
    }

    toggleProcessPreview = (item = null) => {
        this.setState({
            previewDocument: item,
            invoicePreviewDialog: !this.state.invoicePreviewDialog
        })
    }

    toggleAdjustInvoice = (item = null) => {
        this.setState({
            selectedItem: item,
            isAdjustDialogVisible: !this.state.isAdjustDialogVisible
        })
    }

    ApplyCredit = (item = null) => {
        this.props.dispatch(createResource({
            user: LocalStorage.get('user'),
            params: {
                InvoiceID: this.state.selectedItem.InvoiceID,
                CreditMemoID: item.CreditMemoID,
                Date: moment(this.state.fieldsApplyCreditDate.Date.value).format("YYYY-MM-DD HH:mm:ss")
            },
            piggyQuery: this.getQuery(),
            piggyResource: this.getResource(),
            resource: Resources.IncomeCreditApply,
            successMessage: this.props.translate("message.credit_applied")
        }))


        this.setState({
            selectedItem: item,
            isApplyCreditDialogVisible: !this.state.isApplyCreditDialogVisible,
            fieldsApplyCreditDate: this.getApplyCreditDateFields()
        })
    }

    toggleApplyCredit = (item = null) => {
        if (item?.OrganizationID || item?.ContactID) {
            this.fetchApplyCredits(item);
        }

        this.setState({
            selectedItem: item,
            isApplyCreditDialogVisible: !this.state.isApplyCreditDialogVisible,
            fieldsApplyCreditDate: this.getApplyCreditDateFields()
        })
    }

    fetchApplyCredits = (item) => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: Resources.IncomeCredit,
            query: {
                searchFields: JSON.stringify({
                    CreditMemoStatusID: ["CreditMemoStatusID", "=", 0],
                    OrganizationID: item?.OrganizationID,
                    ContactID: item?.ContactID
                })
            }
        }))
    }
    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    toggleReceivePaymentDialog = (item) => {
        this.setState({
            receivePaymentItem: item,
            isReceivePaymentDialogVisible: !this.state.isReceivePaymentDialogVisible
        })
    }

    openInvoiceDialog = (item = null, shouldReopenDetailsDialog) => {
        if (this.state.updateItem && shouldReopenDetailsDialog) {
            const updatedItem = getProp(this.props.resource, 'data.list', []).find(it => it.InvoiceID === this.state?.updateItem?.InvoiceID);

            if (updatedItem) {
                item = updatedItem;
            }
        }

        this.setState({
            isInvoiceDialogVisible: true,
            updateItem: item,
            shouldReopenDetailsDialog: !!shouldReopenDetailsDialog,
        })
    }

    closeInvoiceDialog = () => {
        this.setState({
            isInvoiceDialogVisible: false,
            dialogHideAnimation: false,
            isDetailsDialogVisible: this.state.shouldReopenDetailsDialog,
            selectedItem: this.state.shouldReopenDetailsDialog ? this.state.updateItem : this.state.selectedItem,
            shouldReopenDetailsDialog: false
        });
    }

    openCopyInvoiceDialog = (item = null) => {
        this.setState({
            isInvoiceCopyDialogVisible: true,
            updateItem: item
        })
    }

    closeCopyInvoiceDialog = () => {
        this.setState({
            isInvoiceCopyDialogVisible: false,
            dialogHideAnimation: false
        })
    }

    handleToggleOrganizationInfoDialog = (item = {}) => {
        this.setState({
            selectedOrganization: item,
            isOrganizationInfoDialogOpen: true
        })
    }

    handleToggleContactInfoDialog = (item = {}) => {
        this.setState({
            selectedContact: item,
            isContactInfoDialogOpen: true
        })
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), this.tablePageDefaults, this.props.translate)
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state,
            ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'query', 'showArchived'])
    }

    editHidden = (item) => {
        const LockedData = getProp(this.props.resource.data, 'defaults.LockedDate', null)
        return !((!LockedData || (moment(LockedData).diff(item?.Date) < 0))
            && !item?.IsLoadInvoice && !item?.IsDriverTerminationInvoice)
    }

    deleteHidden = (item) => {
        const LockedData = getProp(this.props.resource.data, 'defaults.LockedDate', null)
        return !((!LockedData || (moment(LockedData).diff(item?.Date) < 0))
            && !item?.IsLoadInvoice && !item?.IsDriverTerminationInvoice
            && (!(item?.AmountTransferred > 0) || (item?.InvoiceTypeID != 1)))
    }

    canReceivePayment = (item) => {
        return !((item?.InvoiceStatusID == 1 || item?.InvoiceStatusID == 2))
    }

    deleteLoadInvoiceHidden = (item) => {
        const LockedData = getProp(this.props.resource.data, 'defaults.LockedDate', null)
        return !(
            (!LockedData || (moment(LockedData).diff(item?.Date) < 0))
            && !!item?.IsLoadInvoice
            && (!(item?.AmountTransferred > 0) || (item?.InvoiceTypeID != 1))
        )
    }

    togglePayInvoiceModal = (item = null) => {
        this.setState({
            selectedItem: item,
            isStripeConfirmDialogOpen: !this.state.isStripeConfirmDialogOpen,
        })
    }

    toggleCreatePaymentLinkModal = (item) => {
        this.setState({
            selectedItem: item,
            isCreatePaymentLinkDialogOpen: !this.state.isCreatePaymentLinkDialogOpen,
        })
    }

    toggleSetFactoringBalanceModal = (item) => {
        this.setState({
            selectedItem: item,
            isSetFactoringBalanceModalOpen: !this.state.isSetFactoringBalanceModalOpen,
        })
    }

    handleConfirmPayWithStripeClick = (Amount) => {
        if (this.state.selectedItem.InvoiceID) {
            this.setState({
                isStripeIntentProcessing: true
            }, () => {
                axios.post(
                    Env.getApiUrl('api/' + Resources.PaymentsCreateTerminal),
                    {
                        InvoiceID: this.state.selectedItem.InvoiceID,
                        Amount: Amount
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getJWT().access_token
                        }
                    }
                ).then(() => {
                    this.setState({
                        isStripeConfirmDialogOpen: false,
                        isStripeIntentProcessing: false
                    })
                }).catch((error) => {
                    this.setState({
                        isStripeConfirmDialogOpen: false,
                        isStripeIntentProcessing: false,
                        stripeReaderStatus: error.response.data?.data?.readerStatus
                    })
                })
            })
        }
    }

    handleConfirmCreatePaymentLinkClick = (Amount) => {
        if (this.state.selectedItem.InvoiceID) {
            this.setState({
                isStripeIntentProcessing: true
            }, () => {
                axios.post(
                    Env.getApiUrl('api/' + Resources.PaymentsLinks),
                    {
                        "Invoices": [
                            {
                                "InvoiceID": this.state.selectedItem.InvoiceID,
                                "AutoCounter": this.state.selectedItem.AutoCounter,
                                "RefNumber": this.state.selectedItem.RefNumber,
                                "Amount": numberWithCommasToBack(Amount)
                            }
                        ]
                    },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getJWT().access_token
                        }
                    }
                ).then((result) => {
                    this.setState({
                        isCreatePaymentLinkDialogOpen: false,
                        isStripeIntentProcessing: false
                    });

                    this.props.dispatch(showGlobalModal('StripePaymentLinkModal', result?.data?.data?.url));
                }).catch(() => {
                    this.setState({
                        isCreatePaymentLinkDialogOpen: false,
                        isStripeIntentProcessing: false
                    });

                    this.props.dispatch(pushNotification({
                        title: this.props.translate('text.creating_payment_link_failed'),
                        messageType: "error"
                    }));
                })
            })
        }
    }

    handleConfirmCreatePaymentLinksClick = (selectedInvoices = []) => {
        const selectedInvoicesValues = Object.values(selectedInvoices);
        if (!selectedInvoicesValues.length) {
            return null;
        }

        this.setState({
            isStripeIntentProcessing: true
        }, () => {
            axios.post(
                Env.getApiUrl('api/' + Resources.PaymentsLinks),
                {
                    "Invoices": selectedInvoicesValues.map(invoice => {
                        return {
                            "InvoiceID": invoice.InvoiceID,
                            "AutoCounter": invoice.AutoCounter,
                            "RefNumber": invoice.RefNumber,
                            "Amount": invoice.PayAmount
                        }
                    })
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + getJWT().access_token
                    }
                }
            ).then((result) => {
                this.setState({
                    isBulkCreatePaymentsLinksDialogOpen: false,
                    isStripeIntentProcessing: false
                });

                this.props.dispatch(showGlobalModal('StripePaymentLinkModal', result?.data?.data?.url));
            }).catch(() => {
                this.setState({
                    isBulkCreatePaymentsLinksDialogOpen: false,
                    isStripeIntentProcessing: false
                });

                this.props.dispatch(pushNotification({
                    title: this.props.translate('text.creating_payment_link_failed'),
                    messageType: "error"
                }));
            })
        })
    }

    handleConfirmCreateTerminalPaymentsClick = (selectedInvoices = []) => {
        const selectedInvoicesValues = Object.values(selectedInvoices);
        if (!selectedInvoicesValues.length) {
            return null;
        }

        this.setState({
            isStripeIntentProcessing: true
        }, () => {
            axios.post(
                Env.getApiUrl('api/' + Resources.PaymentsCreateTerminalBulk),
                {
                    "Invoices": selectedInvoicesValues.map(invoice => {
                        return {
                            "InvoiceID": invoice.InvoiceID,
                            "AutoCounter": invoice.AutoCounter,
                            "RefNumber": invoice.RefNumber,
                            "Amount": invoice.PayAmount
                        }
                    })
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + getJWT().access_token
                    }
                }
            ).then(() => {
                this.setState({
                    isBulkCreateTerminalPaymentsDialogOpen: false,
                    isStripeIntentProcessing: false
                })
            }).catch((error) => {
                this.setState({
                    isStripeConfirmDialogOpen: false,
                    isStripeIntentProcessing: false,
                    stripeReaderStatus: error.response.data?.data?.readerStatus
                })
            })
        })
    }

    toggleShowDetails = (item = null) => {
        this.setState({
            selectedItem: item,
            isDetailsDialogVisible: !this.state.isDetailsDialogVisible,
        })
    }

    closeBatchActionDialog = () => {
        this.setState({batchActionDialogHideAnimation: true}, () => {
            setTimeout(() => {
                this.setState({batchActionDialog: false, batchActionDialogHideAnimation: false})
            })
        })
    }

    closeCreateBatchDialog = () => {
        this.setState({createBatchDialog: false})
    }

    closeBatchNumberDialog = () => {
        this.setState({batchDetailsDialog: false})
    }

    closeBatchListDialog = () => {
        this.setState({batchListDialog: false})
    }

    getResource = () => {
        return Resources.Invoices
    }

    toggleSendEmailDialog = (item = null) => {
        // ignore event
        item = item?.current ? null : item

        if (item) {
            item.DocumentName = item.AutoCounter + '.pdf'
        }

        let emailFields = this.state.emailFields
        emailFields.emails.value = item?.InvoiceSendEmails?.split(',').map(it => ({label: it, value: it})) ?? ''

        this.setState({
            emailModalOpen: !this.state.emailModalOpen,
            previewDocument: item,
            documentSettingsFields: this.getDocumentSettingsFields(item)
        })
    }

    setSelectedRows = (selectedRows) => {
        this.setState({selectedRows})
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, thirdResource, historyResource, dialogResource} = this.props

        const data = getProp(resource, 'data.list', [])
        const count = getProp(resource.data, 'count', 0)

        const dataApplyCredit = getProp(dialogResource.data, 'list', [])
        const isDialogLoading = getProp(dialogResource, 'isLoading', false)

        const isLoading = !!resource.isLoading

        const defaults = getProp(resource.data, 'defaults', []);

        const totalAmount =
            genericMoneyFormatter(Object.values(this.state.selectedRows).reduce((memo, it) => memo + it.Amount, 0))
        const totalBalance =
            genericMoneyFormatter(Object.values(this.state.selectedRows).reduce((memo, it) => memo + (it.Amount - it.AmountTransferred), 0))

        const selectedRowsContainReceipt = Object.values(this.state.selectedRows).some((item) => item.InvoiceTypeID === 2)

        const secondData = getProp(this.props.secondResource, 'data', [])

        const hasTerminal = getProp(resource, 'data.integrations.Stripe.hasTerminal', 0)
        const hasIntegration = getProp(resource, 'data.integrations.Stripe.hasIntegration', 0)

        const hasPayedInvoicesSelected = !!Object.values(this.state.selectedItems).find(item => item.InvoiceStatusID !== 1 && item.InvoiceStatusID !== 2);

        return (
            <React.Fragment>
                <div className="sm:flex mb-2">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />

                    <div className="ml-auto flex sm:justify-start justify-end items-center gap-4">
                        <div className={'flex'}>
                            <Tippy content={translate('text.print_sample_invoice')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.printSampleInvoice}
                                >
                                    <DocumentIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>

                        {!!hasIntegration && !!hasTerminal && (
                            <StripePaymentIntentsModal translate={translate}/>
                        )}

                        {!!hasIntegration && (
                            <StripePaymentLinksListModal
                                translate={translate}
                            />
                        )}

                        <Button
                            hasPerm={checkPerm(Resources.InvoicesBatchList, READ_PERM)}
                            onClick={() => {
                                this.setState({batchListDialog: true})
                            }}
                        >
                            {translate('text.Invoice_batches')}
                        </Button>

                        <Button
                            hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                            className={`btn btn-primary`}
                            onClick={() => {
                                this.openInvoiceDialog()
                            }}
                        >
                            {translate('btn.create_new')}
                        </Button>
                    </div>
                </div>

                <TableCard
                    addClass={
                        classNames(
                            this.state.isTableFullScreen
                                ? 'fixed inset-1 -top-1 z-50'
                                : undefined
                        )}
                >
                    <TableFilters
                        hideLimit
                        forceDialog={true}
                        filterFields={excludeFields(this.state.queryFilterFields, ['StartDate', 'EndDate', 'DateType'])}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        customHtml={
                            <PayDateFilter
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                                updateQueryFields={(queryFieldsUpdate) => {
                                    this.setState({
                                        queryFilterFields: queryFieldsUpdate,
                                        offset: 0
                                    }, () => this.fetchData())
                                    this.saveFilters(queryFieldsUpdate)
                                }}
                                onQueryChange={this.handleFilterInputChange}
                                hasDateType={true}
                                isDateTypeClearable={true}
                            />
                        }
                        onRefreshTable={this.fetchData}
                        onFullScreenToggle={this.handleFullScreenToggle}
                        isTableFullScreen={this.state.isTableFullScreen}
                        isLoading={isLoading}
                        selects={{
                            InvoiceBatchID: {
                                api: 'api/' + Resources.InvoicesBatchList,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    label: item.AutoCounter + ' - ' + item.InvoiceBatch,
                                    value: item.InvoiceBatchID
                                })
                            },
                            RecurringInvoiceID: {
                                api: 'api/' + Resources.IncomeRecurring,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    label: item.TemplateName,
                                    value: item.RecurringInvoiceID
                                })
                            },
                            ExcludeContactIDs: {
                                api: 'api/' + Resources.ContactsQuick,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    label: item.FirstName + " " + item.LastName,
                                    value: item.ContactID
                                })
                            },
                            OrganizationIDs: {
                                api: 'api/' + Resources.OrganizationsQuick,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    label: item.LegalName,
                                    value: item.OrganizationID
                                })
                            },
                            ExcludeOrganizationIDs: {
                                api: 'api/' + Resources.OrganizationsQuick,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    label: item.LegalName,
                                    value: item.OrganizationID
                                })
                            },
                            InvoiceFactorStatusID: ConstantTranslate(INVOICE_FACTOR_STATUS_OPTIONS, this.props.translate)
                        }}
                    />

                    <ResourceTable
                        tableKey={'InvoiceID'}
                        updatedRows={this.state.updatedRows}
                        verticalTableIsVisible={this.props.breakpoint.index <= 1}
                        fields={this.getFields()}
                        data={data}
                        isLoading={isLoading}

                        limit={this.state.queryFilterFields.limit.value}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onSecondSortChange={this.handleSecondSortChange}
                        secondSortBy={this.state.secondSortBy}
                        secondSort={this.state.secondSort}

                        onRowClick={this.toggleShowDetails}

                        actions={[
                            {
                                action: this.togglePayInvoiceModal,
                                customContent: () => <StripeIcon className="w-5 h-5 relative p-0.5"/>,
                                visible: (it) => hasTerminal && hasIntegration && (it.Amount - it.AmountTransferred > 0),
                                hasPerm: checkPerm(Resources.InvoicesPayment, CREATE_PERM),
                                title: translate('text.pay_invoice_stripe'),
                                order: 50
                            },

                            {
                                action: this.toggleCreatePaymentLinkModal,
                                customContent: () => <LinkIcon className="w-5 h-5 text-[#6772e5] relative p-0.5"/>,
                                visible: (it) => hasIntegration && (it.Amount - it.AmountTransferred > 0),
                                hasPerm: checkPerm(Resources.InvoicesPayment, CREATE_PERM),
                                title: translate('btn.create_payment_link'),
                            },
                            {
                                action: this.toggleShowDetails,
                                icon: EyeIcon,
                                hasPerm: checkPerm(Resources.InvoicesInvoice, READ_PERM),
                                title: translate('text.show_details'),
                                order: 11
                            },
                            {
                                action: this.toggleProcessPreview,
                                icon: MagnifyingGlassIcon,
                                hasPerm: checkPerm(Resources.BillingDocuments, READ_PERM),
                                title: translate('text.preview_document'),
                                order: 21
                            },
                            {
                                action: (it) => this.openInvoiceDialog(it, false),
                                icon: PencilIcon,
                                visible: (item) => !this.editHidden(item),
                                hasPerm: checkPerm(this.getResource(), UPDATE_PERM),
                                title: translate('text.edit_invoice'),
                                order: 31
                            },
                            {
                                action: this.toggleReceivePaymentDialog,
                                icon: CreditCardIcon,
                                visible: (item) => !this.canReceivePayment(item),
                                hasPerm: checkPerm(Resources.InvoicesPayment, CREATE_PERM),
                                title: translate('text.receive_payment'),
                                order: 41
                            },
                            {
                                action: this.toggleSetFactoringBalanceModal,
                                icon: ScaleIcon,
                                hasPerm: checkPerm(Resources.InvoicesFactor, UPDATE_PERM),
                                visible: (it) => it.IsFactoringActive,
                                title: translate('btn.setFactoringBalance'),
                                order: 51
                            },
                            {
                                action: this.toggleAdjustInvoice,
                                icon: AdjustmentsHorizontalIcon,
                                visible: (it) => it?.InvoiceTypeID !== 2,
                                hasPerm: checkPerm(Resources.InvoicesAdjust, UPDATE_PERM),
                                title: translate('text.edit_adjust'),
                                order: 60
                            },
                            {
                                action: this.toggleApplyCredit,
                                icon: TicketIcon,
                                visible: (it) => it?.InvoiceTypeID !== 2,
                                hasPerm: checkPerm(Resources.IncomeCreditApply, CREATE_PERM),
                                title: translate('text.apply_credit'),
                                order: 61
                            },
                            {
                                action: this.toggleSendEmailDialog,
                                icon: EnvelopeIcon,
                                title: translate('text.send_document_via_email'),
                                hasPerm: checkPerm(Resources.EmailTemplates, READ_PERM)
                            },
                            {
                                action: this.deleteResource,
                                icon: TrashIcon,
                                visible: (item) => !this.deleteHidden(item),
                                hasPerm: checkPerm(this.getResource(), DELETE_PERM),
                                title: translate('btn.delete'),
                            },
                            {
                                action: this.deleteLoadInvoice,
                                icon: TrashIcon,
                                visible: (item) => !this.deleteLoadInvoiceHidden(item),
                                hasPerm: checkPerm(this.getResource(), DELETE_PERM),
                                title: translate('btn.delete'),
                            },
                            {
                                action: this.handleToggleHistoryModal,
                                customContent: () => <HourglassIcon className="w-5 h-5"/>,
                                hasPerm: checkPerm(Resources.IncomeHistory, READ_PERM),
                                title: translate('text.history'),
                            },
                            {
                                action: this.openCopyInvoiceDialog,
                                icon: DocumentDuplicateIcon,
                                hasPerm: checkPerm(this.getResource(), CREATE_PERM),
                                title: translate('btn.copy'),
                            },
                        ]}
                        onSelectRow={this.handleSelectRowClick}
                        selectedRows={this.state.selectedRows}
                        onSelectAllClick={this.handleSelectAllClick}

                        saveTableOptions={this.setOptions}

                        options={this.state.tableOptions}
                        translate={translate}
                    />


                    <TableBulkActions
                        selectedRows={this.state.selectedRows}
                        tableKey="InvoiceID"
                        fields={this.getFields()}
                        translate={translate}
                        options={this.state.tableOptions}
                        setSelectedRows={this.setSelectedRows}
                        onSelectAllClick={this.handleSelectAllClick}
                        footerContent={
                            <div className="text-sm flex justify-between w-full px-4 py-1 h-8">
                                <div className="whitespace-nowrap">
                                    Total amount for selected entries:
                                    <span className="ml-2 font-bold text-primary-contrast">
                                        {totalAmount} (Balance {totalBalance})
                                    </span>
                                </div>
                            </div>
                        }
                    >
                        <div className="flex items-center h-8">
                            <div className={classNames('flex divide-x-2 divide-primary-shade pr-2')}>

                                {checkPerm(Resources.AccountingMark, UPDATE_PERM) && (
                                    <div className="flex items-center gap-x-1 pr-4">
                                        <Tippy content={translate('btn.set_as_marked')}>
                                            <button
                                                onClick={() => this.handleSetMarkedClick(true)}
                                                className="flex p-1 rounded-btn hover:bg-primary-shade"
                                            >
                                                <FireSolidIcon
                                                    className="w-5 h-5 text-primary-contrast"/>
                                            </button>
                                        </Tippy>

                                        <Tippy content={translate('btn.remove_from_marked')}>
                                            <button
                                                onClick={() => this.handleSetMarkedClick(false)}
                                                className="flex p-1 rounded-btn hover:bg-primary-shade"
                                            >
                                                <FireIcon
                                                    className="w-5 h-5 text-primary-contrast"/>
                                            </button>
                                        </Tippy>
                                    </div>
                                )}


                                {checkPerm(Resources.InvoicesPayment, UPDATE_PERM) && (
                                    <div className="flex items-center gap-x-1 px-4">
                                        <Tippy content={translate('text.receive_payment')}>
                                        <span>
                                            <button
                                                onClick={() => this.setState({batchActionDialog: true})}
                                                className="flex p-1 rounded-btn text-primary-contrast hover:bg-primary-shade disabled:hover:bg-primary disabled:opacity-60"
                                            >
                                                <CreditCardIcon
                                                    className="w-5 h-5 "/>
                                            </button>
                                        </span>
                                        </Tippy>
                                    </div>
                                )}

                                {checkPerm(Resources.InvoicesPayment, UPDATE_PERM) && !!hasIntegration && (
                                    <div className="flex items-center gap-x-1 px-4">
                                        <Tippy
                                            content={hasPayedInvoicesSelected ? translate('text.create_bulk_terminal_payment_payed_selected') : translate('text.create_bulk_terminal_payment')}>
                                        <span>
                                            <button
                                                onClick={() => this.setState({isBulkCreateTerminalPaymentsDialogOpen: true})}
                                                className="flex p-1 rounded-btn text-primary-contrast hover:bg-primary-shade disabled:hover:bg-primary disabled:opacity-60"
                                                disabled={hasPayedInvoicesSelected}
                                            >
                                                <StripeIcon fill="#fff" className="w-5 h-5"/>
                                            </button>
                                        </span>
                                        </Tippy>

                                        <Tippy
                                            content={hasPayedInvoicesSelected ? translate('text.create_bulk_payment_link_payed_selected') : translate('text.create_bulk_payment_link')}>
                                        <span>
                                            <button
                                                onClick={() => this.setState({isBulkCreatePaymentsLinksDialogOpen: true})}
                                                className="flex p-1 rounded-btn text-primary-contrast hover:bg-primary-shade disabled:hover:bg-primary disabled:opacity-60"
                                                disabled={hasPayedInvoicesSelected}
                                            >
                                                <LinkIcon
                                                    className="w-5 h-5 "/>
                                            </button>
                                        </span>
                                        </Tippy>
                                    </div>
                                )}

                                {checkPerm(Resources.InvoicesBatch, CREATE_PERM) && Object.values(this.state.selectedRows).some((it) => !it.InvoiceBatchID) && (
                                    <div className="flex items-center gap-x-1 px-4">
                                        <Tippy content={translate('btn.create_invoices_batch')}>
                                        <span>
                                            <button
                                                onClick={() => this.setState({createBatchDialog: true})}
                                                className="flex p-1 rounded-btn text-primary-contrast hover:bg-primary-shade disabled:hover:bg-primary disabled:opacity-60"
                                            >
                                                <NumberedListIcon
                                                    className="w-5 h-5 "/>
                                            </button>
                                        </span>
                                        </Tippy>
                                    </div>
                                )}


                                {checkPerm(Resources.InvoicesInvoice, READ_PERM) && (
                                    <Flags authorizedFlags={['InvoiceJobON']}
                                           renderOn={() => (
                                               <div className="flex items-center gap-x-1 px-4">
                                                   <Tippy content={translate('text.create_zip')}>
                                                        <span>
                                                            <button
                                                                onClick={this.onBulkDownloadInvoices}
                                                                className="flex p-1 rounded-btn text-primary-contrast hover:bg-primary-shade disabled:hover:bg-primary disabled:opacity-60"
                                                            >
                                                                <ArchiveBoxArrowDownIcon
                                                                    className="w-5 h-5 "/>
                                                            </button>
                                                        </span>
                                                   </Tippy>
                                               </div>
                                           )}
                                           renderOff={() => null}
                                    />
                                )}

                                {checkPerm(Resources.AccountingColor, UPDATE_PERM) && (
                                    <div className="flex items-center gap-x-1 pl-4">
                                        <TableTagManager
                                            rowKey={'InvoiceID'}
                                            id={'InvoiceIDs'}
                                            requestMethod={'POST'}
                                            btnIconClass="w-5 h-5"
                                            customIcon={<ChevronUpSolidIcon className="w-5 h-5"/>}
                                            ExpenseIDs={[]}
                                            selectedRows={this.state.selectedRows}
                                            resourceName={Resources.AccountingColor}
                                            piggyResourceName={this.getResource()}
                                            query={this.getQuery()}
                                            onAfterTagRows={() => {
                                                this.setState({selectedRows: {}})
                                            }}
                                            translate={translate}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </TableBulkActions>

                    {/*Table footer*/}
                    <TableCardFooter
                        show={!(!data.length && !resource.isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={resource.isLoading || thirdResource.isLoading}
                            hideRowsPerPage={this.props.breakpoint.index <= 1}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>

                    <NoRecords
                        show={(data.length === 0) && !resource.isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                </TableCard>

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

                <ModalSaveResource
                    title={'Adjust income'}
                    widthClass="max-w-md"
                    show={this.state.isAdjustDialogVisible}
                    gridClass="grid gap-4 grid-cols-1 p-6"
                    onClose={this.toggleAdjustInvoice}
                    fields={this.getAdjustFields()}
                    onSubmit={(params) => {
                        if (params) {
                            params.InvoiceID = this.state.selectedItem.InvoiceID
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.InvoicesAdjust,
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: translate('message.invoice_adjusted')
                            }))
                            this.toggleAdjustInvoice(false)
                        }
                    }}
                    metadata={{
                        AccountID: {
                            api: 'api/' + Resources.AccountsQuick,
                            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                            searchMap: (item) => ({
                                value: item.AccountID,
                                label: item.AccountNumber + ' ' + item.AccountName,
                                metadata: item
                            })
                        }
                    }}
                    translate={this.props.translate}

                    htmlAfter={(fields) => {
                        const originalValue = this.state.selectedItem?.AmountTransferred ?? 0
                        let newValue = originalValue

                        let mod = 1
                        const Account = fields.AccountID?.value?.metadata
                        switch (Account?.MainAccountTypeID) {
                            case MAJOR_ACCOUNT_TYPE_LIABILITY:
                            case MAJOR_ACCOUNT_TYPE_EXPENSE:
                                mod = 1
                                break
                            default:
                                mod = -1
                        }
                        newValue += ((fields.Amount?.value ? numberWithCommasToBack(fields.Amount?.value) : 0) * mod)

                        return (
                            <div className="p-5 md border-t border-tm-gray-300">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <CurrencyDollarIcon className="h-6 w-6 text-tm-gray-500"/>
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dt className="text-sm font-medium text-tm-gray-700 truncate">
                                                <span>{translate('text.original_value')}: {genericMoneyFormatter(originalValue)}</span>
                                            </dt>
                                            <dd>
                                                <div className="text-lg font-medium text-tm-gray-900">
                                                    <span>{translate('text.new_value')} {genericMoneyFormatter(newValue)}</span>
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                />

                <ModalDefault
                    show={this.state.isDetailsDialogVisible}
                    widthClass={'max-w-full'}
                    title={translate('text.InvoiceID')}


                    buttonDisabled={this.editHidden(this.state.selectedItem) || !checkPerm(Resources.InvoicesInvoice, UPDATE_PERM)}
                    onButtonClick={() => {
                        this.toggleShowDetails();
                        this.openInvoiceDialog(this.state.selectedItem, true);
                    }}
                    buttonLabel={translate('btn.edit')}
                    customHtmlLeft={(
                        <Button
                            onClick={() => {
                                !this.deleteHidden(this.state.selectedItem) ? this.deleteResource(this.state.selectedItem) : !this.deleteLoadInvoiceHidden(this.state.selectedItem) && this.deleteLoadInvoice(this.state.selectedItem);
                            }}
                            appearance="danger"
                            disabled={this.deleteHidden(this.state.selectedItem) && this.deleteLoadInvoiceHidden(this.state.selectedItem)}
                            hasPerm={checkPerm(this.getResource(), DELETE_PERM)}
                        >
                            {translate("btn.delete")}
                        </Button>
                    )}
                    onMiddleButtonClick={() => this.toggleReceivePaymentDialog(this.state.selectedItem)}
                    middleButtonLabel={translate('text.receive_payment')}
                    middleButtonDisabled={!checkPerm(Resources.InvoicesPayment, CREATE_PERM) || this.canReceivePayment(secondData)}
                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.toggleShowDetails()}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={this.props.secondResource}
                        dialogResource={this.props.dialogResource}
                        isLoading={this.props.secondResource.isLoading || this.props.resource.isLoading || this.props.download.isLoading}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        selectedItem={this.state.selectedItem}
                        type="invoice"
                        canDeletePayment={this.state.selectedItem?.InvoiceTypeID != 2}
                        showLoadReferenceLinks
                        createCommentDispatchAction={createSecondResource}
                        deleteCommentDispatchAction={deleteSecondResource}
                        onDocumentDownload={(item) => {
                            const fileExt = item?.AttachPath.split('.').pop()

                            this.props.dispatch(download({
                                user: LocalStorage.get('user'),
                                resource: Resources.InvoicesDocuments,
                                query: Object.assign({
                                    id: item.InvoiceID,
                                    format: fileExt,
                                    name: 'document_' + currentDate() + '.' + fileExt
                                })
                            }))
                        }}

                        onFetchData={() => {
                            if (this.state.selectedItem) {
                                this.fetchInvoiceDialogData();
                            }
                        }}

                        onFactoringToggle={(item) => {
                            this.props.dispatch(updateSecondResource({
                                user: LocalStorage.get('user'),
                                resource: Resources.InvoicesFactoring,
                                params: {
                                    InvoiceID: item.InvoiceID
                                },
                                errorMessage: true,
                                successMessage:
                                    item.IsFactoringActive ?
                                        translate('text.UnmarkedForFactoring') :
                                        translate('text.MarkedForFactoring')
                            }))
                        }}

                        onDeletePayment={(item) => {
                            this.props.dispatch(deleteSecondResource({
                                user: LocalStorage.get('user'),
                                resource: Resources.InvoicesPayment,
                                query: {
                                    RecvPaymentID: item.RecvPaymentID
                                },
                                errorMessage: true, successMessage: translate('text.PaymentDeletedSuccessfully')
                            }))
                        }}
                    />
                </ModalDefault>

                {this.state.isInvoiceDialogVisible && (
                    <IncomeExpenseDialog
                        isInvoiceDialogType={true}
                        onClose={this.closeInvoiceDialog}
                        defaults={defaults}
                        query={this.getQuery()}
                        updateItem={this.state.updateItem}
                        onSubmit={(params, resource, files, fileResource) => {
                            if (params) {
                                if (params.id) {
                                    this.props.dispatch(updateResource({
                                        user: LocalStorage.get('user'),
                                        query: this.getQuery(),
                                        params: params,
                                        resource: resource,
                                        file: files,
                                        fileResource: fileResource,
                                        piggyResource: this.getResource(),
                                        errorMessage: true, successMessage: translate('message.invoice_updated')
                                    }))
                                } else {
                                    this.props.dispatch(createResource({
                                        user: LocalStorage.get('user'),
                                        query: this.getQuery(),
                                        params: params,
                                        resource: resource,
                                        file: files,
                                        fileResource: fileResource,
                                        piggyResource: this.getResource(),
                                        errorMessage: true, successMessage: translate('message.invoice_created')
                                    }));
                                    this.closeInvoiceDialog();
                                }
                            }
                        }}
                        {...this.props}
                    />
                )}

                {this.state.isInvoiceCopyDialogVisible && (
                    <IncomeExpenseDialog
                        isInvoiceDialogType={true}
                        onClose={this.closeCopyInvoiceDialog}
                        defaults={defaults}
                        query={this.getQuery()}
                        updateItem={this.state.updateItem}
                        isCopy={true}
                        onSubmit={(params, resource, files, fileResource) => {
                            if (params) {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: resource,
                                    file: files,
                                    fileResource: fileResource,
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: translate('message.invoice_created')
                                }))
                            }
                            this.closeCopyInvoiceDialog()
                        }}
                        {...this.props}
                    />
                )}

                <PaymentDialog
                    show={this.state.isReceivePaymentDialogVisible}
                    payment={'receive'}
                    item={this.state.receivePaymentItem}
                    resourcePath={Resources.InvoicesPayment}
                    piggyResourcePath={this.getResource()}
                    query={this.getQuery()}
                    onClose={this.toggleReceivePaymentDialog}
                    defaults={defaults}
                    {...this.props}
                />

                <BulkPaymentDialog
                    show={this.state.batchActionDialog}
                    translate={translate}
                    HideModalAnimation={this.state.batchActionDialogHideAnimation}
                    onClose={this.closeBatchActionDialog}
                    selectedItems={Object.values(this.state.selectedRows).filter((item) => item.InvoiceTypeID !== 2)}
                    onSubmit={this.submitBulkPayment}
                    defaults={defaults}
                    itemKeyLabel={'InvoiceID'}
                    containsInvalidEntry={selectedRowsContainReceipt}
                    {...this.props}
                />

                <CreateBatchDialog
                    show={this.state.createBatchDialog}
                    translate={translate}
                    onClose={this.closeCreateBatchDialog}
                    selectedItems={Object.values(this.state.selectedRows).filter((item) => !item.InvoiceBatchID)}
                    onSubmit={this.submitCreateBatch}
                    defaults={defaults}
                    itemKeyLabel={'InvoiceID'}
                    resource={this.props.resource}
                />

                <BatchListDialog
                    show={this.state.batchListDialog}
                    title={translate("text.batchListDialog")}
                    onClose={this.closeBatchListDialog}
                    closeButtonLabel={translate("btn.Close")}
                    itemKeyLabel={"InvoiceID"}
                    translate={translate}
                    batchID={this.state.batchID}
                    dialogInfoResource={this.props.dialogInfoResource}
                    dispatch={this.props.dispatch}
                    location={this.props.location}
                    showDetails={(it) => this.setState({batchDetailsDialog: true, batchID: it.InvoiceBatchID})}
                />

                <BatchDetailsDialog
                    show={this.state.batchDetailsDialog}
                    onClose={this.closeBatchNumberDialog}
                    closeButtonLabel={translate("btn.Close")}
                    itemKeyLabel={"InvoiceID"}
                    translate={translate}
                    batchID={this.state.batchID}
                    dialogResource={this.props.dialogResource}
                    dispatch={this.props.dispatch}
                    location={this.props.location}
                    secondResource={this.props.secondResource}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.deleteDialog}
                    text={this.state.confirmText}
                    onClose={() => this.handleToggleDeleteModal()}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.deleteDialog}
                />

                <ModalDefault
                    show={this.state.invoicePreviewDialog}
                    limitHeight={true}
                    widthClass={'max-w-7xl'}
                    title={translate('preview') + (this.state.previewDocument?.Description ? ' - ' + this.state.previewDocument?.Description : '')}

                    onButtonClick={this.handleDownloadDocument}
                    buttonLabel={translate('Download')}

                    customButtonsHTML={(
                        <div>
                            <button
                                className={`btn btn-primary mr-2`}
                                onClick={() => onDocumentPrint(Env.getApiUrl('api/' + Resources.BillingDocuments, Object.assign({}, {
                                    InvoiceID: this.state.previewDocument?.InvoiceID,
                                    token: getJWT().access_token,
                                    type: 1,
                                    name: 'invoice_' + this.state.previewDocument?.InvoiceID + '.pdf'
                                })), 'pdf')}
                            >
                                {translate('btn.Print')}
                            </button>
                        </div>
                    )}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={this.toggleProcessPreview}
                    isLoading={false}
                >
                    {!!this.state.invoicePreviewDialog && (
                        <FileViewer
                            fileType={'pdf'}
                            filePath={Env.getApiUrl('api/' + Resources.BillingDocuments, Object.assign({}, {
                                InvoiceID: this.state.previewDocument?.InvoiceID,
                                token: getJWT().access_token,
                                type: 1,
                                name: 'invoice_' + this.state.previewDocument?.InvoiceID + '.pdf'
                            }))}
                            onError={(e) => {
                                console.log(e)
                            }}
                        />
                    )}
                </ModalDefault>

                <EmailDocumentDialog
                    show={this.state.emailModalOpen}
                    title={translate('modal_heading.send_document_via_email')}
                    widthClass={'max-w-5xl'}
                    onClose={this.toggleSendEmailDialog}
                    fields={this.state.emailFields}
                    metadata={{
                        AddContactID: {
                            api: 'api/' + Resources.ContactsQuick,
                            query: {offset: 0, sort: 'ASC', limit: 10},
                            searchMap: (item) => ({
                                value: item.Email,
                                label: item.FirstName + ' ' + item.LastName
                            })
                        },
                        EmailTemplateID: {
                            api: 'api/' + Resources.EmailTemplates,
                            query: {offset: 0, sort: 'ASC', limit: 10},
                            searchMap: (item) => ({
                                value: item.EmailTemplateID,
                                label: item.EmailTemplateName
                            })
                        }
                    }}

                    fetchEmailTemplateQuery={{
                        InvoiceID: this.state?.previewDocument?.InvoiceID,
                        OrganizationID: this.state?.previewDocument?.OrganizationID
                    }}

                    handleInputChange={this.handleInputChange}
                    onSubmit={(params) => {
                        if (params) {
                            let emails = params.emails.filter(it => !it.invalid).reduce((memo, it) => {
                                memo += it.value + ','
                                return memo
                            }, '')

                            this.props.dispatch(getResource({
                                user: LocalStorage.get('user'),
                                query: Object.assign({}, {
                                    status: 1,
                                    InvoiceID: this.state.previewDocument.InvoiceID,
                                    type: 2,
                                    name: this.state.previewDocument.DocumentName,
                                    InvoiceSendEmails: emails.substring(0, emails.length - 1),
                                    subject: params.subject,
                                    message: params.emailMessage,
                                    HideBalance: this.state?.documentSettingsFields?.HideBalance?.value,
                                    ShowAdjustments: this.state?.documentSettingsFields?.ShowAdjustments?.value,
                                    AttachDocuments: this.state?.documentSettingsFields?.AttachDocuments?.value
                                }, this.getQuery()),
                                resource: Resources.BillingDocuments,
                                piggyResource: this.getResource(),
                                errorMessage: true,
                                successMessage: 'Sent invoice to: ' + emails.substring(0, emails.length - 1)
                            }))

                            this.toggleSendEmailDialog()
                        }
                    }}
                    onPrint={() => this.handleDownloadDocument()}
                    onDocumentSettingsInputChange={this.handleDocumentSettingsInputChange}
                    documentSettingsFields={this.state.documentSettingsFields}
                    document={this.state.previewDocument}
                    documentType={this.state.previewDocument?.InvoiceType}
                    documentDescription={this.state.previewDocument?.ExternalNotes}
                    fileType={'pdf'}
                    filePath={Env.getApiUrl('api/' + Resources.BillingDocuments, Object.assign({}, {
                        InvoiceID: this.state?.previewDocument?.InvoiceID,
                        token: getJWT().access_token,
                        type: 1,
                        name: 'invoice_' + this.state.previewDocument?.DocumentName,
                        HideBalance: this.state?.documentSettingsFields?.HideBalance?.value,
                        ShowAdjustments: this.state?.documentSettingsFields?.ShowAdjustments?.value,
                        AttachDocuments: this.state?.documentSettingsFields?.AttachDocuments?.value
                    }))}
                    {...this.props}
                />

                <ModalDefault
                    show={!!this.state.printInvoiceModalOpen}
                    widthClass={'max-w-7xl'}
                    limitHeight={true}
                    title={translate('modal_heading.sample_invoice')}

                    closeButtonLabel={translate('Close')}
                    onClose={() => this.printSampleInvoice()}
                    buttonLabel={translate('Download')}
                    onButtonClick={this.downloadDocument}
                >
                    <FileViewer
                        fileType={'pdf'}
                        filePath={Env.getApiUrl('api/' + Resources.AccountingIncomeSample, Object.assign({}, {
                            token: getJWT().access_token,
                            type: 1
                        }))}
                        onError={(e) => {
                            console.log(e)
                        }}
                    />
                </ModalDefault>

                <Modal
                    show={this.state.historyModalOpen}
                    widthClass={'max-w-full'}
                    onClose={() => this.setState({historyModalOpen: false})}
                    translate={translate}
                >
                    {this.state.historyModalOpen && (
                        <HistoryModal
                            onClose={() => this.setState({historyModalOpen: false})}
                            translate={translate}
                            title={translate('text.' + Resources.IncomeHistory)}
                            tableFields={DEFAULT_HISTORY_TABLE_FIELDS(translate, this.getFieldTypes())}
                            filterFields={DEFAULT_HISTORY_TABLE_FILTER_FIELDS()}
                            onFetchData={(query) => {
                                this.props.dispatch(getHistoryResource({
                                    user: LocalStorage.get('user'),
                                    query: Object.assign({}, query, {
                                        id: this.state.selectedItem.InvoiceID
                                    }),
                                    resource: Resources.IncomeHistory
                                }))
                            }}
                            data={getProp(historyResource, 'data.list', [])}
                            count={getProp(historyResource, 'data.count', 0)}
                            isLoading={getProp(historyResource, 'isLoading', false)}
                            showUserAvatar

                            onRowClick={(item) => {
                                this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
                            }}
                            fieldTypes={this.getFieldTypes()}
                        />
                    )}
                </Modal>

                {(this.state.isOrganizationInfoDialogOpen &&
                    <OrganizationInfoDialog
                        show={this.state.isOrganizationInfoDialogOpen}
                        translate={translate}
                        organizationID={this.state.selectedOrganization?.OrganizationID}
                        handleClose={() => this.setState({
                            isOrganizationInfoDialogOpen: false
                        })}
                    />
                )}

                {(this.state.isContactInfoDialogOpen &&
                    <ContactInfoDialog
                        show={this.state.isContactInfoDialogOpen}
                        translate={translate}
                        contactID={this.state.selectedContact?.ContactID}
                        handleClose={() => this.setState({
                            isContactInfoDialogOpen: false
                        })}
                    />
                )}

                <ModalConfirm
                    title={'Pay confirmation'}
                    show={this.state.isStripeConfirmDialogOpen}
                    type="custom"
                    customIcon={<StripeIcon fill="#fff" className="w-5 h-5"/>}
                    customIconBG="bg-[#6772e5]"
                    onClose={this.togglePayInvoiceModal}
                    buttonDisabled={this.state.isStripeIntentProcessing}
                    hideModalFooter={true}
                    translate={translate}
                >
                    {this.state.isStripeIntentProcessing && (
                        <div className="absolute inset-0 flex items-center justify-center">
                            <LoaderLarge/>
                        </div>
                    )}

                    <StripePayConfirmationModal
                        title={`Confirm Payment for Invoice #${this.state.selectedItem?.AutoCounter}`}
                        translate={translate}
                        selectedItem={this.state.selectedItem}
                        onClose={this.togglePayInvoiceModal}
                        onConfirm={this.handleConfirmPayWithStripeClick}
                        confirmButtonLabel={translate("btn.pay_with_card")}
                    />
                </ModalConfirm>

                <ModalConfirm
                    title={'Payment error!'}
                    show={!!this.state.stripeReaderStatus}
                    text={`Stripe terminal is ${this.state.stripeReaderStatus}.`}
                    type="custom"
                    customIcon={<StripeIcon fill="#fff" className="w-5 h-5"/>}
                    customIconBG="bg-[#6772e5]"
                    onClose={() => {
                        this.setState({
                            stripeReaderStatus: null
                        })
                    }}
                    closeButtonLabel={'Close'}
                    translate={translate}
                >
                    {this.state.isStripeIntentProcessing && (
                        <div className="absolute inset-0 flex items-center justify-center">
                            <LoaderLarge/>
                        </div>
                    )}
                </ModalConfirm>

                <ModalConfirm
                    title={'Create payment link'}
                    show={this.state.isCreatePaymentLinkDialogOpen}
                    type="custom"
                    customIcon={<StripeIcon fill="#fff" className="w-5 h-5"/>}
                    customIconBG="bg-[#6772e5]"
                    onClose={() => {
                        this.setState({
                            isCreatePaymentLinkDialogOpen: false
                        })
                    }}
                    // buttonDisabled={this.state.isStripeIntentProcessing}
                    hideModalFooter={true}
                    translate={translate}
                >
                    {this.state.isStripeIntentProcessing && (
                        <div className="absolute inset-0 flex items-center justify-center">
                            <LoaderLarge/>
                        </div>
                    )}

                    <StripePayConfirmationModal
                        title={`Create payment link for Invoice #${this.state.selectedItem?.AutoCounter}`}
                        translate={translate}
                        selectedItem={this.state.selectedItem}
                        onClose={() => {
                            this.setState({
                                isCreatePaymentLinkDialogOpen: false
                            })
                        }}
                        onConfirm={this.handleConfirmCreatePaymentLinkClick}
                        confirmButtonLabel={translate("btn.create_payment_link")}
                    />
                </ModalConfirm>

                <StripeBulkCreatePaymentLinks
                    show={this.state.isBulkCreatePaymentsLinksDialogOpen}
                    data={this.state.selectedRows}
                    onSubmit={this.handleConfirmCreatePaymentLinksClick}
                    isLoading={this.state.isStripeIntentProcessing}
                    onClose={() => this.setState({
                        isBulkCreatePaymentsLinksDialogOpen: false
                    })}
                    translate={translate}
                />

                <StripeBulkCreateTerminalPayments
                    show={this.state.isBulkCreateTerminalPaymentsDialogOpen}
                    data={this.state.selectedRows}
                    onSubmit={this.handleConfirmCreateTerminalPaymentsClick}
                    isLoading={this.state.isStripeIntentProcessing}
                    onClose={() => this.setState({
                        isBulkCreatePaymentsLinksDialogOpen: false
                    })}
                    translate={translate}
                />

                <ModalDefault
                    show={this.state.isApplyCreditDialogVisible}
                    widthClass={'max-w-6xl'}
                    title={translate('text.apply_credit')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.toggleApplyCredit()}
                >
                    <div>
                        <div className={"grid grid-cols-12 m-2"}>
                            <FieldContainer
                                item={this.state.fieldsApplyCreditDate.Date}
                                translate={translate}
                            >
                                <FieldDate
                                    {...this.state.fieldsApplyCreditDate.Date}
                                    addClass={'form-control text-center col-span-2'}
                                    onChange={this.handleApplyDateInputChange}
                                    showTimeSelect={false}
                                />
                            </FieldContainer>
                        </div>

                        <ResourceTable
                            data={dataApplyCredit}
                            commonTable={true}
                            maxHeightClass={'max-h-[calc(100vh-32rem)]'}
                            fields={this.getDetailsFields()}

                            translate={translate}
                            isLoading={isDialogLoading}

                            limit={(dataApplyCredit?.length ?? 10)}

                            actions={[
                                {
                                    action: this.ApplyCredit,
                                    icon: TicketIcon,
                                    visible: () => !!this.state.fieldsApplyCreditDate.Date.value,
                                    hasPerm: checkPerm(Resources.IncomeCreditApply, CREATE_PERM),
                                    title: translate('btn.apply'),
                                }
                            ]}
                        />

                        <NoRecordsTable
                            show={(dataApplyCredit.length === 0) && !isDialogLoading}
                            canCreate={false}
                            title={translate('text.no_matching_records')}
                        />
                    </div>
                </ModalDefault>


                <SetFactoringModal
                    show={this.state.isSetFactoringBalanceModalOpen}
                    onClose={this.toggleSetFactoringBalanceModal}
                    selectedItem={this.state?.selectedItem}
                    query={this.getQuery()}
                    translate={translate}
                />
            </React.Fragment>
        )
    }
}

export default connect(state => state)(InvoicesTab)
