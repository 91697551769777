import SocketAPI from "./socket";
import { pushNotification } from "../../data/actions/ui";
import { addNotification, setNotifications } from "./actions/notifications";
import {getProp} from "../../common/util/util-helpers";
import LocalStorage from "../../util/localStorage";

const socket = new SocketAPI();

const OnNotificationEvent = "OnNotification";
const OnNotificationsEvent = "OnNotifications";

const notificationMiddleware = store => next => action => {
    switch (action.type) {
        case 'DONE_LOGIN':
            connect(socket, store)
            break;
        case 'LOGOUT':
            disconnect(socket, store)
            break;
        default:
            break;
    }

    return next(action);
};

notificationMiddleware.getUrl = () => {
    return null;
}

notificationMiddleware.getToken = () => {
    return null;
}

notificationMiddleware.getCurrentUser = () => {
    return LocalStorage.get('user');
}

notificationMiddleware.run = (store, getUrl, getToken) => {
    notificationMiddleware.getUrl = getUrl;
    notificationMiddleware.getToken = getToken;

    if (getToken()) {
        connect(socket, store)
    }
}

const connect = (socket, store) => {
    const currentUser = notificationMiddleware.getCurrentUser();
    socket.connect(notificationMiddleware.getUrl(), notificationMiddleware.getToken())
        .then(() => {
            console.log("notificationMiddleware:connect")

        })
        .catch((e) => {
            console.log("notificationMiddleware:error", e)
        });

    socket.on(OnNotificationEvent, (data) => {
        if (!!data.Metadata && (data.Metadata?.event === "silent")) {
            store.dispatch(pushNotification({
                    user: currentUser,
                    title: data.Title,
                    content: data.Message,
                    notificationMetadata: data.Metadata,
                    position: "silent"
                }
            ));
        } else {
            store.dispatch(pushNotification({
                    user: currentUser,
                    title: data.Title,
                    content: data.Message,
                    notificationMetadata: data.Metadata,
                    position: "top"
                }
            ));

            let unreadCount = store.getState()?.notifications?.data?.unreadCount;
            let notifications = store.getState()?.notifications?.data?.notifications ?? {
                list: [],
                count: 0
            };
            unreadCount += 1;
            notifications.list.push(data);
            store.dispatch(addNotification({
                unreadCount: unreadCount,
                notifications: notifications
            }));

           void playAudioNotification(currentUser);
        }
    });
    socket.on(OnNotificationsEvent, (data) => {
        store.dispatch(setNotifications({
            unreadCount: 0,
            notifications: data
        }));
    });
}

async function playAudioNotification(currentUser) {
    const href = getProp(currentUser, 'Contact.NotificationSound', 0)
    if (href && !getProp(currentUser, 'Contact.NotificationSoundMute', 1)) {
        const audio = new Audio(`/audio/${href}.mp3`);

        if (audio.paused || audio.ended) {
            const playPromise = audio.play();

            if (playPromise !== undefined) {
                playPromise.catch((error) => {
                    console.error('Error while trying to play media:', error);
                });
            }
        }
    }
}

const disconnect = (socket, store) => {
    socket.disconnect()
        .then(() => {
            console.log("notificationMiddleware:disconnect");
        })
        .catch((e) => {
            console.log("notificationMiddleware:error", e)
        });
}

export default notificationMiddleware
