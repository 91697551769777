import React from 'react'
import moment from 'moment'
import {classNames} from '../../../../common/util/util-helpers'
import {FieldsManager} from '../../../../data/services/fields'
import {toBackDateTime} from "../../../../common/util/util-dates";
import LocalStorage from "../../../../util/localStorage";
import {fieldsToHtml, includeFields} from "../../../../common/util/util-fields";

export default function PayDateFilter({
                                          queryFields,
                                          updateQueryFields,
                                          onQueryChange,
                                          translate,
                                          hasDateType = true,
                                          isDateTypeClearable = true,
                                          isClearDatesButtonVisible = true,
                                          gridCols
                                      }) {
    const setTimePeriod = (StartDate, EndDate) => {
        let queryFieldsUpdate = Object.assign({}, queryFields)

        queryFieldsUpdate.StartDate = Object.assign({}, queryFieldsUpdate.StartDate, {value: StartDate})
        queryFieldsUpdate.EndDate = Object.assign({}, queryFieldsUpdate.EndDate, {value: EndDate})

        queryFieldsUpdate.StartDate.props.maxDate = queryFieldsUpdate.EndDate.value
        queryFieldsUpdate.EndDate.props.minDate = queryFieldsUpdate.StartDate.value

        if (hasDateType && !queryFieldsUpdate.DateType.value) {
            queryFieldsUpdate.DateType.value = '2'
        }

        if (queryFieldsUpdate.StartDate.value && queryFieldsUpdate.EndDate.value) {
            updateQueryFields(queryFieldsUpdate)
        }
    }

    const handleClearDaysClick = () => {
        let queryFieldsUpdate = Object.assign({}, queryFields)
        queryFieldsUpdate.StartDate.value = ''
        queryFieldsUpdate.EndDate.value = ''

        // hasDateType && isDateTypeClearable ? queryFieldsUpdate.DateType.value = '' : null

        queryFieldsUpdate.StartDate.props.maxDate = ''
        queryFieldsUpdate.EndDate.props.minDate = ''

        updateQueryFields(queryFieldsUpdate)
    }

    const getCurrentDate = () => {
        if (!LocalStorage.get('user')) return

        return moment().format('YYYY-MM-DD HH:mm:ss')
    }

    const setTimeSpan = (timeUnit, timeUnitType = 'day', operation = 'subtract') => {
        let fields = {...queryFields}

        if (operation === 'subtract') {
            fields = FieldsManager.updateField(fields, 'StartDate', moment().subtract(timeUnit, timeUnitType).format('YYYY-MM-DD HH:mm:ss'))
            fields = FieldsManager.updateField(fields, 'EndDate', moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'))
        } else {
            if (timeUnit === 'today') {
                FieldsManager.updateField(fields, 'StartDate', toBackDateTime(moment().startOf('day')))
                FieldsManager.updateField(fields, 'EndDate', getCurrentDate())
            } else if (timeUnit === 'this week') {
                FieldsManager.updateField(fields, 'StartDate', toBackDateTime(moment().startOf('isoWeek')))
                FieldsManager.updateField(fields, 'EndDate', toBackDateTime(moment().endOf('isoWeek')))
            } else if (timeUnit === 'last week') {
                FieldsManager.updateField(fields, 'StartDate', toBackDateTime(moment().subtract(1, 'weeks').startOf('isoWeek')))
                FieldsManager.updateField(fields, 'EndDate', toBackDateTime(moment().subtract(1, 'weeks').endOf('isoWeek')))
            } else {
                fields = FieldsManager.updateField(fields, 'StartDate', moment().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'))
                fields = FieldsManager.updateField(fields, 'EndDate', moment().add(timeUnit, timeUnitType).format('YYYY-MM-DD HH:mm:ss'))
            }
        }

        //  fields.offset ? fields.offset.value = 0 : null

        fields.StartDate.props.maxDate = fields.EndDate.value
        fields.EndDate.props.minDate = fields.StartDate.value

        updateQueryFields(fields)
    }

    const getPreviousMonths = () => {
        const currentYear = moment().startOf('year')
        return [...Array(12).keys()].map((it, i) => {
            let StartDate = moment().subtract(11 - it, 'month').startOf('month')
            const label = StartDate.format('MMM')
            StartDate = StartDate.format('YYYY-MM-DD HH:mm:ss')
            const EndDate = moment().subtract(11 - it, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')

            return (
                <button
                    key={i}
                    className={classNames(
                        'hover:bg-primary border-tm-gray-200 justify-center hover:text-white p-0 w-auto text-tm-gray-700 flex items-center py-2 px-2 flex-grow',
                        (moment(StartDate, 'YYYY-MM-DD HH:mm:ss').isBefore(currentYear)) ? 'bg-tm-gray-100' : '',
                        i !== 11 ? 'border-r' : undefined
                    )}
                    onClick={() => setTimePeriod(StartDate, EndDate)}
                >
                    {label}
                </button>
            )
        })
    }

    const handleSelectYear = (year) => {
        let queryFieldsUpdate = Object.assign({}, queryFields)

        if (year === 'lastYear') {
            queryFieldsUpdate.StartDate.value = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD HH:mm:ss')
            queryFieldsUpdate.EndDate.value = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD HH:mm:ss')
        }

        if (year === 'thisYear') {
            queryFieldsUpdate.StartDate.value = moment().startOf('year').format('YYYY-MM-DD HH:mm:ss')
            queryFieldsUpdate.EndDate.value = moment().endOf('year').format('YYYY-MM-DD HH:mm:ss')
        }


        updateQueryFields(queryFieldsUpdate)
    }

    const dateButtons = [
        {
            unit: 'today',
            unitType: 'hour',
            operation: 'add',
            label: translate('text.today'),
            buttonWidth: 'col-span-full'
        },
        {
            unit: 'last week',
            unitType: 'day',
            operation: 'add',
            label: translate('text.last_week')
        },
        {
            unit: 'this week',
            unitType: 'day',
            operation: 'add',
            label: translate('text.this_week')
        },
        {
            unit: 1,
            unitType: 'day',
            operation: 'subtract',
            label: translate('text.yesterday')
        },
        {
            unit: 1,
            unitType: 'day',
            operation: 'add',
            label: translate('text.tomorrow')
        },
        {
            unit: 3,
            unitType: 'day',
            operation: 'subtract',
            label: translate('text.last_3_days')
        },
        {
            unit: 3,
            unitType: 'day',
            operation: 'add',
            label: translate('text.next_3_days')
        },
        {
            unit: 7,
            unitType: 'day',
            operation: 'subtract',
            label: translate('text.last_7_days')
        },
        {
            unit: 7,
            unitType: 'day',
            operation: 'add',
            label: translate('text.next_7_days')
        },
        {
            unit: 1,
            unitType: 'month',
            operation: 'subtract',
            label: translate('text.last_30_days')
        },
        {
            unit: 1,
            unitType: 'month',
            operation: 'add',
            label: translate('text.next_30_days')
        }]

    return (
        <div className="col-span-full p-4 border border-tm-gray-200 rounded-card">
            <div className="flex items-center justify-between mb-3">
                <p className="text-base text-tm-gray-700">Select date range</p>

                {!!isClearDatesButtonVisible && (
                    <button
                        className="btn btn-outline-secondary py-1"
                        onClick={handleClearDaysClick}
                        disabled={hasDateType ? (!queryFields.StartDate.value && !queryFields.EndDate.value && !queryFields.DateType.value) : (!queryFields.StartDate.value && !queryFields.EndDate.value)}
                    >
                        Clear dates
                    </button>
                )}
            </div>

            <div className="grid grid-cols-12 gap-4">
                <div className={classNames("grid sm:col-span-6 gap-4", gridCols ?? "grid-cols-2")}>
                    {fieldsToHtml(Object.values(Object.assign({}, includeFields(queryFields, hasDateType ? ['StartDate', 'EndDate', 'DateType'] : ['StartDate', 'EndDate']))), translate, onQueryChange)}
                </div>

                <div className="col-span-full sm:col-span-6">
                    <label
                        className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap mb-0.5">
                        Quick selection
                    </label>

                    <div className="grid gap-1.5 grid-cols-2">
                        {dateButtons.map((it, i) => (
                            <button
                                key={i}
                                onClick={() => setTimeSpan(it.unit, it.unitType, it.operation)}
                                className={"btn btn-outline justify-center h-6" + " " + it.buttonWidth ?? null}
                            >
                                {it.label}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            <div className="flex border border-tm-gray-200 mt-4 rounded-md overflow-hidden">
                {getPreviousMonths()}
            </div>

            <div className={"grid grid-cols-12 gap-4 w-full mt-4"}>
                <button
                    onClick={() => handleSelectYear('lastYear')}
                    className={"btn btn-outline justify-center h-8 col-span-6"}
                >
                    Last year
                </button>
                <button
                    onClick={() => handleSelectYear('thisYear')}
                    className={"btn btn-outline justify-center h-8 col-span-6"}
                >
                    This year
                </button>
            </div>
        </div>
    )
}



