import React, {useState} from "react";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import {InformationCircleIcon, UsersIcon} from "@heroicons/react/24/outline";
import {useDispatch} from "react-redux";
import {COLOR_PICKER_COLOR} from "../../../../../util/util-constants";
import {Field, FieldsManager} from "../../../../../data/services/fields";
import {deleteDialogResource} from "../../../../../data/actions/dialogResource";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../../data/services/resources";
import Modal from "../../../modal";
import ModalHeader from "../../../modal/modal-header";
import NavResponsive from "../../../nav-responsive";
import {classNames} from "../../../../util/util-helpers";
import ModalFooter from "../../../modal/modal-footer";
import {LoaderLarge} from "../../../loader";
import FieldsToHtml from "../../../fields/fields-to-html";
import ColorPickerUserColors from "../../../color-picker/color-picker-user-colors";

export default function TableTagManagerModal({
                                                 show,
                                                 onClose,
                                                 fetchLabels,
                                                 onCreateTag,
                                                 tagsData,
                                                 isLoading,
                                                 translate
                                             }) {
    /** Store
     ================================================================= */
    const dispatch = useDispatch();

    /** State
     ================================================================= */
    const [tabs, setTabs] = useState(getTabs());
    const [fields, setFields] = useState(getFields());
    const [selectedLabels, setSelectedLabels] = useState({});
    const [selectedColor, setSelectedColor] = useState(COLOR_PICKER_COLOR[0]);

    /** Constants
     ================================================================= */
    const currentTab = tabs.find(it => it.current);

    /** UI Events
     ================================================================= */
    const handleTabChange = (tabName) => {
        let tabsUpdate = [...tabs];
        tabsUpdate.map(tab => {
            tab.current = tabName === tab.resource;

            return tab;
        });

        setTabs(tabsUpdate);
    }

    const handleInputChange = (name, value) => {
        let fieldsUpdate = fields;
        fieldsUpdate = FieldsManager.updateField(fieldsUpdate, name, value);
        setFields(fieldsUpdate);
    }

    const handleTagCheckboxChange = (id) => {
        let selectedLabelsUpdate = Object.assign({}, selectedLabels);
        if (selectedLabelsUpdate[id]) {
            delete selectedLabelsUpdate[id];
        } else {
            selectedLabelsUpdate = Object.assign({}, selectedLabelsUpdate, {[id]: id})
        }

        setSelectedLabels(selectedLabelsUpdate);
    }

    const deleteLabels = () => {
        const ColorTag = Object.keys(selectedLabels).reduce((memo, it) => {
            memo.push(it)
            return memo
        }, [])

        dispatch(deleteDialogResource({
            user: LocalStorage.get('user'),
            query: {ColorTagIDs: ColorTag},
            resource: Resources.ColorTag,
            piggyResource: Resources.ColorTag,
            errorMessage: true, successMessage: translate('text.selected_tags_deleted'),
        }))

        if (fetchLabels) {
            fetchLabels();
        }
    }

    /** Helpers
     ================================================================= */
    function getTabs() {
        return [
            {
                id: 1,
                name: 'Create',
                resource: 'create',
                icon: InformationCircleIcon,
                visible: true,
                invalid: false,
                current: true
            },
            {
                id: 2,
                name: 'Delete',
                resource: 'delete',
                icon: UsersIcon,
                visible: true,
                invalid: false,
            }
        ];
    }

    function getFields() {
        return {
            TagLabel: new Field('TagLabel', '', [''], false, 'text')
        }
    }

    return (
        <Modal
            show={show}
            widthClass="max-w-xs"
            gridColsClass="grid-cols-1"
            onClose={onClose}
            translate={translate}
        >
            <ModalHeader
                title={translate('text.manage_tags')}
                onClose={onClose}
            />

            <div className="">
                <NavResponsive
                    tabs={tabs}
                    onTabChange={handleTabChange}
                    translate={translate}
                />

                <div className="space-y-8 p-6 relative max-h-[calc(100vh-15rem)] overflow-auto">
                    {currentTab?.resource === 'create' && (
                        <React.Fragment>
                            <FieldsToHtml
                                fieldsState={fields}
                                onInputChange={handleInputChange}
                                translate={translate}
                            />

                            <label
                                htmlFor="NewTag"
                                className="flex flex-col text-sm font-semibold text-tm-gray-900"
                            >
                                {translate('text.colors')} *

                                <ColorPickerUserColors
                                    selectedColor={selectedColor}
                                    setSelectedColor={setSelectedColor}
                                    translate={translate}
                                />
                            </label>
                        </React.Fragment>
                    )}

                    {currentTab?.resource === 'delete' && (
                        <div className="min-h-[8rem]">
                            <div className="flex items-center mb-4">
                                Tag name
                                <TrashIcon className="w-5 h-5 ml-auto text-tm-gray-600 relative -right-px"/>
                            </div>

                            {tagsData.map((it) => {
                                const tag = it.ColorTag.split(',')
                                return (
                                    <label
                                        key={it.ColorTagID}
                                        className="flex items-center gap-2 text-sm py-2 rounded-md text-tm-gray-700 hover:bg-tm-gray-50 cursor-pointer"
                                    >
                                        <div className={classNames('w-3 h-3 rounded-full')}
                                             style={{background: tag[1]}}/>

                                        {tag[0]}

                                        <input
                                            type="checkbox"
                                            checked={!!selectedLabels[it.ColorTagID]}
                                            onChange={() => handleTagCheckboxChange(it.ColorTagID)}
                                            className={
                                                classNames(
                                                    "z-10 rounded bg-tm-gray-50 checked:bg-primary text-primary ring-offset-2 focus:ring-offset-inverse checked:ring-offset-inverse ml-auto",
                                                )
                                            }
                                        />
                                    </label>
                                )
                            })}
                        </div>
                    )}

                    {isLoading && (
                        <LoaderLarge stripesBg/>
                    )}
                </div>

                <ModalFooter
                    buttonLabel={currentTab?.resource === 'create' ? translate("btn.create") : translate("btn.delete")}
                    buttonDisabled={currentTab?.resource === 'create' ? !fields?.TagLabel?.value : !Object.keys(selectedLabels)?.length}
                    onButtonClick={() => {
                        currentTab?.resource === 'create'
                            ? onCreateTag(fields.TagLabel.value, selectedColor)
                            : deleteLabels();
                        handleInputChange('TagLabel', "");
                    }}

                    closeButtonLabel={translate("btn.close")}
                    onClose={onClose}
                />
            </div>
        </Modal>
    )
}