import React, {useEffect, useState} from 'react';
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import ModalDefault from "../../../../../common/components/modal/modal-default";
import {Field, FieldsManager} from "../../../../../data/services/fields";
import moment from "moment";
import ResourceTable from "../../../../../common/components/resource-table";
import {getUser} from "../../../../../common/util/util-auth";
import Resources from "../../../../../data/services/resources";
import {useDispatch, useSelector} from "react-redux";
import {getDialogResource} from "../../../../../data/actions/dialogResource";
import {DEFAULT_QUERY_LIMIT, READ_PERM} from "../../../../../common/util/util-consts";
import {checkPerm} from "../../../../../common/util/util-helpers";
import ActiveFilters from "../../../../../common/components/resource-table/table-components/active-filters";
import Button from "../../../../../common/components/button";
import NoRecordsTable from "../../../../../common/components/no-records-found/no-records-table";
import TableFilters from "../../../../../common/components/resource-table/table-components/table-filters";
import Badge from "../../../../../common/components/badge";
import InvoiceExpenseDetailsDialog from "../../../../accounting/dialogs/invoice-expense-details-dialog";
import {download} from "../../../../../data/actions/download";
import LocalStorage from "../../../../../common/util/localStorage";
import {currentDate} from "../../../../../common/util/util-dates";
import {createThirdResource, deleteThirdResource, getThirdResource} from "../../../../../data/actions/thirdResource";
import Pagination from "../../../../../common/components/resource-table/table-components/pagination";
import TableCardFooter from "../../../../../common/components/resource-table/table-components/table-card-footer";
import Tooltip from "../../../../../common/components/tooltip";
import DocumentArrowDownIcon from "@heroicons/react/20/solid/DocumentArrowDownIcon";

export default function TruckPayrollStatementsSearch({translate, TruckID, onRowClick}) {
    const resourcePath = Resources.TrucksPayrollSummaryResearch;
    const tableKey = 'AutoCounter';

    const dispatch = useDispatch();

    const resource = useSelector(state => state.dialogResource);
    const thirdResource = useSelector(state => state.thirdResource);
    const dialogInfoResource = useSelector(state => state.dialogInfoResource);
    const data = resource?.data?.list ?? [];
    const count = resource?.data?.count ?? 0;
    const isLoading = !!resource.isLoading;

    const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);
    const [queryFields, setQueryFields] = useState(() => getQueryFields());
    const [isExpenseDialogVisible, setIsExpenseDialogVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const query = FieldsManager.getFieldKeyValues(queryFields);
    query.TruckID = TruckID;

    // const tableOptions = getDefaultTableOptions(this.getFields(), {}, "", translate);

    function fetchData() {
        dispatch(getDialogResource({
            user: getUser(),
            resource: resourcePath,
            query: query
        }))
    }

    function downloadExcel() {
        dispatch(download({
            user: getUser(),
            resource: resourcePath,
            query: Object.assign({format: 'EXCEL', name: `truck_${TruckID}_payroll_statements_` + currentDate() + '.xlsx'}, query)
        }));
    }

    function handleQueryInputChange(name, value) {
        const queryFieldsUpdate = FieldsManager.updateField(queryFields, name, value);

        if ("sortBy" === name) {
            if (value === queryFieldsUpdate?.sortBy?.value) {
                queryFieldsUpdate.sort.value = queryFieldsUpdate.sort.value === 'ASC' ? 'DESC' : 'ASC'
            }
        }

        setQueryFields(queryFieldsUpdate);
    }

    function handleClearAllFiltersClick() {
        setQueryFields(getQueryFields(true));
    }

    function getFields() {
        return {
            AutoCounter: new Field('AutoCounter', '', [''], false, 'text', {label: "batch"}),
            Date: new Field('Date', '', [''], false, "date"),
            BookDate: new Field('BookDate', '', [''], false, 'date', {label: "CheckPostingDate"}),
            Description: new Field('Description', '', [''], false, 'textarea'),
            Amount: new Field('Amount', '', [''], false, 'money'),
            Type: new Field('Type', '', [], false, 'custom', {
                render: (it) => <SearchType item={it} translate={translate} onExpenseClick={handleExpenseClick}/>
            }),
        }
    }

    function getQueryFields(clearFilters = false) {
        const startOfLastMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss');
        const endOfLastMonth = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss');
        return {
            query: new Field('query', '', [''], false, 'search'),
            StartDate: new Field('StartDate', clearFilters ? "" : startOfLastMonth, [''], false, 'date', {}, {}),
            EndDate: new Field('EndDate', clearFilters ? "" : endOfLastMonth, [''], false, 'date', {}, {}),
            sort: new Field('sort', 'DESC', ['']),
            sortBy: new Field('sortBy', tableKey, ['']),
            offset: new Field('offset', "0", ['']),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true})
        }
    }


    function handleExpenseClick(item) {
        setSelectedItem(item);
        setIsExpenseDialogVisible(true);
    }

    useEffect(() => {
        if (isSearchDialogOpen) {
            fetchData();
        }
    }, [isSearchDialogOpen, queryFields]);

    return <>
        <Button
            className="btn btn-outline gap-2"
            hasPerm={checkPerm(resourcePath, READ_PERM)}
            onClick={() => setIsSearchDialogOpen(true)}
        >
            <MagnifyingGlassIcon className="w-5 h-5 -ml-1.5 "/>

            Search
        </Button>


        <ModalDefault
            show={isSearchDialogOpen}
            widthClass="max-w-7xl"
            title={translate('modal_heading.driver_statements_search')}
            hideDialogFooter={true}
            onClose={() => setIsSearchDialogOpen(false)}
            closeButtonLabel={translate('btn.close')}
            limitHeight={true}
            translate={translate}
        >
            <div className="flex-col gap-2 h-dialog-body h-screen">
                <div className="flex px-5 pt-1">
                    <ActiveFilters
                        filterFields={queryFields}
                        onLabelClick={handleQueryInputChange}
                        onClearFiltersClick={handleClearAllFiltersClick}
                        translate={translate}
                    />

                    <Tooltip content={translate('text.download_excel')}>
                        <button
                            className="btn-icon ml-auto"
                            onClick={downloadExcel}
                        >
                            <DocumentArrowDownIcon className="w-5 h-5"/>
                        </button>
                    </Tooltip>
                </div>

                <TableFilters
                    hideLimit
                    filterFields={queryFields}
                    handleInputChange={handleQueryInputChange}
                    translate={translate}
                    onRefreshTable={fetchData}
                    isLoading={isLoading}
                />

                <ResourceTable
                    data={data}
                    fields={getFields()}

                    translate={translate}
                    isLoading={isLoading}

                    queryFields={queryFields}
                    onSortChange={(fieldName) => handleQueryInputChange('sortBy', fieldName)}

                    onRowClick={onRowClick}

                    onView={onRowClick}

                    hasViewPerm={!!onRowClick}
                />

                <TableCardFooter
                    show={!(!data.length && !isLoading)}
                >
                    <Pagination
                        count={count}
                        isLoading={resource.isLoading}
                        handleQueryChange={(name, value) => handleQueryInputChange(name, value)}
                        queryFields={queryFields}
                        translate={translate}
                    />
                </TableCardFooter>

                <NoRecordsTable
                    show={(data.length === 0) && !isLoading}
                    canCreate={false}
                    title={'No matching records found'}
                />


            </div>

            <ModalDefault
                show={isExpenseDialogVisible}
                widthClass={'max-w-full'}
                title={translate('text.ExpenseID')}

                closeButtonLabel={translate('btn.Close')}

                onClose={() => setIsExpenseDialogVisible(false)}
            >
                <InvoiceExpenseDetailsDialog
                    resource={thirdResource}
                    disableTransactionImport={true}
                    selectedItem={selectedItem}
                    dialogResource={dialogInfoResource}
                    isLoading={dialogInfoResource.isLoading || thirdResource.isLoading}
                    translate={translate}
                    dispatch={dispatch}
                    type="expense"
                    createCommentDispatchAction={createThirdResource}
                    deleteCommentDispatchAction={deleteThirdResource}
                    expenseID={selectedItem?.ExpenseID}
                    canDeletePayment={selectedItem?.ExpenseTypeID != 2}
                    showLoadReferenceLinks

                    onDocumentDownload={(item) => {
                        const fileExt = item?.AttachPath.split('.').pop()

                        dispatch(download({
                            user: LocalStorage.get('user'),
                            resource: Resources.ExpenseDocuments,
                            query: Object.assign({
                                id: item.ExpenseID,
                                format: fileExt,
                                name: 'document_' + currentDate() + '.' + fileExt
                            })
                        }))
                    }}

                    onFetchData={() => {
                        if (selectedItem?.ExpenseID) {
                            dispatch(getThirdResource({
                                user: LocalStorage.get('user'),
                                query: {
                                    ExpenseID: selectedItem.ExpenseID
                                },
                                resource: Resources.ExpenseExpense
                            }))
                        }
                    }}
                />
            </ModalDefault>
        </ModalDefault>
    </>
}

function SearchType({item, onExpenseClick, translate}) {
    const badges = [];

    if (item.ExpenseID) {
        badges.push(<Badge
            onClick={(e) => {
                e.stopPropagation();
                onExpenseClick(item)
            }}
            type={"primary"}
        >{translate("text.ExpenseID")}</Badge>)
    }

    if (item.IsAdvance) {
        badges.push(<Badge type={"outline"}>{translate("text.advance_given")}</Badge>)
    }

    if (item.IsEscrowUsage) {
        badges.push(<Badge type={"outline"}>{translate("text.Escrow")}</Badge>)
    }

    if (item.IsGross) {
        badges.push(<Badge type={"outline"}>{translate("text.IsGross")}</Badge>)
    }


    return <div className="flex gap-1">{badges.map(it => it)}</div>
}