import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../../data/services/fields'
import {XMarkIcon} from '@heroicons/react/24/outline'
import moment from 'moment'
import Resources from '../../../../data/services/resources'
import {
    BookDateDefaultInvoice,
    BookDateDefaultPickup,
    DEFAULT_DATABASE_DATETIME_FORMAT
} from '../../../../util/util-constants'
import {genericMoneyFormatter, scrollErrorIntoViewFields} from '../../../../common/util/util-vanilla'
import ModalDefault from "../../../../common/components/modal/modal-default";
import {fieldsToHtml} from "../../../../common/util/util-fields";
import {LoaderLarge} from "../../../../common/components/loader";

export default class BillingBulkBatchModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            items: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show !== this.props.show && !!this.props.show && this.props.selectedItems) {
            this.setState({items: this.getItems()})
        }
        if (prevProps.show !== this.props.show && !this.props.show) {
            this.setState({items: []})
        }
    }

    submit = () => {
        this.setState({items: this.state.items.map(item => FieldsManager.validateFields(item))}, () => {
            if (this.state.items.every(item => FieldsManager.checkFieldsForErrors(item))) {
                this.props.onCreateBillingBulk({
                    params: {
                        Items: this.state.items.map(item => FieldsManager.getFieldKeyValues(item))
                    },
                    onResultCallback: (result) => {
                        if (result.status === 0) this.props.onClose(true)
                    },
                    resource: Resources.BillingBulk,
                    piggyResource: Resources.Billing,
                    query: this.props.query,
                    successMessage: this.props.translate("text.loads_invoices_created")
                })
            } else {
                scrollErrorIntoViewFields(this.state.items)
            }
        })
    }

    handleInputChange = (name, value, index) => {
        let items = this.state.items
        items[index] = FieldsManager.updateField(items[index], name, value)
        this.setState({items})
    }

    handleRemoveItem = (index) => {
        this.setState({items: this.state.items.filter((_, i) => i !== index)}, () => {
            if (this.state.items.length === 0) this.props.onClose()
        })
    }

    getItems = () => {
        return this.props.selectedItems ? Object.values(this.props.selectedItems).map((item, index) => this.getFields(item, index)) : []
    }

    getFields = (item = {}) => {
        return {
            LoadID: new Field('LoadID', item.LoadID, [''], true, 'hidden'),
            LoadNumber: new Field('LoadNumber', item.LoadNumber, [''], true, 'text'),
            Price: new Field('Price', genericMoneyFormatter(Number(item?.Price) + Number(item?.AccessorialAmount)), ['empty'], true, 'text'),
            Date: new Field('Date', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date'),
            DueDate: new Field('DueDate', moment().add(item?.CustomerNetTermDays ?? item?.CompanyNetTermDays ?? 15, 'days').format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date'),
            BookDate: new Field('BookDate',
                item?.BookDateDefault === BookDateDefaultInvoice ? this.returnValidDate(item?.EventDate) :
                    item?.BookDateDefault === BookDateDefaultPickup ? this.returnValidDate(item?.PickupDate) :
                        this.returnValidDate(item?.DestinationDate)
                , ['empty'], false, 'date'),
            IsFactoringActive: new Field('IsFactoringActive', item?.IsReceivableFactoringActive ? 1 : 0, [''], false, 'checkbox'),
        }
    }

    returnValidDate = (date) => {
        const validationDate = moment(date)
        return (date && validationDate.isValid()) ? date : ''
    }

    render() {
        const {translate} = this.props

        const fieldsHtml = this.state.items.map((item, index) => {
            const itemToHtml = fieldsToHtml(Object.values(Object.assign({}, item)), translate, (name, value) => this.handleInputChange(name, value, index), {})

            return (
                <div key={index} className={'mb-5'}>
                    <div className={'flex justify-end'}>
                        <XMarkIcon onClick={() => this.handleRemoveItem(index)}
                                   className={'w-5 h-5 cursor-pointer'}/>
                    </div>
                    <div className={'grid gap-4 grid-cols-3'}>
                        {itemToHtml}
                    </div>
                    <div className="separator py-5"/>
                </div>
            )
        })

        return (
            <ModalDefault
                show={this.props.show}
                widthClass={'max-w-5xl'}
                title={translate('text.ReceivePayment')}

                onButtonClick={this.submit}
                buttonLabel={translate('btn.save')}
                closeButtonLabel={translate('btn.Cancel')}
                onClose={this.props.onClose}
                buttonDisabled={this?.props?.isLoading}
            >
                <div className="relative p-5">
                    {this?.props?.isLoading && (
                        <LoaderLarge />
                    )}

                    {fieldsHtml}
                </div>
            </ModalDefault>
        )
    }
}

