import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {
    checkPerm,
    getLookup,
    getProp,
    handleMaskedFocus,
    hexToRgb,
    resourceIsUpdated
} from '../../../common/util/util-helpers'
import {getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../common/util/localStorage'
import Resources from '../../../data/services/resources'
import Dropzone from 'react-dropzone'
import Env from '../../../util/env'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import {getCountryCodes} from '../../../common/util/countryCodes'
import {
    DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS,
    MAXIMUM_DOCUMENT_UPLOAD_SIZE,
    SCAC_CODE_FIELD_LENGTH,
    UPDATE_PERM
} from '../../../common/util/util-consts'
import {dirtyClone} from '../../../common/util/util-vanilla'
import Tippy from "@tippyjs/react";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import FieldSwitch from "../../../common/components/fields/field-switch";
import FieldSwitchLabel from "../../../common/components/fields/field-switch/field-switch-label";
import Card from "../../../common/components/card";
import FieldCheckbox from "../../../common/components/fields/field-checkbox";
import FieldText from "../../../common/components/fields/field-text";
import InfoParagraph from "../../../common/components/info-paragraph";
import FieldTextMask from "../../../common/components/fields/field-text-mask";
import FieldSelectSearch from "../../../common/components/fields/field-select-search";
import FieldGooglePlaces from "../../../common/components/fields/field-google-places";
import {getJWT} from "../../../common/util/util-auth";
import ColorPickerUserColors from "../../../common/components/color-picker/color-picker-user-colors";

export default class InfoTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            canSubmit: false,
            files: [],
            altImage: false,
            footerIsSticky: true,
            flag: false,
            brokerage: !!getProp(this.props, 'resource.data.LineOfBusiness', []).includes('1'),
            carrier: !!getProp(this.props, 'resource.data.LineOfBusiness', []).includes('2'),

            src: null,
            crop: {
                unit: '%',
                width: 100,
                height: 50,
                // aspect: 2 / 1,
            },
            isCropVisible: false,
            lightBGForDarkMode: localStorage.getItem("lightBGForDarkMode") === "true"
        }

        this.fileImageObject = null
    }

    componentDidMount = () => {
        this.fetchData()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.canSubmit !== this.state.canSubmit) {
            this.props.setSubmit(true)
        }
        if (prevProps.resource.isLoading && (!this.props.resource.isLoading && getProp(this.props, 'resource.data.CompanyName', false))) {
            this.setState({
                fields: this.getFields(),
                brokerage: !!getProp(this.props, 'resource.data.LineOfBusiness', []).includes('1'),
                carrier: !!getProp(this.props, 'resource.data.LineOfBusiness', []).includes('2'),
            })
        }

        if (resourceIsUpdated(this.props.resource, prevProps.resource)) {
            LocalStorage.set("redirect_path", '/account');
            LocalStorage.remove('user');
            this.props.history.push('/logging-in');
        }
    }

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.Company
        }))
    }

    handleCheckboxChange = (state) => {
        let LineOfBusinessPrimary = this.state.fields.LineOfBusinessPrimary.value
        if (LineOfBusinessPrimary == '1' && state === 'brokerage' && !!this.state[state]) {
            LineOfBusinessPrimary = ''
        }
        if (LineOfBusinessPrimary == '2' && state === 'carrier' && !!this.state[state]) {
            LineOfBusinessPrimary = ''
        }
        this.setState({
            [state]: !this.state[state],
            canSubmit: true,
            fields: FieldsManager.updateField(this.state.fields, 'LineOfBusinessPrimary', LineOfBusinessPrimary)
        })
    }

    handleInputChange = (name, value) => {
        console.log('name, value', name, value)
        if (!this.state.flag) {
            this.props.canSwapTabsHandler()
        }
        if ((name === 'CountryID') && (value != 1)) {
            this.setState({fields: FieldsManager.updateField(this.state.fields, 'StateID', '')})
        }
        this.setState({canSubmit: true, fields: FieldsManager.updateField(this.state.fields, name, value)})
    }

    cancel = () => {
        if (this.state.canSubmit) {
            this.setState({
                canSubmit: false,
                src: null,
                croppedImageUrl: null,
                isCropVisible: false,
                fields: this.getFields(),
                brokerage: !!getProp(this.props, 'resource.data.LineOfBusiness', []).includes('1'),
                carrier: !!getProp(this.props, 'resource.data.LineOfBusiness', []).includes('2'),
            })
        }
    }

    getFields = () => {
        return {
            CompanyName: new Field('CompanyName', getProp(this.props, 'resource.data.CompanyName', ''), ['empty']),
            SCAC: new Field('SCAC', getProp(this.props, 'resource.data.SCAC', ''), []),
            AddressName: new Field('AddressName', getProp(this.props, 'resource.data.AddressName', ''), ['empty']),
            AddressName2: new Field('AddressName2', getProp(this.props, 'resource.data.AddressName2', ''), ['']),
            AreaCode: new Field('AreaCode', getProp(this.props, 'resource.data.AreaCode', ''), ['empty'], false, 'select'),
            PhoneNumber: new Field('PhoneNumber', getProp(this.props, 'resource.data.PhoneNumber', ''), ['contains_number']),
            PhoneExtension: new Field('PhoneExtension', getProp(this.props, 'resource.data.PhoneExtension', ''), ['']),
            CityName: new Field('CityName', getProp(this.props, 'resource.data.CityName', ''), ['empty']),
            CountryID: new Field('CountryID', getProp(this.props, 'resource.data.CountryID', 1), ['empty']),
            StateID: new Field('StateID', getProp(this.props, 'resource.data.StateID', ''), []),
            PostalCode: new Field('PostalCode', getProp(this.props, 'resource.data.PostalCode', ''), ['empty']),

            BusinessTypeID: new Field('BusinessTypeID', getProp(this.props, 'resource.data.BusinessTypeID', ''), ['empty']),
            FederalID: new Field('FederalID', getProp(this.props, 'resource.data.FederalID', ''), ['federal_id']),
            MCNumber: new Field('MCNumber', getProp(this.props, 'resource.data.MCNumber', ''), []),
            USDOTNumber: new Field('USDOTNumber', getProp(this.props, 'resource.data.USDOTNumber', ''), []),
            LineOfBusinessPrimary: new Field('LineOfBusinessPrimary', getProp(this.props, 'resource.data.LineOfBusinessPrimary', ''), ['']),
            LineOfBusiness: new Field('LineOfBusiness', getProp(this.props, 'resource.data.LineOfBusiness', ''), ['empty']),
            UseMultiSCAC: new Field('UseMultiSCAC', getProp(this.props, 'resource.data.UseMultiSCAC', ''), [''], false, 'checkbox'),
            IsReceivePromotions: new Field('IsReceivePromotions', getProp(this.props, 'resource.data.IsReceivePromotions', ''), [''], false, 'checkbox'),
            ColorLogoBackground: new Field('ColorLogoBackground', getProp(this.props, 'resource.data.ColorLogoBackground', ''), [], false, 'text'),
        }
    }

    submit = () => {
        if (this.state.canSubmit) {
            if ((!!this.state.carrier || !!this.state.brokerage)) {
                this.setState({isInvalid: false})
            } else {
                this.setState({isInvalid: true})
            }
            this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields) && (!!this.state.carrier || !!this.state.brokerage)) {
                    localStorage.setItem("lightBGForDarkMode", this.state.lightBGForDarkMode);
                    this.setState({canSubmit: false}, () => {
                        let arr = []
                        let LineOfBusinessPrimary = this.state.fields.LineOfBusinessPrimary.value
                        if (this.state.carrier) {
                            if (!LineOfBusinessPrimary && !this.state.brokerage) LineOfBusinessPrimary = '2'
                            arr.push('2')
                        }
                        if (this.state.brokerage) {
                            if (!LineOfBusinessPrimary && !this.state.carrier) LineOfBusinessPrimary = '1'
                            arr.push('1')
                        }
                        if (this.state.brokerage && !!this.state.carrier && !LineOfBusinessPrimary) {
                            this.setState({isInvalid: true})
                            return
                        }

                        this.props.setSubmit(false);

                        this.props.dispatch(updateResource({
                            user: LocalStorage.get('user'),
                            params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                                LineOfBusiness: arr,
                                LineOfBusinessPrimary: LineOfBusinessPrimary
                            }),
                            fileParams: {
                                id: getProp(this.props, 'resource.data.CompanyID')
                            },
                            errorMessage: true, successMessage: `${this.props.resource.data.CompanyName} updated`,
                            file: !!this.fileImageObject && [this.fileImageObject],
                            resource: Resources.Company,
                            piggyResource: Resources.User,
                            fileResource: Resources.CompanyImage,
                        }))
                    })
                }
            })
        }
    }

    setLocations = (fields) => {
        let updatedFields = dirtyClone(this.state.fields)

        for (const [key, value] of Object.entries(fields)) {
            if (key in this.state.fields) {
                updatedFields = FieldsManager.updateField(updatedFields, key, value)
            }
        }

        this.setState({
            fields: updatedFields,
            canSubmit: true
        })
    }

    onSelectFile = acceptedFiles => {
        if (acceptedFiles) {
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                this.setState({
                    src: reader.result,
                    canSubmit: true,
                    isCropVisible: true
                })
            )
            reader.readAsDataURL(acceptedFiles[0])
        }
    }

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image
    }

    onCropComplete = crop => {
        this.makeClientCrop(crop)
    }

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({crop})
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            )
            this.setState({croppedImageUrl})
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas')
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        canvas.width = crop.width
        canvas.height = crop.height
        const ctx = canvas.getContext('2d')

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty')
                    return
                }
                blob.name = fileName
                window.URL.revokeObjectURL(this.fileUrl)
                this.fileUrl = window.URL.createObjectURL(blob)
                this.fileImageObject = new File([blob], 'company-logo.png')
                resolve(this.fileUrl)
            }, 'image/png')
        })
    }

    handleLogoBackgroundSwitchClick = () => {
        this.setState({
            lightBGForDarkMode: !this.state.lightBGForDarkMode,
            canSubmit: true
        })
    }

    render() {
        const {crop, croppedImageUrl, src} = this.state
        const {translate} = this.props
        const states = getLookup('State', 'StateID', 'State')
        const countries = getLookup('Country', 'CountryID', 'CountryName')
        const BusinessType = getLookup('BusinessType', 'BusinessTypeID', 'BusinessType')
        const files = this.state.files.map((it, i) => {
            return (
                <div key={i}>{it.name}</div>
            )
        })

        const pdfPrimaryColor = `rgb(${hexToRgb(this.state.fields.ColorLogoBackground?.value)})`
        const pdfSecondaryColor = `rgba(${hexToRgb(this.state.fields.ColorLogoBackground?.value)}, .1)`

        return (
            <React.Fragment>
                {!this.props.resource.isLoading && (
                    <React.Fragment>
                        <div className="grid grid-cols-12 gap-4 mb-4">
                            <Card addClass="col-span-5">

                                <h5 className="text-lg mb-2 text-tm-gray-500">{translate('card_header.basic_info')}</h5>

                                <div className="grid grid-cols-12 gap-4">
                                    <div className="col-span-4">
                                        <label
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                        >
                                            {translate('field.country')} *
                                        </label>

                                        <FieldSelectSearch
                                            className="form-control"
                                            values={countries}
                                            onChange={this.handleInputChange} {...this.state.fields.CountryID}
                                            placeholder={''} addClass={'form-control'}
                                        />

                                    </div>

                                    {this.state.fields.CountryID && this.state.fields.CountryID.value && (
                                        <div className="col-span-8">
                                            <label
                                                className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                            >
                                                {translate('field.location_autocomplete')}
                                            </label>

                                            <FieldGooglePlaces
                                                key={this.state.fields.CountryID?.value}
                                                autoFocus
                                                setLocations={this.setLocations}
                                                stateFields={this.state.fields}
                                                placeholderText={translate('text.enter_a_city')}
                                            />
                                        </div>
                                    )}

                                    <div className="col-span-full">
                                        <label
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                        >
                                            {translate('field.company_name')} *
                                        </label>

                                        <FieldText
                                            className="form-control"
                                            onChange={this.handleInputChange} {...this.state.fields.CompanyName}
                                            addClass={'form-control'}/>
                                    </div>

                                    <div className="col-span-full">
                                        <label
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                        >
                                            {translate('field.AddressName')} *
                                        </label>

                                        <FieldText
                                            className="form-control"
                                            onChange={this.handleInputChange} {...this.state.fields.AddressName}
                                            addClass={'form-control'}
                                        />
                                    </div>

                                    <div className="col-span-full">
                                        <label
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                        >
                                            {translate('field.AddressName2')}
                                        </label>

                                        <FieldText
                                            className="form-control"
                                            onChange={this.handleInputChange} {...this.state.fields.AddressName2}
                                            addClass={'form-control'}
                                        />
                                    </div>

                                    <div className="col-span-4">
                                        <label
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                        >
                                            {translate('field.city')} *
                                        </label>

                                        <FieldText
                                            className="form-control"
                                            onChange={this.handleInputChange} {...this.state.fields.CityName}
                                            placeholder={''} addClass={'form-control'}
                                        />
                                    </div>


                                    {this.state.fields.CountryID.value == 1 && (
                                        <div className="col-span-4">
                                            <label
                                                className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                            >
                                                {translate('field.state')}
                                            </label>

                                            <FieldSelectSearch
                                                className="form-control"
                                                values={states}
                                                onChange={this.handleInputChange} {...this.state.fields.StateID}
                                                placeholder={''} addClass={'form-control'}
                                            />
                                        </div>
                                    )}

                                    <div className="col-span-4">
                                        <label
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                        >
                                            {translate('field.postal_code')} *
                                        </label>

                                        <FieldText
                                            className="form-control"
                                            onChange={this.handleInputChange} {...this.state.fields.PostalCode}
                                            placeholder={''} addClass={'form-control'}
                                        />
                                    </div>

                                    <div className="col-span-4">
                                        <label
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                        >
                                            {translate('field.area_code')} *
                                        </label>

                                        <FieldSelectSearch
                                            addClass="form-control"

                                            values={getCountryCodes()}
                                            onChange={this.handleInputChange} {...this.state.fields.AreaCode}
                                            placeholder={''}
                                        />
                                    </div>


                                    <div className="col-span-4">
                                        <label
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                        >
                                            {translate('field.main_phone')} *
                                        </label>

                                        <FieldTextMask
                                            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                            showMask
                                            className="form-control"
                                            onChange={this.handleInputChange}
                                            onFocus={handleMaskedFocus}
                                            {...this.state.fields.PhoneNumber}
                                            placeholder={''} addClass={'form-control'}
                                        />
                                    </div>

                                    <div className="col-span-4">
                                        <label
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                        >
                                            {translate('field.phone_extension')}
                                        </label>

                                        <FieldText
                                            addClass="form-control"
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.PhoneExtension}
                                            placeholder={''}
                                        />
                                    </div>
                                </div>
                            </Card>

                            <div className="col-span-3">
                                <Card addClass="h-full">
                                    <h5 className="text-lg mb-2 text-tm-gray-500">{translate('card_header.business_info')}</h5>

                                    <div className="grid grid-cols-12 gap-4">
                                        <div className="col-span-full">
                                            <label
                                                className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                            >
                                                {translate('field.business_type')} *
                                            </label>

                                            <FieldSelectSearch
                                                className="form-control"
                                                onChange={this.handleInputChange} {...this.state.fields.BusinessTypeID}
                                                placeholder={''} addClass={'form-control'}
                                                values={BusinessType}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-full">
                                        <label
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                        >
                                            Federal ID *
                                        </label>

                                        <FieldTextMask
                                            mask={[/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                            showMask
                                            className="form-control"
                                            onChange={this.handleInputChange} {...this.state.fields.FederalID}
                                            placeholder={''} addClass={'form-control'}
                                        />
                                    </div>

                                    <div className="col-span-full">
                                        <label
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                        >
                                            {translate('field.SCAC')}
                                        </label>

                                        <FieldText
                                            className="form-control"
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.SCAC}
                                            placeholder={''} addClass={'form-control'}
                                            max={SCAC_CODE_FIELD_LENGTH}
                                            upperCaseOnly
                                        />
                                    </div>
                                    <div className="col-span-full">
                                        <label
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5"
                                        >
                                            {translate('field.UseMultiSCAC')}
                                        </label>

                                        <FieldCheckbox
                                            className="checkbox w-6 h-6 mt-2"
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.UseMultiSCAC}
                                            addClass={'form-control'}
                                        />
                                    </div>
                                    {!!this.state.fields.UseMultiSCAC.value && (
                                        <div className="col-span-full">
                                            <InfoParagraph className="mb-3"
                                                           message={translate('message.UseMultiSCACChecked')}/>
                                        </div>
                                    )}
                                    <div className="col-span-full">
                                        <label
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                        >
                                            MC
                                        </label>

                                        <FieldText
                                            className="form-control"
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.MCNumber}
                                            placeholder={''} addClass={'form-control'}
                                        />
                                    </div>

                                    <div className="col-span-full">
                                        <label
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                        >
                                            USDOT
                                        </label>

                                        <FieldText
                                            className="form-control"
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.USDOTNumber}
                                            placeholder={''} addClass={'form-control'}
                                        />
                                    </div>

                                    <div className="col-span-full">
                                        <label
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5"
                                        >
                                            {translate('field.IsReceivePromotions')}
                                        </label>

                                        <FieldCheckbox
                                            className="checkbox w-6 h-6 mt-2"
                                            onChange={this.handleInputChange}
                                            {...this.state.fields.IsReceivePromotions}
                                            addClass={'form-control'}
                                        />
                                    </div>
                                </Card>
                            </div>


                            <div className="col-span-4">
                                <Card addClass="h-full">
                                    <h5 className="text-lg mb-2 text-tm-gray-500">{translate('card_header.company_logo')}</h5>


                                    <p className="text-tm-gray-500">Please use image that is at least 200px wide and
                                        100px high.</p>

                                    {!this.state.altImage && !croppedImageUrl && (
                                        <div
                                            style={{background: pdfSecondaryColor}}
                                            className="text-left relative mb-5 p-1">
                                            <i className="absolute position-absolute-center"></i>

                                            <img className="z-index-10 d-inline-block relative"
                                                 style={{width: '200px'}}
                                                 src={(this.state.files.length > 0) ? this.state.files[0].preview : Env.getApiUrl('api/' + Resources.CompanyImage, {
                                                     token: getJWT().access_token,
                                                     ContactID: LocalStorage.get('user')?.Contact.ContactID
                                                 })}
                                                 alt={''}
                                            />
                                        </div>
                                    )}

                                    {croppedImageUrl && (
                                        <React.Fragment>
                                            <h6 className="text-tm-gray-500">Preview:</h6>
                                            <div
                                                className="mb-4 border border-color-table">
                                                <img alt="Crop" className={'object-contain w-full h-72'}
                                                     src={croppedImageUrl}/>
                                            </div>
                                        </React.Fragment>
                                    )}

                                    {!!src && !!this.state.isCropVisible && (
                                        <React.Fragment>
                                            <h6 className="text-tm-gray-500">Select area to crop image to:</h6>

                                            <div className="row mb-3">
                                                <div className="col">
                                                    <ReactCrop
                                                        src={src}
                                                        crop={crop}
                                                        ruleOfThirds
                                                        onImageLoaded={this.onImageLoaded}
                                                        onComplete={this.onCropComplete}
                                                        onChange={this.onCropChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="text-right">
                                                <div className="separator mb-3"/>

                                                <button
                                                    onClick={() => {
                                                        this.fileImageObject = null

                                                        this.setState({
                                                            src: null,
                                                            croppedImageUrl: null
                                                        })
                                                    }}
                                                    className="btn btn-xs btn-outline"
                                                >
                                                    {translate('btn.cancel')}
                                                </button>

                                                <button
                                                    onClick={() => this.setState({
                                                        isCropVisible: false
                                                    })}
                                                    className="btn btn-xs btn-outline ml-3"
                                                >
                                                    {translate('btn.ok')}
                                                </button>
                                            </div>
                                        </React.Fragment>
                                    )}

                                    {(!src || !this.state.isCropVisible) && (
                                        <div className="row">
                                            <div className="col">
                                                <div className="mb-5 w-full text-center ">
                                                    <Dropzone
                                                        onDrop={(acceptedFiles) => {
                                                            this.onSelectFile(acceptedFiles)
                                                        }}
                                                        onDragEnter={this.onDragEnter}
                                                        onDragLeave={this.onDragLeave}
                                                        onDropAccepted={this.onDrop}
                                                        maxFiles={1}
                                                        accept={DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS}
                                                        maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                                                        multiple={false}
                                                    >
                                                        {({getRootProps, getInputProps}) => (
                                                            <section>
                                                                <div {...getRootProps()}
                                                                     className={'select-from-library-button flex justify-center items-center ' + (this.state.dropzoneActive ? 'active' : '')}>
                                                                    <div>
                                                                        <input {...getInputProps()} />
                                                                        <p>{translate('field.drag_n_drop')}</p>
                                                                        <em>{translate('field.image_types')}</em>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                    {files}
                                                </div>
                                            </div>
                                        </div>
                                    )}


                                    <div
                                        className="-mx-4 pr-4 col-span-full flex items-center hover:bg-sky-600/10 rounded-xl">
                                        <FieldSwitchLabel
                                            onClick={this.handleLogoBackgroundSwitchClick}
                                            label={this.props.translate("field.lightBGForDarkMode")}
                                            note={"This is only relevant for PNG logo with dark elements"}
                                        />

                                        <FieldSwitch
                                            id="ShowAllTasks"
                                            value={this.state.lightBGForDarkMode}
                                            onChange={this.handleLogoBackgroundSwitchClick}
                                        />
                                    </div>

                                    <Tippy
                                        interactive
                                        maxWidth={'200rem'}
                                        placement={'left'}
                                        theme={'light'}
                                        trigger={'click'}
                                        content={
                                            <>
                                                <div style={{backgroundColor: pdfSecondaryColor}}>
                                                    <div className="flex justify-between items-center p-10">
                                                        <h3 className={'mr-5 text-xl'}
                                                            style={{color: pdfPrimaryColor}}>PDF
                                                            Example</h3>
                                                        <img className="z-index-10 d-inline-block relative"
                                                             style={{width: '200px'}}
                                                             src={(this.state.files.length > 0) ? this.state.files[0].preview : Env.getApiUrl('api/' + Resources.CompanyImage, {
                                                                 token: getJWT().access_token,
                                                                 ContactID: LocalStorage.get('user')?.Contact.ContactID
                                                             })}
                                                             alt={''}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex justify-between items-center p-10">
                                                    <div>
                            <span style={{
                                borderLeft: `2px solid ${pdfPrimaryColor}`,
                                padding: '4px',
                                background: pdfSecondaryColor
                            }}>Details #1</span>
                                                        <div>Lorem</div>
                                                        <div>Ipsum</div>
                                                    </div>
                                                    <div>
                            <span style={{
                                borderLeft: `2px solid ${pdfPrimaryColor}`,
                                padding: '4px',
                                background: pdfSecondaryColor
                            }}>Details #2</span>
                                                        <div>Lorem</div>
                                                        <div>Ipsum</div>
                                                    </div>
                                                </div>

                                                <div style={{backgroundColor: pdfSecondaryColor}} className="p-10">
                                                    <table style={{border: `1px solid ${pdfPrimaryColor}`}}
                                                           className="w-full primary-table"
                                                           cellSpacing="0" cellPadding="0">
                                                        <thead>
                                                        <tr style={{backgroundColor: pdfPrimaryColor, color: '#fff'}}>
                                                            <th className="p-5">Item</th>
                                                            <th>Amount</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr className="text-sm">
                                                            <td className="p-5">Item 1</td>
                                                            <td>&#36;16,000.00</td>
                                                        </tr>
                                                        <tr style={{backgroundColor: pdfSecondaryColor}}>
                                                            <td className="p-5">Item 2</td>
                                                            <td>&#36;13,000.00</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className="w-3/4 ml-auto mt-5">
                                                        <div className={'flex justify-between'}>
                                                            <div>Sub Total</div>
                                                            <div className={'font-bold'}>&#36;40,000</div>
                                                        </div>
                                                        <div className={'flex justify-between'}>
                                                            <div>Duscount(10%)</div>
                                                            <div className={'font-bold'}>&#36;40,000</div>
                                                        </div>
                                                        <div className={'flex justify-between'}>
                                                            <div>Taxable Amount</div>
                                                            <div className={'font-bold'}>&#36;36,000</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    >
                                        <button className="btn btn-primary">
                                            Preview
                                        </button>
                                    </Tippy>
                                    <p className="text-tm-gray-500">Select logo background, will be used on PDFs that
                                        are
                                        created
                                        in the
                                        system.</p>

                                    <ColorPickerUserColors
                                        selectedColor={this.state.fields.ColorLogoBackground?.value}
                                        setSelectedColor={(val) => this.handleInputChange("ColorLogoBackground", val)}
                                        translate={translate}
                                    />
                                </Card>
                            </div>
                        </div>

                        <PageFooter
                            canSubmit={this.state.canSubmit}
                            actionCancel={this.cancel}
                            actionSubmit={checkPerm(Resources.Company, UPDATE_PERM) && this.submit}
                            translate={translate}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}
