import React, {Component, Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {ArrowTopRightOnSquareIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {connect} from "react-redux";
import Tippy from "@tippyjs/react";
import KnowledgeBaseHelp from "./knowledge-base-help";
import KnowledgeBaseCompanyProcedure from "./knowledge-base-company-procedure";
import Resources from "../../../../data/services/resources";
import {getProp} from "../../../util/util-helpers";
import {getHelpResource, updateHelpResource} from "../../../../data/actions/helpResource";
import LocalStorage from "../../../util/localStorage";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {LoaderLarge} from "../../loader";
import {excludeFields, fillFieldsFromData} from "../../../util/util-fields";

// TODO: Tuck logic inside Dialog component.
// This will not mount component until dialog is shown. Now is being mounted with page.

class KnowledgeBase extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isEditMode: false,
            canSubmit: false,
            fields: this.getFields(),
            tabs: [
                // {
                //     name: "help",
                //     resource: Resources.LoadEvents,
                //     visible: checkPerm(Resources.LoadEvents, READ_PERM),
                //     current: true
                // },
                {
                    name: "company_policy_and_procedures",
                    resource: Resources.LoadChatInternal,
                    current: true,
                    visible: true
                },
            ]
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidUpdate = (prevProps) => {
        if (prevProps.open !== this.props.open && this.props.open) {
            this.fetchData()
            this.setState({
                isEditMode: false,
                canSubmit: false
            })
        }
        if (prevProps.helpResource !== this.props.helpResource && !!this.props.helpResource.data) {
            const item = getProp(this.props.helpResource, "data.list", {});
            this.setState({fields: this.getFields(item)});
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getHelpResource({
            user: LocalStorage.get("user"),
            resource: Resources.Help,
            query: {
                pageKey: window.location.pathname
            }
        }))
    }

    submit = () => {
        this.setState({
            fields: FieldsManager.validateFields(this.state.fields),
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(updateHelpResource({
                    user: LocalStorage.get("user"),
                    request: "PUT",
                    resource: Resources.Help,
                    query: {
                        pageKey: window.location.pathname
                    },
                    piggyResource: Resources.Help,
                    params: FieldsManager.getFieldKeyValues(this.state.fields),
                    errorMessage: true, successMessage: 'Help Articles Updated Successfully!'
                }))
                this.handleCancel()
            }
        });
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({canSubmit: true, fields: FieldsManager.updateField(this.state.fields, name, value)})
    }

    handleCancel = () => {
        this.setState({
            isEditMode: false,
            canSubmit: false,
            fields: this.getFields(getProp(this.props.helpResource, "data.list", {}))
        })
    }

    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            isEditMode: false
        })
    }

    setIsEditMode = (bool = !this.state.isEditMode) => {
        this.setState({isEditMode: bool})
    }

    /** Fields/Data Definitions
     ================================================================= */
    getFields = (item = {}) => {
        const fieldTemplates = {
            Title: new Field("Title", "", ['']),
            Keywords: new Field("Keywords", "", ['']),
            HelpArticleParentID: new Field("HelpArticleParentID", "", [''], false, 'select-search'),
            Content: new Field("Content", "", [''], false, 'rich-text', {}, {className: "form-control min-h-[6rem] max-h-[calc(100vh-37rem)] overflow-y-auto"}),
            CompanyContent: new Field("CompanyContent", "", [''], false, 'rich-text', {}, {className: "form-control min-h-[6rem] max-h-[calc(100vh-30rem)] overflow-y-auto"}),
            PageKey: new Field("PageKey", "", [''], false, 'hidden'),
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    /** Helpers
     ================================================================= */
    handleVisitArticlesClick = () => {
        const win = window.open("/articles", "_blank");
        win.focus();
    }

    /** Render
     ================================================================= */
    render() {
        const {open, onClose, translate, helpResource} = this.props;
        const {isEditMode} = this.state;

        const currentTab = this.state.tabs.find(it => !!it.current);
        const data = getProp(helpResource, "data", {});

        return (
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" static className="fixed inset-0 overflow-hidden z-50" open={open}
                        onClose={onClose}>
                    <div className="absolute inset-0 overflow-hidden">
                        <Dialog.Overlay className="absolute inset-0"/>

                        <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-xl">
                                    <div
                                        className="h-full flex flex-col bg-inverse shadow-xl text-tm-gray-900 border-l border-tm-gray-300">
                                        <div className="">
                                            <div className="flex items-start justify-between">
                                                <div className="flex gap-1 items-center pt-4 pb-2 px-6">
                                                    <Dialog.Title className="text-lg font-medium">
                                                        {translate("text.KnowledgeBase")}
                                                    </Dialog.Title>

                                                    <Tippy
                                                        content={translate("text.visit_articles_page")}
                                                        delay={[400, 0]}
                                                        trigger={"mouseenter"}
                                                    >
                                                        <button
                                                            onClick={this.handleVisitArticlesClick}
                                                            className="btn-icon">
                                                            <ArrowTopRightOnSquareIcon
                                                                className="h-5 w-5 text-primary"/>
                                                        </button>
                                                    </Tippy>
                                                </div>

                                                <div className="pt-5 pr-6 ml-3 h-7 flex items-top space-x-3">
                                                    <button
                                                        className="btn btn-close"
                                                        onClick={() => onClose()}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                                    </button>
                                                </div>
                                            </div>

                                            {/*<NavResponsive*/}
                                            {/*    addClass="pl-3"*/}
                                            {/*    tabs={this.state.tabs}*/}
                                            {/*    onTabChange={this.handleTabChange}*/}
                                            {/*    translate={translate}*/}
                                            {/*/>*/}
                                        </div>

                                        {this.props.helpResource.isLoading && (
                                            <div className={"text-center mt-10 mb-4"}>
                                                <LoaderLarge/>
                                            </div>
                                        )}

                                        {currentTab.name === 'help' && (
                                            <KnowledgeBaseHelp
                                                helpResource={this.props.helpResource}
                                                data={data}
                                                isLoading={helpResource.isLoading}
                                                onSubmit={this.submit}
                                                setIsEditMode={this.setIsEditMode}
                                                fields={excludeFields(this.state.fields, ['CompanyContent'])}
                                                handleInputChange={this.handleInputChange}
                                                onCancel={this.handleCancel}
                                                isEditMode={isEditMode}
                                                canSubmit={this.state.canSubmit}
                                                translate={translate}
                                            />
                                        )}

                                        {currentTab.name === 'company_policy_and_procedures' && (
                                            <KnowledgeBaseCompanyProcedure
                                                helpResource={this.props.helpResource}
                                                data={data}
                                                isLoading={helpResource.isLoading}
                                                onSubmit={this.submit}
                                                setIsEditMode={this.setIsEditMode}
                                                fields={this.state.fields}
                                                handleInputChange={this.handleInputChange}
                                                onCancel={this.handleCancel}
                                                isEditMode={isEditMode}
                                                canSubmit={this.state.canSubmit}
                                                translate={translate}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        );
    }
}

export default connect(state => state)(KnowledgeBase);
