import React, {Component} from 'react'
import {FieldsManager} from "../../../../data/services/fields";
import {createResource, deleteResource, getResource, updateResource} from "../../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import {getDefaultTableOptions, getHeaderFilterValues} from "../../../util/util-helpers";
import InfoParagraph from "../../info-paragraph";
import NoRecordsTable from "../../no-records-found/no-records-table";
import ModalSaveResource from "../../modal/modal-save-resource";
import ResourceTable from "../../resource-table";
import {cloneDeep} from "../../../util/util-vanilla";
import {checkPerm, getProp, mergeDeep} from "../../../util/util-helpers";
import ActiveFilters from "../../resource-table/table-components/active-filters";
import TableCard from "../../resource-table/table-components/table-card";
import TableFilters from "../../resource-table/table-components/table-filters";
import Pagination from "../../resource-table/table-components/pagination";
import ModalConfirm from "../../modal/modal-confirm";
import {CREATE_PERM, DEFAULT_CRUD_STATE} from "../../../../util/util-constants";
import {fillFieldsFromData} from "../../../util/util-fields";
import Button from "../../button";

export default class ResourceListTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: this.props.sortBy ?? "",

            queryFilterFields: cloneDeep(this.props.searchFields) ?? {},
            headerFilterFields: this.getHeaderFilterFields(),
            fields: this.getFields(),
            tableOptions: this.props.tableOptions ?? getDefaultTableOptions(
                this.getFields(),
                {},
                this.props.tabKey,
                this.props.translate
            ),

            selectedItem: null,
            editModalOpen: false,
            confirmModalOpen: false
        };

        if (this.props.tabKey) {
            this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.props.tabKey));
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.queryFilterFields !== this.state.queryFilterFields) {
            if (typeof this.props.filters === 'function') {
                this.props.filters(this.state.queryFilterFields)
            }
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        if (this.props.onFetchDataCallback) {
            this.props.onFetchDataCallback(this.getQuery())
        } else {
            this.props.dispatch(getResource({
                user: LocalStorage.get("user"),
                query: this.getQuery(),
                resource: this.getResourceName()
            }))
        }
    };

    archiveResource = () => {
        this.props.dispatch(deleteResource({
            user: LocalStorage.get("user"),
            query: Object.assign({
                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
            }, this.getQuery()),
            resource: this.getResourceName(),
            piggyResource: this.getResourceName(),
            errorMessage: true,
            successMessage: this.props.dialogTitle + " delete.",
            refreshLocalStorageLookups: this.props.refreshLocalStorageLookups
        }));
        this.handleToggleConfirmModal()
    }

    restoreFromArchive = () => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {
                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()],
                ArchivedDate: 1
            },
            query: this.getQuery(),
            errorMessage: true, successMessage: this.props.dialogTitle + ` restored`,
            resource: this.getResourceName(),
            piggyResource: this.getResourceName(),
            refreshLocalStorageLookups: this.props.refreshLocalStorageLookups
        }))
        this.handleToggleConfirmModal()
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => {
            this.fetchData();
            this.saveFilters();
        })
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => {
            this.fetchData();
            this.saveFilters();
        });
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen,
            errorMessage: ''
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen,
            errorMessage: ''
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
                queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
                offset: 0,
                paginationPage: 1
            }, () => {
                this.fetchData();
                this.saveFilters();
            }
        );
    }

    handleFilterClear = () => {
        this.setState({
            queryFilterFields: cloneDeep(this.props.searchFields) ?? {},
            headerFilterFields: this.getHeaderFilterFields(),
        }, () => {
            this.fetchData();
            this.saveFilters();
        });
    }

    toggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    handleResultCallback = (result, successCallback = () => {
    }) => {
        if (result && result.status === 0) {
            successCallback()
        }
        if (result && result.status === 2) {
            this.setState({errorMessage: result.data})
        }
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = [''];//['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }
    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getPrimaryKey = () => {
        return this.props.primaryKey ?? "";
    }

    getResourceName = () => {
        return this.props.resourceName ?? "";
    }

    getQuery = () => {
        return Object.assign({}, (this.getId()) ? {id: this.getId()} : {}, {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            archived: this.state.archived ? 1 : 0,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),

            ...getHeaderFilterValues(this.state.headerFilterFields),
        }, this.props.additinonalQuery ?? {})
    }

    getHeaderFilterFields = () => {
        return this.props.headerFilterFields ?? {}
    }

    getFields = (item = null) => {
        const fieldTemplates = cloneDeep(this.props.fields);

        return fillFieldsFromData(fieldTemplates, item);
    };

    saveFilters = () => {
        if (this.props.tabKey) {
            LocalStorage.persistState(this.props.tabKey, this.state, Object.keys(this.getQuery()));
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {
            translate,
            disableEdit,
            disableDelete,
            disableCreate,
            enableRestore,
            onCreateButtonClick,
            onSecondaryButtonClick,
            secondaryButtonDisabled = false,
            secondaryButtonLabel,
            tableFooterLeft,
            isSelectRowDisabled,
            onRowClick,
            buttonLabel = translate('btn.create_new'),
            hasPagination = true,
            secondaryButtonHasPerm = true,
            addClassTableCard = ""
        } = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        return (
            <React.Fragment>
                <div className={"flex"}>
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />

                    {!!this.props.dialogTitle && !this.props.hideTitle && (
                        <div className={"text-xl"}>{this.props.dialogTitle}</div>
                    )}

                    <div className="flex ml-auto space-x-4">
                        {!disableCreate && (
                            <Button
                                onClick={() => onCreateButtonClick ? onCreateButtonClick() : this.handleToggleCreateModal()}
                                hasPerm={checkPerm(this.getResourceName(), CREATE_PERM)}
                                appearance={`primary`}
                            >
                                {buttonLabel}
                            </Button>
                        )}

                        {secondaryButtonLabel && (
                            <Button
                                hasPerm={secondaryButtonHasPerm}
                                appearance={`muted`}
                                onClick={onSecondaryButtonClick}
                                disabled={secondaryButtonDisabled}
                            >
                                {secondaryButtonLabel}
                            </Button>
                        )}
                    </div>
                </div>

                {this.props.infoParagraph && (
                    <div className="mt-2">
                        <InfoParagraph className="" message={this.props.infoParagraph}/>
                    </div>
                )}

                <TableCard addClass={addClassTableCard}>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        isLoading={isLoading}
                    />

                    <ResourceTable
                        data={Array.isArray(data) ? data : []}
                        fields={this.getFields()}
                        verticalTableIsVisible={this.props.breakpoint && this.props.breakpoint.index <= 1}

                        addClass={this.props.addClass}
                        tableKey={this.props.tableKey}
                        onSelectRow={this.props.onSelectRow}
                        onSelectAllRows={this.props.onSelectAllRows}
                        isSelectRowDisabled={isSelectRowDisabled}
                        selectedRows={this.props.selectedRows}
                        onRowClick={onRowClick}

                        translate={translate}
                        isLoading={isLoading}

                        options={this.state.tableOptions}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onEdit={!disableEdit ? this.handleToggleEditModel : null}
                        onDelete={!disableDelete ? this.handleToggleConfirmModal : null}
                        onRestore={(!disableDelete && !!enableRestore) ? this.handleToggleConfirmModal : null}

                        actions={this.props.actions}

                        limit={getProp(this.state.queryFilterFields, 'limit.value', 10)}
                    />

                    {/*Table footer*/}
                    {!(!data.length && !isLoading) && (
                        <div
                            className="bg-inverse px-4 py-3 flex items-center justify-between ring-1 ring-black ring-opacity-5 sm:px-6 h-16 rounded-b-lg"
                        >
                            {tableFooterLeft}
                            {!!hasPagination && (
                                <Pagination
                                    count={count}
                                    isLoading={isLoading}
                                    queryFields={this.state.queryFilterFields}
                                    hideRowsPerPage={this.props.breakpoint && this.props.breakpoint.index <= 1}
                                    handleQueryChange={
                                        (name, value, currentPage) => name === "offset"
                                            ? this.handleUpdateOffset(value, currentPage)
                                            : this.handleFilterInputChange(name, value)
                                    }
                                    pageOffset={this.state.offset}
                                    translate={translate}
                                />
                            )}
                        </div>
                    )}

                    <NoRecordsTable
                        show={(data.length === 0) && !isLoading}
                        canCreate={false}
                        title={'No matching records found'}

                        className={"pb-12 pt-16 px-6"}
                        onClearFilterClick={this.handleClearFiltersClick}
                        clearFilterBtnLabel={translate("text.clear_all_filters")}
                        clearFilterText={translate("text.try_without_filters")}
                        filters={{}}
                    />
                </TableCard>

                <ModalSaveResource
                    title={"Edit " + (this.props.dialogTitle ? this.props.dialogTitle : "")}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    errorMessage={this.state.errorMessage}
                    show={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    handleAddPhoneField={() => {
                    }}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName(),
                                errorMessage: true, successMessage: this.props.dialogTitle + " updated.",
                                onResultCallback: (result) => this.handleResultCallback(result, () => this.handleToggleEditModel(null)),
                                refreshLocalStorageLookups: this.props.refreshLocalStorageLookups
                            }));
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata}
                    handleInputChange={this.props.handleInputChange ?? null}
                    closeButtonLabel={translate('btn.cancel')}
                />

                <ModalSaveResource
                    show={this.state.createModalOpen}
                    title={"Create " + (this.props.dialogTitle ? this.props.dialogTitle : "")}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    errorMessage={this.state.errorMessage}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            if (this.props.createDataCallback) {
                                this.props.createDataCallback({
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName(),
                                    query: this.getQuery(),
                                    onResultCallback: (result) => this.handleResultCallback(result, this.handleToggleCreateModal),
                                    errorMessage: true, successMessage: this.props.dialogTitle + " created.",
                                })
                            } else {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName(),
                                    errorMessage: true, successMessage: this.props.dialogTitle + " created.",
                                    onResultCallback: (result) => this.handleResultCallback(result, this.handleToggleCreateModal),
                                    refreshLocalStorageLookups: this.props.refreshLocalStorageLookups
                                }));
                            }
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata}
                    handleInputChange={this.props.handleInputChange ?? null}
                    closeButtonLabel={translate('btn.cancel')}
                />

                <ModalConfirm
                    title={"Confirm"}
                    show={!!this.state.confirmModalOpen}
                    text={"Are you sure you want to " + (this.state.selectedItem?.ArchivedDate ? 'restore' : 'delete') + " this " + this.props.dialogTitle + "?"}
                    onClose={this.handleToggleConfirmModal}
                    buttonLabel={translate("btn.confirm")}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        if (this.state.selectedItem?.ArchivedDate) {
                            this.restoreFromArchive()
                        } else {
                            this.archiveResource()
                        }
                    }}
                />
            </React.Fragment>
        )
    }
}
