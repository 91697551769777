import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../../data/services/resources'
import InfoTab from './infoTab'
import ReviewsTab from './../customer/reviewsTab'
import Tippy from '@tippyjs/react'
import {showGlobalModal} from '../../../data/actions/ui'
import LocalStorage from '../../../util/localStorage'
import {getSecondResource} from '../../../data/actions/secondResource'
import {getCountTaskResource} from '../../../data/actions/taskResource'
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import {Field} from '../../../data/services/fields'
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import {CREATE_PERM, DELETE_PERM, READ_PERM, REFERENCE_TYPE_VENDOR, UPDATE_PERM} from '../../../util/util-constants'
import {checkPerm, classNames, getDocumentTypesFor, getProp} from '../../../common/util/util-helpers'
import VendorSummaryTab from "./summaryTab";
import {getThirdResource} from "../../../data/actions/thirdResource";
import {deleteDialogResource} from "../../../data/actions/dialogResource";
import ContactsTab from "../../../common/components/tabs/contacts-tab";
import ResourceTasksDialog from "../../../common/components/modal/tasksDialogs/resourceTasksDialog";
import ExpiryItemsTab from "../../../common/components/tabs/expiry-items-tab";
import CompaniesPaymentTab from "../../../common/components/tabs/companies-payment-tab";
import Page from "../../../common/components/layout/layout-components/page";
import DocumentsUpdateTab from "../../../common/components/tabs/documents-tab/update-documents";
import Layout from "../../../common/components/layout";
import NavResponsive from "../../../common/components/nav-responsive";
import ModalDefault from "../../../common/components/modal/modal-default";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";

class VendorView extends Component {

    constructor(props) {
        super(props)

        const tabs = [
            {
                name: 'VendorsInfo',
                resource: Resources.VendorsInfo,
                current: false,
                visible: checkPerm(Resources.VendorsInfo, READ_PERM)
            },
            {
                name: 'VendorsSummary',
                resource: Resources.AccountingSummary,
                current: false,
                visible: checkPerm(Resources.AccountingSummary, READ_PERM)
            },
            {
                name: 'VendorsPayment',
                resource: Resources.VendorsPayment,
                current: false,
                visible: checkPerm(Resources.VendorsPayment, READ_PERM)
            },
            {
                name: 'VendorsContacts',
                resource: Resources.VendorsContacts,
                current: false,
                visible: checkPerm(Resources.VendorsContacts, READ_PERM)
            },
            {
                name: 'VendorsReviews',
                resource: Resources.VendorsReviews,
                current: false,
                visible: checkPerm(Resources.VendorsReviews, READ_PERM)
            },
            {
                name: 'ExpiryItems',
                resource: Resources.VendorsExpiry,
                current: false,
                visible: checkPerm(Resources.VendorsExpiry, READ_PERM)
            },
            {
                name: 'VendorsDocuments',
                resource: Resources.VendorsDocuments,
                current: false,
                visible: checkPerm(Resources.VendorsDocuments, READ_PERM)
            },
        ]
        tabs[0].current = true

        this.state = {
            // Tabs
            tabs: tabs,
            isDirty: false,
            selectedTab: Resources.VendorsInfo,

            // Modals
            resourceTaskModalOpen: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        if (this.getID()) {
            this.selectTab()
            this.fetchData()
            this.fetchDataThird()
            this.fetchTaskResourceCount()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource !== this.props.resource && this.state.selectedTab === Resources.VendorsInfo) {
            this.setState({
                LegalName: getProp(this.props.resource, 'data.LegalName', ''),
                ArchivedDate: getProp(this.props.resource, 'data.ArchivedDate', null),
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = (query = '') => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.VendorsDocuments,
            query: {
                id: this.getID(),
                query: query
            }
        }))
    }

    fetchDataThird = (query = '') => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            resource: Resources.VendorsInfo,
            query: {
                id: this.getID(),
                query: query
            }
        }))
    }

    fetchTaskResourceCount = () => {
        this.props.dispatch(getCountTaskResource({
            user: LocalStorage.get('user'),
            resource: Resources.TaskResourceCount,
            query: {param: window.location.pathname}
        }))
    }

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        if (this.state.isDirty) {
            this.setState({
                confirmModal: true,
                reserveTab: resource
            })
        } else {
            this.tabChange(resource)
        }
    }

    tabChange = (resource) => {
        this.props.history.push('?tab=' + resource)
        this.setState({
            confirmModal: false,
            isDirty: false,
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    handleCreateNewTask = () => {
        this.props.dispatch(showGlobalModal('createTaskDialog', {taskResource: this.props.match.url}))
    }

    handleToggleResourceTasks = () => {
        this.setState({
            resourceTaskModalOpen: !this.state.resourceTaskModalOpen
        })
    }

    /** Helpers
     ================================================================= */

    getID = () => {
        return this.props.match.params.VendorID
    }

    canSwapTabsHandler = (isDirty = true) => {
        this.setState({isDirty: isDirty})
    }

    selectTab = () => {
        const query = new URLSearchParams(this.props.location.search)
        const tab = query.get('tab')
        if (tab) this.handleTabChange(tab)
    }

    render() {
        const {translate} = this.props
        const TaskCount = getProp(this.props.taskResource, 'count.count', 0)
        const infoData = getProp(this.props.thirdResource, 'data', {})
        const vendorName = getProp(infoData, 'LegalName', 'Vendor')

        const openTaskCountTwoDigits = TaskCount > 9;

        return (
            <Layout
                {...this.props}
                hasFooter={
                    this.state.selectedTab === Resources.VendorsInfo ||
                    this.state.selectedTab === Resources.VendorsSettings ||
                    this.state.selectedTab === Resources.VendorsPayment
                }
                isDirty={this.state.isDirty}
                customPageTitle={vendorName}
            >
                <Page>
                    <div className="flex justify-between items-center">
                        <div className="mb-2 flex justify-start items-center">
                            <GoBackButton
                                translate={this.props.translate}
                                history={this.props.history}
                                path={'/vendors'}
                            />
                            <h1 className={'mr-5 text-3xl ml-2'}>
                                {translate('text.vendor')}
                                <span className="text-tm-gray-500"> - {infoData.LegalName}
                                    {this.state.ArchivedDate && (
                                        <Tippy content={<span>Archived</span>}>
                                            <i className="iconsminds-delete-file"/>
                                        </Tippy>
                                    )}
                                            </span>
                            </h1>
                        </div>

                        <div className="gap-2 flex flex-wrap items-center justify-end">
                            {checkPerm(Resources.Tasks, CREATE_PERM) && (
                                <button className="btn btn-outline mr-3"
                                        onClick={this.handleCreateNewTask}>{translate('btn.new_vendor_task')}
                                </button>
                            )}

                            {checkPerm(Resources.Tasks, READ_PERM) && (
                                <div className="relative">
                                    <button
                                        className="btn btn-outline"
                                        disabled={!checkPerm(Resources.TaskResource, READ_PERM)}
                                        onClick={() => this.handleToggleResourceTasks()}>

                                        {TaskCount > 0 && (
                                            <span
                                                className={
                                                    classNames(
                                                        "absolute z-10 flex -top-2.5 -left-2",
                                                        openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                    )
                                                }
                                            >
                                                          <div
                                                              className="absolute z-10 font-bold inset-0 flex items-center justify-center text-primary-contrast">{[TaskCount]}</div>
                                                          <span
                                                              className="animate-ping absolute z-10 inline-flex h-full w-full rounded-full bg-primary opacity-25"></span>
                                                          <span
                                                              className={
                                                                  classNames(
                                                                      "relative inline-flex rounded-full bg-primary",
                                                                      openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                                  )
                                                              }
                                                          />
                                                    </span>
                                        )}
                                        {translate('btn.show_tasks')}
                                        {this.props.taskResource.isCountLoading && `(${translate('text.Loading...')})`}
                                        {!this.props.taskResource.isCountLoading && TaskCount === 0 && `(${TaskCount})`}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mb-5 hidden sm:block">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="mb-5 sm:hidden">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div
                        className={`col${this.state.selectedTab === Resources.VendorsInfo ? '-lg-12' : ''}`}>
                        {this.state.selectedTab === Resources.VendorsInfo && (
                            <InfoTab
                                canSwapTabsHandler={this.canSwapTabsHandler}
                                {...this.props}/>
                        )}

                        {this.state.selectedTab === Resources.AccountingSummary && (
                            <VendorSummaryTab
                                {...this.props}
                                canSwapTabsHandler={this.canSwapTabsHandler}
                                id={this.props.match.params.OrganizationID}
                            />
                        )}

                        {this.state.selectedTab === Resources.VendorsDocuments && (
                            <DocumentsUpdateTab
                                id={this.props.match.params.OrganizationID}
                                dispatch={this.props.dispatch}
                                translate={this.props.translate}
                                resource={this.props.resource}
                                thirdResource={this.props.thirdResource}

                                sortBy={'Description'}
                                primaryKey={'OrganizationDocumentID'}

                                download={this.props.download}
                                resourceName={Resources.VendorsDocuments}

                                documentUpdateFields={checkPerm(Resources.VendorsDocuments, UPDATE_PERM) && {
                                    DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}, {
                                        values: getDocumentTypesFor("IsCompanyDocument")
                                    }),
                                    ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {addContainerClass: 'col-span-full'}, {isClearable: true}),
                                    Description: new Field("Description", '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
                                }}
                                documentTypes={getDocumentTypesFor("IsCompanyDocument")}
                                searchFields={{
                                    query: new Field('query', '', [], false, 'search', {
                                        containerClass: 'col-md-4'
                                    }),
                                    DocumentTypeID: new Field('DocumentTypeID', '', [], false, 'select', {
                                        containerClass: 'col-md-4'
                                    }, {
                                        all: true,
                                        values: getDocumentTypesFor("IsCompanyDocument")
                                    }),
                                    DocumentExtension: new Field('DocumentExtension', '', [], false, 'select', {
                                        containerClass: 'col-md-4'
                                    }, {
                                        all: true
                                    }),
                                }}
                                readOnly={!checkPerm(Resources.VendorsDocuments, CREATE_PERM)}
                                disableDelete={!checkPerm(Resources.VendorsDocuments, DELETE_PERM)}
                            />
                        )}

                        {this.state.selectedTab === Resources.VendorsReviews && (
                            <ReviewsTab {...this.props} id={this.getID()}
                                        resourceName={Resources.VendorsReviews}/>
                        )}

                        {this.state.selectedTab === Resources.VendorsContacts && (
                            <ContactsTab
                                {...this.props}
                                id={this.getID()}
                                resourceName={Resources.VendorsContacts}
                                resourceImagePath={Resources.ContactImage}
                                organizationID={this.props.match.params.OrganizationID}
                                primaryKey={'ContactInOrganizationID'}
                                disableMemberOfOrganization
                                hideSystemUser={true}
                                queryFilterFields={{
                                    DepartmentID: new Field('DepartmentID', '', [], false, 'select', {addContainerClass: "col-span-1"}, {isClearable: true}),
                                    limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                                        hideLabel: true,
                                        labelType: "float"
                                    }, {menuPlacement: "top"})
                                }}
                                fields={{
                                    FirstName: new Field('FirstName', '', []),
                                    LastName: new Field('LastName', '', []),
                                    Email: new Field('Email', '', []),
                                    Phone: new Field('Phone', '', []),
                                    Department: new Field('Department', '', []),
                                    Notes: new Field('Notes', '', []),
                                }}
                                onDelete={(item, query) => {
                                    this.props.dispatch(deleteDialogResource({
                                        user: LocalStorage.get('user'),
                                        query: {
                                            ContactInOrganizationID: item.ContactInOrganizationID,
                                            ...query
                                        },
                                        piggyQuery: {
                                            ContactInOrganizationID: item.ContactInOrganizationID,
                                            ...query
                                        },
                                        errorMessage: true,
                                        successMessage: translate(`text.contact_deleted_from_${Resources.VendorsContacts}`, [`${item.FirstName} ${item.LastName}`]),
                                        resource: Resources.VendorsContacts,
                                        piggyResource: Resources.VendorsContacts
                                    }))
                                }}
                            />
                        )}

                        {this.state.selectedTab === Resources.VendorsPayment && (
                            <CompaniesPaymentTab
                                resourceName={Resources.VendorsPayment}
                                OrganizationID={this.props.match.params.VendorID}
                                canSwapTabsHandler={this.canSwapTabsHandler}
                                {...this.props}
                            />
                        )}

                        {this.state.selectedTab === Resources.VendorsExpiry && (
                            <ExpiryItemsTab
                                translate={translate}
                                resourceName={Resources.VendorsExpiry}
                                Reference={infoData.LegalName ?? ''}
                                ReferenceID={this.getID()}
                                ReferenceType={REFERENCE_TYPE_VENDOR}
                            />
                        )}
                    </div>
                </Page>

                <ModalConfirm
                    type="default"
                    title={translate("dialog_heading.unsaved_changes_on_tab")}
                    show={!!this.state.confirmModal && (this.state.selectedTab === Resources.VendorsInfo || this.state.selectedTab === Resources.VendorsSettings || this.state.selectedTab === Resources.VendorsPayment)}
                    onClose={() => this.setState({confirmModal: false})}
                    buttonLabel={translate("btn.proceed")}
                    closeButtonLabel={translate('btn.stay_on_current_tab')}
                    translate={translate}
                    onConfirm={() => this.setState({
                        selectedTab: this.state.reserveTab,
                        confirmModal: false,
                        isDirty: false,
                        tabs: this.state.tabs.map((it) => {
                            it.current = it.resource === this.state.reserveTab
                            return it
                        })
                    })}
                >
                    <p>{translate("text.unsaved_changes_on_tab")}</p>
                    <p>{translate("text.unsaved_changes_on_tab_line_2")}</p>
                </ModalConfirm>

                <ModalDefault
                    show={!!this.state.resourceTaskModalOpen}
                    widthClass={'max-w-3xl'}
                    title={translate('modal_heading.vendor_tasks')}

                    closeButtonLabel={translate('btn.close')}
                    onClose={this.handleToggleResourceTasks}

                    translate={translate}
                >
                    <ResourceTasksDialog
                        close={this.handleToggleResourceTasks}
                        {...this.props}
                        large={true}
                    />
                </ModalDefault>
            </Layout>
        )
    }
}

export default connect(state => state)(VendorView)
