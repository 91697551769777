import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {DEFAULT_CRUD_STATE} from '../../../util/util-constants'
import {getDefaultTableOptions, getProp, resourceIsLoaded} from '../../../common/util/util-helpers'
import Resources from '../../../data/services/resources'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {download} from '../../../data/actions/download'
import {currentDate} from '../../../common/util/util-dates'
import moment from "moment-timezone";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import NoRecords from "../../../common/components/no-records-found/no-records";
import ResourceTable from "../../../common/components/resource-table";
import PayDateFilter from "../carrier-pay/carrier-pay-date-filter";

export default class DriverNetGross extends Component {
    constructor(props) {
        super(props)

        this.tablePageDefaults = {
            behaviour: {
                canAdjustWidth: false,
                hasMenu: false
            },
            style: {
                condensed: false,
                verticalLines: true,
                isGPUAccelerated: true,
                floatingActions: true
            }
        }

        this.pagePath = "driver-net-gross";

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: '',

            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),

            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            data: [],
            isLoading: true
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (resourceIsLoaded(this.props.resource, prevProps.resource) && this.props.resource.resource === this.getResourceName()) {
            this.setState({
                data: getProp(this.props.resource, 'data.list', []),
                isLoading: false
            });
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))

        this.setState({isLoading: true})
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'reports_driver_gross_' + currentDate() + '.xlsx'
            }, this.getQuery())
        }))
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy, filteredData) => {

        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'

        sort = (sort === 'ASC') ? 'DESC' : 'ASC'

        const newData = filteredData.sort((A, B) => {
            let a = A[sortBy];
            let b = B[sortBy];

            if (a === null && b !== null) {
                return sort === "ASC" ? 1 : -1;
            } else if (a !== null && b === null) {
                return sort === "ASC" ? -1 : 1;
            } else {
                return sort === "ASC" ? (a === b ? 0 : (a < b ? -1 : 1)) : (a === b ? 0 : (a > b ? -1 : 1));
            }
        })

        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC',
            data: newData
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.fetchData()
        })
    }

    /** Helpers
     ================================================================= */
    getResourceName = () => {
        return Resources.AccountingReportsDriverNetGross
    }

    getQuery = () => {
        const {
            StartDate,
            EndDate
        } = FieldsManager.getFieldKeyValues(this.state.queryFilterFields)

        return {
            StartDate: StartDate,
            EndDate: EndDate
        }
    }

    getFields = () => {
        return {
            Name: new Field('Name', '', [''], false, 'text', {}),
            AvgGross: new Field('AvgGross', '', [''], false, 'float', {}),
            AvgNet: new Field('AvgNet', '', [''], false, 'float', {}),
            TotalGross: new Field('TotalGross', '', [''], false, 'float', {}),
            TotalNet: new Field('TotalNet', '', [''], false, 'float', {}),
            PaycheckCount: new Field('PaycheckCount', '', [''], false, 'integer', {}),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            StartDate: new Field('StartDate', moment().subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss'), ['empty'], false, 'date', {
                labelType: 'float',
                isRequiredFilter: true
            }),
            EndDate: new Field('EndDate', moment().subtract().format('YYYY-MM-DD HH:mm:ss'), ['empty'], false, 'date', {
                labelType: 'float',
                isRequiredFilter: true
            })
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {
            translate,
        } = this.props

        const filteredData = this.state.data.filter(it => {
            let canPass = true
            if (this.state.queryFilterFields.query.value) {
                canPass &=
                    it.Name?.toLowerCase().includes(this.state.queryFilterFields.query.value?.toLowerCase())
            }

            return canPass
        }).sort((A, B) => {
            let a = A[this.state.sortBy];
            let b = B[this.state.sortBy];

            if (a === null && b !== null) {
                return this.state.sort === "ASC" ? 1 : -1;
            } else if (a !== null && b === null) {
                return this.state.sort === "ASC" ? -1 : 1;
            } else {
                return this.state.sort === "ASC" ? (a === b ? 0 : (a < b ? -1 : 1)) : (a === b ? 0 : (a > b ? -1 : 1));
            }
        })

        return (
            <React.Fragment>
                <div className="flex mb-4">
                    <div className="flex items-end ml-auto">
                        <Tippy content={translate('text.download_excel')}>
                            <button
                                className="btn-icon"
                                onClick={this.downloadExcel}
                            >
                                <DocumentArrowDownIcon className="w-5 h-5"/>
                            </button>
                        </Tippy>
                    </div>
                </div>

                <TableCard>
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        largeGrid
                        forceDialog
                        onRefreshTable={this.fetchData}
                        customHtml={
                            <PayDateFilter
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                                updateQueryFields={(queryFieldsUpdate) => {
                                    this.setState({
                                        queryFilterFields: queryFieldsUpdate
                                    }, () => this.fetchData())
                                }}
                                onQueryChange={this.handleFilterInputChange}
                                hasDateType={false}
                                gridCols={"grid-cols-1"}
                                isClearDatesButtonVisible={false}
                            />
                        }
                    />

                    <ResourceTable
                        data={Array.isArray(filteredData) ? filteredData : []}
                        fields={this.getFields()}

                        translate={translate}
                        isLoading={this.state.isLoading}

                        options={this.state.tableOptions}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={(sortBy) => this.handleUpdateSort(sortBy, filteredData)}
                        maxHeightClass="max-h-[calc(100vh-27rem)]"
                    />

                    {/*Table footer*/}
                    <NoRecords
                        show={(filteredData.length === 0) && !this.state.isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                    <div
                        className='bg-tm-gray-200 px-5 flex items-center justify-between h-2 rounded-card md:rounded-t-none'
                    />
                </TableCard>
            </React.Fragment>
        )
    }
}
