import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getDefaultContactGroup,
    getDefaultUserOfficeValues,
    getDocumentTypesFor,
    getLookup, getPreFillFromQuery,
    getProp,
    openInNewTab
} from "../../../common/util/util-helpers";
import Resources from "../../../data/services/resources";
import WorkOrdersCreateInfoTab from "./work-orders-create-info-tab";
import {Field, FieldsManager} from "../../../data/services/fields";
import {checkPerm, getDefaultQueryFields, getUser, updateQuery} from "../../../util/util";
import {createResource} from "../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import InformationCircleIcon from "@heroicons/react/20/solid/esm/InformationCircleIcon";
import ListBulletIcon from "@heroicons/react/24/outline/ListBulletIcon";
import {ArrowLeftIcon, CheckCircleIcon, PlusIcon} from "@heroicons/react/24/outline";
import {showGlobalModal, showModal} from "../../../data/actions/ui";
import {getSecondResource} from "../../../data/actions/secondResource";
import useQuery from "../../../hooks/use-query";
import Layout from "../../../common/components/layout";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import PageTabWithFooter from "../../../common/components/layout/layout-components/page/page-tab-with-footer";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import NavResponsive from "../../../common/components/nav-responsive";
import NoRecords from "../../../common/components/no-records-found/no-records";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import ModalDefault from "../../../common/components/modal/modal-default";
import ResourceTableTopBar from "../../../common/components/resource-table/table-components/resource-table-top-bar";
import FieldSearch from "../../../common/components/fields/field-text/search";
import ResourceTable from "../../../common/components/resource-table";
import FieldOptions from "../../../common/components/fields/field-options";
import ModalCreateSuccessNew from "../../../common/components/modal/create-success-modal-new";
import {UPDATE_PERM} from "../../../util/util-constants";
import CellLink from "../../../common/components/resource-table/table-components/cell-link";
import WorkOrderStatusBadge from "../../../common/components/badge/work-order-status-badge";
import Tippy from "@tippyjs/react";
import {genericMoneyFormatter, scrollErrorIntoViewFields} from "../../../common/util/util-vanilla";
import {XMarkIcon} from "@heroicons/react/20/solid";
import Card from "../../../common/components/card";
import DocumentsCreateTab from "../../../common/components/tabs/documents-tab/create-documents";
import ImagesCreateTab from "../../../common/components/tabs/documents-tab/create-images";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import {
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DRIVER_STATUS_AVAILABLE,
    DRIVER_STATUS_BOOKED,
    DRIVER_STATUS_ON_LOAD
} from "../../../common/util/util-consts";
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";
import Button from "../../../common/components/button";
import {useLocation} from "react-router-dom";

const CheckboxCell = ({value}) => {
    return value ? <CheckCircleIcon className="w-5 h-5 text-green-600 inline"/>
        : <XMarkIcon className="w-5 h-5 text-tm-gray-400 inline"/>
}

export default function WorkOrdersCreate({history, match, translate}) {
    const [isCustomerCreditOnHold, setIsCustomerCreditOnHold] = useState(false);

    const handleOrganizationQuickView = (it) => {
        dispatch(showModal('ViewCustomerCard', {
            OrganizationID: it.value.value
        }))
    }

    const getBasicFields = (item = {}, ScheduledMaintenanceIDPrefilled) => {
        const prefilled = ScheduledMaintenanceIDPrefilled

        let ScheduledMaintenanceValue;
        let ScheduledMaintenanceType;
        let ScheduledMaintenanceNotes;

        const isValidJSON = str => {
            try {
                JSON.parse(str);
                return true;
            } catch (e) {
                return false;
            }
        };

        if (isValidJSON(prefilled)) {
            ScheduledMaintenanceValue = JSON.parse(prefilled)
            ScheduledMaintenanceType = getLookup('ScheduledMaintenanceType')[ScheduledMaintenanceValue.type]
            ScheduledMaintenanceNotes = ScheduledMaintenanceValue.notes
        }

        const fieldTemplates = {
            WorkOrderID: new Field('WorkOrderID', '', [''], false, 'hidden'),
            // RequestedServiceCategoryID: new Field('RequestedServiceCategoryID', '', ['empty'], false, 'select', {addContainerClass: "col-span-6"}),
            // WorkOrder: new Field('WorkOrder', '', ['empty'], false, "text", {addContainerClass: "col-span-6"}),

            ChargeTo: new Field('ChargeTo', '', [''], false, 'button-group', {
                data: {
                    0: translate('field.ContactID'),
                    1: translate('field.OrganizationID'),
                    2: translate('field.Non-ChargeableRepair')
                },
                hideTable: true,
                addContainerClass: 'col-span-12'
            }),
            IsInHouseRepair: new Field('IsInHouseRepair', 0, [], false, "hidden"),
            ChargeContactID: new Field('ChargeContactID', '', ['empty'], false, 'select-search', {
                addContainerClass: 'col-span-6',
                hideTable: true,
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => dispatch(showModal('ViewContactCard', {ContactID: it?.value.value})),
                                    isVisible: !!it.value
                                }
                            ]}
                        />
                    )
                }
            }),
            ChargeOrganizationID: new Field('ChargeOrganizationID', '', [''], true, 'select-search', {
                addContainerClass: 'col-span-6',
                hideTable: true,
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => handleOrganizationQuickView(it),
                                    isVisible: !!it.value
                                }
                            ]}
                        />
                    )
                }
            }),
            CustomerReferenceNumber: new Field('CustomerReferenceNumber', '', [''], false, 'text', {addContainerClass: "col-span-6"}),
            OfficeID: new Field('OfficeID', getDefaultUserOfficeValues(), ['empty'], false, 'select-search', {addContainerClass: "col-span-6"}),
            ContactGroupID: new Field('ContactGroupID', getDefaultContactGroup(), ['empty'], false, 'select-search', {addContainerClass: "col-span-6"}),
            Notes: new Field('Notes', '', [''], false, 'textarea', {
                label: 'internal_notes',
                addContainerClass: "col-span-12",
                htmlAfter: () => <InfoBar type="info" addClass="col-span-full">
                    {translate("text.internal_notes_message")}
                </InfoBar>
            }),
            ExternalNotesCustomer: new Field('ExternalNotesCustomer', '', [''], false, 'textarea', {
                label: "customer_notes",
                addContainerClass: "col-span-12",
                htmlAfter: () => <InfoBar type="warning" addClass="col-span-full font-bold">
                    {translate("text.external_customer_notes_message")}
                </InfoBar>
            }),
            ScheduledMaintenance: new Field('ScheduledMaintenance', ScheduledMaintenanceValue ? 'true' : '', [''], false, 'checkbox',{addContainerClass:"col-span-6"}),
            ScheduledMaintenanceID: new Field('ScheduledMaintenanceID', ScheduledMaintenanceValue ? {value: ScheduledMaintenanceValue.value, label: ScheduledMaintenanceType + " " + ScheduledMaintenanceNotes} : '', [''], false, (item?.ScheduledMaintenanceID || ScheduledMaintenanceValue) ? 'select-search' : 'hidden',{addContainerClass:"col-span-6"}),
        }

        return fieldTemplates
    }

    const getAssignedFields = (item = {}, translate, handleVendorInfoClick) => {
        const fieldTemplates = {
            IsInHouseRepair: new Field('IsInHouseRepair', 1, [''], false, 'button-group', {
                data: {1: translate('btn.internal_shop'), 0: translate('btn.third_party_shop')},
                addContainerClass: 'col-span-full',
                hideLabel: true
            }),
            AssignedVendorID: new Field(
                'AssignedVendorID',
                '',
                [''],
                true,
                'select-search',
                {
                    addContainerClass: "col-start-1 col-span-6",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[{
                                    icon: InformationCircleIcon,
                                    onClick: () => handleVendorInfoClick(it?.value?.metadata),
                                    isVisible: !!it?.value
                                }]}
                            />
                        )
                    }
                },
                {isClearable: true}
            )
        }

        let filledFields = fillFieldsFromData(fieldTemplates, item);

        if (!!filledFields.AssignedVendorID.value) {
            filledFields.AssignedVendorID.value.metadata = item.AssignedVendorInfo;
        }

        return filledFields;
    }

    const getAssetFields = (item = {}, translate, handleTruckInfoClick, handleTrailerInfoClick, handleTruckWoClick, handleTrailerWoClick, toggleTruckSelectDialog, toggleTrailerSelectDialog, prefilled) => {

        let ScheduledMaintenanceValue;
        let ScheduledMaintenanceTruckID;
        let ScheduledMaintenanceTrailerID;
        let ScheduledMaintenanceTruck;
        let ScheduledMaintenanceTrailer;
        let TruckMileage;
        let TrailerHours;

        const isValidJSON = str => {
            try {
                JSON.parse(str);
                return true;
            } catch (e) {
                return false;
            }
        };

        if (isValidJSON(prefilled)) {
            ScheduledMaintenanceValue = JSON.parse(prefilled)
            ScheduledMaintenanceTruckID = ScheduledMaintenanceValue.TruckID
            ScheduledMaintenanceTruck = ScheduledMaintenanceValue.Truck
            ScheduledMaintenanceTrailerID = ScheduledMaintenanceValue.TrailerID
            ScheduledMaintenanceTrailer = ScheduledMaintenanceValue.Trailer
            TruckMileage = ScheduledMaintenanceValue.Mileage;
            TrailerHours = ScheduledMaintenanceValue.Hours;
        }


        const fieldTemplates = {
            TruckID: new Field(
                'TruckID',
                ScheduledMaintenanceTruckID ? {value: ScheduledMaintenanceTruckID, label: ScheduledMaintenanceTruck} : '',
                (!!item.TrailerID || ScheduledMaintenanceTrailerID) ? [''] : ['empty'],
                false,
                'select-search',
                {
                    addContainerClass: "col-span-6 max-w-80",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[
                                    {
                                        icon: ListBulletIcon,
                                        onClick: () => handleTruckWoClick(it?.value?.metadata),
                                        isVisible: !!it?.value?.metadata
                                    },
                                    {
                                        icon: InformationCircleIcon,
                                        onClick: () => handleTruckInfoClick(it?.value?.value),
                                        isVisible: !!it?.value
                                    }
                                ]}
                            />
                        )
                    },
                    htmlAfterField: (it) => {
                        return <button
                            onClick={() => toggleTruckSelectDialog()}
                            className="btn btn-input"
                        >
                            <PlusIcon/>
                        </button>
                    },
                    htmlAfter: (it) => {
                        return (<div className="col-start-1 2xl:col-span-6 col-span-full row-start-2 gap-4">
                            <div className="flex gap-2 px-px text-tm-gray-900">
                                <div className="w-full">
                                    <div className="px-3 group h-4">
                                        <Button
                                            onClick={() => openInNewTab("/truck/create")}
                                            className="btn btn-outline h-5"
                                        >
                                            Create truck
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    }
                },
                {
                    isClearable: true
                }
            ),
            TrailerID: new Field(
                'TrailerID',
                ScheduledMaintenanceTrailerID ? {value: ScheduledMaintenanceTrailerID, label: ScheduledMaintenanceTrailer} : '',
                (!!item.TruckID || ScheduledMaintenanceTruckID) ? [''] : ['empty'],
                false,
                'select-search',
                {
                    addContainerClass: "col-span-6 max-w-80",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[
                                    {
                                        icon: ListBulletIcon,
                                        onClick: () => handleTrailerWoClick(it?.value?.metadata),
                                        isVisible: !!it?.value?.metadata
                                    },
                                    {
                                        icon: InformationCircleIcon,
                                        onClick: () => handleTrailerInfoClick(it?.value?.value),
                                        isVisible: !!it?.value
                                    }]}
                            />
                        )
                    },
                    htmlAfterField: (it) => {
                        return <button
                            onClick={() => toggleTrailerSelectDialog()}
                            className="btn btn-input"
                        >
                            <PlusIcon/>
                        </button>
                    },
                    htmlAfter: (it) => {
                        return (<div className="col-start-7 2xl:col-span-6 col-span-full gap-4 row-start-2">
                            <div className="flex gap-2 px-px text-tm-gray-900">
                                <div className="w-full">
                                    <div className="px-3 group h-4">
                                        <Button
                                            onClick={() => openInNewTab("/trailer/create")}
                                            className="btn btn-outline h-5"
                                        >
                                            Create trailer
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    }
                },
                {isClearable: true}
            ),
            CurrentOdometerValue: new Field('CurrentOdometerValue', TruckMileage ?? '0', [''], false, ScheduledMaintenanceTruckID ? 'integer' : 'hidden', {addContainerClass: "col-span-6 col-start-1"}),
            CurrentHoursValue: new Field('CurrentHoursValue', TrailerHours ?? '0', [''], false, ScheduledMaintenanceTrailerID ? 'integer' : 'hidden', {addContainerClass: "col-span-6 col-start-7"})        }


        return fieldTemplates;
    }

    function getFields(item = {}) {

        const fieldTemplates = {
            AutoCounter: new Field('AutoCounter', '', [''], false, 'custom', {
                render: (it) => {
                    return (
                        <CellLink
                            openInNewTab={true}
                            to={checkPerm(Resources.WorkOrders, UPDATE_PERM) && "/work-orders/" + it.WorkOrderID}
                            disabled={!checkPerm(Resources.WorkOrders, UPDATE_PERM)}
                        >
                            {it.AutoCounter}
                        </CellLink>
                    )
                }
            }),
            WorkOrderStatusID: new Field('WorkOrderStatusID', '', [''], false, 'select', {
                render: (it) => <WorkOrderStatusBadge
                    workOrderStatuses={getLookup("WorkOrderStatus") ?? {}}
                    statusID={it.WorkOrderStatusID}
                />
            }),
            ChargeTo: new Field('ChargeTo', '', [''], false, 'custom', {
                omitSort: true,
                label: "ChargeTo",
                render: (item) => {
                    if (item.ChargeTo == 2) {
                        return ("Non-Chargeable")
                    }
                    if (item.ChargeContactID) {
                        return item.ChargeContact;
                    }
                    if (item.ChargeOrganizationID) {
                        return item.ChargeOrganization;
                    }
                }
            }),
            TotalAmount: new Field('TotalAmount', '', [''], false, "float", {
                render: (it) => (
                    <Tippy
                        content={
                            <div className={"text-right"}>
                                <div>Labor: {genericMoneyFormatter(it.LaborAmount)}</div>
                                <div>Parts: {genericMoneyFormatter(it.PartsAmount)}</div>
                                <div>Shop supplies: {genericMoneyFormatter(it.MiscAmount)}</div>
                                <div>Misc. surcharge: {genericMoneyFormatter(it.SurchargeAmount)}</div>
                            </div>
                        }
                    >
                        <div className={"text-right"}>
                            {genericMoneyFormatter(it.TotalAmount)}
                        </div>
                    </Tippy>
                ),
                colFilter: true
            }, {icon: false}),
            IsInvoiced: new Field('IsInvoiced', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsInvoiced}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
            IsAuthorized: new Field('IsAuthorized', '', [''], false, 'select', {
                render: (it) => <CheckboxCell value={it.IsAuthorized}/>,
                colFilter: true
            }, {addClass: "min-w-[9rem] form-control", hasPortal: true}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    const getComplaintFields = (item = {}, translate) => {
        const fieldTemplates = {
            InitialComplaint: new Field('InitialComplaint', '', [''], false, 'textarea', {addContainerClass: "col-span-full"})
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    const getPrimaryField = () => "WorkOrderID";

    const getQueryFields = (translate) => {
        return getDefaultQueryFields(getPrimaryField(), translate);
    }

    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const dialogResource = useSelector((state) => state.dialogResource);
    const document = useSelector((state) => state.document);
    const download = useSelector((state) => state.download);

    const isLoading = getProp(resource, "isLoading", false);
    const documentIsLoading = getProp(document, "isLoading", false);
    const downloadIsLoading = getProp(download, "isLoading", false);
    const data = getProp(resource, "data", {});
    const [dataImages, setDataImages] = useState([]);
    const [dataDocuments, setDataDocuments] = useState([]);
    const [isStateDirty, setIsStateDirty] = useState(false);
    const [tabs, setTabs] = useState(getTabs());
    const currentTab = tabs.find(it => it.current);
    const [fieldsBasic, setFieldsBasic] = useState(getBasicFields({}, translate));
    const [fieldsAssigned, setFieldsAssigned] = useState(getAssignedFields({}, translate));
    const [fieldsAssets, setFieldsAssets] = useState(getAssetFields({}, translate));
    const [fieldsComplaint, setFieldsComplaint] = useState(getComplaintFields({}, translate));
    // const [fieldsCustom, setFieldsCustom] = useState(getCustomFields({}, translate));
    const [showTruckWoDialog, setShowTruckWoDialog] = useState(false);
    const [showTrailerWoDialog, setShowTrailerWoDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [prefilled, setPrefilled] = useState(getPreFillFromQuery());
    let isItemCreatedRef = useRef(false);

    const secondResource = useSelector((state) => state.secondResource)

    const secondData = getProp(secondResource, 'data.list', [])
    const secondCount = getProp(secondResource, 'data.count', 0)
    const secondIsLoading = getProp(secondResource, 'isLoading', false)
    const [fields, setFields] = useState(getFields({}));
    const layoutRef = useRef();
    const [isLayoutScrolled, setIsLayoutScrolled] = useState(false)

    const [isTruckSelectDialogOpen, setIsTruckSelectDialogOpen] = useState(false);
    const [isTrailerSelectDialogOpen, setIsTrailerSelectDialogOpen] = useState(false);

    const contactTableOptions = {
        style: {
            stripedRows: true,
            horizontalLines: false,
            floatingActions: false,
        },
    }
    /** Constants
     ================================================================= */
    const getResourcePath = () => Resources.WorkOrders;
    const getListPath = () => getResourcePath() + "_wo"
    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), getListPath())

    const fetchSecondData = (item, query, type) => {
        dispatch(getSecondResource({
            user: getUser(),
            query: Object.assign({
                searchFields: JSON.stringify({
                    [type]: item,
                })
            }, query)
            ,
            resource: Resources.WorkOrders,
        }))
    }

    const handleQueryChange = (name, value) => {
        let queryUpdate = updateQuery(queryFields, name, value);
        if (name === 'query') {
            queryUpdate.offset.value = "0"
        }

        setQueryFields(queryUpdate);
        fetchSecondData(selectedItem.TruckID ?? selectedItem.TrailerID, FieldsManager.getFieldKeyValues(queryUpdate), selectedItem.TruckID ? 'TruckID' : 'TrailerID');
    }
    const handleVendorInfoClick = (item) => {
        dispatch(showModal('ViewVendorCard', item?.value?.metadata))
    }

    const handleContactInfoClick = (item) => {
        dispatch(showModal('ViewContactCard', item?.value?.metadata))
    }

    const handleTruckInfoClick = (truckID) => {
        dispatch(showGlobalModal("ViewTruckCard", truckID))
    }

    const handleTrailerInfoClick = (trailerID) => {
        dispatch(showGlobalModal("ViewTrailerCard", trailerID))
    }

    const handleTruckWoClick = (item) => {
        setShowTruckWoDialog(!showTruckWoDialog);
        setSelectedItem(item);
        fetchSecondData(item?.TruckID, FieldsManager.getFieldKeyValues(queryFields), 'TruckID');
        setQueryFields(getQueryFields(translate));
    }
    const handleTrailerWoClick = (item) => {
        setShowTrailerWoDialog(!showTrailerWoDialog);
        setSelectedItem(item);
        fetchSecondData(item?.TrailerID, FieldsManager.getFieldKeyValues(queryFields), 'TrailerID');
        setQueryFields(getQueryFields(translate));
    }

    const handleLayoutScroll = () => {
        if (layoutRef?.current.scrollTop > 100 && !isLayoutScrolled) {
            setIsLayoutScrolled(true)
        }

        if (layoutRef?.current.scrollTop <= 100 && isLayoutScrolled) {
            setIsLayoutScrolled(false)
        }
    }

    const handleScrollLayoutOnTop = () => {
        if (layoutRef?.current) {
            layoutRef.current.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    const getTruckFields = () => ({
        TruckNumber: new Field('TruckNumber', '', [''], false, "text",),
        Manufacturer: new Field('Manufacturer', '', [''], false, "text",),
        Model: new Field('Model', '', [''], false, "text",),
        Year: new Field('Year', '', [''], false, "text", {label: "model_year"}),
        Engine: new Field('Engine', '', [''], false, "text"),
        VIN: new Field('VIN', '', [''], false, "text"),
        OwnedBy: new Field('OwnedBy', '', [''], false, "custom", {
            omitSort: true,
            render: (it) => {
                return (
                    <div>
                        {it.OwnedByOrganization ?? it.OwnedByContact}
                    </div>
                )
            }
        })
    });

    const getTrailerFields = () => ({
        TrailerNumber: new Field('TrailerNumber', '', [''], false, "text",),
        Manufacturer: new Field('Manufacturer', '', [''], false, "text",),
        Model: new Field('Model', '', [''], false, "text",),
        Year: new Field('Year', '', [''], false, "text", {label: "model_year"}),
        Engine: new Field('Engine', '', [''], false, "text"),
        VIN: new Field('VIN', '', [''], false, "text"),
        OwnedBy: new Field('OwnedBy', '', [''], false, "custom", {
            omitSort: true,
            render: (it) => {
                return (
                    <div>
                        {it.OwnedByOrganization ?? it.OwnedByContact}
                    </div>
                )
            }
        })
    });

    const getAssetFilters = () => (Object.assign({
            query: new Field('query', '', [''], false, 'search', {
                addContainerClass: 'col-span-3',
                labelType: "float"
            }),
            StatusID: new Field('StatusID', '', [''], false, 'select', {
                addContainerClass: 'col-span-3',
                labelType: "float"
            }, {isClearable: true}),
        },
        getUser("Office.CountryName") === "USA" ? {
            StateID: new Field('StateID', '', [''], false, 'select', {
                addContainerClass: 'col-span-3',
                labelType: "float"
            }, {isClearable: true})
        } : {}
    ));

    const toggleTruckSelectDialog = () => {
        setIsTruckSelectDialogOpen(!isTruckSelectDialogOpen)
    }

    const toggleTrailerSelectDialog = () => {
        setIsTrailerSelectDialogOpen(!isTrailerSelectDialogOpen)
    }

    const getAssetSelects = () => ({
        StatusID: {
            [DRIVER_STATUS_AVAILABLE]: "Available",
            [DRIVER_STATUS_BOOKED]: "Booked",
            [DRIVER_STATUS_ON_LOAD]: "On Load"
        },
        CountryID: getLookup('Country', 'CountryID', 'CountryName'),
        StateID: getLookup('State', 'StateID', 'State'),
        OwnedByOrganizationID: {
            api: 'api/' + Resources.OrganizationsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.OrganizationID,
                label: item.LegalName
            })
        },
        OwnedByContactID: {
            api: 'api/' + Resources.ContactsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.ContactID,
                label: item.FirstName + " " + item.LastName
            })
        },
    })

    const handleInputChange = (name, value) => {
        return FieldsManager.updateField(fieldsAssets, name, value)
    }

    /** UI Events
     ================================================================= */
    const handleTabChange = (selectedTab) => {
        const tabsUpdate = tabs.map(tab => {
            tab.current = tab.resource === selectedTab;
            return tab;
        });

        setTabs(tabsUpdate);
    }

    const handleBasicInputChange = (name, value) => {
        let fieldsBasicUpdate = Object.assign({}, fieldsBasic);
        if (name === 'ChargeTo') {
            fieldsBasicUpdate.ChargeContactID.validate = [value == "0" ? 'empty' : '']
            fieldsBasicUpdate.ChargeOrganizationID.validate = [value == "1" ? 'empty' : '']

            fieldsBasicUpdate.ChargeContactID.disabled = value == "0" ? false : true
            fieldsBasicUpdate.ChargeOrganizationID.disabled = value == "1" ? false : true
            fieldsBasicUpdate.ChargeContactID.value = ''
            fieldsBasicUpdate.ChargeOrganizationID.value = ''

            fieldsBasicUpdate.IsInHouseRepair.value = value === 2 && 1;

            setIsCustomerCreditOnHold(false);
        }

        if (name === 'ScheduledMaintenance') {
            fieldsBasicUpdate.ScheduledMaintenanceID.validate = [value == "1" ? 'empty' : '']
            fieldsBasicUpdate.ScheduledMaintenanceID.type  = value == "1" ? 'select-search' : 'hidden'
        }

        fieldsBasicUpdate = FieldsManager.updateField(fieldsBasicUpdate, name, value);
        fieldsBasicUpdate[name].errorMessage = "";

        if (name === 'ChargeOrganizationID') {
            setIsCustomerCreditOnHold(!!value?.metadata?.CreditHold);
            if (!!value?.metadata?.CreditHold) {
                fieldsBasicUpdate[name].errorMessage = ["Customer has credit on hold."];
            }
        }

        setFieldsBasic(fieldsBasicUpdate);
        setIsStateDirty(true);


    }

    const handleAssignedChange = (name, value) => {
        let fieldsAssignedUpdate = Object.assign({}, fieldsAssigned);

        fieldsAssignedUpdate = FieldsManager.updateField(fieldsAssignedUpdate, name, value);

        if ("IsInHouseRepair" === name) {
            fieldsAssignedUpdate.AssignedVendorID.disabled = value === 1;
            fieldsAssignedUpdate.AssignedVendorID.value = value === 1 && "";
            fieldsAssignedUpdate.AssignedVendorID.validate = value ? [''] : ['empty']
        }

        setFieldsAssigned(fieldsAssignedUpdate);
        setIsStateDirty(true);
    }

    const handleAssetsChange = (name, value) => {
        let fieldsAssetsUpdate = Object.assign({}, fieldsAssets);

        fieldsAssetsUpdate = FieldsManager.updateField(fieldsAssetsUpdate, name, value);

        fieldsAssetsUpdate.TruckID.validate = !!fieldsAssetsUpdate.TrailerID?.value?.value ? [''] : ['empty']
        fieldsAssetsUpdate.TrailerID.validate = !!fieldsAssetsUpdate.TruckID?.value?.value ? [''] : ['empty']

        fieldsAssetsUpdate.CurrentOdometerValue.type = !!fieldsAssetsUpdate.TruckID?.value ? 'integer' : 'hidden'
        fieldsAssetsUpdate.CurrentHoursValue.type = !!fieldsAssetsUpdate.TrailerID?.value ? 'integer' : 'hidden'

        if (!!value && (name === 'TruckID')) {
            fieldsAssetsUpdate.CurrentOdometerValue.value = Math.trunc(value?.metadata?.Mileage ?? 0)
        }

        if (!!value && (name === 'TrailerID')) {
            fieldsAssetsUpdate.CurrentHoursValue.value = Math.trunc(value?.metadata?.Mileage ?? 0)
        }

        fieldsAssetsUpdate[name]["errorMessage"] = "";

        setFieldsAssets(fieldsAssetsUpdate);
        setIsStateDirty(true);
    }

    const handleComplaintChange = (name, value) => {
        let fieldsComplaintUpdate = Object.assign({}, fieldsComplaint);

        fieldsComplaintUpdate = FieldsManager.updateField(fieldsComplaintUpdate, name, value);

        setFieldsComplaint(fieldsComplaintUpdate);
        setIsStateDirty(true);
    }

    const handleUploadDocument = (files) => {
        let fields = dataDocuments
        fields = fields.concat(files)
        setDataDocuments(fields)
    }

    const handleDeleteDocument = (item) => {
        let fields = dataDocuments.filter(it => it.preview !== item.preview)
        setDataDocuments(fields)
    }

    const handleUploadImage = (files) => {
        let fields = dataImages
        fields = fields.concat(files)
        setDataImages(fields)
    }

    const handleDeleteImage = (item) => {
        let fields = dataImages.filter(it => it.preview !== item.preview)
        setDataImages(fields)
    }

    const onSubmitClick = () => {
        const fieldsBasicValidate = FieldsManager.validateFields(fieldsBasic);
        const fieldsAssignedValidate = FieldsManager.validateFields(fieldsAssigned);
        const fieldsAssetsValidate = FieldsManager.validateFields(fieldsAssets);
        const fieldsComplaintValidate = FieldsManager.validateFields(fieldsComplaint);

        if (
            FieldsManager.checkFieldsForErrors(fieldsBasicValidate) &&
            FieldsManager.checkFieldsForErrors(fieldsAssignedValidate) &&
            FieldsManager.checkFieldsForErrors(fieldsAssetsValidate) &&
            FieldsManager.checkFieldsForErrors(fieldsComplaintValidate)
        ) {
            let params = Object.assign({},
                FieldsManager.getFieldKeyValues(fieldsBasicValidate),
                FieldsManager.getFieldKeyValues(fieldsAssignedValidate),
                FieldsManager.getFieldKeyValues(fieldsAssetsValidate),
                FieldsManager.getFieldKeyValues(fieldsComplaintValidate)
            )

            delete params.WorkOrderID
            let imageParams = {}
            imageParams = dataImages.map((it, index) => imageParams[index] = it.params)

            if (dataImages.length === 1) {
                imageParams = imageParams[0]
            }

            if (params.VMRSCode?.value) {
                params.VMRSCode = params.VMRSCode.value;
            }

            dispatch(createResource({
                user: LocalStorage.get('user'),
                params: params,
                query: {},
                fileParams: imageParams,
                resource: Resources.WorkOrders,
                piggyResource: Resources.WorkOrders,
                customID: 'WorkOrderID',
                file: dataImages,
                fileResource: Resources.WorkOrdersImages,
                document: dataDocuments,
                documentResource: Resources.WorkOrdersDocuments
            }));
            isItemCreatedRef.current = true;

        } else {
            scrollErrorIntoViewFields([fieldsBasicValidate])
            scrollErrorIntoViewFields([fieldsAssetsValidate])
            scrollErrorIntoViewFields([fieldsAssignedValidate])
            scrollErrorIntoViewFields([fieldsComplaintValidate])
            setFieldsBasic(fieldsBasicValidate)
            setFieldsAssigned(fieldsAssignedValidate)
            setFieldsAssets(fieldsAssetsValidate)
            setFieldsComplaint(fieldsComplaintValidate)
        }
    }

    function getTabs() {
        return [
            {
                name: 'WorkOrdersInfo',
                resource: Resources.WorkOrder,
                current: true,
                visible: true
            },
            {
                name: 'Images',
                resource: Resources.WorkOrdersImages,
                current: false,
                visible: true
            },
            {
                name: 'WorkOrdersDocument',
                resource: Resources.WorkOrdersDocuments,
                current: false,
                visible: true
            }
        ]
    }

    useEffect(() => {
        if (!isLoading) {
            setPrefilled(getPreFillFromQuery())
            setFieldsBasic(getBasicFields({}, prefilled));
            setFieldsAssigned(getAssignedFields({}, translate, handleVendorInfoClick, handleContactInfoClick));
            // setFieldsCustom(getCustomFields({}, translate));
            setFieldsAssets(getAssetFields({}, translate, handleTruckInfoClick, handleTrailerInfoClick, handleTruckWoClick, handleTrailerWoClick, toggleTruckSelectDialog, toggleTrailerSelectDialog, prefilled));
            setFieldsComplaint(getComplaintFields({}, translate));
            setIsStateDirty(false);
            setDataImages([]);
            setDataDocuments([]);
        }
    }, [isLoading])

    /** Page Body
     ================================================================= */
    return (
        <Layout
            history={history}
            match={match}
            translate={translate}
            layoutRef={layoutRef}
            onScroll={handleLayoutScroll}
        >

            <PageTabWithFooter
                isLoading={isLoading}
                buttons={
                    [
                        {
                            className: "btn btn-primary",
                            onClick: () => onSubmitClick(),
                            disabled: !isStateDirty || isLoading || isCustomerCreditOnHold,
                            label: translate("btn.save")
                        },
                        {
                            className: "btn btn-outline",
                            disabled: !isStateDirty,
                            onClick: () => {
                                setFieldsBasic(getBasicFields({}, translate));
                                setFieldsAssigned(getAssignedFields({}, translate));
                                setFieldsAssets(getAssetFields({}, translate));
                                setIsStateDirty(false)
                            },
                            label: translate("btn.cancel")
                        },
                    ]
                }
            >
                <div>
                    <div className={"sticky"}>
                        <PageHeader
                            title={translate("page_title.work-order-create")}
                            removeBorder={true}
                            ButtonsLeading={[
                                {
                                    iconLeading: ArrowLeftIcon,
                                    className: "btn btn-header m-2",
                                    onClick: () => history.push('/work-orders/'),
                                    title: "Go back to work orders list"
                                }
                            ]}
                        />

                        <NavResponsive
                            addClass="px-4 sm:px-6 md:px-8 h-10"
                            tabs={tabs}
                            onTabChange={handleTabChange}
                            translate={translate}
                        />
                    </div>

                    {currentTab.name === "WorkOrdersInfo" && (
                        <WorkOrdersCreateInfoTab
                            isCreate={true}
                            isLoading={isLoading}
                            getResourcePath={currentTab.resource}
                            translate={translate}
                            history={history}
                            handleBasicInputChange={handleBasicInputChange}
                            fieldsBasic={fieldsBasic}
                            fieldsComplaint={fieldsComplaint}
                            handleComplaintChange={handleComplaintChange}
                            handleAssignedChange={handleAssignedChange}
                            fieldsAssigned={fieldsAssigned}
                            handleAssetsChange={handleAssetsChange}
                            fieldsAssets={fieldsAssets}
                            layoutRef={layoutRef}
                            isLayoutScrolled={isLayoutScrolled}
                            scrollToTop={handleScrollLayoutOnTop}
                            isCustomerCreditOnHold={isCustomerCreditOnHold}
                        />
                    )}

                    {currentTab.name === "Images" && (
                        <Card>
                            <ImagesCreateTab
                                fields={dataImages}
                                translate={translate}

                                dialogTitle={'Upload images'}

                                handleUploadDocument={handleUploadImage}
                                handleDeleteDocument={handleDeleteImage}
                            />
                        </Card>
                    )}

                    {currentTab.name === "WorkOrdersDocument" && (
                        <Card>
                            <DocumentsCreateTab
                                fields={dataDocuments}
                                translate={translate}

                                dialogTitle={'Work order documents'}

                                documentTypes={getDocumentTypesFor("IsWorkOrderDocument")}
                                handleUploadDocument={handleUploadDocument}
                                handleDeleteDocument={handleDeleteDocument}
                            />
                        </Card>
                    )}

                    <ModalCreateSuccessNew
                        resource={resource}
                        position={"center"}
                        successText={(createdResourceAutoCounter) => "Work order #" + createdResourceAutoCounter + " created successfully!"}
                        dispatch={dispatch}
                        isItemCreatedRef={isItemCreatedRef}

                        buttons={(createdResourceID, closeSuccessDialog, createdResourceAutoCounter) => [
                            {
                                label: translate("btn.to_labor_and_parts", [createdResourceAutoCounter]),
                                onClick: () => history.push("/work-orders/" + createdResourceID + '?parts'),
                                autoFocus: true,
                                className: "btn btn-outline pr-3 text-link font-bold bg-inverse focus:bg-primary focus:text-inverse focus:border-primary focus:border-2 focus:border-solid"
                            },
                            {
                                label: translate("btn.create_new_work_order"),
                                onClick: closeSuccessDialog
                            },
                            {
                                label: translate("btn.edit_work_order", [createdResourceAutoCounter]),
                                onClick: () => history.push("/work-orders/" + createdResourceID)
                            },
                            {
                                label: translate("btn.go_back_to_work_orders_list"),
                                onClick: () => history.push("/work-orders/")
                            }
                        ]}
                        translate={translate}
                    />

                    <ModalDefault
                        title={translate("title.work_orders_for_truck", [selectedItem?.Truck])}
                        show={showTruckWoDialog}
                        onClose={handleTruckWoClick}
                        translate={translate}
                        widthClass={"max-w-6xl"}
                    >
                        <div className="max-h-[calc(100vh-14rem)] overflow-auto">
                            <ResourceTableTopBar
                                addClass="rounded-t-md ring-1 ring-black ring-opacity-5 px-4"
                            >
                                <FieldSearch
                                    addClass="form-control"
                                    name="query"
                                    value={queryFields?.query?.value}
                                    onChange={handleQueryChange}
                                    translate={translate}
                                />

                            </ResourceTableTopBar>

                            {(secondIsLoading || !!secondCount) && (
                                <ResourceTable
                                    data={secondData}
                                    fields={fields}
                                    queryFields={queryFields}

                                    options={{
                                        style: {
                                            stripedRows: true,
                                            horizontalLines: true,
                                            verticalLines: true,
                                            floatingActions: true
                                        }
                                    }}

                                    translate={translate}
                                    isLoading={secondIsLoading}
                                />

                            )}

                            {!secondIsLoading && !secondCount && (
                                <NoRecords
                                    addClass="p-10"
                                    title={translate("text.no_work_orders_for_truck")}
                                />
                            )}
                        </div>
                        <TableCardFooter
                            show={!(!secondData.length && !secondIsLoading)}
                        >
                            <Pagination
                                count={secondCount}
                                isLoading={secondIsLoading}
                                handleQueryChange={handleQueryChange}
                                queryFields={queryFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                    </ModalDefault>

                    <ModalDefault
                        title={translate("title.work_orders_for_trailer", [selectedItem?.Trailer])}
                        show={showTrailerWoDialog}
                        onClose={handleTrailerWoClick}
                        translate={translate}
                        widthClass={"max-w-6xl"}
                    >
                        <ResourceTableTopBar
                            addClass="rounded-t-md ring-1 ring-black ring-opacity-5"
                        >
                            <FieldSearch
                                addClass="form-control"
                                name="query"
                                value={queryFields?.query?.value}
                                onChange={handleQueryChange}
                                translate={translate}
                            />

                        </ResourceTableTopBar>

                        {(secondIsLoading || !!secondCount) && (
                            <ResourceTable
                                data={secondData}
                                fields={fields}
                                queryFields={queryFields}

                                options={{
                                    style: {
                                        stripedRows: true,
                                        horizontalLines: true,
                                        verticalLines: true,
                                        floatingActions: true
                                    }
                                }}

                                translate={translate}
                                isLoading={secondIsLoading}
                            />
                        )}

                        {!secondIsLoading && !secondCount && (
                            <NoRecords
                                addClass="p-10"
                                title={translate("text.no_work_orders_for_trailer")}
                            />
                        )}

                        <TableCardFooter
                            show={!(!secondData.length && !secondIsLoading)}
                        >
                            <Pagination
                                count={secondCount}
                                isLoading={secondIsLoading}
                                handleQueryChange={handleQueryChange}
                                queryFields={queryFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                    </ModalDefault>
                </div>

                <ResourceTableDialog
                    show={isTruckSelectDialogOpen}
                    dialogResource={dialogResource}
                    resource={Resources.TrucksQuick}
                    title={translate("text.select_truck")}
                    dispatch={dispatch}
                    fields={getTruckFields()}
                    widthClass={"max-w-7xl"}
                    options={contactTableOptions}
                    fieldsFilter={
                        Object.assign(getAssetFilters(),
                            {NotSoldRetired: new Field('NotSoldRetired', '1', [''], false, "hidden")}
                        )}

                    searchFields={
                        {
                            OwnedByContactID: new Field('OwnedByContactID', '', [''], false, 'select-search', {
                                addContainerClass: 'col-span-3',
                                labelType: "float"
                            }, {
                                isClearable: true
                            }),
                            OwnedByOrganizationID: new Field('OwnedByOrganizationID', '', [''], false, 'select-search', {
                                addContainerClass: 'col-span-3',
                                labelType: "float"
                            }, {
                                isClearable: true
                            }),
                        }
                    }
                    sortBy={"TruckNumber"}
                    defaultAction={(item) => dispatch(showGlobalModal('ViewTruckCard', item.TruckID))}
                    onCustomActions={[
                        {
                            action: (item) => {
                                handleAssetsChange("TruckID", {
                                    value: item.TruckID,
                                    label: item.TruckNumber + " - " + item.Manufacturer + ", " + item.Model + ", " + item.Year + " (VIN:" + item.VIN + ")",
                                    metadata: item
                                })
                                toggleTruckSelectDialog();
                            },
                            icon: PlusCircleIcon,
                            visible: () => true
                        }
                    ]}
                    selects={getAssetSelects()}
                    onClose={toggleTruckSelectDialog}
                    translate={translate}
                />

                <ResourceTableDialog
                    show={isTrailerSelectDialogOpen}
                    dialogResource={dialogResource}
                    resource={Resources.TrailersQuick}
                    title={translate("text.select_trailer")}
                    dispatch={dispatch}
                    fields={getTrailerFields()}
                    widthClass={"max-w-7xl"}
                    options={contactTableOptions}
                    fieldsFilter={
                        Object.assign(getAssetFilters(),
                            {NotSoldRetired: new Field('NotSoldRetired', '1', [''], false, "hidden")}
                        )}
                    searchFields={
                        {
                            OwnedByContactID: new Field('OwnedByContactID', '', [''], false, 'select-search', {
                                addContainerClass: 'col-span-3',
                                labelType: "float"
                            }, {
                                isClearable: true
                            }),
                            OwnedByOrganizationID: new Field('OwnedByOrganizationID', '', [''], false, 'select-search', {
                                addContainerClass: 'col-span-3',
                                labelType: "float"
                            }, {
                                isClearable: true
                            }),
                        }
                    }

                    sortBy={"TrailerNumber"}
                    defaultAction={(item) => dispatch(showGlobalModal('ViewTrailerCard', item.TrailerID))}
                    onCustomActions={[
                        {
                            action: (item) => {
                                handleAssetsChange("TrailerID", {
                                    value: item.TrailerID,
                                    label: item.TrailerNumber + " - " + item.Manufacturer + ", " + item.Model + ", " + item.Year + " (VIN:" + item.VIN + ")",
                                    metadata: item
                                })
                                toggleTrailerSelectDialog();
                            },
                            icon: PlusCircleIcon,
                            visible: () => true
                        }
                    ]}
                    selects={getAssetSelects()}
                    onClose={toggleTrailerSelectDialog}
                    translate={translate}
                />
            </PageTabWithFooter>
        </Layout>
    )
}
