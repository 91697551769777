import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    createResource,
    deleteResource,
    getResource,
    resetCreateUpdateResource,
    updateResource
} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    longTableColumn,
    mergeDeep,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import {getSecondResource, resetSecondResource} from '../../../data/actions/secondResource'
import {CREATE_PERM, DEFAULT_CRUD_STATE, DELETE_PERM, READ_PERM, UPDATE_PERM} from '../../../util/util-constants'
import {cloneDeep} from '../../../common/util/util-vanilla'
import moment from 'moment'
import TaxReportDialog from './tax-report-dialog'
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import Modal from "../../../common/components/modal";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ResourceTable from "../../../common/components/resource-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Page from "../../../common/components/layout/layout-components/page";
import Layout from "../../../common/components/layout";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import Button from '../../../common/components/button'
import {DEFAULT_QUERY_LIMIT} from '../../../common/util/util-consts'
import ModalDefault from "../../../common/components/modal/modal-default";
import SalesTaxReportModal from "../summary/sales-tax-report-modal";

class TaxesPage extends Component {

    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: 'Tax',

            tableOptions: getDefaultTableOptions(this.getFields(), {
                behaviour: {
                    canAdjustWidth: true
                },
                style: {
                    condensed: true,
                },
            }, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),
            isSalesTaxReportDialogOpen: false,
            confirmDialog: false,
            breakpoint: {}
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (this.props.resource !== prevProps.resource && !this.props.resource.isLoading && this.props.resource?.create?.id) {
            this.props.dispatch(resetCreateUpdateResource())
            this.setState({
                createModalOpen: false
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    archiveResource = (item) => {

        this.setState({text: this.props.translate('message.confirm_archive_generic') + ` ${item.Tax}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({
                            TaxID: item.TaxID
                        }, this.getQuery()),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [item.Tax]),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {

        this.setState({text: this.props.translate('message.confirm_restore_generic') + ` ${item.Tax}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            TaxID: item.TaxID,
                            ArchivedDate: 1
                        },
                        query: this.getQuery(),
                        errorMessage: true, successMessage: `Tax '${item.Tax}' restored`,
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */

    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10)
            }
        })
    }

    handleToggleInfoCardModal = (item) => {
        this.setState({
            selectedItem: item,
            infoCardDialog: !this.state.infoCardDialog
        })
    }

    handleToggleConfirmDialog = (submit, item) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleCreateUpdateResource = () => {
        this.setState({
            selectedItem: null,
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleUpdateModal = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
        if (!item) this.props.dispatch(resetSecondResource())
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }
    /** Helpers
     ================================================================= */
    getFirstDateOfTheCurrentMonth = () => {
        return moment().startOf('month')
    }

    getLastDateOfTheCurrentMonth = () => {
        return moment().endOf('month')
    }

    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, Object.keys(this.getQuery()).map(it => it))
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
        }
    }

    countDecimals = function (value) {
        if ((value % 1) != 0) {
            return value?.toString()?.split(".")[1]?.length ?? 0;
        }
        return 0;
    };

    percentageDecimals = (value) => {
        if (this.countDecimals(value) >= 2) {
            return (this.countDecimals(value) - 2)
        } else {
            return 0
        }

    }

    handleOnViewClick = (selectedItem) => {
        this.setState({
            isSalesTaxReportDialogOpen: true,
            selectedItem: selectedItem
        })
    }

    getFields = (item = null) => {
        let fieldTemplates = {
            Tax: new Field('Tax', '', ['empty'], false, 'text', {addContainerClass: 'col-span-full', label: 'TaxName'}),
            Amount: new Field('Amount', '', ['float'], false, !!this.state?.createModalOpen ? 'percentage' : 'custom', {
                addContainerClass: 'col-span-full',
                render: (it) => {
                    return <span
                        className="text-gray-500 sm:text-sm">{((it.Amount - 1) * 100).toFixed(this.percentageDecimals(it.Amount))}%</span>
                }
            }),
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
        }
        return fillFieldsFromData(fieldTemplates, item)
    }

    getResource = () => {
        return Resources.AccountingTaxes
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, secondResource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        const metadata = {
            AccountID: {
                api: 'api/accounting/accounts',
                query: {
                    // AccountTypeID: ACCOUNT_TYPE_OTHER_CURRENT_LIABILITY
                },
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName
                })
            }
        }

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.TaxsView')}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="sm:flex mb-2">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex sm:justify-start justify-end items-center">
                            <div className={'flex mr-4'}>
                                <TableSettingsPopOver
                                    options={this.state.tableOptions}
                                    setOptions={this.setOptions}
                                    toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                    translate={translate}
                                />
                            </div>
                            <Button
                                hasPerm={checkPerm(Resources.Expense, CREATE_PERM)}
                                className={`btn btn-primary`}
                                onClick={this.handleCreateUpdateResource}
                            >
                                {translate('btn.create_new')}
                            </Button>
                        </div>
                    </div>

                    <TableCard addClass={'relative'}>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            isLoading={resource.isLoading}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading || secondResource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            // onRowClick={checkPerm(this.getResource(), READ_PERM) ? this.handleToggleInfoCardModal : null}
                            // onView={checkPerm(this.getResource(), READ_PERM) ? this.handleToggleInfoCardModal : null}
                            onRowClick={checkPerm(Resources.AccountingTaxesReport, READ_PERM) ? this.handleOnViewClick : null}
                            onView={this.handleOnViewClick}
                            hasViewPerm={checkPerm(Resources.AccountingTaxesReport, READ_PERM)}
                            onDelete={checkPerm(this.getResource(), DELETE_PERM) ? this.archiveResource : null}
                            onRestore={checkPerm(this.getResource(), UPDATE_PERM) ? this.restoreFromArchive : null}
                        />

                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === 'offset'
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={'No matching records found'}
                            text={'Create a new tax'}
                            btnLabel="Create tax"
                            onBtnClick={() => {
                                this.setState({prefilled: this.state.queryFilterFields.query.value}, () => {
                                    this.handleCreateUpdateResource()
                                })
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    <Modal
                        show={this.state.infoCardDialog}
                        onClose={() => this.handleToggleInfoCardModal()}
                        widthClass={'max-w-5xl'}
                    >
                        <TaxReportDialog
                            title={translate('text.AccountTransactions')}
                            translate={translate}
                            selectedItem={this.state.selectedItem}
                            onClose={() => this.handleToggleInfoCardModal()}

                            data={getProp(this.props.secondResource, 'data.Transactions', [])}
                            isLoading={getProp(this.props.secondResource, 'isLoading', true)}

                            queryFilterFields={() => {
                                return {
                                    StartDate: new Field('StartDate', this.getFirstDateOfTheCurrentMonth(), [], false, 'date', {
                                        addContainerClass: 'col-span-2'
                                    }),
                                    EndDate: new Field('EndDate', this.getLastDateOfTheCurrentMonth(), [], false, 'date', {
                                        addContainerClass: 'col-span-2'
                                    }),
                                }
                            }}

                            tableFields={() => {
                                return {
                                    Description: new Field('Description', '', ['empty'], false, 'custom', {
                                        render: (item) => (
                                            <div>{longTableColumn(item.Description)}</div>
                                        )
                                    }),
                                    CreateUpdateDate: new Field('CreateUpdateDate', '', ['empty'], false, 'datetimezone'),
                                    Date: new Field('Date', '', ['empty'], false, 'date'),
                                    Credit: new Field('Credit', '', []),
                                    Debit: new Field('Debit', '', []),
                                }
                            }}

                            onFetchData={(query) => {
                                this.props.dispatch(getSecondResource({
                                    user: LocalStorage.get('user'),
                                    resource: Resources.AccountingTaxesReport,
                                    query: Object.assign({}, FieldsManager.getFieldKeyValues(query), {
                                        TaxID: this.state.selectedItem.TaxID
                                    })
                                }))
                            }}
                        />
                    </Modal>

                    <ModalSaveResource
                        title={'Edit Tax'}
                        widthClass="max-w-md"
                        gridColsClass="grid-cols-3"
                        show={this.state.editModalOpen}
                        errorMessage={this.state.errorMessage}
                        onClose={this.handleToggleUpdateModal}
                        fields={this.getFields(this.state.selectedItem)}
                        onSubmit={(params) => {
                            if (params) {
                                params.TaxID = this.state.selectedItem.TaxID
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: 'Tax updated.'
                                }))
                                this.handleToggleUpdateModal(null)
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                    />

                    <ModalSaveResource
                        title={'Create Tax'}
                        show={this.state.createModalOpen}
                        widthClass="max-w-md"
                        gridColsClass="grid-cols-3"
                        onClose={this.handleCreateUpdateResource}
                        errorMessage={this.props.resource.errorMessage}
                        fields={this.getFields()}
                        onSubmit={(params) => {
                            if (params) {
                                params.Amount = (params.Amount.replace('%', '') / 100) + 1
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: 'Tax created.'
                                }))
                                this.handleCreateUpdateResource()
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                    />

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.handleToggleConfirmDialog()}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    <TableOptionsDialog
                        show={this.state.columnsModalOpen}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />
                </Page>

                <ModalDefault
                    show={this.state.isSalesTaxReportDialogOpen}
                    title={'Sales Tax report'}
                    widthClass="max-w-5xl"
                    hideDialogFooter={true}
                    closeButtonLabel={translate('btn.close')}
                    onClose={() => {
                        this.setState({
                            isSalesTaxReportDialogOpen: false
                        })
                    }}
                >
                    <SalesTaxReportModal
                        selectedItem={this.state.selectedItem}
                        show={this.state.isSalesTaxReportDialogOpen}
                        onClose={() => {
                            this.setState({
                                isSalesTaxReportDialogOpen: false
                            })
                        }}
                        translate={translate}
                    />
                </ModalDefault>
            </Layout>
        )
    }
}

export default connect(state => state)(TaxesPage)
