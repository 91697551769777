import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {createResource, deleteResource, getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DEFAULT_QUERY_LIMIT,
    DELETE_PERM, PAYMENT_METHOD_FACTOR, READ_PERM, UPDATE_PERM, WITH_DEFAULT_METADATA_SELECT_SEARCH_QUERY
} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep, renderInvoiceStatusBadge,
    saveTableColumns,
    verifyFilterFields
} from '../../../common/util/util-helpers'
import Resources from '../../../data/services/resources'
import {ArrowRightOnRectangleIcon, CheckCircleIcon, EyeIcon, TicketIcon, TrashIcon} from '@heroicons/react/24/outline'
import Tippy from '@tippyjs/react'
import {currentDate, toFrontDateTime} from '../../../common/util/util-dates'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {download} from '../../../data/actions/download'
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import NoRecords from "../../../common/components/no-records-found/no-records";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ResourceTable from "../../../common/components/resource-table";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableCardFooter from '../../../common/components/resource-table/table-components/table-card-footer'
import Button from '../../../common/components/button'
import FieldContainer from "../../../common/components/fields/field-container";
import FieldDate from "../../../common/components/fields/field-date";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalDefault from "../../../common/components/modal/modal-default";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import FieldsToHtml from "../../../common/components/fields/fields-to-html";
import {getDialogResource, resetDialogResource} from "../../../data/actions/dialogResource";
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import {getThirdResource} from "../../../data/actions/thirdResource";
import InvoiceExpenseDetailsDialog from "../dialogs/invoice-expense-details-dialog";
import {
    createSecondResource,
    deleteSecondResource,
    getSecondResource,
    updateSecondResource
} from "../../../data/actions/secondResource";

export default class TransfersTab extends Component {
    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1) + '_income_transfers'

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: this.props.sortBy ? this.props.sortBy : 'Description',
            createDepositOffset: 0,

            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),
            createDepositFields: this.getCreateDepositFields(),
            createDepositFieldsQuery: this.getCreateDepositFieldsQuery(),

            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            isTableFullScreen: false,

            selectedItem: null,
            createModalOpen: false,
            depositModalOpen: false,
            confirmModalOpen: false,
            isTransactionsDialogVisible: false,
            isDetailsDialogVisible: false,
        }

        this.state = mergeDeep(this.state, verifyFilterFields(
            LocalStorage.rehydrateState(this.pagePath),
            this.state.queryFilterFields
        ))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: this.getResource()
        }))
    }

    fetchDetailsData = () => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            query: {
                RecvBatchPaymentID: this.state?.selectedTransactionsItem?.RecvBatchPaymentID,
                RecvPaymentID: this.state?.selectedTransactionsItem?.RecvPaymentID
            },
            resource: Resources.IncomeTransactionsDetails
        }))
    }

    fetchUndepositedPayments = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: Resources.IncomeTransfers,
            query: Object.assign({
                Undeposited: 1,
                offset: this.state.createDepositOffset
            }, FieldsManager.getFieldKeyValues(this.state.createDepositFieldsQuery))
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign(
                {format: 'EXCEL', name: 'Transfers_' + currentDate() + '.xlsx'},
                this.getQuery()
            )
        }))
    }

    createDeposit = (item) => {
        this.props.dispatch(createResource(
            {
                user: LocalStorage.get('user'),
                params: Object.assign({
                    AccountFromID: item.AccountID,
                    RecvBatchPaymentID: item?.RecvBatchPaymentID,
                    RecvPaymentID: item?.RecvPaymentID,
                    Amount: item.Deposit
                }, FieldsManager.getFieldKeyValues(this.state.createDepositFields)),
                resource: this.getResource(),
                piggyResource: this.getResource(),
                errorMessage: true,
                successMessage: this.props.translate('message.item_created')
            }))
        this.handleToggleDepositModal()
    }

    fetchInvoiceDialogData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            query: {
                InvoiceID: this.state.selectedItem.InvoiceID
            },
            resource: Resources.InvoicesInvoice
        }))
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'DESC'
        }, () => {
            this.fetchData()
            this.saveFilters()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
            this.saveFilters()
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen,
        })
    }

    handleToggleDepositModal = () => {
        if (!this.state.depositModalOpen) {
            this.fetchUndepositedPayments();
        } else {
            this.props.dispatch(resetDialogResource());
        }

        this.setState({
            depositModalOpen: !this.state.depositModalOpen,
            createDepositFields: this.getCreateDepositFields(),
        })
    }
    
    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleCreateUpdateOffset = (offset) => {
        this.setState({
            createDepositOffset: offset
        }, () => {
            this.fetchUndepositedPayments()
        })
    }

    handleCreateFilterInputChange = (name, value) => {
        this.setState({
            createDepositFieldsQuery: FieldsManager.updateField(this.state.createDepositFieldsQuery, name, value),
            createDepositOffset: 0
        }, () => {
            this.fetchUndepositedPayments()
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), this.tablePageDefaults, this.props.translate)
    }

    onCreateDepositFieldsChange = (name, value) => {
        this.setState({createDepositFields: FieldsManager.updateField(this.state.createDepositFields, name, value)})
    }

    handleToggleShowTransactions = (item = null) => {
        this.setState({
            selectedTransactionsItem: item,
            isTransactionsDialogVisible: !this.state.isTransactionsDialogVisible,
        }, () => {
            this.state.isTransactionsDialogVisible && this.fetchDetailsData()
        })
    }

    toggleShowDetails = (item = null) => {
        this.setState({
            selectedItem: item,
            isDetailsDialogVisible: !this.state.isDetailsDialogVisible,
        })
    }
    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input'])
    }

    getResource = () => {
        return Resources.IncomeTransfers
    }

    getQuery = () => {
        const {AccountToID, AccountFromID, Date} = FieldsManager.getFieldKeyValues(this.state.queryFilterFields)

        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            searchFields: JSON.stringify({
                AccountFromID: AccountFromID,
                AccountToID: AccountToID,
                Date: Date
            })
        }
    }

    getFields = () => {
        return {
            Description: new Field('Description', '', [''], false, 'text', {addContainerClass: 'col-span-full'}),
            Amount: new Field('Amount', '', ['empty'], false, 'money', {
                addContainerClass: 'col-span-full',
                render: (item) => {
                    if (item.ReconciliationDate) {
                        return (
                            <Tippy content={'Reconciled: ' + toFrontDateTime(item.ReconciliationDate)}>
                                <div className={'flex justify-end'}>
                                    <CheckCircleIcon className="w-5 h-5 text-green-600 mr-4"/>
                                    <div>{genericMoneyFormatter(item.Amount)}</div>
                                </div>
                            </Tippy>
                        )
                    }
                    return <div className={'text-right'}>{genericMoneyFormatter(item.Amount)}</div>
                }
            }),
            Date: new Field('Date', currentDate(), ['empty'], false, 'date', {addContainerClass: 'col-span-full'},{minDate: getProp(this.props.resource.data, 'defaults.LockedDate', null)}),
            AccountFromID: new Field('AccountFromID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}, {
                api: 'api/' + Resources.AccountsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName,
                })
            }),
            AccountToID: new Field('AccountToID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}, {
                api: 'api/' + Resources.AccountsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName,
                })
            })
        }
    }

    getCreateDepositFields() {
        return {
            AccountToID: new Field('AccountToID', '', ['empty'], false, 'select-search', {
                    addContainerClass: 'col-span-4'
                },
                {
                    isClearable: true,
                    api: 'api/' + Resources.AccountsQuick,
                    query: WITH_DEFAULT_METADATA_SELECT_SEARCH_QUERY({
                        IsBankAccount: 1
                    }),
                    searchMap: (item) => ({
                        value: item.AccountID,
                        label: item.AccountNumber + ' ' + item.AccountName,
                    }),
                }),
            Date: new Field('Date', currentDate(), ['empty'], false, 'date', {addContainerClass: 'col-span-4'}, {minDate: getProp(this.props.resource.data, 'defaults.LockedDate', null)}),
            Description: new Field('Description', '', [''], false, 'text', {addContainerClass: 'col-span-4'})

        }
    }

    getCreateDepositFieldsQuery()  {
        return {
            query: new Field('query', '', [''], false, 'search'),
            StartDate: new Field('StartDate', '', [], false, 'date', {}, {isClearable: true}),
            EndDate: new Field('EndDate', '', [], false, 'date', {}, {isClearable: true}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true, labelType: "float"})
        }
    }

    getUndepositedPaymentsFields(item = {}) {
        const fieldTemplates = {
            Invoices: new Field('Invoices', '', [], false, 'custom', {
                render: (item) => {
                    if (item.Items) {
                        const isBatch = (item.Count >= 1 && item.RecvBatchPaymentID != null)
                        return (
                            <div className={'flex'}>
                                {isBatch && <div
                                    className={'bg-green-600 text-white inline-flex text-center px-1 rounded-btn text-sm font-medium whitespace-nowrap'}>{item.Count}</div>}
                                <div className="ml-1 max-w-[24rem] truncate">{item.Items}</div>
                            </div>
                        )
                    }
                    return ''
                },
                omitSort: true
            }),
            ChargeTo: new Field('ChargeTo', '', [], false, 'custom', {
                render: (item) => {
                    if (item.ChargeTo) {
                        return <div className="max-w-[24rem] truncate">{item.ChargeTo}</div>
                    }
                    return ''
                },
                omitSort: true
            }),
            Deposit: new Field('Deposit', '', [], false, 'money', {
                omitSort: true,
                label: 'TransactionAmount',
                render: (item) => {
                    if (item.PaymentTypeID == PAYMENT_METHOD_FACTOR) {
                        return (
                            <div>
                                {item.ReconciliationDate ? (
                                        <Tippy content={'Amount before factoring expenses: ' + item.PaymentAmount}>
                                            <div className="flex justify-end">
                                                <CheckCircleIcon className="mr-4 w-5 h-5 text-green-600"/>
                                                <div>
                                                    {genericMoneyFormatter(item.Deposit)}
                                                </div>
                                            </div>
                                        </Tippy>
                                    ) :
                                    <div className={'text-right'}>
                                        {genericMoneyFormatter(item.Deposit)}
                                    </div>}
                            </div>
                        )
                    }
                    return (
                        <div>
                            {item.ReconciliationDate ? (
                                    <Tippy content={'Reconciled: ' + toFrontDateTime(item.ReconciliationDate)}>
                                        <div className="flex justify-end">
                                            <CheckCircleIcon className="mr-4 w-5 h-5 text-green-600"/>
                                            <div>
                                                {genericMoneyFormatter(item.Deposit)}
                                            </div>
                                        </div>
                                    </Tippy>
                                ) :
                                <div className={'text-right'}>
                                    {genericMoneyFormatter(item.Deposit)}
                                </div>}
                        </div>
                    )
                },
            }),
            Date: new Field('Date', '', [], false, 'date', {}),
            Account: new Field('Account', '', [], false, 'text', {
                omitSort: true
            }),
            PaymentTypeID: new Field('PaymentTypeID', '', [], false, 'select', {
                omitSort: true
            }),
            Description: new Field('Description', '', [], false, 'text', {
                render: (it) => (<div>{it.RecvPaymentDescription ?? it.RecvBatchPaymentDescription}</div>),
                omitSort: true,
                label: 'Description'
            })
        }

        return fillFieldsFromData(fieldTemplates, item);
    }
    
    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            AccountFromID: new Field('AccountFromID', '', [''], false, 'select-search', {},
                {
                    isClearable: true,
                    api: 'api/' + Resources.AccountsQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        value: item.AccountID,
                        label: item.AccountNumber + ' ' + item.AccountName,
                    }),
                }),
            AccountToID: new Field('AccountToID', '', [''], false, 'select-search', {},
                {
                    isClearable: true,
                    api: 'api/' + Resources.AccountsQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        value: item.AccountID,
                        label: item.AccountNumber + ' ' + item.AccountName,
                    }),
                }),
            Date: new Field('Date', '', [''], false, 'date'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                containerClass: 'col-md-6 limit-field-container float-right', hideLabel: true
            }, {menuPlacement: 'top'})
        }
    }

    getDetailsFields = () => {
        return {
            AutoCounter: new Field('AutoCounter', '', [], false, 'text', {
                omitSort: true
            }),
            RefNumber: new Field('RefNumber', '', [], false, 'text', {
                omitSort: true
            }),
            ChargeTo: new Field('ChargeTo', '', [''], false, 'custom', {
                omitSort: true,
                render: (it) => it.Organization ?? it.Contact
            }),
            Amount: new Field('Amount', '', [], false, 'money', {
                omitSort: true,
                label: 'PaymentAmount',
            }),
            InvoiceAmount: new Field('InvoiceAmount', '', [], false, 'money', {
                omitSort: true
            }),
            InvoiceStatus: new Field('InvoiceStatus', '', [''], false, 'custom', {
                omitSort: true,
                render: (it) => renderInvoiceStatusBadge(it)
            }),
        }
    }
    sortByRefNumber = (unsortedTransactions) => {
        const compareFunction = (a, b) => a.RefNumber?.localeCompare(b.RefNumber, 'en', {numeric: true})
        return unsortedTransactions.sort(compareFunction)
    }
    /** Render
     ================================================================= */
    render() {
        const {
            translate,
            dialogResource
        } = this.props

        const data = getProp(this.props, 'resource.data.list', [])
        const count = getProp(this.props, 'resource.data.count', 0)

        const transactionsData = getProp(this.props, 'thirdResource.data.list', [])
        const transactionsDataIsLoading = getProp(this.props, 'thirdResource.isLoading', false)

        const isLoading = getProp(this.props, 'resource.isLoading', false)

        const dataCreateDeposit = getProp(dialogResource.data, 'list', [])
        const dataCreateDepositLength = getProp(dialogResource.data, 'count', [])
        const isDialogLoading = getProp(dialogResource, 'isLoading', false)
        
        return (
            <React.Fragment>
                <div className="flex mb-2">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />
                    <div className="flex ml-auto">
                        <div className={'flex mr-4'}>
                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>

                        <Button
                            hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                            className={`btn btn-primary mr-2`}
                            onClick={() => {
                                this.handleToggleDepositModal()
                            }}
                        >
                            {"Create deposit"}
                        </Button>

                        <Button
                            hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                            className={`btn btn-primary`}
                            onClick={() => {
                                this.handleToggleCreateModal()
                            }}
                        >
                            {"Create transfer"}
                        </Button>
                    </div>
                </div>

                <TableCard
                    addClass={
                        classNames(
                            this.state.isTableFullScreen
                                ? 'fixed inset-1 -top-1 z-50'
                                : undefined
                        )}
                >
                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        onFullScreenToggle={this.handleFullScreenToggle}
                        isLoading={isLoading}
                        isTableFullScreen={this.state.isTableFullScreen}
                    />

                    <ResourceTable
                        tableKey={'AccountTransferID'}
                        data={data}
                        commonTable={true}
                        fields={this.getFields()}

                        translate={translate}
                        isLoading={isLoading}

                        options={this.state.tableOptions}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        saveTableOptions={this.setOptions}

                        actions={[
                            {
                                action: this.handleToggleShowTransactions,
                                icon: EyeIcon,
                                visible: (it) => (it.RecvPaymentID || it.RecvBatchPaymentID),
                                hasPerm: checkPerm(Resources.IncomeTransactionsDetails, READ_PERM),
                                title: translate('text.show_details'),
                            },
                            {
                                action: this.handleToggleConfirmModal,
                                icon: TrashIcon,
                                visible: (it) => !it.ReconciliationDate,
                                hasPerm: checkPerm(this.getResource(), DELETE_PERM),
                                title: translate('text.delete_item'),
                            }
                        ]}
                    />

                    {/*Table footer*/}
                    <NoRecords
                        show={(data.length === 0) && !isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                    <TableCardFooter
                        show={!(!data.length && !isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>
                </TableCard>

                <ModalSaveResource
                    show={this.state.createModalOpen}
                    title={translate('Create transfer')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    translate={this.props.translate}
                    closeButtonLabel={translate('btn.cancel')}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createResource(
                                {
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: this.props.translate('message.item_created')
                                }))
                            this.handleToggleCreateModal()
                        }
                    }}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmModalOpen}
                    text={translate('message.are_you_sure_delete_item')}
                    onClose={this.handleToggleConfirmModal}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        let id = this.state.selectedItem.AccountTransferID
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get('user'),
                            query: {
                                AccountTransferID: id
                            },
                            piggyQuery: this.getQuery(),
                            resource: this.getResource(),
                            piggyResource: this.getResource(),
                            errorMessage: true, successMessage: translate('message.transfer_deleted')
                        }))
                        this.handleToggleConfirmModal()
                    }}
                />

                <ModalDefault
                    show={this.state.depositModalOpen}
                    widthClass={'max-w-6xl'}
                    title={"Create deposit"}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleDepositModal()}
                >
                    <div>
                        <div className={"grid grid-cols-12 gap-x-5 gap-y-2 m-2"}>
                            <FieldsToHtml
                                fieldsState={this.state.createDepositFields}
                                translate={translate}
                                onInputChange={this.onCreateDepositFieldsChange}
                            />
                        </div>

                        <TableFilters
                            hideLimit
                            filterFields={this.state.createDepositFieldsQuery}
                            handleInputChange={this.handleCreateFilterInputChange}
                            translate={translate}
                        />

                        <ResourceTable
                            data={dataCreateDeposit}
                            commonTable={true}
                            maxHeightClass={'max-h-[calc(100vh-32rem)]'}
                            fields={this.getUndepositedPaymentsFields()}

                            translate={translate}
                            isLoading={isDialogLoading}

                            limit={(this.state.createDepositFieldsQuery?.limit?.value ?? 10)}

                            actions={[
                                {
                                    action: this.handleToggleShowTransactions,
                                    icon: EyeIcon,
                                    visible: (it) => (it.RecvPaymentID || it.RecvBatchPaymentID),
                                    hasPerm: checkPerm(Resources.IncomeTransactionsDetails, READ_PERM),
                                    title: translate('text.show_details'),
                                },
                                {
                                    action: this.createDeposit,
                                    icon: TicketIcon,
                                    visible: () => true,
                                    disabled: !this.state.createDepositFields.AccountToID.value || !this.state.createDepositFields.Date.value,
                                    hasPerm: checkPerm(Resources.IncomeTransfers, CREATE_PERM),
                                    title: translate('btn.create'),
                                }
                            ]}
                        />

                        <NoRecordsTable
                            show={(dataCreateDeposit.length === 0) && !isDialogLoading}
                            canCreate={false}
                            title={translate('text.no_matching_records')}
                        />

                        <TableCardFooter
                            show={!(!dataCreateDeposit.length && !isDialogLoading)}
                        >
                            <Pagination
                                count={dataCreateDepositLength}
                                isLoading={isLoading}
                                handleQueryChange={
                                    (name, value) => name === 'offset'
                                        ? this.handleCreateUpdateOffset(value)
                                        : this.handleCreateFilterInputChange(name, value)
                                }
                                pageOffset={this.state.createDepositOffset}
                                queryFields={this.state.createDepositFieldsQuery}
                                translate={translate}
                            />
                        </TableCardFooter>
                    </div>
                </ModalDefault>

                <ModalDefault
                    show={this.state.isTransactionsDialogVisible}
                    widthClass={'max-w-6xl'}
                    title={translate('text.Payments')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.handleToggleShowTransactions()}
                >
                    <div className="p-5">
                        <ResourceTable
                            tableKey={'RecvPaymentID'}
                            maxHeightClass={'max-h-[calc(100vh-26rem)]'}
                            data={this.sortByRefNumber(transactionsData)}
                            fields={this.getDetailsFields()}

                            translate={translate}
                            isLoading={transactionsDataIsLoading}

                            limit={(transactionsData?.length ?? 10)}

                            onRowClick={this.toggleShowDetails}
                            actions={[
                                {
                                    action: this.toggleShowDetails,
                                    icon: EyeIcon,
                                    hasPerm: checkPerm(Resources.InvoicesInvoice, READ_PERM),
                                    title: translate('text.show_details'),
                                }
                            ]}
                        />
                    </div>
                </ModalDefault>

                <ModalDefault
                    show={this.state.isDetailsDialogVisible}
                    widthClass={'max-w-full'}
                    title={translate('text.InvoiceID')}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.toggleShowDetails()}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={this.props.secondResource}
                        isLoading={this.props.secondResource.isLoading || this.props.resource.isLoading || this.props.download.isLoading}
                        translate={translate}
                        dispatch={this.props.dispatch}
                        type="invoice"

                        disableComments={true}
                        canDeletePayment={false}
                        disableAddReference={true}
                        disableTransactionImport={true}
                        showLoadReferenceLinks

                        onDocumentDownload={(item) => {
                            const fileExt = item?.AttachPath.split('.').pop()

                            this.props.dispatch(download({
                                user: LocalStorage.get('user'),
                                resource: Resources.InvoicesDocuments,
                                query: Object.assign({
                                    id: item.InvoiceID,
                                    format: fileExt,
                                    name: 'document_' + currentDate() + '.' + fileExt
                                })
                            }))
                        }}

                        onFetchData={() => {
                            if (this.state.selectedItem) {
                                this.fetchInvoiceDialogData()
                            }
                        }}
                    />
                </ModalDefault>

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

            </React.Fragment>
        )
    }
}
