import Layout from "../../../common/components/layout";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "../../../common/util/util-auth";
import {Field, FieldsManager} from "../../../data/services/fields";
import {CREATE_PERM, DEFAULT_QUERY_LIMIT, READ_PERM} from "../../../common/util/util-consts";
import ResourcePage from "../../../common/components/layout/layout-components/resource-page";
import Resources from "../../../data/services/resources";
import {checkPerm, classNames, getProp, resourceIsCreated} from "../../../common/util/util-helpers";
import useQueryNew from "../../../hooks/use-query-new";
import {createResource, deleteResource, getResource, updateResource} from "../../../data/actions/resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import {useLocation} from "react-router-dom";
import LocalStorage from "../../../common/util/localStorage";
import Modal from "../../../common/components/modal";
import {DELETE_PERM, UPDATE_PERM} from "../../../util/util-constants";
import {
    ArrowUturnLeftIcon,
    EyeIcon,
    EyeSlashIcon,
    KeyIcon,
    LockClosedIcon,
    PowerIcon
} from "@heroicons/react/24/outline";
import {createDialogResource} from "../../../data/actions/dialogResource";
import ModalDefault from "../../../common/components/modal/modal-default";
import CheckIcon from "@heroicons/react/24/outline/CheckIcon";
import ContactForm from "../../../common/components/forms/contact-form";
import {getContactResource} from "../../../data/actions/contactResource";
import {showModal} from "../../../data/actions/ui";
import ClipboardDocumentIcon from "@heroicons/react/24/outline/ClipboardDocumentIcon";


export default function UsersView({translate, match, history}) {
    /** Page constants
     ================================================================= */
    const pageTitle = translate('page.heading.SystemUsers');
    const tableKey = 'Email';
    const noRecordsText = "Create a new contact";
    const noRecordsButtonLabel = "Create contact"
    const resourcePath = Resources.SystemContacts;
    const tableDefaults = {
        behaviour: {
            rowSelect: true,
            canAdjustWidth: true,
            hasMenu: true
        },
        style: {
            condensed: true,
            isGPUAccelerated: true
        },
        version: "1" // changing version will force update tableDefaults settings
    }

    const location = useLocation();
    const pagePath = location.pathname.replaceAll("/", "");

    const user = getUser();
    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);
    const resource = useSelector(state => state.resource);
    const dialogResource = useSelector(state => state.dialogResource);
    const contactResource = useSelector(state => state.contactResource);
    const contactData = getProp(contactResource, 'data', null);
    const contactIsLoading = getProp(contactResource, 'isLoading', false);

    const [breakpoint, setBreakpoint] = useState({});
    const [isResourceFormModalOpen, setIsResourceFormModalOpen] = useState(false);
    const [queryFields, setQueryFields] = useQueryNew(getQueryFields(), pagePath);
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
    const [isConfirmRestoreModalOpen, setIsConfirmRestoreModalOpen] = useState(false);
    const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] = useState(false);
    const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);
    const [emailExist, setEmailExist] = useState(false);
    const [isTemporaryPasswordModalOpen, setIsTemporaryPasswordModalOpen] = useState(false);
    const [isTemporaryPasswordVisible, setIsTemporaryPasswordVisible] = useState(false);
    const [isUrlCopied, setIsUrlCopied] = useState(false);

    const query = FieldsManager.getFieldKeyValues(queryFields);
    query.IsSystemUser = 1;

    const [selectedItem, setSelectedItem] = useState({});
    const [prefilled, setPrefilled] = useState("");

    /** Data Events
     ================================================================= */
    function fetchData() {
        dispatch(getResource({
            user: getUser(),
            resource: resourcePath,
            query: query
        }))
    }

    function fetchContactData() {
        if (!selectedItem?.ContactID) {
            return false;
        }

        dispatch(getContactResource({
            user: LocalStorage.get('user'),
            resource: Resources.Contacts,
            query: {id: selectedItem.ContactID}
        }))
    }

    function handleConfirmActivateClick() {
        dispatch(createResource({
            user: getUser(),
            params: {
                ContactID: selectedItem.ContactID
            },
            errorMessage: true,
            successMessage: `User ${selectedItem.FirstName} ${selectedItem.LastName} activated.`,
            query: query,
            resource: Resources.ContactsActivate,
            piggyResource: Resources.SystemContacts
        }));

        setIsActivateModalOpen(false);
    }

    /** UI Events
     ================================================================= */
    function handleBreakpointChange(breakpointUpdate) {
        setBreakpoint(Object.assign({}, breakpointUpdate));

        if (breakpointUpdate.index <= 1 && queryFields.limit.value !== 10) {
            handleQueryInputChange('limit', 10);
        }
    }

    function handleOpenResourceFormClick(item = {}) {
        setIsResourceFormModalOpen(true);
        setSelectedItem(item);
    }

    function handleCloseResourceFormClick() {
        setIsResourceFormModalOpen(false);
        setSelectedItem({});
        setPrefilled("");
    }

    function handleQueryInputChange(name, value) {
        const queryFieldsUpdate = FieldsManager.updateField(queryFields, name, value);

        if ("sortBy" === name) {
            if (value === queryFieldsUpdate?.sortBy?.value) {
                queryFieldsUpdate.sort.value = queryFieldsUpdate.sort.value === 'ASC' ? 'DESC' : 'ASC'
            }
        }

        setQueryFields(queryFieldsUpdate);
    }


    function handleClearAllFiltersClick() {
        setQueryFields(getQueryFields());
    }

    function handleViewResourceClick(item = {}) {
        dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}));
    }

    function handleDeleteResourceClick(item) {
        setSelectedItem(item);
        setIsConfirmDeleteModalOpen(true);
    }

    function handleConfirmDeleteClick() {
        dispatch(deleteResource({
            user: getUser(),
            query: Object.assign({
                ContactID: selectedItem.ContactID
            }, query),
            errorMessage: true,
            successMessage: translate(`message.contact_deactivated`, [selectedItem.FirstName, selectedItem.LastName]),
            resource: resourcePath,
            piggyResource: resourcePath
        }));
        setSelectedItem({});
        setIsConfirmDeleteModalOpen(false);
    }

    function handleRestoreResourceClick(item) {
        setSelectedItem(item);
        setIsConfirmRestoreModalOpen(true);
    }

    function handleConfirmRestoreClick() {
        dispatch(updateResource({
            user: getUser(),
            params: {
                id: selectedItem.ContactID,
                ArchivedDate: 1
            },
            query: query,
            errorMessage: true,
            successMessage: `User ${selectedItem.FirstName} ${selectedItem.LastName} reactivated.`,
            resource: resourcePath,
            piggyResource: resourcePath
        }));
        setSelectedItem({});
        setIsConfirmRestoreModalOpen(false);
    }

    function handlePermissionsClick(item) {
        history.push(`/contacts/${item.ContactID}`);
    }

    function toggleActionResendPasswordDialog(item = {}) {
        setIsResetPasswordDialogOpen(!isResetPasswordDialogOpen);
        setSelectedItem(item);
    }

    function handleActivateUserClick(item) {
        setSelectedItem(item);
        setIsActivateModalOpen(true);
    }

    function copyToClipboard(password) {
        setIsUrlCopied(true);

        navigator.clipboard.writeText(password);

        setTimeout(() => {
            setIsUrlCopied(false)
        }, 2000)
    }

    /** Helpers
     ================================================================= */
    function getQueryFields() {
        return {
            query: new Field('query', '', [''], false, 'search'),
            Groups: new Field('Groups', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            HighImportance: new Field('HighImportance', '', [''], false, 'checkbox'),
            archived: new Field('archived', '', [''], false, 'checkbox', {label: "deactivated"}),
            sort: new Field('sort', 'DESC', ['']),
            sortBy: new Field('sortBy', tableKey, ['']),
            offset: new Field('offset', '', ['']),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    function getFields() {
        return {
            FirstName: new Field('FirstName', '', ['empty']),
            LastName: new Field('LastName', '', ['empty']),
            Nickname: new Field('Nickname', '', ['empty']),
            Email: new Field('Email', '', ['empty', 'email']),
            PrivateEmail: new Field('PrivateEmail', '', ['empty', 'email']),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'date'),
            IsActivatedSystemUser: new Field('IsActivatedSystemUser', '', [], false, 'checkbox')
        }
    }

    useEffect(() => {
        fetchData();
    }, [queryFields]);

    useEffect(() => {
        // Todo: remove in the next version
        function consumeLegacyState() {
            const oldState = LocalStorage.get(pagePath + "_state");
            if (oldState) {
                const queryFieldsUpdate = Object.assign({}, queryFields);
                queryFieldsUpdate.sort.value = oldState.sort;
                queryFieldsUpdate.sortBy.value = oldState.sortBy;
                queryFieldsUpdate.limit.value = oldState.limit;
                queryFieldsUpdate.offset.value = oldState.offset;
                queryFieldsUpdate.query.value = oldState.queryFilterFields?.query?.value ?? "";
                queryFieldsUpdate.archived.value = oldState.queryFilterFields.archived?.value ?? "";
                queryFieldsUpdate.HighImportance.value = oldState.queryFilterFields.HighImportance?.value ?? "";
                queryFieldsUpdate.Groups.value = oldState.queryFilterFields.Groups?.value ?? "";

                LocalStorage.remove(pagePath + "_state");
                setQueryFields(queryFieldsUpdate);
            }
        }

        consumeLegacyState();
    }, [pagePath]);

    useEffect(() => {
        if (resource.errorMessage && resource.errorMessage === 'EMAIL_PARAMETER_ALREADY_EXISTS') {
            setEmailExist(true);
        }

        if (resourceIsCreated(resource)) {
            setIsResourceFormModalOpen(false);
        }
    }, [resource])

    useEffect(() => {
        if (resourceIsCreated(dialogResource) && dialogResource.create.password) {
            setIsTemporaryPasswordModalOpen(true);
        }
    }, [dialogResource]);

    /** Render
     ================================================================= */
    return (
        <Layout
            ui={ui}
            user={user}
            match={match}
            history={history}
            location={location}
            translate={translate}
            dispatch={dispatch}
            isAccessible={!(resource.errorStatus === 2)}
            onBreakpointChange={handleBreakpointChange}
        >
            <ResourcePage
                tableKey={tableKey}
                pagePath={pagePath}
                resourcePath={resourcePath}
                pageTitle={pageTitle}
                tableFields={getFields()}
                onCreateResourceClick={() => handleOpenResourceFormClick({})}
                onNoRecordsCreateClick={() => {
                    setPrefilled(queryFields.query.value);
                    handleOpenResourceFormClick({});
                }}
                queryFields={queryFields}
                onQueryInputChange={handleQueryInputChange}
                onClearAllFiltersClick={handleClearAllFiltersClick}
                fetchData={fetchData}
                translate={translate}
                breakpoint={breakpoint}
                onView={handleViewResourceClick}
                onEdit={handleOpenResourceFormClick}
                onRowClick={handleOpenResourceFormClick}
                tableDefaults={tableDefaults}
                noRecordsText={noRecordsText}
                noRecordsButtonLabel={noRecordsButtonLabel}
                actions={[
                    {
                        action: handleDeleteResourceClick,
                        icon: PowerIcon,
                        visible: (it) => (!it.ArchivedDate && getProp(getUser(), 'Contact.ContactID', 0) !== it.ContactID),
                        hasPerm: checkPerm(resourcePath, DELETE_PERM),
                        label: false,
                        title: translate('btn.deactivate'),
                        order: 100
                    },
                    {
                        action: handleRestoreResourceClick,
                        icon: ArrowUturnLeftIcon,
                        visible: (it) => !!it.ArchivedDate,
                        hasPerm: checkPerm(resourcePath, UPDATE_PERM),
                        label: false,
                        title: translate('btn.reactivate'),
                        order: 100
                    },
                    {
                        action: handlePermissionsClick,
                        icon: KeyIcon,
                        hasPerm: () => checkPerm(Resources.UserPermissions, READ_PERM),
                        label: false,
                        title: translate('text.permissions'),
                        order: 62
                    },
                    {
                        action: toggleActionResendPasswordDialog,
                        icon: LockClosedIcon,
                        hasPerm: () => checkPerm(Resources.SystemContactsPassword, CREATE_PERM),
                        label: false,
                        title: translate('text.ResendPassword'),
                        visible: (it) => it.IsActivatedSystemUser,
                        order: 63
                    },
                    {
                        action: handleActivateUserClick,
                        icon: CheckIcon,
                        visible: (it) => !it.IsActivatedSystemUser,
                        hasPerm: checkPerm(resourcePath, UPDATE_PERM),
                        label: false,
                        title: translate('btn.Activate'),
                        order: 64
                    },
                ]}
            />

            <Modal
                show={isResourceFormModalOpen}
                widthClass={'max-w-3xl'}
                onClose={handleCloseResourceFormClick}
            >
                <ContactForm
                    forceSystemUser={true}
                    data={!!selectedItem.ContactID && contactData}
                    isLoading={contactIsLoading}
                    onMount={fetchContactData}
                    onClose={handleCloseResourceFormClick}
                    errorMessages={emailExist ? [{
                        field: 'Email',
                        message: 'text.email_exists'
                    }] : []}
                    clearErrorData={() => setEmailExist(false)}
                    onSubmit={(params, file, documents = []) => {
                        if (selectedItem?.ContactID) {
                            dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                errorMessage: true,
                                successMessage: translate('message.ContactUpdatedSuccessfully'),
                                query: query,
                                resource: resourcePath,
                                piggyResource: Resources.SystemContacts,
                                file: !!file && [file],
                                fileResource: Resources.SystemContactImage,
                                document: documents,
                                documentResource: Resources.SystemContactsDocuments
                            }))

                            handleCloseResourceFormClick()
                        } else {
                            dispatch(createResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                errorMessage: true,
                                successMessage: translate('message.ContactCreatedSuccessfully'),
                                query: query,
                                resource: resourcePath,
                                piggyResource: Resources.SystemContacts,
                                file: !!file && [file],
                                fileResource: Resources.SystemContactImage,
                                document: documents,
                                documentResource: Resources.SystemContactsDocuments
                            }))
                            handleCloseResourceFormClick();
                        }
                    }}
                    translate={translate}
                    isSystemUser={true}
                />
            </Modal>

            <ModalDefault
                show={isResetPasswordDialogOpen}
                widthClass="max-w-md"
                title={`${selectedItem?.FirstName} ${selectedItem?.LastName} Resend Password`}
                closeButtonLabel={translate('btn.cancel')}
                onClose={toggleActionResendPasswordDialog}
            >
                <div className={"flex flex-col items-center justify-center w-64 mx-auto px-6 space-y-4 py-8"}>

                    <button
                        className="btn btn-outline-secondary w-full justify-center"
                        onClick={() => {
                            let params = {}
                            if (params) {

                                params[tableKey] = selectedItem[tableKey];
                                params.ValidationType = 1;
                                dispatch(createDialogResource({
                                    user: LocalStorage.get('user'),
                                    query: query,
                                    params: params,
                                    resource: Resources.SystemContactsPassword,
                                    piggyResource: Resources.SystemContacts,
                                    errorMessage: true,
                                    successMessage: Number(params.ValidationType) === 1
                                        ? translate("text.email_instructions_sent", [selectedItem?.Email])
                                        : null
                                }))
                                toggleActionResendPasswordDialog();
                            }
                        }}
                    >
                        Email
                    </button>

                    <button
                        className="btn btn-outline-secondary w-full justify-center"
                        onClick={() => {
                            let params = {}
                            if (params) {

                                params[tableKey] = selectedItem[tableKey];
                                params.ValidationType = 3;
                                dispatch(createDialogResource({
                                    user: LocalStorage.get('user'),
                                    query: query,
                                    params: params,
                                    resource: Resources.SystemContactsPassword,
                                    piggyResource: Resources.SystemContacts,
                                    errorMessage: true,
                                    successMessage: Number(params.ValidationType) === 3
                                        ? translate("text.temporary_password_created")
                                        : null
                                }))
                                toggleActionResendPasswordDialog()
                            }
                        }}
                    >
                        Temporary Password
                    </button>
                </div>
            </ModalDefault>

            <ModalDefault
                show={isTemporaryPasswordModalOpen}
                widthClass={'max-w-xl'}
                title={'Temporary password created'}
                closeButtonLabel={translate('btn.cancel')}
                onClose={() => setIsTemporaryPasswordModalOpen(false)}
            >
                <div className="p-5">
                    <button
                        className={classNames(!isUrlCopied ? 'text-tm-gray-900 hover:bg-tm-gray-100' : 'text-green-900 hover:bg-green-100', 'w-full p-3 flex items-center rounded-md text-base font-medium transition ease-in-out duration-150')}
                        onClick={() => {
                            copyToClipboard(dialogResource.create.password)
                        }}
                    >
                        <ClipboardDocumentIcon className="h-5 w-5"/>
                        <span
                            className="ml-3">{!isUrlCopied ? 'Copy password to clipboard' : 'Password copied to clipboard!'}</span>
                    </button>
                    <div
                        className={classNames('text-tm-gray-900 hover:bg-tm-gray-100', 'w-full p-3 flex items-center rounded-md text-base font-medium transition ease-in-out duration-150')}
                        onMouseEnter={() => {
                            setIsTemporaryPasswordVisible(true);
                        }}
                        onMouseLeave={() => {
                            setIsTemporaryPasswordVisible(false);
                        }}
                    >
                        {isTemporaryPasswordVisible ?
                            <EyeIcon className="h-5 w-5"/>
                            :
                            <EyeSlashIcon className="h-5 w-5"/>
                        }

                        <span
                            className="ml-3">{isTemporaryPasswordVisible ? dialogResource.create.password : '****************'}</span>
                    </div>
                </div>
            </ModalDefault>

            <ModalConfirm
                title={translate("modal_heading.confirm_archive")}
                show={isConfirmDeleteModalOpen}
                text={`${translate('message.confirm_deactivate_user', [`${selectedItem.FirstName} ${selectedItem.LastName}`])}`}
                onClose={() => {
                    setIsConfirmDeleteModalOpen(false);
                    setSelectedItem({});
                }}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={handleConfirmDeleteClick}
            />

            <ModalConfirm
                title={translate("modal_heading.confirm_restore")}
                show={isConfirmRestoreModalOpen}
                text={`${translate('message.are_you_sure_restore_user', [`${selectedItem.FirstName} ${selectedItem.LastName}`])}`}
                onClose={() => {
                    setIsConfirmRestoreModalOpen(false);
                    setSelectedItem({});
                }}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={handleConfirmRestoreClick}
            />

            <ModalConfirm
                type={'info'}
                title={'Confirm activation'}
                show={isActivateModalOpen}
                text={translate("text.activateAndSendEmail")}
                width={'2xl'}
                onClose={() => {
                    setIsActivateModalOpen(false);
                    setSelectedItem({});
                }}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={handleConfirmActivateClick}
            />
        </Layout>
    )
}