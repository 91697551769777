import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Field, FieldsManager} from '../../../../data/services/fields'
import {createResource, updateResource} from '../../../../data/actions/resource'
import LocalStorage from '../../../../util/localStorage'
import Resources from '../../../../data/services/resources'
import {processResponse} from '../../../../data/services/api-util'
import {DEFAULT_CRUD_STATE, DEFAULT_METADATA_SELECT_SEARCH_QUERY,} from "../../../../util/util-constants";
import {getJWT} from '../../../util/util-auth'
import {getProp, returnOffsetAndPagination} from '../../../util/util-helpers'
import axios from 'axios'
import Env from '../../../../util/env'
import {cloneDeep} from "../../../util/util-vanilla";
import {getContactResource, updateContactResource} from "../../../../data/actions/contactResource";
import ModalConfirm from "../modal-confirm";
import PageHeader from "../../layout/layout-components/page/page-header";
import Pagination from "../../resource-table/table-components/pagination";
import ResourceTableDialog from "../resource-table-dialog"
import NoRecords from "../../no-records-found/no-records";
import MobileTabs from "../../tabs-navigation/mobile-tabs";
import FieldContainer from "../../fields/field-container";
import FieldTextarea from "../../fields/field-textarea";
import FieldDropdownSelect from "../../fields/field-dropdown-select";
import FieldCheckbox from "../../fields/field-checkbox";
import ModalDefault from "../modal-default";
import ResourceTable from "../../resource-table";
import NavResponsive from "../../nav-responsive";
import TableFilters from "../../resource-table/table-components/table-filters";
import TableCard from "../../resource-table/table-components/table-card";
import TableCardFooter from "../../resource-table/table-components/table-card-footer";
import FieldText from "../../fields/field-text";
import ColorPickerUserColors from "../../color-picker/color-picker-user-colors";

class CreateContactGroupsDialog extends Component {

    constructor(props) {
        super(props);

        const tabs = this.getTabs()

        this.state = {
            ...DEFAULT_CRUD_STATE,

            createGroupContactsOffset: 0,
            createGroupContactsPaginationPage: 1,

            queryFilterFields: this.getQueryFilterFields(),
            createQueryFilterFields: this.getQueryFilterFieldsCreate(),

            fields: this.getFields(),

            selectedTab: tabs[0].resource,
            tabs: tabs,

            contactsResource: this.props.selectedItem?.ContactGroupID ? Resources.ContactGroupContacts : Resources.ContactsQuick,

            selectedContact: null,
            selectedContacts: {},
            selectedContactsCreate: {},

            selectedContactsToAdd: {},
            selectedContactsToRemove: {},
            reviewContacts: {},
            selectedReviewContacts: {},

            canSubmit: false,

            isAllContactsModalOpen: false,
            isViewContactsDialogOpen: false,
            isRemoveSingleContactConfirmModalOpen: false,
            isRemoveContactsConfirmModalOpen: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidUpdate = (prevProps, prevState) => {
        if (!prevProps.isOpen && this.props.isOpen) {

            const tabs = this.getTabs()

            this.setState({
                ...DEFAULT_CRUD_STATE,
                contactsResource: this.props.selectedItem?.ContactGroupID ? Resources.ContactGroupContacts : Resources.ContactsQuick,

                queryFilterFields: this.getQueryFilterFields(),
                fields: this.getFields(),

                tabs: tabs,
                selectedTab: tabs[0].resource
            }, () => this.fetchData())
        }

        if (prevProps.prefilled !== this.props.prefilled) {
            this.setState({
                fields: this.getFields()
            });
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getContactResource({
                user: LocalStorage.get('user'),
                resource: this.state.contactsResource,
                query: this.props.selectedItem?.ContactGroupID ?
                    Object.assign({...this.getQuery()}, {id: this.props.selectedItem?.ContactGroupID})
                    :
                    this.getQuery()
            })
        )
    }

    handleAddContacts = () => {
        if (this.props.selectedItem?.ContactGroupID) {
            this.props.dispatch(updateContactResource({
                user: LocalStorage.get('user'),
                params: {
                    ContactGroupID: this.props.selectedItem.ContactGroupID,
                    AddContacts: Object.keys(this.state.selectedContactsToAdd),
                    RemoveContacts: []
                },
                resource: Resources.ContactGroups,
                piggyResource: Resources.ContactGroupContacts,
                piggySecondResource: Resources.ContactGroups,
                secondPiggyQuery: this.props.query,
                query: this.getQuery(),
                errorMessage: true,
                successMessage: this.props.translate("message.contact_group_updated")
            }))
            this.handleToggleAllContactsModal()
        } else {
            this.setState({
                selectedContactsCreate: Object.assign(this.state.selectedContactsToAdd, this.state.selectedContactsCreate)
            })
            this.handleToggleAllContactsModal()
        }
    }

    handleRemoveSingleContactFromGroup = (item) => {
        this.setState({
            ...returnOffsetAndPagination(getProp(this.props.contactResource.data, 'list', []), this.getQuery(), this.state.paginationPage)
        }, () => {
            this.props.dispatch(updateContactResource({
                user: LocalStorage.get('user'),
                params: {
                    ContactGroupID: this.props.selectedItem.ContactGroupID,
                    RemoveContacts: [item.ContactID]
                },
                resource: Resources.ContactGroups,
                piggyResource: Resources.ContactGroupContacts,
                piggySecondResource: Resources.ContactGroups,
                secondPiggyQuery: this.props.query,
                query: this.getQuery(),
                errorMessage: true,
                successMessage: this.props.translate("message.contact_group_updated")
            }))
        })
    }

    handleSubmit = (event) => {
        event && event.preventDefault()
        this.setState({fields: FieldsManager.validateFields(this.state.fields), ContactGroupNameExist: false}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                    if (this.props.selectedItem?.ContactGroupID) {
                        if (this.state.selectedTab === 'Contacts') {
                            this.props.dispatch(updateContactResource({
                                user: LocalStorage.get('user'),
                                params: {
                                    ContactGroupID: this.props.selectedItem.ContactGroupID,
                                    RemoveContacts: Object.keys(this.state.selectedReviewContacts)
                                },
                                errorMessage: true,
                                successMessage: this.props.translate("message.contact_group_updated"),
                                query: this.getQuery(),
                                resource: Resources.ContactGroups,
                                piggyResource: Resources.ContactGroupContacts,
                                piggySecondResource: Resources.ContactGroups,
                                secondPiggyQuery: this.props.query
                            }))
                            this.handleCloseReviewSelectedContactsClick()
                        } else {
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                params: Object.assign(FieldsManager.getFieldKeyValues(this.state.fields), {ContactGroupID: this.props.selectedItem.ContactGroupID}),
                                errorMessage: true,
                                successMessage: this.props.translate("message.contact_group_updated"),
                                query: this.props.query,
                                resource: Resources.ContactGroups,
                                piggyResource: Resources.ContactGroups,
                            }))
                            this.hideModal()
                        }
                    } else {
                        this.props.dispatch(createResource({
                            user: LocalStorage.get('user'),
                            errorMessage: true,
                            successMessage: this.props.translate("message.contact_group_saved"),
                            params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                                AddContacts: Object.keys(this.state.selectedContactsCreate) ?? []
                            }),
                            query: this.props.query,
                            resource: Resources.ContactGroups,
                            piggyResource: Resources.ContactGroups
                        }))
                        this.hideModal()
                    }
                } else {
                    this.setState({selectedTab: 'Info'})
                }
            },
        )
    }

    /** Fields/Data Definitions
     ================================================================= */
    getFields = () => {
        const item = this.props.selectedItem ?? {};

        return {
            ContactGroupName: new Field('ContactGroupName', item ? item.ContactGroupName : this.props.prefilled ? this.props.prefilled : '', ['empty']),
            ContactGroupDesc: new Field('ContactGroupDesc', item ? item.ContactGroupDesc : '', []),
            ContactID: new Field('ContactID', item?.ContactGroupID? {
                value: item.ContactID,
                label: item.ContactGroupManager
            } : '', [], false, 'select-search'),
            ContactGroupEmail: new Field('ContactGroupEmail', item ? item.ContactGroupEmail : '', [], false, 'text'),
            IsCreateBoard: new Field('IsCreateBoard', '', [], false, 'checkbox'),
            ColorTag: new Field('ColorTag', item ? item?.ColorTag : "", [], false, 'text')
        }
    }

    /** Query Definitions
     ================================================================= */
    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            limit: new Field('limit', 5, [''], false, 'select', {hideLabel: true, labelType: "float"})
        }
    }

    getQueryFilterFieldsCreate = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            limit: new Field('limit', 5, [''], false, 'select', {hideLabel: true, labelType: "float"})
        }
    }

    getQuery = () => {
        return {
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,

            limit: this.state.queryFilterFields.limit.value,
            query: this.state.queryFilterFields.query.value,
            ...!!this.props.selectedItem?.ContactGroupID && {id: this.props.selectedItem.ContactGroupID}
        }
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value), canSubmit: true})
    }

    handleFilterInputChange = (name, value) => {
        const queryFields = FieldsManager.updateField(this.state.queryFilterFields, name, value)

        this.setState({
            queryFilterFields: queryFields,
            offset: 0,
            paginationPage: 1,
        }, () => {
            this.fetchData();
        })
    }

    handleSelectContact = (row, id) => {

        const isAddContacts = this.state.isAllContactsModalOpen
        const selectedContacts = cloneDeep(this.state.selectedContacts);
        const selectedContactsToAdd = cloneDeep(this.state.selectedContactsToAdd);
        const selectedContactsToRemove = cloneDeep(this.state.selectedContactsToRemove);

        if (!!selectedContacts[id]) {
            delete selectedContacts[id];
            isAddContacts ?
                delete selectedContactsToAdd[id]
                :
                delete selectedContactsToRemove[id]
        } else {
            selectedContacts[id] = row;
            isAddContacts ?
                selectedContactsToAdd[id] = row
                :
                selectedContactsToRemove[id] = row
        }

        this.setState({
            selectedContacts,
            selectedContactsToAdd,
            selectedContactsToRemove
        });
    }

    handleSelectContactCreate = (row, id) => {
        const selectedContactsCreate = cloneDeep(this.state.selectedContactsCreate);

        if (!!selectedContactsCreate[id]) {
            delete selectedContactsCreate[id];
        } else {
            selectedContactsCreate[id] = row
        }

        this.setState({
            selectedContactsCreate
        })
    }

    handleSelectAllContacts = (areAllSelected) => {
        const isAddContacts = this.state.isAllContactsModalOpen
        const prop = isAddContacts ? this.props.dialogResource : this.props.contactResource

        const selectedContacts = cloneDeep(this.state.selectedContacts);
        const selectedContactsToAdd = cloneDeep(this.state.selectedContactsToAdd);
        const selectedContactsToRemove = cloneDeep(this.state.selectedContactsToRemove);

        getProp(prop, 'data.list', []).forEach(item => {
            if (!areAllSelected) {
                selectedContacts[item.ContactID] = item;
                isAddContacts ?
                    selectedContactsToAdd[item.ContactID] = item
                    :
                    selectedContactsToRemove[item.ContactID] = item;
            } else {
                delete selectedContacts[item.ContactID];
                isAddContacts ?
                    delete selectedContactsToAdd[item.ContactID]
                    :
                    delete selectedContactsToRemove[item.ContactID]
            }
        });

        this.setState({
            selectedContacts,
            selectedContactsToAdd,
            selectedContactsToRemove
        });
    }

    handleSelectReviewContact = (row, id) => {
        const selectedReviewContacts = cloneDeep(this.state.selectedReviewContacts);

        if (!!selectedReviewContacts[id]) {
            delete selectedReviewContacts[id];
        } else {
            selectedReviewContacts[id] = row;
        }

        this.setState({
            selectedReviewContacts,
            canSubmit: true
        });
    }

    handleSelectAllReviewContact = (areAllSelected) => {
        const selectedReviewContacts = cloneDeep(this.state.selectedReviewContacts);

        getProp(this.props.contactResource, 'data.list', []).map(item => {
            if (!areAllSelected) {
                selectedReviewContacts[item.ContactID] = item;
            } else {
                delete selectedReviewContacts[item.ContactID];
            }
        });

        this.setState({
            selectedReviewContacts,
            canSubmit: true
        });
    }

    handleOpenReviewSelectedContactsClick = () => {
        this.setState({
            isViewContactsDialogOpen: true,
            reviewContacts: cloneDeep(this.state.selectedContactsToRemove),
            selectedReviewContacts: cloneDeep(this.state.selectedContactsToRemove)
        })
    }

    handleCloseReviewSelectedContactsClick = () => {
        this.setState({
            isViewContactsDialogOpen: false,
            reviewContacts: {},
            selectedReviewContacts: {},
            selectedContactsToRemove: {},
            canSubmit: false
        })
    }

    handleToggleAllContactsModal = () => {
        this.setState({
            isAllContactsModalOpen: !this.state.isAllContactsModalOpen,
            selectedContacts: {},
            selectedContactsToAdd: {}
        })
    }

    handleRemoveContactsModal = () => {
        this.setState({
            isRemoveContactsConfirmModalOpen: !this.state.isRemoveContactsConfirmModalOpen
        })
    }

    handleToggleRemoveSingleContactConfirmModal = (item) => {
        this.setState({
            isRemoveSingleContactConfirmModalOpen: !this.state.isRemoveSingleContactConfirmModalOpen,
            selectedContact: item
        })
    }

    handleUpdateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateQueryCreate = (name, value) => {
        if (name === 'offset') {
            this.setState({
                createGroupContactsOffset: value,
            })
        } else {
            this.setState({
                createQueryFilterFields: FieldsManager.updateField(this.state.createQueryFilterFields, name, value),
                createGroupContactsOffset: 0,
            })
        }
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchData()
        })
    }

    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    hideModal = () => {
        this.setState({
            selectedContactsToAdd: {},
            selectedContactsToRemove: {},
            selectedContactsCreate: {},
            selectedColor: null
        })
        this.props.close();
    }

    /** Helpers
     ================================================================= */
    getTabs = () => {
        return [
            {name: "Info", resource: "Info", visible: true, current: true},
            {name: "Contacts", resource: "Contacts", visible: true, current: false}
        ]
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, isOpen, contactResource} = this.props

        const isEditMode = !!this.props.selectedItem?.ContactGroupID

        const data = getProp(contactResource, 'data.list', [])
        const count = getProp(contactResource, 'data.count', 0)
        const isLoading = getProp(contactResource, 'isLoading', false)

        const createGroupContactsData = Object.values(this.state.selectedContactsCreate)
            .filter(it => it.FirstName.toLowerCase().includes(this.state.createQueryFilterFields.query.value.toLowerCase())
                || it.LastName.toLowerCase().includes(this.state.createQueryFilterFields.query.value.toLowerCase())
                || it.Email.toLowerCase().includes(this.state.createQueryFilterFields.query.value.toLowerCase()))
            .slice(Number(this.state.createGroupContactsOffset), (Number(this.state.createGroupContactsOffset) + Number(this.state.createQueryFilterFields.limit.value)))

        const createGroupContactsCount = Object.keys(this.state.selectedContactsCreate).length

        const selectedContactsCount = Object.keys(this.state.selectedContactsToRemove)?.length ?? 0;


        return (
            <React.Fragment>
                <ModalDefault
                    show={isOpen}
                    widthClass={"max-w-3xl"}
                    title={translate(`text.${isEditMode ? 'Edit' : 'Create'}`) + " " + translate('text.ContactGroup')}

                    onButtonClick={this.handleSubmit}
                    buttonLabel={(isEditMode && this.state.selectedTab === 'Info') || !isEditMode ? translate("btn.save") : null}
                    buttonDisabled={!this.state.canSubmit}

                    secondaryButtonLabel={this.state.selectedTab === 'Contacts' && isEditMode ?
                        <span className={selectedContactsCount ? "pr-8" : undefined}>
                            {translate('btn.delete_contacts')}
                            {!!selectedContactsCount && (
                                <div
                                    className="absolute right-0 top-0 bottom-0 w-10 bg-inverse border-l border-primary rounded-r-btn flex items-center justify-center">
                                <span className="leading-5 text-xs">
                                    {selectedContactsCount}
                                </span>
                                </div>
                            )}
                        </span>
                        : null
                    }
                    secondaryButtonDisabled={!selectedContactsCount}
                    onSecondaryButtonClick={this.handleOpenReviewSelectedContactsClick}
                    onClose={this.hideModal}
                    closeButtonLabel={translate("btn.cancel")}
                    translate={translate}
                >

                    <>
                        {!!this.state.ContactGroupNameExist &&
                            <div className="col-md-12">
                                <div className="col-md-12 pt-4">
                                    <div className="alert alert-danger rounded display-space-between">
                                        {translate(this.props.resource.errorMessage)}
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="mb-5">
                            <div className="hidden sm:block relative z-0">
                                <NavResponsive
                                    tabs={this.state.tabs}
                                    onTabChange={this.handleTabChange}
                                    translate={translate}
                                />
                            </div>

                            <div>
                                <MobileTabs
                                    tabs={this.state.tabs}
                                    onTabChange={this.handleTabChange}
                                    translate={translate}
                                />
                            </div>
                        </div>

                        <div className="p-6">
                            {this.state.selectedTab === "Info" && (
                                <>
                                    <div className="grid grid-cols-1 gap-4">
                                        <FieldContainer
                                            item={this.state.fields.ContactGroupName}
                                            translate={translate}
                                        >
                                            <FieldText
                                                autoFocus
                                                addClass="form-control"
                                                onChange={this.handleInputChange}
                                                {...this.state.fields.ContactGroupName}
                                                placeholder={''}/>
                                        </FieldContainer>


                                        <FieldContainer
                                            label={translate("field.contact_group_desc")}
                                            translate={translate}
                                        >
                                            <FieldTextarea
                                                addClass="form-control whitespace-pre-wrap"
                                                onChange={this.handleInputChange}
                                                {...this.state.fields.ContactGroupDesc}
                                                rows={5}
                                                placeholder={''}/>
                                        </FieldContainer>


                                        <FieldContainer
                                            label="Select group manager"
                                            translate={translate}
                                        >
                                            <FieldDropdownSelect
                                                onChange={this.handleInputChange}
                                                {...this.state.fields.ContactID}
                                                addClass="form-control"
                                                defaultOptions={true}
                                                value={this.state.fields.ContactID.value}
                                                isClearable={this.state.fields.ContactID?.value?.value ? true : false}
                                                loadOptions={
                                                    (inputValue, callback) => {
                                                        axios.get(
                                                            Env.getApiUrl('api/' + Resources.ContactsQuick, {
                                                                query: inputValue,
                                                                ...DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                                                IsSystemUser: 1
                                                            }),
                                                            {
                                                                headers: {
                                                                    'Authorization': 'Bearer ' + getJWT().access_token
                                                                }
                                                            }
                                                        )
                                                            .then((response) => {
                                                                const result = processResponse(response)
                                                                if (result && result.status === 0) {
                                                                    const list = result.data.list.map((it) => {
                                                                        if (this.state.fields.ContactID && it.ContactID === this.state.fields.ContactID.value) {
                                                                            this.setState({
                                                                                fields: FieldsManager.updateField(this.state.fields, 'ContactID', {
                                                                                    label: `${it.FirstName} ${it.LastName} - ${it.Email}`,
                                                                                    value: it.ContactID
                                                                                })
                                                                            })
                                                                        }
                                                                        return {
                                                                            label: `${it.FirstName} ${it.LastName} - ${it.Email}`,
                                                                            value: it.ContactID
                                                                        }
                                                                    })
                                                                    callback(list)
                                                                }
                                                            })
                                                    }
                                                }
                                            />
                                        </FieldContainer>


                                        <FieldContainer
                                            label={translate('field.ContactGroupEmail')}
                                            translate={translate}
                                        >
                                            <FieldText
                                                autoFocus
                                                addClass="form-control"
                                                onChange={this.handleInputChange}
                                                {...this.state.fields.ContactGroupEmail}
                                                placeholder={''}/>
                                        </FieldContainer>

                                        {!isEditMode && (
                                            <label
                                                className="h-10 flex items-center justify-start px-2 -ml-2 rounded-lg hover:bg-tm-gray-50 cursor-pointer">
                                                <FieldCheckbox
                                                    className="checkbox"
                                                    onChange={this.handleInputChange}
                                                    {...this.state.fields.IsCreateBoard}
                                                />

                                                <span className="ml-2 text-sm select-none">
                                                    {translate('field.IsCreateBoardField')}
                                                </span>
                                            </label>
                                        )}

                                        <div>
                                            <div className={"text-tm-gray-900 font-bold"}>Group color:</div>
                                            <ColorPickerUserColors
                                                clear={true}
                                                selectedColor={this.state.fields.ColorTag?.value}
                                                setSelectedColor={(val) => this.handleInputChange("ColorTag", val)}
                                                translate={translate}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}


                            {this.state.selectedTab === 'Contacts' && isEditMode && (
                                <div className="">
                                    <PageHeader
                                        className="flex pb-4"
                                        title={translate("title.group_members")}
                                        onButtonClick={this.handleToggleAllContactsModal}
                                        buttonLabel={translate("btn.add_contacts")}
                                    />

                                    <TableCard addClass={'rounded-none'}>
                                        <TableFilters
                                            hideLimit
                                            filterFields={this.state.queryFilterFields}
                                            handleInputChange={this.handleFilterInputChange}
                                            translate={translate}
                                        />

                                        <ResourceTable
                                            commonTable={true}

                                            data={data}
                                            count={count}

                                            tableKey={"ContactID"}
                                            onSelectRow={this.handleSelectContact}
                                            onSelectAllClick={this.handleSelectAllContacts}
                                            selectedRows={this.state.selectedContactsToRemove}

                                            onRowClick={(row) => this.handleSelectContact(row, row.ContactID)}

                                            onDelete={(item) => this.handleToggleRemoveSingleContactConfirmModal(item)}

                                            fields={{
                                                FirstName: new Field('FirstName', '', ['empty']),
                                                LastName: new Field('LastName', '', ['empty']),
                                                Email: new Field('Email', '', ['empty', 'email']),
                                                Title: new Field('Title', '', ['empty']),

                                            }}

                                            translate={translate}
                                            isLoading={isLoading}

                                            limit={this.state.queryFilterFields.limit.value}
                                            offset={this.state.offset}
                                            page={this.state.paginationPage}
                                            paginationButtonLimit={5}
                                            onOffsetChange={this.handleUpdateOffset}

                                            sort={this.state.sort}
                                            sortBy={this.state.sortBy}
                                            onSortChange={this.handleUpdateSort}
                                            options={{
                                                style: {
                                                    floatingActions: false,
                                                    stripedRows: true
                                                }
                                            }}
                                        />

                                        <NoRecords
                                            addClass={"mt-5"}
                                            show={(data.length === 0) && !isLoading}
                                            title={translate('text.no_records')}
                                        />

                                        <TableCardFooter
                                            show={!(!data.length && !isLoading)}
                                        >
                                            <Pagination
                                                count={count}
                                                isLoading={isLoading}
                                                handleQueryChange={
                                                    (name, value, currentPage) => name === "offset"
                                                        ? this.handleUpdateOffset(value, currentPage)
                                                        : this.handleFilterInputChange(name, value)
                                                }
                                                pageOffset={this.state.offset}
                                                queryFields={this.state.queryFilterFields}
                                                translate={translate}
                                            />
                                        </TableCardFooter>
                                    </TableCard>
                                </div>
                            )}

                            {this.state.selectedTab === 'Contacts' && !isEditMode && (
                                <div>
                                    <PageHeader
                                        className="flex pb-4"
                                        title={translate("title.group_members")}
                                        onButtonClick={this.handleToggleAllContactsModal}
                                        buttonLabel={translate("btn.add_contacts")}
                                    />

                                    <TableCard>
                                        <TableFilters
                                            hideLimit
                                            filterFields={this.state.createQueryFilterFields}
                                            handleInputChange={this.handleUpdateQueryCreate}
                                            translate={translate}
                                        />

                                        <ResourceTable
                                            commonTable={true}

                                            data={createGroupContactsData}
                                            count={createGroupContactsCount}

                                            hideSelectAll={true}

                                            tableKey={"ContactID"}
                                            onSelectRow={this.handleSelectContactCreate}
                                            selectedRows={this.state.selectedContactsCreate}

                                            onRowClick={(row) => this.handleSelectContactCreate(row, row.ContactID)}

                                            fields={{
                                                FirstName: new Field('FirstName', '', ['empty']),
                                                LastName: new Field('LastName', '', ['empty']),
                                                Email: new Field('Email', '', ['empty', 'email']),
                                                Title: new Field('Title', '', ['empty'])
                                            }}

                                            translate={translate}
                                            isLoading={isLoading}

                                            limit={this.state.createQueryFilterFields.limit.value}
                                            offset={this.state.createGroupContactsOffset}
                                            page={this.state.createGroupContactsPaginationPage}
                                            paginationButtonLimit={5}
                                            onOffsetChange={this.handleUpdateOffset}

                                            sort={this.state.sort}
                                            sortBy={this.state.sortBy}
                                            onSortChange={this.handleUpdateSort}
                                            options={{
                                                style: {
                                                    floatingActions: false,
                                                    stripedRows: true
                                                }
                                            }}
                                        />

                                        <NoRecords
                                            addClass={"mt-5"}
                                            show={(createGroupContactsData.length === 0) && !isLoading}
                                            title={translate('text.no_records')}
                                        />

                                        <TableCardFooter
                                            show={!(!createGroupContactsData.length && !isLoading)}
                                        >
                                            <Pagination
                                                count={this.state.createQueryFilterFields.query.value ? createGroupContactsData.length : createGroupContactsCount}
                                                isLoading={isLoading}
                                                handleQueryChange={
                                                    (name, value) => this.handleUpdateQueryCreate(name, value)
                                                }
                                                pageOffset={this.state.createGroupContactsOffset}
                                                queryFields={this.state.createQueryFilterFields}
                                                translate={translate}
                                            />
                                        </TableCardFooter>
                                    </TableCard>
                                </div>
                            )}

                            {/* All contacts */}
                            <ResourceTableDialog
                                show={this.state.isAllContactsModalOpen}
                                dialogResource={this.props.dialogResource}
                                resource={Resources.ContactsQuick}
                                title={translate("btn.add_contacts")}
                                dispatch={this.props.dispatch}

                                fields={{
                                    FirstName: new Field('FirstName', '', [''], false),
                                    LastName: new Field('LastName', '', [''], false),
                                    Email: new Field('Email', '', [''], false),
                                    Title: new Field('Title', '', [''], false)
                                }}

                                fieldsFilter={{
                                    query: new Field('query', '', [''], false, 'search', {addContainerClass: 'col-span-3'}, {innerRef: this.splitDispatchDialogRef}),
                                    ContactGroupID: new Field('ContactGroupID', '', [''], false, 'select-search', {addContainerClass: 'col-span-3'}, {isClearable: true})
                                }}

                                defaultQuery={{ExcludeIDs: isEditMode ? data.map(it => it.ContactID).join(", ") : Object.values(this.state.selectedContactsCreate).map(it => it.ContactID).join(", ")}}

                                defaultAction={(row) => this.handleSelectContact(row, row.ContactID)}

                                tableKey={"ContactID"}
                                onSelectRow={this.handleSelectContact}
                                onSelectAllClick={this.handleSelectAllContacts}
                                selectedRows={this.state.selectedContactsToAdd}
                                selects={{
                                    ContactGroupID: {
                                        api: 'api/' + Resources.ContactGroupsQuick,
                                        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                        searchMap: (item) => ({
                                            label: item.ContactGroupName,
                                            value: item.ContactGroupID
                                        })
                                    }
                                }}


                                buttonLabel={translate('btn.add_selected')}
                                buttonDisabled={!Object.keys(this.state.selectedContactsToAdd).length}
                                onButtonClick={this.handleAddContacts}

                                onClose={() => this.setState({isAllContactsModalOpen: false})}
                                translate={translate}
                                commonTable={true}
                            />

                            <ModalConfirm
                                title={translate("Confirm")}
                                show={this.state.isRemoveContactsConfirmModalOpen}
                                text={translate("message.are_you_sure_remove_contacts")}
                                onClose={this.handleRemoveContactsModal}
                                buttonLabel={translate("btn.confirm")}
                                closeButtonLabel={translate('Cancel')}
                                translate={translate}
                                onConfirm={() => {
                                    this.handleSubmit();
                                    this.handleRemoveContactsModal()
                                }}
                            />

                            <ModalDefault
                                show={this.state.isViewContactsDialogOpen}
                                widthClass={"max-w-xl"}
                                translate={translate}
                                title={translate("modal_heading.review_selected_contacts")}
                                buttonLabel={translate("btn.delete_selected")}
                                onButtonClick={this.handleRemoveContactsModal}
                                buttonDisabled={false}
                                onClose={this.handleCloseReviewSelectedContactsClick}
                                closeButtonLabel={translate("btn.cancel")}
                            >
                                <ResourceTable
                                    commonTable={true}
                                    data={Object.values(this.state.reviewContacts)}
                                    count={count}

                                    tableKey={"ContactID"}
                                    onSelectRow={this.handleSelectReviewContact}
                                    selectedRows={this.state.selectedReviewContacts}
                                    onSelectAllClick={this.handleSelectAllReviewContact}

                                    fields={{
                                        FirstName: new Field('FirstName', '', ['empty']),
                                        LastName: new Field('LastName', '', ['empty']),
                                        Email: new Field('Email', '', ['empty', 'email']),
                                    }}
                                    translate={this.props.translate}

                                    options={{
                                        style: {
                                            floatingActions: false,
                                            stripedRows: true
                                        }
                                    }}
                                />
                            </ModalDefault>

                            <ModalConfirm
                                title={translate("Confirm")}
                                show={this.state.isRemoveSingleContactConfirmModalOpen}
                                text={translate("message.are_you_sure_remove_contact")}
                                onClose={this.handleToggleRemoveSingleContactConfirmModal}
                                buttonLabel={translate("btn.confirm")}
                                closeButtonLabel={translate('Cancel')}
                                translate={translate}
                                onConfirm={() => {
                                    this.handleRemoveSingleContactFromGroup(this.state.selectedContact);
                                    this.handleToggleRemoveSingleContactConfirmModal()
                                }}
                            />
                        </div>
                    </>
                </ModalDefault>
            </React.Fragment>
        )
    }
}

export default connect(state => state)(CreateContactGroupsDialog)
