import {useMemo, useState} from "react";
import {getDefaultTableOptions, saveTableColumns} from "../util/util-helpers";

export default function useTableOptions(defaultTableFields, key = undefined, tableDefaults = {}, translate) {
    const defaultOptions = useMemo(
        () => getDefaultTableOptions(defaultTableFields, tableDefaults, key, translate),
        [defaultTableFields, key, tableDefaults, translate]
    );

    const [options, setOptions] = useState(defaultOptions);

    function saveOptions(optionsToSave) {
        if (key) {
            saveTableColumns(key, optionsToSave);
        }

        setOptions(() => optionsToSave);
    }

    return [options, saveOptions, defaultOptions];
}

