import {useState} from "react";
import LocalStorage from "../util/localStorage";
import {fillFieldsFromData} from "../common/util/util-fields";
// Version 1.0.0

// The purpose of this new hook is to simplify the process of storing and retrieving filters.
// It aims to make the implementation more straightforward and user-friendly.
// Update all legacy hooks to use this new one, ensuring backward compatibility throughout the transition.

// The `fallback` flag indicates that the old query data structure is being used.
// If true, the data will be converted and saved using the new data structure.

export default function useQueryNew(defaultFields, key = undefined, fallback = false) {
    let savedQuery;
    if (fallback) {
        savedQuery = getSavedQueryLegacy(defaultFields, key);
    } else {
        savedQuery = getSavedQuery(defaultFields, key);
    }

    const [query, setQuery] = useState(savedQuery ? savedQuery : defaultFields)

    function saveQuery(query, updatedKey = undefined) {
        if ((updatedKey ?? key) && updatedKey !== null) {
            saveCurrentQuery(query, updatedKey ?? key)
        }

        setQuery(() => query);
    }

    return [query, saveQuery];
}

const getSavedQuery = (defaultFields, key) => {
    if (!key) {
        return undefined;
    }

    const storeKey = key + "_query";

    let storedQuery = LocalStorage.get(storeKey) ?? {};
    return Object.values(defaultFields).reduce((memo, it) => {
        if (storedQuery[it.name]) {
            it.value = storedQuery[it.name];
        }

        memo[it.name] = it;
        return memo;
    }, {});
}

const saveCurrentQuery = (query, key) => {
    const storageKey = key + "_query";

    const queryToStore = Object.values(query).reduce((memo, it) => {
        memo[it.name] = it.value;

        if (memo[it.name]?.value?.metadata) {
            delete memo[it.name]?.value?.metadata;
        }

        return memo;
    }, {});

    queryToStore.metadata = Object.assign(queryToStore?.metadata ?? {}, {version: 1})

    LocalStorage.set(storageKey, queryToStore);
}

// Legacy Function
const getSavedQueryLegacy = (defaultFields, key) => {
    if (!key) {
        return undefined;
    }

    const storeKey = key + "_query";
    let storedQuery = LocalStorage.get(storeKey) ?? {};

    if (storedQuery?.metadata?.version === 1) { // if legacy is already updated
        return getSavedQuery(defaultFields, key);
    }

    return fillFieldsFromData(defaultFields, storedQuery);
}