import {useDispatch, useSelector} from "react-redux";
import React from "react";
import ResourceListTab from "../resource-list-tab";
import {createDataSelect} from "../../../../data/selectors/resourceSelectors";
import {Field} from "../../../../data/services/fields";
import {DEFAULT_QUERY_LIMIT} from "../../../../util/util-constants";

export default function ExpiryItemsTab({translate, resourceName, Reference, ReferenceID, ReferenceType}) {
    /** Store
     ================================================================= */
    const resource = useSelector((state) => state.resource);
    const user = useSelector((state) => state.user);

    /** Constants
     ================================================================= */
    const dispatch = useDispatch();
    const createData = createDataSelect({dispatch: dispatch, user: user.data})

    /** Fields/Data definitions
     ================================================================= */
    const getFields = () => {
        return {
            Description: new Field('Description', '', ['empty'], false, 'textarea', {
                addContainerClass: 'col-span-full'
            }),
            Date: new Field('Date', '', ['empty'], false, 'date', {
                addContainerClass: 'col-span-full'
            })
        }
    }

    /** Helpers
     ================================================================= */
    const getPrimaryKey = () => {
        return 'CustomExpiryID'
    }

    const getResourceName = () => {
        return resourceName ?? ''
    }

    /** Render
     ================================================================= */
    return (
        <div>
            <ResourceListTab
                dispatch={dispatch}
                translate={translate}
                resource={resource}

                tabKey={getResourceName() + "ExpiryItemsListTab"}
                sortBy={'CreateUpdateDate'}
                primaryKey={getPrimaryKey()}
                resourceName={getResourceName()}
                fields={getFields()}

                additinonalQuery={{
                    searchFields: JSON.stringify({
                        ReferenceID: ReferenceID,
                        ReferenceType: ReferenceType
                    })
                }}

                searchFields={{
                    query: new Field('query', '', [''], false, 'search', {
                    }),
                    limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                        hideLabel: true,
                    })
                }}

                createDataCallback={(data) => {
                    data.params = Object.assign({}, data.params, {
                        Reference: Reference,
                        ReferenceID: ReferenceID,
                        ReferenceType: ReferenceType
                    })
                    createData(data)
                }}
                addClassTableCard={"mt-2"}
            />
        </div>
    )
}
