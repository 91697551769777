import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../../data/services/resources'
import BalanceSheetTab from './balance-sheet'
import IncomeStatementTab from './income-statement'
import AccountingDashboardTab from './dashboard-widget'
import {READ_PERM} from '../../../util/util-constants'
import {checkPerm} from '../../../common/util/util-helpers'
import CustomerAging from './customer-aging'
import AdvancesTab from './advances-tab'
import CommissionsTab from './commisions-tab'
import AccountAuditTab from './account-audit-tab'
import CustomerStatement from './customer-statement'
import ArAging from './ar-aging'
import UnpaidDispatches from "./unpaid-dispatches";
import UnpaidCommissions from "./unpaid-commissions";
import UnpaidCarriers from "./unpaid-carriers";
import Accounting1099ReportsView from "./accounting-1099-reports";
import Accounting1096ReportsView from "./accounting-1096-reports";
import ApAging from './ap-aging'
import VendorPayments from './vendor-payments'
import CustomerRevenue from './customer-revenue'
import TrialBalances from './trial-balances'
import Page from "../../../common/components/layout/layout-components/page";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Layout from "../../../common/components/layout";
import NavResponsive from "../../../common/components/nav-responsive";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import InventoryReport from "./inventory-report";
import LiabilitiesTab from "./liabilities-tab";
import AssetsReportTab from "./asset-tab";
import PaymentAging from "./payment-aging";
import DriverNetGross from "./driver-net-gross";

class AccountingReportsPage extends Component {
    constructor(props) {
        super(props)
        const tabs = [
            {
                name: 'accounting_dashboard',
                resource: Resources.AccountingDashboard,
                current: false,
                visible: true
            },
            {
                name: 'balance_sheet',
                resource: Resources.AccountingBalanceSheet,
                current: false,
                visible: true
            },
            {
                name: 'income_statement',
                resource: Resources.AccountingIncomeStatement,
                current: false,
                visible: true
            },
            {
                name: 'account_audit',
                resource: Resources.AccountingAccountReport,
                current: false,
                visible: true
            },
            {
                name: 'aging',
                resource: Resources.AccountingReportsARAging,
                current: false,
                visible: true
            },
            {
                name: 'customer_aging',
                resource: Resources.AccountingReportsAging,
                current: false,
                visible: true
            },
            {
                name: 'customer_statement',
                resource: Resources.AccountingReportsCustomerStatement,
                current: false,
                visible: true
            },
            {
                name: 'payment_aging',
                resource: Resources.AccountingReportsARPayments,
                current: false,
                visible: true
            },
            {
                name: 'advances',
                resource: Resources.AccountingAccountAdvances,
                current: false,
                visible: true
            },
            {
                name: 'liabilities',
                resource: Resources.AccountingReportsLiabilities,
                current: false,
                visible: true
            },
            {
                name: 'assets',
                resource: Resources.AccountingReportsAsset,
                current: false,
                visible: true
            },
            {
                name: 'commissions',
                resource: Resources.CommissionReport,
                current: false,
                visible: true
            },
            {
                name: 'vendor_payments',
                resource: Resources.AccountingReportsVendorPayments,
                current: false,
                visible: true
            },
            {
                name: 'revenue_customer',
                resource: Resources.AccountingReportsRevenueCustomer,
                current: false,
                visible: true
            },
            {
                name: 'ap_aging',
                resource: Resources.AccountingReportsAPAging,
                current: false,
                visible: true
            },
            {
                name: 'unpaid_commissions',
                resource: Resources.UnpaidCommissions,
                current: false,
                visible: true
            },
            {
                name: 'unpaid_dispatches',
                resource: Resources.UnpaidDispatches,
                current: false,
                visible: true
            },
            {
                name: 'unpaid_carriers',
                resource: Resources.UnpaidCarriers,
                current: false,
                visible: true
            },
            {
                name: 'accounting_1099',
                resource: Resources.Accounting1099Reports,
                current: false,
                visible: true
            },
            {
                name: 'accounting_1096',
                resource: Resources.Accounting1096Reports,
                current: false,
                visible: true
            },
            {
                name: 'trial_balances',
                resource: Resources.AccountingReportsTrialBalances,
                current: false,
                visible: true
            },
            {
                name: 'inventory_report',
                resource: Resources.ProductServiceReport,
                current: false,
                visible: true
            },
            {
                name: 'driver_gross',
                resource: Resources.AccountingReportsDriverNetGross,
                current: false,
                visible: true
            },
        ]
            .map((it) => {
            it.visible = checkPerm(it.resource, READ_PERM)
            return it
        })

        const currentTab = this.getCurrentTab(tabs)

        tabs.map(it => {
            it.current = it.resource === currentTab

            return it
        })

        this.state = {
            selectedTab: currentTab,
            tabs: tabs
        }
    }

    /** UI events
     ================================================================= */
    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        this.props.history.push('?tab=' + resource)
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    /** Helpers
     ================================================================= */
    getCurrentTab = (tabs) => {
        const query = new URLSearchParams(this.props.location.search)
        const tab = query.get('tab')

        const currentTab = tabs.find(it => tab === it.resource)?.resource

        return currentTab ?? Resources.AccountingDashboard
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, dispatch, resource, secondResource, thirdResource} = this.props

        return (
            <Layout
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('text.accounting_reports')}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={dispatch}
                            />
                        )}
                    />

                    <div className="mb-5 hidden sm:block relative z-[33]">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="mb-5">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    {this.state.selectedTab === Resources.AccountingDashboard && (
                        <AccountingDashboardTab
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}
                            secondResource={secondResource}
                            thirdResource={thirdResource}
                            dialogResource={this.props.dialogResource}
                        />
                    )}
                    {this.state.selectedTab === Resources.AccountingBalanceSheet && (
                        <BalanceSheetTab
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}

                            secondResource={secondResource}
                            thirdResource={thirdResource}
                            dialogResource={this.props.dialogResource}
                        />
                    )}
                    {this.state.selectedTab === Resources.AccountingIncomeStatement && (
                        <IncomeStatementTab
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}
                            secondResource={secondResource}
                            thirdResource={thirdResource}
                            dialogResource={this.props.dialogResource}
                        />
                    )}

                    {this.state.selectedTab === Resources.AccountingAccountReport && (
                        <AccountAuditTab
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}
                            secondResource={secondResource}
                            thirdResource={thirdResource}
                            dialogResource={this.props.dialogResource}
                            location={this.props.location}
                            info={this.props.info}
                        />
                    )}

                    {this.state.selectedTab === Resources.AccountingReportsARAging && (
                        <ArAging
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}
                            secondResource={secondResource}
                            thirdResource={thirdResource}
                        />
                    )}

                    {this.state.selectedTab === Resources.AccountingReportsAging && (
                        <CustomerAging
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}
                            secondResource={secondResource}
                            thirdResource={thirdResource}
                        />
                    )}

                    {this.state.selectedTab === Resources.AccountingReportsCustomerStatement && (
                        <CustomerStatement
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}
                            secondResource={secondResource}
                            thirdResource={thirdResource}
                        />
                    )}

                    {this.state.selectedTab === Resources.AccountingReportsARPayments && (
                        <PaymentAging
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}
                            secondResource={secondResource}
                            thirdResource={thirdResource}
                        />
                    )}


                    {this.state.selectedTab === Resources.AccountingAccountAdvances && (
                        <AdvancesTab
                            resourceName={this.state.selectedTab}
                            translate={translate}
                        />
                    )}

                    {this.state.selectedTab === Resources.AccountingReportsLiabilities && (
                        <LiabilitiesTab
                            resourceName={this.state.selectedTab}
                            translate={translate}
                        />
                    )}

                    {this.state.selectedTab === Resources.AccountingReportsAsset && (
                        <AssetsReportTab
                            resourceName={this.state.selectedTab}
                            location={this.props.location}
                            translate={translate}
                        />
                    )}

                    {this.state.selectedTab === Resources.CommissionReport && (
                        <CommissionsTab
                            resourceName={this.state.selectedTab}
                            translate={translate}
                        />
                    )}

                    {this.state.selectedTab === Resources.AccountingReportsAPAging && (
                        <ApAging
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}
                            secondResource={secondResource}
                            thirdResource={thirdResource}
                        />
                    )}

                    {this.state.selectedTab === Resources.AccountingReportsVendorPayments && (
                        <VendorPayments
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}
                            secondResource={secondResource}
                            thirdResource={thirdResource}
                        />
                    )}

                    {this.state.selectedTab === Resources.AccountingReportsRevenueCustomer && (
                        <CustomerRevenue
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}
                            secondResource={secondResource}
                            thirdResource={thirdResource}
                        />
                    )}

                    {this.state.selectedTab === Resources.AccountingReportsTrialBalances && (
                        <TrialBalances
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}
                            secondResource={secondResource}
                            thirdResource={thirdResource}
                        />
                    )}

                    {this.state.selectedTab === Resources.UnpaidCommissions && (
                        <UnpaidCommissions
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}
                        />
                    )}

                    {this.state.selectedTab === Resources.UnpaidDispatches && (
                        <UnpaidDispatches
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}
                        />
                    )}

                    {this.state.selectedTab === Resources.UnpaidCarriers && (
                        <UnpaidCarriers
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}
                        />
                    )}

                    {this.state.selectedTab === Resources.Accounting1099Reports && (
                        <Accounting1099ReportsView
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}
                        />
                    )}

                    {this.state.selectedTab === Resources.Accounting1096Reports && (
                        <Accounting1096ReportsView
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}
                        />
                    )}

                    {this.state.selectedTab === Resources.ProductServiceReport && (
                        <InventoryReport
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}
                            dialogResource={this.props.dialogResource}
                            thirdResource={this.props.thirdResource}
                        />
                    )}

                    {this.state.selectedTab === Resources.AccountingReportsDriverNetGross && (
                        <DriverNetGross
                            dispatch={dispatch}
                            translate={translate}
                            resource={resource}
                        />
                    )}
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(AccountingReportsPage)
