import React, {useEffect, useState} from 'react';
import {READ_PERM} from "../../../util/util-constants";
import {Field, FieldsManager} from "../../../data/services/fields";
import {checkPerm, getDefaultTableOptions, getProp} from "../../../common/util/util-helpers";
import {getResource} from "../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {ArrowDownTrayIcon, EyeIcon} from "@heroicons/react/24/outline";
import Env from "../../../util/env";
import {download} from "../../../data/actions/download";
import {getSecondResource} from '../../../data/actions/secondResource'
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import PopoverDatePicker from "../../../common/components/popover/popover-datepicker";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ResourceTable from "../../../common/components/resource-table";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import ModalDefault from "../../../common/components/modal/modal-default";
import FileViewer from "../../../common/components/file-viewer/components"
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import {getJWT} from "../../../common/util/util-auth";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import useQueryNew from "../../../hooks/use-query-new";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";

const EmployeeSettlementTab = (props) => {

    /** Constants
     ================================================================= */
    const {translate, dispatch, EmployeeID} = props;
    const storageKey = "employee_settlement_summary";

    const [queryFields, setQueryFields] = useQueryNew(getQueryFields(), storageKey);
    const [tableOptions] = useState(() => getTableOptions());

    /** Fields/Data definitions
     ================================================================= */
    function getFields(item = null) {
        const fieldTemplates = {
            PayrollBatch: new Field('PayrollBatch', '', [], false, 'text'),
            Date: new Field('Date', '', [], false, 'date'),
            BookDate: new Field('BookDate', '', [''], false, 'date', {
                label: 'CheckPostingDate'
            }),
            Gross: new Field('Gross', '', [], false, 'money'),
            TotalAmount: new Field('TotalAmount', '', [], false, 'money', {
                label: 'NetAmount'
            }),
            PayToOrganizationID: new Field('PayToOrganizationID', '', [], false, 'custom', {
                render: (it) => {
                    return it.PayToOrganizationID ? it.PayToOrganization : ''
                }
            }),
            IncludeIn1099: new Field('IncludeIn1099', '', [], false, 'checkbox'),
            IsPayed: new Field('IsPayed', '', [], false, 'checkbox')
        }
        return fillFieldsFromData(fieldTemplates, item)
    }

    function getQueryFields() {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            StartDate: new Field('StartDate', '', [], false, 'date', {labelType: "stack"}),
            EndDate: new Field('EndDate', '', [], false, 'date', {labelType: "stack"}),
            sort: new Field('sort', 'DESC', [''], false, 'text', {}, {}),
            sortBy: new Field('sortBy', 'PayrollBatch', [''], false, 'text', {}, {}),
            offset: new Field('offset', "0", [''], false, 'text', {}, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true})
        }
    }

    /** State
     ================================================================= */
    const [selectedItem, setSelectedItem] = useState(null)

    /** Helpers
     ================================================================= */
    const getResourceName = () => {
            return Resources.EmployeesPayroll
        }

    function getQuery() {
        const query = FieldsManager.getFieldKeyValues(queryFields);
        query.searchFields = JSON.stringify({
            ...(!!query.StartDate && {StartDate: ['Date', '>=', query.StartDate]}),
            ...(!!query.EndDate && {EndDate: ['Date', '<=', query.EndDate]}),
        });
        query.EmployeeID = EmployeeID;
        delete query.StartDate;
        delete query.EndDate;

        return query;
    }

    const getCommissionsQuery = () => {
        return {
            offset: 0,
            limit: 1000
        }
    }

    function getTableOptions() {
        return getDefaultTableOptions(getFields(), {
            behaviour: {
                hasVirtualRows: true
            },
            style: {
                condensed: false,
                horizontalLines: true,
                verticalLines: false,
                headerHeightClass: 'h-8',
                floatingActions: true,
                frozenActionColumn: true,
                isGPUAccelerated: true
            },
        }, "add_entry_table", translate)
    }

    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            query: getQuery(),
            resource: getResourceName()
        }))
    }

    const fetchCommissionData = () => {
        dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            query: getCommissionsQuery(),
            resource: Resources.EmployeesCommissions
        }))
    }

    /** UI events
     ================================================================= */
    const handleQueryInputChange = (name, value) => {
        let queryFieldsUpdate = Object.assign({}, queryFields);

        if ("sortBy" === name && queryFieldsUpdate.sortBy.value === value) {
            queryFieldsUpdate.sort.value = queryFieldsUpdate.sort.value === 'DESC' ? 'ASC' : 'DESC';
        }

        queryFieldsUpdate = FieldsManager.updateField(queryFieldsUpdate, name, value);

        setQueryFields(queryFieldsUpdate)
    }

    const handleToggleViewModal = (selectedItem = null) => {
        setSelectedItem(selectedItem)
    }

    const handleClearFiltersClick = () => {
        setQueryFields(getQueryFields());
    }
    
    const downloadDocument = (item) => {
        props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.PayrollBatchesItemsDocument,
            query: {
                PayrollBatchEntryID: selectedItem?.PayrollBatchEntryID ?? item.PayrollBatchEntryID,
                type: 1,
                name: (selectedItem?.Name ?? item.Name) + ' ' + (selectedItem?.PayrollBatch ?? item.PayrollBatch) + '.pdf'
            },
            errorMessage: true, successMessage: 'Successfully downloaded payroll batch!'
        }))
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchCommissionData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [queryFields]);

    /** Render
     ================================================================= */
    const renderBatchItemPDF = (translate) => {
        return (
            <ModalDefault
                show={!!selectedItem}
                widthClass={'max-w-7xl'}
                limitHeight={true}
                title={translate("modal_heading.upload_confirm")}
                buttonLabel={translate('btn.download')}
                onButtonClick={downloadDocument}
                close={handleToggleViewModal}
                closeButtonLabel={translate('Close')}
                onClose={handleToggleViewModal}
            >
                <FileViewer
                    fileType={'pdf'}
                    filePath={Env.getApiUrl('api/' + Resources.PayrollBatchesItemsDocument, Object.assign({}, {
                        PayrollBatchEntryID: selectedItem.PayrollBatchEntryID,
                        token: getJWT().access_token,
                        type: 1
                    }))}
                    onError={(e) => {
                        console.log(e)
                    }}/>
            </ModalDefault>
        )
    }

    const data = getProp(props, 'resource.data.list', [])
    const count = getProp(props, 'resource.data.count', 0)
    const isLoading = getProp(props, 'resource.isLoading', false)

    return (
        <>
            <div className={"mb-5"}>
                <ActiveFilters
                    filterFields={queryFields}
                    onLabelClick={handleQueryInputChange}
                    onClearFiltersClick={handleClearFiltersClick}
                    translate={translate}
                />
            </div>

            <TableCard>
                <TableFilters
                    hideLimit
                    filterFields={queryFields}
                    handleInputChange={handleQueryInputChange}
                    onRefreshTable={fetchData}
                    customHeaderHtml={
                        <PopoverDatePicker
                            queryFields={queryFields}
                            onQueryInputChange={handleQueryInputChange}
                            updateFields={(fields) => setQueryFields(Object.assign({}, fields))}
                            translate={translate}
                        />
                    }
                    translate={translate}
                />

                <ResourceTable
                    data={data}
                    count={count}
                    fields={getFields()}
                    translate={translate}
                    isLoading={isLoading}
                    onSortChange={(colName) => handleQueryInputChange("sortBy", colName)}
                    queryFields={queryFields}
                    options={tableOptions}
                    onRowClick={checkPerm(Resources.PayrollBatchesItemsDocument, READ_PERM) && handleToggleViewModal}

                    actions={[
                        {
                            tooltipText: () => translate("btn.preview"),
                            action: (it) => handleToggleViewModal(it),
                            icon: () => EyeIcon,
                            hidden: () => false,
                            visible: () => checkPerm(Resources.PayrollBatchesItemsDocument, READ_PERM)
                        },
                        {
                            tooltipText: () => translate("btn.download"),
                            action: (it) => downloadDocument(it),
                            icon: () => ArrowDownTrayIcon,
                            hidden: () => false,
                            title: () => translate('btn.download'),
                            visible: () => checkPerm(Resources.PayrollBatchesItemsDocument, READ_PERM)
                        },
                    ]}
                />

                <NoRecordsTable
                    show={(data.length === 0) && !isLoading}
                    canCreate={false}
                    title={translate('text.no_matching_records')}
                    className={"pt-16 px-6"}
                />

                <TableCardFooter
                    show={!(data.length === 0) && !isLoading}>
                    <Pagination
                        count={count}
                        isLoading={isLoading}
                        handleQueryChange={
                            (name, value) => handleQueryInputChange(name, value)
                        }
                        queryFields={queryFields}
                        translate={translate}
                    />
                </TableCardFooter>
            </TableCard>

            {!isLoading && !!selectedItem && renderBatchItemPDF(translate)}
        </>
    )
}

export default EmployeeSettlementTab;