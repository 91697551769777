import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../../data/services/resources'
import {Field, FieldsManager} from '../../../data/services/fields'
import {createResource, resetResource} from '../../../data/actions/resource'
import InfoTab from './infoTab'
import LocalStorage from '../../../util/localStorage'

import {
    CREATE_PERM,
    NUMBER_OF_AXLES,
    READ_PERM,
    SCAC_CODE_FIELD_LENGTH,
    VIN_CODE_FIELD_LENGTH
} from '../../../util/util-constants'
import {
    checkPerm, getDefaultContactGroup,
    getDefaultUserOfficeValues,
    getDocumentTypesFor,
    getLookup, getPreFillFromQuery,
    getProp,
    getSubLookupFor
} from '../../../common/util/util-helpers'
import {scrollErrorIntoViewFields} from '../../../common/util/util-vanilla'
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import GroupsViewDialog from "../../my-company/groups-view/groups-view-dialog";
import Layout from "../../../common/components/layout";
import Page from "../../../common/components/layout/layout-components/page";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import NavResponsive from "../../../common/components/nav-responsive";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import RedirectDialog from "../../../common/components/modal/redirect-dialog";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import DocumentsCreateTab from "../../../common/components/tabs/documents-tab/create-documents";
import Card from "../../../common/components/card";
import ResourceCreateListTab from "../../../common/components/tabs/resource-create-list-tab";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import FieldOptions from "../../../common/components/fields/field-options";

class TruckCreateView extends Component {

    constructor(props) {
        super(props)

        const tabs = [
            {
                name: 'TrucksInfo',
                resource: Resources.TrucksInfo,
                current: true,
                visible: checkPerm(Resources.TrucksInfo, READ_PERM)
            },
            {
                name: 'TrucksPermits',
                resource: Resources.TrucksPermits,
                current: false,
                visible: checkPerm(Resources.TrucksPermits, READ_PERM)
            },
            {
                name: 'TrucksType',
                resource: Resources.TrucksType,
                current: false,
                visible: checkPerm(Resources.TrucksType, READ_PERM)
            },
            {
                name: 'RestrictedArea',
                resource: Resources.TrucksCanGo,
                current: false,
                visible: checkPerm(Resources.TrucksCanGo, READ_PERM)
            },
            {
                name: 'TruckServices',
                resource: Resources.TruckServices,
                current: false,
                visible: checkPerm(Resources.TruckServices, READ_PERM)
            },
            {
                name: 'TruckDocuments',
                resource: Resources.TruckDocuments,
                current: false,
                visible: checkPerm(Resources.TruckDocuments, CREATE_PERM)
            }
        ];

        this.state = {
            // Tabs
            tabs: tabs,
            selectedTab: Resources.TrucksInfo,

            // Fields
            fields_info: this.getFieldsInfo(),
            fields_permits: [],
            fields_types: [],
            fields_cango: [],
            fields_locations: [],
            fields_documents: [],
            fields_services: [],

            // Modals
            isRedirectDialogVisible: false,
            isGroupInfoDialogOpen: false,
            groupInfoData: {},

            canSwapTabs: true,
            footerIsSticky: true,
            emailExist: false
        }

        this.fieldsRef = {}
    }

    /** Lifecycle
     ================================================================= */
    componentDidUpdate(prevProps) {
        if (this.props.resource !== prevProps.resource && !prevProps.resource.showRedirectDialog && !!this.props.resource.showRedirectDialog) {
            this.handleToggleRedirectDialog()
        }
        if (!!this.props.resource.errorMessage && !prevProps.resource.errorMessage && (this.props.resource.errorMessage === 'EMAIL_PARAMETER_ALREADY_EXISTS')) {
            this.setState({emailExist: true, selectedTab: Resources.DriverInfo})
        }
    }

    /** Data events
     ================================================================= */
    onSubmit = () => {
        this.setState({
            fields_info: FieldsManager.validateFields(this.state.fields_info),
            canSubmit: false
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields_info)) {
                let params = Object.assign({},
                    FieldsManager.getFieldKeyValues(this.state.fields_info),
                    {
                        Permits: this.state.fields_permits,
                        Types: this.state.fields_types,
                        CanGo: this.state.fields_cango,
                        Services: this.state.fields_services,
                    }
                )
                this.props.dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: params,
                    resource: Resources.Trucks,
                    showRedirectDialog: true,
                    errorMessage: true,
                    document: this.state.fields_documents,
                    documentResource: Resources.TruckDocuments,
                }))
            } else {
                this.handleTabChange(Resources.TrucksInfo)
                scrollErrorIntoViewFields([this.state.fields_info])
            }
        })
    }

    /** UI events
     ================================================================= */
    handleFormCancel = () => {
        this.setState({
            fields_info: this.getFieldsInfo(),
            canSubmit: false
        })
    }

    handleToggleRedirectDialog = () => {
        this.setState({
            createdResourceID: this.props.resource.create && this.props.resource.create.id,
            isRedirectDialogVisible: !this.state.isRedirectDialogVisible,
            fields_info: this.getFieldsInfo(),
            fields_permits: [],
            fields_types: [],
            fields_cango: [],
            fields_locations: [],
            fields_documents: [],
            fields_services: [],
            canSubmit: false
        })
    }

    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }
        this.tabChange(resource)
    }

    tabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    handleInputChange = (name, value) => {
        const fields = FieldsManager.updateField(this.state.fields_info, name, value)
        if (name === "OwnedByID") {
            fields["OwnedByContactID"].value = ''
            fields["OwnedByOrganizationID"].value = ''

            fields["OwnedByContactID"].disabled = value === 2
            fields["OwnedByOrganizationID"].disabled = value === 1
        }

        if (name === 'OwnedByCompany') {
            fields['OwnedByID'].disabled = !!value
            fields['OwnedByContactID'].disabled = true
            fields['OwnedByOrganizationID'].disabled = true
            fields['TruckOwnershipTypeID'].disabled = !!value
            fields['OwnedByID'].value = null
            fields['OwnedByContactID'].value = null
            fields['OwnedByOrganizationID'].value  = null
            fields['TruckOwnershipTypeID'].value  = null
        }

        this.setState({canSubmit: true, fields_info: fields})
    }

    handleItemInputChange = (i, name, value) => {
        let fields = this.state.phones_info[i]
        const newItems = this.state.phones_info
        newItems[i] = FieldsManager.updateField(fields, name, value)
        this.setState({canSubmit: true, phones_info: newItems})
    }

    handleUploadDocument = (files) => {
        let fields = this.state.fields_documents
        fields = fields.concat(files)
        this.setState({fields_documents: fields})
    }

    handleDeleteDocument = (item) => {
        let fields = this.state.fields_documents.filter(it => it.preview !== item.preview)
        this.setState({fields_documents: fields})
    }

    handleGroupInfoClick = (groupData) => {
        this.setState({
            isGroupInfoDialogOpen: true,
            groupInfoData: groupData
        })
    }

    handleAddToListTab = (state, params) => {
        this.setState({[state]: [...this.state[state], params].map((item, index) => ({...item, index}))})
    }

    handleEditListTab = (state, params) => {
        const newData = this.state[state].map((item, index) => {
            item.index = index
            if (index === getProp(params, 'index', 0)) {
                item = params
            }
            return item
        })
        this.setState({[state]: newData})
    }

    handleRemoveFromListTab = (state, params) => {
        let dataTmp = [...this.state[state]]
            .filter((item, index) => getProp(params, 'index', 0) !== index)
            .map((item, index) => ({...item, index}))
        this.setState({[state]: dataTmp})
    }

    /** Fields/Data Definitions
     ================================================================= */
    getFieldsInfo = () => {
        const prefilled = getPreFillFromQuery()

        let VINPrefilled;

        const isValidJSON = str => {
            try {
                JSON.parse(str);
                return true;
            } catch (e) {
                return false;
            }
        };

        if (isValidJSON(prefilled)) {
            VINPrefilled = JSON.parse(prefilled)
        }

        return {
            SCAC: new Field('SCAC', '', [''], false, 'text', {addContainerClass: 'col-span-3 md:col-span-2'}, {
                upperCaseOnly: true,
                max: SCAC_CODE_FIELD_LENGTH
            }),
            TruckNumber: new Field('TruckNumber', !VINPrefilled?.Model ? prefilled : '', ['empty'], false, 'text', {addContainerClass: 'col-span-3 md:col-span-2'}),
            Year: new Field('Year', VINPrefilled?.Year ?? '', [''], false, 'select', {addContainerClass: 'col-span-3 md:col-span-2'}),
            TruckLicenceNumber: new Field('TruckLicenceNumber', '', [''], false, 'text', {addContainerClass: 'col-span-3'}),
            TruckLicenceStateID: new Field('TruckLicenceStateID', '', [''], false, 'select', {addContainerClass: 'col-span-3'}, {
                values: getLookup('State')
            }),
            Manufacturer: new Field('Manufacturer', VINPrefilled?.Manufacturer ?? '', [''], false, 'text', {addContainerClass: 'col-span-2'}),
            Model: new Field('Model', VINPrefilled?.Model ?? '', [''], false, 'text', {addContainerClass: 'col-span-2'}),
            Engine: new Field('Engine', VINPrefilled?.Engine ?? '', [''], false, 'text', {addContainerClass: 'col-span-2'}),
            OfficeID: new Field('OfficeID', getDefaultUserOfficeValues(), ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
            ContactGroupID: new Field('ContactGroupID', getDefaultContactGroup(), ['empty'], false, 'select-search', {
                addContainerClass: 'col-span-3', label: "FleetGroupID",
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.handleGroupInfoClick(it?.value?.metadata),
                                    isVisible: !!it.value
                                }
                            ]}
                        />
                    )
                }
            }, {
                isClearable: true,
                api: 'api/' + Resources.ContactGroupsQuick,
                query: {},
                searchMap: (it) => ({
                    label: it.ContactGroupName,
                    value: it.ContactGroupID,
                    metadata: it
                })
            }),
            VIN: new Field('VIN', VINPrefilled?.VIN ?? '', ['length_===_17'], false, 'text', {addContainerClass: 'col-span-3'}, {
                max: VIN_CODE_FIELD_LENGTH,
                upperCaseOnly: true,
                numbersAndLettersOnly: true
            }),
            CarbID: new Field('CarbID', '', [''], false, 'text', {addContainerClass: 'col-span-3'}),
            WarrantyMileage: new Field('WarrantyMileage', '', ['float_or_empty'], false, 'text', {addContainerClass: 'col-span-3'}),
            WarrantyDate: new Field('WarrantyDate', '', [''], false, 'date', {addContainerClass: 'col-span-3'}),
            InServiceDate: new Field('InServiceDate', '', [''], false, 'date', {addContainerClass: 'col-span-3'}),
            IsNonIFTA: new Field('IsNonIFTA', '', [''], false, 'checkbox', {addContainerClass: 'col-span-3'}),
            TruckUnloadedWeight: new Field('TruckUnloadedWeight', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
            TruckGrossWeight: new Field('TruckGrossWeight', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
            TruckNumberOfAxles: new Field('TruckNumberOfAxles', '', [''], false, 'select', {addContainerClass: 'col-span-3'}, {
                values: NUMBER_OF_AXLES
            }),
            TruckWidth: new Field('TruckWidth', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
            TruckHeight: new Field('TruckHeight', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),

            Notes: new Field('Notes', '', [], false, 'textarea', {
                heightClass: 'h-52',
                addContainerClass: 'col-span-3'
            }),
            AssetCompanyStatusID: new Field('AssetCompanyStatusID', '', [], false, 'select'),
            AssignmentNotes: new Field('AssignmentNotes', '', [], false, 'textarea', {
                heightClass: 'h-52',
                addContainerClass: 'col-span-3'
            }),
            TruckUtilizationStatusID: new Field('TruckUtilizationStatusID', '', [], false, 'select', {}, {isClearable: true}),
            TruckUtilizationNotes: new Field('TruckUtilizationNotes', '', [], false, 'textarea', {addContainerClass:'col-span-full'}),
            OwnedByCompany: new Field('OwnedByCompany', '', [], false, 'checkbox', {addContainerClass: 'col-span-6'}),
            TruckOwnershipTypeID: new Field('TruckOwnershipTypeID', '', [''], false, 'select', {addContainerClass: 'col-span-6'}),
            OwnedByID: new Field('OwnedByID', 1, [''], false, 'button-group', {
                data: {
                    1: this.props.translate('field.OwnedByContactID'),
                    2: this.props.translate('field.OwnedByOrganizationID')
                },
                addContainerClass: 'col-span-6',
            }),
            OwnedByContactID: new Field('OwnedByContactID', '', [''], false, 'select-search', {addContainerClass: 'col-span-3'}),
            OwnedByOrganizationID: new Field('OwnedByOrganizationID', '', [''], true, 'select-search', {addContainerClass: 'col-span-3'}),

            CurrentMarkerValue: new Field('CurrentMarkerValue', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
            LastCurrentMarkerValueDate: new Field('LastCurrentMarkerValueDate', '', [''], false, 'date', {addContainerClass: 'col-span-3'}),
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props;

        return (
            <Layout
                {...this.props}
                hasFooter={true}
                isAccessible={checkPerm(Resources.Trucks, CREATE_PERM)}
                isDirty={this.state.canSubmit}
            >
                <Page>
                    <div className="flex justify-between items-center">
                        <div className="mb-2 flex justify-start items-center">
                            <GoBackButton
                                translate={this.props.translate}
                                history={this.props.history}
                                path={'/trucks'}
                            />
                            <h1
                                className={'mr-5 text-3xl ml-2'}> {translate('text.create')} {translate('page.heading.truck')} </h1>
                        </div>
                    </div>

                    <div className="mb-5 hidden sm:block">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="mb-5">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    {this.state.selectedTab === Resources.TrucksInfo && (
                        <InfoTab
                            fieldsRef={this.fieldsRef}
                            handleItemInputChange={this.handleItemInputChange}
                            handleInputChange={this.handleInputChange}
                            fields_info={this.state.fields_info}
                            isLoading={resource.isLoading}
                            {...this.props}
                        />
                    )}

                    {this.state.selectedTab === Resources.TrucksPermits && (
                        <ResourceCreateListTab
                            data={this.state.fields_permits}
                            translate={translate}
                            dialogTitle={translate('modal_heading.TrucksPermits')}

                            resourceName={Resources.TrucksPermits}
                            fields={{
                                TruckPermitID: new Field('TruckPermitID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
                                PermitNumber: new Field('PermitNumber', '', ['empty'], false, 'text', {
                                    addContainerClass: 'col-span-full'
                                }),
                                StartDate: new Field('StartDate', '', ['empty'], false, 'date', {
                                    maxDate: this.getMaxDate,
                                    addContainerClass: 'col-span-full'
                                }),
                                EndDate: new Field('EndDate', '', [], false, 'date', {
                                    minDate: this.getMinDate,
                                    addContainerClass: 'col-span-full'
                                }),
                                Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}),
                            }}
                            metadata={{
                                TruckPermitID: getLookup('TruckPermit')
                            }}

                            onAddItem={(params) => this.handleAddToListTab('fields_permits', params)}
                            onEditItem={(params) => this.handleEditListTab('fields_permits', params)}
                            onRemoveItem={(params) => this.handleRemoveFromListTab('fields_permits', params)}
                        />
                    )}

                    {this.state.selectedTab === Resources.TrucksType && (
                        <ResourceCreateListTab
                            data={this.state.fields_types}
                            translate={translate}
                            dialogTitle={translate('modal_heading.TrucksTypes')}

                            resourceName={Resources.TrucksType}
                            fields={{
                                TruckTypeID: new Field('TruckTypeID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
                                TruckTypeSubItemID: new Field('TruckTypeSubItemID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}, {}),
                                Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}),
                            }}
                            metadata={{
                                TruckTypeID: getProp(LocalStorage.get('lookup'), 'TruckType', []).reduce(
                                    (memo, item) => {
                                        memo[item['TruckTypeID']] = item['TruckType'] + (item.TruckTypeSubItems.length === 0 ? ' (Has no SubItems)' : '');
                                        return memo;
                                    }, {})
                            }}
                            fillFieldTemplate={(fields, item = null) => {
                                fields = fillFieldsFromData(fields, item)

                                if (item && item.TruckTypeSubItemID) {
                                    const TruckSubTypes = getProp(LocalStorage.get('lookup'), 'TruckType', []).find(it => it.TruckTypeID == item.TruckTypeID)
                                    fields['TruckTypeSubItemID'].props.values = getProp(TruckSubTypes, 'TruckTypeSubItems', []).reduce((memo, it) => {
                                        memo[it.TruckTypeSubItemID] = it.TruckTypeSubItem
                                        return memo
                                    }, {})
                                }

                                return fields
                            }}

                            onAddItem={(params) => {
                                const TruckTypeSubItem = getProp(getSubLookupFor('TruckType', params.TruckTypeID), 'TruckTypeSubItems', [])
                                    .find(it => it.TruckTypeSubItemID == params.TruckTypeSubItemID)

                                const data = [...this.state.fields_types, {
                                    ...params,
                                    TruckTypeSubItem: TruckTypeSubItem?.TruckTypeSubItem ?? '',
                                    index: this.state.fields_types.length
                                }]
                                this.setState({fields_types: data})
                            }}
                            onEditItem={(params) => {
                                const newData = this.state.fields_types.map((item, index) => {
                                    item.index = index
                                    if (index === getProp(params, 'index', 0)) {
                                        const TruckTypeSubItem = getProp(getSubLookupFor('TruckType', params.TruckTypeID), 'TruckTypeSubItems', [])
                                            .find(it => it.TruckTypeSubItemID == params.TruckTypeSubItemID)
                                        item = {
                                            ...params,
                                            TruckTypeSubItem: TruckTypeSubItem?.TruckTypeSubItem ?? '',
                                        }
                                    }
                                    return item
                                })
                                this.setState({fields_types: newData})
                            }}

                            onRemoveItem={(params) => this.handleRemoveFromListTab('fields_types', params)}

                            handleInputChange={(fields, name, value) => {
                                if (name === "TruckTypeID") {
                                    const TruckSubTypes = getProp(LocalStorage.get('lookup'), 'TruckType', []).find(it => it.TruckTypeID == value)
                                    fields['TruckTypeSubItemID'].props.values = getProp(TruckSubTypes, 'TruckTypeSubItems', []).reduce((memo, it) => {
                                        memo[it.TruckTypeSubItemID] = it.TruckTypeSubItem
                                        return memo
                                    }, {})
                                    fields['TruckTypeSubItemID'].value = ''
                                }
                                return FieldsManager.updateField(fields, name, value)
                            }}
                        />
                    )}

                    {this.state.selectedTab === Resources.TrucksCanGo && (
                        <ResourceCreateListTab
                            data={this.state.fields_cango}
                            translate={translate}
                            dialogTitle={translate('modal_heading.TrucksCanGo')}

                            resourceName={Resources.TrucksCanGo}
                            fields={{
                                TruckCanGoID: new Field('TruckCanGoID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
                                Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}),
                            }}
                            metadata={{
                                TruckCanGoID: getProp(LocalStorage.get('lookup'), 'TruckCanGo', [])
                                    .filter(item => !this.state.fields_cango.some(elem => elem.TruckCanGoID == item.TruckCanGoID))
                                    .reduce((memo, item) => {
                                        memo[item.TruckCanGoID] = item.TruckCanGo
                                        return memo
                                    }, {})
                            }}

                            onAddItem={(params) => this.handleAddToListTab('fields_cango', params)}
                            onEditItem={(params) => this.handleEditListTab('fields_cango', params)}
                            onRemoveItem={(params) => this.handleRemoveFromListTab('fields_cango', params)}
                        />
                    )}

                    {this.state.selectedTab === Resources.TruckServices && (
                        <ResourceCreateListTab
                            data={this.state.fields_services}
                            translate={translate}
                            dialogTitle={translate('modal_heading.TruckServices')}

                            resourceName={Resources.TruckServices}
                            fields={{
                                VendorID: new Field("VendorID", '', ['empty'], false, 'select-search', {addContainerClass: "col-span-full"}),
                                OutOfServiceDate: new Field("OutOfServiceDate", '', ['empty'], false, 'date', {addContainerClass: "col-span-full"}),
                                ReturnsToServiceDate: new Field("ReturnsToServiceDate", '', ['empty'], false, 'date', {addContainerClass: "col-span-full"}),
                                ServiceNotes: new Field("ServiceNotes", '', ['empty'], false, 'textarea', {addContainerClass: "col-span-full"}),
                            }}

                            getRawFields
                            onAddItem={(fields) => {
                                const params = Object.assign({}, FieldsManager.getFieldKeyValues(fields), {
                                    Vendor: fields.VendorID.value.label
                                })
                                this.handleAddToListTab('fields_services', params)
                            }}
                            onEditItem={(fields) => {
                                const params = Object.assign({}, FieldsManager.getFieldKeyValues(fields), {
                                    Vendor: fields.VendorID.value.label,
                                    index: fields.index
                                })
                                this.handleEditListTab('fields_services', params)
                            }}
                            onRemoveItem={(params) => this.handleRemoveFromListTab('fields_services', params)}
                            metadata={{
                                VendorID: {
                                    api: "api/" + Resources.VendorsQuick,
                                    query: {},
                                    searchMap: (item) => ({
                                        label: item.LegalName + ", " + item.AddressName,
                                        value: item.VendorID
                                    })
                                }
                            }}
                        />
                    )}

                    {this.state.selectedTab === Resources.TruckDocuments && (
                        <Card>
                            <DocumentsCreateTab
                                fields={this.state.fields_documents}
                                translate={this.props.translate}

                                dialogTitle={'Employee Documents'}

                                documentTypes={getDocumentTypesFor("IsFleetDocument")}
                                handleUploadDocument={this.handleUploadDocument}
                                handleDeleteDocument={this.handleDeleteDocument}
                            />
                        </Card>
                    )}

                    <PageFooter
                        translate={translate}
                        canSubmit={this.state.canSubmit}
                        actionCancel={this.handleFormCancel}
                        actionSubmit={checkPerm(Resources.Trucks, CREATE_PERM) && this.onSubmit}
                    />
                </Page>

                <RedirectDialog
                    show={this.state.isRedirectDialogVisible}
                    title={translate('card_header.truck_created')}
                    onClose={() => {
                        this.props.dispatch(resetResource())
                        this.setState({
                            createdResourceID: null,
                            isRedirectDialogVisible: false
                        })
                    }}

                    createdLabel={translate('btn.truck')}
                    createdLink={`/trucks/info/${this.state.createdResourceID}`}

                    newLabel={translate('btn.create_new')}

                    listLabel={translate('btn.list')}
                    listLink="/trucks"

                    dashboardLabel={translate('btn.dashboard')}

                    {...this.props}
                >
                    <p>{translate('message.truck_created')}</p>
                </RedirectDialog>

                <GroupsViewDialog
                    groupID={this.state.fields_info?.ContactGroupID?.value?.value}
                    isGroupInfoDialogOpen={this.state.isGroupInfoDialogOpen}
                    groupInfoData={this.state.groupInfoData}
                    onClose={() => this.setState({
                        isGroupInfoDialogOpen: false
                    })}
                    translate={translate}
                />
            </Layout>
        )
    }
}

export default connect(state => state)(TruckCreateView)

