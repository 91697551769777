import React, {useEffect} from 'react'
import Tippy from "@tippyjs/react";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/outline";
import FileViewer from "../../../file-viewer/components";
import Env from "../../../../../util/env";
import {getProp, onDocumentPrint} from "../../../../util/util-helpers";
import ModalDefault from "../../../modal/modal-default";
import {getJWT} from "../../../../util/util-auth";

const DocumentPreviewModal = ({
                                  show,
                                  translate,
                                  item,
                                  onButtonClick,
                                  buttonLabel,
                                  closeButtonLabel,
                                  onClose,
                                  id,
                                  primaryKey,
                                  resourceName,
                                  singleResourceName,
                                  fileType,
                                  isLoading,
                                  showNextButton,
                                  showPreviousButton,
                                  onNextDocumentPreviewClick,
                                  onPreviousDocumentPreviewClick,
                                  onReturnExpiryDate
                              }) => {
    const handlePreviewDocumentSlide = event => {
        if (event.keyCode === 37) onPreviousDocumentPreviewClick()
        if (event.keyCode === 39) onNextDocumentPreviewClick()
    }

    useEffect(() => {
        if (show) document.addEventListener("keydown", handlePreviewDocumentSlide, false)
        if (!show) document.removeEventListener("keydown", handlePreviewDocumentSlide, false)
    }, [show])

    const type = fileType ?? item?.DocumentName.split('.').pop()
    const path = Env.getApiUrl("api/" + singleResourceName, Object.assign({}, {
        [primaryKey]: getProp(item, primaryKey, ''),
        token: getJWT().access_token,
        type: 1
    }))

    return (
        <ModalDefault
            show={show}
            widthClass={"max-w-7xl"}
            limitHeight={true}
            title={translate("text.FilePreview")}
            isLoading={isLoading}

            onButtonClick={onButtonClick}
            buttonLabel={buttonLabel}

            closeButtonLabel={closeButtonLabel}
            onClose={onClose}

            customButtonsHTML={(
                <div className={'flex items-center'}>
                    {showPreviousButton && (
                        <Tippy
                            content={translate("btn.ShowPrevious")}
                        >
                            <button
                                className="mx-1 bg-tm-gray-100 p-2 rounded-full text-tm-gray-700 hover:bg-tm-gray-200 focus:outline-none focus:ring-2 ring-offset-inverse ring-link focus:ring-offset-2"
                                onClick={onPreviousDocumentPreviewClick}
                            >
                                <ChevronLeftIcon className="h-4 w-4"/>
                            </button>
                        </Tippy>
                    )}
                    {showNextButton && (
                        <Tippy
                            content={translate("btn.ShowNext")}
                        >
                            <button
                                className="mx-1 bg-tm-gray-100 p-2 rounded-full text-tm-gray-700 hover:bg-tm-gray-200 focus:outline-none focus:ring-2 ring-offset-inverse ring-link focus:ring-offset-2"
                                onClick={onNextDocumentPreviewClick}
                            >
                                <ChevronRightIcon className="h-4 w-4"/>
                            </button>
                        </Tippy>
                    )}

                    <button
                        className={`btn btn-primary mr-2`}
                        onClick={() => onDocumentPrint(path, type)}
                    >
                        {translate("btn.Print")}
                    </button>
                </div>
            )}
        >
            {item && (
                <div className={'p-5'}>
                    <FileViewer
                        fileType={type}
                        filePath={path}
                        onError={(e) => {
                            console.log(e)
                        }}
                    />

                    <div className="w-full border-t border-tm-gray-300 mt-5"/>

                    {!!item.Version && (
                        <>
                            <label className="font-bold text-tm-gray-500 pl-0 pt-2">{translate("field.Version")}</label>
                            <div className={'break-all'}>{item.Version}</div>
                            <div className="w-full border-t border-tm-gray-300 mt-5"/>
                        </>
                    )}

                    <label className="font-bold text-tm-gray-500 pl-0 pt-2">{translate("text.OriginalFilename")}</label>
                    <div className={'break-all'}>{item.OriginalFilename}</div>
                    <div className="w-full border-t border-tm-gray-300 mt-5"/>

                    <label className="font-bold text-tm-gray-500 pl-0 pt-2 break-all">{translate("text.FileDescription")}</label>
                    <div className={'break-all'}>{item.Description}</div>
                    <div className="w-full border-t border-tm-gray-300 mt-5"/>

                    <label className="font-bold text-tm-gray-500 pl-0 pt-2">{translate("field.ImageTypeID")}</label>
                    <div>{item.ImageTypeID === 1 ? "Before" : "After"}</div>
                    <div className="w-full border-t border-tm-gray-300 mt-5"/>

                    <label className="font-bold text-tm-gray-500 pl-0 pt-2 relative top-2.5">{translate("text.UpdatedBy")}</label>
                    <span
                        className={'px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full ml-5 relative top-2.5'}>
                        {item.UpdatedByContact ?? translate("text.system")}
                    </span>
                    <div className="w-full border-t border-tm-gray-300 mt-5"/>

                    <label className="font-bold text-tm-gray-500 pl-0 pt-4 relative top-2">{translate("text.ExpiryDate")}</label>
                    <span className="ml-5 relative top-2">{onReturnExpiryDate(item)}</span>
                </div>
            )}
        </ModalDefault>
    )
}

export default DocumentPreviewModal
