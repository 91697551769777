import React, {useEffect} from 'react'
// import {DriverPayrollOpenPayEntries, DriverPayrollSummary} from './driver-payroll-section'
import TruckPayrollSettlement from "./truck-payroll-settlement";
import TruckFuelCards from "./truck-fuel-cards";
import TruckPayrollSummary from "./truck-payroll-summary";
import {getSecondResource} from "../../../../data/actions/secondResource";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {useDispatch, useSelector} from "react-redux";
import useQuery from "../../../../hooks/use-query";
import {Field, FieldsManager} from "../../../../data/services/fields";
import TruckPayrollOpenPayEntries from "./truck-payroll-open-pay-entries";
import {fillFieldsFromData} from "../../../../common/util/util-fields";
import {getProp} from "../../../../common/util/util-helpers";

export default function TruckPayrollTab({translate, location, TruckID}) {
    const dispatch = useDispatch();

    const summaryData = useSelector(state => state.secondResource);
    const driverData = summaryData?.data?.Driver;
    const isSummaryLoading = summaryData.isLoading

    const [queryFields, setQueryFields] = useQuery(getQueryFields());
    const query = FieldsManager.getFieldKeyValues(queryFields);
    query.searchFields = JSON.stringify({
        ...(queryFields.EndDate.value && {EndDate: ['Date', '<', queryFields.EndDate.value]}),
        ...(queryFields.StartDate.value && {StartDate: ['Date', '>', queryFields.StartDate.value]})
    });
    query.TruckID = TruckID;
    delete query.StartDate;
    delete query.EndDate;

    function fetchSummaryData() {
        dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            query: query,
            resource: Resources.TrucksPayrollSummary
        }))
    }

    function handleQueryInputChange(name, value) {
        const queryFieldsUpdate = FieldsManager.updateField(queryFields, name, value);

        if ("sortBy" === name) {
            if (value === queryFieldsUpdate?.sortBy?.value) {
                queryFieldsUpdate.sort.value = queryFieldsUpdate.sort.value === 'ASC' ? 'DESC' : 'ASC'
            }
        }

        setQueryFields(queryFieldsUpdate);
    }

    function handleQueryClear() {
        setQueryFields(getQueryFields());
    }

    function getQueryFields() {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            StartDate: new Field('StartDate', '', [], false, 'date', {labelType: 'stack'}),
            EndDate: new Field('EndDate', '', [], false, 'date', {labelType: 'stack'}),
            sort: new Field('sort', 'DESC', [''], false, 'text'),
            sortBy: new Field('sortBy', 'Date', [''], false, 'text'),
            offset: new Field('offset', '0', [''], false, 'text'),
            limit: new Field('limit', '10', [''], false, 'select', {hideLabel: true}, {
                menuPlacement: 'top',
                hasPortal: true
            })
        }
    }

    function getSummaryFields(item = null) {
        const fieldTemplates = {
            GrossSoFarYTD: new Field('GrossSoFarYTD', '', [], false, 'text'),
            TotalAmountYTD: new Field('TotalAmountYTD', '', [], false, 'text'),
            GrossSoFarPreviousYTD: new Field('GrossSoFarPreviousYTD', '', [], false, 'text'),
            TotalAmountPreviousYTD: new Field('TotalAmountPreviousYTD', '', [], false, 'text'),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    function getUnpaidItemsFields(item = null) {
        const fieldTemplates = {
            LoadNumber: new Field('LoadNumber', '', [], false, 'text'),
            Customer: new Field('Customer', '', [], false, 'text'),
            Amount: new Field('Amount', '', [], false, 'money')
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    function loanBalancesFields(item = null) {
        const fieldTemplates = {
            Description: new Field('Description', '', [], false, 'text'),
            Amount: new Field('Amount', '', [], false, 'money')
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    function escrowBalancesFields(item = null) {
        const fieldTemplates = {
            Notes: new Field('Notes', '', [], false, 'text'),
            Amount: new Field('Amount', '', [], false, 'money'),
            MaxAmount: new Field('MaxAmount', '', [], false, 'money')
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    function getOpenPayEntryFields(item = null) {
        const fieldTemplates = {
            Item: new Field('Item', '', [], false, 'text', {hideTable: true}),
            Name: new Field('Name', '', [], false, 'text'),
            TotalAmount: new Field('TotalAmount', '', [], false, 'money')
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    const openPayEntryData = [
        {
            Item: 'UnpaidItemsTotal',
            Name: translate('field.UnpaidItemsTotal'),
            TotalAmount: getProp(summaryData, 'data.UnpaidItemsTotal', 0)
        },
        {
            Item: 'EscrowBalancesTotal',
            Name: translate('field.EscrowBalancesTotal'),
            TotalAmount: getProp(summaryData, 'data.EscrowBalancesTotal', 0)
        },
        {
            Item: 'LoanBalancesTotal',
            Name: translate('field.LoanBalancesTotal'),
            TotalAmount: getProp(summaryData, 'data.LoanBalancesTotal', 0)
        },
    ]

    /** Render
     ================================================================= */
    useEffect(() => {
        fetchSummaryData();
    }, [queryFields]);

    return (
        <div className="space-y-8">
            <TruckPayrollSummary
                getFields={getSummaryFields}
                data={summaryData?.data ?? {}}
                isLoading={isSummaryLoading}
                translate={translate}
            />

            <TruckPayrollOpenPayEntries
                contact={driverData}
                data={summaryData?.data}
                getSummaryFields={getSummaryFields}
                unpaidItemsFields={getUnpaidItemsFields()}
                loanBalancesFields={loanBalancesFields()}
                escrowBalancesFields={escrowBalancesFields()}
                openPayEntryFields={getOpenPayEntryFields()}
                openPayEntryData={openPayEntryData}
                isLoading={isSummaryLoading}
                translate={translate}
            />

            <TruckPayrollSettlement
                TruckID={TruckID}
                location={location}
                queryFields={queryFields}
                query={query}
                handleQueryInputChange={handleQueryInputChange}
                handleQueryClear={handleQueryClear}
                translate={translate}
            />

            <TruckFuelCards
                translate={translate}
            />
        </div>
    )
}

