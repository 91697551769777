import React, {useState} from "react";
import TruckPayrollSetup from "./truck-payroll-setup";
import {useDispatch, useSelector} from "react-redux";
import TruckPayrollAdjustments from "./truck-payroll-adjustments";

export default function TruckPayrollSetupTab({translate, setIsStateDirty, TruckID}) {
    const dispatch = useDispatch();
    const secondResource = useSelector(state => state.secondResource);

    const [deductionData, setDeductionData] = useState({});

    const [payAdjustments, setPayAdjustments] = useState([]);

    function handleAddPayAdjustments(params) {
        const payAdjustmentsUpdate = [...payAdjustments];
        payAdjustmentsUpdate.push(params);

        setPayAdjustments(payAdjustmentsUpdate);
        //setIsStateDirty
    }

    return <div className="grid grid-cols-12 gap-x-5 gap-y-10 pb-24">
        <div className="col-span-full 3xl:col-span-4">
            <TruckPayrollSetup
                TruckID={TruckID}
                setIsStateDirty={setIsStateDirty}
                getData={(item) => setDeductionData(item)}
                translate={translate}
            />
        </div>

        <div className="col-span-full 3xl:col-span-8">
            <TruckPayrollAdjustments
                deductionData={deductionData}
                dispatch={dispatch}
                TruckID={TruckID}
                secondResource={secondResource}
                onAddPayAdjustment={handleAddPayAdjustments}
                payAdjustments={payAdjustments}
                translate={translate}
            />
        </div>
    </div>
}