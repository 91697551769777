import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {fieldsToHtml, fillFieldsFromData} from '../../../common/util/util-fields';
import {getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import Tippy from '@tippyjs/react'
import moment from 'moment-timezone'
import {
    DEFAULT_HISTORY_TABLE_FILTER_FIELDS,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    NUMBER_OF_AXLES,
    READ_PERM,
    SCAC_CODE_FIELD_LENGTH,
    TRUCK_STATUS_AVAILABLE,
    TRUCK_STATUS_BOOKED,
    TRUCK_STATUS_ON_LOAD,
    TRUCK_STATUS_OUT_OF_SERVICE,
    TRUCK_STATUS_RETIRED,
    TRUCK_STATUS_SOLD,
    TRUCK_STATUS_TYPE_TIPPY_TEXT,
    TRUCK_STATUS_TYPES,
    UPDATE_PERM,
    VIN_CODE_FIELD_LENGTH
} from '../../../common/util/util-consts'
import {checkPerm, classNames, getLookup, getProp, returnSameKeyValueObject} from '../../../common/util/util-helpers'
import {toFrontDate, toFrontDateTimeFromUTC} from '../../../common/util/util-dates'
import {scrollErrorIntoViewFields} from '../../../common/util/util-vanilla'
import {
    ArrowTopRightOnSquareIcon,
    InformationCircleIcon,
    PlayIcon,
    StopIcon,
    TagIcon
} from '@heroicons/react/24/outline'
import {getHistoryResource} from "../../../data/actions/historyResource";
import {showModal} from "../../../data/actions/ui";
import GroupsViewDialog from "../../my-company/groups-view/groups-view-dialog";
import {HourglassIcon} from "../../../data/themes/icons";
import CardSubTitle from "../../../common/components/card/card-sub-title";
import {LoaderLarge, LoaderSmall} from "../../../common/components/loader";
import Card from "../../../common/components/card";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import Modal from "../../../common/components/modal";
import HistoryModal, {DEFAULT_HISTORY_TABLE_FIELDS} from "../../../common/components/modal/modal-history";
import FieldOptions from "../../../common/components/fields/field-options";
import Badge from "../../../common/components/badge";
import ContactInfoDialog from "../../../common/components/modal/contact-info-dialog";
import OrganizationInfoDialog from "../../../common/components/modal/modal-global/organization-info-dialog";
import {createDialogResource, getDialogResource, updateDialogResource} from "../../../data/actions/dialogResource";
import PopOver from "../../../common/components/popover";
import TableTagManagerPopover
    from "../../../common/components/resource-table/table-components/table-tag-manager/table-tag-manager-popover";
import TableTagManagerModal
    from "../../../common/components/resource-table/table-components/table-tag-manager/table-tag-manager-modal";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import FleetShareTableMap
    from "../../../common/components/command-center-table/fleet-share-table/fleet-share-table-map";
import ModalDefault from "../../../common/components/modal/modal-default";
import {formatNumber} from "chart.js/helpers";

export default class InfoTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            canSubmit: false,

            isRetiredModalOpen: false,
            isGroupInfoDialogOpen: false,
            groupInfoData: {},
            isTagManagerModalOpen: false,
            isMapModalOpen: false,
            mileageModalOpen: false,
            lastLocation: null
        }

        this.fieldsRef = {}
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchData();
        this.fetchLabels();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.resource.isLoading && (!this.props.resource.isLoading)) {
            this.setState({
                fields: this.getFields(getProp(this.props, 'resource.data', {}))
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.Trailer,
            query: {
                id: this.props.match.params.id
            }
        }))
    }

    fetchLabels = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: Resources.ColorTag
        }));
    }

    onSubmit = () => {
        if (this.state.canSubmit) {
            this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                    this.props.canSwapTabsHandler(true)
                    this.setState({canSubmit: false}, () => {
                        this.props.dispatch(updateResource({
                            user: LocalStorage.get('user'),
                            params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                                id: this.props.match.params.id,
                                WarrantyMileage: this.state.fields.WarrantyMileage.value ? this.state.fields.WarrantyMileage.value.replace(',', '') : ''
                            }),
                            query: {
                                id: this.props.match.params.id
                            },
                            errorMessage: true,
                            successMessage: `Trailer ${getProp(this.props, 'resource.data.SCAC', '')} updated`,
                            resource: Resources.Trailer,
                            piggyResource: Resources.Trailer,
                        }))
                    })
                } else {
                    scrollErrorIntoViewFields([this.state.fields])
                }
            })
        }
    }

    onIsRetiredModalSubmit = (params = {}) => {
        params.TrailerID = this.props.match.params.id
        params.IsRetired = getProp(this.props, 'resource.data.IsRetired', 0) ? 0 : 1
        this.props.dispatch(updateResource({
            user: LocalStorage.get("user"),
            params: params,
            resource: Resources.TrailerRetire,
            request: "PUT",
            query: {
                id: this.props.match.params.id
            },
            piggyResource: Resources.Trailer,
            errorMessage: true,
            successMessage: params.IsRetired ? "Trailer Retired Successfully!" : "Trailer is set as active!"
        }))
        this.handleToggleRetiredModal()
    }

    /** UI Events
     ================================================================= */
    handleToggleRetiredModal = () => {
        this.setState({isRetiredModalOpen: !this.state.isRetiredModalOpen})
    }

    handleInputChange = (name, value, nextElementRef) => {
        if (nextElementRef) {
            this.fieldsRef[nextElementRef].focus()
        }

        if (!this.state.canSubmit) {
            this.props.canSwapTabsHandler()
        }

        const fields = FieldsManager.updateField(this.state.fields, name, value)
        if (name === "OwnedByID") {
            fields["OwnedByContactID"].value = ''
            fields["OwnedByOrganizationID"].value = ''

            fields["OwnedByContactID"].disabled = value === 2
            fields["OwnedByOrganizationID"].disabled = value === 1
        }

        if (name === 'OwnedByCompany') {
            fields['OwnedByID'].disabled = !!value
            fields['OwnedByContactID'].disabled = true
            fields['OwnedByOrganizationID'].disabled = true
            fields['TrailerOwnershipTypeID'].disabled = !!value
            fields['OwnedByID'].value = null
            fields['OwnedByContactID'].value = null
            fields['OwnedByOrganizationID'].value  = null
            fields['TrailerOwnershipTypeID'].value  = null
        }

        this.setState({canSubmit: true, fields: fields})
    }

    handleFormCancel = () => {
        if (this.state.canSubmit) {
            this.setState({
                canSubmit: false,
                fields: this.getFields(getProp(this.props, 'resource.data', {}))
            }, () => this.props.canSwapTabsHandler(true))
        }
    }

    handleGroupInfoClick = (groupData) => {
        this.setState({
            isGroupInfoDialogOpen: true,
            groupInfoData: groupData
        })
    }

    handleContactInfoClick = (id) => {
        this.setState({selectedContactID: id, isContactInfoDialogOpen: true})
    }

    handleOrganizationInfoClick = (id) => {
        this.setState({selectedOrganizationID: id, isOrganizationInfoDialogOpen: true})
    }

    handleTagLoad = (selectedLabels) => {
        const IDs = [this.props.match.params.id]
        const ColorTag = selectedLabels.filter(it => it.checked).reduce((memo, it, index) => {
            memo = memo + (index ? '|' : '') + it.label + ',' + it.color
            return memo
        }, '')

        this.props.dispatch(updateDialogResource({
            user: LocalStorage.get('user'),
            params: {IDs, ColorTag},
            resource: Resources.TrailersColor,
            query: {},
            errorMessage: true,
            successMessage: this.props.translate('text.tags_applied'),
        }));
    }

    createTag = (label, color) => {
        this.props.dispatch(createDialogResource({
            user: LocalStorage.get('user'),
            params: {ColorTag: label + ',' + color},
            resource: Resources.ColorTag,
            piggyResource: Resources.ColorTag,
            errorMessage: true,
            successMessage: this.props.translate('text.tag_created'),
        }))
    }

    toggleTagManagerModal = () => {
        this.setState({isTagManagerModalOpen: !this.state.isTagManagerModalOpen})
    }

    handleToggleEditMileageModal = () => {
        this.setState({mileageModalOpen: !this.state.mileageModalOpen})
    }
    /** Fields/Data Definitions
     ================================================================= */
    getFields = (item = {}) => {
        const fieldTemplates = {
            SCAC: new Field('SCAC', '', [''], false, 'text', {addContainerClass: 'col-span-3 md:col-span-2'}, {
                upperCaseOnly: true,
                max: SCAC_CODE_FIELD_LENGTH
            }),
            TrailerNumber: new Field('TrailerNumber', this.props.location?.query?.input ? this.props.location.query.input : '', ['empty'], false, 'text', {addContainerClass: 'col-span-3 md:col-span-2'}),
            Year: new Field('Year', '', [''], false, 'select', {addContainerClass: 'col-span-3 md:col-span-2'}),
            TrailerLicenceNumber: new Field('TrailerLicenceNumber', '', [''], false, 'text', {addContainerClass: 'col-span-3'}),
            TrailerLicenceStateID: new Field('TrailerLicenceStateID', '', [''], false, 'select', {addContainerClass: 'col-span-3'}, {
                values: getLookup('State')
            }),
            TrailerTypeID: new Field('TrailerTypeID', '', [''], false, 'select', {addContainerClass: 'col-span-3'}),
            Manufacturer: new Field('Manufacturer', '', [''], false, 'text', {addContainerClass: 'col-span-3'}),
            Model: new Field('Model', '', [''], false, 'text', {addContainerClass: 'col-span-3'}),
            Engine: new Field('Engine', '', [''], false, 'text', {addContainerClass: 'col-span-3'}),
            VIN: new Field('VIN', '', ['empty'], false, 'text', {addContainerClass: 'col-span-3'}, {
                //max: VIN_CODE_FIELD_LENGTH,
                //upperCaseOnly: true,
                //numbersAndLettersOnly: true
            }),
            OfficeID: new Field('OfficeID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
            ContactGroupID: new Field('ContactGroupID', '', ['empty'], false, 'select-search', {
                addContainerClass: 'col-span-3', label: "FleetGroupID", fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.handleGroupInfoClick(it?.value?.metadata),
                                    isVisible: !!it.value
                                }
                            ]}
                        />
                    )
                }
            }, {
                isClearable: true,
                api: 'api/' + Resources.ContactGroupsQuick,
                query: {},
                searchMap: (it) => ({
                    label: it.ContactGroupName,
                    value: it.ContactGroupID,
                    metadata: it
                })
            }),
            WarrantyMileage: new Field('WarrantyMileage', '', ['float_or_empty'], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
            WarrantyDate: new Field('WarrantyDate', '', [''], false, 'date', {addContainerClass: 'col-span-3'}),
            InServiceDate: new Field('InServiceDate', '', [''], false, 'date', {addContainerClass: 'col-span-3'}),
            CarbID: new Field('CarbID', '', [''], false, 'text', {addContainerClass: 'col-span-3'}),
            Notes: new Field('Notes', '', [], false, 'textarea', {
                heightClass: 'h-52',
                addContainerClass: 'col-span-3'
            }),
            AssetCompanyStatusID: new Field('AssetCompanyStatusID', '', [], false, 'select', {}, {isClearable: true}),
            AssignmentNotes: new Field('AssignmentNotes', '', [], false, 'textarea', {
                heightClass: 'h-52',
                addContainerClass: 'col-span-3'
            }),
            TrailerUtilizationStatusID: new Field('TrailerUtilizationStatusID', '', [], false, 'select', {}, {isClearable: true}),
            TrailerUtilizationNotes: new Field('TrailerUtilizationNotes', '', [], false, 'textarea', {addContainerClass:'col-span-full'}),

            TrailerUnloadedWeight: new Field('TrailerUnloadedWeight', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
            TrailerGrossWeight: new Field('TrailerGrossWeight', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
            TrailerNumberOfAxles: new Field('TrailerNumberOfAxles', '', [''], false, 'select', {addContainerClass: 'col-span-3'}, {
                values: NUMBER_OF_AXLES
            }),
            TrailerLength: new Field('TrailerLength', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
            TrailerHeight: new Field('TrailerHeight', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),

            OwnedByCompany: new Field('OwnedByCompany', '', [], false, 'checkbox', {addContainerClass: 'col-span-6'}),
            TrailerOwnershipTypeID: new Field('TrailerOwnershipTypeID', '', [''], !!item.OwnedByCompany, 'select', {addContainerClass: 'col-span-6'}, {isClearable: true}),
            OwnedByID: new Field('OwnedByID', item.OwnedByCompany ? '' : 1, [''], !!item.OwnedByCompany, 'button-group', {
                data: {
                    1: this.props.translate('field.OwnedByContactID'),
                    2: this.props.translate('field.OwnedByOrganizationID')
                },
                addContainerClass: 'col-span-6',
                hideLabel: true
            }),
            OwnedByContactID: new Field('OwnedByContactID', '', [''], false, 'select-search', {
                addContainerClass: 'col-span-3',
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.handleContactInfoClick(it.value.value),
                                    isVisible: !!it?.value?.value
                                }
                            ]}
                        />
                    )
                }
            }, {isClearable: true}),
            OwnedByOrganizationID: new Field('OwnedByOrganizationID', '', [''], true, 'select-search', {
                addContainerClass: 'col-span-3',
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => this.handleOrganizationInfoClick(it.value.value),
                                    isVisible: !!it?.value?.value
                                }
                            ]}
                        />
                    )
                }
            }, {isClearable: true}),
            CurrentMarkerValue: new Field('CurrentMarkerValue', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
            LastCurrentMarkerValueDate: new Field('LastCurrentMarkerValueDate', '', [''], false, 'date', {addContainerClass: 'col-span-3'}),
        }

        if (item) {
            if (item.OwnedByContactID) {
                fieldTemplates["OwnedByID"].value = 1
                fieldTemplates["OwnedByContactID"].disabled = false
                fieldTemplates["OwnedByOrganizationID"].disabled = true
            }
            if (item.OwnedByOrganizationID) {
                fieldTemplates["OwnedByID"].value = 2
                fieldTemplates["OwnedByOrganizationID"].disabled = false
                fieldTemplates["OwnedByContactID"].disabled = true
            }
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getMileageFields = (item = {}) => {
        const fieldTemplates = {
            Mileage: new Field('Mileage', '', ['empty'], false, 'decimal', {addContainerClass: 'col-span-3', label: "EngineHours"}),
            OdometerDate: new Field('OdometerDate', '', ['empty'], false, 'datetime', {addContainerClass: 'col-span-3'}),
            // OdometerMethodID: new Field('OdometerMethodID', '', [''], false, 'select-search', {addContainerClass: 'col-span-3'})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getFieldTypes = () => {
        return Object.values(this.getFields()).reduce((memo, it) => {
            memo[it.name] = it.type
            return memo
        }, {})
    }

    lastGPSLocation = (item) => {
        if (item.LocationDate) {
            const LocationDate = moment(item.LocationDate);
            const currentDate = moment();

            const dateDifference = moment.duration(LocationDate.diff(currentDate)).asDays();

            return (<div>
                    <p>Last GPS/ELD location:</p>

                    <div className={"flex justify-center"}>
                        <CellButton
                            onClick={() => {
                                this.setState({isMapModalOpen: true, lastLocation: item})
                            }}
                        >
                            <Tippy content={this.props.translate("text.GPS_older_than_7_days")}
                                   disabled={dateDifference > -7}>
                                <div
                                    className={classNames("text-left", (dateDifference < -7) ? "text-red-500" : 'text-primary')}>
                                    {item?.CityName || item?.State || item?.PostalCode ?
                                        <div>{item?.CityName ? item.CityName + ", " : ""} {item?.GPSState} {item?.PostalCode}</div> : ""}
                                    {(item.LocationDate ?
                                        <div>({toFrontDateTimeFromUTC(item.LocationDate)})</div> : undefined)}
                                </div>
                            </Tippy>
                        </CellButton>
                    </div>
                </div>
            )
        } else {
            return (<div className=""><p>Last GPS/ELD location:</p><p className={"text-center"}>No data</p></div>)
        }
    }
    /** Helpers
     ================================================================= */
    getTrailerStatusBadgeClass = (type) => {
        switch (type) {
            case TRUCK_STATUS_AVAILABLE:
                return "success"
            case TRUCK_STATUS_BOOKED:
            case TRUCK_STATUS_ON_LOAD:
                return "primary"
            case TRUCK_STATUS_OUT_OF_SERVICE:
                return "warning"
            case TRUCK_STATUS_SOLD:
            case TRUCK_STATUS_RETIRED:
                return "danger"
            default:
                return "danger"
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, historyResource} = this.props
        const {StatusID, RetiredNotes, OnLoadID, LastActive, IsRetired} = getProp(this.props, "resource.data", {})
        const isLoading = this.props.resource.isLoading;

        const data = getProp(this.props.resource, 'data', [])
        const ColorLabels = getProp(this.props.dialogResource, 'data', []);

        const taggedRows = String(data.ColorTag).split("|").reduce((memo, it) => {
            memo[it] = [1]
            return memo;
        }, {});

        const selects = {
            OfficeID: {
                api: 'api/' + Resources.OfficesQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.OfficeName,
                    value: item.OfficeID
                })
            },
            Year: returnSameKeyValueObject(1900, moment().year() + 1),
            TrailerType: getLookup('TrailerType'),
            TrailerOwnershipType: getLookup('TrailerOwnershipType'),
            OwnedByOrganizationID: {
                api: 'api/' + Resources.OrganizationsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.OrganizationID,
                    label: item.LegalName
                })
            },
            OwnedByContactID: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.ContactID,
                    label: item.FirstName + " " + item.LastName
                })
            },
            ContactGroupID: {
                api: 'api/' + Resources.ContactGroupsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            },
        }

        const fieldsHtmlBasicInfo = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)).slice(0, 15), translate, this.handleInputChange, selects)
        const fieldsHtmlNotes = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)).slice(16, 21), translate, this.handleInputChange, selects)
        const fieldsHtmlProfile = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)).slice(21, 26), translate, this.handleInputChange, selects)
        const fieldsHtmlOwnership = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)).slice(26, 33), translate, this.handleInputChange, selects)

        return (
            <React.Fragment>
                <React.Fragment>
                    <div className={'flex justify-end items-center mb-3 ml-4'}>
                        <div className={"relative"}>
                            {!!isLoading && (
                                <LoaderSmall/>
                            )}

                            <div
                                className={
                                    classNames(
                                        "flex justify-end items-center space-x-3",
                                        !!isLoading && "invisible"
                                    )
                                }
                            >
                                <PopOver
                                    btnClass={"text-opacity-90 btn btn-header"}
                                    tippy={translate("text.label_trailer")}
                                    BtnIcon={TagIcon}
                                    btnIconClass="text-tm-gray-600 w-5 h-5"
                                    widthClass={'max-w-[16rem]'}
                                    positionClass="fixed md:absolute right-0 mx-5 md:mx-0 translate-x-0"
                                >
                                    <div
                                        className="bg-popup border border-tm-gray-300 rounded-md"
                                    >
                                        <TableTagManagerPopover
                                            translate={translate}
                                            labels={ColorLabels}
                                            taggedRows={{
                                                list: taggedRows
                                            }}
                                            selectedRows={{[1]: 1}}
                                            onApplySelected={(taggedRows, labelList) => this.handleTagLoad(labelList)}
                                            onManageTagsClick={this.toggleTagManagerModal}
                                            isLoading={false}
                                        />
                                    </div>
                                </PopOver>

                                {
                                    this.lastGPSLocation(data)
                                }

                                <button
                                    onClick={() => this.handleToggleEditMileageModal()}
                                    className={`btn btn-xs btn-outline py-1.5`}>
                                    Engine hours: {data.Mileage ? formatNumber(data.Mileage, undefined) : "0" } {toFrontDate(data.OdometerDate)}
                                </button>

                                <div className={'flex justify-end ml-6'}>
                                    <Tippy content={
                                        <span>{(IsRetired ? "Trailer is in the retired state. Click to change status back to active." : "Trailer is in active state. Click to change status to retired state.")}</span>}>
                                        <button
                                            type="button"
                                            onClick={this.handleToggleRetiredModal}
                                            className="btn btn-header mr-2 w-fit px-2"
                                        >
                                            {IsRetired ?
                                                <div className={'h-5 text-green-600'}>Set active</div>
                                                :
                                                <div className={'h-5 text-red-600'}>Set inactive</div>
                                            }
                                        </button>
                                    </Tippy>
                                </div>
                                {/*<Tippy*/}
                                {/*    content={*/}
                                {/*        <span>{translate(TRUCK_STATUS_TYPE_TIPPY_TEXT[StatusID], [RetiredNotes])}</span>}*/}
                                {/*>*/}
                                {/*    <div>*/}
                                {/*        <Badge*/}
                                {/*            addClass="h-8 items-center"*/}
                                {/*            onClick={() => {*/}
                                {/*                if (StatusID === TRUCK_STATUS_BOOKED || StatusID === TRUCK_STATUS_ON_LOAD) {*/}
                                {/*                    window.open(`${window.location.origin}/loads/info/${OnLoadID}`, '_blank');*/}
                                {/*                }*/}
                                {/*            }}*/}
                                {/*            type={this.getTrailerStatusBadgeClass(StatusID)}>*/}
                                {/*            <div className="flex">*/}
                                {/*                {TRUCK_STATUS_TYPES[StatusID]}*/}
                                {/*                {(StatusID === TRUCK_STATUS_BOOKED || StatusID === TRUCK_STATUS_ON_LOAD) && (*/}
                                {/*                    <ArrowTopRightOnSquareIcon*/}
                                {/*                        className={'w-5 h-5 ml-4'}*/}
                                {/*                    />*/}
                                {/*                )}*/}
                                {/*            </div>*/}
                                {/*        </Badge>*/}
                                {/*    </div>*/}
                                {/*</Tippy>*/}
                                {LastActive && (
                                    <button
                                        onClick={() => this.props.setActiveTab(Resources.TrailersLoads)}
                                        className={`btn btn-xs active btn-light`}>
                                        Last load on: {toFrontDate(LastActive)}
                                    </button>
                                )}

                                {
                                    checkPerm(Resources.TrailerHistory, READ_PERM) && (
                                        <div className={'flex justify-end ml-6'}>
                                            <Tippy content={<span>{translate('text.view_history')}</span>}>
                                                <button
                                                    type="button"
                                                    onClick={() => this.setState({historyModalOpen: true})}
                                                    className="btn btn-header"
                                                >
                                                    {<HourglassIcon className="w-5 h-5"/>}
                                                </button>
                                            </Tippy>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="pb-20">
                        <div className="grid grid-cols-12 gap-4 max-w-4xl 3xl:max-w-full mx-auto 3xl:mx-0">
                            <div className="col-span-full 3xl:col-span-6">
                                <Card addClass={'h-full relative'}>
                                    <CardSubTitle
                                        text={translate('text.BasicInfo')}
                                    />

                                    <div className="grid grid-cols-6 gap-5">
                                        {fieldsHtmlBasicInfo}
                                    </div>

                                    {isLoading && (
                                        <LoaderLarge stripesBg/>
                                    )}
                                </Card>
                            </div>

                            <div className="col-span-full 3xl:col-span-6">
                                <Card addClass={'h-full relative'}>
                                    <CardSubTitle
                                        text={translate('text.Notes')}
                                    />

                                    <div className="space-y-5">
                                        {fieldsHtmlNotes}
                                    </div>

                                    {isLoading && (
                                        <LoaderLarge stripesBg/>
                                    )}
                                </Card>
                            </div>

                            <div className="col-span-full 3xl:col-span-6">
                                <Card addClass={'h-full relative'}>
                                    <CardSubTitle
                                        text={translate('text.Profile')}
                                    />

                                    <div className="grid grid-cols-6 gap-5">
                                        {fieldsHtmlProfile}
                                    </div>

                                    {isLoading && (
                                        <LoaderLarge stripesBg/>
                                    )}
                                </Card>
                            </div>

                            <div className="col-span-full 3xl:col-span-6">
                                <Card addClass={'h-full relative'}>
                                    <CardSubTitle
                                        text={translate('text.OwnerShipAndValue')}
                                    />

                                    <div className="grid grid-cols-6 gap-5">
                                        {fieldsHtmlOwnership}
                                    </div>

                                    {isLoading && (
                                        <LoaderLarge stripesBg/>
                                    )}
                                </Card>
                            </div>
                        </div>
                    </div>

                    <PageFooter
                        translate={translate}
                        canSubmit={this.state.canSubmit}
                        actionCancel={this.handleFormCancel}
                        actionSubmit={checkPerm(Resources.Trailer, UPDATE_PERM) && this.onSubmit}
                    />
                </React.Fragment>

                <ModalSaveResource
                    show={this.state.isRetiredModalOpen && !IsRetired}
                    title={"Set Trailer as inactive/retired"}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleRetiredModal}
                    fields={{
                        RetiredNotes: new Field("RetiredNotes", '', ['empty'], false, 'textarea', {
                            addContainerClass: "col-span-full"
                        })
                    }}
                    onSubmit={(params) => {
                        if (params) {
                            this.onIsRetiredModalSubmit(params)
                        }
                    }}
                    translate={this.props.translate}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!(this.state.isRetiredModalOpen && IsRetired)}
                    text={"Are you sure you want to set this trailer to active state?"}
                    onClose={this.handleToggleRetiredModal}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => this.onIsRetiredModalSubmit()}
                />

                <Modal
                    show={this.state.historyModalOpen}
                    widthClass={"max-w-5xl"}
                    onClose={() => this.setState({historyModalOpen: false})}
                    translate={translate}
                >
                    {this.state.historyModalOpen && (
                        <HistoryModal
                            onClose={() => this.setState({historyModalOpen: false})}
                            translate={translate}
                            title={translate("text." + Resources.TrailerHistory)}
                            tableFields={DEFAULT_HISTORY_TABLE_FIELDS(translate, this.getFieldTypes())}
                            filterFields={DEFAULT_HISTORY_TABLE_FILTER_FIELDS()}
                            onFetchData={(query) => {
                                this.props.dispatch(getHistoryResource({
                                    user: LocalStorage.get('user'),
                                    query: Object.assign({}, query, {
                                        id: this.props.match.params.id
                                    }),
                                    resource: Resources.TrailerHistory
                                }))
                            }}
                            data={getProp(historyResource, "data.list", [])}
                            count={getProp(historyResource, "data.count", 0)}
                            isLoading={getProp(historyResource, "isLoading", false)}
                            showUserAvatar

                            onRowClick={(item) => {
                                this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
                            }}
                            fieldTypes={this.getFieldTypes()}
                        />
                    )}
                </Modal>

                <GroupsViewDialog
                    groupID={this.state.fields?.ContactGroupID?.value?.value}
                    isGroupInfoDialogOpen={this.state.isGroupInfoDialogOpen}
                    groupInfoData={this.state.groupInfoData}
                    onClose={() => this.setState({
                        isGroupInfoDialogOpen: false
                    })}
                    translate={translate}
                />

                {(this.state.isContactInfoDialogOpen &&
                    <ContactInfoDialog
                        show={this.state.isContactInfoDialogOpen}
                        translate={translate}
                        contactID={this.state.selectedContactID}
                        handleClose={() => this.setState({
                            isContactInfoDialogOpen: false
                        })}
                    />
                )}

                {(this.state.isOrganizationInfoDialogOpen &&
                    <OrganizationInfoDialog
                        show={this.state.isOrganizationInfoDialogOpen}
                        translate={translate}
                        organizationID={this.state.selectedOrganizationID}
                        handleClose={() => this.setState({
                            isOrganizationInfoDialogOpen: false
                        })}
                    />
                )}

                <TableTagManagerModal
                    show={this.state.isTagManagerModalOpen}
                    onClose={this.toggleTagManagerModal}
                    translate={translate}
                    onCreateTag={this.createTag}
                    tagsData={ColorLabels}
                    isLoading={this.props.dialogResource.isLoading}
                />

                <ModalDefault
                    show={this.state.isMapModalOpen}
                    widthClass={"max-w-7xl"}
                    title={translate("text.last_known_trailer_location")}

                    closeButtonLabel={translate("btn.close")}
                    onClose={() => this.setState({isMapModalOpen: false})}
                >
                    <FleetShareTableMap
                        translate={translate}
                        selectedLocation={this.state.lastLocation}
                    />
                </ModalDefault>

                <ModalSaveResource
                    show={this.state.mileageModalOpen}
                    title={translate("text.update_engine_hours")}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={() => this.handleToggleEditMileageModal()}
                    errorMessage={this.state.errorMessage}
                    fields={this.getMileageFields(data)}
                    onSubmit={(params) => {
                        if (params) {
                            params.TrailerID = this.props.match.params.id;
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: {
                                id: this.props.match.params.id
                                },
                                params: params,
                                resource: Resources.TrailerMileage,
                                piggyResource: Resources.Trailer,
                                errorMessage: true, successMessage: "Engine hours updated"
                            }));
                            this.handleToggleEditMileageModal(null)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata}
                />
            </React.Fragment>
        )
    }
}
