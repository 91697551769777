export function createResourceDiff(data) {
    return {
        type: 'CREATE_RESOURCE_DIFF',
        data: data
    };
}

export function doneCreateResourceDiff(data) {
    return {
        type: 'DONE_CREATE_RESOURCE_DIFF',
        data: data
    };
}

export function errorCreateResourceDiff(data) {
    return {
        type: 'ERROR_CREATE_RESOURCE_DIFF',
        data: data
    };
}

export function getResourceDiff(data) {
    return {
        type: 'GET_RESOURCE_DIFF',
        data: data
    };
}

export function doneGetResourceDiff(data) {
    return {
        type: 'DONE_GET_RESOURCE_DIFF',
        data: data
    };
}

export function errorGetResourceDiff(data) {
    return {
        type: 'ERROR_GET_RESOURCE_DIFF',
        data: data
    };
}

export function updateResourceDiff(data) {
    return {
        type: 'UPDATE_RESOURCE_DIFF',
        data: data
    };
}

export function doneUpdateResourceDiff(data) {
    return {
        type: 'DONE_UPDATE_RESOURCE_DIFF',
        data: data
    };
}

export function errorUpdateResourceDiff(data) {
    return {
        type: 'ERROR_UPDATE_RESOURCE_DIFF',
        data: data
    };
}

export function deleteResourceDiff(data) {
    return {
        type: 'DELETE_RESOURCE_DIFF',
        data: data
    };
}

export function doneDeleteResourceDiff(data) {
    return {
        type: 'DONE_DELETE_RESOURCE_DIFF',
        data: data
    };
}

export function errorDeleteResourceDiff(data) {
    return {
        type: 'ERROR_DELETE_RESOURCE_DIFF',
        data: data
    };
}

export function createResourceDiffSilent(data) {
    return {
        type: 'CREATE_RESOURCE_DIFF_SILENT',
        data: data
    };
}

export function updateResourceDiffSilent(data) {
    return {
        type: 'UPDATE_RESOURCE_DIFF_SILENT',
        data: data
    };
}

export function deleteResourceDiffSilent(data) {
    return {
        type: 'DELETE_RESOURCE_DIFF_SILENT',
        data: data
    };
}