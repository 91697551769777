import React, {Component} from 'react'
import LocalStorage from '../../../../util/localStorage'
import {Field, FieldsManager} from '../../../../data/services/fields'
import Resources from '../../../../data/services/resources'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DELETE_PERM,
    MAJOR_ACCOUNT_TYPE_EXPENSE,
    UPDATE_PERM
} from '../../../../util/util-constants'
import {checkPerm, classNames, getProp} from '../../../../common/util/util-helpers'
import {
    createSecondResource,
    deleteSecondResource,
    getSecondResource,
    updateSecondResource
} from "../../../../data/actions/secondResource";
import {MinusCircleIcon, PlusCircleIcon} from "@heroicons/react/20/solid";
import {PencilIcon, TrashIcon} from "@heroicons/react/24/outline";
import {fillFieldsFromData} from "../../../../common/util/util-fields";
import PageHeader from "../../../../common/components/layout/layout-components/page/page-header";
import Card from "../../../../common/components/card";
import NoRecordsTable from "../../../../common/components/no-records-found/no-records-table";
import ResourceTable from "../../../../common/components/resource-table";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import {formatMoney, numberWithCommas} from "../../../../common/util/util-formaters";
import InfoParagraph from "../../../../common/components/info-paragraph";
import {Link} from "react-router-dom";
import {toFrontDate} from "../../../../common/util/util-dates";

export default class TruckPayrollAdjustments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...DEFAULT_CRUD_STATE,
            fields: this.getFields(),
            canSubmit: false,
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        if (this.getID()) {
            this.fetchData()
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.deductionData !== this.props.deductionData) {
            this.fetchData();
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: this.getResource()
        }))
    }

    /** UI Events
     ================================================================= */
    handleToggleCreateModal = () => {
        this.setState({createModalOpen: !this.state.createModalOpen})
    }

    handleToggleUpdateModal = (item = null) => {
        this.setState({
            updateModalOpen: !this.state.updateModalOpen,
            selectedItem: item
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            confirmModalOpen: !this.state.confirmModalOpen,
            selectedItem: item
        })
    }

    handleInputChange = (fields, name, value) => {
        const fieldsUpdate = FieldsManager.updateField(fields, name, value);
        if (name === 'DeductionRepeatTypeID') {
            if (value === '2') {
                fieldsUpdate.MaxAmount.value = ''
                fieldsUpdate.MaxAmount.disabled = false
            } else {
                fieldsUpdate.MaxAmount.disabled = true
            }
        }

        if (name === 'Deduction') {
            fieldsUpdate.IncomeAccountID.type = value === 1 ? 'select-search' : 'hidden';
            fieldsUpdate.ExpenseAccountID.type = value === 0 ? 'select-search' : 'hidden';
            fieldsUpdate.IncomeAccountID.validate = value === 1 ? ['empty'] : [''];
            fieldsUpdate.ExpenseAccountID.validate = value === 0 ? ['empty'] : [''];
        }
        if (name === 'ScheduledDeductionsType') {
            if (value === 1) {
                fieldsUpdate.Amount.type = 'float'
                fieldsUpdate.AmountPer.type = 'hidden'
                fieldsUpdate.AmountPer.value = ''
            } else {
                fieldsUpdate.AmountPer.type = 'float'
                fieldsUpdate.Amount.type = 'hidden'
                fieldsUpdate.Amount.value = ''
            }
        }
        return fieldsUpdate;
    }
    /** Helpers
     ================================================================= */
    getID = () => {
        return this.props.TruckID
    }

    getResource = () => {
        return Resources.TrucksDeduction
    }

    getQuery = () => {
        return {
            id: this.getID(),
            limit: 100,
            offset: 0
        }
    }

    getFields = (item = null) => {
        let fieldTemplates = {
            Notes: new Field('Notes', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full'
            }),
            Deduction: new Field('Deduction', Number(item?.Deduction) === 1 ? 1 : 0, [], false, 'button-group', {
                data: {1: 'Deduction', 0: 'Addition'},
                addContainerClass: 'col-span-full',
                render: (it) => !it.Deduction
                    ? <div className="flex gap-x-2">
                        <PlusCircleIcon className="text-green-600 w-5 h-5"/>
                        {this.props.translate("text.addition")}
                    </div>
                    : <div className="flex gap-x-2">
                        <MinusCircleIcon className="text-red-600 w-5 h-5"/>
                        {this.props.translate("text.deduction")}
                    </div>
            }),
            IncomeAccountID: new Field('IncomeAccountID', '', Number(item?.Deduction) === 1 ? ['empty'] : [''], false, Number(item?.Deduction) !== 1 ? 'hidden' : 'select-search', {
                addContainerClass: 'col-span-full',
                label: "account",
                hideTable: true
            }),
            ExpenseAccountID: new Field('ExpenseAccountID', '', Number(item?.Deduction) === 0 ? ['empty'] : [''], false, Number(item?.Deduction) === 1 ? 'hidden' : 'select-search', {
                addContainerClass: 'col-span-full',
                label: "account",
                render: (it) => it.Deduction ? it.IncomeAccount : it.ExpenseAccount
            }),
            ScheduledDeductionsType: new Field('ScheduledDeductionsType',  item?.ScheduledDeductionsType ?? 1, [], false, 'button-group', {
                data: {1: 'Fixed amount', 2: 'Per mile', 3: 'Per gallon'},
                addContainerClass: 'col-span-full',
                render: (item) => (Number(item?.ScheduledDeductionsType) === 1)
                    ? <div className="flex gap-x-2">
                        {this.props.translate("text.fixed_amount")}
                    </div>
                    : (Number(item?.ScheduledDeductionsType) === 2) ? <div className="flex gap-x-2">
                        {this.props.translate("text.per_mile_amount")}
                    </div> : <div className="flex gap-x-2">
                        {this.props.translate("text.per_gallon_amount")}
                    </div>
            }),
            Amount: new Field('Amount', '', [], false, Number(item?.ScheduledDeductionsType ?? 1) === 1 ? 'float' : 'hidden', {
                addContainerClass: 'col-span-full',
                render: (item) => {
                    return formatMoney(!!item?.Amount && !!item?.Amount > 0 ? item?.Amount : item?.AmountPer)
                }
            }),
            AmountPer: new Field('AmountPer', '', [], false, Number(item?.ScheduledDeductionsType ?? 1) !== 1 ? 'float' : 'hidden', {
                addContainerClass: 'col-span-full',
                hideTable: true
            }),
            StartDate: new Field('StartDate', '', ['empty'], false, 'date', {
                addContainerClass: 'col-span-full'
            }),
            DeductionRepeatTypeID: new Field('DeductionRepeatTypeID', '1', [], false, 'radio', {
                options: [
                    {name: 'Unlimited', value: '1'},
                    {name: 'Up to the account balance', value: '2'},
                    {name: 'Until account balance reaches zero', value: '3'},
                    {name: 'One time', value: '4'}
                ],
                addContainerClass: 'col-span-full',
                render: (it) => {
                    let text = '';
                    switch (+it.DeductionRepeatTypeID) {
                        case 1:
                            text = 'Unlimited'
                            break;
                        case 2:
                            text = 'Up to the account balance'
                            break;
                        case 3:
                            text = 'Until account balance reaches zero'
                            break;
                        case 4:
                            text = 'One time'
                            break;
                    }
                    return text
                }
            }),
            MaxAmount: new Field('MaxAmount', '', [], true, 'float', {
                addContainerClass: 'col-span-full',
                label: "up_to_account_balance",
                render: (it) => {
                    switch (+it.DeductionRepeatTypeID) {
                        case 2:
                            return (numberWithCommas(it.MaxAmount))
                        default:
                            return "/";
                    }
                }
            }),
            ScheduleFrequency: new Field('ScheduleFrequency', "0", ['empty'], false, 'select', {
                addContainerClass: 'col-span-full',
                render: (it) => {
                    switch (+it.ScheduleFrequency) {
                        case 1:
                            return "Weekly";
                        case 2:
                            return "Monthly";
                        default:
                            return "Every payroll";
                    }
                }
            }, {
                values: {
                    0: "Every payroll",
                    1: "Weekly",
                    2: "Monthly"
                },
            }),
            Active: new Field('Active', 1, [], false, 'checkbox', {
                addContainerClass: 'col-span-full'
            }),
            Usage: new Field('Usage', "", [], false, 'custom', {
                addContainerClass: 'col-span-full',
                render: (it) => {
                    let text = '';
                    switch (+it.DeductionRepeatTypeID) {
                        case 3:
                        case 2:
                            text = 'Balance ' + numberWithCommas(it.UsedAmount, 2)
                            break;
                        case 1:
                            text = !!it.LastUsedDate ? "Last usage " + toFrontDate(it.LastUsedDate) : "Not used"
                            break;
                        case 4:
                            text = !!it.LastUsedDate ? "Used at " + toFrontDate(it.LastUsedDate) : "Not used"
                            break;
                    }
                    return text
                },
                hideDialog: true
            })
        }

        if (item) {
            if (item.ScheduleFrequency == 0) {
                item.ScheduleFrequency = "0";
            }
            fieldTemplates = fillFieldsFromData(fieldTemplates, item)
            switch (+item.DeductionRepeatTypeID) {
                case 2:
                    fieldTemplates.MaxAmount.disabled = false
                    break;
                default:
                    fieldTemplates.MaxAmount.disabled = true
            }
        } else {
            fieldTemplates.ExpenseAccountID.validate = ['empty'];
        }

        return fieldTemplates
    }

    render() {
        const {translate, secondResource, payAdjustments} = this.props

        // payAdjustments is the state of list of created adjustments in create truck mode
        const data = payAdjustments.length ? payAdjustments : getProp(secondResource, 'data.list', []);
        const isLoading = secondResource.isLoading;
        const isCreateMode = !this.getID();

        const metadata = {
            IncomeAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    limit: 20,
                    offset: 0,
                    IncomeLiabilityAccounts: 1
                },
                searchMap: (item) => ({
                    label: item.AccountNumber + ' ' + item.AccountName,
                    value: item.AccountID
                })
            },
            ExpenseAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    limit: 20,
                    offset: 0,
                    ExpenseAdvancesAccounts: 1
                },
                searchMap: (item) => ({
                    label: item.AccountNumber + ' ' + item.AccountName,
                    value: item.AccountID
                })
            }
        }

        return (
            <div className="space-y-4 flex flex-col">
                <PageHeader
                    title={translate('title.Payroll_adjustments')}
                    className="flex h-9"
                    buttonLabel={translate('btn.create_new')}
                    onButtonClick={this.handleToggleCreateModal}
                    buttonHidden={!checkPerm(this.getResource(), CREATE_PERM) || this.props.visible}
                />

                <Card addClass={"flex-shrink-1 min-h-[33.5rem]"} bodyClass="overflow-hidden rounded-lg">
                    <div className={
                        classNames(
                            "space-y-4 rounded-card shadow min-h-[33.5rem]",
                            (data.length === 0) && !isLoading ? "flex items-center justify-center" : undefined
                        )
                    }>
                        <NoRecordsTable
                            show={(data.length === 0) && !isLoading}
                            title={translate('text.no_matching_records')}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}

                            options={{
                                style: {
                                    verticalLines: true,
                                    stripedRows: true
                                },
                                classes: {
                                    headerTextClass: 'text-xs font-semibold text-tm-gray-900 capitalize',
                                    primaryTextClass: ''
                                }
                            }}

                            translate={translate}
                            isLoading={isLoading}

                            onRowClick={(item) => checkPerm(this.getResource(), UPDATE_PERM) && !this.props.visible && this.handleToggleUpdateModal(item)}
                            actions={[
                                {
                                    action: this.handleToggleUpdateModal,
                                    icon: PencilIcon,
                                    visible: () => checkPerm(this.getResource(), UPDATE_PERM) && !this.props.visible,
                                    title: translate('text.show_details'),
                                },
                                {
                                    action: this.handleToggleConfirmModal,
                                    icon: TrashIcon,
                                    visible: () => checkPerm(this.getResource(), DELETE_PERM) && !this.props.visible,
                                    title: translate('text.delete_item'),
                                }
                            ]}
                        />
                    </div>

                    <ModalSaveResource
                        title={translate('dialog_heading.edit_truck_deduction')}
                        widthClass="max-w-md"
                        gridColsClass="grid-cols-12"
                        show={this.state.updateModalOpen}
                        onClose={() => this.handleToggleUpdateModal()}
                        fields={this.getFields(this.state.selectedItem)}

                        onSubmit={(params) => {
                            if (params) {
                                params.IsForever = params.DeductionRepeatTypeID == '1' ? 1 : 0
                                if (params.DeductionRepeatTypeID == '4') {
                                    params.MaxAmount = params.ScheduledDeductionsType == 1 ? params.Amount : params.AmountPer
                                }
                                this.props.dispatch(updateSecondResource({
                                    user: LocalStorage.get('user'),
                                    params: Object.assign(params, {
                                        TruckID: this.getID(),
                                        TruckScheduledDeductionsID: this.state.selectedItem.TruckScheduledDeductionsID,
                                    }),
                                    query: this.getQuery(),
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: translate('text.truck_deduction_updated')
                                }))
                                this.handleToggleUpdateModal()
                            }
                        }}
                        translate={translate}
                        metadata={metadata}
                        handleInputChange={this.handleInputChange}
                    />

                    <ModalSaveResource
                        title={translate('dialog_heading.create_truck_deduction')}
                        widthClass="max-w-md"
                        gridColsClass="grid-cols-12"
                        show={this.state.createModalOpen}
                        onClose={() => this.handleToggleCreateModal()}
                        fields={this.getFields()}
                        onSubmit={(params) => {
                            if (params && !isCreateMode) {
                                params.IsForever = params.DeductionRepeatTypeID == '1' ? 1 : 0
                                params.TruckID = this.getID()
                                this.props.dispatch(createSecondResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: translate('text.truck_deduction_created')
                                }))
                                this.handleToggleCreateModal();
                            }
                        }}
                        translate={translate}
                        metadata={metadata}
                        handleInputChange={this.handleInputChange}
                    />

                    <ModalConfirm
                        title={translate('text.Confirm')}
                        show={!!this.state.confirmModalOpen}
                        text={translate('message.confirm_delete_deduction')}
                        onClose={() => this.handleToggleConfirmModal()}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={translate('btn.cancel')}
                        translate={translate}
                        onConfirm={() => {
                            this.props.dispatch(deleteSecondResource({
                                user: LocalStorage.get('user'),
                                query: Object.assign(
                                    {TruckScheduledDeductionsID: this.state.selectedItem.TruckScheduledDeductionsID},
                                    this.getQuery()
                                ),
                                secondPiggyQuery: this.getQuery(),
                                resource: this.getResource(),
                                secondPiggyResource: this.getResource(),
                                errorMessage: true, successMessage: translate('text.truck_deduction_deleted')
                            }))
                            this.handleToggleConfirmModal()
                        }}
                    />
                    <InfoParagraph type="info" className={"m-4"}>
                        <p>{"Payroll adjustment entries will appear on "} <Link to={'/truck-payroll'}
                                                                                    className={"text-primary underline"}>payroll
                            record.</Link> {"Serving as a template for recurring charges or additions, these entries can be edited or selectively added to each payroll record."}
                        </p>
                    </InfoParagraph>
                </Card>
            </div>
        )
    }
}
