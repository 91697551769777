import ModalDefault from "../../../../common/components/modal/modal-default";
import React, {useEffect, useRef, useState} from "react";
import FactoringFileUploadZone from "./factoring-file-upload-zone";
import {LoaderLarge} from "../../../../common/components/loader";
import LocalStorage from "../../../../common/util/localStorage";
import Resources from "../../../../data/services/resources";
import {useDispatch, useSelector} from "react-redux";
import {previewDocumentResource} from "../../../../data/actions/previewDocumentResource";
import FactoringImportTable from "./factoring-import-table";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import {updateResource} from "../../../../data/actions/resource";
import {formatMoney} from "../../../../common/util/util-formaters";

export default function CollectionsFactoringImportDialog({query, piggyResource, translate, onClose, show}) {
    const isUpdated = useRef(false);

    const dispatch = useDispatch();
    const resource = useSelector(state => state.resource);
    const documentResource = useSelector(state => state.previewDocumentResource);
    const isLoading = documentResource.isLoading || resource.isLoading;
    const data = documentResource.data;

    const [file, setFile] = useState();
    const [selectedRows, setSelectedRows] = useState({});

    const totalAmountSelected = Object.values(selectedRows).reduce((memo, it) => {
        memo += it.Amount;
        return memo;
    }, 0)

    function handleSubmitClick() {
        const params = {
            Factoring: 1,
            Items: Object.values(selectedRows).map(it => {
                return {
                    InvoiceID: it.InvoiceID?.value,
                    Amount: it.Amount,
                }
            })
        }

        dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: params,
            errorMessage: true, successMessage: 'Data imported successfully.',
            query: query,
            resource: Resources.InvoicesFactor,
            piggyResource: piggyResource,
        }));
        isUpdated.current = true;
    }

    function fetchData() {
        dispatch(previewDocumentResource({
            user: LocalStorage.get("user"),
            resource: Resources.ExcelPreview,
            params: {Factoring: 1},
            files: file
        }))
    }

    useEffect(() => {
        if (file) {
            fetchData();
        }
    }, [file]);

    useEffect(() => {
        if (resource.updated && isUpdated.current && !isLoading) {
            onClose();
            isUpdated.current = false;
        }
    }, [resource]);

    useEffect(() => {
        isUpdated.current = false;
    }, [show])

    return (
        <ModalDefault
            show={show}
            widthClass="max-w-2xl"
            title={!!data && !!file ? translate("text.selectRowsForImport") : translate("text.importFactoring")}
            onClose={onClose}
            closeButtonLabel={translate('btn.close')}
            buttonLabel={translate("btn.import")}
            buttonDisabled={!Object.keys(selectedRows)[0]}
            onButtonClick={handleSubmitClick}
            customButtonsHTML={
                totalAmountSelected
                    ? <div className="text-base">Total amount selected: <span
                        className="font-bold">${formatMoney(totalAmountSelected)}</span></div>
                    : undefined
            }
            translate={translate}
        >
            <div className="p-6 relative min-h-[16rem]">
                {isLoading && (
                    <LoaderLarge/>
                )}

                {!file && (
                    <div className="p-6 relative h-64">
                        <FactoringFileUploadZone
                            setFile={setFile}
                            translate={translate}
                        />
                    </div>
                )}

                {!!data && !!file && (
                    <>
                        <div>
                            <InfoBar addClass="text-base">{translate("message.importRowsInfo")}</InfoBar>
                        </div>

                        <FactoringImportTable
                            data={data}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            translate={translate}
                        />
                    </>
                )}
            </div>
        </ModalDefault>
    )
}