import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {Field, FieldsManager} from "../../data/services/fields";
import {ArchiveBoxArrowDownIcon} from "@heroicons/react/24/outline";
import {getProp} from "../../common/util/util-helpers";
import {toFrontDateTimeFromUTC} from "../../common/util/util-dates";
import NoRecords from "../../common/components/no-records-found/no-records";
import Pagination from "../../common/components/resource-table/table-components/pagination";
import TableFilters from "../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../common/components/resource-table/table-components/table-card";
import PageHeader from "../../common/components/layout/layout-components/page/page-header";
import Page from "../../common/components/layout/layout-components/page";
import TableCardFooter from "../../common/components/resource-table/table-components/table-card-footer";
import ResourceTable from "../../common/components/resource-table";
import Badge from "../../common/components/badge";
import Layout from "../../common/components/layout";
import {excludeFields} from "../../common/util/util-fields";
import {DEFAULT_QUERY_LIMIT} from "../../common/util/util-consts";

const DownloadCenter = ({match, history, location, translate}) => {
    const dispatch = useDispatch()
    const ui = useSelector((state) => state.ui)
    const user = useSelector((state) => state.user)
    const resource = useSelector((state) => state.resource)

    const count = getProp(resource, 'data.count', 0);
    const isLoading = resource?.isLoading


    const getFields = () => {
        return {
            JobID: new Field('JobID', '', [''], false, 'string', {
                label: "job_number"
            }),
            Description: new Field('Description', '',),
            JobStatusID: new Field('JobStatusID', '', [''], false, 'custom', {
                render: (item) => renderJobStatusBadge(item.JobStatusID)
            }),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [''], false, 'data', {
                label: 'LastUpdated',
                render: (it) => <div
                    className="">{toFrontDateTimeFromUTC(it.CreateUpdateDate, 0, '.', ',')}</div>
            }),
        }
    }

    const getQueryFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            offset: new Field('offset', '0', [''], false, 'text', {}, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                labelType: 'float', hideLabel: true
            }, {menuPlacement: 'top'})
        }
    }

    const [fields, setFields] = useState(getFields())
    const [queryFields, setQueryFields] = useState(getQueryFields())
    const [data, setData] = useState([])

    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.Jobs,
            query: Object.assign({
                sort: "DESC",
                sortBy: "JobID"},
                FieldsManager.getFieldKeyValues(queryFields))
        }))
    }

    useEffect(() => {
        fetchData()
    }, [queryFields])

    useEffect(() => {
        if (getProp(resource, 'data.list', []).length) setData(resource.data.list)
    }, [resource])

    const onDownloadZipClick = (item) => {
        handleDownloadZip(item.Result)
    }

    const handleDownloadZip = (url) => {
        window.open(url, '_self')
    }

    const handleQueryChange = (name, value) => {
        let queryFieldsClone = Object.assign({}, queryFields);
        queryFieldsClone = FieldsManager.updateField(queryFieldsClone, name, value);
        setQueryFields(queryFieldsClone)
    }

    const tableActions = [
        {
            action: (item) => onDownloadZipClick(item),
            icon: ArchiveBoxArrowDownIcon,
            title: translate('btn.download_zip'),
            visible: (item) => item.JobStatusID === 2
        }]

    const renderJobStatusBadge = (statusID) => {
        let badgeType;
        let label;

        switch (statusID) {
            case 1:
                badgeType = 'outline'
                label = 'Started'
                break;
            case 2:
                badgeType = 'success'
                label = 'Completed'
                break;
            case 3:
                badgeType = 'danger'
                label = 'Failed'
                break;
            default:
                badgeType = 'outline'
                label = 'Pending'
        }

        return (
            <Badge type={badgeType}>
                {label}
            </Badge>
        )
    }

    return (
        <Layout
            ui={ui}
            user={user}
            match={match}
            history={history}
            location={location}
            translate={translate}
            dispatch={dispatch}
            hasFooter
            isAccessible={!(resource.errorStatus === 2)}
        >
            <Page>
                <PageHeader title={"Download center"}></PageHeader>

                <TableCard>
                    <TableFilters
                        hideLimit
                        handleInputChange={handleQueryChange}
                        filterFields={excludeFields(queryFields, ['limit', 'offset'])}
                        translate={translate}
                        isLoading={isLoading}
                        onRefreshTable={fetchData}
                    />

                    <ResourceTable
                        tableKey={'JobID'}
                        data={data}
                        count={count}
                        fields={fields}
                        translate={translate}
                        actions={tableActions}
                        queryFields={queryFields}
                        isLoading={isLoading}
                    />

                    <NoRecords
                        show={!count && !isLoading}
                        title={translate("text.no_download_jobs")}
                        addClass={'pt-10'}
                    />

                    <TableCardFooter>
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={handleQueryChange}
                            queryFields={queryFields}
                            translate={translate}
                        />
                    </TableCardFooter>
                </TableCard>
            </Page>
        </Layout>
    )
}

export default DownloadCenter;